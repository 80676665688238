// import config from '../config/config';
import { AxiosResponse } from 'axios';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
// import PageConfigService from './page-config.service';
import pageConfig from './PageConfig.json';
import { useContext } from 'react';
import SessionContext from '../../../library/Core/SessionContext';
import { SessionStateType } from '../../../library/Core/SmartTypes';

class CreateNewAlumniService {
    static userStatusCode: string;
    static studentId: string;
    static userTypeCode: string;
    static genderCode: string;
    static instituteLogo: string;
    static instituteGroupId: string;
    constructor() {
        // IIFE (Immediately Invoked Function Expression)
        (() => {
            const { sessionState } = useContext(SessionContext);
            CreateNewAlumniService.userStatusCode = sessionState?.userStatusCode as any;
            CreateNewAlumniService.studentId = sessionState?.studentId as any;
            CreateNewAlumniService.userTypeCode = sessionState?.userTypeCode as any;
            CreateNewAlumniService.genderCode = sessionState?.genderCode as any;
            CreateNewAlumniService.instituteLogo = sessionState?.instituteLogo as any;
            CreateNewAlumniService.instituteGroupId = sessionState?.instituteGroupId as any;
            //const { userStatusCode, studentId, userTypeCode, genderCode, instituteLogo: schoolImage } = sessionState as SessionStateType;
        })();

        // Rest of the constructor
    }

    static pageLoader(params: any) {
        const state: any = {};
        const { pageName } = params;
        logger.log(`Loading ${pageName}`);

        return Promise.all([this.getPageConfig()])
            .then((values) => {
                logger.log(`Retrieved values successfully for ${pageName}`);
                state.formConfig = values[0];
                // state.data = values[1];
                state.routeInfo = { pageName };
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    static async get(id: string, includeAdditionalInfo = false, includeComputedFieldsJson = false): Promise<any> {
        // TODO: Fix the issue with sessionStorage
        let studentProfile = JSON.parse(sessionStorage.getItem(`studentProfile-XXXXXXXXXXXXXXXXXXXXXX-${id}`) as string);

        if (!studentProfile) {
            studentProfile = (await axiosClient().get(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/profile`))?.data;
            studentProfile = CreateNewAlumniService.toStudentDto(studentProfile);
            sessionStorage.setItem(`studentProfile-${id}`, JSON.stringify(studentProfile));
        }

        if (includeAdditionalInfo) {
            // Fetch student additional info
            let studentComputedFields: any = {};
            if (includeComputedFieldsJson) {
                studentComputedFields = await axiosClient().get(
                    `${process.env.REACT_APP_REPORTS_SERVER}/common/${id}/profile-computed-fields`
                );
            }

            const studentAdditionalInfo = (await axiosClient().get(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`))
                ?.data;
            studentProfile = {
                ...studentProfile,
                ...CreateNewAlumniService.toStudentAdditionalInfoDto(studentAdditionalInfo),
                ...studentComputedFields?.data,
            };
        }

        return Promise.resolve(studentProfile);
    }

    static async save(id: string, type: string, data: any): Promise<any> {
        let result;
        if (type === 'profile') {
            result = await axiosClient().put(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/profile`, this.toStudentEntity(data));
            sessionStorage.setItem(`studentProfile-${id}`, JSON.stringify(result));
        } else if (type === 'additional-info') {
            result = await axiosClient().put(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`, data);
        }

        return Promise.resolve(result);
    }

    static toStudentDto(student: any) {
        //const userTypeCode = sessionStorage.getItem('user-type-code');
        student.profile = student.profile ?? {};
        student.underGradColleges = student.underGradColleges ?? [];
        student.schools = student.schools ?? [];
        student.postGradCollege = student.postGradCollege ?? [];
        student.entranceTests = student.entranceTests ?? [];
        student.jobs = student.jobs ?? [];
        student.socialProfileLinks = student.socialProfileLinks ?? [];
        student.benefits = student?.benefits ?? {};

        if (CreateNewAlumniService.userTypeCode === 'SCHOOL_ADMIN') {
            if (student?.profile?.adminAdditionalInfo) {
                student.adminAdditionalInfo = JSON.parse(student.profile.adminAdditionalInfo);
            } else {
                student.adminAdditionalInfo = { timeSlots: [{}] };
            }

            // student.adminAdditionalInfo = JSON.parse(student?.profile?.adminAdditionalInfo) ?? { timeSlots: [{}] };
        }

        student.user = {
            firstName: student.firstName,
            lastName: student.lastName,
            classCode: student.classCode,
            curriculumCode: student.curriculumCode,
            mobileCountryCode: student.mobileCountryCode,
            mobileNumber: student.mobileNumber,
            registeredEmailId: student.registeredEmailId,
            isMobileVerified: student.isMobileVerified,
            isEmailVerified: student.isEmailVerified,
            isPeerReviewed: student.isPeerReviewed,
            schoolId: student.schoolId,
            schoolGroupId: CreateNewAlumniService.instituteGroupId,
        };

        student.profile.schoolId = student.schoolId;
        student.profile.contactEmailId = student.profile.contactEmailId ?? student.registeredEmailId;
        student.profile.contactMobileCountryCode = student?.profile?.contactMobileCountryCode ?? student.mobileCountryCode;
        student.profile.contactMobileNumber = student?.profile?.contactMobileNumber ?? student.mobileNumber;
        student.profile.schoolGroupId = CreateNewAlumniService.instituteGroupId;

        // school
        student.schoolCurrent = student.schools.find((school: any) => school.recordTypeCode === 'CURRENT') ?? {};

        if (parseInt(student.classCode) > 10) {
            student.schoolClass10 = student.schools.find((school: any) => school.recordTypeCode === 'CLASS_10') ?? {};
            student.schoolClass10 = {
                ...student.schoolClass10,
                recordTypeCode: 'CLASS_10',
                // cieGrade: student.schoolClass10?.cieGrade ? JSON.parse(student.schoolClass10.cieGrade) : {},
            };

            student.schoolClass12 = student.schools.find((school: any) => school.recordTypeCode === 'CLASS_12') ?? {};
            student.schoolClass12 = {
                ...student.schoolClass12,
                recordTypeCode: 'CLASS_12',
                curriculumCode: student.curriculumCode,
                schoolId: student.schoolId,
            };
        }
        if (student.classCode > 10) {
            student.schoolClass10.cieGrade = student?.schoolClass10?.cieGrade ? JSON.parse(student?.schoolClass10?.cieGrade) : {};
            student.schoolClass12.cieGrade = student?.schoolClass12?.cieGrade ? JSON.parse(student?.schoolClass12?.cieGrade) : {};
        }
        // if (student.classCode > 10 && student.schoolClass12?.recordTypeCode === 'CLASS_12') {
        //     student.schoolClass12.curriculumCode = student.curriculumCode;
        // }

        // Undergraduate
        student.underGradCollegeJoined = student.underGradColleges.find((ug: any) => ug.recordTypeCode === 'JOINED') ?? {};
        student.underGradCollegeApplied =
            student.underGradColleges.filter(
                (ug: any) => (ug.universityId != null || ug.otherUniversity != null) && ug.recordTypeCode !== 'JOINED'
            ) ?? [];
        student.underGradCollegeApplied = student.underGradCollegeApplied.map((ug: any) => {
            if (isEmpty(ug.scholarshipAmount)) ug.didYouReceiveScholarship = false;
            return ug;
        });
        // Entrance Tests
        student.entranceTestForJoined = student.entranceTests.filter((test: any) => test.recordTypeCode === 'JOINED') ?? [];
        student.entranceTestForApplied = student.entranceTests.filter((test: any) => test.recordTypeCode === 'APPLIED') ?? [];

        // student.targetSkills = student.skills.find((skill: any) => skill.recordTypeCode === '') ?? {};
        // student.currentSkills = student.skills.filter((skill: any) => skill.recordTypeCode !== '') ?? [];

        //Benefits
        student.benefits.filtersCountry =
            student?.benefits?.filtersCountry?.split(',').map((country: string) => ({ filtersCountry: country })) ?? [];
        student.benefits.filtersCourse =
            student?.benefits?.filtersCourse?.split(',').map((course: string) => ({ filtersCourse: course })) ?? [];
        student.benefits.filtersIndustry =
            student?.benefits?.filtersIndustry?.split(',').map((industry: string) => ({ filtersIndustry: industry })) ?? [];
        student.benefits.filtersFunctionalArea =
            student?.benefits?.filtersFunctionalArea?.split(',').map((area: string) => ({ filtersFunctionalArea: area })) ?? [];

        return student;
    }

    static toStudentEntity(student: any) {
        console.log(student);
        if (student.user) {
            student.firstName = student.user['firstName'];
            student.lastName = student.user['lastName'];
            student.classCode = student.user['classCode'];
            student.curriculumCode = student.user['curriculumCode'];
            student.mobileCountryCode = student.user['mobileCountryCode'];
            student.mobileNumber = student.user['mobileNumber'];
            student.registeredEmailId = student.user['registeredEmailId'];
        }

        if (student.schools) {
            const schoolCurrent =
                Object.keys(student.schoolCurrent).length > 0 ? { ...student.schoolCurrent, recordTypeCode: 'CURRENT' } : {};

            const schoolClass10 =
                student && student.schoolClass10 && Object.keys(student.schoolClass10).length > 0
                    ? {
                          ...student.schoolClass10,
                          classCode: 10,
                          recordTypeCode: 'CLASS_10',
                          cieGrade: JSON.stringify(student.schoolClass10.cieGrade),
                      }
                    : {};

            const schoolClass12 =
                student && student.schoolClass12 && Object.keys(student.schoolClass12).length > 0
                    ? {
                          ...student.schoolClass12,
                          classCode: 12,
                          recordTypeCode: 'CLASS_12',
                          cieGrade: JSON.stringify(student.schoolClass12.cieGrade),
                      }
                    : {};

            student.schools = [schoolCurrent, schoolClass10, schoolClass12]
                .flatMap((item) => item)
                .filter((item) => Object.keys(item).length > 0);
        }

        if (student.underGradColleges) {
            const underGradCollegeJoined =
                Object.keys(student.underGradCollegeJoined).length > 0
                    ? { ...student.underGradCollegeJoined, recordTypeCode: 'JOINED' }
                    : {};

            const underGradCollegeApplied = student.underGradCollegeApplied.filter(
                (college: any) => !isEmpty(college.universityId) || !isEmpty(college?.otherUniversity)
            );

            student.underGradColleges = [underGradCollegeJoined, underGradCollegeApplied]
                .flatMap((item) => item)
                .filter((item) => Object.keys(item).length > 0);
        }

        if (student.entranceTests) {
            const entranceTestForApplied = student.entranceTestForApplied.map((item: any) =>
                isEmpty(item?.entranceTestCode) ? {} : { ...item, recordTypeCode: 'APPLIED' }
            );

            const entranceTestForJoined = student.entranceTestForJoined.map((item: any) =>
                isEmpty(item?.entranceTestCode) ? {} : { ...item, recordTypeCode: 'JOINED' }
            );
            // Object.keys(student.entranceTestForJoined).length > 0 ? { ...student.entranceTestForJoined, recordTypeCode: 'JOINED' } : {};

            student.entranceTests = [
                ...entranceTestForJoined.filter((item: any) => !isEmpty(item)),
                ...entranceTestForApplied.filter((item: any) => !isEmpty(item)),
            ];
        }

        if (student.jobCompanyPreferences) {
            student.jobCompanyPreferences = student.jobCompanyPreferences.filter(
                (item: any) => !isEmpty(item) && item.companyName != null && item.companyName != ''
            );
        }

        if (student.educationCountryPreferences) {
            student.educationCountryPreferences = student.educationCountryPreferences
                .filter((item: any) => !isEmpty(item) && !isEmpty(item.countryCode))
                .map((item: any, index: number) => ({ ...item, preferenceOrder: index + 1 }));
        }

        if (student.educationFieldOfStudyPreferences) {
            student.educationFieldOfStudyPreferences = student.educationFieldOfStudyPreferences
                .filter((item: any) => !isEmpty(item))
                .map((item: any, index: number) => ({ ...item, preferenceOrder: index + 1 }));
        }

        if (student.educationUniversityPreferences) {
            student.educationUniversityPreferences = student.educationUniversityPreferences
                .filter((item: any) => !isEmpty(item) && !isEmpty(item.universityId))
                .map((item: any, index: number) => ({ ...item, preferenceOrder: index + 1 }));
        }

        //Benefits
        let benefits = { filtersCountry: '', filtersCourse: '', filtersIndustry: '', filtersFunctionalArea: '' };
        if (student.benefits) {
            benefits.filtersCountry = student?.benefits?.filtersCountry.map((country: any) => country.filtersCountry).join(',');
            benefits.filtersCourse = student?.benefits?.filtersCourse.map((country: any) => country.filtersCourse).join(',');
            benefits.filtersIndustry = student?.benefits?.filtersIndustry.map((country: any) => country.filtersIndustry).join(',');
            benefits.filtersFunctionalArea = student?.benefits?.filtersFunctionalArea
                .map((country: any) => country.filtersFunctionalArea)
                .join(',');
            student.benefits = { ...student.benefits, ...benefits };
        }

        if (student?.benefits && student?.educationPreferenceInfo && !isEmpty(student?.benefits?.eventsPursuePgDegree)) {
            student.educationPreferenceInfo = {
                ...student?.educationPreferenceInfo,
                doYouRequireApplicationSupportForPgAdmission: student?.benefits?.eventsPursuePgDegree,
            };
        }

        if (student?.benefits && student?.jobPreferenceInfo && !isEmpty(student?.benefits?.eventsNotifyJobReferrals)) {
            student.jobPreferenceInfo = {
                ...student?.jobPreferenceInfo,
                interestedInJobReferrals: student?.benefits?.eventsNotifyJobReferrals,
            };
        }

        //Skills
        if (student.skills) {
            let currentSkills = student.skills;
            const filteredSkills = currentSkills.filter((skill: any) => skill.skillCode !== undefined && skill.skillCode !== '');
            student.skills = filteredSkills;
        }

        if (student.skillInfo) {
            const targetSkillsCSV = student?.targetSkills
                ?.filter((skill: any) => skill.skillCode !== undefined && skill.skillCode !== '')
                ?.map((skill: any) => skill.skillCode)
                ?.join(',');

            student.skillInfo = { ...student.skillInfo, targetSkillCode: targetSkillsCSV ?? '' };
        }

        return { ...student };
    }

    static toStudentAdditionalInfoDto(studentAdditionalInfo: any) {
        //studentAdditionalInfo.targetSkills = [];
        //studentAdditionalInfo.existingSkills = [];
        studentAdditionalInfo.skillInfo = studentAdditionalInfo.skillInfo ?? {};
        studentAdditionalInfo.skills = studentAdditionalInfo?.skills ?? [];

        //studentAdditionalInfo.skillInfo = studentAdditionalInfo?.skillInfo ?? {};
        //studentAdditionalInfo.skills = studentAdditionalInfo?.skills ?? {};
        studentAdditionalInfo.educationPreferenceInfo = studentAdditionalInfo?.educationPreferenceInfo ?? {};
        studentAdditionalInfo.jobPreferenceInfo = studentAdditionalInfo?.jobPreferenceInfo ?? {};
        studentAdditionalInfo.mentoringInterests = studentAdditionalInfo?.mentoringInterests ?? {};

        studentAdditionalInfo.targetSkills = studentAdditionalInfo.skillInfo?.targetSkillCode
            ?.split(',')
            ?.map((skill: any) => ({ skillCode: skill }));

        studentAdditionalInfo.educationCountryPreferences = studentAdditionalInfo.educationCountryPreferences.sort(
            (a: any, b: any) => a.preferenceOrder - b.preferenceOrder
        );

        studentAdditionalInfo.educationFieldOfStudyPreferences = studentAdditionalInfo.educationFieldOfStudyPreferences.sort(
            (a: any, b: any) => a.preferenceOrder - b.preferenceOrder
        );

        studentAdditionalInfo.educationUniversityPreferences = studentAdditionalInfo.educationUniversityPreferences.sort(
            (a: any, b: any) => a.preferenceOrder - b.preferenceOrder
        );

        studentAdditionalInfo.currentSkills = studentAdditionalInfo.skills.filter((skill: any) => skill.skillLevelCode != null) ?? [];

        return studentAdditionalInfo;
    }

    static async pageSave(id: string, pageName: string, data: any): Promise<AxiosResponse<any, any>> {
        // TODO: Refactor this to depend on teh nodes rather than the pages
        const pagesBasedOnStudentProfile = [
            'alumni-basic-info',
            'alumni-profile-picture',
            'alumni-undergraduate',
            'alumni-scholarship',
            'alumni-entrance-test',
            'alumni-postgraduate',
            'alumni-professional-work',
            'alumni-benefits',
            'alumni-mentoring-interests',
            'student-basic-info',
            'student-education',
            'student-entrance-exams',
            'admin-basic-info',
            'admin-additional-info',
            'admin-preferences',
        ];

        const url = pagesBasedOnStudentProfile.includes(pageName)
            ? `${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/profile`
            : `${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`;

        if (['alumni-benefits', 'alumni-mentoring-interests'].includes(pageName)) {
            axiosClient().put(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`, data);
        }

        return axiosClient().put(url, data);
    }

    static async SaveProfileImagePath(state: any): Promise<AxiosResponse<any, any>> {
        const imagePath = state.data.profile.profilePictureFilePath;
        const id = state.routeInfo.id;
        const data = { id: id, imagePath: imagePath };
        const url = `${process.env.REACT_APP_COMMON_API}/users/update-profile-image-path`;
        return axiosClient().post(`${url}`, data);
    }

    static getStudentPassOutYear = (student: any) => student.profile.passOutYear ?? 2000;
}
export default CreateNewAlumniService;
