import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import { axiosClient } from '../../../library/Service/axiosClient';
const Grid = () => {
    const { state, dispatch } = useContext(SmartContext);
    const schoolList = state.data.schoolList;

    const navigate = useNavigate();
    const [alertVisible, setAlertVisible] = useState(false);

    const summaryRecord = schoolList?.find((row: any) => row.schoolName === 'All Schools' && row.schoolGroupName === 'All Groups') ?? {};
    const [hasMore, setHasMore] = useState(true);
    const detailList = schoolList?.filter((row: any) => row.schoolName !== 'All Schools') ?? [];
    const [defaultDataListLength, setDefaultDataListLength] = useState(detailList.slice(0, 20));
    const [disabledButtons, setDisabledButtons] = useState(new Set());

    const updateToMOE = async (schoolId: string, program_level: string) => {
        const impersonatedUserSession = await axiosClient().get(
            `${process.env.REACT_APP_ALUMNI_MATCH_SERVER}send-alumni-to-moe/${schoolId}/${program_level}`
        );
        setAlertVisible(true);

        setDisabledButtons((prev) => new Set(prev).add(schoolId)); // Disable button after the update
    };

    const fetchMoreStudents = () => {
        if (defaultDataListLength.length >= detailList.length) {
            setHasMore(false);
            return;
        }

        const startIndex = defaultDataListLength.length;
        const endIndex = startIndex + 10;
        const newDisplayedData = detailList.slice(startIndex, endIndex);

        setDefaultDataListLength((previousData: any) => [...previousData, ...newDisplayedData]);
    };

    const loadMoreRef = useRef(null);

    useEffect(() => {
        const loadMoreObserver = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                fetchMoreStudents();
            }
        });

        if (loadMoreRef.current) loadMoreObserver.observe(loadMoreRef.current);

        return () => {
            if (loadMoreRef.current) {
                loadMoreObserver.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, defaultDataListLength, schoolList]);

    // Reset data when schoolList changes
    useEffect(() => {
        setDefaultDataListLength(detailList.slice(0, 20));
    }, [schoolList]);
    return (
        <div className="table-responsive attendee_dtl">
            {/* Display the alert when alertVisible is true */}
            {alertVisible && (
                <div className="alert alert-success text-center" role="alert">
                    Details Sent to MOE!
                    <button type="button" className="btn-close" onClick={() => setAlertVisible(false)}></button>
                </div>
            )}
            <table className="table" style={{ minWidth: '1200px' }}>
                <thead>
                    <tr>
                        <th style={{ width: '200px' }}>
                            <div className="d-flex flex-wrap">Institute Name</div>
                        </th>
                        <th>
                            <div className="labelItem">City</div>
                        </th>
                        <th>
                            <div className="labelItem">Institute Group</div>
                        </th>
                        <th className="text-center">
                            <div className="labelItem">
                                No. of
                                <br /> Students
                            </div>
                        </th>
                        <th className="text-center">
                            <div className="labelItem">
                                No. of
                                <br /> Alumni
                            </div>
                        </th>
                        <th className="text-center">
                            <div className="labelItem">
                                Unique Student to
                                <br /> Alumni Matches Found
                            </div>
                        </th>
                        <th className="text-end">MoE Status</th>
                        <th className="text-center">MOE Updated Date</th>
                        <th className="text-center">Actions</th>
                    </tr>
                    <tr style={{ color: 'red' }}>
                        <th>
                            <div className="d-flex flex-wrap">All Institutes</div>
                        </th>
                        {/* <th className="university-name"></th> */}
                        <th>
                            <div className="d-flex flex-wrap">All Cities</div>
                        </th>
                        <th>
                            <div className="d-flex flex-wrap"> All Groups </div>
                        </th>
                        <th className="text-center">{summaryRecord.totalStudent}</th>
                        <th className="text-center">{summaryRecord.totalAlumni}</th>
                        <th className="text-center">{summaryRecord.totalStudentsWithMatch}</th>
                        <th className="text-end">MoE Status</th>
                        <th className="text-end">{summaryRecord.updatedToMoe}</th>
                        <th className="text-end"></th>
                    </tr>
                </thead>
                <tbody>
                    {defaultDataListLength.map((row: any) => (
                        <tr key={row.schoolName}>
                            <td>
                                <div className="d-flex align-items-center checkoption">
                                    {row.schoolId !== row.schoolGroupId && (
                                        <a
                                            className="tablink font-16 font-500"
                                            role="button"
                                            onClick={() => navigate(`/support/alumni-school/${row.schoolGroupId}/${row.schoolId}/${row?.studentClass}`)}>
                                            {row.schoolName}
                                        </a>
                                    )}

                                    {row.schoolId === row.schoolGroupId && (
                                        <a
                                            className="tablink font-16 font-500"
                                            role="button"
                                            onClick={() => navigate(`/support/alumni-school/${row.schoolId}/${row.studentClass}`)}>
                                            {row.schoolName}
                                        </a>
                                    )}
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-wrap align-items-center">
                                    {getDomainValueForCode(row.cityCode, 'CITY_CODE', state)}
                                </div>
                            </td>
                            <td>
                                {row.schoolId !== row.schoolGroupId && (
                                    <a
                                        className="tablink font-16 font-500"
                                        role="button"
                                        onClick={() => navigate(`/support/alumni-school-group/${row.schoolGroupId}/${row.studentClass}`)}>
                                        {row.schoolGroupName}
                                    </a>
                                )}
                                {row.schoolId === row.schoolGroupId && (
                                    <div className="d-flex flex-wrap align-items-center">NA</div>
                                )}
                            </td>
                            <td className="text-center">
                                <div className="d-flex flex-wrap justify-content-center mailSetting">{row.totalStudent}</div>
                            </td>
                            <td className="text-center">
                                <div className="d-flex align-items-center justify-content-center">{row.totalAlumni}</div>
                            </td>
                            <td className="text-center">
                                <div className="">{row.totalStudentsWithMatch}</div>
                            </td>
                            <td className="text-center">
                                <div className="">
                                    {row.updatedToMoe ? (row.profileUpdatedToMoe == 1 ? 'Success' : 'Failed') : 'Not pushed'}
                                </div>
                            </td>
                            <td>
                                <div className="text-end">{row.updatedToMoe ?? 'Not pushed'}</div>
                            </td>
                            <td className="text-right">
                                <div className="dateLabelParent">
                                    <button
                                        onClick={() => {
                                            const level =
                                                row.studentClass === 'SCHOOL' && row.studentClass === 'K12_SCHOOL'
                                                    ? 'K12_SCHOOL'
                                                    : row?.studentClass;
                                            updateToMOE(row.schoolId, level);
                                        }}
                                        className="btn-update bg-lite-blue btn-green"
                                        disabled={disabledButtons.has(row.schoolId)}>
                                        <span>{disabledButtons.has(row.schoolId) ? 'Updated to MOE' : 'Update to MOE'}</span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                    <tr ref={loadMoreRef} />
                </tbody>
            </table>
        </div>
    );
};

export default Grid;
