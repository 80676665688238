import { useContext, useEffect, useRef, useState } from 'react';
import FormBuilder from '../../../library/Builders/FormBuilder';
import { SmartContext } from '../../../library/Core/SmartContext';
import { FormSection } from '../../../library/Core/SmartTypes';
import useManageAlumni from '../engagement-list/useManageAlumni';
import DomainCategoryDataService from './DomainCategoryDataService';

const SearchCriteria = () => {
    const { state,dispatch } = useContext(SmartContext);
    const { setStateValue } = useManageAlumni();
    const searchFormConfig = state.formConfig?.sectionRepository[0] as FormSection;
    const [isExpanded, setIsExpanded] = useState(true);

    const isFirstRender = useRef(true);


    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        DomainCategoryDataService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) =>
            setStateValue('alumniList', response.data)
        );

        // dispatch({
        //     type: 'SET_DOMAIN',
        //     payload: { key:"DOMAIN_CATEGORY_CODE", value:state?.internal?.DomainCategorycode },
        // });

    }, [state.data.searchCriteria]);

    return (
        <>
            <div className="fitergroup mb-3">
                <div id="collapseOne" className="accordion-collapse collapse show">
                    <div className="accordion-body p-4">
                        <div className="row g-3">
                            <FormBuilder key="searchCriteria" section={searchFormConfig} dataKey="searchCriteria" />
                        </div>
                       
                    </div>
                </div>
            </div>
        </>
    );
};

export default SearchCriteria;
