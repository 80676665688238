const LoadingSpinner = (props) => {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: props.height ?? '100vh' }}>
            <div className="spinner-border text-primary" role="status" id="loadingSpinner">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

export default LoadingSpinner;
