import logger from '../../library/Core/logger';
import { axiosClient } from '../../library/Service/axiosClient';
import pageConfig from './PageConfig.json';

class InstituteProgramService {
    static pageLoader(params: any) {
        const state: any = {};
        const { id, pageName } = params;

         return Promise.all([
            this.getPageConfig(),
          ])
            .then((values) => {
                state.formConfig = values[0];
                state.routeInfo = { id, pageName };
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }
    
    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }
}
export default InstituteProgramService;
