import { useContext, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';

import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, handleControlValueChange } from '../Core/SmartFunctions';
import { DomainElement, SimpleFormControlArguments, State } from '../Core/SmartTypes';
import './custom-control.css';
import ErrorControl from './ErrorControl';

const SelectControlV3 = ({ control, dataKey, parentDataKey }: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const data = getControlValueFromState(dataKey, state as State, []);
    const autoGrow = control?.props?.customProperties?.autoGrow ?? true;
    const [customHeight, setCustomHeight] = useState('45px');
    const [Limit, setLimit] = useState(control?.props?.customProperties?.limit ?? 100);

    useEffect(() => {
        const errorMessages = validateFormField(
            control,
            data,
            state,
            control?.props?.label,
            dataKey,
            getControlValueFromState(parentDataKey as string, state as State)
        );
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });
    }, []);

    const parentData = control.props.parentId && getControlValueFromState(parentDataKey + '.' + control.props.parentId, state as State);
    let controlDomain: any = (state?.domain?.get(control.props.domainCategoryCode) as DomainElement[]).filter((domain: DomainElement) => {
        if (
            parentData == null ||
            control.props.parentId === null ||
            control.props.parentId === undefined ||
            control.props.parentId.length === 0
        )
            return true;
        else return domain.parentCode == parentData;
    });

    const options = useMemo(() => {
        // const controlDomain = state?.domain?.get(control.props.domainCategoryCode) as DomainElement[];
        return controlDomain.map((domain: any) => ({ value: domain.code, label: domain.value }));
    }, []);

    const selectedValue = useMemo(() => {
        const tempVal = controlDomain.filter((domain: any) =>
            data?.some((item: any) => item[control.id] == domain.code)
        ) as DomainElement[];
        if (autoGrow) setCustomHeight(`${45 + tempVal.length * 0.4 * 40}px`);
        return tempVal.map((item) => ({ value: item.code, label: item.value }));
    }, [data]);

    // const customHeight = '100px'; //`${selectedValue.length * 40}px`;

    // Note: https://stackoverflow.com/questions/54218351/changing-height-of-react-select-component
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            height: customHeight,
            overflow: 'auto', // Make sure the overflow is set to 'auto' or 'visible'
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: customHeight,
            padding: '0 6px',
        }),
        input: (provided: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            height: customHeight,
        }),
        option: (provided: any, controlState: any) => ({
            ...provided,
            color: controlState.isSelected ? 'text-white' : provided.color,
            backgroundColor: controlState.isSelected ? 'bg-primary' : provided.backgroundColor,
        }),
    };

    const handleOnChange = (event: any) => {
        let newOptionsSelected = [];
        for (let i = 0; i < event.length; i++) {
            newOptionsSelected.push({ [control.id]: event[i].value });
        }
        handleControlValueChange({ control, value: newOptionsSelected, dataKey, parentDataKey, state, dispatch });
        if (autoGrow) setCustomHeight(`${45 + event.length * 0.4 * 40}px`);
    };

    // console.log(state.data[dataKey].length,'length');

    return (
        <>
            {control.props?.label && (
                <label htmlFor={control.id} className="form-label">
                    {`${control.props.label} `}
                </label>
            )}
            <div
                className="custom-input-group custom-input-wrap"
                style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', height: customHeight, alignItems: 'stretch' }}>
                <div
                    className="custom-input-group-text bg-white d-flex align-items-center justify-content-center border"
                    style={{ padding: '0.375rem 0.75rem' }}>
                    <i className="bi bi-search fs-6"></i>
                </div>
                <Select
                    isMulti
                    name="colors"
                    onChange={handleOnChange}
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    // defaultValue={selectedValue}
                    value={selectedValue}
                    components={{ DropdownIndicator: null, IndicatorSeparator: null }}
                    styles={customStyles}
                    isOptionDisabled={() => (state.data[dataKey] != undefined) && (state.data[dataKey].length >= Limit)}
                    isClearable={true}
                />
            </div>
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default SelectControlV3;
