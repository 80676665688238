import { useEffect, useState } from 'react';
import { OptionsOrGroups, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { getStudentImage } from '../../common/styles/Images';
import { axiosClient } from '../../library/Service/axiosClient';
import Option from 'react-select/dist/declarations/src/components/Option';
import { isEmpty } from '../../library/Core/SmartFunctions';

// Define the interface for the user object
interface User {
    uuid: string;
    userTypeCode: string;
    firstName: string;
    lastName: string;
    programName: string;
    passOutYear?: number;
    // Add other properties as needed
}

interface CustomOption {
    value: string;
    label: string;
    // other properties
}

const UserSearch = () => {
    const [userNameForSearch, setUserNameForSearch] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadUserNamesForSearch = async () => {
            const res = await axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/common/list-for-name-search`);
            // sessionStorage.setItem('user-names-for-search', JSON.stringify(res.data?.students));
            setUserNameForSearch(res.data?.students);
        };
        loadUserNamesForSearch();
    }, []);

    // const loadOptions = (inputValue: string, callback: (options: any[]) => void) => {
    //     return Promise.resolve(userNameForSearch.filter((option: any) => option?.name?.toUpperCase().includes(inputValue.toUpperCase())));
    // };
    // const loadOptions = async (inputValue: string, callback: (options: any[]) => void) => {
    //     setIsLoading(true);
    //     try {
    //         const res = await axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/common/list-for-name-search`);
    //         const users = res.data?.students || [];
    //         setUserNameForSearch(users);
    //         callback(users.filter((option: any) => option?.name?.toUpperCase().includes(inputValue.toUpperCase())));
    //     } catch (error) {
    //         console.error("Error loading user names:", error);
    //         callback([]);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    // const loadOptions = async (inputValue: string, callback: (options: OptionsOrGroups<any, any>) => void) => {
    //     setIsLoading(true);
    //     try {
    //         const res = await axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/common/list-for-name-search`);
    //         const users = res.data?.students || [];
    //         setUserNameForSearch(users);
    //         // Map the fetched users to the expected format by AsyncSelect
    //         const formattedOptions = users.map((user: any) => ({
    //             value: user.uuid,
    //             label: `${user.firstName} ${user.lastName}`,
    //             // Add other properties if needed
    //         }));
    //         callback(formattedOptions.filter((option: Option) => option.label.toUpperCase().includes(inputValue.toUpperCase())));
    //         setIsLoading(false);
    //     } catch (error) {
    //         console.error("Error loading user names:", error);
    //         setIsLoading(false);
    //     }
    // };

    const loadOptions = async (inputValue: string, callback: (options: OptionsOrGroups<any, any>) => void) => {
        setIsLoading(true);
        try {
            if (isEmpty(inputValue) || inputValue.length < 3) {
                return [];
            }

            const res = await axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/common/list-for-name-search?term=${inputValue}`);
            const users = res.data?.students || [];
            setUserNameForSearch(users);
            // Map the fetched users to the expected format by AsyncSelect
            // const formattedOptions = users.map((user: any) => ({
            //     value: user.uuid,
            //     label: `${user.firstName} ${user.lastName}`,
            //     // Add other properties if needed
            // }));
            const formattedOptions = users;
            setIsLoading(false);
            callback(formattedOptions);
            return formattedOptions; // Returning the options
        } catch (error) {
            console.error('Error loading user names:', error);
            setIsLoading(false);
            callback([]); // Return empty options in case of error
            return []; // Return empty options
        }
    };

    const handleOnChange = (user: any) => {
        if (isEmpty(user)) {
            return;
        }
        const { uuid, userTypeCode } = user;

        const targetPath = `/alumni/${uuid}/profile`;
        window.open(targetPath, '_blank');
    };

    const CustomOption = (props: any) => {
        const { data } = props;
        return (
            <components.Option {...props}>
                <p
                    key={data.id}
                    className=" d-flex flex-wrap align-items-center font-16 line-height-18 font-500 text-dark-blue mb-0 pb-3 pb-sm-2">
                    <span className="me-3">
                        <img
                            src={getStudentImage(data)}
                            alt=""
                            className="rounded-circle"
                            style={{ height: '50px', borderRadius: '50%', width: '50px' }}
                        />
                    </span>
                    <span className="flex-1">
                        {`${data.firstName} ${data.lastName}`},{' '}
                        <span className="fst-italic">
                            {data.programName}
                            {data.passOutYear ? `, ${data.passOutYear}` : ''}
                        </span>
                    </span>
                </p>
            </components.Option>
        );
    };

    return (
        <>
            {userNameForSearch && (
                <AsyncSelect
                    loadOptions={loadOptions}
                    onChange={handleOnChange}
                    isSearchable={true}
                    isClearable
                    components={{
                        DropdownIndicator: null,
                        IndicatorSeparator: null,
                        Option: CustomOption,
                    }}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
            )}
        </>
    );
};

export default UserSearch;
