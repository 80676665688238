import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import HeaderControl from '../../features/alumni/_common/HeaderControl';
import PageFooterControl from '../../library/SimpleControls/PageFooterControl';
import MenuBuilder from '../../routes/MenuBuilder';
import ErrorPage from '../../library/SimpleControls/ErrorPage';

function ManagementLayout() {
    const handleNavigateToHome = () => {
        // resetErrorBoundary();
        return <ErrorPage />;
    };

    return (
        <>
            <header>
                <HeaderControl />
                {/* <MenuLayout /> */}
                <MenuBuilder />
            </header>
            <ErrorBoundary fallbackRender={handleNavigateToHome} onReset={(details) => {}}>
                <Outlet />
            </ErrorBoundary>
            <PageFooterControl />
        </>
    );
}

export default ManagementLayout;
