import React, { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import DebugBarControl from '../../library/SimpleControls/DebugBarControl';
import SessionContext from '../../library/Core/SessionContext';
import QnaApiService from './QnaApiService';
import { GlobalContext } from '../../library/Core/GlobalContext';

const AlumniMyQueries: React.FC = () => {
    const { ids } = useParams<{ ids: string }>();
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);
    const { state, dispatch } = useContext(SmartContext);
    const { formConfig, data, internal } = useLoaderData() as State;
    const { globalState } = useContext(GlobalContext);
    const userId = sessionState?.studentId ?? sessionState?.userId;
    const instituteGroupId = sessionState?.instituteGroupId;

    useEffect(() => {
        getAlumniUnanswered({});
    }, []);

    function getAlumniUnanswered(searchVal: any): Promise<any> {
        const params = `?instituteGroupId=${instituteGroupId}`;
        return Promise.all([
            QnaApiService.getAlumniUnanswered(params),
        ]).then((response) => {
            const questions = response[0]?.data.data ?? [];
            dispatch({
                type: 'DATA_INIT',
                payload: {
                    formConfig,
                    data: { ...data, alumniMyQuery: questions },
                    domain: globalState.domain,
                    internal,
                    flags: { isDataLoading: false }
                },
            });
            return Promise.resolve(state);
        });
    }

    const formatDate = (isoDateString: string): string => {
        const createdDate = new Date(isoDateString); // Convert ISO string to Date object

        // Format the date as per your requirement
        const formattedDate = `${createdDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        })} | ${createdDate.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        })}`;

        return formattedDate;
    };

    const alumniMyQuery = state.data?.alumniMyQuery || [];

    const handleAnswerQuestionClick = (question_id: string) => {
        navigate(`/answer-question?question_id=${question_id}`);
    };

    return (
        <div>
            <div className="post-wrap">
                {alumniMyQuery.map((query: any) => (
                    <div className="post-block">
                        <div className="row g-3">
                            <div className="col-auto">
                                <div className="post-img">
                                    <img src={query.profile_picture_file_path || 'https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/yellow-student-boy.svg'} width="60" alt={query.first_name} />
                                </div>
                            </div>
                            <div className="col">
                                <div className="row g-2">
                                    <div className="col-12">
                                        <div className="row g-2">
                                            <div className="col-sm">
                                                <div className="post-head">POSTED BY</div>
                                                <div className="post-alumni-name">{query.first_name} {query.last_name}</div>
                                                <div className="post-alumni-batch">{query.program_name}, {query.pass_out_year}</div>
                                            </div>  
                                            <div className="col-sm-auto">
                                                <span className="font-14">{formatDate(query.created)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row g-2">
                                            <div className="col-sm">
                                            <p className="brife">{query.description}</p>
                                            </div>  
                                        <div className="col-sm-auto">
                                            <button className="btn btn-green" onClick={() => handleAnswerQuestionClick(query.question_id)}>
                                                <span>Answer</span>
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <DebugBarControl />
        </div>
    );
};

export default AlumniMyQueries;
