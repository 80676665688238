import { useContext } from 'react';
import BasicInfoVisual from '../../../../styles/images/basic-info-visual.png';
import CollegeSearch from './CollegeSearch';
import TopColleges from './TopColleges';
import TopSearchTab from './TopSearchTab';
import TopCollegesTab from './TopCollegesTab';
import CompareColleges from './CompareColleges';
import CollegeService from './CollegeService';
import { SmartContext } from '../../library/Core/SmartContext';
import { GlobalContext } from '../../library/Core/GlobalContext';
import { useLoaderData } from 'react-router-dom';
import { State } from '../../library/Core/SmartTypes';

const SchoolLogo = sessionStorage.getItem('school-image') as any;

const TopCollegesLayout = (props: any) => {

    const { state, dispatch } = useContext(SmartContext);
    const { globalState } = useContext(GlobalContext);

    const { formConfig, data, internal } = useLoaderData() as State;

    function topCollegeFilter(rank:any): Promise<any> {
        // const state: any = {};
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig,data, domain: globalState.domain, internal,flags:{isDataLoading:true} },
        });
        
        var topCollegeUrl = `?has_ranking=${rank}`;
        
        return Promise.all([
            CollegeService.getTopCollegeFilter(topCollegeUrl),
        ]).then((response) => {
            // console.log('response---Filter-- JSON' + JSON.stringify(response[0]?.data.data));

            dispatch({
                type: 'DATA_INIT',
                payload: { formConfig,
                           data:{...data,topCollegeList: response[0]?.data.data ?? []}, 
                           domain: globalState.domain,
                           internal,
                           flags:{isDataLoading:false} },
            });

            return Promise.resolve(state);

        });
    }

    return (
        <>
            <div className="m-0 pt-4 pb-5 mt-2 d-none">
                <p className="font-16 mb-4">
                    Confused about which college to choose? Add up to 3 universities for college comparison on various points
                </p>
                <div className="position-relative compareCollegeswrap">
                    <div className="tabular-data">
                        <div id="content">
                            <div className="row pb-4">
                                <div className="col-md-3 col-sm-3 col-4">
                                    <div className="heading"></div>
                                    <div className="content">
                                        <span className="lead">Establishment Year</span>
                                    </div>
                                    <div className="content">
                                        <span className="lead">Undergraduate Courses</span>
                                    </div>
                                    <div className="content">
                                        <span className="lead">Institute Type </span>
                                    </div>
                                    <div className="content">
                                        <span className="lead">Campus Size (in Acres) </span>
                                    </div>
                                    <div className="content">
                                        <span className="lead"> Calendar System </span>
                                    </div>
                                    <div className="content ranking">
                                        <span className="lead"> Ranking</span>
                                    </div>
                                    <div className="content">
                                        <span className="lead"> Total Students</span>
                                    </div>
                                    <div className="content">
                                        <span className="lead">Number of Scholarships </span>
                                    </div>
                                    <div className="content">
                                        <span className="lead"> Number of Campuses </span>
                                    </div>
                                    <div className="content">
                                        <span className="lead">International Students </span>
                                    </div>
                                    <div className="content">
                                        <span className="lead"> Acceptance Ratio </span>
                                    </div>
                                    <div className="content">
                                        <span className="lead"> Cost of living on Campus </span>
                                    </div>
                                    <div className="content">
                                        <span className="lead">Cost of living in City </span>
                                    </div>
                                    <div className="content">
                                        <span className="lead"> Average Tuition Fees </span>
                                    </div>
                                    <div className="content" id="admChances">
                                        <span className="lead">Admission Chances</span>
                                    </div>
                                </div>
                                <div className="col-md-9 col-sm-9 col-8">
                                    <div className="mobile-scroll h-100">
                                        <div className="row">
                                            <input type="hidden" className="sessionuniId" name="Ids[]" value="549" autoComplete="off" />
                                            <input type="hidden" className="ctryId-549" name="ctryIds[]" value="36" autoComplete="off" />
                                            <div className="col-sm-4 col-4">
                                                <div className=" block collegeBlock firstCol">
                                                    <div className="heading pos-rel">
                                                        <a href="javascript:void(0);" className="btn-close btn-close-white btn-close-small">
                                                            <i className="fa fa-times" aria-hidden="true"></i>
                                                        </a>
                                                        <i className="icon icon-checked"></i>
                                                        <h6 className="mb-1 d-flex">
                                                            <small id="tag-549" className="me-2">
                                                                <span
                                                                    style={{ cursor: 'pointer' }}
                                                                    className="compare_add"
                                                                    data-toggle="tooltip"
                                                                    title=""
                                                                    data-original-title="Add to shortlist"
                                                                    data-bs-original-title="">
                                                                    <i className="fa fa-check-circle fa-lg"></i>
                                                                </span>
                                                            </small>
                                                            <span className="flex-1 d-flex flex-column">
                                                                <span className="font-small font-18 font-500">University of Toronto</span>
                                                                <span className="font-small font-14">Toronto, Ontario, Canada</span>
                                                            </span>
                                                        </h6>
                                                    </div>
                                                    <div className="content">
                                                        <span>1827</span>
                                                    </div>
                                                    <div className="content">
                                                        <span>
                                                            <a href="/college/University-of-Toronto/731845d5#academics" target="_blank">
                                                                187
                                                            </a>
                                                        </span>
                                                    </div>
                                                    <div className="content">
                                                        <span>Public</span>
                                                    </div>
                                                    <div className="content">
                                                        <span>180</span>
                                                    </div>
                                                    <div className="content">
                                                        <span>Semester</span>
                                                    </div>
                                                    <div className="content ranking">
                                                        <span>#29-QS Survey-2020 </span>
                                                        <span> --- </span>
                                                    </div>
                                                    <div className="content">
                                                        <span>88,700</span>
                                                    </div>
                                                    <div className="content">
                                                        <span>
                                                            <a
                                                                href="/app/../online-scholarship-search/uni1/549/study_level/6/show/1"
                                                                target="_blank">
                                                                22
                                                            </a>
                                                        </span>
                                                    </div>
                                                    <div className="content">
                                                        <span> --</span>
                                                    </div>
                                                    <div className="content">
                                                        <span>--</span>
                                                    </div>
                                                    <div className="content">
                                                        <span>$ 18,000</span>
                                                    </div>
                                                    <div className="content">
                                                        <span>$ 9,000</span>
                                                    </div>
                                                    <div className="content">
                                                        <span>$ 46,214 / Year</span>
                                                    </div>
                                                    <div className="content admChanceDiv" style={{ display: 'block' }}>
                                                        <span id="admChance-549" className="loadDefault">
                                                            Click on 'Check Chances' below
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* More university entries can be added similarly */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main flex-1'>
                <div className='max-1140 mx-auto d-flex flex-column h-100'>
                    <div className="m-w-90 d-flex flex-column justify-content-between  pt-5 mb-3">
                        <h3  className="font-35 font-600 mb-0 block-title aos-init aos-animate">Search for colleges</h3>
                        <p className="font-18 font-500 mb-2">Use the college finder tool to find the best colleges! Choose from the following options.</p>
                    </div>   

                    <div className="job_internship_tab polartabsec bg-white radius-6">
                        <div className="xscroll p-0 pb-2 pb-md-0">
                            <ul className="nav nav-tabs aos-init aos-animate" id="JobInternshipTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button onClick={()=>topCollegeFilter(1)} className="nav-link active" id="topColleges-tab" data-bs-toggle="tab" data-bs-target="#topColleges" type="button" role="tab" aria-controls="topColleges" aria-selected="false">Top Colleges</button>
                                </li>
                                { <li className="nav-item" role="presentation">
                                    <button onClick={()=>topCollegeFilter(2)} className="nav-link" id="collegeSearch-tab" data-bs-toggle="tab" data-bs-target="#collegeSearch" type="button" role="tab" aria-controls="collegeSearch" aria-selected="true">College Search</button>
                                </li>
                                 }
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="compareColleges-tab" data-bs-toggle="tab" data-bs-target="#compareColleges" type="button" role="tab" aria-controls="compareColleges" aria-selected="false">Compare Colleges</button>
                                </li>                                   
                            </ul>
                        </div>
                    </div> 

                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade active show" id="topColleges" role="tabpanel" aria-labelledby="topColleges-tab">
                           <TopCollegesTab hasRanking="1"/>
                        </div>
                        { <div className="tab-pane fade" id="collegeSearch" role="tabpanel" aria-labelledby="collegeSearch-tab">
                           <TopSearchTab hasRanking="2"/>
                        </div> }
                        <div className="tab-pane fade" id="compareColleges" role="tabpanel" aria-labelledby="compareColleges-tab">
                            <CompareColleges/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopCollegesLayout;
