import { reportConfig } from './report-config';

class AlumniConversionsService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
            state.formConfig = [];
            state.data = {
                alumniList: [],
                searchCriteria: [],
                filterDomains: [],
                reportConfig: reportConfig[params.pageName],
            };
            state.routeInfo = params;
            state.internal = { gridState: {} };
            return Promise.resolve(state);
    }
}

export default AlumniConversionsService;
