import '../../../styles/css/alumni.css';
import { useNavigate } from 'react-router-dom';
const schoolSettings = localStorage.getItem('school_settings');
const parsedSchoolSettings = schoolSettings ? JSON.parse(schoolSettings) : null;
const schoolName = parsedSchoolSettings?.schoolName ?? '';
const dummy_female=`${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_female.svg`;
const dummy_male=`${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_male.svg`;

const AlumniVerifyLayout = (props: any) => {
    const navigate = useNavigate();
return (
    <div className="main flex-1 bg-red">
        {/* <!-- BASIC SECTION --> */}
        <section className="pt-3 pt-sm-5 pb-3" id="alumni_directory">
            <div className="container max-1140 px-lg-0">
                {/* <!-- Need to set this were we want the alert div --> */}
                                        
                                                
                <div className="row alumni-directory">
                    <div className="col-lg-12 d-flex flex-wrap align-items-center mb-2">
                        <a href="/alumni/directory" className="btn-back font-16 font-500 text-white"><i className="icon-left-arrow font-16 position-relative me-1"></i> <span>Back to Directory1111</span></a>
                    </div>
                    <div className="row">
                        <div className="flex-1">
                            <div className="basicInformation bg-red px-0 ps-md-5 py-2 py-md-4 position-relative">
                                <div className="row">
                                    <div className="col-md d-flex mb-3 mb-lg-0">
                                        <div id="profile_progress">
                                            <figure className="mb-0">
                                                <span className="d-inline-block position-relative profile_pic"><img src={(props.alumni.image)?props.alumni.image:(props.alumni.gender == "MALE")?dummy_male:dummy_female} className="rounded-circle" /></span>
                                                <em><i className="fa fa-pencil" aria-hidden="true"></i></em>
                                            </figure>
                                            <div className="profileLinks">
                                                <a href="javascript:;" className="btn-remove">Remove Picture</a>
                                                <a href="javascript:;" className="btn-upload">Upload New Picture</a>
                                            </div>
                                        </div>
                                        <div className="profilenameclass">
                                            <div className="profilename text-white font-16 mt-2">{props.alumni.first_name} {props.alumni.last_name} </div>
                                            <div className="class text-white font-14 fst-italic">Class of {props.alumni.passoutYear}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        {/* <p className="font-16 text-white mb-4">
                                            Taking Gap Year                                            
                                        </p> */}
                                    </div>
                                    <div className="col-md-6 mb-3 mb-md-0">
                                        <div className="w-100 d-flex flex-wrap">
                                            <div className="phone_number d-flex flex-column me-3">
                                                <label className="font-14 fst-italic text-gray-e1">Contact Number</label>
                                                <span className="font-16 text-white">{(props.alumni.mobile_country_code)?'+':''}{props.alumni.mobile_country_code} {props.alumni.mobile_number}</span>
                                            </div>
                                                                                        
                                                                                    </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="w-100 d-flex flex-wrap align-items-center">
                                            <div className="phone_number d-flex flex-column contemail me-3">
                                                <label className="font-14 fst-italic text-gray-e1">Contact Email</label>
                                                <span className="font-16 text-white"><a className="text-white text-decoration-none" href="mailto:{props.alumni.registered_email_id}">{props.alumni.registered_email_id}</a></span>
                                            </div>
                                            {/* <a className="btn-greenm btnverified" href="javascript:;"><i className="icon-varified text-white font-16 me-1"></i>Verified</a> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 d-flex flex-wrap justify-content-start justify-content-lg-center justify-content-sm-start justify-content-md-start align-items-center align-items-md-start align-items-sm-start align-items-lg-end flex-row flex-lg-column  px-3 px-md-5 pb-3 pb-mb-0">
                            <a href="javascript:;" className="btn btn-white mt-0 mb-3 max-200 height-40 me-2 me-lg-0 font-16 font-500" onClick={()=>{props.VerifyAlumni(props.alumni.uid,"APPROVE",navigate)}}><span>Yes, I know this person!</span></a>
                            <a href="javascript:;" className="btn btn-white max-200 mt-0 height-40 font-16 font-500" onClick={()=>{props.VerifyAlumni(props.alumni.uid,"DISCARD",navigate)}}><span>Not Sure</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
    );
};

export default AlumniVerifyLayout;