import { DispatchEvent } from './SmartTypes';

function sessionReducer(state: any, action: DispatchEvent) {
    switch (action.type) {
        case 'SET':
            return { ...state, ...action.payload };
        default:
            return { ...state, ...action.payload };
    }
}

export default sessionReducer;
