import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { isEmpty } from '../../../../library/Core/SmartFunctions';
import GraphComponent from '../../../../library/SimpleControls/GraphControl';

const DashboardReportControl = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const [reportData, setReportData] = useState([] as any);

    // const schoolFoundingYear = parseInt((sessionStorage.getItem('school-founding-year') as string) ?? 2000);
    // const passOutYear = parseInt((sessionStorage.getItem('pass-out-year') as string) ?? 2020);
    const loggedInUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    console.log(loggedInUser.id);
    const schoolId = loggedInUser?.instituteId as string;
    const schoolGroupId = loggedInUser?.instituteGroupId as string;
    const schoolFoundingYear = parseInt((loggedInUser?.instituteFoundingYear as string) ?? 2000);
    const passOutYear = parseInt((loggedInUser?.passOutYear as string) ?? 2000);

    // const schoolId = sessionStorage.getItem('user-school-id') as string;
    // const schoolGroupId = sessionStorage.getItem('user-school-group-id') as string;

    const schoolDomainName = props.schoolDomainName;
    const schoolGroupDomainName = props.schoolGroupDomainName;
    const reportName = props.reportName;
    const domainName = props.domainName;

    const [settings, setSettings] = useState({
        campus: 'MY_CAMPUS',
        documentType: schoolDomainName,
        documentId: schoolId,
        // batch: passOutYear,
        batch: 0,
        data: [],
    } as any);

    useEffect(() => {
        const newData = refresh(settings);
        setReportData(newData);
    }, [settings]);

    const handleCampusChange = (event: any) => {
        const newSettings = {
            ...settings,
            campus: event.target.value,
            documentType: event.target.value === 'MY_CAMPUS' ? schoolDomainName : schoolGroupDomainName,
            documentId: event.target.value === 'MY_CAMPUS' ? schoolId : schoolGroupId,
        };
        setSettings(newSettings);
    };

    const handleBatchChange = (event: any) => {
        const newSettings = { ...settings, batch: event.target.value };
        setSettings(newSettings);
    };

    const refresh = (settings: any) => {
        const report: any = state.data?.documents.filter(
            (document: any) =>
                document.documentType === settings.documentType &&
                document.documentId == settings.documentId &&
                document.documentSubId == settings.batch
        )[0];

        const domainForField = state.domain.get(domainName);
        const data = report?.document
            ?.filter((item: any) => !isEmpty(item.code))
            .map((item: any) => ({
                key: domainForField?.find((domain) => domain.code == item.code)?.value,
                value: item.count,
            }));

        return data;
    };

    const findMaxCountInReport = (doc: any) => doc?.reduce((max: number, item: any) => Math.max(max, item.value || 0), 0);

    return (
        <div className="row mb-2 mb-sm-4">
            <div className="col-md-12">
                <div className="white-block pb-3 h-100 aos-init aos-animate" data-aos="fade-up">
                    <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
                        <h3 className="font-22 font-600 mb-3 mb-lg-0 mb-mb-0 block-title">{reportName}</h3>
                        <div className="d-flex flex-wrap justify-content-end align-content-center max-480 mjcstart">
                            <select
                                className="form-select onboarding mb-3 ms-1 mb-lg-0 mb-lg-0 me-2"
                                aria-label="Default select example"
                                onChange={handleCampusChange}
                                defaultValue={'MY_CAMPUS'}
                                id="SCampus">
                                <option value="MY_CAMPUS">My Campus</option>
                                <option value="MY_SCHOOL_GROUP">All Campus</option>
                            </select>
                            <select
                                className="form-select onboarding mb-3 mb-lg-0 SSelect select2-hidden-accessible"
                                id="SSelect"
                                data-select2-id="select2-data-SSelect"
                                tabIndex={-1}
                                onChange={handleBatchChange}
                                defaultValue={0}
                                aria-hidden="true">
                                <option key={0} value={0}>
                                    {'ALL'}
                                </option>
                                {Array.from(
                                    { length: new Date().getFullYear() - schoolFoundingYear + 1 },
                                    (_, index) => schoolFoundingYear + index
                                ).map((year) => (
                                    <option key={year} value={year}>{`${year} ${year == passOutYear ? ' (Your Batch)' : ''}`}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 presentCareer">
                            <GraphComponent
                                data={reportData ?? []}
                                maxCount={findMaxCountInReport(reportData)}
                                mapLowValue={5}
                                mapAdjustmentPercentage={5}
                                showAll={''}
                                color={props.color}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardReportControl;
