import moment from 'moment';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import { axiosClient } from '../../../library/Service/axiosClient';

const instituteListAction = () => {
    const getFormattedDate = (inDate: string) => {
        if (isEmpty(inDate)) return '';
        return moment(inDate).format('DD-MMM-YYYY');
    };

    const publishUnpublishInstitute = (instituteId: any,type:number) =>
    axiosClient().post(
        `${process.env.REACT_APP_ADMIN_API_SERVER}/institute/publishUnpublishInstitute`,
        { instituteId, type }
      );

    return { getFormattedDate, publishUnpublishInstitute };
};

export default instituteListAction;
