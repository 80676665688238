import { useContext, useEffect } from 'react';
import { DispatchEvent, State } from '../../../../../../library/Core/SmartTypes';
import { SmartContext } from '../../../../../../library/Core/SmartContext';
import PageBuilder from '../../../../../../library/Builders/PageBuilder/PageBuilder';
import EditDemographicssFormLayout from './EditDemographicssFormLayout';
import ProfileMarkerService from './ProfileMakerService';
import { isFormValid } from '../../../../../../library/Core/SmartFunctions';

const EditDemographicssModal = ({ userdata,DemographicsProps }: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { saveMarker,fetchUserProfileMarkers } = ProfileMarkerService();
    const handleSubmit = async (
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void
    ) => {
        event.preventDefault();
        const payload = {
            markerType: 'demographics',
            ...state?.data?.demographics,
        };

        if (!isFormValid(state, dispatch)) {
            dispatch({ type: 'SHOW_ERRORS' });
            return;
        }
        
        await saveMarker(payload, state?.internal?.model?.id);
        await fetchUserProfileMarkers(state?.internal?.model?.id);
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showDemoGraphicsModal' } });
    };

    useEffect(() => {
        dispatch({ type: 'ADD_ACTIONS', payload: { ...state.actions, SAVE: handleSubmit } });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <EditDemographicssFormLayout>{!state.flags.isDataLoading && <PageBuilder />}</EditDemographicssFormLayout>
        </SmartContext.Provider>
    );
};

export default EditDemographicssModal;
