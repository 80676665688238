import { useContext, useState } from 'react';
import { SmartContext } from '../../library/Core/SmartContext';
import { DomainElement } from '../../library/Core/SmartTypes';
import useMessages from './useMessages';

const MessageListActionBar = () => {
    const { state, dispatch } = useContext(SmartContext);
    const [action, setAction] = useState('' as string);
    const { performAction, goToNextPage, goToPreviousPage, refreshConversations } = useMessages();
    const numberOfConversations = state.data?.conversations?.length || 0;

    const handleAction = () => {
        const selectedConversationIds = state.data.conversations
            .filter((conversation: any) => conversation.action)
            .map((conversation: any) => conversation.id);

        if (selectedConversationIds.length === 0) return;
        performAction(action, selectedConversationIds);
    };

    const handleActionSelect = (event: any) => setAction(event.target.value);

    const handleRefresh = () => {
        refreshConversations();
    };

    const handleSelectAll = (event: any) => {
        state.data.conversations.forEach((conversation: any, index: number) =>
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: `conversations.${index}.action`, value: event.target.checked, errorMessages: [] },
            })
        );

        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: `actions.selectAll`, value: event.target.checked },
        });
    };

    const handlePagination = (event: any, mode: string) => {
        event.preventDefault();
        mode === 'NEXT' ? goToNextPage() : goToPreviousPage();
    };

    return (
        <div className="msg_title msg_heading_row extmar pb-2 d-flex flex-wrap justify-content-between align-items-center position-relative">
            <div className="d-flex flex-wrap justify-content-start align-items-center me-2">
                <div className="form-check pb-2 d-flex align-items-center me-2">
                    <input
                        className="form-check-input me-2"
                        type="checkbox"
                        value=""
                        id="selectAll"
                        onChange={handleSelectAll}
                        checked={state.data.actions?.selectAll}
                    />
                    <label className="form-check-label pt-1 font-14 font-500 text-black" htmlFor="selectAll">
                        Select All
                    </label>
                </div>
                <div className="pb-2 d-flex justify-content-start align-items-center customselect_parnt">
                    {/* <div className="custom-select me-1 font-14 font-500 flex-1"> */}
                    <div>
                        <select className="select-selected" onChange={handleActionSelect}>
                            <option value="">--Select Action--</option>
                            {(state.domain?.get('MESSAGE_ACTION_TYPE_CODE') as DomainElement[]).map((action) => (
                                <option key={action.code} value={action.code}>
                                    {action.value}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button className="btn btn-complete-now ms-1" onClick={handleAction}>
                        <span>Apply</span>
                    </button>
                    <button className="btn btn-complete-now ms-1 bg-success" onClick={handleRefresh}>
                        <span>Refresh</span>
                    </button>
                </div>
            </div>
            {numberOfConversations > 0 && (
                <div className="d-flex pb-2 justify-content-start align-items-center selallarr mt-3 mt-sm-0">
                    <p className="font-14 font-500 text-black mb-0 me-3">
                        <span className="msg_start_count">{state.data.pagination.currentPage * state.data.pagination.pageSize + 1}</span>-
                        <span className="msg_end_count">
                            {(state.data.pagination.currentPage + 1) * state.data.pagination.pageSize < numberOfConversations
                                ? (state.data.pagination.currentPage + 1) * state.data.pagination.pageSize
                                : numberOfConversations}
                        </span>{' '}
                        of
                        <span className="msg_total_count"> {numberOfConversations}</span>
                    </p>
                    <div className="msg_lt_rt">
                        <a href="" className="btn msg-lt me-1" role="button" onClick={(event) => handlePagination(event, 'PREV')}>
                            <i className="icon-left-arrow"></i>
                        </a>
                        <a
                            href=""
                            className={
                                'btn msg-rt' +
                                ((state.data.pagination.currentPage + 1) * state.data.pagination.pageSize >= numberOfConversations
                                    ? ' disabled'
                                    : '')
                            }
                            role="button"
                            onClick={(event) => {
                                if ((state.data.pagination.currentPage + 1) * state.data.pagination.pageSize < numberOfConversations)
                                    handlePagination(event, 'NEXT');
                            }}>
                            <i className="icon-right-arrow"></i>
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MessageListActionBar;
