import React, { useContext } from 'react';
import { SmartContext } from '../../../../../../library/Core/SmartContext';

const EventDetailsModal = ({ eventData, eventType }: { eventData: any[], eventType: string }) => {
    const { state, dispatch } = useContext(SmartContext);
    const showFlag = state?.modalFlags?.showAffinityEventsModal;

    const typeField = eventType === 'networkingEvents' ? 'networkingEventsType' : 'lAndDEventsType';
    const title = eventType === 'networkingEvents' ? 'Networking Events' : 'L&D Events';
    const filePathField = eventType === 'networkingEvents' ? 'networkingEventsFilePath' : 'lAndDEventsFilePath';

    const eventTypeCounts = eventData.reduce((acc: Record<string, number>, event: any) => {
        const type = event[typeField];
        if (type) {
            acc[type] = (acc[type] || 0) + 1;
        }
        return acc;
    }, {});

    const hasFilesToDownload = eventData.some(event => event[filePathField]);

    const handleDownload = (e: React.MouseEvent) => {
        e.preventDefault();
        eventData.forEach(event => {
            if (event[filePathField]) {
                window.open(event[filePathField], '_blank');
            }
        });
    };

    return (
        <div
            id="update-affinity-data"
            className={`modal fade ${showFlag ? 'show' : ''}`}
            tabIndex={-1}
            role="dialog"
            style={{ display: showFlag ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-dialog-centered max-800 mx-auto">
                <div className="modal-content">
                    <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
                        <h5 className="modal-title" id="staticBackdropLabel">{title}</h5>
                        <button
                            type="button"
                            className="btn-close btn-close-small"
                            onClick={() => {
                                dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showAffinityEventsModal' } });
                            }}
                            aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <p>
                            {Object.entries(eventTypeCounts).map(([eventType, count]) => (
                                <span key={eventType}>
                                    {count} {eventType}
                                    <br/>
                                </span>
                            ))}
                        </p>
                        {hasFilesToDownload && (
                            <p>
                                <a href="#" onClick={handleDownload}>Download</a> Uploaded Files
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventDetailsModal;
