import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty } from '../../../library/Core/SmartFunctions';

const SelectUserModal = ({ data }: any) => {
    const { state, dispatch } = useContext(SmartContext);

    const [searchInput, setSearchInput] = useState<string>('');
    const { alumniData, selectedSendingReasonValue, adminUsers, handlePreviewEmailPopup, alumni, getSentHistory } = data;
    const showFlag = state?.modalFlags?.showUserModal;
    console.log('state?.modalFlags in user');
    console.log(state?.modalFlags);
    const maskEmail = (email: string) => {
        const atIndex = email.indexOf('@');
        if (atIndex !== -1) {
            return email[0] + email.substring(1, atIndex).replace(/./g, 'X') + email.substring(atIndex);
        }
        return email; // Handle the case where '@' is not found, e.g., for invalid email addresses
    };

    const maskMobile = (country_code: number | null, mobile: string) => {
        if (mobile !== null && !isEmpty(mobile)) {
            if (country_code !== null) {
                return `+${country_code} ${'X'.repeat(mobile.length - 4)}${mobile.slice(-4)}`;
            }
            return `${'X'.repeat(mobile.length - 4)}${mobile.slice(-4)}`;
        }
        return mobile;
    };

    const filterTable = (user: any) => {
        const fullName = `${user.first_name} ${user.last_name}`;
        const email = user.registered_email_id;
        const mobile = `${user.mobile_country_code} ${user.mobile_number}`;
        return (
            fullName.toLowerCase().includes(searchInput.toLowerCase()) ||
            email.toLowerCase().includes(searchInput.toLowerCase()) ||
            mobile.includes(searchInput) // You can adjust this part to match your specific mobile format
        );
    };

    type UserItem = {
        uid: any;
        mobile_number: any;
        registered_email_id: any;
        is_internal_user: any;
        mobile_country_code: any;
    };
    const [selectedItems, setSelectedItems] = useState<UserItem[]>([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    useEffect(() => {
        setSelectedItems([]);
        setSelectAllChecked(false);
    }, []);

    const handleSelectAll = (event: any) => {
        const isChecked = event.target.checked;
        setSelectAllChecked(isChecked); // Update the "Select All" checkbox state

        let selectedUsersArray = [];

        if (isChecked) {
            selectedUsersArray = adminUsers
                .filter((user: UserItem) => user.is_internal_user !== 1)
                .map((user: UserItem) => ({
                    uid: user.uid,
                    is_internal_user: user.is_internal_user,
                    mobile_number: user.mobile_number,
                    registered_email_id: user.registered_email_id,
                    mobile_country_code: user.mobile_country_code,
                }));
        }

        setSelectedItems(selectedUsersArray);
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, item: UserItem) => {
        const updatedSelectedItems = [...selectedItems];
        const index = updatedSelectedItems.findIndex((selectedItem) => selectedItem.uid === item.uid);

        if (e.target.checked) {
            if (index === -1) {
                updatedSelectedItems.push({
                    uid: item.uid,
                    is_internal_user: item.is_internal_user,
                    mobile_number: item.mobile_number,
                    registered_email_id: item.registered_email_id,
                    mobile_country_code: item.mobile_country_code,
                });
            }
        } else {
            if (index !== -1) {
                updatedSelectedItems.splice(index, 1);
            }
        }

        setSelectedItems(updatedSelectedItems);
    };

    function handleEmailPreviewClick() {
        if (selectedItems && selectedItems.length > 0) {
            handlePreviewEmailPopup(selectedItems);
        } else {
            alert('Select at least one user.');
        }
    }

    useEffect(() => {
        setSelectedItems([]);
        setSelectAllChecked(false);
    }, [adminUsers]);

    useEffect(() => {
        const modalElement = document.getElementById('select-user-popup');
        const handleCloseModal = () => {
            setSelectedItems([]);
            setSelectAllChecked(false);
        };

        if (modalElement) {
            modalElement.addEventListener('hidden.bs.modal', handleCloseModal);
        }

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('hidden.bs.modal', handleCloseModal);
            }
        };
    }, []);

    return (
        // <ErrorBoundary fallbackRender={handleNavigateToHome} onReset={(details) => {}} >
        <div
            id="select-user-popup"
            className={`modal fade ${showFlag ? 'show' : ''}`} // removed vh-100 and show class
            tabIndex={-1}
            role="dialog"
            style={{ display: showFlag ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            {/* <div className="modal fade px-3" id="select-user-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" style={{ display: 'none' }} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered max-800 mx-auto"> */}
            <div className="modal-dialog modal-dialog-centered max-800 mx-auto">
                <div className="modal-content">
                    <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
                        <div className="d-flex flex-wrap">
                            <a
                                onClick={() => {
                                    dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showEmailModal' } });
                                }}
                                href="javascript:;"
                                data-bs-dismiss="modal"
                                className="fa fa-arrow-circle-left icon icon-left-arrow mt-1 me-2 go-back text-black font-20 text-decoration-none"></a>

                            <h5 className="modal-title flex-1" id="staticBackdropLabel">
                                <span>Send Profile</span> <span>&gt; </span><i className="fa fa-angle-right"></i> <span>Select users</span>
                            </h5>
                        </div>
                        <button
                            type="button"
                            className="btn-close btn-close-small"
                            onClick={() => {
                                dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showUserModal' } });
                            }}
                            aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <form className="lstCustForm needs-validation" noValidate>
                            <div className="row g-4">
                                <div className="col-md-4 col-sm-6">
                                    <label className="name">Name</label>
                                    <input
                                        type="text"
                                        className="form-control flex-1"
                                        id="alumni_name"
                                        value={alumniData?.AlumniName}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <label className="name">Mobile</label>
                                    <input
                                        type="text"
                                        className="form-control flex-1"
                                        id="alumni_mobile"
                                        value={alumniData?.AlumniMobile}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group mb-0">
                                        <label className="form-label">Last Sent</label>
                                        <div className="form-last-sent">
                                            {alumniData?.LastSentDate ? (
                                                <>
                                                    <a href="javascript:;" onClick={() => getSentHistory(alumni)}>
                                                        {alumniData?.ProfileSendUsers.length} users
                                                    </a>{' '}
                                                    on {moment(alumniData?.LastSentDate).format('DD-MMM-YYYY')}
                                                </>
                                            ) : (
                                                <span>NA</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <label className="name">User ID</label>
                                    <input
                                        type="text"
                                        className="form-control flex-1"
                                        id="alumni_user_id"
                                        value={alumniData?.AlumniUserId}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <label className="name">Email</label>
                                    <input
                                        type="text"
                                        className="form-control flex-1"
                                        id="alumni_email"
                                        value={alumniData?.AlumniEmail}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <label className="name">Reason of sending</label>
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        value={selectedSendingReasonValue}
                                        disabled>
                                        <option>{selectedSendingReasonValue}</option>
                                    </select>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="d-flex align-items-center h-100">
                                        <input
                                            type="checkbox"
                                            value=""
                                            id="Select_all"
                                            style={{ position: 'static' }}
                                            className="form-check-input mt-0 ms-0 me-2"
                                            onChange={handleSelectAll}
                                            checked={selectAllChecked}
                                        />
                                        <label htmlFor="Select_all" className="mb-0">
                                            Select all external users
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md col-sm">
                                    <input
                                        type="text"
                                        className="form-control form-search-control border-radius-25"
                                        id="Search"
                                        placeholder="Search by name, email ID, mobile"
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)} // Update searchInput state
                                    />
                                </div>
                                <div className="col-md-12 mb-4">
                                    <div className="view-vertical">
                                        <div className="table-responsive">
                                            <table className="table w-750" style={{ minWidth: '750px' }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ width: '220px' }}>
                                                            Name
                                                        </th>
                                                        <th scope="col" style={{ width: '250px' }}>
                                                            Designation
                                                        </th>
                                                        <th scope="col">Contact Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(adminUsers) &&
                                                        adminUsers.filter(filterTable).map((item: any, index) => (
                                                            <tr key={item.id || index}>
                                                                <td>
                                                                    <div className="d_user_name d-flex flex-wrap">
                                                                        <input
                                                                            className="form-check-input ms-0 me-2"
                                                                            type="checkbox"
                                                                            id={item.uid}
                                                                            value={item.uid}
                                                                            checked={selectedItems.some(
                                                                                (selectedItem) => selectedItem.uid === item.uid
                                                                            )}
                                                                            onChange={(e) => handleCheckboxChange(e, item)}
                                                                        />
                                                                        <label htmlFor={item.uid} className="flex-1 flex-column d-flex">
                                                                            {item.first_name} {item.last_name}
                                                                            {item.is_internal_user === 1 ? (
                                                                                <span className="font-14 text-gray-41 fst-italic flex-1 ps-0">
                                                                                    (Internal User)
                                                                                </span>
                                                                            ) : null}
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                                <td>{item?.designation}</td>
                                                                <td>
                                                                    <div className="td_contact_detail">
                                                                        <div className="td_contact_detail_email">
                                                                            <label>Email</label> - {maskEmail(item.registered_email_id)}
                                                                        </div>
                                                                        <div className="td_contact_detail_phone">
                                                                            <label>Mobile</label> -{' '}
                                                                            {maskMobile(item.mobile_country_code, item.mobile_number)}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center align-items-center">
                                {/* Your buttons here */}
                                <div className="col-auto">
                                    <button type="button" className="btn btn-green max-135" onClick={() => handleEmailPreviewClick()}>
                                        <span>Next</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        // </ErrorBoundary>
    );
};

export default SelectUserModal;
