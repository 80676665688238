export enum Column {
    CAMPUS = 'CAMPUS',
    CURRICULUM = 'CURRICULUM',
    COURSE = 'COURSE',
    ENGAGEMENTS = 'ENGAGEMENTS',
    LAST_INTERACTION = 'LAST_INTERACTION',
    UNIVERSITY = 'UNIVERSITY',
    PREF_CAREER = 'CAREER',
    PREF_COUNTRY = 'COUNTRY',
    PREF_COLLEGE = 'COLLEGE',
    PRESENT_STATUS = 'PRESENT_STATUS',
    LOCATION = 'LOCATION',
    CREATED_ON = 'CREATED_ON',
    MODIFIED_ON = 'MODIFIED_ON',
    ACTION = 'ACTION',
}

export enum Action {
    ACTIVATE = 'Activate',
    DEACTIVATE = 'Deactivate',
    SEND_MESSAGE = 'Send Message',
    MARK_AS_NOTABLE = 'Mark as Notable',
    ADD_NOTES = 'Add Notes',
    VIEW_NOTES = 'View Notes',
    SEND_INVITATION = 'Send Invitation',
    SEND_PROFILE = 'Send Profile',
    VIEW_SENT_HISTORY = 'View Sent History',
}

export const reportConfig: any = {
    'track-student-profiles': {
        gridColumns: [Column.CAMPUS, Column.CURRICULUM, Column.CREATED_ON, Column.MODIFIED_ON, Column.ACTION],
        actions: [Action.ACTIVATE, Action.DEACTIVATE, Action.SEND_MESSAGE],
        fuzzySearchPlaceholder: 'Search by Student Name',
    },
    'track-student-career-preferences': {
        gridColumns: [Column.CAMPUS, Column.CURRICULUM, Column.PREF_CAREER, Column.PREF_COUNTRY, Column.PREF_COLLEGE, Column.ACTION],
        actions: [Action.ADD_NOTES, Action.SEND_MESSAGE],
        fuzzySearchPlaceholder: 'Search by Student Name',
    },
    'track-alumni-profiles': {
        gridColumns: [Column.CAMPUS, Column.PRESENT_STATUS, Column.LOCATION, Column.CREATED_ON, Column.ACTION],
        actions: [Action.ACTIVATE, Action.DEACTIVATE, Action.SEND_MESSAGE, Action.MARK_AS_NOTABLE],
        fuzzySearchPlaceholder: 'Search by Alumnus Name',
    },
    'track-alumni-education-preferences': {
        gridColumns: [Column.CAMPUS, Column.PRESENT_STATUS, Column.COURSE, Column.UNIVERSITY, Column.ACTION],
        actions: [Action.SEND_MESSAGE],
        fuzzySearchPlaceholder: 'Search by Alumnus Name',
    },
    'track-alumni-engagement-with-students': {
        gridColumns: [Column.CAMPUS, Column.PRESENT_STATUS, Column.ENGAGEMENTS, Column.LAST_INTERACTION, Column.ACTION],
        actions: [Action.SEND_MESSAGE],
        fuzzySearchPlaceholder: 'Search by Alumnus Name',
    },
    'track-alumni-highlights-with-students-engagement': {
        gridColumns: [Column.CAMPUS, Column.PRESENT_STATUS, Column.ENGAGEMENTS, Column.LAST_INTERACTION, Column.ACTION,Column.COURSE],
        actions: [Action.SEND_PROFILE, Action.VIEW_SENT_HISTORY],
        fuzzySearchPlaceholder: 'Search by Alumnus Name',
    },
};
