import { useContext,useEffect, useState } from 'react';
import Select from 'react-select';
import { SmartContext } from '../../library/Core/SmartContext';
import ImgCollegeLogo from '../../styles/images/university_melbourne.jpg';
import CollegeService from './CollegeService';
import { useLoaderData } from 'react-router-dom';
import { State } from '../../library/Core/SmartTypes';
import { GlobalContext } from '../../library/Core/GlobalContext';


const itemsPerPage = 10; // Number of items to display per page

const PaginationSearchCollege = ({ result , search , fetchDepartment , fetchCourse , fetchCountry , fetchState , fetchCity , hasRanking, loading , onLoaderChange }:any) => {

    const { state, dispatch } = useContext(SmartContext);
    const { formConfig, data, internal } = useLoaderData() as State;
    const { globalState } = useContext(GlobalContext);
  
    const [currentPage, setCurrentPage] = useState(1);
    const [PageNo, setPageNo] = useState(1);
    const [paginationOffset, setPaginationOffset] = useState<number>(0);
    const [originalVal, setOriginalVal] = useState('');

  // Function to handle prev and next button
  const handlePageChange = (pageNumber:any) => {
    setCurrentPage(pageNumber);
    
    // Set Active page status
    var pageActive =(pageNumber -1) * itemsPerPage;
    pageActive = pageActive + 1;
    setPageNo(pageActive);
    
    // Set offset value  
    const offset = (pageNumber-1) * 100;
    setPaginationOffset(offset);
    setOriginalVal(offset.toString());

  };

  const handleDataChange = (pageNumber:any) => {
    const offset = (pageNumber-1) * itemsPerPage;
    // console.log('pageNumber');
    // console.log(pageNumber);
    setPageNo(pageNumber);
    setPaginationOffset(offset);
    setOriginalVal(offset.toString());
  };

  useEffect(() => {
    if(originalVal.length>0){
      //  onLoaderChange(true);
       topCollegeFilter();
    }
  }, [originalVal]);


  function topCollegeFilter(): Promise<any> {
    // const state: any = {};
    dispatch({
        type: 'DATA_INIT',
        payload: { formConfig,data, domain: globalState.domain, internal,flags:{isDataLoading:true} },
    });

    var topCollegeUrl = `?has_ranking=${hasRanking}&search=${search}&course_id=${fetchDepartment}&sub_course_id=${fetchCourse}&country_id=${fetchCountry}&state_id=${fetchState}&city_id=${fetchCity}&offset=${paginationOffset}&&limit=10`;
  
    return Promise.all([
        CollegeService.getTopCollegeFilter(topCollegeUrl),
    ]).then((response) => {
      // onLoaderChange(false);
    

        const result = response[0]?.data.data;
        
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig,
                       data:{...data,topCollegeList: response[0]?.data.data ?? []}, 
                       domain: globalState.domain,
                       internal,
                       flags:{isDataLoading:false} },
         });
        

        return Promise.resolve(state);

    });
}

// const totalPages = result.total_count;

// Example array for pagination
const [items, setItems] = useState(Array.from({ length: result.total_pages }, (_, i) => i + 1));
const itemsPerPage = 10;
const prevData = (items.length / 10);


// Calculate current page items
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
  
// console.log(currentPage);
// console.log(currentItems);

// Define variables before the return statement

const paginationItems = currentItems.map((item, index) => {
 
    const isActive = PageNo === item;
    const className = isActive ? "page-item active" : "page-item";

    return (
      <li key={index} className={className}>
        <div role="button" onClick={() => handleDataChange(item)} aria-label={"Page " + item}>
          {item}
        </div>
      </li>
    );
  });

  return (
    <div className="pagination-div mt-4">
      <ul className="pagination" role="navigation" aria-label="Pagination">
        <li className="previous">
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          </button>
        </li>

        {paginationItems}

        <li className="next">
          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage > prevData}>
          </button>
        </li>
      </ul>
    </div>
  );
   
  

  

};

export default PaginationSearchCollege;
