import { useContext } from 'react';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { getYear } from '../../profile-edit/benefits/BenefitsActions';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { evaluateExpression, getDomainValueForCode, isEmpty, isSchoolAdmin } from '../../../../library/Core/SmartFunctions';
import { useNavigate } from 'react-router-dom';

const ProfileBenefits = () => {
    const { state } = useContext(SmartContext);
    const { globalState } = useContext(GlobalContext);
    let model = { ...state.internal.model };
    const navigate = useNavigate();

    const passOutYear = state.data?.profile?.passOutYear as number;
    const tookGap = state.data?.profile?.didYouTakeGapAfter12;
    const presentStatus = tookGap ? 'TOOK_GAP' : (state.data?.profile?.educationOrEmploymentCurrentStatusCode as string);

    const isVisible = (question: any) => {
        if (isEmpty(question.preCondition)) return true;
        if (question.preCondition) return evaluateExpression(question.preCondition as string, state.data, state.data.benefits);
        return false;
    };

    const getNetworking = (batchYear: number, presentStatus: string) => {
        const networking = globalState?.appSettings?.benefitsConfig;
        const network = networking.filter(
            (item: any) =>
                batchYear >= getYear(item.fromYear) &&
                batchYear <= getYear(item.toYear) &&
                item.presentStatusCode.includes(presentStatus) &&
                item.nextMoveTypeCode === 'NETWORKING'
        );
        return network;
    };

    const getCareer = (batchYear: number, presentStatus: string) => {
        const careers = globalState?.appSettings?.benefitsConfig;

        const career = careers.filter(
            (item: any) =>
                batchYear >= getYear(item.fromYear) &&
                batchYear <= getYear(item.toYear) &&
                item.presentStatusCode.includes(presentStatus) &&
                item.nextMoveTypeCode === 'CAREER_SUPPORT'
        );
        return career;
    };

    let selectedCourses = "";
    if (state?.data?.benefits?.filtersCourse[0]?.filtersCourse !== "") {
        const courseValues = state?.data?.benefits?.filtersCourse.map((item: any) => {
            return getDomainValueForCode(item?.filtersCourse, 'FIELD_OF_STUDY_TYPE_CODE', state);
        });
        selectedCourses = courseValues.join(', ');
    }

    let selectedCountries = "";
    if (state?.data?.benefits?.filtersCountry[0]?.filtersCountry !== "") {
        const countriesValues = state?.data?.benefits?.filtersCountry.map((item: any) => {
            return getDomainValueForCode(item?.filtersCountry, 'COUNTRY_CODE', state);
        });
        selectedCountries = countriesValues.join(', ');
    }

    let selectedIndustries = "";
    if (state?.data?.benefits?.filtersIndustry[0]?.filtersIndustry !== "") {
        const industryValues = state?.data?.benefits?.filtersIndustry.map((item: any) => {
            return getDomainValueForCode(item?.filtersIndustry, 'INDUSTRY_CODE', state);
        });
        selectedIndustries = industryValues.join(', ');
    }

    let selectedFunctionalArea = "";
    if (state?.data?.benefits?.filtersFunctionalArea[0]?.filtersFunctionalArea !== "") {
        const functionalAreaValues = state?.data?.benefits?.filtersFunctionalArea.map((item: any) => {
            return getDomainValueForCode(item?.filtersFunctionalArea, 'FUNCTIONAL_AREA_CODE', state);
        });
        selectedFunctionalArea = functionalAreaValues.join(', ');
    }

    return (
        <div>
            <div className="tab-pane fade active show" id="pills-benefits" role="tabpanel" aria-labelledby="pills-benefits-tab">
                <div className="underGraduate-detail">
                    <div className="blockHeader">
                        <div className="row g-2  align-items-center">
                            <div className="col">
                                <div className="title">
                                    <i className="icon icon-brife-case"></i> <span> Benefits L3 </span>
                                </div>
                            </div>
                            {model.isEditable &&  !isSchoolAdmin() && (
                            <div className="col-auto">
                                <a role="button" onClick={() => navigate(`/alumni/${model.id}/benefits`)} className="btn-add-section">
                                    <b className="icon icon-edit me-2"></b> <span>EDIT</span>
                                </a>
                            </div>
                            )}
                        </div>
                    </div>

                    <div className="row g-3">
                        <div className="col-12">
                            <h3 className="subHeader d-flex align-items-center font-20">
                                <i className="icon-dollor me-2"></i>Networking and Alumni Pairing
                            </h3>

                            <div className="row g-4">
                            {!state?.flags.isDataLoading &&
                                getNetworking(passOutYear, presentStatus)?.map((item: any) => {
                                return (
                                    isVisible(item) && (
                                        <>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="d-flex flex-column line-height-24">
                                                <span className="font-16 font-400">
                                                    {item.question}
                                                </span>
                                                <span className="font-18 font-500">{state?.data?.benefits[item?.code] == true ? "Yes" : "No"}</span>
                                            </div>
                                        </div>
                                        </>
                                    )
                                );
                            })}

                                <div className="col-md-12">
                                    <h4 className="font-20 font-500">Select the criteria and choices to get pairing suggestions</h4>
                                    <div className="row g-4">

                                        <div className="col-md-3 col-sm-6">
                                            <div className="d-flex flex-column line-height-24">
                                                <span className="font-16 font-400"> Course Taken</span>
                                                <span className="font-18 font-500">{selectedCourses === "" ? "No" : "Yes - " + selectedCourses}</span>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-6">
                                            <div className="d-flex flex-column line-height-24">
                                                <span className="font-16 font-400">Present Country</span>
                                                <span className="font-18 font-500">{selectedCountries === "" ? "No" : "Yes - " + selectedCountries}</span>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-6">
                                            <div className="d-flex flex-column line-height-24">
                                                <span className="font-16 font-400">Industry</span>
                                                <span className="font-18 font-500">{selectedIndustries === "" ? "No" : "Yes - " + selectedIndustries}</span>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-6">
                                            <div className="d-flex flex-column line-height-24">
                                                <span className="font-16 font-400">Functional Area</span>
                                                <span className="font-18 font-500">{selectedFunctionalArea === "" ? "No" : "Yes - " + selectedFunctionalArea}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                    
                        </div>

                        <div className="col-12"></div>

                        <div className="col-12">
                            <h3 className="subHeader d-flex align-items-center font-20">
                                <i className="icon-goal me-2"></i> Events and Career Support
                            </h3>

                            <div className="row g-4">

                                {!state?.flags.isDataLoading &&
                                    getCareer(passOutYear, presentStatus)?.map((item: any) => {
                                    return (
                                        isVisible(item) && (
                                            <>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="d-flex flex-column line-height-24">
                                                    <span className="font-16 font-400">
                                                        {item.question}
                                                    </span>
                                                    <span className="font-18 font-500">{state?.data?.benefits[item?.code] == true ? "Yes" : "No"}</span>
                                                </div>
                                            </div>
                                            </>
                                        )
                                    );
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileBenefits;
