import * as XLSX from 'xlsx';
import { getDownloadFileName, isEmpty } from '../Core/SmartFunctions';

const columnConfig: any = {
    oldSystemUserId: 'System ID',
    firstName: 'First Name',
    lastName: 'Last Name',
    passOutYear: 'PassOut Year',
    genderCode: 'Gender',
    schoolName: 'Institute Name',
    workingStatusCode: 'Current Status',
    networkSimilarIndustryAlumni: 'Similar Profile Alumni',
    networkForSocialCausesVolunteer: 'Volunteering Work',
    networkForBusinessIdeasAndPartnerships: 'Business Partnerships',
    eventsNotifyJobReferrals: 'Notify Job Referrals',
    eventsNotifyInternships: 'NotifyInternships',
    eventsPursueUgDegree: 'Pursue Ug Degree',
    eventsPursuePgDegree: 'Pursue Pg Degree',
    eventsNeedHelpForUgApplication: 'Need Help For Ug Application',
    eventsNeedHelpForPgApplication: 'Need Help For Pg Application',
    eventsInterestedInUpskilling: 'Interested In Upskilling',
    eventsInterestedInEntrepreneurship: 'Interested In Entrepreneurship',
    eventsInterestedInRemoteWorking: 'Interested In Remote Working',
    // COnversation Rpeort
    conversationId:'conversation Id',
    createdAt:'Created At',
    createdBy:'Created By',
    updatedAt:'Updated At',
    updatedBy:'Updated By',
    senderId:'Sender UUID',
    senderFullName:'Sender Name',
    senderRegisteredEmailId:'Sender Email',
    senderProgramName:'Sender Program',
    senderInstituteName:'Sender Institute',
    // senderPassOutYear:'Sender Passout year',
    senderUserType:'Sender Type',
    message:'Message',
    receiverUUID:'Receiver UUID',
    receiverFullName:'Receiver Name',
    receiverEmailId:'Receiver Email',
    receiverProgramName:'Receiver Program',
    receiverInstituteName:'Receiver Institute',
    senderProfileAutologinlink:'sender_profile_autologinlink',
    receiverProfileAutologinlink:'receiver_profile_autologinlink'
};

const extractData = (data: any[], columns: string[]) => {
    // Filter and map the raw data to include only the specified columns
    let gridData = data.map((item) => {
        let filteredItem: { [key: string]: any } = {};
        columns.forEach((column) => {
            if (item.hasOwnProperty(column)) {
                filteredItem[columnConfig[column]] = item[column];
            }
        });
        return filteredItem;
    });

    // Create column headers based on the specified columns
    let columnHeaders = columns.map((column) => columnConfig[column] || column);

    return { gridData, columnHeaders };
};

const ExportToExcel = ({ rawData, columns, pageName }: { rawData: any; columns: any; pageName?: any }) => {
    if (isEmpty(columns)) return <></>;

    const { gridData, columnHeaders } = extractData(rawData, columns);
    const handleExport = () => {
        // Create a new worksheet with custom headers
        const worksheet = XLSX.utils.json_to_sheet([], { skipHeader: true });

        // Adding custom headers
        XLSX.utils.sheet_add_aoa(worksheet, [columnHeaders]);

        // Adding data
        XLSX.utils.sheet_add_json(worksheet, gridData, { origin: 'A2', skipHeader: true });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // You can customize the file name and extension (e.g., .xlsx)
        let downloadFileName = 'GridData';

        if ( ! isEmpty(pageName) ) {
            downloadFileName = getDownloadFileName(pageName, rawData);
        }
        XLSX.writeFile(workbook, `${downloadFileName}.xlsx`);
    };

    return (
        <button className="btn-update" onClick={handleExport}>
            Export to Excel
        </button>
    );
};

export default ExportToExcel;
