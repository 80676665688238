import { useContext, useEffect , useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import DebugBarControl from '../../library/SimpleControls/DebugBarControl';
import SessionContext from '../../library/Core/SessionContext';
import StudentAllQueries from './StudentAllQueries';
import StudentMyQueries from './StudentMyQueries';
import QnaApiService from './QnaApiService';
import { GlobalContext } from '../../library/Core/GlobalContext';

const StudentQna = () => {
    const { ids } = useParams();
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);
    const { formConfig, data, internal } = useLoaderData() as State;
    const { globalState } = useContext(GlobalContext);
    const userId = sessionState?.studentId ?? sessionState?.userId;
    // console.log(userId);
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        internal: { messagesFromCode: null, messageTypeCode: null, filteredMessages: [], searchText: null },
        actions: { ROUTER_NAVIGATE: navigate },
    } as any);

    const [studentCount, setStudentCount] = useState<number | undefined>(undefined);
    const [studentMYCount, setStudentMyCount] = useState<number | undefined>(undefined);
    const [activeTab, setActiveTab] = useState<string>('allQueries');
    const [selectedTime, setSelectedTime] = useState<string>('');
    const [selectedTag, setSelectedTag] = useState<string>('');
    const [tags, setTags] = useState<any[]>([]); 
    const instituteGroupId = sessionState?.instituteGroupId;
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    
    const handleAskQuestionClick = () => {
        navigate('/ask-question');
    };
    
    useEffect(() => {
        fetchTags();
        getStudentAllQuery({});
        getStudentMyQuery({});
    }, []);

    function getStudentAllQuery(searchVal: any): void {
        setLoading(true);
        setError(null);
        const params = `?selectedTime=${selectedTime}&selectedTag=${selectedTag}&instituteGroupId=${instituteGroupId}`;
        try {
            QnaApiService.getStudentAllQuery(params)
                .then((response) => {
                    const { questions } = response.data.data;

                    // Update component state using useImmerReducer dispatch
                    dispatch({
                        type: 'DATA_INIT',
                        payload: {
                            formConfig,
                            data: { ...data, studentAllQuery: questions },
                            domain: globalState.domain,
                            internal,
                            flags: { isDataLoading: false }
                        },
                    });

                    // Update studentCount
                    setStudentCount(questions.length);

                })
                .catch((error) => {
                    console.error('Error fetching student queries:', error);
                    // Handle error if needed
                });
        } catch (error) {
            console.error('Error fetching student queries:', error);
            setError('Failed to fetch queries. Please try again.');
        } finally {
            setLoading(false);
        }
    }

    function getStudentMyQuery(searchVal: any): void {
        setLoading(true);
        setError(null);
        var params = `?user_id=${userId}`;
        try {
            QnaApiService.getStudentMyQuery(params)
                .then((response) => {
                    const { questions } = response.data.data;

                    // Update component state using useImmerReducer dispatch
                    dispatch({
                        type: 'DATA_INIT',
                        payload: {
                            formConfig,
                            data: { ...data, studentMyQuery: questions },
                            domain: globalState.domain,
                            internal,
                            flags: { isDataLoading: false }
                        },
                    });

                    // Update studentCount
                    setStudentMyCount(questions.length);

                })
                .catch((error) => {
                    console.error('Error fetching student queries:', error);
                    // Handle error if needed
                });
        } catch (error) {
            console.error('Error fetching student queries:', error);
            setError('Failed to fetch queries. Please try again.');
        } finally {
            setLoading(false);
        }
    }

    const fetchTags = () => {
        fetch(`${process.env.REACT_APP_QNA_API}v1/question/fetchtags`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch tags');
                }
                return response.json();
            })
            .then(data => {
                const fetchedTags = data.data.map((tag: any) => ({
                    code: tag.code,
                    value: tag.value
                }));
                setTags(fetchedTags);
            })
            .catch(error => {
                console.error('Error fetching tags:', error);
                // Handle error (e.g., show an error message to the user)
            });
    };
    

    const handleAllQueriesClick = () => {
        setActiveTab('allQueries');
        getStudentAllQuery({});
    };

    const handleMyQueriesClick = () => {
        setActiveTab('myQueries');
        getStudentMyQuery({});
    };

    const handleTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedTime(event.target.value);
    };

    const handleTagChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedTag(event.target.value);
    };

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            
            <>
            <div className="main flex-1 bg-white">

                <section className="updateadditionalinformation">
                    <div className="max-1140 px-lg-0 overflow-hidden py-4 my-2 mx-auto">
                        <div className="alumni_statistics">

                            <div className="row g-2 align-items-center mb-3">
                                <div className="col"><h2 className="font-35 font-600 mb-0">All Queries</h2></div>
                                <div className="col-auto"><button className="btn btn-green px-3" onClick={handleAskQuestionClick}><i className="icon-question circle position-relative"></i><span>Ask a Question</span></button></div>
                            </div>

                            <div className="ActiveListings_present">
                                {activeTab === 'allQueries' && (
                                    <div className="d-flex flex-wrap justify-content-between align-items-start align-items-md-center mb-2 filterSearch">
                                        <div className="d-flex flex-wrap flex-1 mb-0 justify-content-start">
                                            <button className="btn-grpfiter me-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <i className="icon-filter font-13 me-2"></i> Filters
                                            </button>
                                            
                                        </div>
                                    </div>
                                )}

                                {activeTab === 'allQueries' && (
                                    <div className="fitergroup mb-3">
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" >
                                            <div className="accordion-body p-4">
                                                <form className="row g-3">
                                                    <div className="col-md-3">
                                                    <select className="form-select" id="select-time" name="complete Class 12" aria-label="Default select example" value={selectedTime} onChange={handleTimeChange}>
                                                        <option value="0">Select Time</option>
                                                        <option value="1">last 7 days</option>
                                                        <option value="2">last 30 days</option>
                                                        <option value="3">more then 30 days</option>
                                                        </select>                                      
                                                    </div>
                                                    <div className="col-md-3">
                                                    <select className="form-select" name="complete Class 12" aria-label="Default select example" value={selectedTag} onChange={handleTagChange}>
                                                        <option value="">Select Tags</option>
                                                        {tags.map(tag => (
                                                            <option key={tag.value} value={tag.value}>{tag.value}</option>
                                                        ))}
                                                    </select>                                
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                

                                <div className="row job_internship_tab polartabsec">
                                    <div className="xscroll p-0">
                                        <ul className="nav nav-tabs aos-init aos-animate" id="SchoolConnectTab" role="tablist" data-aos="fade-left" data-aos-delay="600">
                                            <li className="nav-item" role="presentation">
                                                <button onClick={handleAllQueriesClick} className="nav-link active" id="allQueries-tab" data-bs-toggle="tab" data-bs-target="#allQueries" type="button" role="tab" aria-controls="allQueries" aria-selected="true">All Queries <span>({studentCount})</span></button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button onClick={handleMyQueriesClick} className="nav-link" id="QnABoard-tab" data-bs-toggle="tab" data-bs-target="#myQueries" type="button" role="tab" aria-controls="myQueries" aria-selected="false">My Queries <span>({studentMYCount})</span></button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="tab-content mb-4 mt-3" id="myTabContent">
                                    <div className="tab-pane fade active show" id="allQueries" role="tabpanel" aria-labelledby="allQueries-tab">
                                       <StudentAllQueries selectedTime={selectedTime} selectedTag={selectedTag}/>
                                    </div>
                                    <div className="tab-pane fade" id="myQueries" role="tabpanel" aria-labelledby="myQueries-tab">
                                       <StudentMyQueries/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
                <DebugBarControl />
            </>
        </SmartContext.Provider>
    );
};

export default StudentQna;
