
import { storeAppConfig } from '../../../library/Core/SessionStore';
import { convertDomainArrayToMap } from '../../../library/Core/SmartFunctions';
import logger from '../../../library/Core/logger';

import { axiosClient } from '../../../library/Service/axiosClient';

import pageConfig from './PageConfigspeaker.json';

class SpeakerInfoService {
    static pageLoader(params: any) {
        const state: any = {};
        const { id,groupId, pageName, searchBy } = params;

         return Promise.all([
            this.getPageConfig(),
            SpeakerInfoService.get()
          ])
            .then((values) => {
                console.log(values)
                state.formConfig = values[0];
                //state.domain = values[1].data instanceof Map ? values[1].data : convertDomainArrayToMap(values[1].data);
                console.log(values);
                state.data = values[1];
                state.internal = { gridState: [] };
                state.routeInfo = { id, groupId,pageName };
                
                return Promise.resolve(state);
            })
            .catch(function (err) {
                //logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }


    static async get(): Promise<any> {
        // TODO: Fix the issue with sessionStorage
        
        let SpeakerInfo = [ ];
        const queryParams = new URLSearchParams(window.location.search);
        const eventId = queryParams.get('id');
        
        if (eventId) {
            SpeakerInfo = (await axiosClient().get(`${process.env.REACT_APP_LEAD_SERVER}/marketevent/getspeakerinfo/${eventId}`))?.data;


            const speakerdata = SpeakerInfo?.data
            
            if (speakerdata){
                SpeakerInfo = SpeakerInfoService.toSpeakerto(SpeakerInfo);

            } else{

                SpeakerInfo = [ ];
            }
            
        }
        //console.log(SpeakerInfo)

        return Promise.resolve(SpeakerInfo);
    }


    static toSpeakerto(speakerinfo: any) {
       
        const speakerdata = speakerinfo.data

        //const speakerNames = speakerdata.map((speaker: any) => speaker.speaker_name);

        // Using map to create a new array with custom objects
        const speakerSummaries = speakerdata.map((speaker: any) => ({
            speakername: speaker.speaker_name,
            designation: speaker.designation,
            organization: speaker.organization,
            speakerbio: speaker.speaker_bio,
            addVideoThumbnail: speaker.picture,
        }));
       

        return speakerSummaries;
    }


    
    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }
}
export default SpeakerInfoService;
