import { convertDomainArrayToMap, isEmpty } from '../../library/Core/SmartFunctions';
import { DomainElement } from '../../library/Core/SmartTypes';
import logger from '../../library/Core/logger';
import { axiosClient } from '../../library/Service/axiosClient';
import DomainDataService from '../../services/domain-data.service';
import PageConfigService from '../../services/page-config.service';
import qnaApiService from '../../library/Service/qnaApiService';

class QnaApiService {

    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        return Promise.all([
            // QnaApiService.getStudentAllQuery({}),
        ]).then((response) => {
            state.data = {
                // studentAllQuery: response[0]?.data.data ?? [],
            };

            return Promise.resolve(state);
        });
    }

    static getStudentAllQuery = (params: any) => {
        return qnaApiService.get(
            `v1/question/questionAnswer${params}`,
            {
                headers: {},
            }
        );
    };

    static getStudentMyQuery(params: any) {
        return qnaApiService.get(
            `v1/question/fetchByStudentId${params}`,
            // { ...CollegeService.filterData },
            {
                headers: {},
            }
        );
    }

    static getAlumniUnanswered(params: any) {
        return qnaApiService.get(
            `v1/question/unanswered${params}`,
            // { ...CollegeService.filterData },
            {
                headers: {},
            }
        );
    }

    static getStudentProfile(params: any) {
        return qnaApiService.get(
            `v1/question/fetchStudentProfile${params}`,
            // { ...CollegeService.filterData },
            {
                headers: {},
            }
        );
    }

    static viewMoreAnswer(params: any) {
        return qnaApiService.get(
            `v1/question/fetchByQuestionId${params}`,
            // { ...CollegeService.filterData },
            {
                headers: {},
            }
        );
    }

}

export default QnaApiService;
