import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useRef, useState } from 'react';

function ActionSettings(props) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleClick = (event) => {
        setIsOpen(false);
        props.onClick(props.id, event.target.textContent.replace(/[' ]/g, '_').toUpperCase());
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const toSentenceCase = (str) => {
        return str
            .replace(/_/g, ' ') // Replace all underscores with spaces
            .toLowerCase() // Convert the entire string to lowercase
            .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()); // Capitalize the first character of each word
    };

    return (
        <div className="d-inline-block" ref={dropdownRef}>
            {props.actions.length === 1 ? (
                <a role="button" onClick={handleClick}>
                    {toSentenceCase(props.actions[0])}
                </a>
            ) : (
                <i className="icon-setting text-gray-84 pe-0" role="button" onClick={() => setIsOpen(!isOpen)}></i>
            )}
            {isOpen && (
                <div className="dropdown-menu show">
                    {props.actions.map((action, index) => (
                        <a className="dropdown-item" key={index} role="button" onClick={handleClick}>
                            {toSentenceCase(action)}
                        </a>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ActionSettings;
