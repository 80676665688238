import { Dropdown,Modal,Tooltip } from 'bootstrap';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import useSmartState from '../../../common/hooks/useSmartState';
import { formatFullName, getDomainValueForCode, handleCopyText, isEmpty } from '../../../library/Core/SmartFunctions';
import { REPORT_CONFIG } from '../../admin/reports/report-config';
import { Link, useParams } from 'react-router-dom';
import instituteListAction from '../institute/instituteListAction';
import { axiosClient } from '../../../library/Service/axiosClient';
import ConversationReportService from './ConversationReportService';
import ExportToExcelConversationReport from '../../../library/SimpleControls/ExportToExcelConversationReport';

const BATCH_SIZE = 10;
const DEFAULT_SORT_COLUMN = 'createdAt';
const DEFAULT_SORT_DIRECTION = 'desc';

const ConversionsReportGrid = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const params = useParams();

    const [sortedStudents, setSortedStudents] = useState<string[]>([]);
    console.log("state?.data",state.data)
    const [visibleStudents, setVisibleStudents] = useState(state.data.alumniList.slice(0, 10));
    const loadMoreRef: any = useRef();
    const dropdownRef = useRef(null);
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);
    const allRows = [...state.data.alumniList] ?? [];
    const config = REPORT_CONFIG[state.routeInfo.pageName];
    const { getFormattedDate,publishUnpublishInstitute } = instituteListAction();
    
    const schoolAdminRoles = ['INSTITUTE_ADMIN', 'INSTITUTE_SUPPORT_MANAGER', 'INSTITUTE_MARKETING_MANAGER','INSTITUTE_ADMIN', 'SCHOOL_ADMIN'];

    var [alumniData, setAlumniData] = useState({
        alumniUUID:'',
        AlumniName: '',
        profilePictureFilePath: '',
        schoolId: '',
        AlumniprogramName:'',
        Alumnipassoutyear:'',
        AlumniRole:''
    });
    
    const [studentRole, setStudentRole] = useState('');
    const [studentImage, setStudentImage] = useState('');
    const [studentSchoolName, setStudentSchoolName] = useState('');
    const [studentprogramName, setStudentprogramName] = useState('');
    const [StudentpassoutYear, setStudentpassoutYear] = useState('');
    const [studentName, setStudentName] = useState('');

    useEffect(() => {
        console.log(state.data.alumniList);
        console.log(`useEffect 1`);
        if (dropdownRef.current) {
            new (Dropdown as any)(dropdownRef.current);
        }
    }, []);

    useEffect(() => {
        console.log(`useEffect 2`);
        const sorted = [...allRows].sort(compareFunction);
        setSortedStudents(sorted);
    }, [state.data.alumniList, sortColumn, sortDirection]);

    useEffect(() => {
        console.log(`useEffect 3`);
        setVisibleStudents(sortedStudents.slice(0, BATCH_SIZE));
    }, [sortedStudents]);

    useEffect(() => {
        console.log(`useEffect 4`);
        const loadMoreObserver = new IntersectionObserver((entries) => {
            // Check if all students are already visible
            if (sortedStudents.length <= visibleStudents.length) {
                if (loadMoreRef.current) {
                    loadMoreObserver.unobserve(loadMoreRef.current); // Stop observing
                }
                return;
            }

            if (entries[0].isIntersecting) {
                const nextBatch = sortedStudents.slice(0, visibleStudents.length + BATCH_SIZE);
                setVisibleStudents([...nextBatch]);
            }
        });

        if (loadMoreRef.current) loadMoreObserver.observe(loadMoreRef.current);
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });

        return () => {
            if (loadMoreRef.current) {
                loadMoreObserver.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, visibleStudents]);

    // Utility to determine if the column is a string type.
    const isStringColumn = typeof visibleStudents[0]?.[sortColumn] === 'string';

    const compareFunction = (a: any, b: any) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (isStringColumn) {
            return sortDirection === 'asc'
                ? aValue?.localeCompare(bValue, undefined, { sensitivity: 'accent' })
                : bValue?.localeCompare(aValue, undefined, { sensitivity: 'accent' });
        } else {
            return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
        }
    };

    const getTaglineSender = (row: any) => {
        if (!isEmpty(row.senderPassOutYear)) {
            return `${row.senderProgramName} - ${row.senderPassOutYear}`;
        } else if (!isEmpty(row.senderProgramName)) {
            return `${row.senderProgramName}`;
        } else {
            return '';
        }
    };

    const getTaglineReceiver = (row: any) => {
        if (!isEmpty(row.receiverPassOutYear)) {
            return `${row.receiverProgramName} - ${row.receiverPassOutYear}`;
        } else if (!isEmpty(row.receiverProgramName)) {
            return `${row.receiverProgramName}`;
        } else {
            return '';
        }
    };
   
    const getNameField = (row: any, isSender: boolean) => {
        const fullName = isSender ? row.senderFullName : row.receiverFullName;
        const userType = isSender ? row.senderUserType : row.receiverUserType;
        const id = isSender ? row.senderId : row.receiverUUID;
        const tagline = isSender ? getTaglineSender(row) : getTaglineReceiver(row);
    
        return (
            <div className="d-flex flex-column checkoption">
                <a
                    className="tablink"
                    href={`/alumni/${id}/profile`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <span
                        data-bs-toggle="tooltip"
                        title= {`${fullName} ${!isSender ? `(${userType})` : ''}`}
                        className="singlelinename font-16 lineHeight-16">
                        {`${fullName} ${!isSender ? `(${userType})` : ''}`}
                        <span className="d-block font-13 fst-italic"> {tagline} </span>
                    </span>
                </a>
                <div className="copyId d-flex align-items-center mt-1">
                    <span className="font-13 fst-italic singlelinename me-1"> {id} </span>
                    <button
                        id={`uuid-${id}`}
                        className="copy-text border-0 bg-transparent"
                        title="click to copy id"
                        onClick={() => handleCopyText(id, `uuid-${id}`)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-copy"
                            viewBox="0 0 16 16">
                            <path
                                fillRule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        );
    };

    const [conversationData, setConversationData] = useState([]);
   
    const displayConversationWithstudent = async (
        conversationId: string,
        senderfullName: string,
        senderprogramName: string,
        senderimagePath: string,
        senderpassoutYear:string,
        senderschoolName: string,
        senderRole:string,
        receiverfullName: string,
        receiverimagePath: string,
        receiverschoolName: string,
        receiverpassoutYear:string,
        receiverProgramName:string,
        receiveralumniUUID:string,
        receiverRole:string
    ) => {
        const response = await axiosClient().get(
            `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/view-message-by-id/${conversationId}`
        );
        if (response.status === 200) {
            setConversationData(response.data);
        }
        // setSelectedMessageIds([]);
        // setPopupVisible(true);
        setStudentRole(senderRole);
        setStudentImage(senderimagePath);
        setStudentprogramName(senderprogramName);
        setStudentpassoutYear(senderpassoutYear);
        setStudentName(senderfullName);
        setStudentSchoolName(senderschoolName);

        setAlumniData({
            ...alumniData,
            alumniUUID:receiveralumniUUID,
            AlumniName:receiverfullName, 
            profilePictureFilePath: receiverimagePath,
            schoolId: receiverschoolName,
            AlumniprogramName:receiverProgramName,
            Alumnipassoutyear:receiverpassoutYear,
            AlumniRole:receiverRole
        });
        // const modalElement = document.getElementById('conversationList');
        // if (modalElement) {
        //     const modal = new Modal(modalElement);
        //     modal.show();
        // }
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'conversationList' } });
    };

    const totalConversations = state?.internal?.conversationCount?.[0]?.totalConversations || 0;
    const twoWayConversations = state?.internal?.twoWayconversationCount?.[0].conversationCount || 0;

    // Calculate the yield percentage
    const yieldPercentage = totalConversations > 0 
        ? ((twoWayConversations / totalConversations) * 100).toFixed(2) 
        : 0;
    
    const showFlag = state?.modalFlags?.conversationList;

    return (
        <> 
         <div>
            <div className="ActiveListings_present">
                <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks my-2">
                            <div className="d-flex justify-content-start align-items-center customselect_parnt">
                            </div>
                    </div>
                
                    <ExportToExcelConversationReport
                        state={state}
                        columns={ConversationReportService.getExportToExcelColumns(state.routeInfo.pageName)}
                        pageName={state.routeInfo.pageName}
                     />
                    
                </div>
                <div className="bg-darker-blue p-4 mb-3 radius-6">
                            <div className="row g-3">
                                <div className="col-sm-4">
                                <div className="font-24 font-600 text-white">{state?.internal?.conversationCount && state?.internal?.conversationCount[0]?.totalConversations?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} </div>
                                <div className="font-18 font-400 text-gray-f3">All Queries</div>
                                </div>

                                <div className="col-sm-4">
                                <div className="font-24 font-600 text-white">{state?.internal?.twoWayconversationCount && state?.internal?.twoWayconversationCount?.[0]?.conversationCount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} </div>
                                <div className="font-18 font-400 text-gray-f3">Two way Conversation </div>
                                </div>

                                <div className="col-sm-4">
                                <div className="font-24 font-600 text-white"> {yieldPercentage}%</div>
                                <div className="font-18 font-400 text-gray-f3">Yield</div>
                            </div>
                        </div>
                    </div>

                <div className="table-responsive attendee_dtl">
                    <table className="table" style={{ minWidth: '1042px' }}>
                        <thead>
                            <tr>
                                <th>Name & Program</th>
                                <th>Email ID</th>
                                <th>Sender Institute</th>
                                <th>Sender</th>
                                <th>Receiver Details</th>
                                <th>See Full Conversation</th>
                            </tr>
                        </thead>
                        <tbody>
                        {visibleStudents.map((row: any) => (
                            <tr key={row.id}>
                                <td>{getNameField(row,true)}</td>  
                                <td>{row.senderRegisteredEmailId}</td>
                                <td>{row.senderInstituteName}</td>
                                <td>{row.senderUserType.replace(/_/g, ' ')}</td>
                                <td>{getNameField(row,false)}</td> 
                                <td>
                                    <a
                                        href="javascript:void(0)"
                                        className="link"
                                        onClick={(e) =>
                                            displayConversationWithstudent(
                                                row.conversationId,
                                                row.senderFullName,
                                                row.senderProgramName,
                                                row.senderProfilePictureFilePath,
                                                row.senderPassOutYear,
                                                row.senderInstituteName,
                                                row.senderUserType,
                                                row.receiverFullName,
                                                row.receiverProfilePictureFilePath,
                                                row.receiverInstituteName,
                                                row.receiverPassOutYear,
                                                row.receiverProgramName,
                                                row.receiverUUID,
                                                row.receiverUserType
                                            )
                                        }>
                                       See Conversation
                                    </a>
                                </td>
                            </tr>
                          ))}
                        <tr ref={loadMoreRef} />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        {/* modal */}
        <div
                id="conversationList"
                className={`modal fade ${showFlag ? 'show' : ''}`} 
                tabIndex={-1}
                role="dialog"
                style={{ display: showFlag ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                >
                <div className="modal-dialog modal-dialog-centered max-800 mx-auto">
                    <div className="modal-content half-gray">
                        <div className="modal-header">
                        <button
                            type="button"
                            className="btn-close btn-close-small"
                            onClick={() => {
                                dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'conversationList' } });
                            }}
                            aria-label="Close"></button>
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-sm-6 mb-2 mb-sm-0">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <i className="me-2">
                                                <img src={studentImage} className="rounded-circle img-fluid" width="47" />
                                            </i>
                                            <span className="font-16 lineHeight-16 flex-1 d-flex flex-column align-items-start align-items-sm-start">
                                                <span className="font-16 font-500 text-dark-blue">
                                                    {formatFullName(studentName,'')}, {' '}<br/>
                                                    <span className={`fst-italic font-15`}>
                                                      {schoolAdminRoles.includes(studentRole)  
                                                            ? studentRole.replace(/_/g, ' ') 
                                                            : `${studentprogramName} - ${StudentpassoutYear}`}
                                                    </span>
                                                </span>
                                                {/* <span className="d-block font-14 font-500 fst-italic text-dark-blue">
                                                    {studentSchoolName}{' '}
                                                </span> */}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="d-flex flex-wrap align-items-center flex-row-reverse flex-lg-row pe-md-4">
                                            <i className="ms-2 ms-lg-0 me-0 me-lg-2">
                                                <img
                                                    src={alumniData?.profilePictureFilePath}
                                                    className="rounded-circle img-fluid"
                                                    width="47"
                                                />
                                            </i>
                                            <span className="lineHeight-16 flex-1 d-flex flex-column align-items-end align-items-lg-start">
                                                <span className="font-16 font-500 text-dark-blue">
                                                    {alumniData?.AlumniName},{' '}<br/>
                                                    <span className="fst-italic font-15">
                                                     {schoolAdminRoles.includes(alumniData?.AlumniRole)  
                                                            ? alumniData?.AlumniRole.replace(/_/g, ' ') 
                                                            : `${alumniData?.AlumniprogramName} - ${alumniData?.Alumnipassoutyear}`}
                                                    </span>
                                                    </span>
                                                {/* <span className="d-block font-14 font-500 fst-italic text-dark-blue">
                                                    <span className="d-block font-14 font-500 fst-italic text-dark-blue">
                                                        {alumniData?.AlumnischoolName}
                                                    </span>
                                                </span> */}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body px-md-4 pb-4">
                            <div className="conversation gap">
                                {conversationData.map((message: any, index: number) => (
                                    <div
                                        key={index}
                                        className={
                                            message?.senderId == alumniData?.alumniUUID ? 'row justify-content-end' : 'row justify-content-start'
                                        }>
                                        <div className="col-sm-6">
                                            <div className="d-flex flex-wrap">
                                                <div
                                                    className={message?.senderId == alumniData?.alumniUUID ? 'messages leftUser' : 'messages rightUser'}>
                                                    <p>
                                                        {message.messageContent}
                                                    </p>
                                                    <span className="date">{moment(message.createdAt).format('DD-MMM-YYYY h:mm A')}</span>
                                                </div>
                                                {/* <div className="userimg ms-2">
                                                    <img src="images/ravi_verma_icon.png" className="rounded-circle img-fluid" width="47" />
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        );
    };
    
export default ConversionsReportGrid;
