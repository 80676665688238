import { useCallback, useContext } from 'react';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import PageConfigService from '../../../services/page-config.service';
import { getStudentList } from '../common/db';

const useStudentReport = () => {
    const { globalState } = useContext(GlobalContext);
    const { state } = useContext(SmartContext);

    const initialize = async (pageName: string | undefined) => {
        const values = await Promise.all([PageConfigService.get(pageName as string), getStudentList()]);

        const state = {
            formConfig: values[0].data,
            data: { ...values[1].data, searchCriteria: {} },
            domain: globalState.domain,
            routeInfo: { pageName },
            internal: { gridState: {} },
        };

        return state;
    };

    const test = useCallback(() => {
        console.log(JSON.stringify(state));
    }, []);

    return { initialize, test };
};

export default useStudentReport;
