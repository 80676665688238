import { useContext } from 'react';
import { getControlFromFactory } from '../Core/ControlFactory';
import { SmartContext } from '../Core/SmartContext';
import { findTheRightDataKey, getControlValueFromState } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';

const GridCardsControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const finalDataKey = findTheRightDataKey(control, dataKey, parentDataKey as string);
    const data = Object.values(getControlValueFromState(finalDataKey as string, state as State) as any[]);
    console.log(data);

    return (
        <>
            {data.map((item, index) => (
                <div key={index}>{getControlFromFactory(control.controlGroup[0], dataKey, dataKey + `.` + control.id, state as State)}</div>
            ))}
        </>
    );
};

export default GridCardsControl;
