import { storeDomainData } from '../../library/Core/SessionStore';
import { convertDomainArrayToMap } from '../../library/Core/SmartFunctions';
import logger from '../../library/Core/logger';
import { axiosClient } from '../../library/Service/axiosClient';

class InviteService {
    static sendEmailTOInvitees = async (email,instituteId) => {
        const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/email/send-email-invite`, { email,instituteId });
        return response;
    };

    static sendInvitesByPhone = async (numbers) => {
        const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/sms/send-invite-sms`, { numbers });
        return response;
    };
}

export default InviteService;
