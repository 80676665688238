import React, { Fragment, useContext, useEffect, useState } from 'react';
import BasicInfoVisual from '../../../../styles/images/basic-info-visual.png';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';

const EducationLayout = (props: any) => { 
    const { state, dispatch } = useContext(SmartContext);

    return (
        <>
            <div className="main-wrap">
                <div className="leftPane">
                    <div className="leftPaneWrap">
                        <div className="leftPaneBodyWrap">
                            <div className="leftPaneBody">
                                <div className="qs_body aos-init aos-animate" data-aos="fade-right" data-nextpage="undergraduate.html">
                                    <div className="steps_questions">{props.children}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rightPane basic-information">
                    <div className="rightPaneWrap">
                        <div className="rightPaneBodyWrap">
                            <div className="rightPaneBody aos-init aos-animate" data-aos="fade-left">
                                <div className="step step_1">
                                    <h2 className="stepHeader">
                                    Why should you update your school details?
                                    </h2>
                                    <div className="listed_points">
                                        <ul>
                                            <li>This network can be the one-stop
                                                platform for all your career and growth
                                                needs. And all you will need is a complete
                                                profile that tells us more about you!</li>
                                            {/* <li>Gain relevant insights as per your curriculum and scores.</li> */}
                                        </ul>
                                    </div>
                                    <div className="step_img">
                                        <img src={BasicInfoVisual} className="img-fluid float_horizontal_animate" width="100%" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
           </>
        );
};

export default EducationLayout;
