import { useContext, useEffect, useRef, useState } from "react";
import useSmartState from "../../../common/hooks/useSmartState";
import { SmartContext } from '../../../library/Core/SmartContext';
import { Dropdown, Modal } from "bootstrap";
import { axiosClient } from "../../../library/Service/axiosClient";
import { REPORT_CONFIG } from '../reports/report-config';
import { toast } from "react-toastify";
import ProfileMarkerNotesService from "./ProfileMarkerNotesService";
import useInternalAdminReports from "../reports/useInternalAdminReports";
import ActionSettings from "../../../library/SimpleControls/ActionSettings";

const BATCH_SIZE = 10;
const DEFAULT_SORT_COLUMN = 'createdAt';
const DEFAULT_SORT_DIRECTION = 'desc';

const Results = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const [sortedStudents, setSortedStudents] = useState<string[]>([]);
    const [visibleStudents, setVisibleStudents] = useState(state.data.notesList.slice(0, 10));
    const loadMoreRef: any = useRef();
    const dropdownRef = useRef(null);
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);
    const allRows = [...state.data.notesList] ?? [];
    const [isEditMode, setIsEditMode] = useState(false);
    const config = REPORT_CONFIG[state.routeInfo.pageName];
    const [noteDescription, setNoteDescription] = useState(""); 
    const { getFormattedDate } = useInternalAdminReports();

    useEffect(() => {
        console.log(`useEffect 1`);
        if (dropdownRef.current) {
            new (Dropdown as any)(dropdownRef.current);
        }
    }, []);

    useEffect(() => {
        console.log(`useEffect 2`);
        const sorted = [...allRows].sort(compareFunction);
        setSortedStudents(sorted);
    }, [state.data.notesList, sortColumn, sortDirection]);

    useEffect(() => {
        console.log(`useEffect 3`);
        setVisibleStudents(sortedStudents.slice(0, BATCH_SIZE));
    }, [sortedStudents]);

    useEffect(() => {
        console.log(`useEffect 4`);
        const loadMoreObserver = new IntersectionObserver((entries) => {
            // Check if all students are already visible
            if (sortedStudents.length <= visibleStudents.length) {
                if (loadMoreRef.current) {
                    loadMoreObserver.unobserve(loadMoreRef.current); // Stop observing
                }
                return;
            }

            if (entries[0].isIntersecting) {
                const nextBatch = sortedStudents.slice(0, visibleStudents.length + BATCH_SIZE);
                setVisibleStudents([...nextBatch]);
            }
        });

        if (loadMoreRef.current) loadMoreObserver.observe(loadMoreRef.current);

        return () => {
            if (loadMoreRef.current) {
                loadMoreObserver.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, visibleStudents]);

    const isStringColumn = typeof visibleStudents[0]?.[sortColumn] === 'string';
    const showFlag = state?.modalFlags?.showNotesModal;

    const compareFunction = (a: any, b: any) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (isStringColumn) {
            return sortDirection === 'asc'
                ? aValue?.localeCompare(bValue, undefined, { sensitivity: 'accent' })
                : bValue?.localeCompare(aValue, undefined, { sensitivity: 'accent' });
        } else {
            return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
        }
    };

    const addEditNotes = async () => {
        if (!noteDescription || noteDescription.trim() === '') {
            alert('Note description cannot be blank');
            return;
        }
    
        try {
            const response: any = await axiosClient().post(`${process.env.REACT_APP_ADMIN_API_SERVER}/markers/add-update-notes`, {
                noteDescription: noteDescription,id:state?.modalData?.data?.id || null ,
                user_id:state.routeInfo.id
            });
            console.log("response", response);
            const data = await response.data;
            if (data.success) {
                console.log(data.message);   
                dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showNotesModal' } });     
                alert(data.message);
                ProfileMarkerNotesService.search(state.data.searchCriteria, state.routeInfo.pageName,state?.data?.id).then((response: any) => {
                    setStateValue('notesList', response.data);
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    useEffect(() => {
        if (isEditMode && state?.modalData?.data?.noteDesc) {
            setNoteDescription(state.modalData.data.noteDesc);
        } else {
            setIsEditMode(false)
            setNoteDescription("");
        }
    }, [isEditMode, state?.modalData?.data])

    const getValidActions = (isActive: number) => {
        const actions = config['ROW_ACTION'];
        return actions.filter((item: any) => item !== (isActive == 1 ? 'ACTIVATE' : 'DEACTIVATE'));
    };

    const getActionNode = (row: any) => {
        const actions = config['ROW_ACTION'];
        return actions.length > 1 ? (
            <ActionSettings id={row} actions={getValidActions(row?.isActive)} onClick={handleAction} />
        ) : (
            <a className="btn-update" href={config['UPDATE_ACTION_LINK'](row.uuid)} target="_blank" rel="noopener noreferrer">
                Update
            </a>
        );
    };

    const handleAction = (row: any, action: string) => {
        console.log(row,action)
        handlePerformAction(action,row)
    }

    const handlePerformAction = async (action: string, row: any) => {
        switch (action) {
            case 'DEACTIVATE':
                handleDelete(row.id,row.isActive);
                break;
            case 'ACTIVATE':
                handleDelete(row.id,row.isActive);
                break;
            case 'UPDATE':
                handleUpdateAction(row)
                break;
        }
    };

    const handleDelete=(id:number,status:number)=>{
        const msg=(status==1) ? 'Are you sure you want to Deactivate this record?' : 'Are you sure you want to Activate this record?';
        const isConfirmed = window.confirm(msg);
        if (isConfirmed) {
            notesAction(id,status);
        }
    }

    const notesAction = async (id: number,status:number) => {
        try {
          const response = await axiosClient().get(`${process.env.REACT_APP_ADMIN_API_SERVER}/markers/notes-actions/${id}/${status}`);
          const data = response.data;
          const toastMessage = (status === 1) ? 'Deactivated successfully' : 'Activated successfully';
          if (data.success) {
            ProfileMarkerNotesService.search(state.data.searchCriteria, state.routeInfo.pageName,state?.data?.id).then((response: any) => {
              setStateValue('notesList', response.data);
              toast.success(toastMessage);
            });
          }
        } catch (error) {
          console.error('Error deleting data:', error);
          alert(error);
        }
    
    };

    const handleUpdateAction = (row: any) => {
        setIsEditMode(true);
        dispatch({ type: 'SET_MODAL_DATA', payload: {data:row} });
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showNotesModal' } });
    }
    
    return (
        <>
            <div className="table-responsive attendee_dtl">
                <div>Found {state.data.notesList.length} rows</div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Added On</th>
                            <th>Added By</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                <tbody>
                     {visibleStudents.map((row: any) => (
                         <tr key={row.category_code}>
                            <td>{row.noteDesc}</td>
                            <td>{getFormattedDate(row['createdAt'])}</td>
                            <td>{row.addedBy}</td>
                            <td style={{ color: (row.isActive as boolean) ? 'green' : 'red' }}>  
                             {(row.isActive as boolean) ? 'Active' : 'De-active'}</td>
                            <td>{getActionNode(row)}</td>
                         </tr>
                     ))}
                      <tr ref={loadMoreRef} />
                </tbody>
            </table>
            </div>
          {/* edit modal */}
           <div
                    id="update-notes-data"
                    className={`modal fade ${showFlag ? 'show' : ''}`} 
                    tabIndex={-1}
                    role="dialog"
                    style={{ display: showFlag ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered max-600 mx-auto">
                        <div className="modal-content">
                        <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
                            <h5 className="modal-title" id="staticBackdropLabel">
                            {isEditMode ? "Edit Note" : "Add Note"}
                            </h5>
                            <button
                            type="button"
                            className="btn-close btn-close-small"
                            onClick={() => {
                                dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showNotesModal' } });
                            }}
                            aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-4">
                            <form className="needs-validation" noValidate autoComplete="off">
                            <div className="row g-4">
                                <div className="col-md-12">
                                <label className="form-label">Note Description</label>
                                <textarea
                                    className="form-control flex-1"
                                    id="note_description"
                                    rows={5}
                                    value={noteDescription} 
                                    onChange={(e) => setNoteDescription(e.target.value)} 
                                />
                                </div>
                            </div>
                            <br/>
                            <div className="row justify-content-center align-items-center">
                            <div className="col-auto">
                                <button type="button" className="btn btn-green max-135" onClick={() => addEditNotes()}>
                                <span>{isEditMode ? 'Update' : 'Save'}</span>
                                </button>
                            </div>
                            </div>
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
        </>
    );
};

export default Results;