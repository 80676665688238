import axios from 'axios';
import { useEffect } from 'react';
import { Default } from 'react-toastify/dist/utils';
import sfeApiService from '../../library/Service/sfeApiService';

class CollegeService {
    static filterData = {};

    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        return Promise.all([
            CollegeService.getCollegesList({}),
            CollegeService.getTopCollegesList(1),
            CollegeService.getDepartmentData({}),
            CollegeService.getCountryData({}),
            CollegeService.getTopCollegesList(2),
        ]).then((response) => {
            // console.log('response----- JSON' + JSON.stringify(response[1]?.data.data));

            state.data = {
                collegeList: response[0]?.data.data ?? [],
                topCollegeList: response[1]?.data.data ?? [],
                departmentList: response[2]?.data.data ?? [],
                countryList: response[3]?.data.data ?? [],
                serachCollegeList: response[4]?.data.data ?? [],
            };

            return Promise.resolve(state);
        });
    }

    static getCollegesList = (collegeFilters: any) => {
        return sfeApiService.post(
            'college/getAllCollege',
            { ...CollegeService.filterData },
            {
                headers: {},
            }
        );
    };

    static getTopCollegesList = (collegeFilters: any) => {
        return sfeApiService.get(
            `college/getCollegeDetails?has_ranking=${collegeFilters}`,
            // { ...CollegeService.filterData },
            {
                headers: {},
            }
        );
    };

    static getSearchCollegesList = (collegeFilters: any) => {
        return sfeApiService.get(
            `college/getCollegeDetails?has_ranking=2`,
            // { ...CollegeService.filterData },
            {
                headers: {},
            }
        );
    };

    static getDepartmentData = (collegeFilters: any) => {
        return sfeApiService.get(
            'course/getAllParentCourse',
            // { ...CollegeService.filterData },
            {
                headers: {},
            }
        );
    };

    static getCountryData = (collegeFilters: any) => {
        return sfeApiService.get(
            'country/getAllCountry',
            // { ...CollegeService.filterData },
            {
                headers: {},
            }
        );
    };

    static getTopCollegeFilter(params: any) {
        return sfeApiService.get(
            `college/getCollegeDetails${params}`,
            // { ...CollegeService.filterData },
            {
                headers: {},
            }
        );
    }

    static getCourseList(params: any) {
        return sfeApiService.get(`course/getAllSubCourse?parent_id=${params}`, {
            headers: {},
        });
    }

    static getStateList(params: any) {
        return sfeApiService.get(`state/getStates?country_id=${params}`, {
            headers: {},
        });
    }

    static getCityList(state_id:any) {
        return sfeApiService.post('city/getAllCity', { state_id });
    }


}

export default CollegeService;
