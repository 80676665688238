import { useEffect } from 'react';
import { getCompanyImage } from '../../../common/styles/Images';
import { getDomainValueForCode, isEmpty } from '../../../library/Core/SmartFunctions';
import { Tooltip } from 'bootstrap';

const CardHomeMaker = ({ alumni, state }: { alumni: any;  state: any }) => {
    console.log(alumni, " alumni ")
    let subjectDiv = null; // Initialize subjectDiv as null

    const break_from_date = alumni?.alumniBreakFrom?.fromDate ?? '';
    const break_to_date = alumni?.alumniBreakFrom?.toDate ?? '';

    if (alumni?.workingStatusCode === 'HOME_MAKER') {
        if (!isEmpty(alumni?.homeMakerStartYear)) {
            if (alumni?.homeMakerStartYear !== 0) {
                subjectDiv = (
                    <div className="alumni_university_subject">
                        <i className=""></i>
                        <span>Since {alumni?.homeMakerStartYear}</span>
                    </div>
                );
            }
        }
    } else {
        subjectDiv = (
            <div className="alumni_univarsity_name">
                <span>{alumni?.jobCompanyId ? getDomainValueForCode(alumni?.jobCompanyId, 'COMPANY_CODE', state) : alumni?.companyName}</span>
                {alumni?.jobCompanyPrestigious === 1 && (
                    <i className="ms-1">
                        <img src="https://miles.univariety.com/school/public/images/subtraction.svg" alt="" />
                    </i>
                )}
                {alumni?.designation && (
                    <div className="alumni_univarsity_subject">
                        <i className="icon-brifecase"></i>
                        <span>{alumni.designation}</span>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div key={alumni.uuid} className="alumni_univarsity_details working">
            <div className="alumni_univarsity_details_inner_cover">
                <div className="alumni_univarsity_logo">
                    {/* <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_male.svg`} alt="" /> */}
                    <img src={(alumni?.workingStatusCode !== 'HOME_MAKER') ? getCompanyImage(alumni.jobCompanyImage) : 'https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/home-icon-directory.svg'} alt="" />
                </div>
                <div className="alumni_univarsity_brife">
                    <div className="alumni_education">
                        <div className="row g-2">
                            <div className="col">{(alumni?.workingStatusCode === 'HOME_MAKER') ? 'Working as' : 'Professional Practice'} </div>
                        </div>
                    </div>
                    <div className="alumni_univarsity_name">
                        <span>{(alumni?.workingStatusCode === 'HOME_MAKER') ? 'Homemaker' : ''}</span>
                    </div>
                    {subjectDiv}
                </div>
            </div>
        </div>
    );
};

export default CardHomeMaker;
