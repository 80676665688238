import React, { useContext, useState, useEffect } from 'react';
import Select from 'react-select';
import { SmartContext } from '../../library/Core/SmartContext';
import ImgCollegeLogo from '../../styles/images/university_melbourne.jpg';
import CollegeService from './CollegeService';
import { useLoaderData } from 'react-router-dom';
import { State } from '../../library/Core/SmartTypes';
import { GlobalContext } from '../../library/Core/GlobalContext';
import PaginationSearchCollege from './PaginationSearchCollege';
import loaderImg from '../../styles/images/load_more_icon.jpg';

const TopSearchGrid = ({ fetchDepartment , fetchCourse ,fetchCountry , fetchState, fetchCity , hasRanking , loading , onLoaderChange }: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { formConfig, data, internal } = useLoaderData() as State;
    const { globalState } = useContext(GlobalContext);
    const [search, setSearch] = useState('');

    var serachCollegeList = state?.data?.serachCollegeList;

    // console.log(serachCollegeList.total_count);

    useEffect(() => {
        if(search.length>0){
            topCollegeFilter(search);
        }
    }, [search]); // Call the filter function whenever the search term changes


    function topCollegeFilter(searchVal:any): Promise<any> {
        onLoaderChange(true);
        var topCollegeUrl = `?has_ranking=2&search=${searchVal}&course_id=${fetchDepartment}&sub_course_id=${fetchCourse}&country_id=${fetchCountry}&state_id=${fetchState}&city_id=${fetchCity}`;

        return Promise.all([
            CollegeService.getTopCollegeFilter(topCollegeUrl),
        ]).then((response) => {
            // serachCollegeList = state?.data?.serachCollegeList;
            onLoaderChange(false);
            // console.log('response---Filter-- JSON' + JSON.stringify(response[0]?.data.data));

            dispatch({
                type: 'DATA_INIT',
                payload: { formConfig,
                           data:{...data,serachCollegeList: response[0]?.data.data ?? []}, 
                           domain: globalState.domain,
                           internal,
                           flags:{isDataLoading:false} },
            });

            return Promise.resolve(state);

        });
    }

    function courseOnChange(e: any) {
        const value = e.target.value;
        setSearch(value);
        if(value.length==0){
            topCollegeFilter('');
        }
    }

    function clearInput(e:any) {
        setSearch('');
        topCollegeFilter('');
      };

    return (
       
    <div className="flex-1 polarcontsec tab-content" id="myTabContent">

        {loading &&  
        <div className='text-center w-100'>
            <a className="load_more_btn">
                <img src={loaderImg} /> Load More
            </a>
        </div>
        
        }

        <div className="ActiveListings_present">

            <div className='row g-2 align-items-center mb-2'>
            

                {!loading &&  serachCollegeList.total_count > 0 ? (  
                <div className='col-sm'>
                    <h3 className="font-18 font-500 mb-0">{serachCollegeList.total_count} count of colleges found</h3>
                </div>
              ):(
                <div className='col-sm'>
                    <h3 className="font-18 font-500 mb-0">0 count of colleges</h3>
                </div>
              )}

                <div className='col-sm-auto position-relative search_college'>
             
                        <input type="text" placeholder='Search' className='ms-auto border-0 p-2 form-control form-search ps-5' value={search} required onChange={courseOnChange}/>
                        <i className='icon icon-cross' onClick={clearInput}></i>
              
                </div>
            </div>

              
        {!loading && serachCollegeList.total_count > 0 ? (  
          <>  
           
           <div className="row mt-0 college-details">
                        <div className="col-md-12" id="collegeBlock_collegeSearch">
                         {serachCollegeList.data?.map((data: any) => (
                            <div className="college_blk">                                                                
                                <div className="collegeName d-flex flex-wrap">
                                    <div className="colllegeLogo me-3">
                                        <img src={data.logo} alt="university melbourne" width="84" className="img-fluid"/>
                                    </div>                                                                  
                                    <div className="collegeNameDetail flex-1">
                                        <h3 className="mb-0">{data.name}</h3>
                                        <label className="tick font-14 font-500 me-3"><i aria-hidden="true" className="icon-location-pin blue-text  mt-1 me-2"></i>{data.city} , {data.state} , {data.country}</label>
                                        
                                    </div>
                                    
                                    {data.course_count > 0 && (
                                    <div className="toggle-link">
                                        <a
                                            href="#"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#Kamran${data.id}`}
                                            aria-expanded="false"
                                            className="btn btn-green height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center align-items-center collapsed"
                                        >
                                            <span className="font-14 font-500 d-flex align-items-center justify-content-center">
                                                {data.course_count} Courses
                                            </span>
                                            <span className="icon-down-arrow text-white font-13 ms-2"></span>
                                        </a>
                                    </div>
                                    )}
                                </div>

                                            
                                <div
                                    className="college-list collapse"
                                    id={`Kamran${data.id}`}
                                    data-bs-parent="#collegeBlock"
                                >
                                    <div className="p-0 accordion-body">
                                        <div className="college-list">
                                            <div className="college_detail">
                                                <div className="row g-3">
                                                    {data.courses?.map((res: any) => (  
                                                        <div className="col-md-6">
                                                            <div className="college">
                                                                <div className="college-brife m-0">
                                                                    <label><a className="font-16 font-500 cursor-pointer">{res.name}</a></label>
                                                                    <div className="text-gray-41 d-flex justify-content-between mt-2">
                                                                        <div className=" college-name flex-1 font-16 font-500 me-3"><b className="d-block font-14 font-500">Fees: </b><span> ₹ {res.fee_value}</span> / {res.fee_duration}</div>
                                                                        <div className="duration flex-1 font-16 font-500"><b className="d-block font-14 font-500">Duration:</b>{res.duration} Months</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))} 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                  </div>                                                
                         ))}
                </div>
                        
            </div>
            

        
           {/* <Pagination result={topCollegeList}/> */}

           <PaginationSearchCollege
                result={serachCollegeList}
                search = {search}
                fetchDepartment = {fetchDepartment}
                fetchCourse = {fetchCourse}
                fetchCountry = {fetchCountry}
                fetchState = {fetchState}
                fetchCity = {fetchCity}
                hasRanking = {hasRanking}
                loading = {loading}
                onLoaderChange = {onLoaderChange}
                />

           </>
          ) : (
            <div></div>
          )}   

          </div>
    </div>


    );
};

export default TopSearchGrid;
