import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

const RedirectToUserLogin = () => {
    const { schoolDomain, invitedBy } = useParams();
    const targetUrl = invitedBy ? `/user/${schoolDomain}/login/${invitedBy}` : `/user/${schoolDomain}/login`;

    return <Navigate to={targetUrl} replace />;
};

export default RedirectToUserLogin;
