import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import Layout from './Layout';
import DomainDataGrid from './DomainDataGrid';
import { getAllDomain } from '../../../library/Service/domainService';

const DomainDetail = () => {
    const navigate = useNavigate();
    const { globalState } = useContext(GlobalContext);
    const categoryCodes = [
        // ... (your list of category codes)
    ];
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;
    const [allDomainCode, setAllDomainCode] = useState(getAllDomain('DOMAIN_CATEGORY_CODE', internal.DomainCategorycode));
    useEffect(() => {
        setAllDomainCode(getAllDomain('DOMAIN_CATEGORY_CODE', internal.DomainCategorycode));

        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain: new Map([...globalState.domain]), internal, routeInfo },
        });
        dispatch({
            type: 'SET_DOMAIN',
            payload: { key: 'DOMAIN_CATEGORY_CODE', value: allDomainCode },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <DebugBarControl />
            {!state.flags.isDataLoading && (
                <Layout>
                    {/* Please check layout for the <SearchCriteria /> */}
                    <DomainDataGrid />
                </Layout>
            )}
        </SmartContext.Provider>
    );
};

export default DomainDetail;
