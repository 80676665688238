import { useContext } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControl, getDataKeyForSection, getSectionConfig } from '../Core/SmartFunctions';
import { FormControl, FormSection } from '../Core/SmartTypes';

const SmartControl = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);

    const dataKey = props.dataKey ? props.dataKey : getDataKeyForSection(props.sectionId, state);
    const formSection = getSectionConfig(props.sectionId, state) as FormSection;
    const control = formSection.controlGroup.find((control) => control.id === props.controlId) as FormControl;

    return getControl(control, dataKey, state, '');
};

export default SmartControl;
