import { useContext, useEffect } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import FunnelChart from './FunnelChart';
import useManagementDashboard from './useManagementDashboard';

const AlumniOnboarding = () => {
    const { state } = useContext(SmartContext);
    const { getAlumniOnboarding } = useManagementDashboard();
    const criteria = state.data.searchCriteria.alumniOnboarding;
    const data = state.data?.reportData?.alumniOnboarding;

    useEffect(() => {
        getAlumniOnboarding();
    }, [criteria.dateRange, criteria.fromDate, criteria.toDate, criteria.campus]);

    if (isEmpty(state.data?.reportData?.alumniOnboarding)) return null;
    return (
        <div>
            <FunnelChart keys={Object.keys(data)} values={Object.values(data)} />
        </div>
    );
};

export default AlumniOnboarding;
