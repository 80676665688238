import { activateUserSession, getDomainValueForCode, isEmpty, isFormValid, setError } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import { getDomainElementsForGivenYearRange } from '../../../../library/Service/domainService';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';
import axios from 'axios';

export const onStartOrEndYearChange = (props: ControlOnChangeArguments) => {
    const errorMessages = [] as any[];
    if (props.control.id === 'startYear') {
        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: 'underGradCollegeJoined.endYear',
                value: parseInt(props.value) + 3,
            },
        });

        props.dispatch({
            type: 'SET_DOMAIN',
            payload: {
                key: 'UG_END_YEAR_CODE',
                value: getDomainElementsForGivenYearRange('UG_END_YEAR_CODE', parseInt(props.value) + 3, parseInt(props.value) + 6),
            },
        });

        // if (isEmpty(props.state?.data?.underGradCollegeJoined?.endYear) || isEmpty(props.state?.data?.underGradCollegeJoined?.endYear)) {
        //     errorMessages.push('Please enter both start and end year');
        // }
    }

    props.dispatch({
        type: 'SET_FIELD_VALIDATION_ERRORS',
        payload: { dataKey: 'underGradCollegeJoined.ugJoinedStartAndEndYear', errorMessages },
    });

    //alert(props.value);
    // if (
    //     props.value == null ||
    //     (props.control.id === 'startYear'
    //         ? props.state?.data?.underGradCollegeJoined?.endYear
    //         : props.state?.data?.underGradCollegeJoined?.startYear) == null
    // )
    //     errorMessages.push('Please enter both start and end year');

    // props.dispatch({
    //     type: 'SET_FIELD_VALIDATION_ERRORS',
    //     payload: { dataKey: 'underGradCollegeJoined.ugJoinedStartAndEndYear', errorMessages },
    // });
};

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const formValidationPassed = isFormValid(state, dispatch, [
        'underGradCollegeJoined.universityId',
        'underGradCollegeApplied',
        'underGradCollegeJoined.ugJoinedStartAndEndYear',
    ]);
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    if (!isImpersonatedSession && (!formValidationPassed || !customValidationPassed)) {
        alert('Please correct the form errors!');
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state.formValidationErrors);
        return;
    }
        // Send event to Moengage UG College
        sendMoEngageEvent(state?.data);

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            underGradColleges: state?.data['underGradColleges'],
            underGradCollegeJoined: state?.data['underGradCollegeJoined'],
            underGradCollegeApplied: state?.data['underGradCollegeApplied'],
            profile: {
                ...state?.data?.profile,
                appliedToNoOtherUgColleges: state.data.profile.appliedToNoOtherUgColleges,
            },
        })
    ).then((response) => {
        if (response?.status === 200) {
            // Call the API here to update the status as ACTIVE & COMPLETE.
            // activateUserSession(sessionState, sessionDispatch).then(() => {
            //     state.actions['REDIRECT'](`/alumni/${sessionState.id}/profile/ug`);
            // });
            navigateToNextStep(AlumniProfileEditScreens.UG_JOURNEY, state, dispatch, sessionState, sessionDispatch);
        }
    });
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.UG_JOURNEY, state, dispatch);
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    const underGradColleges = [state?.data?.underGradCollegeJoined, ...state?.data?.underGradCollegeApplied];
    const universityIds = new Set();
    let isFormInvalid = false;

    const joinedCollege = state.data.underGradCollegeJoined;

    // if (isEmpty(state.data.underGradCollegeJoined.startYear)) {
    //     isFormInvalid = true;
    //     setError('underGradCollegeJoined.startYear', [`Please select "start year"`], dispatch);
    // }

    // if (isEmpty(state.data.underGradCollegeJoined.endYear)) {
    //     isFormInvalid = true;
    //     setError('underGradCollegeJoined.endYear', [`Please select "end year"`], dispatch);
    // }

    if (isEmpty(state?.data?.underGradCollegeJoined?.endYear) || isEmpty(state?.data?.underGradCollegeJoined?.endYear)) {
        isFormInvalid = true;
        errorMessages.push('Please enter both start and end year');
    }

    if (isEmpty(joinedCollege?.universityId) && isEmpty(joinedCollege?.otherUniversity)) {
        isFormInvalid = true;
        setError('underGradCollegeJoined.universityId', [`Please enter "My Undergraduate Degree Was From"`], dispatch);
    }

    underGradColleges
        .filter((college: any) => college.universityId)
        .forEach((college: any) => {
            const universityName = getDomainValueForCode(college.universityId, `UNIVERSITY_CODE`, state);
            if (universityIds.has(college.universityId)) {
                errorMessages.push(`University with name ${universityName} has already been added.`);
                isFormInvalid = true;
            } else {
                universityIds.add(college.universityId);
            }
        });

    const appliedCount = state?.data?.underGradCollegeApplied.filter(
        (college: any) => !isEmpty(college.universityId) || !isEmpty(college?.otherUniversity)
    )?.length;

    if (appliedCount === 0 && !state.internal['underGradCollegeApplied.skip']) {
        errorMessages.push('Please select at least one college to which you have applied.');
        isFormInvalid = true;
    }

    const notFilledOfferStatusCount = state?.data?.underGradCollegeApplied.filter(
        (college: any) => !isEmpty(college?.universityId) && isEmpty(college?.collegeApplicationResultCode)
    )?.length;

    if (notFilledOfferStatusCount > 0) {
        errorMessages.push('Please select offer status.');
        isFormInvalid = true;
    }

    // Dispatch the validation errors to the state
    dispatch({
        type: 'SET_FIELD_VALIDATION_ERRORS',
        payload: { dataKey: 'underGradCollegeApplied', errorMessages },
    });

    return !isFormInvalid;
};

const sendMoEngageEvent = async (user:any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-ug-updation`, {
            userInfo       : user
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };