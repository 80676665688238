import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import DebugBarControl from '../../library/SimpleControls/DebugBarControl';
import MessageHeader from './MessageHeader';
import MessageRightBar from './MessageRightBar';
import MessageLeftBar from './MessageLeftBar';
import SessionContext from '../../library/Core/SessionContext';
import { useLocation } from 'react-router-dom';

const Messages = () => {
    const { ids } = useParams();
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);
    const [isActive, setActive] = useState(false);

    const location = useLocation();
    const activeTab = location.state?.tab;

    const isPeerReviewed = sessionState?.isPeerReviewed;
    const userType = sessionState?.userTypeCode;
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
    // const [showNewMessage, setShowNewMessage] = useState(false);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        internal: { messagesFromCode: null, messageTypeCode: null, filteredMessages: [], searchText: null },
        actions: { ROUTER_NAVIGATE: navigate },
    } as any);

    const { formConfig, domain, data, internal, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo: { ...routeInfo, ids } },
        });
    }, []);

    if (userType === 'STUDENT' && sessionState?.profileStatusCode === 'PREFERENCES_INCOMPLETE') {
        window.location.href = `/student/${sessionStorage.getItem('user-id')}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    const toggleClass = () => {
        console.log(' Clicked..!!');
        setActive(!isActive);
    };
    return (
        <>
            <SmartContext.Provider value={{ state, dispatch }}>
                <div className="main flex-1 bg-white">
                    <MessageHeader />

                    <section className="alumni_chat_wrap mt-2">
                        <div className="max-1140 mx-auto">
                            <div className="row">
                                <MessageLeftBar activeTab={activeTab} toggleClass={toggleClass} isActive={isActive}/>
                                <MessageRightBar toggleClass={toggleClass} isActive={isActive} />
                            </div>
                        </div>
                    </section>
                </div>
                <DebugBarControl />
            </SmartContext.Provider>
        </>
    );
};

export default Messages;
