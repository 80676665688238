import { useContext, useEffect, useRef, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import useManageAlumni from '../engagement-list/useManageAlumni';
import { FormSection } from '../../../library/Core/SmartTypes';
import FormBuilder from '../../../library/Builders/FormBuilder';
import FiltersApplied from '../../../library/SimpleControls/FiltersApplied';
import AlumniEngagementService from './AlumniEngagementService';
import { getFuzzySearchCriteria, isEmpty } from '../../../library/Core/SmartFunctions';


const SearchCriteria = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useManageAlumni();
    const searchFormConfig = state.formConfig?.sectionRepository[0] as FormSection;
    const [isExpanded, setIsExpanded] = useState(true);
    const [fuzzySearchText, setFuzzySearchText] = useState('');

    const isFirstRender = useRef(true);

    // useEffect(() => {
    //     if (isEmpty(fuzzySearchText))
    //     AlumniEngagementService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
    //             setStateValue('alumniList', response.data);
    //             const fetchAlumniCount = async () => {
    //                 try {
    //                     const alumniCountData = await AlumniEngagementService.searchCount(getFuzzySearchCriteria(fuzzySearchText), state.routeInfo.pageName);
                                        
    //                     dispatch({
    //                         type: 'SET_INTERNAL_STATE',
    //                         payload: { key:"alumniCount", value:alumniCountData?.data },
    //                     });
    //                 } catch (error) {
    //                     console.error("Error fetching alumni count:", error);
    //                 }
    //             };
            
    //             fetchAlumniCount();
    //         });
    // }, [fuzzySearchText]);

    useEffect(() => {
        AlumniEngagementService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
            setStateValue('alumniList', response.data);
            fetchAlumniCount(state);
        });
    }, [state.data.searchCriteria]);


    const handleFuzzySearch = (event: any) => {
        event.preventDefault();
        //if (isEmpty(fuzzySearchText)) return;

        const currentSearchCriteria = state.data.searchCriteria;

        const mergedSearchCriteria = {
            ...currentSearchCriteria,
            ...getFuzzySearchCriteria(fuzzySearchText) 
        };

        AlumniEngagementService.search(mergedSearchCriteria, state.routeInfo.pageName).then((response: any) => {
            setStateValue('alumniList', response.data);
            const fetchAlumniCount = async () => {
                try {
                    const alumniCountData = await AlumniEngagementService.searchCount(mergedSearchCriteria, state.routeInfo.pageName);
                                    
                    dispatch({
                        type: 'SET_INTERNAL_STATE',
                        payload: { key:"alumniCount", value:alumniCountData?.data },
                    });
                } catch (error) {
                    console.error("Error fetching alumni count:", error);
                }
            };
        
            fetchAlumniCount();
        });
    };

    const handleClearAll = () => {
        const defaultSearchCriteria = AlumniEngagementService.getDefaultSearchCriteria();

        defaultSearchCriteria.passOutYear = [];
        defaultSearchCriteria.hasReceivedScholarship = [];
        defaultSearchCriteria.hasAppliedUgEntranceTest = [];
        defaultSearchCriteria.hasRepliedToMsg = [];
        defaultSearchCriteria.hasUploadedGuidanceVideos = [];
        defaultSearchCriteria.hasWorkDtlAdded = [];
        // defaultSearchCriteria.userStatusCode = [];
        defaultSearchCriteria.pursuingEducationLevelCode=[];
        defaultSearchCriteria.programId=[];

        defaultSearchCriteria.universityId=[];
        document.querySelectorAll('.css-1dimb5e-singleValue').forEach(element => {
            element.textContent = ''; 
        }); //universityId text not removed after clear

        // var buttons = document.querySelectorAll('.row.g-3 button');
        // buttons.forEach(function (button) {
        //     button.textContent = '';
        // });
        setStateValue('searchCriteria', defaultSearchCriteria);
    };

    const fetchAlumniCount = async (state:any) => {
        try {
            console.log("SearchCriteriasearchCount")
            const alumniCountData = await AlumniEngagementService.searchCount(state.data.searchCriteria, state.routeInfo.pageName);
            await new Promise(resolve => setTimeout(resolve, 400));
            dispatch({
                type: 'SET_INTERNAL_STATE',
                payload: { key:"alumniCount", value:alumniCountData?.data },
            });
        } catch (error) {
            console.error("Error fetching alumni count:", error);
        }
    }

    return (
        <>
            <div className="d-flex flex-wrap justify-content-between align-items-start align-items-md-center mb-2 filterSearch">
                <div className="d-flex flex-wrap flex-1 mb-0 justify-content-start">
                    <button className="btn-grpfiter me-3" onClick={() => setIsExpanded(!isExpanded)}>
                        <i className="icon-filter font-13 me-2"></i>
                        Filters
                    </button>
                    <div className="search alumniSearch m-0 open position-static flex-1 mb-2">
                        <div className="form-inline d-flex flex-wrap justify-content-between d-block">
                            <input
                                value={fuzzySearchText}
                                placeholder="Search by Alumni Name, Email, User ID"
                                aria-label="Search"
                                className="form-control flex-1 font-13 font-500 text-truncate"
                                onChange={(event) => setFuzzySearchText(event?.target.value)}
                            />
                            <button className="btn btn-search icon icon-search" onClick={handleFuzzySearch}></button>
                        </div>
                        <a href="" className="btn-search-toggle icon-search"></a>
                    </div>
                    {isExpanded && (
                        <div className="col-12 fitergroup p-3 bg-body-secondary">
                            <FormBuilder key="searchCriteria" section={searchFormConfig} dataKey="searchCriteria" />
                        </div>
                    )}
                </div>
            </div>

            <div className="filterresult">
                <FiltersApplied dataKey="searchCriteria" onClearAll={handleClearAll} />
            </div>
        </>
    );
};

export default SearchCriteria;
