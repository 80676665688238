import { useContext } from 'react';
import { SmartContext } from '../../Core/SmartContext';
import './PageTitleControl.css';
const PageTitleControl = () => {
    const { state, dispatch } = useContext(SmartContext);

    const getPageTitle = () =>
        state?.formConfig?.title.split('|').map((part, index) =>
            index % 2 ? (
                <span key={part + index} className="">
                    {''}
                    {part}{''}
                </span>
            ) : (
                part
            )
        );

    return (
        <div className="mb-4">
            <h2>{state?.formConfig?.title && getPageTitle()}</h2>
            <div className="mt-0">
                <h6>{state?.formConfig?.subTitle}</h6>
            </div>
        </div>
    );
};

export default PageTitleControl;
