import React, { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, getFilteredDomain, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const MultiSelectCustomControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State) ?? [];
    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl
    const dropdownRef: RefObject<HTMLDivElement> = useRef(null);

    const parentData = control.props.parentId && getControlValueFromState(parentDataKey + '.' + control.props.parentId, state as State);
    const [parentId, setParentId] = useState(null);
    if (!isEmpty(parentData) && parentId !== parentData) setParentId(parentData);

    const controlDomain = getFilteredDomain(control.props.domainCategoryCode, state, parentId);

    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredDomain, setFilteredDomain] = useState(controlDomain || []);

    const getButtonText = (newVal: any = null, action: string = 'ADD') => {
        if (!controlDomain) return '';
        let selectedNames: any[] = [];

        if (action === 'ADD') {
            selectedNames = controlDomain
                .filter((domain: any) => [...data, newVal].includes(domain.code))
                .map((domain: any) => domain.value);
        }

        if (action === 'REMOVE') {
            const tmpData = data.filter((item: any) => item !== newVal);
            selectedNames = controlDomain.filter((domain: any) => tmpData.includes(domain.code)).map((domain: any) => domain.value);
        }

        if (selectedNames.join(', ').length > 20) return `${selectedNames.length} selected`;
        return selectedNames.join(', ') ?? '';
    };

    const [selectedItemsLabel, setSelectedItemsLabel] = useState(getButtonText());

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (searchTerm) {
            const filtered = controlDomain.filter((domain: any) => domain.value.toLowerCase().includes(searchTerm.toLowerCase()));
            setFilteredDomain(filtered);
        } else {
            setFilteredDomain(controlDomain);
        }
    }, [searchTerm, parentId]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const errorMessages = validateFormField(control, event.target.value, state, control.props.label, dataKey);
        const eventType = event.target.checked ? 'CHECKBOX_ADD_NEW_RECORD_IN_ARRAY' : 'DELETE_RECORD_IN_SIMPLE_ARRAY';
        dispatch({ type: eventType, payload: { dataKey, name: control.id, value: event.target.value, errorMessages } });
        setSelectedItemsLabel(getButtonText(event.target.value, event.target.checked ? 'ADD' : 'REMOVE'));
    };

    const handleClearAll = () => {
        setSelectedItemsLabel('');
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey, value: [] } });
    };

    return (
        <div className="dropdown" ref={dropdownRef}>
            <label htmlFor={control.id} className="form-label w-100">
                {`${control.props.label} `}
            </label>
            <button className="form-select" type="button" onClick={() => setIsOpen(!isOpen)}>
                {selectedItemsLabel}
            </button>

            {isOpen && (
                <div className="dropdown-menu w-100" style={{ display: 'block', background: 'white' }}>
                    <div className="dropdown-wrap">
                        <div className="p-2">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={`Search ${control.props.label}`}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <a role="button" className="dropdown-item" onClick={handleClearAll}>
                            Clear All
                        </a>
                        {filteredDomain?.length > 0 ? (
                            filteredDomain.map((domain: any) => (
                                <div key={domain.code} className="dropdown-item">
                                    <label>
                                        <input
                                            id={domain.code.toString()}
                                            type="checkbox"
                                            name={dataKey}
                                            className="form-check-input"
                                            value={domain.code}
                                            checked={data?.some((item: any) => item == domain.code)}
                                            onChange={(event) => handleChange(event)}
                                            ref={formControlRef}
                                        />
                                        {domain.value}
                                    </label>
                                </div>
                            ))
                        ) : (
                            <div className="dropdown-item text-muted">No results</div>
                        )}
                        <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiSelectCustomControl;
