import { getCardType } from '../../../library/Service/cardTypeServce';
import CardBreak from './CardBreak';
import CardExam from './CardExam';
import CardHomeMaker from './CardHomeMaker';
import CardNoPreparingExam from './CardNoPreparingExam';
import CardPreparingExam from './CardPreparingExam';
import CardSchool from './CardSchool';
import CardUniversity from './CardUniversity';
import CardWorking from './CardWorking';
import CardWorkingOutcome from './CardWorkingOutcome';


const CardBottom = ({ alumni, state }: any) => {
   
    const cardType = getCardType(alumni, state?.data?.cardViewMode);
    console.log("cardType",cardType)

    switch (cardType) {
        case 'WORKING':
            return <CardWorking alumni={alumni} state={state} />;
        case 'WORKING_OUTCOME':
                return <CardWorkingOutcome alumni={alumni} state={state} />;
        case 'UG':
        case 'JOINED_UG':
            return <CardUniversity alumni={alumni} cardType={cardType} state={state} />;
        case 'PG':
        case 'JOINED_PG':
            return <CardUniversity alumni={alumni} cardType={cardType} state={state} />;
        case 'ENTRANCE_EXAM':
            return <CardExam alumni={alumni} cardType={cardType} state={state} />;
        case 'TOOK_BREAK':
            return <CardBreak alumni={alumni} cardType={cardType} state={state} />;
        case 'Doctoral':
                return <CardUniversity alumni={alumni} cardType={cardType} state={state} />;
        case 'HOME_MAKER':
            return <CardHomeMaker alumni={alumni} state={state} />;
        case 'PREPARED_EXAM':
            return <CardPreparingExam alumni={alumni} state={state} />;
        case 'NO_PREPARED_EXAM':
                return <CardNoPreparingExam alumni={alumni} state={state} />;
        default:
            return <CardSchool alumni={alumni} state={state} />;
    }
};

export default CardBottom;
