import { Buffer } from 'buffer';
import { useContext, useEffect, useRef, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getFlexColDomainValueForCode, handleCopyText, isEmpty, numberToOrdinal } from '../../../library/Core/SmartFunctions';
import SessionContext from '../../../library/Core/SessionContext';


const getBaseUrl = (alumniItem:any,state:any) => {

    if (alumniItem?.autologin) {
        return alumniItem.autologin;
    }

    const flexCol = getFlexColDomainValueForCode(alumniItem?.instituteId, 'INSTITUTE_DOMAIN', state);
    const whitelabel_url = isEmpty(flexCol) ? '' : JSON.parse(flexCol).whitelabel_url;
    const baseUrl=!isEmpty(whitelabel_url) ? whitelabel_url : `${process.env.REACT_APP_APP_BASE_URL}/`;
    const finalUrl = `${baseUrl}sso/${alumniItem?.userHash}/cHJvZmlsZQ==`;
    return finalUrl;
};

const MatchList = ({ data, slot, uniqueId }: any) => {
    const seriesData = data?.filter((item: any) => item.slot === slot);
    const { sessionState } = useContext(SessionContext);
    const [isExpanded, setIsExpanded] = useState(false);

    const { state, dispatch } = useContext(SmartContext);

    if (!seriesData || !seriesData.length) return null;

    let first, second, others;

    if (seriesData.length === 1) {
        [first] = seriesData;
        others = [];
    } else {
        [first, second, ...others] = seriesData;
    }

    return (
        <>
            <ul className="match-list">
                {first && (
                    <li>
                        <a
                            className="tablink font-16 font-500"
                            // href={getBaseUrl(first,state)}
                            href={`/alumni/${first.alumniId}/profile`}
                            target="_blank"
                            rel="noreferrer">
                            {first.name} - {first.batch}
                        </a>
                    </li>
                )}
                {second && (
                    <li>
                        <a
                            className="tablink font-16 font-500"
                            // href={getBaseUrl(second,state)}
                            href={`/alumni/${second.alumniId}/profile`}
                            target="_blank"
                            rel="noreferrer">
                            {second.name} - {second.batch}
                        </a>
                    </li>
                )}
            </ul>
            {isExpanded && (
                <ul className="match-list">
                    {others.map((item, index) => (
                        <li key={index}>
                            <a
                                className="tablink font-16 font-500"
                                href={`/alumni/${item.alumniId}/profile`}
                                target="_blank"
                                rel="noreferrer">
                                {item.name} - {item.batch}
                            </a>
                        </li>
                    ))}
                </ul>
            )}
            {others.length > 0 && (
                <button className="show-more-btn" onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? '- Less' : `+ ${others.length} More`}
                </button>
            )}
        </>
    );
};

const Grid = () => {
    const { state, dispatch } = useContext(SmartContext);
    const studentList = state.data.studentList;
    const [defaultDataListLength, setDefaultDataListLength] = useState(studentList.slice(0, 20));
    const loadMoreRef = useRef(null);
    const [hasMore, setHasMore] = useState(true);
  
    const { sessionState } = useContext(SessionContext);

    const fetchMoreStudents = () => {
        if (defaultDataListLength.length >= studentList.length) {
            setHasMore(false);
            return;
        }

        const startIndex = defaultDataListLength.length;
        const endIndex = startIndex + 10;
        const newDisplayedData = studentList.slice(startIndex, endIndex);

        setDefaultDataListLength((previousData: any) => [...previousData, ...newDisplayedData]);
    };

    useEffect(() => {
        const loadMoreObserver = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                fetchMoreStudents();
            }
        });

        if (loadMoreRef.current) loadMoreObserver.observe(loadMoreRef.current);

        return () => {
            if (loadMoreRef.current) {
                loadMoreObserver.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, defaultDataListLength, studentList]);

    // Reset data when schoolList changes
    useEffect(() => {
        setDefaultDataListLength(studentList.slice(0, 20));
    }, [studentList]);

    function maskMobile(phone:any) {
        const parts = phone.split('-');
        const countryCode = parts[0];
        const number = parts[1];
        const maskedNumber = number.slice(0, 2) + '****' + number.slice(-2);
        return `${countryCode}-${maskedNumber}`;
    }

    const maskEmail = (email: string) => {
        const atIndex = email.indexOf('@');
        if (atIndex !== -1) {
            return email[0] + email.substring(1, atIndex).replace(/./g, 'X') + email.substring(atIndex);
        }
        return email; // Handle the case where '@' is not found, e.g., for invalid email addresses
    };

    return (
        <div>
          <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                        <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks my-2">
                            <div className="d-flex justify-content-start align-items-center customselect_parnt"></div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-end">
                            <h3 className="font-14 font-500 mb-0 me-2">
                                Total {state?.internal?.studentCount && state?.internal?.studentCount[0]?.totalCount} records
                            </h3>
                        </div>
                    </div>
            <div className="table-responsive attendee_dtl">
                <table className="table" style={{ minWidth: '1360px' }}>
                    <thead>
                        <tr>
                            <th>
                                <div className="d-flex flex-wrap">Student Name &amp; Email ID</div>
                            </th>
                            <th className="text-start">
                                <div className="labelItem">Phone Number</div>
                            </th>
                            <th className="">Program Name</th>
                            <th className="">Program Level</th>
                            <th className=" text-center">Completion Year</th>
                            {/* <th className="text-center">
                                <div className="labelItem">
                                    Alumni
                                    <br /> Matches Found
                                </div>{' '}
                            </th> */}
                            <th className="">
                                <div className="labelItem">Alumni Matched 1</div>
                            </th>
                            <th className="">
                                <div className="labelItem">Alumni Matched 2</div>
                            </th>
                            <th className="">
                                <div className="labelItem">Alumni Matched 3</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {defaultDataListLength.map((student: any) => (
                            <tr>
                                <td>
                                    <div className="name_email">
                                        <a
                                            className="tablink font-16 font-500"
                                            // href={process.env.REACT_APP_APP_BASE_URL + `/sso/` + student.studentHash + '/~)^'}
                                            href={`/alumni/${student?.studentId}/profile`}
                                            target="_blank"
                                            rel="noreferrer">
                                            {student.studentName}
                                        </a>

                                        <a
                                            href={`/alumni/${student?.studentId}/profile`}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            {maskEmail(student.studentEmail)}
                                        </a>
                                        <div className="copyId d-flex align-items-center mt-1">
                                            <span className="font-13 fst-italic singlelinename me-1"> {student.studentId} </span>
                                            <button
                                                id={`uuid-${student.studentId}`}
                                                className="copy-text border-0 bg-transparent"
                                                title="click to copy id"
                                                onClick={() => handleCopyText(student.studentId, `uuid-${student.studentId}`)}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-copy"
                                                    viewBox="0 0 16 16">
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div>{student.studentPhoneNumber ? maskMobile(student.studentPhoneNumber) : ''}</div>
                                </td>
                                <td>{student?.studentProgramName}</td>
                                <td>
                                    <div>
                                        {student.studentClassCode}
                                        {/* {numberToOrdinal(student.studentClassCode)}  */}
                                    </div>
                                </td>
                                <td className="text-center">{student.studentBatchYearCode}</td>
                                {/* <td className="text-center">{student.totalAlumniCount}</td> */}
                                <td className="text-start" valign="top">
                                    <MatchList data={student.matchedAlumni} slot={1} uniqueId={student.studentHash} />
                                </td>
                                <td className="text-start" valign="top">
                                    <MatchList data={student.matchedAlumni} slot={2} uniqueId={student.studentHash} />
                                </td>
                                <td className="text-start" valign="top">
                                    <MatchList data={student.matchedAlumni} slot={3} uniqueId={student.studentHash} />
                                </td>
                            </tr>
                        ))}
                        <tr ref={loadMoreRef} />
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Grid;
