import {  Tooltip } from 'bootstrap';
import React,{useEffect} from 'react';
import moment from 'moment';


const UpdateActionModal = ({data}:any) => {
    const { alumniData, viewSentHistory,getValuableQaModal}=data;
   
    useEffect(() => {
      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    });


   

return (
    <div className="modal fade px-3" id="update-action" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" style={{ display: 'none' }} aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered max-800 mx-auto">
      <div className="modal-content">
        <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
          <h5 className="modal-title" id="staticBackdropLabel">Update Lead Status</h5>
          <button type="button" className="btn-close btn-close-small" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body p-4">
          <form className="needs-validation" noValidate>
            <div className="row g-4">
              <div className="col-md-4">
                <label className="form-label">Lead status</label>
                <select className="form-select me-2" > 
                  <option value="">Select Status</option>
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                  <option value="2">Deleted</option>
                </select>
              </div>
              
            </div>
            
            <div className="action_btns mt-3">
            <button type="submit" className="btn btn-green height-45 px-4"><span>Update</span></button> 
            <a href="javascript:;" className="btn btn-green height-45 px-4" data-bs-toggle="modal">Close</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
    );
  };

  export default UpdateActionModal;