import { useContext } from 'react';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { evaluateExpression, isEmpty } from '../../../../library/Core/SmartFunctions';
import SmartControl from '../../../../library/SimpleControls/SmartControl';
import { getYear } from './BenefitsActions';

const BenefitsCareerSupport = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { globalState } = useContext(GlobalContext);

    const passOutYear = state.data?.profile?.passOutYear as number;
    const tookGap = state.data?.profile?.didYouTakeGapAfter12;
    const presentStatus = tookGap ? 'TOOK_GAP' : (state.data?.profile?.educationOrEmploymentCurrentStatusCode as string);

    const isVisible = (question: any) => {
        if (isEmpty(question.preCondition)) return true;
        if (question.preCondition) return evaluateExpression(question.preCondition as string, state.data, state.data.benefits);
        return false;
    };

    const getCareer = (batchYear: number, presentStatus: string) => {
        // const careers = JSON.parse(sessionStorage.getItem('benefits-config') as string);
        const careers = globalState?.appSettings?.benefitsConfig;

        const career = careers.filter(
            (item: any) =>
                batchYear >= getYear(item.fromYear) &&
                batchYear <= getYear(item.toYear) &&
                item.presentStatusCode.includes(presentStatus) &&
                item.nextMoveTypeCode === 'CAREER_SUPPORT'
        );
        return career;
    };

    return (
        <>
            {!state?.flags.isDataLoading &&
                getCareer(passOutYear, presentStatus)?.map((item: any) => {
                    return (
                        isVisible(item) && (
                            <SmartControl key={`benefits.${item.code}`} sectionId="benefits" controlId={item.code} dataKey="benefits" />
                        )
                    );
                })}
        </>
    );
};

export default BenefitsCareerSupport;
