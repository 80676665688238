import { isFormValid } from '../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import logger from '../../../../library/Core/logger';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import axios from 'axios';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    logger.log(`Signing up the new user`);
    logger.log(state.data['user']);
    isFormValid(state, dispatch) ? logger.log('Form is valid') : logger.log('Form is invalid. Please correct the errors.');
    //state['data']['user']['school_id'] = 1;
    logger.log(state?.data);

    // Send event to Moengage Consent
    sendMoEngageEvent(state?.data);

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            profile: state?.data['profile'],
            mentoringInterests: state?.data['mentoringInterests'],
        })
    // ).then(() => ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.MENTORING_INTEREST, state, dispatch));
    ).then(() => {
        if (state?.data?.userStatusCode != 'ACTIVE') {
            ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.MENTORING_INTEREST, state, dispatch)
        } else {
            state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile/mentoring-interests`);
        }
    });
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.MENTORING_INTEREST, state, dispatch);
};

const sendMoEngageEvent = async (user:any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-consent`, {
            userInfo       : user
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return false;
  };
