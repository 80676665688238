import React, { useState } from 'react';
import { axiosClient } from '../../../library/Service/axiosClient';

const VerifyMobileControl = (props: any) => {
    const [otp, setOtp] = useState(['', '', '', '']);

    const handleClose = (event: any) => {
        event.preventDefault();
        props.setShowVerifyMobile(false);
    };

    const handleResend = (event: any) => {
        event.preventDefault();
    };

    const handleVerify = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        const enteredOtp = otp.join('');
        if (enteredOtp.length === 4) {
            const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/sms/verify-otp`, { otp: enteredOtp });
            console.log('Verifying OTP:', enteredOtp);
        } else {
            console.log('Please enter a valid OTP');
        }
    };
    const handleOtpChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const updatedOtp = [...otp];
        updatedOtp[index] = value;
        setOtp(updatedOtp);
    };
    return (
        <div
            className="modal fade show"
            id="Verify_mobile"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            style={{ display: 'block' }}
            aria-modal="true"
            role="dialog">
            <div className="modal-dialog modal-dialog-centered max-700 mx-auto">
                <div className="modal-content">
                    <div className="modal-header border-0 pt-5">
                        <h5 className="modal-title font-24 text-dark-blue text-center w-100" id="staticBackdropLabel">
                            Let’s verify your mobile number
                        </h5>
                        <button type="button" className="btn-close btn-close-small" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body pt-0" id="OTPBLOCK">
                        <div className="p-4 bg-gray radius-10 mx-2">
                            <p className="font-16 text-gray-41 text-center">Please enter the OTP sent to</p>
                            <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                                <span className="font-24">{props.mobileNumber} </span>
                                {/* <button className="btn-edit mt-3 mt-sm-0">
                                    <i className="icon-edit me-1"></i>Edit Number
                                </button> */}
                            </div>
                            <div className="d-flex justify-content-center my-4 " id="Otpinput">
                                <input
                                    className="input-fieldss input-otp text-center"
                                    type="text"
                                    placeholder=""
                                    name="otp"
                                    id="one"
                                    required={true}
                                    maxLength={1}
                                    style={{ borderColor: 'rgb(132, 132, 132)' }}
                                    onChange={(e) => handleOtpChange(0, e)}
                                />

                                <input
                                    className="input-fieldss input-otp text-center "
                                    name="otp"
                                    type="text"
                                    placeholder=""
                                    id="two"
                                    required={true}
                                    maxLength={1}
                                    style={{ borderColor: 'rgb(132, 132, 132)' }}
                                    onChange={(e) => handleOtpChange(1, e)}
                                />

                                <input
                                    className="input-fieldss input-otp text-center "
                                    name="otp"
                                    type="text"
                                    placeholder=""
                                    id="three"
                                    required={true}
                                    maxLength={1}
                                    style={{ borderColor: 'rgb(132, 132, 132)' }}
                                    onChange={(e) => handleOtpChange(2, e)}
                                />

                                <input
                                    className="input-fieldss input-otp text-center "
                                    name="otp"
                                    type="text"
                                    placeholder=""
                                    id="four"
                                    required={true}
                                    maxLength={1}
                                    style={{ borderColor: 'rgb(132, 132, 132)' }}
                                    onChange={(e) => handleOtpChange(3, e)}
                                />
                            </div>
                            <a href="" onClick={handleVerify} role="button" id="submitbtn" className="btn-green max-190 mx-auto">
                                <span>Verify</span>
                            </a>
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center py-3">
                            <p className="font-16 text-gray-41 m-0">Didn’t receive the OTP?</p>
                            <span id="load"></span>
                            <a
                                href=""
                                className="btn-border-gray width-97 mx-auto my-2"
                                id="Resend"
                                onClick={handleResend}
                                style={{ display: 'none' }}>
                                <span className="position-relative">Resend</span>
                            </a>
                            <span className="font-14 text-gray-41 fst-italic">
                                <span id="countdown">Resend after 0:51 minutes</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyMobileControl;
