import { useContext } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { axiosClient } from '../../../library/Service/axiosClient';
import EventsService from './EventsService';

const useEvents = () => {
    const { state, dispatch } = useContext(SmartContext);

    const performSearch = async (action: string) => {
        if (action === 'TAB_CHANGE') await performUpdateFilters(action);
        const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/event`, getSearchCriteria());
        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: 'events',
                value: [
                    ...(response.data?.response?.result?.past_events ?? []),
                    ...(response.data?.response?.result?.upcoming_events ?? []),
                ],
            },
        });

        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: 'counts', value: response.data?.response?.result?.counts },
        });
    };

    const performUpdateFilters = async (selectedTab: string = '') => {
        // const response: any = await EventsService.pageLoader({ searchBy: state.data.searchBy });
        const searchBy = selectedTab === '' ? state.data.searchBy : selectedTab;
        const filterDomain = await EventsService.getDefaultFilterDomain(searchBy);

        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'filters', value: EventsService.getDefaultFilters() } });
        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: 'filterDomain', value: filterDomain },
        });
    };

    const getSearchCriteria = () => {
        const criteria: any = getDefaultSearchCriteria();
        for (let prop in state?.data.filters) {
            if (criteria.param.hasOwnProperty(prop)) {
                // This logic to be changes based on how events filters are implemented
                criteria.param[prop] = state?.data?.filters[prop].join(',');
            }
        }
        criteria.param.upcoming_past = state?.data?.searchBy === 'upcoming' ? 1 : 2;
        criteria.param.login_ref_id = sessionStorage.getItem('user-login-id');
        return criteria;
    };

    const getDefaultSearchCriteria = () => {
        const userTypeCode = sessionStorage.getItem('user-type-code') as string;
        let req_from = EventsService.getRequestFrom();
        let student_class = null;
        let board_ref_id = null;

        if (userTypeCode === 'STUDENT') {
            student_class = sessionStorage.getItem('user-class');
            board_ref_id = sessionStorage.getItem('user-board-ref-id');
        }

        return {
            name: 'events',
            param: {
                req_from,
                school_ref_id: 34692,
                //login_ref_id: sessionStorage.getItem('user-login_ref_id'),
                login_ref_id: '',
                eve_types: '',
                upcoming_past: state?.data?.searchBy === 'upcoming' ? '1' : '2',
                eve_presenter: '',
                eve_themes: '',
                eve_test: '',
                eve_skill: '',
                eve_country: '',
                eve_university: '',
                eve_inds: '',
                eve_pdt: '',
                eve_career: '',
                passout_year: sessionStorage.getItem('pass-out-year'),
                eve_department: '',
                class: student_class,
                board_ref_id: board_ref_id,
                start: 0,
                limit: 1000,
            },
        };
    };

    return { performSearch, performUpdateFilters };
};

export default useEvents;
