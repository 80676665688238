import { useEffect } from 'react';
import { getDomainValueForCode, isEmpty } from '../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, State } from '../../library/Core/SmartTypes';
import { axiosClient } from '../../library/Service/axiosClient';
import pageConfig from './PageConfig.json';
import { useLoaderData } from 'react-router-dom';

class SchoolApiService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const { schoolId, schoolGroupId } = params;
        const searchCriteria = SchoolApiService.getDefaultSearchCriteria(schoolId, schoolGroupId, params.pageName);

        return Promise.all([
            SchoolApiService.getPageConfig(),
            SchoolApiService.search(searchCriteria, params.pageName),
            SchoolApiService.getSchoolGroupDomain(),
        ]).then((values) => {
            state.formConfig = values[0];
            state.data = {
                studentList: values[1].data ?? [],
                searchCriteria,
            };
            state.routeInfo = params;
            state.internal = { gridState: {} };
            state.internal.schoolGroupDomain = values[2].data;
            return Promise.resolve(state);
        });
    }

    static getDefaultSearchCriteria(schoolId: number, schoolGroupId: number, pageName: string = '') {
        const searchCriteria: any = {};
        if (!isEmpty(schoolId)) searchCriteria.schoolId = schoolId;
        if (!isEmpty(schoolGroupId)) searchCriteria.schoolGroupId = schoolGroupId;
        return searchCriteria;
    }

    static search(searchCriteria: any, pageName: string = '') {
        return axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/schook-api-key/school-api-details`, searchCriteria);
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    static onSchoolChange(props: ControlOnChangeArguments) {
        let schoolName = getDomainValueForCode(props.value, 'SCHOOL_CODE', props.state);

        props.dispatch({
            type: 'SET_INTERNAL_STATE',
            payload: { key:"schoolName", value:schoolName },
        });
    }
    
    static onSchoolGroupChange(props: ControlOnChangeArguments) {
        let schoolGroupName = getDomainValueForCode(props.value, 'SCHOOL_GROUP', props.state);

        props.dispatch({
            type: 'SET_INTERNAL_STATE',
            payload: { key:"schoolGroupName", value:schoolGroupName },
        });
        
        props.dispatch({
            type: 'SET_INTERNAL_STATE',
            payload: { key:"schoolName", value:"" },
        });
    }

    static getSchoolGroupDomain() {
        return axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/schook-api-key/school-group-domain`);
    }

    static apiKeyGenerate(schoolId:any) {
        // console.log('1233');
        // console.log(schoolId,'service school id');
        let postData = {"schoolId":schoolId}
        // let postData = JSON.stringify(data); 
        return axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/schook-api-key/school-apikey-generate`,postData);
    }
}

export default SchoolApiService;
