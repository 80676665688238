import { axiosClient } from '../../../library/Service/axiosClient';

export const sendRequest = async (alumniList: any[], id: string,approvalType:string) => {
    try {
        const createRequestResponse = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/create-approve-request`, {
            approverId: id,
            approvalType:approvalType
        });

        const response = await axiosClient().post(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations`, {
            memberIds: [id],
            message: 'Hello! I am interested in joining our school Alumni Network. Please validate my profile.',
            userTypeCode: 'ALUMNI',
            invitationType: 'alumniVerification',
        });

        const updatedAlumni: any = alumniList.map((alumni) => {
            if (alumni.uid == id) {
                return { ...alumni, requestSent: true };
            }
            return alumni;
        });

        console.log(updatedAlumni);

        return updatedAlumni;
        // // const response:any = {data:"test"}
        // const data = await response.data; // Extract the data from the response
    } catch (error) {
        console.error('Error fetching data:', error);
        alert(error);
    }
};
