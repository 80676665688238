import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import { handleCompleteLater, handleNext, onStartOrEndYearChange } from './UnderGraduateActions';
import UnderGraduateLayout from './UnderGraduateLayout';

const UnderGraduate = () => {
    const navigate = useNavigate();
    const { globalState } = useContext(GlobalContext);
    const [redirectUrl, setRedirectUrl] = useState('');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            NEXT: handleNext,
            I_LL_DO_THIS_LATER: handleCompleteLater,
            ROUTER_NAVIGATE: navigate,
            startYear: onStartOrEndYearChange,
            endYear: onStartOrEndYearChange,
            REDIRECT: setRedirectUrl
        },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        data['underGradCollegeJoined'] = data['underGradCollegeJoined'] || {};
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });

        if (data?.underGradCollegeJoined?.startYear == null || data?.underGradCollegeJoined?.endYear == null) {
            dispatch({
                type: 'SET_FIELD_VALIDATION_ERRORS',
                payload: {
                    dataKey: 'underGradCollegeJoined.ugJoinedStartAndEndYear',
                    errorMessages: ['Please enter both start and end year'],
                },
            });
        }
    }, []);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <UnderGraduateLayout>{!state.flags.isDataLoading && <PageBuilder />}</UnderGraduateLayout>
        </SmartContext.Provider>
    );
};

export default UnderGraduate;
