import { useContext, useEffect, useState } from 'react';
import Select, { ActionMeta, OnChangeValue } from 'react-select';

import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState } from '../Core/SmartFunctions';
import { DomainElement, SimpleFormControlArguments, State } from '../Core/SmartTypes';
import './custom-control.css';

// Reference: https://stackoverflow.com/questions/45940726/populate-react-select-with-image

const MultiSelectControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const [options, setOptions] = useState([] as any[]);

    useEffect(() => {
        const controlDomain = state?.domain?.get(control.props.domainCategoryCode) as DomainElement[];
        setOptions(controlDomain.map((domain) => ({ value: domain.code, label: domain.value })));
    }, []);

    const tmpVal: DomainElement[] = (state?.domain?.get(control.props.domainCategoryCode) as DomainElement[]).filter((item) =>
        item.code.includes(data)
    );

    const selectedValues = tmpVal.map((item) => ({ value: item?.code, label: item?.value }));

    const handleOnChange = (newValue: OnChangeValue<any, any>, actionMeta: ActionMeta<any>) => {
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey, name: control.id, value: newValue, errorMessages: [] } });
    };

    return (
        <>
            {control.props?.label && (
                <label htmlFor={control.id} className="form-label">
                    {`${control.props.label} `}
                </label>
            )}
            <div className="input-group">
                <div className="input-group-text bg-white rounded-start">
                    <i className="bi bi-search fs-6"></i>
                </div>
                <div
                    className="flex-fill form-control border-start-0 p-0"
                    style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}>
                    <Select
                        isMulti
                        name="colors"
                        options={options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        defaultValue={selectedValues}
                        onChange={handleOnChange}
                    />
                </div>
            </div>
        </>
    );
};

export default MultiSelectControl;
