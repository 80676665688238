import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../library/Core/GlobalContext';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, DomainElement, State } from '../../library/Core/SmartTypes';
import DebugBarControl from '../../library/SimpleControls/DebugBarControl';
import Layout from './Layout';
import { stat } from 'fs';
import { getDomainValueForCode } from '../../library/Core/SmartFunctions';
import SchoolApiService from './SchoolApiService';

const SchoolApi = () => {
    const { globalState } = useContext(GlobalContext);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        internal: {
            "schoolName": '',
            "schoolGroupName": ''
        },
        actions: {
            schoolId: SchoolApiService.onSchoolChange,
            schoolGroupId: SchoolApiService.onSchoolGroupChange
        },
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        domain.set('SCHOOL_GROUP_CODE', internal.schoolGroupDomain);

        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, internal, routeInfo },
        });

        const schoolName = (domain?.get('SCHOOL_CODE')?.find((element) => element.code == data.searchCriteria.schoolId) as DomainElement)?.value;

        dispatch({
            type: 'SET_INTERNAL_STATE',
            payload: { key:"schoolName", value:schoolName },
        });

        const schoolGroupName = (domain?.get('SCHOOL_GROUP')?.find((element) => element.code == data.searchCriteria.schoolGroupId) as DomainElement)?.value;
        
        dispatch({
            type: 'SET_INTERNAL_STATE',
            payload: { key:"schoolGroupName", value:schoolGroupName },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <DebugBarControl />
            {!state.flags.isDataLoading && <Layout />}
        </SmartContext.Provider>
    );
};

export default SchoolApi;
