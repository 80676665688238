import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import VerificationService from './VerificationService';

const EmailVerification = () => {
    const { id } = useParams();

    const [showModal, setShowModal] = useState(false); // initialize state for modal
    const [emailVerifyResponseText, setReponseText] = useState(''); // initialize state for modal
    //let VerificationMEssage = '';
    useEffect(() => {
        // show modal on page load

        // const response = VerificationService.verifyMyEmail(id);
        VerificationService.verifyMyEmail(id)
            .then((result) => {
                //console.log(result.message);
                //VerificationMEssage = result?.message;
                setReponseText(result?.message);
                setShowModal(true);
            })
            .catch((error) => {
                setReponseText('Please try again');
            });
    }, []);

    return (
        <div className="container max-1140 px-lg-0">
            <div className="row">
                <div className="col-md-12 mb-4">Alumni Verification</div>
                <div
                    id="myModal"
                    data-backdrop="static"
                    data-keyboard="false"
                    className={`modal fade in ${showModal ? 'show d-block' : ''}`}
                    tabIndex={-1}
                    role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" onClick={() => setShowModal(false)}>
                                    &times;
                                </button>
                                <h4 className="modal-title">Email Verified Successfully</h4>
                            </div>
                            <div className="modal-body">
                                <p>{emailVerifyResponseText}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => setShowModal(false)}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailVerification;
