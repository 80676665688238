import React, { useContext, useEffect, useRef } from 'react';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, formatDate, getControlValueFromState, handleControlValueChange, parseDateStr } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const YearSelector = (args: SimpleFormControlArguments) => {
  const { state, dispatch } = useContext(SmartContext);
  const { control, dataKey, parentDataKey } = { ...args };
  const formControlRef = useRef(null);
  const data = getControlValueFromState(dataKey, state as State);
  const parsedDate = data ? new Date(`${data}-01-15`) : null;
  const formattedDate = parsedDate ? formatDate(parsedDate, 'yyyy') : '';

  const currentYear = new Date().getFullYear();
  const maxSelectableYear = currentYear; // Maximum selectable year is current year

  const readOnly = evaluateExpression(
    control.readOnlyExpression,
    state?.data,
    getControlValueFromState(parentDataKey as string, state as State)
  );

  useEffect(() => {
    const errorMessages = validateFormField(
      control,
      data,
      state,
      control?.props?.label,
      dataKey,
      getControlValueFromState(parentDataKey as string, state as State)
    );
    dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });
  }, []);

  return (
    <>
      <label htmlFor={control.id} className="form-label w-100">
        {`${control.props.label} ${control.props.required ? '*' : ''}`}
      </label>

      <div className='react-datepicker-cnt'>
        <DatePicker
          id="year-picker"
          className='form-control form-date only_year'
          selected={formattedDate ? new Date(formattedDate) : null}
          showYearPicker
          dateFormat="yyyy"
          maxDate={new Date(maxSelectableYear, 11, 31)} // Set maximum selectable year
          onChange={(dateVal: any) =>
            handleControlValueChange({ control, value: formatDate(dateVal, 'yyyy'), dataKey, parentDataKey, state, dispatch })
          }
          required={control.props?.required}
        />
      </div>

      <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
    </>
  );
};

export default YearSelector;