import { activateUserSession, isEmpty, isFormValid, setError } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';
import axios from 'axios';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const inValidDate = isValidDate(state, dispatch);
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    // let customValidationPassed = true;
    if (
        !isImpersonatedSession &&
        !state?.data?.profile?.haveNoWorkExp &&
        (!isFormValid(state, dispatch, ['jobs.*.companyId']) || !customValidationPassed || !inValidDate)
    ) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }
    let jobHistory = state?.data['jobs'];

    if (Array.isArray(jobHistory)) {
        // Update endDate for jobs where isCurrent is clicked
        jobHistory = jobHistory.map((job) => {
            // Check if the job is current then set endDate to null
            let recordTypeCode = isEmpty(job.recordTypeCode) ? 'JOINED' : job.recordTypeCode;
            if (job.isCurrent) {
                return { ...job, endDate: null, recordTypeCode: recordTypeCode };
            } else {
                // Return the job as it is not current
                return { ...job, recordTypeCode: recordTypeCode };
            }
        });
    }

    jobHistory = [...jobHistory, ...state?.data?.jobsApplied];

    // Send event to Moengage PG College
    sendMoEngageEvent(state?.data);

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobs: jobHistory,
            profile: state?.data['profile'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            // Call the API here to update the status as ACTIVE & COMPLETE.
            // const workOutcomeList = ['JOINED_COMPANY', 'ENTREPRENEURSHIP']
            // if ( workOutcomeList.includes(state?.data?.selectedInstitute?.postProgramCompletionStatusCode) ) {
            //     activateUserSession(sessionState, sessionDispatch).then(() => {
            //         state.actions['REDIRECT'](`/alumni/${sessionState.id}/profile/work`);
            //     });
            // } else {
            //     if ( sessionState?.userStatusCode == 'ACTIVE' ) {
            //         state.actions['REDIRECT'](`/alumni/${sessionState.id}/profile/work`);
            //     } else {
            //         state.actions['REDIRECT'](`/alumni/${sessionState.id}/outcome`);
            //     }
            // }
            
            navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, {...state,data:{...state.data,jobs:response?.data?.jobs}}, dispatch, sessionState, sessionDispatch);
        }
    });
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const jobs = state.data.jobs;

    jobs.forEach((job: any, index: number) => {
        if (isEmpty(job?.companyId) && isEmpty(job?.otherCompany)) {
            isFormInvalid = true;
            setError(`jobs.${index}.companyId`, [`Please enter "I Was an Employee at"`], dispatch);
        } else {
            setError(`jobs.${index}.companyId`, [], dispatch);
        }
    });

    // Dispatch the validation errors to the state
    if (isFormInvalid) {
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'jobs', errorMessages },
        });
    }

    return !isFormInvalid;
};

export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
    const rowIndex = parseInt(props.parentDataKey.split('.').pop() as string);
    const jobs = props.state.data.jobs.map((row: any, index: number) => {
        setError(`jobs.${index}.endDate`, [], props.dispatch); 
        return index == rowIndex ? { ...row, endYear: '', isCurrent: false } : { ...row, isCurrent: false };
    });
    props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.jobStartAndEndDates`, value: [] } });

    props.dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'jobs', value: jobs } });
};

export const onStartDateChange = (props: ControlOnChangeArguments) => {
    alert(props.row.endDate);
    const errorMessages =
        new Date(props.value) > new Date(props.row.endDate) ? ['Start year should be less than or equal to End year'] : [];
    // props.dispatch({
    //     type: 'SET_FIELD_VALIDATION_ERRORS',
    //     payload: { dataKey: `${props.parentDataKey}.startDate`, errorMessages },
    // });

    props.errorMessages.push(errorMessages);
};

export const onEndDateChange = (props: ControlOnChangeArguments) => {
    const errorMessages =
        new Date(props.value) < new Date(props.row.startDate) ? ['End year should be greater than or equal to Start year'] : [];
    props.errorMessages.push(errorMessages);
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch);
};

const isValidDate = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const jobdata = state?.data['jobs'];
    let isFormValid = true;
    jobdata.forEach((job: any, index: number) => {
        console.log(job);

        if (isEmpty(job?.startDate)) {
            setError(`jobs.${index}.startDate`, [`Please enter "Start Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`jobs.${index}.startDate`, [], dispatch);
        }

        if ((job?.isCurrent === false || isEmpty(job?.isCurrent)) && isEmpty(job?.endDate)) {
            setError(`jobs.${index}.endDate`, [`Please enter "End Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`jobs.${index}.endDate`, [], dispatch);
        }

        if (new Date(job?.startDate) >= new Date(job?.endDate)) {
            console.log('Invalid date');
        }
        if (job?.isCurrent === false || isEmpty(job?.isCurrent)) {
            if (isEmpty(job?.endDate) || new Date(job?.startDate) > new Date(job?.endDate)) {
                setError(`jobs.${index}.endDate`, [`End year should be greater than or equal to Start year`], dispatch);
                isFormValid = false;
            } else {
                setError(`jobs.${index}.endDate`, [], dispatch);
            }
        }
    });

    return isFormValid;
};

const sendMoEngageEvent = async (user:any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-work-experience`, {
            userInfo       : user
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};