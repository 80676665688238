import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { SmartContext } from '../../../library/Core/SmartContext';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import Layout from './Layout';
import ManageleadGrid from './ManageleadGrid';
import SessionContext from '../../../library/Core/SessionContext';
import useManagementDashboard from '../../management/dashboard/useManagementDashboard';
import { getInstituteProgramDomain } from '../../../library/Service/domainService';
import { getinstituteList } from '../../../library/Service/domainService';

const ManageLead = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState } = useContext(SessionContext);
    const { schoolDomain, invitedBy } = useParams();
    console.log("sessionState",sessionState)
    const { getCampuses } = useManagementDashboard();

    const campuses = getCampuses();
    const [instituteList, setinstituteList] = useState(getinstituteList('INSTITUTE_GROUP_CODE', campuses));

  


    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;


    useEffect(() => {
        // let schoolId = '';
        // let school_settings:any = JSON.parse(localStorage.getItem('school_settings') ?? '{}');
        
        const domain = new Map([...globalState.domain]);
        // domain.set('INSTITUTE_PROGRAM_CODE', getInstituteProgramDomain(school_settings?.programs));

        
        const loadSchoolSettings = async () => {
            const school_settingsString = localStorage.getItem('school_settings');
            if (school_settingsString) {
                try {
                    const school_settings = JSON.parse(school_settingsString);
                    const schoolUrl = school_settings.domain;
                    console.log(schoolUrl);
                    const responsedata = await axios.get(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-info/${schoolUrl}`);
                    console.log(responsedata.data?.programs);
                    const domain = new Map([...globalState.domain]);
                    domain.set('INSTITUTE_PROGRAM_CODE', getInstituteProgramDomain(responsedata.data?.programs));
                    dispatch({
                        type: 'DATA_INIT',
                        payload: { formConfig, data, domain, internal, routeInfo },
                    });
            
                    dispatch({
                        type: 'SET_DOMAIN',
                        payload: { key: 'INSTITUTE_GROUP_CODE', value: instituteList },
                    });

                } catch (error) {
                    console.error('Error fetching school info:', error);
                }
            } else {
                console.log('No school settings found in localStorage');
            }
           
        };

        loadSchoolSettings();

        

       

    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
        <DebugBarControl />
        {!state.flags.isDataLoading && (
            <Layout>
                {/* Please check layout for the <SearchCriteria /> */}
                <ManageleadGrid />
            </Layout>
            
        )}
    </SmartContext.Provider>
    );
};

export default ManageLead;
