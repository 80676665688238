import React from 'react';

interface MentionDropdownProps {
    onSelect: (value: string) => void;
    position: { top: number; left: number };
}
const MentionDropdown: React.FC<MentionDropdownProps> = ({ onSelect, position }) => {
    const options = ['First_Name', 'Last_Name', 'Program_Completion'];

    return (
        <div
            className="mention-dropdown"
            style={{
                position: 'absolute',
                top: position.top,
                left: position.left,
                border: '1px solid #ccc',
                backgroundColor: '#fff',
                zIndex: 1000,
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                padding: '-500px',
                margin: '-205px 0px 0px -25px'
            }}
        >
            {options.map((option, index) => (
                <div
                    key={index}
                    onMouseDown={(e) => {
                        e.preventDefault(); // Prevent blur
                        onSelect(option);
                    }}
                    style={{ padding: '8px', cursor: 'pointer', fontSize: '14px' }}
                >
                    {option}
                </div>
            ))}
        </div>
    );
};

export default MentionDropdown;
