import { useContext, useEffect } from 'react';
import { DispatchEvent, State } from '../../../../../../library/Core/SmartTypes';
import { SmartContext } from '../../../../../../library/Core/SmartContext';
import PageBuilder from '../../../../../../library/Builders/PageBuilder/PageBuilder';
import EditInterestsFormLayout from './EditInterestsFormLayout';
import ProfileMarkerService from './ProfileMakerService';

const EditInterestsModal = ({ userdata }: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { saveMarker, fetchUserProfileMarkers } = ProfileMarkerService();
    const handleSave = async (
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void
    ) => {
        event.preventDefault();
        console.log('form_submit');
        let payload = {
            markerType: 'interests',
            ...state?.data?.interests,
        };

        await saveMarker(payload, state?.internal?.model?.id);
        await fetchUserProfileMarkers(state?.internal?.model?.id);
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showInterestsModal' } });
    };

    useEffect(() => {
        dispatch({ type: 'ADD_ACTIONS', payload: { ...state.actions, SAVE: handleSave } });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <EditInterestsFormLayout>{!state.flags.isDataLoading && <PageBuilder />}</EditInterestsFormLayout>
        </SmartContext.Provider>
    );
};

export default EditInterestsModal;
