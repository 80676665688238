import { useState } from 'react';
const CollegeProperties = () => {
    return (
        <div className="col-md-3 col-sm-3 col-4">
            <div className="heading"></div>
            <div className="content"></div>
            <div className="content">
                <span className="lead">Establishment Year</span>
            </div>
            <div className="content">
                <span className="lead">Undergraduate Courses</span>
            </div>
            <div className="content">
                <span className="lead">Institute Type </span>
            </div>
            <div className="content">
                <span className="lead">Campus Size (in Acres) </span>
            </div>
            <div className="content ranking">
                <span className="lead"> Ranking</span>
            </div>
            <div className="content">
                <span className="lead"> Total Students</span>
            </div>
            <div className="content">
                <span className="lead">Number of Scholarships </span>
            </div>
            <div className="content">
                <span className="lead"> Number of Campuses </span>
            </div>
            <div className="content">
                <span className="lead">International Students </span>
            </div>
            <div className="content">
                <span className="lead"> Acceptance Rate </span>
            </div>
            <div className="content">
                <span className="lead"> Cost of living on Campus </span>
            </div>
            <div className="content">
                <span className="lead">Cost of living in City </span>
            </div>
        </div>
    );
};

export default CollegeProperties;
