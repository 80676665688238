import { useContext, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { SmartContext } from '../../library/Core/SmartContext';

interface College {
  id: string;
  name: string;
  establish_year?: number;
  institution_type?: string;
  campus_size?: string;
  college_rank?: number;
  student_count?: number;
  scholarship_count?: number;
  percent_international_student?: number;
  acceptance_ratio?: number;
  avg_cost_living?: number;
}

interface OptionType {
  value: string;
  label: string;
}

const CollegeSearchGrid = () => {
  const { state } = useContext(SmartContext);
  const [compareColleges, setCompareColleges] = useState<(College | null)[]>([null, null, null]);
  const [searchCollegeParameters, setSearchCollegeParameters] = useState<string[]>(['', '', '']);

  const removeSearchCollegeByIndex = (indexToRemove: number) => {
    setCompareColleges((prevSelectedColleges) => {
      const updatedColleges = [...prevSelectedColleges];
      updatedColleges[indexToRemove] = null;
      return updatedColleges;
    });

    setSearchCollegeParameters((previousSearchData) => {
      const updatedSearchData = [...previousSearchData];
      updatedSearchData[indexToRemove] = '';
      return updatedSearchData;
    });
  };

  const handleOnChange = (selectedOption: SingleValue<OptionType>, index: number) => {
    const selectedCollegeId = selectedOption?.value || '';

    setSearchCollegeParameters((prevParameters) => {
      const updatedParameters = [...prevParameters];
      updatedParameters[index] = selectedCollegeId;
      return updatedParameters;
    });

    const selectedCollege = state?.data.collegeList.find((college: College) => college.id === selectedCollegeId);
    if (selectedCollege) {
      setCompareColleges((prevSelectedColleges) => {
        const updatedColleges = [...prevSelectedColleges];
        updatedColleges[index] = selectedCollege;
        return updatedColleges;
      });
    }
  };
  
  const options: OptionType[] = Array.isArray(state?.data.collegeList)
    ? state.data.collegeList.map((college: College) => ({
        value: college.id,
        label: college.name,
      }))
    : [];

  return (
    <div className="col-md-9 col-sm-9 col-8 mt-4 pt-2">
      <div className="mobile-scroll h-100">
        <div className="row">
          {[0, 1, 2].map((index) => (
            <div className="col-sm-4 col-4 searchGridFirst" key={index}>
              <div className="heading-search mb-4">
                {!compareColleges[index] ? (
                  <Select
                    value={options.find((option) => option.value === searchCollegeParameters[index])}
                    className="custom-select"
                    options={options}
                    onChange={(selectedOption) => handleOnChange(selectedOption, index)}
                  />
                ) : null}
              </div>
              {compareColleges[index] && (
                <div className="block collegeBlock firstCol">
                  <div className="heading pos-rel">
                    <a
                      onClick={() => removeSearchCollegeByIndex(index)}
                      href="javascript:void(0);"
                      className="btn-close btn-close-white btn-close-small"
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                    <i className="icon icon-checked"></i>

                    <h6 className="mb-1 d-flex">
                      <small id="tag-549" className="me-2">
                        <span
                          style={{ cursor: 'pointer' }}
                          className="compare_add"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Add to shortlist"
                          data-bs-original-title=""
                        >
                          <i className="fa fa-check-circle fa-lg"></i>
                        </span>
                      </small>
                      <span className="flex-1 d-flex flex-column">
                        <span className="font-small font-18 font-500">
                          {compareColleges[index]?.name ?? '-'}
                        </span>
                      </span>
                    </h6>
                  </div>
                  <div className="content">
                    <span>{compareColleges[index]?.establish_year ?? '-'}</span>
                  </div>
                  <div className="content">
                    <span>-</span>
                  </div>
                  <div className="content">
                    <span>{compareColleges[index]?.institution_type ?? '-'}</span>
                  </div>
                  <div className="content">
                    <span>{compareColleges[index]?.campus_size ?? '-'}</span>
                  </div>
                  <div className="content ranking">
                    <span>{compareColleges[index]?.college_rank ?? '-'}</span>
                  </div>
                  <div className="content">
                    <span>{compareColleges[index]?.student_count ?? '-'}</span>
                  </div>
                  <div className="content">
                    <span>{compareColleges[index]?.scholarship_count ?? '-'}</span>
                  </div>
                  <div className="content">
                    <span>{compareColleges[index]?.campus_size ?? '-'}</span>
                  </div>
                  <div className="content">
                    <span>{compareColleges[index]?.percent_international_student ?? '-'}</span>
                  </div>
                  <div className="content">
                    <span>{compareColleges[index]?.acceptance_ratio ?? '-'}</span>
                  </div>
                  <div className="content">
                    <span>-</span>
                  </div>
                  <div className="content">
                    <span>{compareColleges[index]?.avg_cost_living ?? '-'}</span>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CollegeSearchGrid;
