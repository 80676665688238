import { useContext, useEffect, useRef, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import GuidanceSelectedFilters from './GuidanceSelectedFilters';
import { useNavigate } from 'react-router-dom';
import SessionContext from '../../../library/Core/SessionContext';

declare global {
    interface Window {
        YT: any; // Add this declaration for the YT property
    }
}

const GuidanceGridControl = () => {
    const [isVideoModalOpen, setVideoModalOpen] = useState(false);
    const [player, setPlayer] = useState<any>(null);
    const playerRef = useRef<any>(null);
    const [videoLink, setVideoLink] = useState('');
    const { state, dispatch } = useContext(SmartContext);
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);
    const userType = sessionState?.userTypeCode;

    function extractVideoId(url: string) {
        if (url === undefined) return '';
        const videoIdMatch = url.match(/(?:youtu.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/);
        if (videoIdMatch) {
            return videoIdMatch[1];
        }
        return '';
    }

    useEffect(() => {
        if (window.YT && typeof window.YT.Player !== 'undefined' && videoLink) {
            const videoId = extractVideoId(videoLink);
            playerRef.current = new window.YT.Player('youtube-player', {
                videoId: videoId,
                playerVars: {
                    origin: window.location.origin,
                },
                events: {
                    onReady: onPlayerReady,
                },
            });
        }
    }, [videoLink]);

    // const onYouTubeIframeAPIReady = () => {
    //     if (playerRef.current === null) {
    //         const videoId = extractVideoId(videoLink);
    //         playerRef.current = new window.YT.Player('youtube-player', {
    //             videoId: videoId,
    //             events: {
    //                 onReady: onPlayerReady,
    //             },
    //         });
    //     }
    // };

    const onPlayerReady = (event: any) => {
        setPlayer(event.target);
        if (isVideoModalOpen) {
            event.target.playVideo();
        }
    };

    const openVideoModal = (link: string) => {
        setVideoLink(link);
        window.scrollTo(0, 0);
        setVideoModalOpen(true);

        // Destroy existing player
        if (playerRef.current && playerRef.current.destroy) {
            playerRef.current.destroy();
        }
    };

    const closeVideoModal = () => {
        setVideoModalOpen(false);

        // Delay the execution of player destruction
        setTimeout(() => {
            if (playerRef.current && playerRef.current.destroy) {
                playerRef.current.destroy();
                playerRef.current = null;
                setPlayer(null);
            }
        }, 100); // You can adjust the delay as needed
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleAskQuestionClick = (videoId: string) => {
        navigate(`/messages/${videoId}`);
    };

    return (
        <div
            className="flex-1 polarcontsec tab-content mt-0 pe-md-1 aos-init aos-animate"
            id="myTabContent"
            data-aos="fade-left"
            data-aos-delay="900">
            <div className="tab-pane fade show active" id="AllTab" role="tabpanel" aria-labelledby="All-tab">
                <div className="ActiveListings_present">
                    <div className="row">
                        <div className="col-md-7">
                            <h3 className="font-18 font-500 my-2 d-none d-md-block" id="Headblock">
                                {state?.data?.filteredData.length} videos found
                            </h3>
                        </div>
                        {/* <div className="col-md-5">
                            <a
                                className="btn btn-success d-none d-md-block"
                                href={`${process.env.REACT_APP_IMAGE_BASEPATH}/guidance-video`}
                                style={{ backgroundColor: '#5cae48', borderColor: '#5cae48' }}>
                                Create new guidance video request
                            </a>
                        </div> */}
                    </div>
                    <GuidanceSelectedFilters />
                    <div className="filterChecked d-flex flex-wrap mb-3 py-1 align-items-center" id="filterapp"></div>
                    <div className="row uni_profiles">
                        {state?.data?.filteredData?.map((video: any) => (
                            <div className="col-sm-6 mb-4" key={video.id}>
                                <a className="video_block video-btn h-100">
                                    <span className="video_block_img position-relative">
                                    <i onClick={() => openVideoModal(video.video)} className="icon-invert-play" ></i>
                                        <img
                                            src={`https://img.youtube.com/vi/${extractVideoId(video.video)}/mqdefault.jpg`}
                                            className="img-fluid"
                                            width="1000px"
                                        />
                                    </span>
                                    <span className="video_block_name h-auto p-3">
                                        <span className='row g-2'>
                                            <span className='col-12'><label>{video.title}</label> </span>
                                            <span className='col-12'>
                                        {userType === 'STUDENT' && (

                                                <button
                                                type="button"
                                                className="btn btn-green"
                                                onClick={() => handleAskQuestionClick(video.studentId)}>
                                                <i className="icon-question circle position-relative me-2">
                                                    
                                                </i>
                                                <span>Ask a Question</span>
                                            </button>  
                                        )}

                                            </span>
                                        </span>                        
                                    </span>

                                </a>
                            </div>
                        ))}

                    </div>
                    <div
                        id="video_pop"
                        className={`modal fade show vh-100 ${isVideoModalOpen ? 'show' : ''}`}
                        tabIndex={-1}
                        role="dialog"
                        style={{ display: isVideoModalOpen ? 'block' : 'none' }}>
                        <div className="modal-dialog modal-lg " role="document">
                            <div className="modal-content">
                                <div className="modal-body p-0">
                                    <button type="button" className="btn-close" onClick={closeVideoModal}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <div className="ratio ratio-16x9">
                                        <div id="youtube-player"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GuidanceGridControl;
