import React, { useContext } from 'react';
import { GlobalContext } from '../library/Core/GlobalContext';
import AppSettingsService from '../services/app-settings.service';
import SessionContext from '../library/Core/SessionContext';

const ProtectedRoute: React.FC<any> = ({ children }) => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState } = useContext(SessionContext);

    const excludePaths = ['login', 'signup'];
    const userLoggedOut = sessionStorage.getItem('user-logged-out') === 'TRUE' ? true : false;

    const pathSegments = window.location.pathname.split('/');
    const currentPathSegment = pathSegments[pathSegments.length - 1] || '';
    const isExcludedPath = excludePaths.includes(currentPathSegment);

    if (!isExcludedPath && userLoggedOut) {
        sessionStorage.setItem('user-logged-out', 'FALSE');
        // const loginUrl = AppSettingsService.getLoginUrlFromStorage();
        const loginUrl = AppSettingsService.getLoginUrl(sessionState);
        if (loginUrl) window.location.href = loginUrl;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
