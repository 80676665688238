import { isFormValid } from '../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../library/Core/SmartTypes';
import JobPostingService from '../../../services/job-posting.service';

export const onSave = (event: React.SyntheticEvent<HTMLButtonElement>, state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    event.preventDefault();

    if (!isFormValid(state, dispatch)) {
        alert('Please correct the form errors!');
        return;
    }

    JobPostingService.saveJobPosting(state.data['jobPosting']).then((response) => {
        // dispatch({ type: 'REFRESH_DATA', payload: { data: { jobPosting: JobPostingService.toDto(response) } } });
        state.actions['ROUTER_NAVIGATE'](`/job-posting`);
    });
};

export const onMaxExpChange = (props: ControlOnChangeArguments) => {
    console.log(props.value);
    const minExperience = parseInt(props.row['minExperience'], 10);
    const maxExperience = parseInt(props.value, 10);

    if (isNaN(maxExperience)) {
        props.errorMessages.push('Please enter a valid number for maximum experience');
        return;
    }

    if (maxExperience < minExperience) {
        props.errorMessages.push('Maximum experience should be greater than or equal to minimum experience');
        return;
    }
};

export const onChangeTitle = (props: ControlOnChangeArguments) => {
    const value = props.value;

    // Check if the value is a number
    if (!isNaN(value)) {
        props.errorMessages.push('Please enter a valid job title.');
        return;
    }
};

export const onChangeCompanyName = (props: ControlOnChangeArguments) => {
    const value = props.value;

    // Check if the value is a number
    if (!isNaN(value)) {
        props.errorMessages.push('Please enter a valid comppay name.');
        return;
    }
};
