import { useContext, useEffect } from 'react';
import { useLoaderData, Outlet, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import HeaderControl from '../_common/HeaderControl';
import MenuBuilder from '../../../routes/MenuBuilder';
import PageFooterControl from '../../../library/SimpleControls/PageFooterControl';
import ErrorPage from '../../../library/SimpleControls/ErrorPage';

const Dashboard = () => {
    const { id, pageName } = useParams();
    const { globalState } = useContext(GlobalContext);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        data: [],
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const handleNavigateToHome = () => {
        // resetErrorBoundary();
        return <ErrorPage />;
    };

    // const { formConfig, data } = useLoaderData() as State;

    // useEffect(() => {
    //     const domain = globalState.domain;
    //     dispatch({
    //         type: 'DATA_INIT',
    //         payload: {
    //             formConfig,
    //             data: { ...data, pageDomain: data.domain, summaryCounts: data.summaryCounts, selectedTab: 'ACTIVE_LIST' },
    //             pageDomain: data.domain,
    //             domain,
    //             routeInfo: { id, pageName },
    //         },
    //     });
    // }, []);

    return (
        <div className="d-flex flex-column min-vh-100">
            <header>
                <HeaderControl />
                {/* <MenuLayout /> */}
                <MenuBuilder />
            </header>
            <main className="flex-1"></main>
            <PageFooterControl />
        </div>
    );
};

export default Dashboard;
