import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { handleSubmit } from './EntranceExamActions';
import EntranceExamLayout from './EntranceExamLayout';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import SessionContext from '../../../../library/Core/SessionContext';

const EntranceExam = () => {
    const { globalState } = useContext(GlobalContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);
    const [redirectUrl, setRedirectUrl] = useState('');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { SUBMIT: handleSubmit, ROUTER_NAVIGATE: navigate, REDIRECT: setRedirectUrl },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    if (sessionState?.userTypeCode === 'STUDENT' && sessionState?.profileStatusCode === 'PREFERENCES_INCOMPLETE') {
        window.location.href = `/alumni/${sessionState?.id}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <EntranceExamLayout>{!state.flags.isDataLoading && <PageBuilder />}</EntranceExamLayout>
        </SmartContext.Provider>
    );
};

export default EntranceExam;
