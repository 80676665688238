import moment from 'moment';
import { isFormValid } from '../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import logger from '../../../../library/Core/logger';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
import StudentService from '../../../../services/student.service';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    logger.log(state.data['profile']);

    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    if (!isImpersonatedSession && !isFormValid(state, dispatch)) {
        alert('Please correct the form errors!');
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            benefits: state?.data?.benefits,
            educationPreferenceInfo: state?.data?.educationPreferenceInfo,
            jobPreferenceInfo: state?.data?.jobPreferenceInfo,
        })
    ).then(() => {
        // ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.BENEFITS_OPT_IN, state, dispatch);
        state.actions['REDIRECT'](`/alumni/${state.routeInfo.id}/profile/benefits`);

    });

    // pageSave(
    //     state.routeInfo.id,
    //     state.routeInfo.pageName,
    //     toStudentEntity({
    //         id: state.routeInfo.id,
    //         // profile: state?.data['profile'],
    //         benefits: state?.data['benefits'],
    //     })
    // ).then(() => {
    //     ScreenNavigationService.navigateToNextStep(Screen.BENEFITS_OPT_IN, state, dispatch);
    // });
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.BENEFITS_OPT_IN, state, dispatch);
};

export const getYear = (token: string): number => {
    let year = 0;

    switch (token) {
        case 'CURRENT_YEAR_MINUS_1':
            year = moment().year() - 1;
            break;
        case 'CURRENT_YEAR_MINUS_2':
            year = moment().year() - 2;
            break;
        case 'CURRENT_YEAR_MINUS_10':
            year = moment().year() - 10;
            break;
        case 'CURRENT_YEAR_MINUS_11':
            year = moment().year() - 11;
            break;
        case 'CURRENT_YEAR_MINUS_99':
            year = moment().year() - 99;
            break;

        case 'CURRENT_YEAR':
            year = moment().year();
            break;
    }

    return year;
};
