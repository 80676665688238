import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import ProgramLayout from './ProgramLayout';
import PageBuilder from '../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../library/Core/GlobalContext';
import { getDomainValueForCode, getFlexColDomainValueForCode, isEmpty, isFormValid, setError } from '../../library/Core/SmartFunctions';
import { axiosClient } from '../../library/Service/axiosClient';
import Swal from 'sweetalert2';
import useManagementDashboard from '../management/dashboard/useManagementDashboard';
import { getinstituteList } from '../../library/Service/domainService';
import SessionContext from '../../library/Core/SessionContext';

const CreateinstituteProgram = () => {
    const { globalState } = useContext(GlobalContext);
    const { getCampuses } = useManagementDashboard();
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            SAVE: handleSave,
        },
    } as any);
    const { sessionState } = useContext(SessionContext);
    console.log("sessionState",sessionState)
    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;
    const campuses = getCampuses();
    const [instituteList, setinstituteList] = useState(getinstituteList('INSTITUTE_GROUP_CODE', campuses));
    const params = useParams();
    const navigate = useNavigate();
    const schoolArray=['SCHOOL','PRE_SCHOOL','K12_SCHOOL']
    const universityArray=['UNIVERSITY','DEEMED_UNIVERSITY']

    useEffect(() => {
        const fetchData = async () => {
            const isEditing = !!params.id;    
            try {
                let instituteId = {};
                let programData = {};
                if (isEditing) {
                    const response = await axiosClient().post(`${process.env.REACT_APP_ADMIN_API_SERVER}/program/get-program-by-id`, {
                        id: params.id,
                    });
                    programData = response.data.data;
                    instituteId = response.data.data?.instituteId;
                    
                    dispatch({
                        type: 'REFRESH_DATA',
                        payload: {
                            data: {
                                instituteprogram: {
                                    ...programData,
                                    instituteGrouId: sessionState?.instituteGroupId,
                                    instituteIdSession: sessionState?.institutes
                                }
                            },
                            instituteId
                        },
                    });
                    dispatch({ type: 'RESET_VALIDATION_ERRORS' });
                }
    

                dispatch({
                    type: 'DATA_INIT',
                    payload: {
                        formConfig,
                        data: {
                            instituteprogram: {
                                instituteGrouId:sessionState?.instituteGroupId,
                                instituteIdSession: sessionState?.institutes,
                                ...isEditing ? programData : {}
                            },
                            instituteId: isEditing ? instituteId : instituteList 
                        },
                        domain: new Map([...globalState.domain]),
                        routeInfo,
                    },
                });
    
                dispatch({
                    type: 'SET_DOMAIN',
                    payload: { key: 'INSTITUTE_GROUP_CODE', value: instituteList },
                });
    
            } catch (error) {
                console.error('Error fetching program data:', error);
            }
        };
    
        fetchData();
    }, []);

    // useEffect(() => {
    //     if (state.data?.instituteprogram?.programCategory !== '14') {
    //         setError('instituteprogram.otherprogramCategory', [], dispatch);
    //     }
    // }, [state.data?.instituteprogram?.programCategory]);

    useEffect(() => {
        if (state.data?.instituteprogram?.typeOfProgram !== '14') {
            setError('instituteprogram.otherProgramType', [], dispatch);
        }
    }, [state.data?.instituteprogram?.typeOfProgram]);

    useEffect(() => {
        const programCategory = state.data?.instituteprogram?.programCategory;
        if (programCategory && !programCategory.some((category: any) => category.programCategory === '7')) {
            setError('instituteprogram.otherprogramCategory', [], dispatch);
        }
    }, [state.data?.instituteprogram?.programCategory]);

    useEffect(() => {
        if (state.data?.instituteprogram?.courseEligibility !== '22') {
            setError('instituteprogram.othercourseEligibility', [], dispatch);
        }
    }, [state.data?.instituteprogram?.courseEligibility]);

    const isOtherProgramType = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        if (state?.data?.instituteprogram?.typeOfProgram == 14 && (state?.data?.instituteprogram?.otherProgramType == undefined || state?.data?.instituteprogram?.otherProgramType == '')) {
            setError('instituteprogram.otherProgramType', ['Please enter Other program Type'], dispatch);
            return true;
        }
        return false;
    };
    // const isOtherProgramCategory = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    //     if (state?.data?.instituteprogram?.programCategory == 14 && state?.data?.instituteprogram?.otherprogramCategory == undefined) {
    //         setError('instituteprogram.otherprogramCategory', ['Please enter Other program category'], dispatch);
    //         return true;
    //     }
    //     return false;
    // };

    const isOtherProgramCategory =  (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        const programCategory = state?.data?.instituteprogram?.programCategory;
        const otherProgramCategory = state?.data?.instituteprogram?.otherprogramCategory;
        if (Array.isArray(programCategory) && programCategory.some(category => category.programCategory === '7')) {
            if (otherProgramCategory === undefined || otherProgramCategory=='') {
                setError('instituteprogram.otherprogramCategory', ['Please enter Other program category'], dispatch);
                return true;
            }
        }
        return false;
    };

    const isOtherothercourseEligibility = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        if (state?.data?.instituteprogram?.courseEligibility == 22 && (state?.data?.instituteprogram?.othercourseEligibility == undefined || state?.data?.instituteprogram?.othercourseEligibility == "")) {
            setError('instituteprogram.othercourseEligibility', ['Please enter Other Course Eligibility'], dispatch);
            return true;
        }
        return false;
    };

    const isInstituteEntered=(state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        const instituteGrouId = state?.data?.instituteprogram?.instituteGrouId;
        const instituteIdSession = state?.data?.instituteprogram?.instituteIdSession;
        if (instituteGrouId && instituteIdSession && instituteIdSession.includes(String(instituteGrouId))) {
            return false;
        } else {
            const selectedInstituteId = state?.data?.instituteId?.[0]?.instituteId;
            if (selectedInstituteId && instituteIdSession && instituteIdSession.includes(selectedInstituteId.toString())) {
                return false;
            }else{
                setError('instituteId', ['Please select atleast one instiitute'], dispatch);
                return true;
            }
        }
    }

    useEffect(() => {
        console.log("state.data?.instituteprogram?.intakeFrequencyId",state.data?.instituteprogram?.intakeFrequencyId)
        if (state.data?.instituteprogram?.intakeFrequencyId == '' || state.data?.instituteprogram?.intakeFrequencyId == undefined) {
            setError('instituteprogram.intakeFrequencyValue', [], dispatch);
        }
    }, [state.data?.instituteprogram?.intakeFrequencyId]);

    useEffect(() => {
        if (state.data?.instituteprogram?.examConducted == '') {
            setError('instituteprogram.examIds', [], dispatch);
        }
    }, [state.data?.instituteprogram?.examConducted]);

    const isallowintakeFrequencyValue = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        const value = state?.data?.instituteprogram?.intakeFrequencyValue;
        const intakeFrequencyId = state?.data?.instituteprogram?.intakeFrequencyId;
        console.log("intakeFrequencyId",intakeFrequencyId)
        if (intakeFrequencyId != null && intakeFrequencyId != '' || intakeFrequencyId != undefined){
            console.log("value_if",value)
               if (isEmpty(value)) {
                  if(value==undefined){
                    setError('instituteprogram.intakeFrequencyValue', ['Intake frequency value is required'], dispatch);
                    return true;
                  }
                } else {
                    const pattern = /^[1-9][0-9]*$/;
                    if (!pattern.test(value)) {
                        setError('instituteprogram.intakeFrequencyValue', ['Please enter a numeric value'], dispatch);
                        return true;
                    }
                }
        } else {
            console.log("value_else",value)
            if (!isEmpty(value)) {
                const pattern = /^[1-9][0-9]*$/;
                if (!pattern.test(value)) {
                    setError('instituteprogram.intakeFrequencyValue', ['Please enter a numeric value'], dispatch);
                    return true;
                }
            }
        }
        return false;
    }


    function handleSave(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();
        const isnotvalidType = isOtherProgramType(state, dispatch);
        const isnotvalidCategory = isOtherProgramCategory(state, dispatch);
        const isnotvalidCourseEligibility = isOtherothercourseEligibility(state, dispatch);
        const isinstitute = isInstituteEntered(state, dispatch);
        const isIntake = isallowintakeFrequencyValue(state,dispatch)
        console.log("isinstitute",isinstitute)

        if (!isFormValid(state, dispatch) || isnotvalidType || isnotvalidCategory || isnotvalidCourseEligibility || isinstitute || isIntake) {
            dispatch({ type: 'SHOW_ERRORS' });
            return;
        }
        saveBasicProgramInfo(state);
    }

    const saveBasicProgramInfo = async (state: any) => {
        try {
                let instituteId;
                let errorInstitutes = [];

                if ("instituteId" in state.data.instituteId[0]) {
                    instituteId = state.data.instituteId;
                } else {
                    instituteId = [{
                        "instituteId": state.data.instituteId[0].code
                    }];
                }
            console.log("instituteId",instituteId);

            //as per school and university type of program check as per level of program
            const universityProgramLevel = ['UG', 'PG', 'Doctoral'];
            for (const institute of instituteId) {
                const instituteIdForIndex = institute.instituteId;
                const instituteName = getDomainValueForCode(instituteIdForIndex, 'INSTITUTE_DOMAIN', state);
                // Now, using this instituteIdForIndex, you want to fetch flexCol
                const flexCol = getFlexColDomainValueForCode(instituteIdForIndex, 'INSTITUTE_DOMAIN', state);
                const institute_type = isEmpty(flexCol) ? '' : JSON.parse(flexCol).institute_type;

                if ((schoolArray.includes(institute_type) && universityProgramLevel.includes(state?.data?.instituteprogram?.programLevel)) 
                || (universityArray.includes(institute_type) && !(universityProgramLevel.includes(state?.data?.instituteprogram?.programLevel)))) {
                    errorInstitutes.push(instituteName);
                }
            }
            if (errorInstitutes.length > 0) {
                const errorMessage = `Can not add University or School Level ${
                    errorInstitutes.length > 1 ? 'programs' : 'program'
                } to '${
                    errorInstitutes.join(', ')
                }', Please remove ${
                    errorInstitutes.length > 1 ? 'school or university programs' : 'school or university program'
                }`;
                Swal.fire({
                    title: 'Error!',
                    text: errorMessage,
                    icon: 'error',
                    confirmButtonText: 'Close',
                });
                return;
            }
            
            const response = await axiosClient().post(`${process.env.REACT_APP_ADMIN_API_SERVER}/program/add-program`, {
                programData: state?.data?.instituteprogram,
                createdBy: sessionState?.userId,
                instituteId: instituteId,
                programId: params.id,
            });
            
            const data = await response.data;
            if (data.success) {
                Swal.fire({
                    title: 'Success!',
                    text: data.message,
                    icon: 'success',
                    showConfirmButton: false, 
                    timer: 1000, 
                    timerProgressBar: true 
                }).then((result) => {
                    navigate(`/program/listing`);
                });
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'Close',
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <ProgramLayout campuses={campuses}>{!state.flags.isDataLoading && <PageBuilder />}</ProgramLayout>
        </SmartContext.Provider>
    );
};

export default CreateinstituteProgram;
