import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUniversityImage } from '../../../../common/styles/Images';
import SessionContext from '../../../../library/Core/SessionContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { getDomainValueForCode, isSchoolAdmin } from '../../../../library/Core/SmartFunctions';

const ProfileUnderGraduate = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    const model = state.internal.model;
    const navigate = useNavigate();

    const isPeerReviewed = sessionState?.isPeerReviewed ?? false;
    const userType = sessionState?.userTypeCode ?? false;
    // const userType = sessionStorage.getItem('user-type-code');
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
    // const handleAlumniJoined = (universityId: any) => {
    //     navigate(`/alumni/directory?universityId=${universityId}`);
    // };

    // const [isActive, setIsActive] = useState(false);
    const [isActive, setIsActive]: any = useState({});
    const [isHidden, setIsHidden] = useState(false);

    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    // const handleClick = () => {
    //     setIsActive(!isActive)
    //     // setIsHidden(!isHidden);
    // };

    const handleClick = (id: any) => {
        setIsActive((prevState: any) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    console.log("model.underGradColleges",model.underGradColleges)
    return (
        <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-underGraduate" role="tabpanel" aria-labelledby="pills-underGraduate-tab">
                <div className="underGraduate-detail">
                    <div className="blockHeader">
                        <div className="row g-2  align-items-center">
                            <div className="col">
                                {!model.isEditable && (
                                    <div className="title">
                                        Under Graduate College(s) of{' '}
                                        <span className="font-600">
                                            {`${model.firstName}`} {model.lastName ? `${model.lastName}` : ''}
                                        </span>
                                    </div>
                                )}
                                {model.isEditable && <div className="title">My Under Graduate College(s)</div>}
                            </div>
                            {model.isEditable && (
                                <div className="col-auto">
                                    <a role="button" onClick={() => navigate(`/alumni/${model.id}/ug`)} className="btn-add-section">
                                        <i></i> <span>ADD</span>
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>

                    {model.underGradColleges.map((college: any) => (
                        <div key={college.universityId + college.universityName} className="graduateRow edit">
                            <div className="college_detail_wrap">
                                <div className="college_detail">
                                    <div className="college_logo">
                                        <img
                                            className="img-fluid"
                                            width="80"
                                            src={getUniversityImage(college.logoFileName)}
                                            alt={college.universityName}
                                        />
                                    </div>
                                    <div className="college_name_scholarship">
                                        <div className="college_name">
                                            <h3>{college.universityName}</h3>
                                            <p>
                                                {college.cityName && `${college.cityName},`} {college.stateName && `${college.stateName},`}{' '}
                                                {college.countryName} | Estd.
                                                {college.establishedYear}
                                            </p>
                                            {college.fieldOfStudy && (
                                                <div className="alumni_profile_college_subject">
                                                    <i className="icon"></i>
                                                    {typeof college.fieldOfStudy === 'number' ? (
                                                        <span>{getDomainValueForCode(college.fieldOfStudy, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state)}</span>
                                                    ) : (
                                                        <span>{college.fieldOfStudy}</span>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* <a href={'google.com'} className="block-link"></a> */}
                            </div>
                            <div className="offer offer_edit">
                                <div className="offer_existing_links">
                                    {college.recordTypeCode === 'JOINED' && (
                                        <a className="joindeLink">
                                            <i className="icon-like"></i> <span>{college.recordTypeCode}</span>
                                        </a>
                                    )}
                                    {college.collegeApplicationResultCode === 'GOT_OFFER_DID_NOT_JOIN' && (
                                        <div className="got_offer">
                                            <i className="icon-like"></i>
                                            <span>Got Offer but did not join</span>
                                        </div>
                                    )}
                                    {college.collegeApplicationResultCode === 'NO_OFFER' && (
                                        <div className="got_offer">
                                            <span>Did not get offer</span>
                                        </div>
                                    )}
                                    {model.isEditable && userType === "SUPER_ADMIN" && (
                                    <>
                                    <a
                                        className="joindeLink"
                                        href={`${process.env.REACT_APP_APP_BASE_URL}/alumni/${model.id}/scholarship`}
                                        rel="noreferrer">
                                        Update Scholarship
                                    </a>
                                    <a
                                        className="joindeLink"
                                        href={`${process.env.REACT_APP_APP_BASE_URL}/alumni/${model.id}/entrance-test`}
                                        rel="noreferrer">
                                        Update Exam
                                    </a>
                                    </>
                                    )}
                                    <div className="offer_joined">
                                        <i></i>
                                        <a
                                            className="btn btn-link"
                                            target="_blank"
                                            // href={`${process.env.REACT_APP_APP_BASE_URL}/alumni/directory?universityId=${college.universityId}`}
                                            rel="noreferrer">
                                            {!isSchoolAdmin() && college.alumniCount > 0 ? college.alumniCount - 1 : college.alumniCount} Alumni Joined
                                        </a>
                                    </div>
                                </div>
                                {model.isEditable && (
                                    // <div className={`edit_section ${isActive ? 'open' : ''} ${isHidden ? 'hidden' : ''}`}>
                                    <div className={`edit_section ${isActive[college.universityId] ? 'open' : 'hidden'}`}>
                                        {/* <button className="edit_section_toggle" onClick={handleClick}></button> */}
                                        <button className="edit_section_toggle" onClick={() => handleClick(college.universityId)}></button>
                                        <div className="edit_section_inner">
                                            <a role="button" onClick={() => navigate(`/alumni/${model.id}/ug`)} className="edit_link">
                                                Edit
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProfileUnderGraduate;
