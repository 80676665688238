import { useContext } from 'react';
import { SmartContext } from '../Core/SmartContext';

const ActionIconGroup = ({ control, row, dataKey }: any) => {
    const { state, dispatch } = useContext(SmartContext);

    return (
        <div className="form-control">
            {control.props.customProperties.map((icon: any) => (
                <i
                    key={icon.id}
                    className={`bi ${icon.iconClass} m-2 ${control.className}`}
                    onClick={(event) => state.actions[icon.id]({ event, dataKey, row, control })}
                    style={icon.style || {}}
                    title={icon.title}
                />
            ))}
        </div>
    );
};

export default ActionIconGroup;
