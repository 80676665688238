import { useContext } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import CardBottom from './CardBottom';

const AlumniGridWithCardsControl = ({ alumni }: any) => {
    const { state, dispatch } = useContext(SmartContext);

    return (
        <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="alumni-bucket">
                <div className="alumni_className_link">
                    <div className="alumni_name_detail">
                        <div className="alumni_img">
                            <a className="alumni_img_link" href="">
                                <img
                                    className="img-thumbnail border border-0"
                                    src={`${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_male.svg`}
                                    alt="Shiv Talwar"
                                />
                            </a>
                        </div>
                        <div className="alumni_name_brife">
                            <h3>
                                <a className="alumni_name_link" href="">
                                    Sreeharsha Shankar
                                </a>
                            </h3>
                            <span className="alumni_campus">B.Tech, 2016</span>
                        </div>
                    </div>
                    <div className="send_link">
                        <a href="" className="btn-send">
                            {' '}
                            <i className="icon-alumni-send mx-1"></i>
                        </a>
                    </div>
                </div>
                <>
                    <CardBottom alumni={alumni} />
                </>
            </div>
        </div>
    );
};

export default AlumniGridWithCardsControl;
