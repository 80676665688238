import { useContext } from "react";
import { getDomainValueForCode, getFlexColDomainValueForCode, isEmpty } from "../../../library/Core/SmartFunctions";
import { getUniversityImage } from "../../../common/styles/Images";
import { SmartContext } from "../../../library/Core/SmartContext";

const AdminInstituteSelectionLayout = (data: any) => {
    const { handleSelection,institutesLength,userData } = data;
    const { state, dispatch } = useContext(SmartContext);

    const profileCount = userData?.userInstitutes.filter((institute: any) => institute?.userStatusCode !== 'INACTIVE').length ?? 0;
    let institutes: any[] = [];
    userData?.userInstitutes.map((institute:any) => {
        let name = getDomainValueForCode(''+institute.instituteId, 'INSTITUTE_DOMAIN', state);
        let flexCol = getFlexColDomainValueForCode(institute.instituteId, 'INSTITUTE_DOMAIN', state);
        let logo = isEmpty(flexCol) ? '' : JSON.parse(flexCol).logo;
        let current_logo = getUniversityImage(logo);
        institutes.push({...institute,name : name,logo : current_logo});
    });

    if (institutesLength <= 1) {
        return null;
    }

    return (
        <>
          
            <div className="main flex-1">
                <div className="bg-purple alumni-networks-header">
                    <div className="max-1140 mx-auto py-5">
                        <div className="pt-4 d-flex flex-column align-items-center text-center">
                            <h1>The account is associated<br/>with {profileCount} alumni networks!</h1>
                            <p>Select the network for which you wish to access the information! You can switch views later to access the other network  </p>
                        </div>
                    </div>
                </div>
                <div className="alumni-networks-block-wrap pb-5">
                    <div className="max-1140 mx-auto position-relative">
                        <div className="row g-4">
                         {institutes
                             .filter((institute: any) => institute?.userStatusCode !== 'INACTIVE')
                             .map((institute: any) => (
                            <div key={institute.instituteId} className={`col-md-4 col-sm-6${profileCount === 1 ? ' center-block' : ''}`}>
                                <div className="alumni-networks-block">
                                    <img src={institute.logo} width="200" className="img-fluid" alt="" />
                                    <h4>{institute.name.charAt(0).toUpperCase() + institute.name.slice(1)}</h4>
                                    <button className="btn btn-green" onClick={() => handleSelection(institute)}>
                                    <span>Enter</span>
                                    </button>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    );
};

export default AdminInstituteSelectionLayout;
