import { useContext, useEffect } from 'react';
import { Outlet, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import MenuBuilder from '../../../routes/MenuBuilder';
import HeaderControl from '../../alumni/_common/HeaderControl';

import React from 'react';
import OwlCarousel from 'react-owl-carousel';

const GuidingAlumniPreviewFrontend = () => {
   
    const options = {
        items: 4,
            merge: true,
            loop: false,
            video: true,
            lazyLoad: true,
            mouseDrag: true,
            touchDrag: true,
            pullDrag: true,
            nav: true,
            dots: false,
            margin: 23,
            responsive: {
                0: {
          
                    items: 1
                  
                },
                600: {
                    items: 2
                    
                },
                1000: {
                    items: 4,
                    dots: false
                }
            }
      };

    return (
        <>
        <div className='py-5 bg-blue-164F88'>
            <div className='max-1140 mx-auto'>
                <h4 className="font-30 text-center text-white mb-2">School of Scholars Guiding Alumni</h4>
                <p className="text-center text-white pb-3">We are proud of our alumni who are setting and example and motivating younger students. Here are some alumni who are actively guiding current students by answering questions, conducting webinars and much more.</p>
                <OwlCarousel {...options} className='fourcardslider'>
          <div className="item">
              <div className="guiding_alumni_block h-100">
                <div className="guiding_alumni_top">
                  <div className="guiding_alumni_profile_img"><img src="http://www.univariety.xyz/prototype/school_dashboard/images/gantavya_pahwa.jpg" width="80" alt=""/></div>
                  <div className="guiding_alumni_profile_name">Sonika Tandon</div>
                </div>
                <div className="guiding_alumni_college_cource">
                  <ul>
                    <li><i className="college"></i> <span>School Of Scholars, Wanadongri, 2017</span></li>
                    <li><i className="cap"></i> <span>FLAME University, Pune</span></li>
                    <li><i className="brifecase"></i> <span>India</span></li>                          
                  </ul>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="guiding_alumni_block h-100">
                <div className="guiding_alumni_top">
                  <div className="guiding_alumni_profile_img"><img src="http://www.univariety.xyz/prototype/school_dashboard/images/gantavya_pahwa.jpg" width="80" alt=""/></div>
                  <div className="guiding_alumni_profile_name">Rohit Sen</div>
                </div>
                <div className="guiding_alumni_college_cource">
                  <ul>
                    <li><i className="college"></i> <span>School Of Scholars, Amrawati, 2019</span></li>
                    <li><i className="tool"></i> <span>Engineering & Technology</span></li>
                    <li><i className="cap"></i> <span>Manipal Institute of Technology (MIT), Mumbai</span></li>                          
                  </ul>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="guiding_alumni_block h-100">
                <div className="guiding_alumni_top">
                  <div className="guiding_alumni_profile_img"><img src="http://www.univariety.xyz/prototype/school_dashboard/images/gantavya_pahwa.jpg" width="80" alt=""/></div>
                  <div className="guiding_alumni_profile_name">Mahima Gaggad</div>
                </div>
                <div className="guiding_alumni_college_cource">
                  <ul>
                    <li><i className="college"></i> <span>School Of Scholars, Amrawati, 2015</span></li>
                    <li><i className="tool"></i> <span>Engineering & Technology</span></li>
                    <li><i className="cap"></i> <span>College of Engineering Pune (COEP)</span></li>                          
                  </ul>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="guiding_alumni_block h-100">
                <div className="guiding_alumni_top">
                  <div className="guiding_alumni_profile_img"><img src="http://www.univariety.xyz/prototype/school_dashboard/images/gantavya_pahwa.jpg" width="80" alt=""/></div>
                  <div className="guiding_alumni_profile_name">Saish Hawa</div>
                </div>
                <div className="guiding_alumni_college_cource">
                  <ul>
                    <li><i className="college"></i> <span>School Of Scholars, Wardha, 2015</span></li>
                    <li><i className="tool"></i> <span>Engineering & Technology</span></li>
                    <li><i className="cap"></i> <span>SRM Institute of Science and Technology (Farmerly)</span></li>                          
                  </ul>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="guiding_alumni_block h-100">
                <div className="guiding_alumni_top">
                  <div className="guiding_alumni_profile_img"><img src="http://www.univariety.xyz/prototype/school_dashboard/images/gantavya_pahwa.jpg" width="80" alt=""/></div>
                  <div className="guiding_alumni_profile_name">Vaibhav Bangia</div>
                </div>
                <div className="guiding_alumni_college_cource">
                  <ul>
                    <li><i className="college"></i> <span>School Of Scholars, Wanadongri, 2017</span></li>
                    <li><i className="cap"></i> <span>FLAME University, Pune</span></li>
                    <li><i className="brifecase"></i> <span>India</span></li>                          
                  </ul>
                </div>
              </div>
            </div>
            </OwlCarousel>
            </div>
        </div>
          
        </>
    );
};

export default GuidingAlumniPreviewFrontend;
