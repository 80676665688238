import { useContext } from 'react';
import { SmartContext } from '../Core/SmartContext';
import logger from '../Core/logger';
import SessionContext from '../Core/SessionContext';

const DebugBarControl = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    const handlePrintState = (event: React.SyntheticEvent, key: string) => {
        event.preventDefault();
        if (key === 'data') logger.log(state['data']);
        if (key === 'session') logger.log(sessionState);
        if (key === 'internal') logger.log(state['internal']);
        if (key === 'searchCriteria') logger.log(state?.data?.searchCriteria);
        if (key === 'formValidationErrors') logger.log(state['formValidationErrors']);
        if (key === 'formConfig') logger.log(state['formConfig']);
        if (key === 'routeInfo') logger.log(state['routeInfo']);
        if (key === 'ALL') logger.log(state);
    };

    return (
        <div className="container max-1140 px-lg-0 overflow-hidden">
            {process.env.NODE_ENV !== 'production' && (
                <div className="btn-group" role="group" aria-label="Basic outlined example">
                    <button
                        type="button"
                        className="btn btn-outline-primary col-2 border bg-warning-subtle"
                        style={{ color: 'red', borderColor: 'transparent', backgroundColor: 'transparent' }}
                        onClick={(event) => handlePrintState(event, 'data')}>
                        Data
                    </button>
                    <button
                        type="button"
                        className="btn btn-outline-primary col-2 border bg-warning-subtle"
                        style={{ color: 'red', borderColor: 'transparent', backgroundColor: 'transparent' }}
                        onClick={(event) => handlePrintState(event, 'session')}>
                        Session
                    </button>
                    <button
                        type="button"
                        className="btn btn-outline-primary col-2 border bg-warning-subtle"
                        style={{ color: 'red', borderColor: 'transparent', backgroundColor: 'transparent' }}
                        onClick={(event) => handlePrintState(event, 'internal')}>
                        Internal
                    </button>
                    <button
                        type="button"
                        className="btn btn-outline-primary col-2 border bg-warning-subtle"
                        style={{ color: 'red', borderColor: 'transparent', backgroundColor: 'transparent' }}
                        onClick={(event) => handlePrintState(event, 'searchCriteria')}>
                        Search Criteria
                    </button>
                    <button
                        type="button"
                        className="btn btn-outline-primary col-2 border bg-warning-subtle"
                        style={{ color: 'red', borderColor: 'transparent', backgroundColor: 'transparent' }}
                        onClick={(event) => handlePrintState(event, 'formValidationErrors')}>
                        Errors
                    </button>
                    <button
                        type="button"
                        className="btn btn-outline-primary col-2 border bg-warning-subtle"
                        style={{ color: 'red', borderColor: 'transparent', backgroundColor: 'transparent' }}
                        onClick={(event) => handlePrintState(event, 'formConfig')}>
                        Form Config
                    </button>
                    <button
                        type="button"
                        className="btn btn-outline-primary col-2 border bg-warning-subtle"
                        style={{ color: 'red', borderColor: 'transparent', backgroundColor: 'transparent' }}
                        onClick={(event) => handlePrintState(event, 'routeInfo')}>
                        Route Info
                    </button>
                    <button
                        type="button"
                        className="btn btn-outline-primary col-2 border bg-warning-subtle"
                        style={{ color: 'red', borderColor: 'transparent', backgroundColor: 'transparent' }}
                        onClick={(event) => handlePrintState(event, 'ALL')}>
                        Full State
                    </button>
                </div>
            )}
        </div>
    );
};

export default DebugBarControl;
