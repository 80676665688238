import { axiosClient } from '../../../library/Service/axiosClient';

const API_URL = `${process.env.REACT_APP_STUDENT_SERVER}/memories`;

class AlbumPostService {
    static toEntity(data) {
        const albumData = { ...data };

        const tags = albumData?.tags?.map((item) => item.tags).join(', ');
        albumData.albumTag = [{ tags }];

        return albumData;
    }

    static async saveAlbum(data) {
        const albumData = this.toEntity(data);

        let response = null;
        if (albumData.id == null) {
            response = await axiosClient().post(`${API_URL}`, albumData);
        } else {
            response = await axiosClient().put(`${API_URL}/${data.id}`, albumData);
        }

        return response.data;
    }
}

export default AlbumPostService;
