import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import { handleCompleteLater, handleNext } from './AchievementActions';
import AchievementLayout from './AchievementLayout';

const Achievement = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { NEXT: handleNext, I_LL_DO_THIS_LATER: handleCompleteLater, ROUTER_NAVIGATE: navigate },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        data['alumniProfile'] = data['alumniProfile'] || {};
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <AchievementLayout>{!state.flags.isDataLoading && <PageBuilder />}</AchievementLayout>
        </SmartContext.Provider>
    );
};

export default Achievement;
