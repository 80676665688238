import { useContext, useEffect } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import PieChartComponent from '../../../library/SimpleControls/PieChartControl';
import useManagementDashboard from './useManagementDashboard';

const AlumniCountryReport = () => {
    const { state } = useContext(SmartContext);
    const { getAlumniCountryReportData } = useManagementDashboard();

    useEffect(() => {
        getAlumniCountryReportData();
    }, [state.data.searchCriteria.alumniCountry.batch, state.data.searchCriteria.alumniCountry.campus]);

    if (isEmpty(state.data?.reportData?.alumniCountry)) return null;

    return (
        <>
            <PieChartComponent data={state.data.reportData.alumniCountry} />
        </>
    );
};

export default AlumniCountryReport;
