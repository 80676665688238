import { getDomainValueForCode, isSchoolAdmin, recentPassoutYear } from "../../../library/Core/SmartFunctions";

const CardNoPreparingExam = ({ alumni, state }: { alumni: any; state: any }) => {
    console.log(alumni, ' alumni ');
    const recentPassoutyear=recentPassoutYear(alumni)
    return (
        <div key={alumni.uuid} className="alumni_univarsity_details examtaken">
            <div className="alumni_univarsity_details_inner_cover">
                <div className="alumni_univarsity_logo">
                    <img src="https://www.univariety.xyz/prototype/alumni-university/images/icon-note.svg" alt="" />
                </div>
                <div className="alumni_univarsity_brife">
                    <div className="alumni_education">
                        <div className="row g-2">
                            <div className="col">Taking A Break</div>
                        </div>
                    </div>
                    <div className="alumni_univarsity_subject">
                        <i className=""></i>
                        <span>Since {alumni?.recentPassoutYear}</span>
                    </div>
                 
                </div>
            </div>
        </div>
    );
};

export default CardNoPreparingExam;
