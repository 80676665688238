import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { canEditProfile } from '../../../../library/Core/SmartFunctions';
import SessionContext from '../../../../library/Core/SessionContext';
const ParentInformation = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { id, pageName } = useParams();

    const model = state.internal.model;
    const { sessionState }: any = useContext(SessionContext);
    const navigate = useNavigate();

    let skeletonTemplate = {
        name: '-',
        contactEmail: '-',
        contactNumber: '-',
    };

    const parentRelation = ['MOTHER', 'FATHER', 'GUARDIAN'];
    let parentsSkeleton: any = [];
    let parentsData: any = [];

    parentRelation.forEach((relation: string, idx: number) => {
        parentsSkeleton.push({
            relation: relation,
            id: idx,
            ...skeletonTemplate,
        });
    });

    if (model.parents.length > 0) {
        parentsData = parentsSkeleton.map((item: any, index: number) => {
            console.log(item, ' ITEM ');
            let relation = item?.relation;
            let parentRec = item;
            model.parents.map((rec: any) => {
                if (rec.relation == relation) {
                    parentRec = rec;
                    return true;
                }
            });
            return { ...parentRec };
        });
    } else {
        parentsData = parentsSkeleton;
    }

    if ( sessionState?.userTypeCode === 'STUDENT' && sessionState?.profileStatusCode === 'PREFERENCES_INCOMPLETE' ) {
        window.location.href = `/alumni/${sessionState?.id}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    return (
        // <div className="tab-pane fade" id="pills-parentInformation" role="tabpanel" aria-labelledby="pills-parentInformation-tab">
        <div>
            <div className="parentInformation-detail tab-white-block">
                {canEditProfile(model.id, sessionState) && (
                    <a className="pencil-edit" role="button" onClick={() => navigate(`/alumni/${id}/parent-info`)}></a>
                )}
                {/* {model.parents.map((item: any, index: number) => ( */}
                {parentsData.map((item: any, index: number) => (
                    <div className="customRow" key={item.id}>
                        <div className="customRow-header">
                            <div className="icon icon-father"></div>
                            <div className="customRow-header-title">
                                <span className="font-20 font-500">{item.relation} Details</span>
                            </div>
                        </div>
                        <div className="customRow-detail">
                            <div className="row g-3">
                                <div className="col-md-4">
                                    <div className="d-flex flex-column">
                                        <div className="font-16 font-400 lineHeight-22 mb-1">Name:</div>
                                        <div className="font-20 font-500 lineHeight-22">{item.name}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="d-flex flex-column">
                                        <div className="font-16 font-400 lineHeight-22 mb-1">Contact Email:</div>
                                        <div className="font-20 font-500 lineHeight-22">{item.contactEmail}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="d-flex flex-column">
                                        <div className="font-16 font-400 lineHeight-22 mb-1">Contact Mobile:</div>
                                        <div className="font-20 font-500 lineHeight-22">{item.contactNumber}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ParentInformation;
