import { useContext } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { LayoutArguments } from '../Core/SmartTypes';

const CardLayout = (args: LayoutArguments) => {
    const { state, dispatch } = useContext(SmartContext);

    const getIndexOfSection = () => state.formConfig?.sections.indexOf(args.section.id as string);

    return (
        <div className="col-xl-12 col-sm-12 mb-3">
            <h2  className={`joined_offer_wrap pb-1 ${
                        getIndexOfSection() === 0 ? ' joined ' : ' didnt-join '
                    }`}>
                <span
                    className={`joined_label joined_label_noline text-uppercase ${
                        getIndexOfSection() === 0 ? ' bg-joined ' : ' didnt-join '
                    }`}>
                    <i></i> {args.section.title}
                </span>
            </h2>
            <div className="row g-4">{args.component}</div>
        </div>
    );
};

export default CardLayout;
