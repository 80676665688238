import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../library/Core/SmartContext';
import CollegeService from './CollegeService';
import sfeApiService from '../../library/Service/sfeApiService';
import { useLoaderData } from 'react-router-dom';
import { State } from '../../library/Core/SmartTypes';
import { GlobalContext } from '../../library/Core/GlobalContext';
import { debounce } from '../../library/Core/SmartFunctions';


const TopCollegeProperties = ({ onDepartmentChange, onCourseChange , onCountryChange, onStateChange , onCityChange, loading , onLoaderChange } : any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { formConfig, data, internal } = useLoaderData() as State;
    const { globalState } = useContext(GlobalContext);

    const departmentList = state?.data?.departmentList;
    const countryList = state?.data?.countryList;

    const [courseList, setCourseList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [departmentValue, setDepartment] = useState('');
    const [courseValue, setCourse] = useState('');
    const [countryValue, setCountry] = useState('');
    const [stateValue, setState] = useState('');
    const [cityValue, setCity] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [countrySearchTerm, setCountrySearchTerm] = useState('');
    const [stateSearchTerm, setStateSearchTerm] = useState('');
    const [citySearchTerm, setCitySearchTerm] = useState('');
    const [courseSearchTerm, setCourseSearchTerm] = useState('');
    

    // Set Department
    function departmentOnChange(e: any) {
        setDepartment(e.target.value);
        onDepartmentChange(e.target.value);
        onLoaderChange(true);
    }
    useEffect(() => {
        getCourse(departmentValue);
        if(departmentValue.length>0){
            topCollegeFilter();
        }
    }, [departmentValue]);

    // Set Course
    function courseOnChange(e: any) {
        setCourse(e.target.value);
        onCourseChange(e.target.value);
        onLoaderChange(true);
    }
    useEffect(() => {
        if(courseValue.length>0){
            topCollegeFilter();
        }
    }, [courseValue]);

    // Set Country
    function countryOnChange(e: any) {
        setCountry(e.target.value);
        onCountryChange(e.target.value);
        onLoaderChange(true);
    }
    useEffect(() => {
        getState(countryValue);
        if(countryValue.length>0){
            topCollegeFilter();
        }
    }, [countryValue]);

    // Set State
    function stateOnChange(e: any) {
        setState(e.target.value);
        onStateChange(e.target.value);
        onLoaderChange(true);
    }
    useEffect(() => {
        getCity(stateValue);
        if(stateValue.length>0){
            topCollegeFilter();
        }
    }, [stateValue]);

    // set City
    function cityOnChange(e: any) {
        setCity(e.target.value);
        onCityChange(e.target.value);
        onLoaderChange(true);
    }
    useEffect(() => {
        if(stateValue.length>0){
            topCollegeFilter();
        }
    }, [cityValue]);


    function getCourse(e: any) {
        if(e.length>0){
         return Promise.all([CollegeService.getCourseList(e)]).then((response) => {
            setCourseList(response[0]?.data.data ?? []);
            return Promise.resolve(state);
         });
       }
    }

    function getState(e: any) {
        if(e.length>0){  
         return Promise.all([CollegeService.getStateList(e)]).then((response) => {
            setStateList(response[0]?.data.data.records ?? []);
            return Promise.resolve(state);
         });
        }
    }

    function getCity(e: any) {
      if(e.length>0){   
        return Promise.all([CollegeService.getCityList(e)]).then((response) => {
            setCityList(response[0]?.data.data.records ?? []);
            return Promise.resolve(state);
        });
      }
    }

    function topCollegeFilter(): Promise<any> {

        var topCollegeUrl = `?has_ranking=1&course_id=${departmentValue}&sub_course_id=${courseValue}&country_id=${countryValue}&state_id=${stateValue}&city_id=${cityValue}`;

        return Promise.all([CollegeService.getTopCollegeFilter(topCollegeUrl)]).then((response) => {
            onLoaderChange(false);
            // console.log('response---Filter-- JSON' + JSON.stringify(response[0]?.data.data));
            // setLoading(false);

            dispatch({
                type: 'DATA_INIT',
                payload: {
                    formConfig,
                    data: { ...data, topCollegeList: response[0]?.data.data ?? [] },
                    domain: globalState.domain,
                    internal,
                    flags: { isDataLoading: false },
                },
            });

            return Promise.resolve(state);
        });
    }

    // console.log('-----------------countryList----------');
    // console.log(countryList);

    const [isOpen, setOpen] = useState(false);
    const handleFilterToggle = () => {
        setOpen(!isOpen);
    };

    const handleClearFilters = () => {
        setDepartment('');
        setCourse('');
        setCountry('');
        setState('');
        setCity('');
        setSearchTerm('');
        setCountrySearchTerm('');
        setStateSearchTerm('');
        setCitySearchTerm('');
        setCourseSearchTerm('');
        setCourseList([]);
        setStateList([]);
        setCityList([]);
        topCollegeFilter();
        window.location.reload();
    };

    const filteredDepartments = departmentList?.filter((department: any) =>
        department.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredCourseList = courseList?.filter((course: any) =>
        course.name.toLowerCase().includes(courseSearchTerm.toLowerCase())
    );

    const filteredCountryList = countryList.records?.filter((country: any) =>
        country.name.toLowerCase().includes(countrySearchTerm.toLowerCase())
    );

    const filteredStateList = stateList?.filter((state: any) =>
        state.name.toLowerCase().includes(stateSearchTerm.toLowerCase())
    );

    const filteredCityList = cityList?.filter((city: any) =>
        city.name.toLowerCase().includes(citySearchTerm.toLowerCase())
    );

    return (
        <div className="col-md-auto ji_filtersec ps-0 mt-2 pt-2">
            <div className="mobileFilter mobileonly text-end">
                <button
                    className="btn-filter btn-unscroll font-18 font-500"
                    type="button"
                    onClick={handleFilterToggle}
                    aria-expanded={isOpen ? 'false' : 'true'}
                    aria-controls="filter_collapse">
                    <span className="icon-filter"></span>Filters
                </button>
            </div>
            <div className={`filters leftPaneFilter collapse ${isOpen ? '' : 'show'}`} id="filter_collapse">
                {/* filters */}

                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <button
                            className="btn-filter btn-unscroll mobileonly"
                            type="button"
                            onClick={handleFilterToggle}
                            aria-expanded={isOpen ? 'false' : 'true'}
                            aria-controls="filter_collapse">
                            Filters
                        </button>
                        <h3 className="font-18 font-500 mb-2 mt-2 ">
                            <span className="icon-filter"></span>Filters
                        </h3>
                    </div>
                    <a href="javascript:;" className="font-14 font-500 text-burgandi text-decoration-underline" onClick={handleClearFilters}>
                        Clear
                    </a>
                </div>

                <div className="filterGroup" id="accordionFilters">
                    <div className="fiterItem mb-3">
                        <div className="filterHeader">
                            <div className="d-flex justify-content-between align-items-center position-relative">
                                <button
                                    className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseDepartment"
                                    aria-expanded="true"
                                    aria-controls="collapseDepartment">
                                    <span className="font-18 font-600">Department</span>
                                    <span className="toggle"></span>
                                </button>
                            </div>
                        </div>
                        <div className="collapse show bg-white" id="collapseDepartment" data-bs-parent="#accordionFilters">
                            <div className="filterSearchblk mb-3">
                                <form className="d-flex align-items-center filterSearch position-relative">
                                    <input
                                        className="form-control flex-1 font-14 font-500 w-100"
                                        type="search"
                                        placeholder="Search"
                                        aria-label="Search"
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <button className="btn icon-search" type="submit"></button>
                                </form>
                            </div>
                            <div className="card card-body mt-2">
                                <div className="overflow-hidden">
                                    <div className="scrollMe">
                                        {filteredDepartments?.map((data: any) => (
                                            <div className="form-check d-flex flex-wrap">
                                                <input
                                                    className="form-check-input me-2"
                                                    onChange={departmentOnChange}
                                                    type="radio"
                                                    name="department"
                                                    value={data.id}
                                                    id="department"
                                                />
                                                <label
                                                    className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                    htmlFor="Department_Architecture">
                                                    {data.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {courseList.length > 0 && (
                        <div className="fiterItem mb-3">
                            <div className="filterHeader">
                                <div className="d-flex justify-content-between align-items-center position-relative">
                                    <button
                                        className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseCourse"
                                        aria-expanded="false"
                                        aria-controls="collapseCourse">
                                        <span className="font-18 font-600">Course</span>
                                        <span className="toggle"></span>
                                    </button>
                                </div>
                            </div>
                            <div className="collapse bg-white" id="collapseCourse" data-bs-parent="#accordionFilters">
                                <div className="filterSearchblk mb-3">
                                    <form className="d-flex align-items-center filterSearch position-relative">
                                        <input
                                            className="form-control flex-1 font-14 font-500 w-100"
                                            type="search"
                                            placeholder="Search"
                                            aria-label="Search"
                                            onChange={(e) => setCourseSearchTerm(e.target.value)}
                                        />
                                        <button className="btn icon-search" type="submit"></button>
                                    </form>
                                </div>
                                <div className="card card-body mt-2">
                                    <div className="overflow-hidden">
                                        <div className="scrollMe">
                                            {filteredCourseList?.map((data: any) => (
                                                <div className="form-check d-flex flex-wrap">
                                                    <input
                                                        className="form-check-input me-2"
                                                        onChange={courseOnChange}
                                                        type="radio"
                                                        name="course"
                                                        value={data.id}
                                                        id="course"
                                                    />
                                                    <label
                                                        className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                        htmlFor="Department_Architecture">
                                                        {data.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="fiterItem mb-3">
                        <div className="filterHeader">
                            <div className="d-flex justify-content-between align-items-center position-relative">
                                <button
                                    className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseCountry_topcollege"
                                    aria-expanded="false"
                                    aria-controls="collapseCountry_topcollege">
                                    <span className="font-18 font-600">Country</span>
                                    <span className="toggle"></span>
                                </button>
                            </div>
                        </div>
                        <div className="collapse bg-white" id="collapseCountry_topcollege" data-bs-parent="#accordionFilters">
                            <div className="filterSearchblk mb-3">
                                <form className="d-flex align-items-center filterSearch position-relative">
                                    <input
                                        className="form-control flex-1 font-14 font-500 w-100"
                                        type="search"
                                        placeholder="Search"
                                        aria-label="Search"
                                        onChange={(e) => setCountrySearchTerm(e.target.value)}
                                    />
                                    <button className="btn icon-search" type="submit"></button>
                                </form>
                            </div>
                            <div className="card card-body mt-2">
                                <div className="overflow-hidden">
                                    <div className="scrollMe">
                                        {filteredCountryList?.map((data: any) => (
                                            <div className="form-check d-flex flex-wrap">
                                                <input
                                                    className="form-check-input me-2"
                                                    onChange={countryOnChange}
                                                    type="radio"
                                                    name="country"
                                                    value={data.id}
                                                    id="country"
                                                />
                                                <label
                                                    className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                    htmlFor="Department_Architecture">
                                                    {data.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {stateList.length > 0 && (
                        <div className="fiterItem mb-3">
                            <div className="filterHeader">
                                <div className="d-flex justify-content-between align-items-center position-relative">
                                    <button
                                        className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseCountry"
                                        aria-expanded="false"
                                        aria-controls="collapseCountry">
                                        <span className="font-18 font-600">State</span>
                                        <span className="toggle"></span>
                                    </button>
                                </div>
                            </div>
                            <div className="collapse bg-white" id="collapseCountry" data-bs-parent="#accordionFilters">
                                <div className="filterSearchblk mb-3">
                                    <form className="d-flex align-items-center filterSearch position-relative">
                                        <input
                                            className="form-control flex-1 font-14 font-500 w-100"
                                            type="search"
                                            placeholder="Search"
                                            aria-label="Search"
                                            onChange={(e) => setStateSearchTerm(e.target.value)}
                                        />
                                        <button className="btn icon-search" type="submit"></button>
                                    </form>
                                </div>
                                <div className="card card-body mt-2">
                                    <div className="overflow-hidden">
                                        <div className="scrollMe">
                                            {filteredStateList?.map((data: any) => (
                                                <div className="form-check d-flex flex-wrap">
                                                    <input
                                                        className="form-check-input me-2"
                                                        onChange={stateOnChange}
                                                        type="radio"
                                                        name="state"
                                                        value={data.id}
                                                        id="state"
                                                    />
                                                    <label
                                                        className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                        htmlFor="Department_Architecture">
                                                        {data.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {cityList.length > 0 && (
                        <div className="fiterItem mb-3">
                            <div className="filterHeader">
                                <div className="d-flex justify-content-between align-items-center position-relative">
                                    <button
                                        className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseCountry"
                                        aria-expanded="false"
                                        aria-controls="collapseCountry">
                                        <span className="font-18 font-600">City</span>
                                        <span className="toggle"></span>
                                    </button>
                                </div>
                            </div>
                            <div className="collapse bg-white" id="collapseCountry" data-bs-parent="#accordionFilters">
                                <div className="filterSearchblk mb-3">
                                    <form className="d-flex align-items-center filterSearch position-relative">
                                        <input
                                            className="form-control flex-1 font-14 font-500 w-100"
                                            type="search"
                                            placeholder="Search"
                                            aria-label="Search"
                                            onChange={(e) => setCitySearchTerm(e.target.value)}
                                        />
                                        <button className="btn icon-search" type="submit"></button>
                                    </form>
                                </div>
                                <div className="card card-body mt-2">
                                    <div className="overflow-hidden">
                                        <div className="scrollMe">
                                            {filteredCityList?.map((data: any) => (
                                                <div className="form-check d-flex flex-wrap">
                                                    <input
                                                        className="form-check-input me-2"
                                                        type="radio"
                                                        name="city"
                                                        onChange={cityOnChange}
                                                        value={data.id}
                                                        id="city"
                                                    />
                                                    <label
                                                        className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                        htmlFor="Department_Architecture">
                                                        {data.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TopCollegeProperties;
