import { useContext } from 'react';
import useSmartState from '../../common/hooks/useSmartState';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, getDomainValueForCode, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import logger from '../Core/logger';

const FiltersApplied = (args: SimpleFormControlArguments | any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { dataKey, onClearAll } = args;
    const data = getControlValueFromState(dataKey as string, state as State);
    const { setStateValue } = useSmartState();

    const handleClick = (key: string, value: string) => {
        logger.log('Filter Pill Clicked');
        const keyToUpdate = dataKey + '.' + key;

        if (Array.isArray(data[key])) {
            const index = data[key].indexOf(value);
            dispatch({ type: 'DELETE_RECORD_IN_ARRAY', payload: { dataKey: keyToUpdate, index } });
        } else {
            // dispatch({ type: 'DELETE_KEY_VALUE', payload: { dataKey: keyToUpdate } });
            if(key==="schoolId"){ document.querySelectorAll('.css-1dimb5e-singleValue').forEach(element => {
                element.textContent = ''; 
            });}
            dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: keyToUpdate, value: '' } });
        }
    };

    const getDomainCategoryCodeForKey = (sectionName: string, key: string, state: State) => {
        return (
            state.formConfig?.sectionRepository
                .find((section) => section.id === sectionName)
                ?.controlGroup?.find((control) => control.id === key)?.props.domainCategoryCode ?? ''
        );
    };

    const hasSearchCriteria = () =>
        Object.values(data).some(
            (value) => (Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null && value !== undefined)
        );

    return (
        <div className="d-flex flex-wrap align-items-center">
            {Object.keys(data).map((key) => {
                if (isEmpty(data[key])) return;
                let domainCategoryCode: string = getDomainCategoryCodeForKey(dataKey, key, state) as string;
                domainCategoryCode =
                (domainCategoryCode==='PREFERENCE_FIELD_OF_STUDY_CODE') ? 'FIELD_OF_STUDY_TYPE_CODE' : 
                (domainCategoryCode==='PREFERENCE_COUNTRY_CODE') ? 'COUNTRY_CODE' : 
                domainCategoryCode
                //this above refer getFilteredDomain()
                
                if (!domainCategoryCode) return null;

                if (Array.isArray(data[key])) {
                    return data[key].map((item: any) => {
                        const val = typeof item === 'object' && item !== null && !Array.isArray(item) ? Object.values(item)[0] : item;
                        const displayValue = getDomainValueForCode(val, domainCategoryCode, state);
                        if (!displayValue) return null;
                        return (
                            <div key={`${key}-${item}`} className="badge rounded-pill text-bg-primary p-2 m-1">
                                {displayValue}
                                <i className="bi bi-x-circle p-2" onClick={() => handleClick(key, item)}></i>
                            </div>
                        );
                    });
                } else {
                    const displayValue = getDomainValueForCode(data[key], domainCategoryCode, state);
                    if (!displayValue) return null;
                    return (
                        <div key={`${key}`} className="badge rounded-pill text-bg-primary p-2 m-1">
                            {displayValue}
                            <i className="bi bi-x-circle p-2" onClick={() => handleClick(key, data[key])}></i>
                        </div>
                    );
                }
            })}
            {hasSearchCriteria() && (
                <a key="onClearAll" role="button" onClick={onClearAll} className="mx-3">
                    Clear All Filters
                </a>
            )}
        </div>
    );
};

export default FiltersApplied;
