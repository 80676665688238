import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartContext } from '../../../../library/Core/SmartContext';
import SessionContext from '../../../../library/Core/SessionContext';

const ProfileConsent = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    const model = state.internal.model;
    console.log(model, ' model ');
    const navigate = useNavigate();
    const [guidanceCnt, setGuidanceCnt] = useState(0);

    const isPeerReviewed = sessionState?.isPeerReviewed ?? false;
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
    const userType = sessionStorage.getItem('user-type-code');

    useEffect(() => {
        const fetchData = async () => {
            let guidance_cnt_resp = await axios.get(
                `${process.env.REACT_APP_COMMON_API}/profile/get-alumni-guidance-video-count/${model?.id}`
            );
            setGuidanceCnt(guidance_cnt_resp?.data?.count);
        };
        fetchData();
    }, []);

    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    return (
        <div>
            <div className="underGraduate-detail">
                <div className="blockHeader">
                    <div className="row g-2  align-items-center">
                        <div className="col">
                            <div className="title">
                                <i className="icon icon-chat"></i> <span> Consent </span>
                            </div>
                        </div>
                        <div className="col-auto">
                            <a
                                href="javascript:;"
                                onClick={() => navigate(`/alumni/${model.id}/mentoring-interests`)}
                                className="btn-add-section"
                                data-bs-toggle="modal"
                                data-bs-target="#consent-edit">
                                <b className="icon icon-edit me-2"></b> <span>EDIT</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row g-3">
                    <div className="col-md-6 col-sm-6">
                        <div className="d-flex flex-column line-height-24">
                            <span className="font-16 font-400">Guidance Videos Available </span>
                            {guidanceCnt > 0 && <span className="font-18 font-500">Yes</span>}
                            {guidanceCnt == 0 && <span className="font-18 font-500">No</span>}
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6">
                        <div className="d-flex flex-column line-height-24">
                            <span className="font-16 font-400"> Webinars Available </span>
                            <span className="font-18 font-500">Not Conducted</span>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6">
                        <div className="d-flex flex-column line-height-24">
                            <span className="font-16 font-400"> Would you like to help your juniors by adding guidance videos? </span>
                            <span className="font-18 font-500">
                                {model.mentoringInterests.shareMyStory && model.mentoringInterests.shareMyStory == 'true' && 'Yes'}
                                {model.mentoringInterests.shareMyStory == 'false' && 'No'}
                                {!model.mentoringInterests.shareMyStory && '-'}
                            </span>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6">
                        <div className="d-flex flex-column line-height-24">
                            <span className="font-16 font-400"> Would you like to help your juniors by conducting webinars? </span>
                            <span className="font-18 font-500">
                                {model.mentoringInterests.interestedInWebinar &&
                                    model.mentoringInterests.interestedInWebinar == 'true' &&
                                    'Yes'}
                                {model.mentoringInterests.interestedInWebinar == 'false' && 'No'}
                                {!model.mentoringInterests.interestedInWebinar && '-'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileConsent;
