import { useContext } from 'react';
import { SmartContext } from '../../library/Core/SmartContext';
import { DomainElement } from '../../library/Core/SmartTypes';
import useMessages from './useMessages';
import SessionContext from '../../library/Core/SessionContext';

const MessagesTopBar = () => {
    const { state } = useContext(SmartContext);
    const { openNewMessage, applyUserTypeFilter } = useMessages();
    const { sessionState } = useContext(SessionContext);

    const handleMessageFrom = (event: any) => applyUserTypeFilter(event.target.value);

    const handleNewMessage = () => openNewMessage();
    const usertype = sessionState?.userTypeCode ?? sessionState?.userTypeCode;

    const getPageTitle = () => (state.data.searchBy === 'STUDENT' ? 'Student Messages' : 'Alumni Messages') ?? 'Messages';

    return (
        <div className="row g-2">
            <div className="col-md-6">
                <h1 className="font-24 font-900 my-1">{getPageTitle()}</h1>
            </div>
            <div className="col-md-6">
                <div className="view_new_message_wrap">
                    <select className="form-select" onChange={handleMessageFrom}>
                        <option value="">View Messages From</option>
                        {state.domain.get('MESSAGE_FROM_TYPE_CODE')?.map((item: DomainElement) => {
                            if ((item.code == 'STUDENTS' && usertype == 'STUDENT') || item.code == 'TEACHERS') {
                                return;
                            } else {
                                return (
                                    <option key={item.code} value={item.code}>
                                        {item.value}
                                    </option>
                                );
                            }
                        })}
                    </select>
                    <button className="btn-green-msg" onClick={handleNewMessage}>
                        <span>New Message</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MessagesTopBar;
