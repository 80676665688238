import { useContext } from 'react';
import { getDomainValueForCode, isEmpty } from '../../../library/Core/SmartFunctions';
import { SmartContext } from '../../../library/Core/SmartContext';

const RowColumnBuilder = (column: string, row: any) => {
    let content;
    const { state, dispatch } = useContext(SmartContext);

    const displayStatus = (value: any) => {
        if (value === 1) return 'Yes';
        if (value === 0) return 'No';
        return 'NA';
    };

    const getNetworkAndPairingPartOne = () => (
        <div key={column} className="d-flex flex-column">
            <span>
                Similar Profile Alumni - <b>{displayStatus(row['networkSimilarIndustryAlumni'])}</b>
            </span>
            <span>
                Volunteering Work - <b>{displayStatus(row['networkForSocialCausesVolunteer'])}</b>
            </span>
            <span>
                Business Partnerships - <b>{displayStatus(row['networkForBusinessIdeasAndPartnerships'])}</b>
            </span>
        </div>
    );

    const getNetworkAndPairingPartTwo = () => {
        if (isEmpty(row?.preferencesJson)) return <span>Preferences Not Selected</span>;

        const preferences = row['preferencesJson'];

        const renderPreferenceItem = (label: string, value: any) => {
            return value ? (
                <span>
                    <strong>{label}</strong> - {value}
                </span>
            ) : null;
        };

        let courseDisp = "";
        let countryDisp = "";
        let industryDisp = "";
        let functionalAreaDisp = "";

        let comma = "";

        if ( ! isEmpty(row.filtersCourse) ) {
            let courseList = row.filtersCourse.split(',');
            for ( let i = 0; i < courseList.length; i++ ) {
                courseDisp += `${comma} ${getDomainValueForCode(courseList[i].trim(), "FIELD_OF_STUDY_TYPE_CODE", state)}`;
                comma = ", ";
            }
        }

        comma = "";
        if ( ! isEmpty(row.filtersCountry) ) {
            let countryList = row.filtersCountry.split(',');
            for ( let i = 0; i < countryList.length; i++ ) {
                countryDisp += `${comma} ${getDomainValueForCode(countryList[i].trim(), "COUNTRY_CODE", state)}`;
                comma = ", ";
            }
        }

        comma = "";
        if ( ! isEmpty(row.filtersIndustry) ) {
            let industryList = row.filtersIndustry.split(',');
            for ( let i = 0; i < industryList.length; i++ ) {
                industryDisp += `${comma} ${getDomainValueForCode(industryList[i].trim(), "INDUSTRY_CODE", state)}`;
                comma = ", ";
            }
        }

        comma = "";
        if ( ! isEmpty(row.filtersFunctionalArea) ) {
            let functionalAreaList = row.filtersFunctionalArea.split(',');
            for ( let i = 0; i < functionalAreaList.length; i++ ) {
                functionalAreaDisp += `${comma} ${getDomainValueForCode(functionalAreaList[i].trim(), "FUNCTIONAL_AREA_CODE", state)}`;
                comma = ", ";
            }
        }


        const preferenceItems = [
            // renderPreferenceItem('Course', isEmpty(preferences?.courseList) ? "NA" : preferences?.courseList),
            // renderPreferenceItem('Country', isEmpty(preferences?.countryList) ? "NA" : preferences?.countryList),
            // renderPreferenceItem('Industry', isEmpty(preferences?.industryList) ? "NA" : preferences?.industryList),
            // renderPreferenceItem('Functional Area', isEmpty(preferences?.functionalAreaList) ? "NA" : preferences?.functionalAreaList),

            renderPreferenceItem('Course', isEmpty(courseDisp) ? "NA" : courseDisp),
            renderPreferenceItem('Country', isEmpty(countryDisp) ? "NA" : countryDisp),
            renderPreferenceItem('Industry', isEmpty(industryDisp) ? "NA" : industryDisp),
            renderPreferenceItem('Functional Area', isEmpty(functionalAreaDisp) ? "NA" : functionalAreaDisp),
        ].filter(Boolean); // Filter out null values

        if (preferenceItems.length === 0) {
            return <span>Preferences Not Available</span>;
        }

        return (
            <div key={column} className="d-flex flex-column text-wrap">
                {preferenceItems}
            </div>
        );
    };

    const getEventsAndCareerSupportPartOne = () => (
        <div key={column} className="d-flex flex-column">
            <span>
                Jobs and Internships - <b>{displayStatus(row['eventsNotifyJobReferrals'] || row['eventsNotifyInternships'])}</b>
            </span>
            <span>
                Higher Studies - <b>{displayStatus(row['eventsPursueUgDegree'] || row['eventsPursuePgDegree'])}</b>
            </span>
            <span>
                Help with Application -{' '}
                <b>{displayStatus(row['eventsNeedHelpForUgApplication'] || row['eventsNeedHelpForPgApplication'])}</b>
            </span>
        </div>
    );

    const getEventsAndCareerSupportPartTwo = () => (
        <div key={column} className="d-flex flex-column">
            <span>
                Upskilling - <b>{displayStatus(row['eventsInterestedInUpskilling'])}</b>
            </span>
            <span>
                Entrepreneurship - <b>{displayStatus(row['eventsInterestedInEntrepreneurship'])}</b>
            </span>
            <span>
                Remote Working - <b>{displayStatus(row['eventsInterestedInRemoteWorking'])}</b>
            </span>
        </div>
    );

    switch (column) {
        case 'networkAndPairingOne':
            content = getNetworkAndPairingPartOne();
            break;
        case 'networkAndPairingTwo':
            content = getNetworkAndPairingPartTwo();
            break;
        case 'eventsAndCareerSupportOne':
            content = getEventsAndCareerSupportPartOne();
            break;
        case 'eventsAndCareerSupportTwo':
            content = getEventsAndCareerSupportPartTwo();
            break;
    }

    return content;
};

export default RowColumnBuilder;
