import { AxiosResponse } from 'axios';
import { getValueFromSessionStore } from '../library/Core/SessionStore';
import { isEmpty } from '../library/Core/SmartFunctions';
import { axiosClient } from '../library/Service/axiosClient';

class PageConfigService {
    static async get(pageName: string): Promise<any> {
        if (pageName == null) return {};
        if (pageName === 'onboarding') return Promise.resolve({ data: {}, source: 'local-storage' });
        const storedPageConfig = getValueFromSessionStore(`page-config-${pageName}`);
        if (!isEmpty(storedPageConfig)) return Promise.resolve({ data: JSON.parse(storedPageConfig as string), source: 'local-storage' });
        const pageConfigBaseUrl = `${process.env.REACT_APP_PAGE_CONFIG_SERVER}/v1/pageConfig`;
        return axiosClient().get(`${pageConfigBaseUrl}/${pageName}`) as Promise<AxiosResponse<any, any>>;
    }
}

export default PageConfigService;
