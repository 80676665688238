import { useEffect, useState } from 'react';

import imgTotalJoinedAlumni from '../../../styles/images/graduates.svg';
import upToDate from '../../../styles/images/timetable.svg';
import l1Due from '../../../styles/images/passport.svg';
import { useNavigate } from 'react-router-dom';
import { axiosClient } from '../../../library/Service/axiosClient';

const SummaryCardControl = () => {
    const [ summaryCardsData, setsummaryCardsData ] = useState({'totalAlumni':0,'upToDate':0,'l1Due':0});

    const summaryCardsControl = [
        { 
            id: 'totalAlumni', 
            label: 'Total Alumni', 
            image: imgTotalJoinedAlumni, 
            class: 'totalAlumni',
            link: 'support/all-alumni-report'
        },
        // { 
        //     id: 'upToDate', 
        //     label: 'Up-To Date', 
        //     image: upToDate, 
        //     class: 'uptoDate',
        //     link: 'support/all-alumni-report?profile_completion=UpToDate'
        // },
        // {
        //     id: 'l1Due',
        //     label: 'L1 Due',
        //     image: l1Due,
        //     class: 'due',
        //     link: 'support/all-alumni-report?profile_completion=L1Due'
        // }
    ];

    useEffect(() => {
        const link = `${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/search/all-school-summary-header-counts`;
        axiosClient()
        .post(link)
        .then((response:any) => {
            if(response.status === 200)
            {
                const data = response.data[0];
                setsummaryCardsData({...summaryCardsData,...data});
            }

        })
    },[]);

    const navigate = useNavigate();

    const handleSummaryCardClick = (id: string) => {
        const foundControl = summaryCardsControl.find(control => control.id === id);

        if (foundControl) {
            window.open(process.env.REACT_APP_APP_BASE_URL + `/` +foundControl.link);
        } else {
            console.error('Control not found');
        }
    };

    // console.log(summaryCardsControl,'cards');

    return (
        <section className="bg-red pt-3 pt-sm-5 pb-3" id="alumni_directory">
        <div className="container max-1140 px-lg-0">
            <div className="row alumni-directory">
                <div className="col-lg-12 d-flex flex-wrap align-items-center mb-3 pb-1">
                    <h2 className="font-26 font-600 text-white mb-3 mb-sm-0  me-3 aos-init aos-animate">All Institute Summary</h2>
                </div>
            </div>
            <div className="x-scroll">
                <div className="summary-blocks">
                {summaryCardsControl.map((control: any) => (
                    <div className={"block cursor-pointer "+control.class} key={control.id} onClick={() => handleSummaryCardClick(control.id)}>
                        <div className="icon">
                            <img src={control.image} width="48" />
                        </div>
                        <div className="block-detail">
                            <div className="number">{summaryCardsData[control.id as keyof typeof summaryCardsData]  ?? 0}</div>
                            <div className="block-detail-title">{control.label}</div>
                        </div>
                    </div>
                ))}

                </div>
            </div>
        </div>
        </section>
    );
};

export default SummaryCardControl;
