import { useState } from 'react';
import CollegeProperties from './CollegeProperties';
import CollegeSearchGrid from './CollegeSearchGrid';

const CompareColleges = (props: any) => {
    const [isOpen, setOpen] = useState(false);

    const handleFilterToggle = () => {
        setOpen(!isOpen);
    };

    return (
        <>
            <div className="row m-0 pt-4 pb-5 mt-2 JobInternshipListing">
                <div className="col-md-12 p-0">
                    <p className="font-16 mb-4">
                        Confused about which college to choose? Add up to 3 universities for college comparison on various points
                    </p>

                    <div className="position-relative compareCollegeswrap">
                        <div className="tabular-data">
                            <div id="content">
                                <div className="row pb-4">
                                    <CollegeProperties />
                                    <CollegeSearchGrid />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CompareColleges;
