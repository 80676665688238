import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SessionContext from '../../../../../../library/Core/SessionContext';
import { SmartContext } from '../../../../../../library/Core/SmartContext';
import Ability from './Ability';
import Willingness from './Willingness';
import Interests from './Interests';
import { axiosClient } from '../../../../../../library/Service/axiosClient';
import ProfileMakerService from './ProfileMakerService';
import { getDomainValueForCode, isEmpty } from '../../../../../../library/Core/SmartFunctions';
import useSmartState from '../../../../../../common/hooks/useSmartState';
import ViewProfileMarkersLogs from './viewProfileMarkersLogs';

const ProfileMarker = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);
    const { fetchUserProfileMarkers,fetchUserProfileMarkersLogs } = ProfileMakerService();

    const navigate = useNavigate();
    const [isPersonate, setIsPersonate] = useState(sessionStorage.getItem('is-impersonated-session') === 'YES');
    const [activeMarkerTab, setActiveMarkerTab] = useState('ability');
    const [buttonText, setbuttonText] = useState(['']);
    const { setStateValue } = useSmartState();
    const [LogsData,setLogsData]= useState('');

    let institutesStatus = state?.data?.userInstitutes?.filter((institute: any) => {
        return institute.userStatusCode != 'INACTIVE';
    });

    useEffect(() => {
        const fetchUserMarkers = async () => {
            const userMarkers = await fetchUserProfileMarkers(state?.internal?.model?.id);
        };
        fetchUserMarkers();
    }, []);

    const renderActiveTab = () => {
        alert(activeMarkerTab);
        switch (activeMarkerTab) {
            case 'ability':
                return <Ability />;
            case 'willingness':
                console.log('willingness');
                return <Willingness />;
            case 'interests':
                return <Interests />;
            default:
                return <Ability />;
        }
    };

    useEffect(() => {
        let txtArray:string[] = [];
        institutesStatus = state?.data?.userInstitutes?.filter((institute: any) => {
            return institute.userStatusCode != 'INACTIVE';
        });
        institutesStatus.map((institute:any,i:number) => {
            let status = 'Disable Login';
            if(institute.userStatusCode == 'RAW_PROFILE'){
                status = 'Enable Login'
            }
            const btnString = getDomainValueForCode(institute.instituteId,'INSTITUTE_DOMAIN',state) +' - '+ status;
            txtArray.push(btnString);
        })
        setbuttonText(txtArray);
    }, [state?.data?.userInstitutes]);

    const handleToggleUserStatus = async (instituteId:any) => {
        try {
            const response = await axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/actions/toggle-user-status`,
                {
                    'uuid' : state?.data?.id,
                    'instituteId' : instituteId
                }
            );
            const data = await response.data;
            if (data.success && !isEmpty(data.userStatusCode)) {
                let userInstitutes = state?.data?.userInstitutes;
                const updatedInstitutes = userInstitutes.map((institute: any) => {
                    if (institute.instituteId == instituteId) {
                        return {
                            ...institute,
                            userStatusCode: data.userStatusCode,
                        };
                    }
                    return institute;
                });
                setStateValue('userInstitutes',updatedInstitutes);
                // console.log(updatedInstitutes,'userInstitutes')
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <div className="border-d2d2d2 border-radius-6 bg-white overflow-hidden">
            <div className="block-header">
                <div className="row g-3 align-items-center">
                    <div className="col">
                        <h3 className="mb-0 font-22 d-flex align-items-center">
                            <i className="icon-selected-user me-2"></i>
                            <span>Profile Markers</span>
                        </h3>
                    </div>
                    <div className="col-auto">
                        <a className="btn btn-border-lite-blue text-decoration-none" href={`/profilemarkersnotes/${state?.data?.id}/list`}>
                            <i className="icon-note me-2"></i>
                            <span>Profile Notes</span>
                        </a>
                    </div> 
                
                    {institutesStatus.map((institute: any,i:number) => (
                                <div className="col-auto">
                                    <a
                                        href="javascript:;"
                                        className="btn-add-section"
                                        onClick={() => handleToggleUserStatus(institute.instituteId)}
                                        key={institute.instituteId}
                                    >
                                        <b className="icon icon-edit me-2"></b> <span>{buttonText[i]}</span>
                                    </a>
                                </div>
                                ))}
                </div>
            </div>
            <div className="block-content">
                <div className="block-custom-tab">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${activeMarkerTab === 'ability' ? 'active' : ''}`}
                                id="pills-ability-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-ability"
                                type="button"
                                role="tab"
                                aria-controls="pills-ability"
                                aria-selected={activeMarkerTab === 'ability'}
                                onClick={() => setActiveMarkerTab('ability')}>
                                ABILITY
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${activeMarkerTab === 'willingness' ? '' : ''}`}
                                id="pills-willingness-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-willingness"
                                type="button"
                                role="tab"
                                aria-controls="pills-willingness"
                                aria-selected={activeMarkerTab === 'willingness'}
                                onClick={() => setActiveMarkerTab('willingness')}>
                                WILLINGNESS
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${activeMarkerTab === 'interests' ? 'active' : ''}`}
                                id="pills-interests-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-interests"
                                type="button"
                                role="tab"
                                aria-controls="pills-interests"
                                aria-selected={activeMarkerTab === 'interests'}
                                onClick={() => setActiveMarkerTab('interests')}>
                                INTERESTS
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        {activeMarkerTab == 'ability' && <Ability />}
                        {activeMarkerTab == 'willingness' && <Willingness />}
                        {activeMarkerTab == 'interests' && <Interests />}
                    </div>
                </div>
            </div>
            {/* modal logs */}
            <ViewProfileMarkersLogs />
            {/* logs end */}
        </div>
    );
};

export default ProfileMarker;
