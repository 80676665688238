import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
//import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    if (!isImpersonatedSession && !isFormValid(state)) {
        // Note: As per the custom implementation of isFormValid() function, we are dispatching the SHOW_ERRORS action from here
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state?.formValidationErrors);
        // alert('Please enter the mandatory fields!');
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            underGradColleges: state?.data['underGradColleges'],
            underGradCollegeJoined: state?.data['underGradCollegeJoined'],
            underGradCollegeApplied: state?.data['underGradCollegeApplied'],
        })
    )
    // .then(() => ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.SCHOLARSHIPS, state, dispatch));
    .then((response) => {
        if (response?.status === 200) {
            // navigateToNextStep(AlumniProfileEditScreens.SCHOLARSHIPS, state, dispatch, sessionState, sessionDispatch);
            return state.actions['REDIRECT'](`/alumni/${state.routeInfo.id}/profile/ug`);
        }
    });
};

// Note: This is the custom implementation of isFormValid() function
const isFormValid = (state: State): boolean => {
    const formErrors = { ...state.formValidationErrors };

    if (!state?.data['underGradCollegeJoined']['didYouReceiveScholarship']) formErrors['underGradCollegeJoined.scholarshipAmount'] = [];

    state?.data['underGradCollegeApplied'].map((item: any, index: number) => {
        if (!item['didYouReceiveScholarship']) formErrors[`underGradCollegeApplied.${index}.scholarshipAmount`] = [];
    });

    return (
        Object.values(formErrors)
            .filter((item) => item !== undefined)
            .flat().length === 0
    );
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.SCHOLARSHIPS, state, dispatch);
};
