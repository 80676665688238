import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';

import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import { onMobileNumberChange } from '../../signup/signupAction';
import { handleSubmit } from './StudentBasicInfoActions';
import StudentBasicInfoLayout from './StudentBasicInfoLayout';

const StudentBasicInfo = () => {
    const { globalState } = useContext(GlobalContext);
    // const { id, pageName } = useParams();
    const navigate = useNavigate();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { SUBMIT: handleSubmit, ROUTER_NAVIGATE: navigate, mobileNumber: onMobileNumberChange },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    if(sessionStorage.getItem('user-type-code') === 'STUDENT' && sessionStorage.getItem('user-profile-status-code') === 'PREFERENCES_INCOMPLETE')
    {
        window.location.href = `/student/${sessionStorage.getItem('user-id')}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <StudentBasicInfoLayout>{!state.flags.isDataLoading && <PageBuilder />}</StudentBasicInfoLayout>
        </SmartContext.Provider>
    );
};

export default StudentBasicInfo;
