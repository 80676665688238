import { Dropdown,Tooltip } from 'bootstrap';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import useSmartState from '../../../common/hooks/useSmartState';
import { renderToString } from 'react-dom/server';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import { REPORT_CONFIG } from '../../admin/reports/report-config';
import instituteListAction from './instituteListAction';
import ActionSettings from '../../../library/SimpleControls/ActionSettings';
import { Link } from 'react-router-dom';
import InstituteLisingService from './InstituteLisingService';
import { toast } from 'react-toastify';

const BATCH_SIZE = 10;
const DEFAULT_SORT_COLUMN = 'createdAt';
const DEFAULT_SORT_DIRECTION = 'desc';

const InstituteGrid = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const [sortedStudents, setSortedStudents] = useState<string[]>([]);
    const [visibleStudents, setVisibleStudents] = useState(state.data.alumniList.slice(0, 10));
    const loadMoreRef: any = useRef();
    const dropdownRef = useRef(null);
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);
    const allRows = [...state.data.alumniList] ?? [];
    const config = REPORT_CONFIG[state.routeInfo.pageName];
    const { getFormattedDate,publishUnpublishInstitute } = instituteListAction();


    useEffect(() => {
        console.log(state.data.alumniList);
        console.log(`useEffect 1`);
        if (dropdownRef.current) {
            new (Dropdown as any)(dropdownRef.current);
        }
    }, []);

    useEffect(() => {
        console.log(`useEffect 2`);
        const sorted = [...allRows].sort(compareFunction);
        setSortedStudents(sorted);
    }, [state.data.alumniList, sortColumn, sortDirection]);

    useEffect(() => {
        console.log(`useEffect 3`);
        setVisibleStudents(sortedStudents.slice(0, BATCH_SIZE));
    }, [sortedStudents]);

    useEffect(() => {
        console.log(`useEffect 4`);
        const loadMoreObserver = new IntersectionObserver((entries) => {
            // Check if all students are already visible
            if (sortedStudents.length <= visibleStudents.length) {
                if (loadMoreRef.current) {
                    loadMoreObserver.unobserve(loadMoreRef.current); // Stop observing
                }
                return;
            }

            if (entries[0].isIntersecting) {
                const nextBatch = sortedStudents.slice(0, visibleStudents.length + BATCH_SIZE);
                setVisibleStudents([...nextBatch]);
            }
        });

        if (loadMoreRef.current) loadMoreObserver.observe(loadMoreRef.current);
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });

        return () => {
            if (loadMoreRef.current) {
                loadMoreObserver.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, visibleStudents]);


    // Utility to determine if the column is a string type.
    const isStringColumn = typeof visibleStudents[0]?.[sortColumn] === 'string';

    const compareFunction = (a: any, b: any) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (isStringColumn) {
            return sortDirection === 'asc'
                ? aValue?.localeCompare(bValue, undefined, { sensitivity: 'accent' })
                : bValue?.localeCompare(aValue, undefined, { sensitivity: 'accent' });
        } else {
            return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
        }
    };

    const getActionNode = (row: any) => {
        const actions = config['ROW_ACTION'].filter((action: any) => {
            if (row.isPeerReviewed == 1 && action === 'VALIDATE') return false;
            // if (row.userStatusCode === 'ACTIVE' && action === 'ACTIVATE') return false;
            if (row.isPublished == 1 && action === 'Publish') return false;
            if (row.isPublished != 1 && action === 'Unpublish') return false;
            return true;
        });

        return actions && actions.length > 1 ? (
            <ActionSettings id={row?.id} actions={actions} onClick={handleAction} />
        ) : null;
    };

    const handleAction = (row: any, action: string) => {
        console.log(row,action)
        handlePerformAction(action,row)
    }

    const handlePerformAction = async (action: string, row: any) => {
        switch (action) {
            case 'PUBLISH':
                publishUnpublishInstituteaction(row,1)
                fetchInstituteCount(state);
                break;
            case 'UNPUBLISH':
                await publishUnpublishInstituteaction(row,2);
                fetchInstituteCount(state);
                break;
            case 'UPDATE':
                window.open(`${process.env.REACT_APP_APP_BASE_URL}/institute/${row}/basic-info`);
                break;
        }
    };

    const publishUnpublishInstituteaction = async ( id: any,type:number) => {
        const message=(type==1) ? 'Published successfully' : 'Unpublished successfully';
        await publishUnpublishInstitute(id,type);
        InstituteLisingService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
            setStateValue('alumniList', response.data);
            toast.success(message);
        });
    }

    const fetchInstituteCount = async (state:any) => {
        try {
            const instituteCount = await InstituteLisingService.searchCount(state.data.searchCriteria, state.routeInfo.pageName);
                            
            dispatch({
                type: 'SET_INTERNAL_STATE',
                payload: { key:"instituteCount", value:instituteCount?.data },
            });
        } catch (error) {
            console.error("Error fetching alumni count:", error);
        }
    }

    const contcats_tool = (row: any) => {
        if (row.contactNames && typeof row.contactNames === 'string') {
            const contactNames = row.contactNames.split(',');
            if (contactNames.length > 0) {
                const listItems = contactNames
                    .map((name: any) => `<li>${name.trim()}</li>`)
                    .join('');
                const viewDetailsLink = `<a  href="/institute/${row.id}/${row.instituteGroupId}/contact-listing">View Details</a>`;
                const html = `<ol>${listItems}</ol>${viewDetailsLink}`;
                return html;
            }
        }
        return '';
    };

    return (
        <> 
         <div>
            <div className="ActiveListings_present">
                <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks my-2">
                            <div className="d-flex justify-content-start align-items-center customselect_parnt">
                            </div>
                    </div>
                    <div className="d-flex flex-wrap align-items-center justify-content-end">
                        <h3 className="font-14 font-500 mb-0 me-2">Total {state?.internal?.instituteCount && state?.internal?.instituteCount[0]?.totalInstitute.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} records</h3>
                    </div>
                </div>
                <div className="table-responsive attendee_dtl">
                    <table className="table" style={{ minWidth: '1042px' }}>
                        <thead>
                            <tr>
                                <th>Institute Name</th>
                                <th>Group Name</th>
                                <th>Type</th>
                                <th>Location</th>
                                <th>Contacts added</th>
                                <th>Programs added</th>
                                <th>Created At</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {visibleStudents.map((row: any) => (
                            <tr key={row.id}>
                                <td>{row.name} </td>  
                                <td>
                                {typeof getDomainValueForCode(row.instituteGroupId, 'SCHOOL_GROUP', state) === 'string' ? 
                                    getDomainValueForCode(row.instituteGroupId, 'SCHOOL_GROUP', state) : 
                                    'NA'}
                                </td>
                                <td>{row.instituteTypeCode.replace(/_/g, ' ')} </td>
                                <td>
                                    {getDomainValueForCode(row.countryCode, 'COUNTRY_CODE', state) ??
                                        (row.CITY_CODE
                                        ? `, ${getDomainValueForCode(row.CITY_CODE, 'CITY_CODE', state)}`
                                        : getDomainValueForCode(row.countryCode, 'COUNTRY_CODE', state)) ??
                                        ''}
                                </td>   
                                <td className="text-start">
                                    <div>
                                        {row.contactsCount > 0 ? (
                                            <>
                                                {row.contactsCount} Contacts <br/>
                                                (
                                                <a
                                                    href="javascript:;"
                                                    className="btn-conview btn-html "
                                                    data-bs-toggle="tooltip"
                                                    data-bs-html="true"
                                                    title=""
                                                    data-bs-original-title={contcats_tool(row)}>
                                                    View
                                                </a>
                                                )
                                            </>
                                        ) : (
                                            <Link
                                            className="btn-conview"
                                            to={`/institute/${row.id}/${row.instituteGroupId}/new/contact-add`}
                                            rel="noopener noreferrer">
                                            Add Contacts
                                           </Link>
                                        )}
                                    </div>
                                </td>
                            <td className="text-start">{row.programCount}</td>
                            <td>{getFormattedDate(row.createdAt)} </td>      
                            <td>{getActionNode(row)}   
                            </td>             
                            </tr>
                          ))}
                        <tr ref={loadMoreRef} />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
        );
    };
    
export default InstituteGrid;
