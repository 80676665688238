import React, { useState, useEffect } from 'react';
import axios from 'axios';
const AddNewLeadProgram: React.FC = () => {
    const [selectedPrograms, setSelectedPrograms] = useState<string[]>(['']); // Array to store selected programs
    const [startYear, setStartYear] = useState<number>(new Date().getFullYear() + 0); // Start year for intake dropdown
    const maxDropdowns =3; // Maximum number of dropdowns allowed
    const [responses, setResponses] = useState<string[]>([]);
    const [sources, setSources] = useState<string[]>([]);
    const [selectedIntake, setSelectedIntake] = useState<string>('');
    const [selectedSource, setSelectedSource] = useState<string>('');
    const [selectedResponce, setSelectedResponce] = useState<string>('');
    const [programError, setProgramError] = useState<string>('');
    const [intakeError, setIntakeError] = useState<string>('');
    const [sourceError, setSourceError] = useState<string>('');
    const [names, setNames] = useState<string[]>([]);
    const [ids, setIds] = useState<number[]>([]);
  
    //get id from url
 // Get the URLSearchParams object from the current URL
const queryParams = new URLSearchParams(window.location.search);
const userDataString = sessionStorage.getItem('user');
if (!userDataString) {
   // window.location.href = "/404";
}
const insertIdsParam = queryParams.get('insert_id');

//alert(insertIdsParam)
const insertIds = insertIdsParam?.split(',') ?? [];
const formattedIds = insertIds.map(insertIds => `${insertIds}`).join(', ');
;
// Split the insertIdsParam string by comma to get an array of insert IDs
    
    useEffect(() => {
        // Fetch data from API when component mounts
        fetchPrograms();      
        fetchResponses();
        fetchSource();
    }, []);
   
    // Function to fetch programs from API
    const fetchPrograms = async () => {
        let instituteId=formattedIds;
       // alert(formattedIds)
        try {
      // alert(instituteId);     
        const requestBody = {instituteId}; // Construct the request body
        const response = await axios.post(`${process.env.REACT_APP_LEAD_SERVER}/addlead/getprogram`, requestBody);
        const data = response.data;
            if (data.success) {
                // Update component state with fetched programs
                setNames(data.names);
                setIds(data.id);
               // alert(data.names); 
            } else {
                console.error('Failed to fetch programs:', data.message);
            }
        } catch (error) {
            console.error('An error occurred while fetching programs:', error);
        }
    };


   
        const fetchSource = async () => {
            try {
                const response = await axios.get<{ success: boolean, message: string, lead_sources: string[] }>(`${process.env.REACT_APP_LEAD_SERVER}/addlead/getsource`);
                const data = response.data;
                console.log("Source data:", data);
                if (data.success && Array.isArray(data.lead_sources) && data.lead_sources.length > 0) {
                    console.log("Parsed sources:", data.lead_sources);
                    setSources(data.lead_sources);
                } else {
                    console.error('Failed to fetch sources:', data.message || 'Unknown error occurred');
                }
            } catch (error) {
                console.error('An error occurred while fetching sources:', error);
            }
        };

     
    
        const fetchResponses = async () => {
            try {
                const response = await axios.get<{ success: boolean, message: string, lead_responds: string[] }>(`${process.env.REACT_APP_LEAD_SERVER}/addlead/getresponse`);
                const data = response.data;
                console.log("Response data:", data);
                if (data.success && Array.isArray(data.lead_responds) && data.lead_responds.length > 0) {
                    console.log("Parsed responses:", data.lead_responds);
                    setResponses(data.lead_responds);
                } else {
                    console.error('Failed to fetch responses:', data.message || 'Unknown error occurred');
                }
            } catch (error) {
                console.error('An error occurred while fetching responses:', error);
            }
        };
      // Function to generate the list of years
        const generateYearOptions = () => {
            const years = [];
            for (let i = startYear; i < startYear + maxDropdowns; i++) {
                years.push(i.toString());
            }
            return years;
        };
 // Function to generate options for program dropdown
 const generateProgramCheckboxes = (): JSX.Element[] => {
    return ids.map((id, index) => (
        <div key={id} className="col-auto">
            <div className='form-check'>
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="program"
                    id={`program-${id}`}
                    value={id.toString()} // Convert id to string
                    checked={selectedPrograms.includes(id.toString())} // Convert id to string
                    onChange={(e) => handleProgramCheckboxChange(e, id)}
                />
                <label className="form-check-label" htmlFor={`program-${id}`}>
                    {names[index]}
                </label>
            </div>
        </div>
    ));
};



    // Event handler for program checkbox change
    const handleProgramCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            // Add the selected program ID to the list
            setSelectedPrograms([...selectedPrograms, id.toString()]); // Convert id to string
        } else {
            // Remove the selected program ID from the list
            setSelectedPrograms(selectedPrograms.filter(programId => programId !== id.toString())); // Convert id to string
        }
    };
    
 // Validation function
 const validateForm = () => {
    const filteredPrograms = selectedPrograms.filter(program => program !== '' && selectedPrograms.length > 1);
    let isValid = true;
    if (filteredPrograms.length === 0) {
        setProgramError('Please select at least one program.');
        isValid = false;
    } else {
        setProgramError('');
    }
    

    if (!selectedIntake) {
        setIntakeError('Please select a planned intake year.');
        isValid = false;
    } else {
        setIntakeError('');
    }

    if (!selectedSource) {
        setSourceError('Please select a lead source.');
        isValid = false;
    } else {
        setSourceError('');
    }

    return isValid;
};
 // Form submission handler
 const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const filteredProgramsToFilter = selectedPrograms.filter(program => program !== '');
    const programsToFilter = filteredProgramsToFilter.length > 0 ? filteredProgramsToFilter : null;

    event.preventDefault(); // Prevent default form submission behavior
    if (validateForm()) {

        const formattedIdsArray = formattedIds.split(',').map(id => id.trim());
//alert(selectedSource);
//alert(selectedIntake);

// Iterate over each ID and make separate API calls
for (const id of formattedIdsArray) {
    // Construct request body for each ID
    const requestBody = {    
        program: programsToFilter, 
        intake: selectedIntake,
        source: selectedSource,
        response: selectedResponce,
        id: id
        // Add other fields from the form as needed
    };
    try {
        // Make API call to update target program for each ID
        const response = await axios.post(`${process.env.REACT_APP_LEAD_SERVER}/addlead/updatetargetProgram`, requestBody);
            
        // Handle success response
        if (response.data.success) {
            console.log(`Data updated successfully for ID ${id}:`, response.data.message);
            window.location.href = `/lead/managelead`; 
            // Clear form fields or show success message
        } else {
            console.error(`Failed to update data for ID ${id}:`, response.data.message);
            // Handle error response
        }
    } catch (error) {
        console.error(`An error occurred while updating data for ID ${id}:`, error);
        // Handle API call error
    }
}
        // Proceed with form submission
        console.log('Form submitted successfully');
    }
};

    return (
        <div className="main flex-1 bg-white">
            <section className="alumni_directory_details container-fluid">
                <div className="max-1140 pt-3 pt-sm-5 px-lg-0 pb-5 overflow-hidden mx-auto">
                    <div className="m-w-90 d-flex justify-content-between align-items-center mb-3">
                        <h3 data-aos="fade-left" data-aos-delay="600" className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                            Update Lead
                        </h3>
                        
                    </div>

                    <div className="job_internship_tab polartabsec radius-6 mx-auto">
                        <div className="xscroll p-0">
                        <ul className="nav nav-tabs" id="JobInternshipTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                <button 
                                className="nav-link" 
                                type="button" 
                                role="tab" 
                                aria-controls="target-class" 
                                aria-selected="false" 
                                disabled  // Add the disabled attribute
                            >Personal Info</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="target-class-tab" data-bs-toggle="tab" data-bs-target="#target-class" type="button" role="tab" aria-controls="target-class" aria-selected="false">Target Program</button>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    {/* tab buttons ends here */}

                    <div className="row m-0 pt-4 mt-2 JobInternshipListing">
                        <div className="flex-1 polarcontsec tab-content" id="myTabContent">
                            
                            {/* basic-info Tab ends here */}

        <div className="tab-pane fade show active" id="target-class" role="tabpanel" aria-labelledby="target-class-tab">
            <form className="lstCustForm w-100 formrowpx mt-2 max-700 mx-auto" onSubmit={handleSubmit}>
                <div className="row g-4">
                    <div className="col-md-12">
                        <label className="form-label m-0 mb-1 font-18 font-500 w-100" htmlFor="OfficialnameofInstitute">Program</label>
                        <div className="row g-2">
                            <div className="col-12">
                                <div className="row g-2 g-sm-3 g-md-4">
                                    
                            {generateProgramCheckboxes()}
                            {programError && <div className="error-message">{programError}</div>}
                            </div>
                            </div>
                        </div>
                    </div>

                    





                    <div className="col-md-12">
                        <label className="form-label m-0 mb-1 font-18 font-500 w-100" htmlFor="OfficialnameofInstitute">Planned Intake</label>
                        <div className="row g-2">
                          
                            <div className="col-12">
                                <div className="row g-2">
                                    <div className="col">
                    
                        <div className="d-flex mb-2">
                            <select
                               
                                className="form-select me-2"
                                value={selectedIntake}
                                onChange={(e) => setSelectedIntake(e.target.value)}
                                // Add event handler if needed
                            > 
                            <option value="">Select Intake</option>
                            
                            {generateYearOptions().map((year, index) => (
                                <option value={year}>{year}</option>
                                ))}
                            </select>
                            
                        </div>
                        {intakeError && <div className="error-message">{intakeError}</div>}
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>



        <div className="col-md-6">
        <label className="form-label m-0 mb-1 font-18 font-500 w-100" htmlFor="OfficialnameofInstitute">Lead Source</label>                                 
        <select 
            className="form-select" 
            id="source" 
            name="preferredjoblocation" 
            aria-label="Default select example" 
            value={selectedSource}  
            onChange={(e) => setSelectedSource(e.target.value)}
        >
            <option value="">Select</option>
            {sources.map((source, index) => (
                <option key={index} value={source}>{source}</option>
            ))}
        </select>
        {sourceError && <div className="error-message">{sourceError}</div>}

                    </div>
                    <div className="col-md-6">
                        <label className="form-label m-0 mb-1 font-18 font-500 w-100" htmlFor="OfficialnameofInstitute">Lead Response (optional)</label>
                       
                        <select 
            id="responses" 
            className="form-select" 
            name="preferredjoblocation" 
            aria-label="Default select example"  
            value={selectedResponce} 
            onChange={(e) => setSelectedResponce(e.target.value)}
        >
            <option value="">Select</option>
            {responses.map((response, index) => (
                <option key={index} value={response}>{response}</option>
            ))}
        </select>
                                    
                                   
                         
                    </div>
                   

                    <div className="col-md-12">
                        <div className="action_btns mt-3">  <button type="submit" className="btn btn-green height-45 px-4"><span>Save</span></button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

                            <div className="tab-pane fade" id="attended-class" role="tabpanel" aria-labelledby="attended-class-tab">
                                {/* Content */}
                            </div>
                        </div>
                    </div>
                    {/* Job Internship Listing */}
                </div>
            </section>
        </div>
    );
};

export default AddNewLeadProgram;
