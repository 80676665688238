import { getUniversityImage } from '../../../common/styles/Images';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import { getFieldOfStudyName } from '../alumni-profile/service/student-profile-view.service';

const CardSchool = ({ alumni, state }: { alumni: any; state: any }) => {
    const getTitle = () => {
        const title = {};
        return 'School';
    };

    const getUniversityName = () => {
        return 'Uni Name';
    };

    const getFieldOfStudy = () => {
        return 'Medicine';
    };

    return (
        <div key={alumni.uuid} className="alumni_univarsity_details pursuing">
            <div className="alumni_univarsity_details_inner_cover">
                <div className="alumni_univarsity_logo">
                    <img src={getUniversityImage(alumni?.studentOutcomeSchoolLogo)} alt="" />
                </div>
                <div className="alumni_univarsity_brife">
                    <div className="alumni_education">
                        <div className="row g-2">
                            <div className="col">{getTitle()}</div>
                        </div>
                    </div>
                    <div className="alumni_univarsity_name">
                        <span>{alumni?.studentOutcomesSchoolName}</span>
                        <i className="ms-1">
                            <img src="images/subtraction.svg" alt="" />
                        </i>
                    </div>
                   
                </div>
            </div>
        </div>
    );
};

export default CardSchool;
