import { useContext, useEffect, useRef, useState } from "react";
import GuidanceService from "../../../guidance/listing/GuidanceService";
import { SmartContext } from "../../../../library/Core/SmartContext";
import { useParams } from "react-router-dom";
import { UUID } from 'crypto';

const GuidanceVideos = () => {
    const { state, dispatch } = useContext(SmartContext);

    const [videoLink, setVideoLink] = useState('');
    const [isVideoModalOpen, setVideoModalOpen] = useState(false);
    const playerRef = useRef<any>(null);
    const [player, setPlayer] = useState<any>(null);
    const [guidanceVideos, setGuidanceVideos] = useState<any[]>([]);
    const { id, pageName } = useParams();

    useEffect(() => fetchGuidanceVideos(), [state.internal?.refreshGuidance]);

    useEffect(() => {
        if (window.YT && typeof window.YT.Player !== 'undefined' && videoLink) {
            const videoId = extractVideoId(videoLink);
            playerRef.current = new window.YT.Player('youtube-player', {
                videoId: videoId,
                playerVars: {
                    origin: window.location.origin,
                },
                events: {
                    onReady: onPlayerReady,
                },
            });
        }
    }, [videoLink]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const openVideoModal = (link: string) => {
        setVideoLink(link);
        window.scrollTo(0, 0);
        setVideoModalOpen(true);

        if (playerRef.current && playerRef.current.destroy) {
            playerRef.current.destroy();
        }
    };

    const closeVideoModal = () => {
        setVideoModalOpen(false);

        setTimeout(() => {
            if (playerRef.current && playerRef.current.destroy) {
                playerRef.current.destroy();
                playerRef.current = null;
                setPlayer(null);
            }
        }, 100);
    };

    const onPlayerReady = (event: any) => {
        setPlayer(event.target);
        if (isVideoModalOpen) {
            event.target.playVideo();
        }
    };

    const fetchGuidanceVideos = () => {
        GuidanceService.getById(id as UUID).then((guidanceResponse) => {
            console.log("guidanceResponse?.data?.guidanceVideos",guidanceResponse?.data?.guidanceVideos)
            setGuidanceVideos(guidanceResponse?.data?.guidanceVideos || []);
        });
    };

    function extractVideoId(url: string) {
        if (url === undefined) return '';
        const videoIdMatch = url.match(/(?:youtu.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/);
        if (videoIdMatch) {
            return videoIdMatch[1];
        }
        return '';
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-guidance-videos" role="tabpanel" aria-labelledby="pills-guidance-videos-tab" tabIndex={0}>
                <div className="row g-4">
                    {guidanceVideos.length > 0 ? (
                        guidanceVideos.map((video) => (
                            <div className="col-md-6" key={video.id}>
                                <a
                                    className="video_block video-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#video_pop"
                                    href="javascript:void(0)"
                                >
                                    <span className="video_block_img">
                                        <img
                                            src={`https://img.youtube.com/vi/${extractVideoId(video.video)}/mqdefault.jpg`}
                                            width="100%"
                                            className="img-fluid"
                                            alt={video.title}
                                        />
                                    </span>
                                    <span className="video_block_name">
                                        <label>{video.title}</label>
                                        <span onClick={() => openVideoModal(video.video)} className="icon-invert-play" />
                                    </span>
                                </a>
                            </div>
                        ))
                    ) : (
                        <div className="col-12">
                           <span className="video_block_name">
                           <label>No Guidance Videos Available</label>
                           </span>
                        </div>
                    )}
                </div>
            </div>

            {/* Video Modal */}
            <div
                id="video_pop"
                className={`modal fade ${isVideoModalOpen ? 'show' : ''}`}
                tabIndex={-1}
                role="dialog"
                style={{ display: isVideoModalOpen ? 'block' : 'none' }}
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <button type="button" className="btn-close" onClick={closeVideoModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className="ratio ratio-16x9">
                                <div id="youtube-player"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GuidanceVideos;
