import { axiosClient } from '../../../library/Service/axiosClient';

export const  VerifyAlumni = async (userId:string,status:string,navigate:any) => {
        try {
            const response:any = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/verify-alumni-by-uuid`,{ 
                'alumniId':userId,
                'status':status
            });

            if(response.success == true){
                navigate('/alumni/onboarding')
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
};