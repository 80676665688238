import { useContext, useRef } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, handleControlValueChange } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';

const StarControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey, handleChangeEvent } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl

    return (
        <div>
            <span
                onClick={(event) =>
                    state.actions[control.id]
                        ? state.actions[control.id]({ controlId: control.id, dataKey, parentDataKey, state, dispatch })
                        : handleControlValueChange({ control, value: !data, dataKey, parentDataKey, state, dispatch })
                }>
                <i className={`bi bi-star-fill ${data ? 'text-warning' : ''}`}></i>
            </span>
        </div>
    );
};

export default StarControl;
