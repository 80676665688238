import { useContext, useEffect } from 'react';
import React, { useState } from 'react';
import { Outlet, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import MenuBuilder from '../../../routes/MenuBuilder';
import HeaderControl from '../../alumni/_common/HeaderControl';
import AddAlumniProfile from './AddAlumniProfile';
import AddTopperZone from './AddTopperZone';
import AddNotableProfile from './AddNotableProfile';
import AddSpeakProfile from './AddSpeakProfile';
import AlumniProfileList from './AlumniProfileList';
import AddEclecticProfile from './AddEclecticProfile';
import AddEntrepreneurProfile from './AddEntrepreneurProfile';
import AddSpotlightProfile from './AddSpotlightProfile';

const AddGuidingAlumni = () => {
   
    const { id, code, pageName } = useParams();
    const navigate = useNavigate();
    const location = useLocation();    
    const [widgetHead, setwidgetHead] = useState('Guiding Alumni');
    console.log(code, 'code')
    useEffect(() => {
        
        if ( code == 'notable_alumni_mentors' ) {
            setwidgetHead('Notable Alumni')
            
        } else if ( code == 'alumni-speak' ) {
            setwidgetHead('Alumni Speak')
            
           
        } else if ( code == 'guiding_alumni_mentors' ) {
            setwidgetHead('Guiding Alumni')
            
        } else if ( code == 'eclectic_career_alumni' ) {
            setwidgetHead('Eclectic Career Alumni')
            
        } else if ( code == 'entrepreneur_alumni' ) {
            setwidgetHead('Entrepreneur Alumni')
            
        } else if ( code == 'spotlight' ) {
            setwidgetHead('Alumni in Spotlight')
            
        } else if ( code == 'topper_zone' ) {
            setwidgetHead('Alumni in Topper Zone')
            
        }
        
    }, [code]);
    return (
        <>
        <div className="main flex-1 bg-white">
            <div className="max-1140 mx-auto pt-5">
                <div className="d-flex justify-content-between align-items-center pb-3 ">
                    <h3 className="font-30 font-600 mb-0 block-title position-relative">
                        Manage Widgets For Alumni <i className="icon-right-arrow font-16 position-relative me-1"></i> <span>{widgetHead}</span>
                    </h3>         
                </div>
            </div>

            
            <div className='max-1140 mb-5 overflow-hidden mx-auto'>
                <div className='row g-4'>
                    

                <div className='col-sm-12'>
                    {code === 'notable_alumni_mentors' ? (
                        <AddNotableProfile />
                    ) : code === 'alumni-speak' ? (
                        <AddSpeakProfile />
                    )  : code === 'eclectic_career_alumni' ? (
                        <AddEclecticProfile />
                    )  : code === 'entrepreneur_alumni' ? (
                        <AddEntrepreneurProfile />
                    ) : code === 'spotlight' ? (
                        <AddSpotlightProfile />
                    ) : code === 'topper_zone' ? (
                        <AddTopperZone />
                    ) : (
                        <AddAlumniProfile />
                    )}
                </div>

                    {/* <div className="col-sm-12">
                        <div className="action_btns mt-0 max-100per">
                            <button type="button" className="nextbtn my-2 my-sm-0 mx-2 px-5" data-bs-toggle="modal" data-bs-target="#exampleModal">Preview</button>              
                            <button type="button" className="btn btn-green my-2 my-sm-0 mx-2 height-45 px-5"><span className="px-3">Save </span></button>
                        </div>
                    </div> */}
                </div>

                
            </div>
        </div>
        </>
    );
};

export default AddGuidingAlumni;
