import { convertDomainArrayToMap } from '../../../library/Core/SmartFunctions';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
import DomainDataService from '../../../services/domain-data.service';
import PageConfigService from '../../../services/page-config.service';

class AlbumListingService {
    static filters = {
        userTypeCode: [],
        year: [],
        tags: [],
    };

    static pageLoader(params: any) {
        const state: any = {};
        const { id, pageName } = params;

        return Promise.all([PageConfigService.get(pageName), AlbumListingService.get(), DomainDataService.get()])
            .then((values) => {
                logger.log(`Retrieved values successfully for ${pageName}`);
                state.formConfig = values[0].data;
                state.data = {
                    ...values[1].data,
                    filteredData: values[1].data.albums,
                    filters: AlbumListingService.filters,
                    selectedTab: 'ALL',
                };
                state.domain = values[2].data instanceof Map ? values[2].data : convertDomainArrayToMap(values[2].data);
                state.routeInfo = { id, pageName };
                // storeDomainData(state.domain);
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static async get(type = 'ALL', userId = '') {
        let apiUrl = `${process.env.REACT_APP_MEMORIES_GUIDANCE_SERVER}/memories?searchBy=${type}`
        if (type == "SCHOOL_ADMIN_ALUMNI") {
            apiUrl += `&userId=${userId}`
        }

        return await axiosClient().get(apiUrl);
    }

    static async getById(albumId: string) {
        return axiosClient().get(`${process.env.REACT_APP_MEMORIES_GUIDANCE_SERVER}/memories/${albumId}`);
    }
}

export default AlbumListingService;
