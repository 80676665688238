import { useContext, useEffect, useRef, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { FormSection } from '../../../library/Core/SmartTypes';
import useManageAlumni from '../../broadcast/student/useManageAlumni';
import ManageEventService from './ManageEventService';
import FormBuilder from '../../../library/Builders/FormBuilder';
import FiltersApplied from '../../../library/SimpleControls/FiltersApplied';


const SearchCriteria = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useManageAlumni();
    const searchFormConfig = state.formConfig?.sectionRepository[0] as FormSection;
    const [isExpanded, setIsExpanded] = useState(true);

    const isFirstRender = useRef(true);

    const handleClearAll = () => {
        var defaultSearchCriteria = ManageEventService.getDefaultSearchCriteria();
        defaultSearchCriteria.campusCode = [];
        defaultSearchCriteria.hasReceivedScholarship = [];
        defaultSearchCriteria.hasAppliedUgEntranceTest = [];
        defaultSearchCriteria.hasRepliedToMsg = [];
        defaultSearchCriteria.hasUploadedGuidanceVideos = [];
        defaultSearchCriteria.hasWorkDtlAdded = [];

        var buttons = document.querySelectorAll('.row.g-3 button');
        buttons.forEach(function (button) {
            button.textContent = '';
        });
        setStateValue('searchCriteria', defaultSearchCriteria);
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        ManageEventService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) =>
            setStateValue('leadList', response.data)
        );
    }, [state.data.searchCriteria]);

    return (
        <>
            {/* <div className="fitergroup mb-3">
                <div id="collapseOne" className="accordion-collapse collapse show">
                    <div className="accordion-body p-4">
                        <div className="row g-3">
                            <FormBuilder key="searchCriteria" section={searchFormConfig} dataKey="searchCriteria" />
                        </div>
                     
                    </div>
                </div>
            </div>
            <div className="filterresult">
                <FiltersApplied dataKey="searchCriteria" onClearAll={handleClearAll} />
            </div> */}
        </>
    );
};

export default SearchCriteria;
