import { axiosClient } from '../../library/Service/axiosClient';
class ManageWidgetsListService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};

        return Promise.all([
            this.getWedgitlist(),
        ]).then((values) => {
            state.data = {
                wedgitList: values[0].data ?? [],
            };
           
            return Promise.resolve(state);
            
            
        });
    }

    
    static getWedgitlist() {
     return axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/getwidgetlist`);
    }

}

export default ManageWidgetsListService;