import { useEffect, useState } from 'react';
import AlumniVerifyLayout from './AlumniVerifyLayout';
import { VerifyAlumni } from './AlumniVerifyActions';
import { axiosClient } from '../../../library/Service/axiosClient';
import { useParams } from "react-router-dom";

const AlumniVerify = () => {
    const params = useParams()
    const [alumni, setAlumni] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/get-alumni-by-uuid`,{'alumniId':params.id});
                const data = await response.data; // Extract the data from the response
                if(data.data)
                {
                setAlumni(data.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                alert(error);
            }
        };

        fetchData();
    }, []);

    return (
            <AlumniVerifyLayout alumni={alumni} VerifyAlumni={VerifyAlumni} />
        );
};

export default AlumniVerify;