import { getMenuType, isEmpty } from '../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
import axios from 'axios';

export const handleInstituteSelection = async (
    institute: any,
    state: State,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    const studentId = globalState.context.get('id');
    const response = await axiosClient().post(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/token/${institute.instituteId}`, {});
    const tokenForSelectedInstitute = response?.data?.token;
    // let inputData = {
    //     // programLevel: response?.data?.selectedInstitute?.presentEducationStatusCode == 'PURSUING_STUDIES' ? response?.data?.selectedInstitute?.pursuingEducationLevelCode : response?.data?.selectedInstitute?.highestCompletedEducationLevelCode,
    //     programLevel: response?.data?.selectedInstitute?.programLevel,
    //     userTypeCode: response?.data?.selectedInstitute?.userTypeCode
    // }
    let menuType = getMenuType(response?.data?.selectedInstitute);

    response.data.selectedInstitute.menuType = menuType;
    response.data.selectedInstitute.tabType = menuType;

    sessionDispatch({ type: 'SET', payload: response?.data?.selectedInstitute });
    setSessionStorage(tokenForSelectedInstitute, response?.data?.selectedInstitute);
    // const navigateToUrl = navigateToLandingPage(institute, studentId);
    // state.actions['ROUTER_NAVIGATE'](navigateToUrl);
};

const setSessionStorage = (token: any, selectedInstitute: any) => {
    const user = JSON.parse(sessionStorage.getItem('user') as string);
    sessionStorage.setItem('user-jwt-token', token);
    localStorage.setItem('user-jwt-token', token);
    
    // sessionStorage.setItem('user-selected-institute', JSON.stringify(selectedInstitute));
    sessionStorage.setItem('user', JSON.stringify({ ...user, ...selectedInstitute }));
    localStorage.setItem('user', JSON.stringify({ ...user, ...selectedInstitute, token }));
};

export const navigateToLandingPage = (institute: any, studentId: string) => {
    const { userStatusCode, userTypeCode, instituteTypeCode, profileStatusCode, genderCode, isPeerReviewed, presentEducationStatusCode, pursuingEducationLevelCode, highestCompletedEducationLevelCode } = institute;

    if (userTypeCode === 'ALUMNI' || userTypeCode === 'STUDENT') {
        if (userTypeCode == 'STUDENT') {
            //Student and school wise Product/test menu assign
            getProductsTestMenuStudent(institute.instituteId, institute.pursuingEducationLevelCode);
        }
        if (!isPeerReviewed && userTypeCode === 'ALUMNI' && profileStatusCode == 'COMPLETE') {
            return `/alumni/welcome`;
        } else if (userStatusCode === 'ACTIVE') {
            // if (pursuingEducationLevelCode === 'k12' && genderCode !== null && genderCode !== undefined && genderCode !== "") {
            //     return `/student/${studentId}/profile`;
            // } else {
            //     return `/alumni/${studentId}/profile`;
            // }
            return `/alumni/${studentId}/profile`;

        } else {
            // if (pursuingEducationLevelCode === 'k12') {
            //     return `/student/${studentId}/info`;
            // } else {
            //     return `/alumni/${studentId}/info`;
            // }

            // if ( ! isEmpty(genderCode) ) {
            if ( ! isEmpty(genderCode) && ! isEmpty(presentEducationStatusCode) && ( ! isEmpty(pursuingEducationLevelCode) || ! isEmpty(highestCompletedEducationLevelCode) )) {
                return `/alumni/${studentId}/outcome`;
            } else {
                return `/alumni/${studentId}/info`;
            }
        }
        // if (userStatusCode === 'ACTIVE' || profileStatusCode === 'COMPLETE') {
        //     if (!isPeerReviewed) {
        //         return `/alumni/welcome`;
        //     }
        //     return `/alumni/onboarding`;
        // } else if (userStatusCode === 'PROFILE_IN_PROGRESS' || userStatusCode === 'PENDING_EMAIL_VERIFICATION') {
        //     return `/alumni/${studentId}/info`;
        // }
    } else if (userTypeCode === 'SUPPORT') {
        return `/alumni/directory`;
    } else if (userTypeCode === 'STUDENT' && instituteTypeCode === 'SCHOOL') {
        // return profileStatusCode === 'PREFERENCES_INCOMPLETE' ? `/alumni/${studentId}/student-preference` : `/alumni/${studentId}/profile`;
        return `/alumni/onboarding`;
    } else if (userTypeCode == 'SCHOOL_ADMIN') {
        return `/management/dashboard`;
    }
};


export const getProductsTestMenuStudent = async (school_id: any, class_code: any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_COMMON_API}/test/get-test-menu-by-schoolid_campuscode`, {
            SchoolId: school_id,
            ClassCode: class_code,
        });
        if (response.data.success) {
            sessionStorage.setItem('product-menu-test', JSON.stringify(response.data.data));
        } else {
            console.error('Error:', response.data.message);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};