import { useContext, useEffect, useRef, useState } from 'react';
import { SmartContext } from '../../../../../../library/Core/SmartContext';
import { Modal } from 'bootstrap';
import AddGuidanceVideoModal from './AddGuidanceVideoModal';
import guidanceVideosFormPageConfig from './guidanceVideosFormPageConfig.json';
import { axiosClient } from '../../../../../../library/Service/axiosClient';
import { getinstituteList } from '../../../../../../library/Service/domainService';
import useManagementDashboard from '../../../../../management/dashboard/useManagementDashboard';

const GuidanceVideos = () => {
    const { state, dispatch } = useContext(SmartContext);
    const [isConfigAvailable, setisConfigAvailable] = useState(false);
    const [guidanceVideos, setguidanceVideos] = useState([]);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const { getCampusesUserWise } = useManagementDashboard();


    const [isVideoModalOpen, setVideoModalOpen] = useState(false);
    const [player, setPlayer] = useState<any>(null);
    const playerRef = useRef<any>(null);
    const [videoLink, setVideoLink] = useState('');

    const campuses = getCampusesUserWise(state?.data?.userInstitutes);
    console.log("campuses",campuses.length)
    const [instituteList, setinstituteList] = useState(getinstituteList('INSTITUTE_GROUP_CODE', campuses));

    console.log("instituteList",instituteList)

    function extractVideoId(url: string) {
        if (url === undefined) return '';
        const videoIdMatch = url.match(/(?:youtu.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/);
        if (videoIdMatch) {
            return videoIdMatch[1];
        }
        return '';
    }

    useEffect(() => {
        if (window.YT && typeof window.YT.Player !== 'undefined' && videoLink) {
            const videoId = extractVideoId(videoLink);
            playerRef.current = new window.YT.Player('youtube-player', {
                videoId: videoId,
                playerVars: {
                    origin: window.location.origin,
                },
                events: {
                    onReady: onPlayerReady,
                },
            });
        }
    }, [videoLink]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const onPlayerReady = (event: any) => {
        setPlayer(event.target);
        if (isVideoModalOpen) {
            event.target.playVideo();
        }
    };

    const openVideoModal = (link: string) => {
        setVideoLink(link);
        window.scrollTo(0, 0);
        setVideoModalOpen(true);

        // Destroy existing player
        if (playerRef.current && playerRef.current.destroy) {
            playerRef.current.destroy();
        }
    };

    const closeVideoModal = () => {
        setVideoModalOpen(false);

        // Delay the execution of player destruction
        setTimeout(() => {
            if (playerRef.current && playerRef.current.destroy) {
                playerRef.current.destroy();
                playerRef.current = null;
                setPlayer(null);
            }
        }, 100); // You can adjust the delay as needed
    };

    useEffect(() => {
        axiosClient().get(`${process.env.REACT_APP_MEMORIES_GUIDANCE_SERVER}/guidance/search-by-student/${state?.data?.id}`)
            .then((response) => {
                if(response.status == 200){
                    // console.log(response)
                    setguidanceVideos(response?.data?.guidanceVideos);
                }
            })
            .catch((error) => {
                console.error('Error fetching page data:', error);
            });
    }, [isDataLoaded]);
    
    useEffect(() => {
        Promise.resolve(guidanceVideosFormPageConfig)
            .then((config) => {
                dispatch({
                    type: 'DATA_INIT',
                    payload: {
                        formConfig: config,
                        data: {...state.data,
                         guidanceVideo:{
                            instituteIdlength: campuses.length,
                         }
                        },
                        domain: new Map([...state.domain]),
                        internal: state.internal,
                        routeInfo: state.routeInfo,
                    },
                });

                dispatch({
                    type: 'SET_DOMAIN',
                    payload: { key: 'INSTITUTE_USERWISE_CODE', value: instituteList },
                });

                setisConfigAvailable(true);
            })
            .catch((error) => {
                console.error('Error fetching page config:', error);
            });
    }, []);

    const isPeerReviewed = sessionStorage.getItem('is-peer-reviewed') == 'true' ? true : false;
    const userType = sessionStorage.getItem('user-type-code');
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    const handleOpenModal = async () => {
        // const modalElement = document.getElementById('add-guidance-video-modal');
        // if (modalElement) {
        //     const modal = new Modal(modalElement);
        //     modal.show();
        // }
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showGuidanceVideoAddModal' } });

    };

    return (
        <>
            <div className="underGraduate-detail">
                <div className="blockHeader">                                
                    <div className="row g-2  align-items-center">
                        <div className="col">
                            <div className="title">
                                <i className="icon icon-video-camera"></i> <span>  Guidance Videos L3</span>
                            </div>
                        </div>
                        <div className="col-auto">
                            <a 
                            href="javascript:;" 
                            className="btn-add-section" 
                            onClick={handleOpenModal}>
                                <b className="icon icon-edit me-2"></b> <span>ADD</span>
                            </a>
                        </div>
                    </div>
                </div>
                
                {guidanceVideos.length > 0 && <div className="row g-3">
                    

                    <div className="col-12">
                        <div className="row g-4">
                        {guidanceVideos?.map((video: any) => (
                            <div className="col-md-4 col-sm-6" key={video.id}>
                                <a className="video_block video-btn">
                                    <span className="video_block_img">
                                        <img
                                            src={`https://img.youtube.com/vi/${extractVideoId(video.video)}/mqdefault.jpg`}
                                            className="img-fluid"
                                            width="1000px"
                                        />
                                    </span>
                                    <span className="video_block_name">
                                        <label>{video.title}</label>
                                        <span onClick={() => openVideoModal(video.video)} className="icon-invert-play" />
                                        {/* <button
                                            className="btn-View-appeal mt-3 video-btn"
                                            onClick={() => openVideoModal(video.video)}></button> */}                                                         
                                    </span>
                                </a>  
                            </div>
                        ))}                                                   
                        </div>
                    </div>
                
                    <div
                        id="video_pop"
                        className={`modal fade show vh-100 ${isVideoModalOpen ? 'show' : ''}`}
                        tabIndex={-1}
                        role="dialog"
                        style={{ display: isVideoModalOpen ? 'block' : 'none' }}>
                        <div className="modal-dialog modal-lg " role="document">
                            <div className="modal-content">
                                <div className="modal-body p-0">
                                    <button type="button" className="btn-close" onClick={closeVideoModal}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <div className="ratio ratio-16x9">
                                        <div id="youtube-player"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>}
            </div>
            {isConfigAvailable && <AddGuidanceVideoModal data={{ isDataLoaded,setDataLoaded }} />}
        </>
    );
};

export default GuidanceVideos;
