import { useContext } from 'react';
import SessionContext from '../../../../library/Core/SessionContext';
import BasicInfoVisual from '../../../../styles/images/basic-info-visual.png';

const EntranceExamLayout = (props: any) => {
    const { sessionState } = useContext(SessionContext);

    const SchoolLogo = sessionStorage.getItem('school-image') as string;
    const userStatusCode = sessionState?.userStatusCode;
    const userTypeCode = sessionState?.userTypeCode;

    return (
        <>
             <div className="main-wrap">
                <div className="leftPane">
                    <div className="leftPaneWrap">
                        {userTypeCode != 'SUPER_ADMIN' && userStatusCode != 'ACTIVE' && (
                            <div className="leftPaneHeaderWrap">
                                <div className="leftPaneHeader">
                                    <img src={SchoolLogo} alt="" />
                                    <label className="user_name">
                                        Hey{' '}
                                        <span>{`${sessionState?.firstName} ${sessionState?.lastName}`}</span>
                                    </label>
                                </div>
                            </div>
                        )}
                        <div className="leftPaneBodyWrap">
                            <div className="leftPaneBody">
                                <div className="qs_body aos-init aos-animate" data-aos="fade-right" data-nextpage="undergraduate.html">
                                    <div className="steps_questions">{props.children}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rightPane basic-information">
                    <div className="rightPaneWrap">
                        {userTypeCode != 'SUPER_ADMIN' && userStatusCode != 'ACTIVE' && (
                            <div className="rightPaneHeaderWrap">
                                <div className="rightPaneHeader">
                                    <label className="user_name">
                                        Hey{' '}
                                        <span>{`${sessionState?.firstName} ${sessionState?.lastName}`}</span>
                                    </label>
                                </div>
                            </div>
                        )}
                <div className="rightPaneBodyWrap">
                            <div className="rightPaneBody aos-init aos-animate" data-aos="fade-left">
                                <div className="step step_1">
                                <div className="textDetail">
                                    <h2 className="stepHeader">
                                    Your Entrance Exam Preferences
                                        <br /> Will Enable You to -
                                    </h2>
                                    <div className="listed_points">
                                        <ul>
                                            <li>Get preparation tips from experts and alumni.</li>
                                            <li>Get updated reminders as per the exam calendar.</li>
                                        </ul>
                                    </div>
                                    <div className="step_img">
                                        <img src={BasicInfoVisual} className="img-fluid float_horizontal_animate" width="100%" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                   </div>
                 </div>
            </div>
        </>
    );
};

export default EntranceExamLayout;
