import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import AlumniListGrid from './AlumniListGrid';
import Layout from './Layout';

const ManageAlumni = () => {
    const navigate = useNavigate();
    const { globalState } = useContext(GlobalContext);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;
    
    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        domain.set('INSTITUTE_PROGRAM_CODE', internal?.programDomainData ?? []);

        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, internal, routeInfo },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <DebugBarControl />
            {!state.flags.isDataLoading && (
                <Layout>
                    {/* Please check layout for the <SearchCriteria /> */}
                    <AlumniListGrid />
                </Layout>
            )}
        </SmartContext.Provider>
    );
};

export default ManageAlumni;
