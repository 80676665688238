import { axiosClient } from '../../../library/Service/axiosClient';

class ContactLisingService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};

        return Promise.all([
            this.search(params.id),
        ]).then((values) => {
            state.data = {
                contactList: values[0].data.data ?? [],
                id : params.id,
                groupId : params.groupId 
            };
            return Promise.resolve(state);
        });
    }
    
    static search(id:any) {
        return axiosClient().get(`${process.env.REACT_APP_ADMIN_API_SERVER}/institute/${id}/contacts`);
    }
}

export default ContactLisingService;
