import { useContext } from 'react';
import { SmartContext } from '../../library/Core/SmartContext';
import { isEmpty } from '../../library/Core/SmartFunctions';
import MessageList from './MessageList';
import MessageListActionBar from './MessageListActionBar';
import MessageView from './MessageView';
import NewMessage from './NewMessage';

const MessagesListContainer = () => {
    const { state, dispatch } = useContext(SmartContext);
    const selectedConversation = state.data?.selectedConversation;

    return (
        <div className="containerinnercover">
            <div className="polarcontsec msgtabcont msgwrapext m-0 w-100" data-aos="fade-left" data-aos-delay="900">
                {!state.data?.newMessageMode && (
                    <>
                        <MessageListActionBar />
                        <MessageList />
                        {!isEmpty(selectedConversation) && <MessageView />}
                    </>
                )}
                {state.data?.newMessageMode && <NewMessage />}
            </div>
        </div>
    );
};

export default MessagesListContainer;
