import { isEmpty } from '../../../library/Core/SmartFunctions';
import { ObjectWithKeys } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';

class EventsService {
    static pageLoader = async (params: any) => {
        const state: any = {};
        const { searchBy, pageName } = params;
        const filters = await EventsService.getDefaultFilters();
        const filterDomain = await EventsService.getDefaultFilterDomain(searchBy);

        state.formConfig = {};
        state.data = {
            // Other could be 'FETCH_EVENTS'
            actionType: 'LOAD_PAGE',
            events: [],
            counts: {},
            searchBy,
            prevSearchBy: searchBy,
            filters,
            filterDomain,
        };

        state.routeInfo = { searchBy, pageName };
        return Promise.resolve(state);
    };

    static async getFilterDomain(searchBy: string, filterName: any) {
        const config = { headers: { 'Referrer-Policy': 'no-referrer' } };
        return await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/event`, this.getParamsForFilter(searchBy, filterName), config);
    }

    static getAdditionalInfo(searchBy: string) {
        return {
            login_ref_id: sessionStorage.getItem('user-login_ref_id'),
            school_ref_id: 34692,
            upcoming_past: searchBy === 'upcoming' ? '1' : '2',
            req_from: 'alumni',
        };
    }

    static getParamsForFilter(searchBy: string, filterName: string) {
        return {
            name: 'filter_list_events',
            param: {
                req_from: EventsService.getRequestFrom(),
                school_ref_id: 34692,
                login_ref_id: '', // '448220', // sessionStorage.getItem('user-login_ref_id'),
                upcoming_past: searchBy === 'upcoming' ? '1' : '2',
                filtername: EventsService.filterConfig[filterName]?.filterName,
            },
        };
    }

    static getRequestFrom = () => {
        let requestFrom = '';
        switch (sessionStorage.getItem('user-type-code')) {
            case 'ALUMNI':
                requestFrom = 'alumni';
                break;
            case 'STUDENT':
                requestFrom = 'student';
                break;
            case 'SCHOOL':
            case 'SCHOOL_ADMIN':
                requestFrom = 'schooldashboard';
                break;
            default:
                requestFrom = 'schooldashboard';
        }
        return requestFrom;
    };

    static getDefaultFilters = () => {
        return {
            eve_types: [],
            eve_presenter: [],
            eve_themes: [],
            eve_test: [],
            eve_skill: [],
            eve_country: [],
            eve_university: [],
            eve_career: [],
            eve_department: [],
            eve_pdt: [],
            eve_inds: [],
        };
    };

    static async getDefaultFilterDomain(searchBy: string) {
        const domains = [
            'type',
            'theme',
            'presenter',
            'skill',
            'university',
            'test',
            'country',
            'career',
            'department',
            'product',
            'industry',
        ];

        const savedFilterDomains = sessionStorage.getItem(`${searchBy}.filterDomains`);

        //to : need to check if array does not have any element
        // if (!isEmpty(savedFilterDomains)) {
        //     return JSON.parse(savedFilterDomains as any);
        // }

        const values = await Promise.all(domains.map((domain) => EventsService.getFilterDomain(searchBy, domain)));

        const filterDomains = domains.reduce((acc, domain, index) => {
            acc[domain] = values[index].data?.response?.result;
            return acc;
        }, {} as Record<string, any>);

        sessionStorage.setItem(`${searchBy}.filterDomains`, JSON.stringify(filterDomains));

        return filterDomains;
    }

    static filterConfig: ObjectWithKeys = {
        type: {
            id: 'type',
            filterName: 'get_events_by_type',
            label: 'Event Type',
            code: 'event_type_id',
            displayText: 'event_type_name',
            searchParam: 'eve_types',
        },
        theme: {
            id: 'theme',
            filterName: 'get_events_by_theme',
            label: 'Theme / Topic',
            code: 'event_theme_id',
            displayText: 'event_theme_name',
            searchParam: 'eve_themes',
        },
        presenter: {
            id: 'presenter',
            filterName: 'get_events_by_presenter',
            label: 'Presenter Category',
            code: 'presenter_category_id',
            displayText: 'presenter_name',
            searchParam: 'eve_presenter',
        },
        skill: {
            id: 'skill',
            filterName: 'get_tag_skill',
            label: 'Select Skill',
            code: 'skill_id',
            displayText: 'skill_name',
            searchParam: 'eve_skill',
        },
        university: {
            id: 'university',
            filterName: 'get_tag_university',
            label: 'Select University',
            code: 'univ_id',
            displayText: 'univ_name',
            searchParam: 'eve_university',
        },
        test: {
            id: 'test',
            filterName: 'get_tag_test',
            label: 'Select Test',
            code: 'test_id',
            displayText: 'test_name',
            searchParam: 'eve_test',
        },
        country: {
            id: 'country',
            filterName: 'get_tag_country',
            label: 'Select Country',
            code: 'country_id',
            displayText: 'country_name',
            searchParam: 'eve_country',
        },
        career: {
            id: 'career',
            filterName: 'get_tag_career',
            label: 'Select Career',
            code: 'career_id',
            displayText: 'career_name',
            searchParam: 'eve_career',
        },
        department: {
            id: 'department',
            filterName: 'get_tag_department',
            label: 'Select Department',
            code: 'department_id',
            displayText: 'department_name',
            searchParam: 'eve_department',
        },
        industry: {
            id: 'industry',
            filterName: 'get_tag_industry',
            label: 'Select Industry',
            code: 'industry_id',
            displayText: 'industry_name',
            searchParam: 'eve_inds',
        },
        product: {
            id: 'product',
            filterName: 'get_tag_product',
            label: 'Select Product',
            code: 'product_id',
            displayText: 'product_name',
            searchParam: 'eve_pdt',
        },
    };
}

export default EventsService;
