import BasicInfoVisual from '../../../../styles/images/basic-info-visual.png';
let SchoolLogo = sessionStorage.getItem('school-image') as any;

const StudentActivityLayout = (props: any) => {
    return (
        <>
            <div className="main-wrap">
                <div className="leftPane">
                    <div className="leftPaneWrap">
                        <div className="leftPaneBodyWrap">
                            <div className="leftPaneBody">
                                <div className="qs_body aos-init aos-animate" data-aos="fade-right" data-nextpage="undergraduate.html">
                                    <div className="steps_questions">{props.children}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rightPane basic-information">
                    <div className="rightPaneWrap">
                        <div className="rightPaneBodyWrap">
                            <div className="rightPaneBody aos-init aos-animate" data-aos="fade-left">
                                <div className="step step_1">
                                    <h2 className="stepHeader">
                                    Showcasing Your Participation In
                                        <br /> Extra-Curricular Activities -
                                    </h2>
                                    <div className="listed_points">
                                        <ul>
                                            <li>Tells about your unique personality and skill set.</li>
                                            <li>Helps build a profile for your college admissions and career growth.</li>
                                        </ul>
                                    </div>
                                    <div className="step_img">
                                        <img src={BasicInfoVisual} className="img-fluid float_horizontal_animate" width="100%" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StudentActivityLayout;
