import { useContext } from 'react';
import { GlobalContext } from '../Core/GlobalContext';
import { SmartContext } from '../Core/SmartContext';
//import { useLocation } from 'react-router-dom';
import SessionContext from '../Core/SessionContext';
import { getDomainValueForCode } from '../Core/SmartFunctions';
const ButtonPalletControl = (props: any) => {
    const { globalState, globalDispatch } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const { state, dispatch } = useContext(SmartContext);
    //const location = useLocation();
    // const isPgPage = location.pathname.includes('pg') ? true : false;

    const currentStatus = getDomainValueForCode(
        state?.data?.profile?.educationOrEmploymentCurrentStatusCode,
        'EDUCATIONAL_OR_EMPLOYMENT_STATUS_CODE',
        state
    );
    const isUserActive = sessionState?.userStatusCode === 'ACTIVE' ? true : false;
    const actions = props.actions;

    const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>, button: string) => {
        // if (button.trim().toLowerCase() === 'back') return navigate(-1);
        return state.actions[`${button.trim().replace(/[' ]/g, '_').toUpperCase()}`](
            event,
            state,
            dispatch,
            globalState,
            globalDispatch,
            sessionState,
            sessionDispatch
        );
    };

    const handleLaterPalletClick = (button: string) => {
        return state.actions['I_LL_DO_THIS_LATER'](state, dispatch, globalState, globalDispatch);
    };

    return (
        actions !== undefined &&
        actions.split(',').map((button: string) => {
            const buttonTrimmed = button.trim().toUpperCase();

            if (buttonTrimmed === `I'LL DO THIS LATER`) {
                if (isUserActive) {
                    return null;
                } else if (
                    (state?.routeInfo?.pageName === 'alumni-postgraduate' && currentStatus === 'Working') ||
                    (state?.routeInfo?.pageName === 'alumni-professional-work' &&
                        currentStatus != 'Working' &&
                        sessionState?.userStatusCode === 'ACTIVE')
                ) {
                    // if page is pg then we only need to show button when user current status is working
                    return (
                        <a key={button} className="btn-skip" role="button" onClick={() => handleLaterPalletClick(button)}>
                            I'LL DO THIS LATER
                        </a>
                    );
                } else if (
                    state?.routeInfo?.pageName != 'alumni-postgraduate' &&
                    state?.routeInfo?.pageName != 'alumni-professional-work' &&
                    sessionState?.userStatusCode === 'ACTIVE'
                ) {
                    return (
                        <a key={button} className="btn-skip" role="button" onClick={() => handleLaterPalletClick(button)}>
                            I'LL DO THIS LATER
                        </a>
                    );
                }
            } else {
                const buttonText = isUserActive && button.trim().toUpperCase() === 'NEXT' ? 'SAVE' : button;
                let btnClass = 'btn btn-login nextbtn text-white col-md-3 m-2 w-auto btn-sussess';
                switch (button.trim().toUpperCase()) {
                    case 'SAVE':
                        btnClass = 'btn btn-green text-white col-md-3 m-2 w-auto btn-sussess px-5 height-45';
                        break;

                    case 'RESET':
                        btnClass = 'btn btn-border-gray col-md-3 m-2 w-auto text-grey height-45  px-5 font-500'
                        break;
                }
                return (
                    <button
                        key={button}
                        // className="btn btn-login nextbtn text-white col-md-3 m-2 w-auto btn-sussess"
                        className={btnClass}
                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', display: 'inline-block' }}
                        onClick={(event) => handleClick(event, button)}>
                        <span>{buttonText}</span>
                    </button>
                );
            }
        })
    );
};

export default ButtonPalletControl;
