import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import Layout from './Layout';
import ProfileMarkerNotesService from './ProfileMarkerNotesService';

const ProfileMarkerNotes = () => {
    const navigate = useNavigate();
    const { globalState } = useContext(GlobalContext);
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ProfileMarkerNotesService.getaddedByDropdown();
                const addedBy = response.data;  
                const domain = state.domain || new Map();  // Use a default empty Map if state.domain is undefined
                const domainMap = new Map([...domain])             
                console.log(addedBy);
                domainMap.set('NOTES_ADDED_BY', addedBy);  
    
                dispatch({
                    type: 'DATA_INIT',
                    payload: { 
                        formConfig, 
                        data, 
                        domain: domainMap, 
                        internal, 
                        routeInfo 
                    },
                });
            } catch (error) {
                console.error("Error fetching addedBy data:", error);
            }
        };
    
        fetchData(); 
    }, []);
    
    return (
        <SmartContext.Provider value={{ state, dispatch }}>
        <DebugBarControl />
        {!state.flags.isDataLoading && <Layout />}
         </SmartContext.Provider>
    );
};

export default ProfileMarkerNotes;
