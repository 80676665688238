import { activateUserSession, isEmpty, isFormValid, setError } from '../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
import StudentService from '../../../../services/student.service';
import axios from 'axios';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const isFormValidated = isFormValid(state, dispatch, ['jobCompanyPreferences', 'jobLocationPreferences', 'jobIndustryPreferences']);
    const isCustomValidationPassed = !isFormInvalidBasedOnCustomValidation(state, dispatch);

    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    if (
        !isImpersonatedSession &&
        state.data.jobPreferenceInfo.interestedInJobReferrals &&
        (!isFormValidated || !isCustomValidationPassed)
    ) {
        alert('Please correct the form errors!');
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobPreferenceInfo: state?.data['jobPreferenceInfo'],
            jobCompanyPreferences: state?.data['jobCompanyPreferences'],
            jobLocationPreferences: state?.data['jobLocationPreferences'],
            jobIndustryPreferences: state?.data['jobIndustryPreferences'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            // Send event to Moengage Job Preferences
            sendMoEngageEvent(state?.data);

            // Call the API here to update the status as ACTIVE & COMPLETE.
            // activateUserSession(sessionState, sessionDispatch).then(() => {
            //     state.actions['REDIRECT'](`/alumni/${state.routeInfo.id}/profile`);
            // });
            navigateToNextStep(AlumniProfileEditScreens.JOB_PREFERENCE, state, dispatch, sessionState, sessionDispatch);
        }
    });
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.JOB_PREFERENCE, state, dispatch);
};

const isFormInvalidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    // At least one company entered
    const atLeastOneCompanyEntered = state?.data['jobCompanyPreferences'].some(
        (jobCompanyPreference: any) => !isEmpty(jobCompanyPreference.companyId) || isEmpty(jobCompanyPreference.otherCompany)
    );
    if (!atLeastOneCompanyEntered) setError('jobCompanyPreferences', ['Please enter at least one dream company'], dispatch);

    // No duplicate company
    const jobCompanyArray = state?.data['jobCompanyPreferences'].map((jobCompanyPreference: any) => jobCompanyPreference.companyId);
    const hasDuplicateCompany = jobCompanyArray.some(
        (companyId: number, idx: number) => companyId && jobCompanyArray.indexOf(companyId) !== idx
    );
    if (hasDuplicateCompany)
        setError('jobCompanyPreferences', ['Duplicate Company: Company already exists. Please enter unique companies.'], dispatch);

    if (atLeastOneCompanyEntered && !hasDuplicateCompany) setError('jobCompanyPreferences', [], dispatch);

    // At least one location entered
    const atLeastOneLocationEntered = state?.data['jobLocationPreferences'].some(
        (jobLocationPreference: any) =>
            !isEmpty(jobLocationPreference.countryCode) &&
            !isEmpty(jobLocationPreference.cityCode) &&
            !isEmpty(jobLocationPreference.preferenceCode)
    );
    if (!atLeastOneLocationEntered) setError('jobLocationPreferences', ['Please enter at least one location'], dispatch);

    // No duplicate location
    const jobLocationArray = state?.data['jobLocationPreferences'].map((jobLocationPreference: any) => jobLocationPreference.cityCode);
    const hasDuplicateLocation = jobLocationArray.some(
        (cityCode: number, idx: number) => cityCode && jobLocationArray.indexOf(cityCode) !== idx
    );
    if (hasDuplicateLocation)
        setError('jobLocationPreferences', ['Duplicate City: City already exists. Please enter unique cities.'], dispatch);

    if (atLeastOneLocationEntered && !hasDuplicateLocation) setError('jobLocationPreferences', [], dispatch);

    // At least one Industry is entered
    const atLeastOneIndustryEntered = state?.data['jobIndustryPreferences'].some(
        (jobIndustryPreference: any) => !isEmpty(jobIndustryPreference.industryCode)
    );
    if (!atLeastOneIndustryEntered) setError('jobIndustryPreferences', ['Please enter at least one industry'], dispatch);
    if (atLeastOneIndustryEntered) setError('jobIndustryPreferences', [], dispatch);

    //
    const formHasError = !atLeastOneCompanyEntered || hasDuplicateCompany || !atLeastOneLocationEntered || hasDuplicateLocation;

    return formHasError;
};

const sendMoEngageEvent = async (user: any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-job-preference`, {
            userInfo: user,
        });
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return false;
};
