import moment from 'moment';
import { useContext } from 'react';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import SessionContext from '../../../library/Core/SessionContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import { axiosClient } from '../../../library/Service/axiosClient';

const useManagementDashboard = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { globalState } = useContext(GlobalContext);
    const { sessionState } = useContext(SessionContext);

    const getCountryReportData = async () => {
        let response: any;
        const payload = { userTypeCode: state.data.selectedTab, ...state.data.searchCriteria?.studentCountry };
        response = await axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/dashboard/country`, payload);
        setStateValue('reportData.studentCountry', response.data);
    };

    const getAlumniCountryReportData = async () => {
        let response: any;
        const payload = { ...state.data.searchCriteria?.alumniCountry };
        response = await axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/dashboard/alumni-country-distribution`, payload);
        setStateValue('reportData.alumniCountry', response.data);
    };

    const getStudentCareerReportData = async () => {
        let response: any;
        const payload = { userTypeCode: state.data.selectedTab, ...state.data.searchCriteria?.studentCareer };
        response = await axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/dashboard/student-career`, payload);
        setStateValue('reportData.studentCareer', response.data);
    };

    const getAlumniPresence = async () => {
        let response: any;
        const payload = { ...state.data.searchCriteria?.alumniPresence };
        response = await axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/dashboard/alumni-presence`, payload);
        setStateValue('reportData.alumniPresence', response.data);
    };

    const getAlumniPresentStatus = async () => {
        let response: any;
        const payload = { ...state.data.searchCriteria?.alumniPresentStatus };
        response = await axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/dashboard/alumni-present-status`, payload);
        setStateValue('reportData.alumniPresentStatus', response.data);
    };

    const getAlumniOnboarding = async () => {
        let response: any;
        const { fromDate, toDate } = getFromAndToDates(state.data.searchCriteria?.alumniOnboarding);
        const payload = { ...state.data.searchCriteria?.alumniOnboarding, fromDate, toDate };
        response = await axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/dashboard/alumni-onboarding`, payload);
        setStateValue('reportData.alumniOnboarding', response.data);
    };

    const getStudentOnboarding = async () => {
        let response: any;
        const { fromDate, toDate } = getFromAndToDates(state.data.searchCriteria?.studentOnboarding);
        const payload = { ...state.data.searchCriteria?.studentOnboarding, fromDate, toDate };
        response = await axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/dashboard/student-onboarding`, payload);
        setStateValue('reportData.studentOnboarding', response.data);
    };

    const setStateValue = (dataKey: string, value: string) =>
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey, value, errorMessages: [] } });

    // const getCampuses = () => {
    //     const response =
    //         sessionState?.institutes.map((item: any) => ({ label: item, value: getDomainValueForCode(item, 'INSTITUTE_DOMAIN', state) })) ??
    //         [];
    //     return response;
    // };

    const getCampuses = () => {
        const institutesCodes = sessionState?.institutes ?? []; // Get the codes from sessionState?.institutes
            const campusesFromDomain = institutesCodes.flatMap((code: string)  => {
            const campus = globalState.domain
                .get('INSTITUTE_DOMAIN')
                ?.find((item: any) => item.code == code);
    
            if (campus) {
                const flexCol = JSON.parse(campus.flexCol || "{}"); 
                if (flexCol.is_published == 1) {
                    return [{ label: campus.value, value: campus.code }];
                }
            }
            return [];
        });
    
        return campusesFromDomain;
    };


    const getFromAndToDates = (searchCriteria: any) => {
        const { dateRange, fromDate, toDate } = searchCriteria;

        if (dateRange === 'CUSTOM') {
            return {
                fromDate: moment(fromDate).format('YYYY-MM-DD'),
                toDate: moment(toDate).format('YYYY-MM-DD'),
            };
        }

        const today = moment();
        let calculatedFromDate;

        switch (dateRange) {
            case '30D':
                calculatedFromDate = today.clone().subtract(30, 'days');
                break;
            case '90D':
                calculatedFromDate = today.clone().subtract(90, 'days');
                break;
            case '180D':
                calculatedFromDate = today.clone().subtract(180, 'days');
                break;
            case '365D':
                calculatedFromDate = today.clone().subtract(365, 'days');
                break;
            default:
                return { fromDate: null, toDate: null };
        }

        return {
            fromDate: calculatedFromDate.format('YYYY-MM-DD'),
            toDate: today.format('YYYY-MM-DD'),
        };
    };

    const getInstitutes = (schoolGroupId: any) => {
        const response =
        globalState.domain
            .get('INSTITUTE_DOMAIN')
            ?.filter((item: any) => {
                const flexCol = JSON.parse(item.flexCol); // Parse the JSON string
                return item.parentCode === schoolGroupId && flexCol.is_published == "1";
            })
            .map((item: any) => ({ label: item.value, value: item.code })) ?? [];
         return response;
    };

    const getprogramLevels = (sessionState:any) => {
        console.log("sessionStateDashbaordfile",sessionState)
        const pursuingEducationLevels = ['UG', 'PG', 'Doctoral'];

        const pursuingEducationLevelCode = sessionState.pursuingEducationLevelCode && !pursuingEducationLevels.includes(sessionState.pursuingEducationLevelCode)
        ? sessionState.pursuingEducationLevelCode
        : 13;

        const response = Array.from({ length: pursuingEducationLevelCode }, (_, index) => index + 1).map(number => ({
            label: number.toString(),
            value: number
        }));
        return response;
    };

    const getCampusesUserWise = (institutesCodes:any) => {
        const campusesFromDomain = institutesCodes.flatMap((institute:any)  => {
            const campus = globalState.domain
                .get('INSTITUTE_DOMAIN')
                ?.find((item) => item.code == institute.instituteId);
    
            if (campus) {
                const flexCol = JSON.parse(campus.flexCol || "{}"); 
                if (flexCol.is_published == 1) {
                    return [{ label: campus.value, value: campus.code }];
                }
            }
            return [];
        });
    
        return campusesFromDomain;
    };


    return {
        getStudentCareerReportData,
        getStudentOnboarding,
        getAlumniOnboarding,
        getAlumniPresence,
        getAlumniPresentStatus,
        getAlumniCountryReportData,
        getCampuses,
        setStateValue,
        getCountryReportData,
        getInstitutes,
        getprogramLevels,
        getCampusesUserWise
    };
};

export default useManagementDashboard;
