import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import SessionContext from '../../../library/Core/SessionContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { handleInstituteSelection, navigateToLandingPage } from './InstituteSectionActions';
import InstituteSelectionLayout from './InstituteSelectionLayout';

const InstituteSelection = ({pageTitle}:any) => {
    document.title=pageTitle;
    const { globalState, globalDispatch } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const navigate = useNavigate();
    const [shouldNavigate, setShouldNavigate] = useState(false);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            ROUTER_NAVIGATE: navigate,
        },
    } as any);

    useEffect(() => {
        if (shouldNavigate) {
            const institute = sessionState;
            if (institute && Object.keys(institute).length > 1) {
                const navigateToUrl: string = navigateToLandingPage(institute, institute.studentId) as string;
                navigate(navigateToUrl);
                setShouldNavigate(false);
            }
        }
    }, [shouldNavigate, navigate, sessionState]);

    const handleSelection = (institute: any) => {
        handleInstituteSelection(institute, state, globalState, globalDispatch, sessionState, sessionDispatch).then(() => {
            setShouldNavigate(true); 
        });
    };

    useEffect(() => {
        if (sessionState?.institutes.length === 1) {  //For only one Institute assign
            handleSelection(sessionState.institutes[0]);
            setShouldNavigate(false); 
        }
    }, []);
    
    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <InstituteSelectionLayout handleSelection={handleSelection} institutesLength={sessionState?.institutes.length} >
            </InstituteSelectionLayout>
        </SmartContext.Provider>
    );
};

export default InstituteSelection;
