import React, { useState, useRef, useEffect } from 'react';
import MentionDropdown from './MentionDropdown';
import Select, { MultiValue } from 'react-select';
import axios from 'axios';

const HTMLTemplate = () => {
    const [htmlContent, setHtmlContent] = useState<string>(''); // Raw HTML content
    const [isCodeView, setIsCodeView] = useState<boolean>(false); // Toggle between code view and preview
    const [selectedCategories, setSelectedCategories] = useState<MultiValue<{ value: string; label: string }>>([]);
    const [availableCategories, setAvailableCategories] = useState([
        { value: 'Donation', label: 'Donation' },
        { value: 'Campus Recruitment', label: 'Campus Recruitment' },
        { value: 'General Updates', label: 'General Updates' },
        { value: 'Recognition', label: 'Recognition' },
        { value: 'Networking', label: 'Networking' },
        { value: 'Emotional Connect', label: 'Emotional Connect' },
        { value: 'Recreation & Events', label: 'Recreation & Events' },
        { value: 'Learning & Development', label: 'Learning & Development' },
        { value: 'Humor', label: 'Humor' },
        { value: 'Festival Wishes', label: 'Festival Wishes' },
    ]);
    const [isColorPickerVisible, setIsColorPickerVisible] = useState(false); // State to manage color picker visibility

    const handleColorChange = (color: string) => {
        document.execCommand('foreColor', false, color); // Apply the color change
        setIsColorPickerVisible(false); // Hide the color picker after selection
    };
    const contentRef = useRef<HTMLDivElement>(null);
    const tempContent = useRef<string>(''); // Temporary storage for live updates
    const mentionRef = useRef<Range | null>(null); // Save the range for dropdown insertion
    const [dropdownPosition, setDropdownPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    // Mock sender, subject, selectedCategory, and sesinstituteId for the demo
    const [sender, setSender] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<string>('1');
    const sesinstituteId = 12345;
    const schoolLogo = '';
    const templateId = new URLSearchParams(window.location.search).get('id'); // Get the template ID from URL
    let temp_id = 5;
    const currentDate = new Date();
   
    const dateOptions: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString(undefined, dateOptions);

    const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
    const formattedTime = currentDate.toLocaleTimeString(undefined, timeOptions);

    const date = `${formattedDate} ${formattedTime}`;
    const deleteTemplate = async (templateId: string | null) => {
        if (!templateId) {
            alert('No template ID found. Redirecting to broadcast...');
            window.location.href = `/broadcast`; // Redirect if template ID is not found
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email-action/delete-draft`, {
                id: templateId,
            });

            if (response.data.success) {
                alert('Template deleted successfully');
                // Redirect to broadcast page after deletion
                //  window.location.href = `/broadcast`;
            } else {
                alert('Failed to delete the template. Please try again.');
            }
        } catch (error) {
            console.error('Error deleting template:', error);
            alert('An error occurred while deleting the template.');
        }
    };

    const fetchTemplateData = async () => {
        try {
            if (!templateId) return; // Exit if no template ID is provided

            const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/get-template`, {
                id: templateId,
            });

            const data = response.data;
            setSubject(data.subject || '');
            setSender(data.sender || '');
            setHtmlContent(data.message || '');

            // Parse email_tag and set categories
            const tags = data.email_tag ? JSON.parse(data.email_tag) : [];
            const formattedTags = tags.map((tag: string) => {
                return availableCategories.find((option) => option.value === tag) || { value: tag, label: tag };
            });

            setSelectedCategories(formattedTags);

            // Filter available categories
            const filteredOptions = availableCategories.filter((option) => !tags.includes(option.value));
            setAvailableCategories(filteredOptions);
        } catch (error) {
            console.error('Error fetching template data:', error);
        }
    };

    useEffect(() => {
        fetchTemplateData();
    }, []);

    const saveOrUpdateTemplate = async () => {
        try {
            if (!subject.trim() || !htmlContent.trim() || !sender.trim()) {
                alert('Sender, Subject, or Message is missing!');
                return;
            }

            const emailTags = selectedCategories.map((category) => category.value);
           
            const jwtToken = localStorage.getItem('user-jwt-token');

            if (templateId) {
                // Update existing template
                const response = await axios.post(
                    `${process.env.REACT_APP_BROADCAST_SERVER}/email/update-template`,
                    {
                        subject,
                        sender_mail: sender,
                        message: htmlContent,
                        created_date: date,
                        attached_file_path: '', // Add attachment logic if needed
                        school_ref_id: sesinstituteId,
                        teplateid: templateId,
                        receiver_type: selectedCategory, // Include Audience Category
                        email_tag: emailTags, // Pass email_tag array
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );
                console.log('Template updated successfully:', response.data);
                
            } else {
                // Save new template
                const response = await axios.post(
                    `${process.env.REACT_APP_BROADCAST_SERVER}/email/save-template`,
                    {
                        subject,
                        message: htmlContent,
                        attached_file_path: '', // Add attachment logic if needed
                        school_ref_id: sesinstituteId,
                        status: 0,
                        created_date: date,
                        sender_mail: sender,
                        university_logo: schoolLogo,
                        receiver_type: selectedCategory, // Include Audience Category
                        email_tag: emailTags, // Pass email_tag array
                        template_ref_id:5
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );
                console.log('Template saved successfully:', response.data);
               
            }
        } catch (error) {
            console.error('Error saving or updating template:', error);
            alert('Failed to save or update the template. Please try again.');
        }
        window.location.href = `/broadcast`;
    };
    const handleSaveAndNext = async () => {
        let insertID;
        if (!subject.trim() || !htmlContent.trim() || !sender.trim()) {
            alert('Sender, Subject, or Message is missing!');
            return;
        }

        const emailTags = selectedCategories.map((category) => category.value);
       
        
        const jwtToken = localStorage.getItem('user-jwt-token');
        const receiverType = selectedCategory; // Audience Category (receiver_type)

        try {
            let response;

            if (templateId) {
                // Update existing template
                response = await axios.post(
                    `${process.env.REACT_APP_BROADCAST_SERVER}/email/update-template`,
                    {
                        subject,
                        sender_mail: sender,
                        message: htmlContent,
                        created_date: date,
                        attached_file_path: '', // Add attachment logic if needed
                        school_ref_id: sesinstituteId,
                        teplateid: templateId,
                        receiver_type: receiverType, // Include Audience Category
                        email_tag: emailTags, // Pass email_tag array
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );
            
            } else {
                // Save new template
                response = await axios.post(
                    `${process.env.REACT_APP_BROADCAST_SERVER}/email/save-template`,
                    {
                        subject,
                        message: htmlContent,
                        attached_file_path: '',
                        status: 0,
                        created_date: date,
                        sender_mail: sender,
                        university_logo: schoolLogo,
                        receiver_type: receiverType, // Include Audience Category
                        email_tag: emailTags, // Pass email_tag array
                        template_ref_id:5
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );
                console.log('Template saved successfully:', response.data);
                insertID = response.data['insertedId'];
            }
            let paramvalue;
            if (templateId) {
                paramvalue = templateId;
            } else {
                paramvalue = insertID;
            }
            // Redirect based on receiver_type
            if (receiverType === '1') {
                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/update-receiver-type`, {
                    receiver_type: 1,
                    teplateid: paramvalue,
                });
                window.location.href = `/broadcast/student-profiles?template_id=${paramvalue}&temp_ref_id=${temp_id}`;
            } else if (receiverType === '2') {
                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/update-receiver-type`, {
                    receiver_type: 2,
                    teplateid: paramvalue,
                });
                window.location.href = `/broadcast/alumni-profiles?template_id=${paramvalue}&temp_ref_id=${temp_id}`;
            } else {
                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/update-receiver-type`, {
                    receiver_type: 3,
                    teplateid: paramvalue,
                });
                window.location.href = `/broadcast/lead-profiles?template_id=${paramvalue}&temp_ref_id=${temp_id}`;
            }
        } catch (error) {
            console.error('Error saving or updating template:', error);
            alert('Failed to save or update the template. Please try again.');
        }
    };

    const toggleView = () => {
        if (!isCodeView && contentRef.current) {
            setHtmlContent(contentRef.current.innerHTML);
        }
        setIsCodeView(!isCodeView);
    };

    const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
        if (contentRef.current) {
            tempContent.current = contentRef.current.innerHTML;

            const selection = window.getSelection();
            const range = selection?.getRangeAt(0);
            if (range) {
                mentionRef.current = range.cloneRange();
                const caretPosition = range.getBoundingClientRect();
                const textBeforeCaret = range.startContainer.textContent?.substring(0, range.startOffset);

                if (textBeforeCaret?.endsWith('@')) {
                    setShowDropdown(true);
                    setDropdownPosition({
                        top: caretPosition.bottom + window.scrollY,
                        left: caretPosition.left + window.scrollX,
                    });
                } else {
                    setShowDropdown(false);
                }
            }
        }
    };

    const handleBlur = () => {
        if (tempContent.current.trim() !== '') {
            setHtmlContent(tempContent.current);
        }
        setShowDropdown(false);
    };

    const handleSelectMention = (mention: string) => {
        if (contentRef.current && mentionRef.current) {
            const range = mentionRef.current;
            range.deleteContents();
            range.insertNode(document.createTextNode(`${mention} `));
            range.collapse(false);

            const selection = window.getSelection();
            selection?.removeAllRanges();
            selection?.addRange(range);
        }

        setShowDropdown(false);
        mentionRef.current = null;
    };

    return (
        <div className="card m-4">
            <div className="card-header">
                <h3 className="my-2">Import HTML Template</h3>
            </div>

            <div className="card-body bg-gray-f2">
                <div className="row g-3">
                    <div className="col-md-4 col-sm-6">
                        <label htmlFor="sender" className="form-label">
                            Name of Sender
                        </label>
                        <input type="text" className="form-control" value={sender} onChange={(e) => setSender(e.target.value)} />
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <label htmlFor="subject" className="form-label">
                            Title of the Broadcast
                        </label>
                        <input type="text" className="form-control" value={subject} onChange={(e) => setSubject(e.target.value)} />
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div className="row g-3 ">
                    <div className="col-12">
                        <div className="border p-2">
                            <div className="row g3 edit-controls align-items-center">
                                <div className="col-auto">
                                    <button onClick={() => document.execCommand('bold', false)}>
                                        <i className="bi bi-type-bold"></i>
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button onClick={() => document.execCommand('italic', false)}>
                                        <i className="bi bi-type-italic"></i>
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button onClick={() => document.execCommand('underline', false)}>
                                        <i className="bi bi-type-underline"></i>
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button onClick={() => document.execCommand('strikeThrough', false)}>
                                        <i className="bi bi-type-strikethrough"></i>
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <select
                                        className="form-select"
                                        onChange={(e) => document.execCommand('fontName', false, e.target.value)}>
                                        <option value="">Font Family</option>
                                        <option value="Arial">Arial</option>
                                        <option value="Courier New">Courier New</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Helvetica">Helvetica</option>
                                        <option value="Tahoma">Tahoma</option>
                                        <option value="Times New Roman">Times New Roman</option>
                                        <option value="Verdana">Verdana</option>
                                        <option value="Comic Sans MS">Comic Sans MS</option>
                                        <option value="Impact">Impact</option>
                                        <option value="Lucida Console">Lucida Console</option>
                                    </select>
                                </div>
                                <div className="col-auto">
                                    <select
                                        className="form-select"
                                        onChange={(e) => document.execCommand('fontSize', false, e.target.value)}>
                                        <option value="1">Font Size 8px</option>
                                        <option value="2">Font Size 10px</option>
                                        <option value="3">Font Size 12px</option>
                                        <option value="4">Font Size 14px</option>
                                        <option value="5">Font Size 18px</option>
                                        <option value="6">Font Size 24px</option>
                                        <option value="7">Font Size 36px</option>
                                    </select>
                                </div>

                                <div className="col-auto">
                                    <button
                                        onClick={() => setIsColorPickerVisible(!isColorPickerVisible)} // Toggle visibility of the color picker
                                    >
                                        <i className="bi bi-palette"></i>
                                    </button>

                                    {isColorPickerVisible && (
                                        <input
                                            className="form-control w-h-32 p-1"
                                            type="color"
                                            onChange={(e) => handleColorChange(e.target.value)} // Handle color selection
                                            title="Font Color"
                                            style={{ display: isColorPickerVisible ? 'inline-block' : 'none' }} // Show/hide based on state
                                        />
                                    )}
                                </div>

                                <div className="col-auto">
                                    <button
                                        onClick={() => {
                                            const url = prompt('Enter the URL');
                                            if (url) {
                                                document.execCommand('createLink', false, url);
                                            }
                                        }}>
                                        <i className="bi bi-link-45deg"></i>
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button className="icon-disable" onClick={() => document.execCommand('unlink', false)}>
                                        <i className="bi bi-link-45deg"></i>
                                    </button>
                                </div>
                                {/* <div className="col-auto">
                                    <button
                                        onClick={() => {
                                            const imageUrl = prompt('Enter the Image URL');
                                            if (imageUrl) {
                                                document.execCommand('insertImage', false, imageUrl);
                                            }
                                        }}>
                                        <i className="bi bi-image"></i>
                                    </button>
                                </div> */}
                                <div className="col-auto">
                                    {/* Toggle Button */}
                                    <button onClick={toggleView}>
                                        <i className="bi bi-code-slash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        {/* Content Area */}
                        {isCodeView ? (
                            // Code View
                            <textarea
                                rows={10}
                                value={htmlContent}
                                onChange={(e) => setHtmlContent(e.target.value)}
                                style={{
                                    width: '100%',
                                    border: '1px solid #ccc',
                                    padding: '10px',
                                    fontFamily: 'monospace',
                                }}
                                placeholder="Paste your HTML code here"
                            />
                        ) : (
                            // Rendered HTML View
                            <div
                                ref={contentRef}
                                contentEditable
                                suppressContentEditableWarning
                                onInput={handleInput}
                                onBlur={handleBlur} // Save content on blur
                                dangerouslySetInnerHTML={{ __html: htmlContent }}
                                style={{
                                    border: '1px solid #ccc',
                                    padding: '10px',
                                    minHeight: '300px',
                                    backgroundColor: '#fff',
                                    overflow: 'auto',
                                }}></div>
                        )}
                        {showDropdown && <MentionDropdown onSelect={handleSelectMention} position={dropdownPosition} />}
                    </div>

                    {/* <div className="row g-3 mt-3">
                        <div className="col-12">
                            <label htmlFor="categories" className="form-label">
                                Campaign Tags
                            </label>
                            <Select
                                isMulti
                                options={availableCategories}
                                value={selectedCategories}
                                onChange={(selected) => setSelectedCategories(selected as MultiValue<{ value: string; label: string }>)}
                            />
                        </div>
                    </div> */}

                    <div className="my-2 d-flex flex-wrap justify-content-center">
                        <label htmlFor="AudienceCategory">
                            <b>Audience Category</b>
                        </label>
                    </div>
                    <div className="my-2 d-flex flex-wrap justify-content-center">
                        <div className="row g-3 g-sm-5">
                            <div className="col-auto">
                                <div className="d-flex flex-wrap align-items-center">
                                    <input
                                        type="radio"
                                        className="form-check-input mt-0 me-1"
                                        value="1"
                                        id="Students"
                                        checked={selectedCategory === '1'}
                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                        required
                                    />
                                    <label className="form-check-label font-16 font-500 flex-1" htmlFor="Students">
                                        Students
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="d-flex flex-wrap align-items-center">
                                    <input
                                        type="radio"
                                        className="form-check-input mt-0 me-1"
                                        value="2"
                                        id="Alumni"
                                        checked={selectedCategory === '2'}
                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                        required
                                    />
                                    <label className="form-check-label font-16 font-500 flex-1" htmlFor="Alumni">
                                        Alumni
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="d-flex flex-wrap align-items-center">
                                    <input
                                        type="radio"
                                        className="form-check-input mt-0 me-1"
                                        value="3"
                                        id="Lead"
                                        checked={selectedCategory === '3'}
                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                        required
                                    />
                                    <label className="form-check-label font-16 font-500 flex-1" htmlFor="Lead">
                                        Lead
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="my-0 d-flex flex-wrap justify-content-center">
                        <button className="btn btn-green me-3" onClick={saveOrUpdateTemplate}>
                        <span> Save as Draft</span>
                        </button>

                        {/* Other buttons */}
                        <button
                            className="btn btn-green me-3"
                            onClick={() => {
                                if (templateId) {
                                    // Confirm deletion
                                    const isConfirmed = window.confirm('Are you sure you want to delete this template?');
                                    if (isConfirmed) {
                                        deleteTemplate(templateId);
                                    }
                                } else {
                                    // If no templateId, redirect directly
                                    window.location.href = `/broadcast`;
                                }
                            }}>
                             <span> Discrete</span>
                        </button>

                        <button className="btn btn-green me-3" onClick={handleSaveAndNext}>
                            <span>Save and Next</span>
                        </button>
                    </div>
                </div>
            </div>
            <div style={{ padding: '20px' }}></div>
        </div>
    );
};

export default HTMLTemplate;
