const CardExam = ({ alumni, cardType, state }: { alumni: any; cardType: string; state: any }) => {
    console.log(alumni, ' alumni ');
    const exam = alumni?.entranceTestResult?.exam ?? '';
    const score = alumni?.entranceTestResult?.score ?? '';
    const year = alumni?.entranceTestResult?.year ?? '';
    const score_type_code = alumni?.entranceTestResult?.scoreTypeCode ?? '';

    return (
        <div key={alumni.uuid} className="alumni_univarsity_details examtaken">
            <div className="alumni_univarsity_details_inner_cover">
                <div className="alumni_univarsity_logo">
                    <img src="https://www.univariety.xyz/prototype/alumni-university/images/icon-note.svg" alt="" />
                </div>
                <div className="alumni_univarsity_brife">
                    <div className="alumni_education">
                        <div className="row g-2">
                            <div className="col">Exam Taken</div>
                        </div>
                    </div>
                    <div className="alumni_univarsity_name">
                        <span>
                            {exam} - {year}
                        </span>
                    </div>
                    <div className="alumni_univarsity_subject">
                        <i className=""></i>
                        <span>
                            {score_type_code.charAt(0).toUpperCase() + score_type_code.slice(1).toLowerCase()} - {score as string}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardExam;
