import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import SessionContext from '../../../library/Core/SessionContext';
import { isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
//import { onMobileCountryCodeChange, onMobileNumberChange, twelthPassoutYear } from './CreateAlumniActions';
import Layout from './Layout';
import { format } from 'date-fns';

const AddNew = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const { routeInfo, formConfig, data } = useLoaderData() as State;
    const { sessionState } = useContext(SessionContext);
    // const { sessionState } = useContext(SessionContext);
    const userType = sessionState?.userTypeCode;
    const userId = sessionState?.id;
    const queryParams = new URLSearchParams(window.location.search);
    const eventId = queryParams.get('id');
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            SAVE: handleCreate,
        },
    } as any);

    // useEffect(() => {
        
    //     dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });
    // }, []);

    function handleCreate(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();
        
        // const istenthdataNotvalid = isTenthDataNotValid(state, dispatch);
        //const isInstituteNamenotValid = isInstituteNameNotValid(state, dispatch);

        console.log(state['data'])
        
        // const isnotValideventTitle = iseventTitlenotValid(state, dispatch);
        // const isnotValideventMode = iseventModenotValid(state, dispatch);
        // const isnotValidactiveEvent = isactiveEventnotValid(state, dispatch);
        const isnotValiddateFrom = isdateFromnotValid(state, dispatch);
        const isnotValidtoFrom = istoFromnotValid(state, dispatch);
        const isnotValidregistrationsActive = isregistrationsActivenotValid(state, dispatch);
        const isnotValidpresenterCategory = ispresenterCategorynotValid(state, dispatch);
        const isnotValideventImage = iseventImagenotValid(state, dispatch);


        if (!isFormValid(state, dispatch) || isnotValiddateFrom || isnotValidtoFrom || isnotValideventImage || isnotValidregistrationsActive || isnotValidpresenterCategory) {
            return;
        }

         let eventAdd = `${process.env.REACT_APP_LEAD_SERVER}/marketevent/addnewevent`;
        // let userStatusCode = 'PROFILE_IN_PROGRESS';
        
        // const dateFromDate = new Date(state['data']['addevent']['dateFrom']);
        // const toFromDate = new Date(state['data']['addevent']['toFrom']);

        // alert(dateFromDate)

        // alert(toFromDate)

        // // Format the date and time as required by MySQL (YYYY-MM-DD HH:MM:SS)
        // const formattedFromDateTime = dateFromDate ? dateFromDate.toISOString().slice(0, 19).replace('T', ' ') : '';
        // const formattedToDateTime = toFromDate ? toFromDate.toISOString().slice(0, 19).replace('T', ' ') : '';


        const dateFromDate = new Date(state['data']['addevent']['dateFrom']);
        const toFromDate = new Date(state['data']['addevent']['toFrom']);

        console.log(dateFromDate, 'dateFromDate')
        console.log(toFromDate, 'toFromDate')

        // Check if the date is valid
        // if (isNaN(dateFromDate.getTime())) {
        // console.error('Invalid dateFromDate:', state['data']['addevent']['dateFrom']);
        // }

        // if (isNaN(toFromDate.getTime())) {
        // console.error('Invalid toFromDate:', state['data']['addevent']['toFrom']);
        // }

        const formattedFromDateTime = format(dateFromDate, 'yyyy-MM-dd HH:mm:ss');
        const formattedToDateTime = format(toFromDate, 'yyyy-MM-dd HH:mm:ss');
        // Format the date as required by MySQL (YYYY-MM-DD HH:MM:SS)
        // const formattedFromDateTime = !isNaN(dateFromDate.getTime())
        // ? dateFromDate.toISOString().slice(0, 19).replace('T', ' ')
        // : '';

        // const formattedToDateTime = !isNaN(toFromDate.getTime())
        // ? toFromDate.toISOString().slice(0, 19).replace('T', ' ')
        // : '';

        console.log(formattedFromDateTime); // Output: "2024-04-20 16:13:00"
        console.log(formattedToDateTime); // Output: "2024-04-20 16:13:00"

        axiosClient()
            .post(`${eventAdd}`, {
                eventId : eventId ? eventId : '',
                eventTitle: state['data']['addevent']['eventTitle'],
                eventMode: state['data']['addevent']['eventMode'],
                activeEvent: state['data']['addevent']['activeEvent'],
                dateFrom: formattedFromDateTime,
                toFrom: formattedToDateTime,
                registrationsActive: state['data']['addevent']['registrationsActive'],
                eventType: state['data']['addevent']['eventType'],
                registrationLink: state['data']['addevent']['registrationLink'],
                videoLink: state['data']['addevent']['videoLink'],
                presenterCategory: state['data']['addevent']['presenterCategory'],
                eventDescription: state['data']['addevent']['eventDescription'],
                instituteId: state['data']['addevent']['instituteId'],
                eventImage: state['data']['addevent']['eventImage'],
                created_by: userId,
                created_role: userType,
                 
             })
             .then((res: any) => {

                console.log(res)
                alert(res.data.message)

                if (!res.data.eventId) return alert('There is some issue in event management. Please try after sometime!');
                navigate(`/event/speaker-info?id=${res.data.eventId}`);

            });

        
    }

    const isdateFromnotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        var returnVal = false;     

        if (state.data?.addevent?.dateFrom === null || state.data?.addevent?.dateFrom === undefined) {
          // alert(state.data?.addevent?.dateFrom);

            setError('addevent.eventFrom', ['Please enter "From date"'], dispatch);
            returnVal = true;
        }else {

            setError('addevent.eventFrom', [], dispatch);
        }
       // alert(returnVal)

        return returnVal;
    };

    const istoFromnotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        var returnVal = false;


        if (state.data?.addevent?.toFrom === null || state.data?.addevent?.toFrom === undefined) {
           //alert(state.data?.addevent?.toFrom);
            setError('addevent.eventTo', ['Please enter "To Date"'], dispatch);
            returnVal = true;
        }else {

            setError('addevent.eventTo', [], dispatch);
        }

        if (new Date(state.data?.addevent?.dateFrom) >= new Date(state.data?.addevent?.toFrom)) {
            setError('addevent.eventTo', ['Please enter "To Date Greater then From Date"'], dispatch);
            returnVal = true;
        }

        // if (state.data?.addevent?.toFrom > state.data?.addevent?.dateFrom) {
        //     setError('addevent.eventTo', ['Please enter "To Date Greater then From Date"'], dispatch);
        //     returnVal = true;
        // }
        //alert(returnVal)

        return returnVal;
    };

    const isregistrationsActivenotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        var returnVal = false;

        console.log(state.data?.addevent?.registrationsActive);
        

        if (state.data?.addevent?.registrationsActive == 1) {
                        
            if (state.data?.addevent?.registrationLink === null || state.data?.addevent?.registrationLink === undefined) {
                alert('Please enter "Registrations Link"')
                setError('addevent.registrationLink', ['Please enter "Registrations Link"'], dispatch);
                returnVal = true;
            }
            //returnVal = false;
        } else {
            setError('addevent.registrationLink', [''], dispatch);
            returnVal = false;
        }

        return returnVal;
    };

    const ispresenterCategorynotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        var returnVal = false;

        console.log(state.data?.addevent?.presenterCategory);
        
        if (state.data?.addevent?.presenterCategory == 4 ) {
            if (state.data?.addevent?.instituteId === null || isEmpty(state.data?.addevent?.instituteId) || state.data?.addevent?.instituteId === undefined) {
                alert('Please enter "Relate Institute name"')
                setError('addevent.instituteId', ['Please enter "Relate Institute name"'], dispatch);
                returnVal = true;
            }
        } else {
            setError('addevent.instituteId', [''], dispatch);
            returnVal = false;

        }

        return returnVal;
    };

    const iseventImagenotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        var returnVal = false;

        // Check if the image is valid
        const image = state.data?.addevent?.eventImage; // Assuming there's an image in your state
        if (!image || !isImageValid(image)) {
            setError('addevent.eventImage', ['Invalid image. Please upload a valid image (JPEG/PNG/GIF, max 5 MB)'], dispatch);
            returnVal = true;
        }

        return returnVal;
    };


    const isImageValid = (image: File): boolean => {
        //const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
        //const maxFileSize = 5 * 1024 * 1024; // 5 MB

        // console.log(image.type, " image type")

    
        // if (!allowedTypes.includes(image.type)) {

        //     return false; // Invalid file type
        // }
    
        // if (image.size > maxFileSize) {
        //     return false; // File too large
        // }
    
        return true; // All checks passed
    };
    
    

    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: { addevent : data  },
                //data:data,
                domain,
                routeInfo,
            },
        });
    }, []);


    return (
        
        <SmartContext.Provider value={{ state, dispatch }}>            
            <Layout>{!state.flags.isDataLoading && <PageBuilder />}</Layout>
        </SmartContext.Provider>
    );
};

export default AddNew;
