import { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
// import { handleFilterChange } from '../../alumni/alumni-directory/AlumniDirectoryActions';
import AlbumListingLayout from './AlbumListingLayout';

const AlbumListing = () => {
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        data: { filters: {} },
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { formConfig, domain, data, internal } = useLoaderData() as State;

    useEffect(() => {
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, internal },
        });
    }, []);

    if(sessionStorage.getItem('user-type-code') === 'STUDENT' && sessionStorage.getItem('user-profile-status-code') === 'PREFERENCES_INCOMPLETE')
    {
        window.location.href = `/student/${sessionStorage.getItem('user-id')}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>{!state?.flags.isDataLoading && <AlbumListingLayout />}</SmartContext.Provider>
    );
};

export default AlbumListing;
