import { useContext } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';

const useGuidance = () => {
    const { state, dispatch } = useContext(SmartContext);
    const getCategoryName = (category: string) =>
        (state.domain.get('GUIDANCE_CATEGORY') as any).find((item: any) => item.code == category)?.value;

    const getSubCategories = (categoryCode: string, uniqueSubCategoryCodes: any) => {
        const subCategories = (state.domain.get('GUIDANCE_SUB_CATEGORY') as any).filter((item: any) => item.parentCode == categoryCode);
        return subCategories?.filter((item: any) => uniqueSubCategoryCodes.includes(item.code));
    };

    const getSubCategoryName = (subCategoryCode: string) =>
        (state.domain.get('GUIDANCE_SUB_CATEGORY') as any).find((item: any) => item.code == subCategoryCode)?.value;

    return { getCategoryName, getSubCategories, getSubCategoryName };
};

export default useGuidance;
