import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { onSave, onMaxExpChange, onChangeTitle, onChangeCompanyName } from './JobPostingAction';
import JobPostingLayout from './JobPostingLayout';

const JobPosting = () => {
    const { id, pageName } = useParams();
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            SAVE: onSave,
            ROUTER_NAVIGATE: navigate,
            maxExperience: onMaxExpChange,
            title: onChangeTitle,
            companyName: onChangeCompanyName,
        },
    } as any);

    const { formConfig, data } = useLoaderData() as State;

    useEffect(() => {
        data.jobPostVisibleTo = data.jobPostVisibleTo ?? [];
        const domain = globalState.domain;
        const schoolId = sessionStorage.getItem('user-school-id');
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: { jobPosting: { ...data.data, schoolId } },
                domain,
                routeInfo: { id, pageName },
            },
        });
    }, [id]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <JobPostingLayout>{!state.flags.isDataLoading && <PageBuilder />}</JobPostingLayout>
        </SmartContext.Provider>
    );
};
export default JobPosting;
