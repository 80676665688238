import { useContext, useEffect, useState } from 'react';
import { getControlFromFactory } from '../Core/ControlFactory';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';

const FiltersControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const [triggerCallback, setTriggerCallback] = useState(false);

    useEffect(() => {
        if (triggerCallback) state?.actions['handleFilterChange'](state, dispatch);
    }, [state?.data?.filters]);

    const handleSearchCriteriaAddition = (id: string, value: any, dataKey: string, checked: boolean) => {
        if (checked) {
            dispatch({ type: 'ADD_NEW_RECORD_IN_ARRAY', payload: { dataKey, value } });
        } else {
            const index = getControlValueFromState(dataKey, state as State).indexOf(value);
            dispatch({ type: 'DELETE_RECORD_IN_ARRAY', payload: { dataKey, index } });
        }
        setTriggerCallback(true);
    };

    return (
        <div className="w-100 ">
            <div className="accordion" id={`filtersFor${dataKey}`}>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne">
                            <i className="bi bi-funnel-fill fs-4 text-dark"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent={`filtersFor${dataKey}`}>
                        <div className="accordion-body">
                            <div className="d-flex flex-wrap align-content-start w-100 pb-2">
                                {control.controlGroup.map((subControl) => (
                                    <div key={subControl.id} className={`d-flex flex-wrap ${subControl.className}`}>
                                        {getControlFromFactory(
                                            subControl,
                                            parentDataKey as string,
                                            parentDataKey + `.` + subControl.id,
                                            state as State,
                                            handleSearchCriteriaAddition,
                                            undefined,
                                            undefined
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FiltersControl;
