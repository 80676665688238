import { useContext } from 'react';
import BasicInfoVisual from '../../../../../styles/images/basic-info-visual.png';
import SessionContext from '../../../../../library/Core/SessionContext';

const AdminAdditionalInfoLayout = (props: any) => {
    const { sessionState } = useContext(SessionContext);
    const SchoolLogo = sessionStorage.getItem('school-image') as string;
    // const userStatusCode = sessionStorage.getItem('user-status-code');
    const userStatusCode = sessionState?.userStatusCode;
    return (
        <>
            <div className="main-wrap">
                <div className="leftPane">
                    <div className="leftPaneWrap">
                        {userStatusCode != 'ACTIVE' && (
                            <div className="leftPaneHeaderWrap">
                                <div className="leftPaneHeader">
                                    <img src={SchoolLogo} alt="" />
                                    <label className="user_name">
                                        Hey{' '}
                                        <span>{`${sessionState?.firstName} ${sessionState?.lastName}`}</span>
                                    </label>
                                </div>
                            </div>
                        )}
                        <div className="leftPaneBodyWrap">
                            <div className="leftPaneBody">
                                {/* <h2>Additional Information</h2> */}
                                <div className="qs_body aos-init aos-animate" data-aos="fade-right" data-nextpage="undergraduate.html">
                                    <div className="steps_questions">
                                        {/* <TimeSlotsControl dataKey={'adminAdditionalInfo.timeSlots'} /> */}
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rightPane basic-information">
                    <div className="rightPaneWrap">
                        {userStatusCode != 'ACTIVE' && (
                            <div className="rightPaneHeaderWrap">
                                <div className="rightPaneHeader">
                                    <label className="user_name">
                                        Hey{' '}
                                        <span>{`${sessionState?.firstName} ${sessionState?.lastName}`}</span>
                                    </label>
                                </div>
                            </div>
                        )}
                        <div className="rightPaneBodyWrap">
                            <div className="rightPaneBody aos-init aos-animate" data-aos="fade-left">
                                <div className="step step_1">
                                    <a
                                        data-bs-toggle="collapse"
                                        data-bs-target="#listed_points"
                                        href=""
                                        className="mobile-step-block"
                                        aria-expanded="false"></a>
                                    <div className="textDetail">
                                        <h2 className="stepHeader">
                                        Sharing This Additional Information
                                            <br /> Will Help Us -
                                        </h2>
                                        <div className="listed_points" id="listed_points">
                                            <ul>
                                                <li>Personalize the communication and share timely updates.</li>
                                                <li>Send marketing collateral and promotional materials to your office.</li>
                                            </ul>
                                        </div>
                                        <a href="" className="step_header_wrap_link">
                                            Read <span className="more">More</span>
                                            <span className="less">Less</span>
                                        </a>
                                    </div>
                                    <div className="step_img">
                                        <img src={BasicInfoVisual} className="img-fluid float_horizontal_animate" width="100%" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a data-bs-toggle="collapse" data-bs-target="#listed_points" href="" className="bg-drop" aria-expanded="false"></a>
            </div>
        </>
    );
};

export default AdminAdditionalInfoLayout;
