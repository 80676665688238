import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useRef, useState } from 'react';

function EngagementActionSettings(props) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleClick = (event) => {
        setIsOpen(false);
        props.onClick(props.id, event.target.textContent.replace(/[' ]/g, '_').toUpperCase());
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    return (
        <div className="d-inline-block" ref={dropdownRef}>
            <i className="icon-setting text-gray-84 pe-0" role="button" onClick={() => setIsOpen(!isOpen)}></i>
            {isOpen && (
                  <div className="dropdown-men-u option show">
                  <ul>
                  {props.actions.map((action, index) => (
                      <li>
                      <a className="" key={action} role="button" onClick={handleClick}>
                          {action}
                      </a>
                      </li>
                  ))}
                  </ul>
              </div>
            )}
        </div>
    );
}

export default EngagementActionSettings;
