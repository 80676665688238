import {  Tooltip } from 'bootstrap';
import React from 'react';
import moment from 'moment';
import { formatFullName } from '../../../library/Core/SmartFunctions';


const ValuableQAModal = ({data}:any) => {
    const {alumniData, valuableQAData,getSentHistory,alumni}=data;
    console.log("alumniData",alumniData)
   
return (
        <>
          <div className="modal fade px-3" id="view_sent_qa_pop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered  max-800 mx-auto">
                    <div className="modal-content">
                    <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
                        <div className="d-flex flex-wrap">
                        <a className="fa fa-arrow-circle-left mt-1 me-2 go-back" data-bs-toggle="modal" data-bs-target="#view-sent-history" href="javascript:;" data-bs-dismiss="modal"></a>
                        <h5 className="modal-title flex-1" id="staticBackdropLabel"><span>View Sent Q&A</span> </h5>
                        </div>
                        <button type="button" className="btn-close btn-close-small" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <form className="needs-validation">
                        <div className="row g-4">
                            <div className="col-md-4 col-sm-6">
                                <label className="form-label">Name</label>
                                <input type="text" className="form-control flex-1" id="skills" value={alumniData?.AlumniName} disabled />
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <label className="form-label">Mobile</label>
                                <input type="text" className="form-control flex-1" id="skills" value={alumniData?.AlumniMobile} disabled/>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group mb-0">
                                <label className="form-label">Last Sent</label>
                                <div className="form-last-sent">
                                {alumniData?.LastSentDate ? (
                                        <>
                                        <a href="javascript:;" onClick={() => getSentHistory(alumni)}>{alumniData?.ProfileSendUsers.length} users</a> on {moment(alumniData?.LastSentDate).format('DD-MMM-YYYY')}
                                        </>
                                    ) : (
                                        <span>NA</span>
                                    )}
                                </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <label className="form-label">User ID</label>
                                <input type="text" className="form-control flex-1" id="skills" disabled value={alumniData?.AlumniUserId} />
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <label className="form-label">Email</label>
                                <input type="text" className="form-control flex-1" id="skills" value={alumniData?.AlumniEmail} disabled/>
                            </div>
                         </div>
                        </form>
                    </div>
                    <div className="half-gray py-4 border-top">
                        <div className="w-100 px-4 pb-4 border-bottom">
                        <div className="row">
                        {Array.isArray(valuableQAData) &&
                                valuableQAData
                                  .map((message: any,index) => (
                                        index === 0 && (
                                            <div key={index} className="col-sm-6 mb-2 mb-sm-0">
                                                <div className="d-flex flex-wrap align-items-center">
                                                    <i className="me-2">
                                                        <img
                                                            src={message.profilePictureFilePath}
                                                            className="rounded-circle img-fluid"
                                                            width="47"
                                                        />
                                                    </i>
                                                    <span className="lineHeight-16 flex-1">
                                                        <span className="font-16 font-500 text-dark-blue">{formatFullName(message.studentName,'')},{' '}<br/>
                                                        <span className={`fst-italic font-15`}>{message?.programName}{message?.passOutYear ? ` - ${message.passOutYear}` : ''}</span>
                                                        </span>
                                                        {/* <span className="d-block font-14 font-500 fst-italic text-dark-blue">{message.schoolName}</span> */}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    ))}
                                <div className="col-sm-6 ">
                                    
                                    <div className="d-flex flex-wrap align-items-center flex-row-reverse flex-lg-row pe-md-4">
                                        <i className="ms-2 ms-lg-0 me-0 me-lg-2"><img src={alumniData?.profilePictureFilePath} className="rounded-circle img-fluid" width="47" /></i>
                                        <span className="lineHeight-16 flex-1">
                                            <span className="font-16 font-500 text-dark-blue"> { alumniData?.AlumniName},{' '}<br/>
                                            <span className="fst-italic font-15">{alumniData?.AlumniprogramName}{alumniData?.AlumniPassoutYear ? ` - ${alumniData.AlumniPassoutYear}` : ''}</span>
                                            </span>
                                            {/* <span className="d-block font-14 font-500 fst-italic text-dark-blue"> {alumniData?.CurrentStatus}{','} {alumniData?.seniorityLevelCode}</span> */}
                                            {/* <span className="d-block font-14 font-500 fst-italic text-dark-blue">{alumniData?.AlumnischoolName}</span>                                        */}
                                        </span>  
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="conversation px-4 pt-4">
                            {Array.isArray(valuableQAData) &&
                                valuableQAData
                                  .map((message: any,index) => ( 
                                <div  key={index}
                                    className={
                                        message?.senderId == alumniData?.AlumniUserId ? 'row justify-content-end' : 'row justify-content-start'
                                    }>
                                    <div className="col-sm-6">
                                        <div className="d-flex flex-wrap">
                                            <div className={
                                                message?.senderId == alumniData?.AlumniUserId ? 'messages leftUser ' : 'messages rightUser '
                                            }>
                                                <p>{message.messageContent}</p>
                                                <span className="date">{moment(message.createdAt).format('DD-MMM-YYYY h:mm A')}</span> 
                                            </div>
                                            {/* <div className="userimg ms-2">
                                                <img src="images/ravi_verma_icon.png" className="rounded-circle img-fluid" width="47" />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                             ))}
                            
                        </div>
                    </div>
                    <div className="modal-footer">
                        {/* <a href="javascript:;" className="btn-good-qa" data-bs-toggle="modal" data-bs-target="#update-marking">Close</a> */}
                        <button type="button" className="btn-good-qa" data-bs-dismiss="modal" aria-label="Close">Close</button>

                    </div>
                    </div>
                </div>
                </div>
        </>
    );
  };

  export default ValuableQAModal;