import { useContext, useEffect, useRef, useState } from "react";
import { SmartContext } from "../../library/Core/SmartContext";
import { arrayColumn, isEmpty, setError, triggerGridDataOnChange } from "../../library/Core/SmartFunctions";
import { axiosClient } from "../../library/Service/axiosClient";
import ErrorControl from "../../library/SimpleControls/ErrorControl";
import { DispatchEvent, State } from "../../library/Core/SmartTypes";
import { v4 as uuidv4 } from 'uuid';
import { Modal } from "bootstrap";
import Swal from "sweetalert2";

interface AddPopupProps {
    id: string;
}

interface Item {
    id: string;
    logo: string;
    name: string;
    file: any;
}

interface PopupDataRecord {
    id: string; // or number, depending on your data
    name: string;
    // add other fields if necessary
}

const AddPopup: React.FC<AddPopupProps> = (args) => {
    const { id } = { ...args };
    const { state, dispatch } = useContext(SmartContext);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const addModalRef = useRef<HTMLDivElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [displayEntitySingular, setDisplayEntitySingular] = useState<string | null>(null);
    const [entityKey, setEntityKey] = useState<string>('');
    const [displayEntity, setDisplayEntity] = useState<string | null>(null);
    const [mode, setMode] = useState<string | null>(null);

    useEffect(() =>{
        setMode(isEmpty(state?.internal?.editId) ? 'add' : 'edit');
        let editData: Item | null = null; // Change to null to represent no data
        const editId = state?.internal?.editId;

        if ( ! isEmpty (state?.internal?.selectedEntityId) && state?.internal?.selectedEntityId > 0 ) {
            const filterData = Object.values(state?.data?.popupData || {})
            .flat()
            .filter((item: unknown): item is Item => {
                return typeof item === 'object' && item !== null && 'id' in item && (item as Item).id === editId;
            });
            
            editData = filterData[0] || null; // Get the first matching item or null
        } else {
            // get the college/company data like name & logo from the otherList data.
            const filterData = Object.values(state?.data?.widgetData?.otherList || {})
            .filter((item: unknown): item is Item => {
                return typeof item === 'object' && item !== null && 'id' in item && (item as Item).id === editId;
            });
            
            editData = filterData[0] || null; // Get the first matching item or null
        }

        // Check if editData is not null before accessing its properties
        if (editData) {
            fetchFile(editData.logo)
                .then(file => {
                    // Create a shallow copy of editData to avoid the "not extensible" error
                    const editableEditData = { ...editData, file }; // Spread operator to copy properties
                    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'addNew', value: editableEditData } });
                    // Use optional chaining and provide a fallback value
                    setImagePreview(editableEditData?.logo || null);
                })
                .catch(error => {
                    console.error("Error fetching file:", error);
                });
        }
              
    }, [state?.internal?.editId]);

    const fetchFile = async (url: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_COMMON_API}/upload/fetch-file`, {
                method: 'POST',
                body: JSON.stringify({ url: url }),  // Replace with the actual URL
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch file');
            }

            // Get the Blob from the response
            const blob = await response.blob();
            
            // Extract the filename from the Content-Disposition header or the URL
            const contentDisposition = response.headers.get('Content-Disposition');
            let filename = 'default-filename.png'; // Fallback filename

            if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                const matches = /filename="([^"]+)"/.exec(contentDisposition);
                if (matches && matches[1]) {
                    filename = matches[1];
                }
            } else {
                // If no filename is provided, extract it from the URL (optional)
                const urlParts = new URL(response.url).pathname.split('/');
                filename = urlParts[urlParts.length - 1] || filename;
            }
            const file = new File([blob], filename, { type: blob.type }); // Change "logo.png" if necessary
            return file;
        } catch (err) {
            console.error('Error fetching file:', err);
        }
    }

    useEffect(() => {
        let displayEntitySingularTemp: string | null = null;
        let entityKeyTemp: string = '';
        let displayEntityTemp: string = '';
        
        switch (id) {
            case 'add_company':
                entityKeyTemp = 'industryCode';
                displayEntityTemp = 'Companies'
                displayEntitySingularTemp = 'Company';
                break;
        
            case 'add_college':
                entityKeyTemp = 'countryCode';
                displayEntityTemp = 'Colleges'
                displayEntitySingularTemp = 'College';
                break;
        }

        if (displayEntitySingular !== displayEntitySingularTemp) {
            setDisplayEntitySingular(displayEntitySingularTemp);
        }

        if (displayEntity !== displayEntityTemp) {
            setDisplayEntity(displayEntityTemp);
        }

        if ( entityKey !== entityKeyTemp ) {
            setEntityKey(entityKeyTemp);
        }
    }, []);

    const saveData = async () => {
        let link = '';
        const metricEntityId = state?.internal?.selectedEntityId;
        let entityData: any = {};
        const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
        let id = mode == 'add' ? uuidv4() : state?.internal?.editId;

        if ( ( ! customValidationPassed ) ) {
            dispatch({ type: 'SHOW_ERRORS' });
            console.log(state.formValidationErrors);
            return;
        }

        if ( ! isEmpty(state?.internal?.addNew?.file) ) {
            const apiUrl    = `${process.env.REACT_APP_COMMON_API}/upload/image`;
            const formData  = new FormData();
            formData.append('image', state?.internal?.addNew?.file);

            const response  = await axiosClient().post(apiUrl, formData);
            link = response.data.link;
        }
        // Reset the file input after successful upload
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }

        entityData = {
            'id': id,
            'name': state?.internal?.addNew?.name?.trim(),
            'logo': link
        };

        if ( ! isEmpty (metricEntityId) && metricEntityId > 0 ) {
            entityData[entityKey] = metricEntityId
            let popupData = JSON.parse(JSON.stringify(state?.data?.popupData)) || {};

            if (!popupData[metricEntityId]) {
                popupData[metricEntityId] = [];
            }

            if ( mode == 'add' ) {
                popupData[metricEntityId].push(entityData);
            } else {
                const index = popupData[metricEntityId].findIndex((item: Item) => item.id === state?.internal?.editId);
                popupData[metricEntityId][index] = entityData;
            }
            // popupData[metricEntityId].push(entityData);
            
            dispatch({
                type: 'REFRESH_DATA',
                payload: {
                    data: {...state.data, popupData: popupData}
                },
            });
                
            setTimeout(() => {
                triggerGridDataOnChange(state.data.metricData, "metricData", "metricEntity2");
            }, 1000);
        } else {
            entityData[entityKey] = 0;
            let otherList = JSON.parse(JSON.stringify(state?.data?.widgetData?.otherList)) || {};
            // otherList.push(entityData);
            if ( mode == 'add' ) {
                otherList.push(entityData);
            } else {
                const index = otherList.findIndex((item: Item) => item.id === state?.internal?.editId);
                otherList[index] = entityData;
            }

            let otherCount = otherList.length ?? 0;
            let otherDisplay = `${otherCount} ${displayEntity}`
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: {
                    dataKey: `widgetData.otherDisp`,
                    value:  otherDisplay
                },
            });

            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: {
                    dataKey: `widgetData.other`,
                    value:  otherCount
                },
            });

            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: {
                    dataKey: `widgetData.otherList`,
                    value:  otherList
                },
            });
        }

        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'addNew', value: {} } });

        Swal.fire({
            title: "Success!",
            text: `${displayEntitySingular} ${mode == 'add' ? 'added' : 'updated'}!`,
            icon: "success"
        });
        
        handleClosePopup();        
    }

    const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        const errorMessages = [] as any[];
        let isFormInvalid = false;
        
        if (isEmpty(state?.internal?.addNew?.name?.trim())) {
            setError(`addNew.name`, [`${displayEntitySingular} name is required!`], dispatch);
            isFormInvalid = true;
        } else {
            setError(`addNew.name`, [], dispatch);
        }

        const popupData = ! isEmpty(state?.internal?.selectedEntityId) && state?.internal?.selectedEntityId > 0 
                            ? state?.data?.popupData[state.internal.selectedEntityId] 
                            : state?.data?.widgetData?.otherList;

        // if (!isEmpty(popupData)) {
        //     const addedList = arrayColumn(popupData, 'name');
        // console.log(addedList, " addedList ")
        //     if ( addedList.includes(state?.internal?.addNew?.name?.trim()) ) {
        //         setError(`addNew.name`, [`${displayEntitySingular} name already added!`], dispatch);
        //         isFormInvalid = true;
        //     }
        // }

        if (!isEmpty(popupData)) {
            // Type assertion for popupData
            const addedList = arrayColumn(popupData, 'name');
            const currentName = state?.internal?.addNew?.name?.trim();
            const currentId = state?.internal?.addNew?.id;
        
            // Check if the name exists in the addedList, excluding the current record's name
            if (addedList.includes(currentName)) {
                const isCurrentRecord = popupData.some((record: PopupDataRecord) => 
                    record.id === currentId && record.name === currentName
                );
        
                if (!isCurrentRecord) {
                    setError(`addNew.name`, [`${displayEntitySingular} name already added!`], dispatch);
                    isFormInvalid = true;
                }
            }
        }
        
        const file = state?.internal?.addNew?.file;
        
        if (state?.data?.widgetData?.logoFlag != 'dont_show' && isEmpty(file)) {
            setError(`addNew.file`, [`Logo is required!`], dispatch);
            isFormInvalid = true;
        } else {
            const allowedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
            
            if (file && !allowedFileTypes.includes(file.type)) {
                setError(`addNew.file`, [`Invalid file extension!`], dispatch);
                isFormInvalid = true;
            } else {
                setError(`addNew.file`, [], dispatch);
            }
        }

        // Dispatch the validation errors to the state
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'addNewCnt', errorMessages },
        });

        return !isFormInvalid;
    }

    const handleControlValueChange = (args: any) => {
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'addNew', value: {...state?.internal?.addNew, name: args.value} } });
    }
    
    const handleImageUpload = async (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setImagePreview(previewUrl);
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'addNew', value: {...state?.internal?.addNew, file} } });
        }
    };

    const handleClosePopup = () => {
        setImagePreview(null);
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'addNew', value: {} } });
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'editId', value: '' } });

        setError('addNew.name', [], dispatch);
        setError('addNew.file', [], dispatch);

        if (addModalRef.current) {
            const modal = Modal.getInstance(addModalRef.current);
            if (modal) {
                dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isAddModalOpen', value: false } });
                modal.hide();
            }
        }
    }

    return (
        <div 
            className="modal fade px-3" 
            id={id}
            data-bs-backdrop="static" 
            data-bs-keyboard="false" 
            tabIndex={-1} 
            aria-labelledby="staticBackdropLabel" 
            style={{ display: 'none' }}
            aria-modal="true" 
            role="dialog"
            ref={addModalRef}>

            <div className="modal-dialog modal-dialog-centered max-600 mx-auto">
                <div className="modal-content">
                    <div className="modal-header p-4 height-70 border-0 bg-lite-blue d-flex align-items-center justify-content-between">
                        <h5 className="modal-title d-flex align-items-center" id="staticBackdropLabel">{mode == 'add' ? state?.internal?.widget_title : state?.internal?.edit_widget_title}</h5>
                        <button type="button" onClick={handleClosePopup} className="btn-close btn-close-small m-0 position-static" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <form>
                            <div className="row g-3">
                                <div className="col-md-12">
                                    <label className="form-label m-0 mb-1 font-16 font-500 w-100 mb-2" htmlFor={`${displayEntity?.toLowerCase()}-name`}>{displayEntitySingular} Name</label>
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    onChange={(event) =>
                                        handleControlValueChange({ value: event.target.value, key: 'name'})
                                    }
                                    value={state?.internal?.addNew?.name || ""}
                                    placeholder={`Enter ${displayEntitySingular} Name`} />
                                    <ErrorControl errorMessages={state?.formValidationErrors['addNew.name']} />
                                </div>

                                <div className="col-md-12">
                                    <label className="form-label m-0 mb-1 font-16 font-500 w-100 mb-2" htmlFor="upload-display-image">Upload Display Image</label>
                                    <div className="upload_section">
                                        <input 
                                            type="file" 
                                            ref={fileInputRef}
                                            id="upload-display-image" 
                                            onChange={handleImageUpload}
                                        />
                                        {!imagePreview && (
                                            <div className="row g-2 align-items-center justify-content-center">
                                                <div className="col-sm d-flex align-items-center justify-content-center">
                                                    <div className="cloud-wrap px-3 px-sm-5 mx-auto">
                                                        <div className="cloud-img"><img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/upload-cloud.svg`} alt="" /></div>
                                                        <div className="cloud-text">Drag and drop file here or <a href="#" className="text-sky-blue">Browse</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {imagePreview && (
                                            <img src={imagePreview} className="img-fluid rounded" alt="Please upload a valid image file..!" />
                                        )}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <ErrorControl errorMessages={state?.formValidationErrors['addNew.file']} />        
                                        </div>
                                        <div className="col-md-8">
                                            <span className="text-end font-14 d-block text-dark-blue opacity-7">Only png, jpg, jpeg files. Best resolution 400px x 400px</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-center">
                                    <button 
                                        type="button" 
                                        onClick={saveData}
                                        className="btn btn-green px-5">
                                        <span> Save</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPopup;
