import { useContext, useEffect } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, getDomainValueForCode, handleControlValueChange, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';

const TextAsLabelControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);

    useEffect(() => {
         // Set the default value in the state if the data is empty
         if (isEmpty(data) && !isEmpty(control.props?.defaultValue)) {
            handleControlValueChange({ control, value: control.props.defaultValue, dataKey, parentDataKey, state, dispatch });
        }
    }, []);

    return (
        <>
            {control.props.label && (
                <label htmlFor={control.id} className="form-label">
                    {`${control.props.label} ${control.props.required ? '*' : ''}`}
                </label>
            )}
            <span className={`form-label ${control.props.labelCSS}`}>
                {control.props.domainCategoryCode ? getDomainValueForCode(data as string, control.props.domainCategoryCode, state) : data || control.props?.defaultValue}
            </span>
        </>
    );
};

export default TextAsLabelControl;
