import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import { handleNext } from './AlumniBasicInfoActions';
import AlumniBasicInfoLayout from './AlumniBasicInfoLayout';
import SessionContext from '../../../../library/Core/SessionContext';
import { getMenuType } from '../../../../library/Core/SmartFunctions';

const AlumniBasicInfo = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;

    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');
    const [menuType, setMenuType] = useState(getMenuType(data));

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { NEXT: handleNext, ROUTER_NAVIGATE: navigate, REDIRECT: setRedirectUrl, SET_MENU_TYPE: setMenuType },
    } as any);

    // const { formConfig, data, internal, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        data['alumniProfile'] = data['alumniProfile'] || {};

        const domainMap = new Map([...globalState.domain]);
        let programLevel = '';
        if(sessionState?.role == 'USER')
        {
            programLevel = sessionState?.programLevel;
        }
        else
        {
            const student = JSON.parse(sessionStorage.getItem(`studentProfile-${data.id}`) ?? '')
            programLevel = student.programLevel;
        }
        // const programLevel = sessionState?.programLevel;

        let programDomain = domainMap.get('PROGRAM_LEVEL');
        let programs: any = [];
        let programLevelFlag = false;

        programDomain?.forEach(program => {
            if (program.value == programLevel) {
                programLevelFlag = true;
            }
            if (programLevelFlag) {
                programs.push(program);
            }
        });

        domainMap.set('PROGRAM_LEVEL', programs);
        domainMap.set('INSTITUTE_PROGRAM_CODE', internal);

        const domain = addUserSpecificDomains(new Map([...domainMap]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    useEffect(() => {
        let sessionData = { ...sessionState }
        sessionData.menuType = menuType;
        sessionDispatch({ type: 'SET', payload: sessionData });
    }, [menuType]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <AlumniBasicInfoLayout>{!state.flags.isDataLoading && <PageBuilder />}</AlumniBasicInfoLayout>
        </SmartContext.Provider>
    );
};

export default AlumniBasicInfo;
