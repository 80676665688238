import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/css_pre_singup/style.css';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';

const IdentifyForm: React.FC = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const { schoolDomain } = useParams<{ schoolDomain: string }>();

    const [schoolImage, setSchoolImage] = useState<string | null>(null);
    const [selectedValue, setSelectedValue] = useState<string | null>(null);

    useEffect(() => {
        const fetchSchoolInfo = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-info/${schoolDomain}`);
                const logoUrl = response.data.institute.logo;
                setSchoolImage(logoUrl);
                localStorage.setItem('school-image', logoUrl);
                sessionStorage.setItem('school-image', logoUrl);
            } catch (error) {
                console.error('Error fetching school info:', error);
            }
        };

        fetchSchoolInfo();
    }, [schoolDomain]);

    const handleNextClick = () => {
        if (!selectedValue) {
            alert('Please select an option before proceeding.');
            return;
        }

        if (selectedValue === '1') {
            window.location.href = 'parent';
        } else if (selectedValue === '2') {
            window.location.href = 'past-student';
        }
    };

    return (
        <main className="wrapper">
            <section className="dark-section student-img d-flex">
                <div className="container d-flex flex-column">
                    <div className="logo">
                        <a href="#" className="h-100"><img src={schoolImage || 'images/logo.svg'} className="img-fluid" alt="logo" /></a>
                    </div>
                    <div className="flex-1 py-5 d-flex flex-column justify-content-center align-items-center text-white py-5">
                        <div className="id-title text-center">PLEASE<br className="d-block d-sm-none"/> IDENTIFY<br className="d-block d-sm-none"/> YOURSELF</div>
                        <div className="sep blue"></div>
                        <div className="pb-5">
                            <form className="min-height-250">
                                <div className="row g-3 align-items-center justify-content-center">
                                    <div className="col-12"><div className="text-center font-30">I am a</div></div>
                                    <div className="col-auto">
                                        <div className="form-check p-0">
                                            <input 
                                                className="form-check-input d-none" 
                                                type="radio" 
                                                name="IDENTIFY" 
                                                id="Parent" 
                                                value="1" 
                                                onChange={(e) => setSelectedValue(e.target.value)} 
                                            />
                                            <label className="form-check-label white-label" htmlFor="Parent">
                                                <i></i><span>Parent</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="form-check p-0">
                                            <input 
                                                className="form-check-input d-none" 
                                                type="radio" 
                                                name="IDENTIFY" 
                                                id="PastStudent" 
                                                value="2" 
                                                onChange={(e) => setSelectedValue(e.target.value)} 
                                            />
                                            <label className="form-check-label white-label" htmlFor="PastStudent">
                                                <i></i><span>Past Student</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center text-white py-5 d-none d-md-flex mb-sm-5">
                        <div className="col-auto">Powered by</div>
                        <div className="col-auto"><img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/univariety-white-logo.svg" width="167" alt="Univariety" /></div>
                    </div>
                </div>
            </section>
            <section className="white-section d-block d-md-none">
                <div className="container py-5">
                    <div className="text-center">Powered by</div>
                    <div className="text-center mb-2"><img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/univariety-dark-logo.svg" width="167" alt="Univariety" /></div>
                </div>
            </section>
            <div className="navigation_footer_wrap">
                <div className="navigation_footer">
                    <a href="info" className="left-link btn-back"><i></i> <span>Back</span></a>
                    <a href="javascript:void(0);" className="right-link btn-next" onClick={handleNextClick}> <span>Next</span> <i></i></a>
                </div>
            </div>

            {/* Modal */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered w-860 mx-auto px-3">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-end"><button type="button" className="btn-close position-absol" data-bs-dismiss="modal" aria-label="Close"></button></div>
                            <img src="images/letter.png" width="860" className="img-fluid" alt="letter" />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default IdentifyForm;
