import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartContext } from '../../../../library/Core/SmartContext';
import greenMeter from '../../../../styles/images/green-meter.svg';
import orangeMeter from '../../../../styles/images/orange-meter.svg';
import yellowMeter from '../../../../styles/images/yellow-meter.svg';
import SkillSimilarAlumni from '../controls/SkillSimilarAlumni';
import SessionContext from '../../../../library/Core/SessionContext';
import { isSchoolAdmin } from '../../../../library/Core/SmartFunctions';

const ProfileSkills = () => {
    const { state } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    const model = state.internal.model;
    // console.log(model, " model ");
    const navigate = useNavigate();

    const isPeerReviewed = sessionState?.isPeerReviewed ?? false;
    const userType = sessionStorage.getItem('user-type-code');
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;

    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    const colorMeter: any = {
        '3': greenMeter,
        '2': yellowMeter,
        '1': orangeMeter,
        '6': greenMeter,
        '5': yellowMeter,
        '4': orangeMeter,
    };

    let levelMapping = ['', 'yellow', 'orange', 'green', 'yellow', 'orange', 'green'];
    console.log(model.currentSkill, ' currentSkill ');
    return (
        <div>
            <div className="skills_detail_wrap">
                <div className="skills-block">
                    <div className="blockHeader">
                        <div className="row g-2  align-items-center">
                            <div className="col">
                                <h2 className="m-0">
                                    <span>
                                        {model.firstName} {model.lastName ? `${model.lastName}` : ''}
                                    </span>{' '}
                                    is skilled at
                                </h2>
                            </div>
                            {model.isEditable && !isSchoolAdmin() && (
                                <div className="col-auto">
                                    <a role="button" onClick={() => navigate(`/alumni/${model.id}/skills`)} className="btn-add-section"><b className='icon-edit me-2'></b> <span>Edit</span></a>
                                </div>
                            )}
                        </div>
                    </div>
               

                    

                    <div className="skills-block-detail">
                        {model?.currentSkill?.length > 0 &&
                            model.currentSkill.map((skill: any) => (
                                <div key={skill.id} className=" d-flex justify-content-between meter" title={skill.skillLevel}>
                                    <span className="flex-1 me-3">{skill.skillName}</span>
                                    <span className="meterimg">
                                        {/* <img src={colorMeter[skill.skillLevel]} /> */}
                                        <img
                                            src={`${process.env.REACT_APP_IMAGE_BASEPATH}/${levelMapping[skill.skillLevelCode]}-meter.svg`}
                                        />
                                        {/* levelMapping */}
                                    </span>
                                </div>
                            ))}
                    </div>
                </div>
                {state.data.skillInfo.interestedInUpskilling && (
                    <div className="skills-block">
                        <div className="skills-block-title">
                            <div className="row g-3 align-items-center">
                                <div className="col">
                                    <h2 className="m-0">
                                        Interested in <span>Upskilling</span> for
                                    </h2>
                                </div>
                                {model.isEditable && !isSchoolAdmin() && (
                                    <div className="col-auto">
                                        <div className="edit_section">
                                        <a className="edit_section_toggle" role="button"
                                                    onClick={() => navigate(`/alumni/${model.id}/skills`)}
                                                    ></a>
                                            <div className="edit_section_inner">
                                                <a
                                                    role="button"
                                                    onClick={() => navigate(`/alumni/${model.id}/skills`)}
                                                    className="edit_link">
                                                    Edit
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {model?.targetSkill?.length > 0 && model.targetSkill[0] !== '' && (
                            <div className="skills-block-detail">
                                <div className="interested-block">
                                    <div className="icon icon-bulb"></div>
                                    <div className="interested-topics">{model.targetSkill.map((skill: any) => skill).join('   |   ')}</div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <SkillSimilarAlumni />
        </div>
    );
};

export default ProfileSkills;
