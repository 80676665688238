
import  HeaderControl  from '../../alumni/_common/HeaderControl';
import MenuBuilder from '../../../routes/MenuBuilder';
import PageFooterControl from '../../../library/SimpleControls/PageFooterControl';
import pageImg from '../../../styles/images/learning-style.svg';

const Engineering = () => {
    return (       
        <div className="d-flex flex-column min-vh-100">
                <header>
                    <HeaderControl />
                    <MenuBuilder />
                </header>
            {/* https://stackoverflow.com/questions/56660153/how-to-listen-to-localstorage-value-changes-in-react */}
            <div className='main flex-1'>
                <div className='container max-1140 px-lg-0'>
                    <div className='row'>
                        <div className='col-md-12 mb-4'>
                        <div className="white-block white-block-notopborderradius h-100 p-0">
                  
                  <div className="page_ttl_row border-bottom border-2 custom-border-gray p-4 px-md-5">
                   <div className="row g-3 align-items-center ">
                      <div className="col">
                        <h3 className="font-35 font-600 mb-0 block-title aos-init aos-animate" data-aos="fade-left" data-aos-delay="300">Engineering Assessment</h3>
                      </div>
                      <div className="col-auto">
                        <div className="dashboard innerpage smart-scroll scrolled-down"><a href="javascript:;" className="btn-green" data-aos="fade-right" data-aos-delay="300"><span>View Report</span></a></div>
                      </div>
                   </div>
                </div>
            
                  <div className="p-4 p-md-5">
                    <div className="row country-info">
                      <div className="col-md-12">
                          <div className="country-deatils border-bottom mb-5">
                            <div className='row'>
                                <div className='col-md'>
                                  <h1 className="font-28 font-900 text-dark-blue  mb-3">About Assessment</h1>
                                  <h3 className="font-22 font-400 mb-4">This test is for students who have decided to pursue career in engineering. It will help choose your preferred engineering branch among the plethora of engineering branches that exist today. The test will evaluate you on your interest and capabilities. It will evaluate you on four fundamental aptitudes of an engineer namely spatial, closure, mechanical and logical. The test has been prepared with the help of psychologists, academic counselors and psychiatrist.</h3>
                                </div>
                                <div className='col-md-auto text-center'>
                                  <img src={pageImg} className="img-fluid float-lg-end ms-lg-5 mx-auto" width="396"/>
                                </div>
                            </div>                              
                          </div>
  
                          <div className="tuition-fees border-bottom mb-5 pb-4" id="Universities">
                              <h2 className="font-18 font-600 position-relative title pb-2 mt-4 mb-4">Assessment Output</h2>
                              <p className="font-16">The test output is in the form of a report which shares a brief about all the engineering branches. The report will consist of scores on engineering interests with the highest score indicating the best engineering area for you. The report will also consist of an engineering interest graph which will plot all your engineering interests on a single graph for you to visualize clearly.</p>
                          </div>
  
                          <div className="visa mb-0 pb-0" id="visa">
                              <h2 className="font-18 font-600 position-relative title pb-2 mb-4">Instructions</h2>

                              <div className="basic-requirements">
                                  <ul className="ms-3 mb-4 p-0">
                             
                                      <li className="mb-3">The test will consist of questions with three option. Choose the option that you feel is the best for you.</li>
                                      <li className="mb-3">The questions do not have any right or wrong answers to the questions.</li>
                                      <li className="mb-3">At the end of the test you will get a detailed report which will give you a clear direction of where you should be going.</li>
                                  </ul>
                              </div>

                          </div>
  
                          <div className="visa mb-0 pb-0" id="visa">
                            <div className="row">
                                <div className="col-auto">
                                    <a className="btn-green" href="#"><span>View Report</span></a>
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>                           
                </div>
                  </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageFooterControl/>
        </div>                         
    );
};

export default Engineering;
