import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartContext } from '../../../../library/Core/SmartContext';
import AlumniDirectoryService from '../../alumni-directory/AlumniDirectoryService';

const SummaryControl = () => {
    const { state, dispatch } = useContext(SmartContext);
    const summaryCounts = state.data.summaryCounts;
    const navigate = useNavigate();

    const cardsConfig = [
        { id: 'activeAlumni', label: 'Total Accessible Alumni', image: 'group-40733.svg' },
        { id: 'pursuingHigherStudies', label: 'Pursuing Higher Studies', image: 'group-29476.svg' },
        { id: 'workingAlumni', label: 'Working Alumni', image: 'group-41775.svg' },
        { id: 'fromYourBatch', label: 'From Your Batch', image: 'mask-group-44.svg' },
        { id: 'sameNextLevelInterests', label: 'Same Next Level Interests', image: 'mask-group-45.svg' },
    ];
    const directoryLevel = state.data.directoryLevel;

    const handleDirectoryLevelChange = async (level: string) => {
        const summaryCounts: any = await AlumniDirectoryService.getSummaryCounts(level);
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'directoryLevel', value: level } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'summaryCounts', value: summaryCounts.data } });
    };

    const handleSummaryCardClick = (cardId: string) => {
        navigate(`/alumni/directory/${cardId}`);
    };

    return (
        <section className="bg-red pt-3 pt-sm-5 pb-3" id="alumni_directory">
            <div className="container max-1140 px-lg-0">
                {/* <div className="row alumni-directory">
                    <div className="col-lg-12 d-flex flex-wrap align-items-center mb-3 pb-1">
                        <h2
                            data-aos="fade-left"
                            data-aos-delay="300"
                            className="font-22 font-600 text-white mb-3 mb-sm-0 me-3 aos-init aos-animate">
                            Alumni Directory
                        </h2>
                        <div
                            className="d-flex align-items-center alumni-switch-container aos-init aos-animate"
                            data-aos="fade-right"
                            data-aos-delay="300">
                            <div className="d-flex flex-wrap switch">
                                <a
                                    role="button"
                                    onClick={() => handleDirectoryLevelChange('MY_SCHOOL')}
                                    className={`btn-myCampus shortlist font-16 font-500 d-flex justify-content-center align-items-center ${
                                        directoryLevel === 'MY_SCHOOL' ? ' active ' : ''
                                    }`}>
                                    My Campus
                                </a>
                                <a
                                    role="button"
                                    onClick={() => handleDirectoryLevelChange('MY_SCHOOL_GROUP')}
                                    className={`btn-allCampuses font-16 font-500 d-flex justify-content-center align-items-center ${
                                        directoryLevel === 'MY_SCHOOL_GROUP' ? ' active ' : ''
                                    }`}>
                                    All Campuses
                                </a>
                                <span className="bg"></span>
                            </div>
                        </div>
                    </div>
                </div> */}
                <form method="POST" action="https://miles.univariety.com/alumni/directory" name="card" id="card">
                    <div className="aldir_scroll myCampus active bothCampus">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">
                            {cardsConfig.map((card: any) => (
                                <div key={card.id} className="col mb-4 aldir_box">
                                    <div
                                        className="bg-white rounded alumni-directory-block h-100 aos-init aos-animate"
                                        data-aos="fade-up"
                                        data-aos-delay="600"
                                        role="button"
                                        onClick={() => handleSummaryCardClick(card.id)}>
                                        <div className="number font-28 font-600 d-flex justify-content-between align-items-center">
                                            {summaryCounts[card.id] ?? 0}
                                            <a
                                                href=""
                                                className="icon-right-arrow font-14 font-600 text-decoration-none text-dark-blue"></a>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div className="font-16 font-500 flex-1">{card.label}</div>
                                            <div className="alumni-directory-img">
                                                <img
                                                    src={`${process.env.REACT_APP_IMAGE_BASEPATH}/${card.image}`}
                                                    className="img-fluid"
                                                    width="52"
                                                    alt="Total Joined Alumni"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default SummaryControl;
