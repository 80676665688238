import { activateUserSession, isFormValid } from '../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import logger from '../../../../library/Core/logger';
import { axiosClient } from '../../../../library/Service/axiosClient';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    logger.log(state.data['profile']);

    // const errorKeysToIgnore = getErrorsToIgnore(state);
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    if (!isImpersonatedSession && !isFormValid(state, dispatch /*, errorKeysToIgnore*/)) {
        alert('Please correct the form errors!');
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            selectedInstitute: state?.data?.selectedInstitute,
            userInstitutes: state?.data?.userInstitutes,
            profile: {
                ...state?.data?.profile,
                contactEmailId: state?.data?.profile.contactEmailId,
                contactMobileNumber: state?.data?.profile.contactMobileNumber,
                contactMobileCountryCode: state?.data?.profile.contactMobileCountryCode,
            },
            schoolOutComesDetail: state?.data?.schoolOutComesDetail,
        })
    ).then((response) => {        
        if (response?.status === 200) {
            // Call the API here to update the status as ACTIVE & COMPLETE.
            // activateUserSession(sessionState, sessionDispatch).then(() => {
            //     state.actions['REDIRECT'](`/alumni/${sessionState.id}/profile`);
            // });
            navigateToNextStep(AlumniProfileEditScreens.SCHOOL, state, dispatch, sessionState, sessionDispatch);
        }
    });
};