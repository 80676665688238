import React, { useContext, useState, useEffect } from 'react';
import { SmartContext } from '../../library/Core/SmartContext';
import useMessages from './useMessages';
// import InitiateNewMessagesBar from './InitiateNewMessagesBar';
import OneToOneChatBar from './OneToOneChatBar';
import { axiosClient } from '../../library/Service/axiosClient';
import { getDomainValueForCode, getDomainValueForCategoryCode } from '../../library/Core/SmartFunctions';
import SessionContext from '../../library/Core/SessionContext';
import { getStudentImage } from '../../common/styles/Images';
import CardBottom from '../../features/alumni/alumni-directory/CardBottom';
import { Modal, Tooltip } from 'bootstrap';


interface Alumni {
    uuid: string;
    name: string;
    slot: number;
    batch: number;
    email: string;
    skill: string;
    alumni_id: string;
    autologin: string;
    condition: number;
    country_id: string;
    achievement: string;
    course_name: string;
    school_name: string;
    college_name: string;
    company_name: string | null;
    program_name: string;
    industry_name: string;
    pass_out_year: number;
    functional_area: string;
    profile_picture_file_path: string | null;
}

const MessageRightBar = (props: any) => {
    const { state } = useContext(SmartContext);
    const [newMessage, setNewMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const { sessionState }: any = useContext(SessionContext);
    const [topAlumni, setTopAlumni] = useState<Alumni[]>([]);
    const [isFooterModalOpen, setFooterModalOpen] = useState(false);
    console.log("sessionState",sessionState)
    const uuid = sessionState?.id;
    const instituteGroupId =sessionState?.instituteGroupId
    const [alumniName, setAlumniName] = useState('');
    const [promptCode, setPromptCode] = useState(null);  

    const { sendMessage } = useMessages();
    const { performAction, removeReceiver } = useMessages();
    // const loggedInUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    const loggedInUser =sessionState;
    //sometimes sessionStorage.getItem('user') returns null that's why added sessionState here by vrinda #UAS-1265
    const userType = sessionState?.userTypeCode;
    let hasShownPrivateOption = false; 

    let promptData = getDomainValueForCategoryCode('PROMPT_MESSAGE', state);

    // Update messages state when conversationWithUser changes
    useEffect(() => {
        console.log('Conversation updated:', state?.data?.conversationWithUser);
        setMessages(state?.data?.conversationWithUser ?? []);
    }, [state?.data?.conversationWithUser]);

    const handleTypedMessageChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const handleSendMessage = () => {
        if (newMessage.trim() === '') return;

        // Assuming sendMessage updates the conversationWithUser in context or state
        sendMessage(newMessage, promptCode);
        setPromptCode(null);
        // Clear input field after sending message
        setNewMessage('');
    };

    const markQueryPrivateClick = async (conversationId: any, isChecked: boolean) => {
        const checkBoxChk = isChecked ? 1 : 0;
        try {
            const response = axiosClient().get(
                `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/mark-private-msg/${conversationId}/${checkBoxChk}`
            );
        } catch (error) {
            console.log(error);
        }
    };

    const handleStarClick = () => {
        const action = state?.data?.starred_marked === 1 ? 'REMOVE_STAR' : 'MARK_STARRED';
        performAction(action, [state?.data?.conversationIdPass]);
    };

    const sendDefaultMessage = async (messageTemplateType: any) => {
        setPromptCode(messageTemplateType); // Set Prompt Code
        const loggedInUser = JSON.parse(sessionStorage.getItem('user') || '{}');
        let messageLanguage = '';
        let userName = loggedInUser?.firstName + ' ' + loggedInUser?.lastName;
        // let programName = loggedInUser?.programName;

        let programName = getDomainValueForCode(loggedInUser?.programId, 'ALL_PROGRAM_CODE', state);

        let promptSingleData = getDomainValueForCode(messageTemplateType, 'PROMPT_MESSAGE', state)
        .replace('${userName}', userName)
        .replace('${programName}', programName);

        messageLanguage = promptSingleData
        
        setNewMessage(messageLanguage);
        //await startFreshConversion(messageLanguage);
        //console.log(messageLanguage);
    };

    const fetchTopAlumni = async () => {
        try {
            // const response = await axiosClient().get<{ fetchedAlumni: Alumni[] }>(`${process.env.REACT_APP_REPORTS_SERVER}/common/alumni-top-match/${uuid}/${instituteGroupId}`);
            // setTopAlumni(response.data.fetchedAlumni);
            openFooterModal();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchTopAlumni = async () => {
            try {
                const response = await axiosClient().get<{ fetchedAlumni: Alumni[] }>(
                    `${process.env.REACT_APP_REPORTS_SERVER}/common/alumni-top-match/${uuid}/${instituteGroupId}`
                );
                setTopAlumni(response.data.fetchedAlumni);
            } catch (error) {
                console.error('Error fetching topAlumni:', error);
            }
        };
    
        fetchTopAlumni(); 
    }, [uuid, instituteGroupId]);
    
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        
        updateNextBatchAlumni();

        // Join UUIDs from the topAlumni array
        const uuids = topAlumni?.map(alum => alum.uuid).join('/');
    
        // Navigate to the new route
        state.actions['ROUTER_NAVIGATE'](`/messages/${uuids}`);
    
        // Close the footer modal
        setFooterModalOpen(false);
    };

    const updateNextBatchAlumni = async () => {
        try {
            const response = await axiosClient().get<{ fetchedAlumni: Alumni[] }>(`${process.env.REACT_APP_REPORTS_SERVER}/common/update-next-alumni-batch/${uuid}`);
        } catch (error) {
            console.log(error);
        }
    };

    const openFooterModal = () => {
        setFooterModalOpen(true);
    };
    
    const closeFooterModal = () => {
        setFooterModalOpen(false);
    };

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl,{
                customClass: 'tooltip-html',
                trigger: 'hover'
            });
        });
    }, [state?.data?.selectedUserDetails]);

 

    const tooltipContent = `<ul class='font-500'>
    <li>Program <span class='font-600 d-block text-black'>
        ${state?.data?.selectedUserDetails?.programName || 'NA'}, 
        ${state?.data?.selectedUserDetails?.schoolName || 'NA'}
    </span></li>
    
    <li>Current Company & Designation <span class='font-600 d-block text-black'>
        ${state?.data?.selectedUserDetails?.companyName || 'NA'}, 
        ${state?.data?.selectedUserDetails?.designation || 'NA'}
    </span></li>
    
    <li>Location <span class='font-600 d-block text-black'>
        ${state?.data?.selectedUserDetails?.cityName || 'NA'}, 
        ${state?.data?.selectedUserDetails?.countryName || 'NA'}
    </span></li>
     </ul>`;


     useEffect(() => {
        if (state?.data?.messageMode === 'NEW_MESSAGE') {
          const firstName = state?.data?.memberIds?.[0]?.firstName;
          const lastName = state?.data?.memberIds?.[0]?.lastName;
          
          if (firstName && lastName) {
            setAlumniName(`${firstName} ${lastName}`);
          }
        }
      }, [state]);

    return (
        <div className={`col-md ${(props.isActive || state?.data?.memberIds?.length > 0) ? 'd-block' : 'd-none'} d-md-block`}>
            <div className="alumni_chat_block">
                {/* Render based on messageMode */}
                {state?.data?.messageMode === 'NEW_MESSAGE' && (
                    <div className="alumni_chat_to">
                        <div className="row g-2 align-items-start">
                            <div className="col-sm-auto">
                                {state?.data?.memberIds?.length > 0 && (
                                    <div className="d-flex align-items-center">
                                        <i className="icon-left-arrow me-2 d-md-none" onClick={props.toggleClass}></i>{' '}
                                        <label className="mt-1">Send to:</label>
                                    </div>
                                )}
                            </div>
                            <div className="col-sm">
                                <div className="sent_too_wrap row g-2 align-items-center">
                                    {state?.data?.memberIds?.length > 0 &&
                                        state.data.memberIds.flat().map((receiverUser: any) => (
                                            <div key={receiverUser.value} className="col-auto">
                                                <div className="sent_too">
                                                    <span>{receiverUser.label}</span>
                                                    <i onClick={() => removeReceiver(receiverUser)} className="icon-cross ms-2 font-10"></i>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {state?.data?.messageMode === 'SHOW_CONVERSATION_WITH_USER' && (
                    <><div className="alumni_chat_conversation_header">
                        {/* Render user details */}
                        <i className="icon-left-arrow d-block d-md-none" onClick={props.toggleClass}></i>
                        <div className="alumni-user-leftpane">
                            <span className="img-alumni">
                                <img src={state?.data?.selectedUserDetails?.userImage ?? 'default_image_path.png'} width="60" alt="" />
                            </span>
                            <span className="alumni-name-batch">
                                <span className="alumni-name">{state?.data?.selectedUserDetails?.userName}</span>
                                <span className="alumni-message">{state?.data?.selectedUserDetails?.usertype}</span>
                            </span>
                            
                            {(state?.data?.selectedUserDetails?.usertype !== 'SCHOOL_ADMIN' && userType !== 'INSTITUTE_ADMIN') && (
                            <div className="btn btn-border-gray btn-html ms-3 radius-25 px-3 py-0 font-500" data-bs-toggle="tooltip"
                            data-bs-html="true" title={tooltipContent} > <span>Info</span> </div>)}

                        </div>
                        <div className={`mark-star ${state?.data?.starred_marked === 1 ? 'selected' : ''}`}>
                            <input type="checkbox" id="mark" className="d-none" />
                            <label htmlFor="mark">
                                <i className="icon-star" onClick={handleStarClick}></i>
                            </label>
                        </div>
                    </div>

                    {topAlumni?.length > 0 && loggedInUser.menuType === 'STUDENT' && (
                             <div className="chat_now">
                                <div className="row g-2 align-items-center">
                                    <div className="col-sm font-500">
                                        Chat with more Potential Alumni who Matches your Profile.
                                    </div>
                                    <div className="col-sm-auto">
                                        <a href="javascript:;"  className="btn btn-white mt-0" onClick={fetchTopAlumni}><i className="icon-chat text-green me-2 position-relative"></i><span>Chat Now</span></a>
                                    </div>
                                </div>
                            </div>
                        )}    
                    </>
                    

                )}

                {state?.data?.messageMode === 'SHOW_CONVERSATION_WITH_USER' && (
                    <div className="alumni_chat_conversation_block my-3">
                        <div className='d-flex flex-column h-100 align-items-end'>
                            <div className='mt-auto w-100'>
                            <div className="row g-3 h-100 align-items-end">
                            {messages.map((message: any) => (
                                <div key={message.id} className="col-12">
                                    <div
                                        className={`row ${
                                            message.isFromCurrentUser === 1 ? 'justify-content-end' : 'justify-content-start'
                                        }`}>
                                        <div className="col-11 col-sm-8">
                                            <div className="d-flex flex-column">
                                                {/* {message.isFromCurrentUser === 1 && userType === 'STUDENT' &&  message.isPrivate === 0 && !hasShownPrivateOption ? (
                                                    <div className="d-flex align-items-center justify-content-end mb-1 pe-3">
                                                        <i className="icon-info"></i>
                                                        <label className="form-label mb-0 mx-2">Mark this query as private</label>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            defaultChecked={message.isPrivate === 1}
                                                            onClick={(e) => {
                                                                const target = e.target as HTMLInputElement;
                                                                markQueryPrivateClick(message.id, target.checked);
                                                            }}
                                                        />
                                                    </div>
                                                ) : null} */}
                                                {/* Set the flag after showing the private option */}
                                                {message.isFromCurrentUser === 1 && userType === 'STUDENT' && message.isPrivate === 0 && !hasShownPrivateOption && (hasShownPrivateOption = true)}

                                                <div className="blue-block">{message?.message}</div>
                                                <div className="col-auto">{message?.sentDate}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                            </div>
                        </div>
                        
                    </div>
                )}

                {(state?.data?.messageMode === 'LIST_VIEW' || state?.data?.messageMode === 'NEW_MESSAGE') && (
                    <>
                    

                            
                            <div className="get_started flex-1 d-flex flex-column justify-content-center align-items-center p-4">
                                <i className="get_started_like"></i>
                                <h3>
                                Connect and Chat: 
                                    <br className="d-none d-sm-block" />Engage with the Community Today!
                                </h3>
                            </div>
                            <div className="get_started_prompt p-4">
                                {loggedInUser.userTypeCode === 'STUDENT' && (
                                    <div className="row g-3 justify-content-center">
                                        
                                    <div className="col-12"><p className="text-center font-18 font-500 mb-0">Frequently Asked Questions</p></div>
                                    {promptData.map((item, index) => (
                                     <div key={index} className="col-auto">
                                     <button className={Number(item.parentCode) === 1 ? "highlight" : ""}
                                      onClick={() => sendDefaultMessage(item.code)}>{item.flexCol}
                                     </button>
                                     </div>
                                    ))}
  
                                    </div>
                                )}
                            </div>
                    </>
                )}

                <OneToOneChatBar promptCode={promptCode} setPromptCode={setPromptCode} newMessage={newMessage} setNewMessage={setNewMessage} loggedInUser={loggedInUser} />
                {/* Message input and send button */}
            </div>
            {isFooterModalOpen && (
                            <>
                                
                                    <div
                                    className={`modal fade show vh-100  ${isFooterModalOpen ? 'show' : ''}`}
                                    id="chat-now"
                                    tabIndex={-1}
                                    aria-labelledby="exampleModalLabelvideo"
                                    style={{ display: "block", background: "rgba(0,0,0,0.7)" }}
                                    aria-modal="true"
                                    role="dialog"
                                  >
                                    
                                    <div className="modal-dialog modal-dialog-centered max-1140" role="document">
                                        <div className="modal-content radius-6">
                                            <div className="modal-header ">
                                                
                                                <h5 className="modal-title">Chat With More Potential Alumni Who Matches Your Profile.</h5>
                                                <button
                                                    type="button"
                                                    className="btn-close m-1 position-absolute right20 top20 z9"
                                                    onClick={closeFooterModal}
                                                ></button>
                                            </div>
                                            <div className="modal-body p-4 text-capitalize">
                            
                                                <div className="row g-2 g-md-4 alumni-showcase">
                                                {topAlumni?.map((alum) => (
                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <div className="alumni-bucket">
                                                            <div className="alumni_class_link">
                                                                <div className="alumni_name_detail">
                                                                    <div className="alumni_img">
                                                                    <img
                                                                        src={getStudentImage(alum)}
                                                                        alt={`${alum.name}`}
                                                                    />
                                                                    </div>
                                                                    <div className="alumni_name_brife">
                                                                        <h3><a className="alumni_name_link" href="javascript:;">{`${alum.name}`}</a></h3>
                                                                        <span className="alumni_campus">{`${alum.program_name}`}, {`${alum.pass_out_year}`}</span>
                                                                        
                                                                    </div>
                                                                </div>
                                                               
                                                            </div>
                                                            <CardBottom alumni={alum} state={state} />
                                                            
                                                        </div>
                                                        </div>
                                        
                                                ))}
                                                    
                                                    <div className="col-12 text-center d-flex justify-content-center">
                                                        <button className="btn btn-green" onClick={handleClick}><span>Send to All</span> <i className="icon-alumni-send ms-2 position-relative"></i></button>
                                                    </div>
                                                    
                                  
                                                    
                                  
                                        
                                                </div>
                                               
                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </>
                        )}
        </div>

        
    );
};

export default MessageRightBar;
