import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import AlumniListGrid from './AlumniListGrid';
import Layout from './Layout';

const ManageAlumni = () => {
    const navigate = useNavigate();
    const { globalState } = useContext(GlobalContext);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const updateFormConfigForInternalAdmin = (formConfig: any) => {
        //return formConfig;
        // Update the formConfig dynamically here
        const updatedFormConfig = JSON.parse(JSON.stringify(formConfig));

        // Find the control you want to update
        let searchCriteriaSection = updatedFormConfig.sectionRepository.find((section: any) => section.id === 'searchCriteria');

        if (searchCriteriaSection) {
            let instituteIdControl = searchCriteriaSection.controlGroup.find((control: any) => control.id === 'instituteId');

            if (instituteIdControl) {
                // Change the domainCategoryCode to a new value
                instituteIdControl.props.domainCategoryCode = 'INSTITUTE_DOMAIN'; // Replace with your new domainCategoryCode
                instituteIdControl.type = 'SELECT_V2'; // Replace with your new domainCategoryCode
                instituteIdControl.id = 'instituteId';
            }

            let programIdControl = searchCriteriaSection.controlGroup.find((control: any) => control.id === 'programId');

            if (programIdControl) {
                // Change the domainCategoryCode to a new value
                programIdControl.props.domainCategoryCode = 'ALL_PROGRAM_CODE'; // Replace with your new domainCategoryCode
                programIdControl.props.parentId = 'instituteId';
                programIdControl.type = 'MULTI_SELECT_CUSTOM_CONTROL_2';
                //programIdControl.type = 'SELECT_V2'; // Replace with your new domainCategoryCode
            }
        }
        return updatedFormConfig;
    };
    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;

    const updatedformCondif = updateFormConfigForInternalAdmin(formConfig);

    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        // domain.set('INSTITUTE_PROGRAM_CODE', internal?.programDomainData ?? []);

        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig: updatedformCondif, data, domain, internal, routeInfo },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <DebugBarControl />
            {!state.flags.isDataLoading && (
                <Layout>
                    {/* Please check layout for the <SearchCriteria /> */}
                    <AlumniListGrid />
                </Layout>
            )}
        </SmartContext.Provider>
    );
};

export default ManageAlumni;
