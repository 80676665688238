import { useEffect, useState } from 'react';

const GraphComponent = ({ data, maxCount, mapLowValue, mapAdjustmentPercentage, showAll, color }) => {
    const [mode, setMode] = useState(showAll);
    const [graphData, setGraphData] = useState(data);
    const [graphItems, setGraphItems] = useState([]);

    useEffect(() => {
        setGraphData(data);
    }, [data]);

    useEffect(() => {
        const preparedData = prepareGraphData(graphData, mode);
        setGraphItems(renderGraphItems(preparedData, mode));
    }, [graphData, mode]);

    const prepareGraphData = (array, mode) => {
        if (mode === 'ALL') return array.sort((a, b) => b.value - a.value);

        const sortedArray = array.sort((a, b) => b.value - a.value);
        const topSeven = sortedArray.slice(0, 7);

        if (sortedArray.length > 7) {
            const remainingCount = sortedArray.slice(7).reduce((total, item) => total + item.value, 0);
            const otherItem = { value: remainingCount, key: 'Other' };
            return [...topSeven, otherItem];
        }

        return [...topSeven];
    };

    const renderGraphItems = (graphData, mode) => {
        const graphItems = [];

        graphData.forEach((graph, index) => {
            const displayStyle = index > 7 && mode !== 'ALL' ? 'none' : 'block';
            const className = index > 7 && mode !== 'ALL' ? 'loadclass' : '';

            const count = graph.value;

            let barHeight = (count / maxCount) * 100;
            if (count <= mapLowValue) {
                barHeight = mapAdjustmentPercentage;
            }
            if (barHeight < 8) {
                barHeight = 8;
            }

            graphItems.push(
                <div key={graph.key} className={`nwsd verticleProgress ${className}`}>
                    <div className="graphContainer width-97">
                        <div className="progress d-flex flex-column justify-content-end bg-white height-217 ">
                            <div
                                data-toggle="tooltip"
                                title={count}
                                data-original-title="14"
                                className={`progress-bar ${color ?? 'dark-blue'} justify-content-center`}
                                role="progressbar"
                                style={{ height: `${barHeight}%` }}
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax={maxCount}>
                                {count}
                            </div>
                        </div>
                    </div>
                    <label className="caption mt-3">
                        <span className="font-16 font-500">{graph.key}</span>
                    </label>
                </div>
            );
        });

        return graphItems;
    };

    const handleViewAll = (e) => {
        e.preventDefault();
        setMode(mode === 'ALL' ? 'TOP' : 'ALL');
        setGraphData([...data]);
    };

    return (
        <>
            <div className="horizontalScrol">
                <div className="bigVerticalProgress">{graphItems}</div>
            </div>
            {graphData.length > 7 && (
                <div className="text-center mt-3 mb-4">
                    <button href="" className="btn-border-gray font-14 max-190 " onClick={handleViewAll}>
                        <span className="position-relative ">{mode === 'ALL' ? 'View Less' : 'View All'}</span>
                    </button>
                </div>
            )}
        </>
    );
};

export default GraphComponent;
