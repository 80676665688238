import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SmartContext } from '../../../library/Core/SmartContext';
import { ControlOnChangeArguments } from '../../../library/Core/SmartTypes';
import WidgetConfigurationLayout from '../WidgetConfigurationLayout';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { getAssociativeArray, isEmpty, triggerGridDataOnChange } from '../../../library/Core/SmartFunctions';

const TopIndustries = () => {
    const { state, dispatch } = useContext(SmartContext);
    let {id, code} = useParams();

    const isEditing = !!id;
    if ( typeof code == 'undefined') {
        code = '';
    }

    useEffect(() => {
        dispatch({ type: 'ADD_ACTIONS', payload: { ...state.actions, metricEntity: onInputChange, value: onInputChange, metricType: onInputChange } });
    }, []);

    useEffect(() => {
        if ( isEditing ) {
            return;
        }

        const allowedWidgets = ['top_industries']

        if (!code || !state?.internal?.[code] || !allowedWidgets.includes(code)) {
            return;
        }
        console.log(state.data, " STATE DATA ")

        let ctr = 0;
        const metricData = state?.data?.metricData;
        console.log(metricData, " metricData ");

        if (!Array.isArray(metricData)) {
            console.error('metricData is not an array', metricData);
            return;
        }

        const updatedMetricData = metricData.map(item => ({ ...item }));
        state.internal[code].forEach((rec: any, idx: any) => {
            if (rec?.metricEntityCode == 0 || ctr >= 4) return;
            if (updatedMetricData[ctr]) {
                updatedMetricData[ctr]['id'] = idx;
                updatedMetricData[ctr]['metricEntity'] = rec?.metricEntityCode;
                ctr++;
            } else {
                updatedMetricData[ctr] = {
                    "id": idx,
                    "metricEntity": rec?.metricEntityCode
                }
                ctr++;
            }
        });

        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {...state.data, metricData: updatedMetricData}
            },
        });

        setTimeout(() => {
            triggerGridDataOnChange(updatedMetricData, "metricData", "metricEntity");
        }, 500);

    }, [state.internal.top_industries])

    function onInputChange(props: ControlOnChangeArguments) {
        if ( isEmpty(props.state.internal) ) {
            return false;
        }

        let metricEntity = props?.value;
        let value = (!isNaN(props?.value)  && props?.value > 0) ? props?.value : 0;
        let metricType= props?.value;
        
        if (props.control.id != 'metricType') {
            metricType = props?.row?.metricType;
        }

        if (props.control.id != 'value') {
            value = props?.row?.value;   
        }
        
        if (props.control.id != 'metricEntity') {
            metricEntity = props?.row?.metricEntity;   
        }

        if (!code || !props?.state?.internal[code]) {
            return;
        }

        const errorMessages = [] as any[];
        let countData = getAssociativeArray(props.state?.internal?.[props.state?.internal?.widget_code], 'metricEntityCode');
        const totalAlumniCount = props.state?.internal?.[code].reduce((sum: any, item: any) => sum + item.userCount, 0);

        let currentCareerUserCount = countData[metricEntity]?.userCount ?? 0;
        let currentPercentage: any = 0;
        let totalPercentage: any = 0;
        
        currentPercentage = Math.round((currentCareerUserCount/totalAlumniCount)*100)

        if (metricType == 'override') {
            currentPercentage = (!isNaN(value)  && value > 0) ? value : 0;
        }

        currentPercentage = parseFloat(currentPercentage);

        props.state.data?.metricData?.map((data: any) => {
            if ( ! isEmpty(data?.metricEntity) && ! isEmpty(data?.value) && data?.metricEntity != metricEntity ) {
                totalPercentage += parseFloat(data.value);
            }
        })

        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: `${props.parentDataKey}.value`,
                value: currentPercentage,
            },
        });

        totalPercentage += currentPercentage;
        let remainingValue: any = 100-parseFloat(totalPercentage); // calculating other career percentage;
        let remainingValueDisp = remainingValue%1 == 0 ? remainingValue.toFixed(0) : remainingValue.toFixed(2); // calculating other career percentage;

        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: `widgetData.other`,
                value:  remainingValueDisp
            },
        });
    };
    
    return (
        <WidgetConfigurationLayout state={state} >{!state.flags.isDataLoading && <PageBuilder />}</WidgetConfigurationLayout>
    );
};

export default TopIndustries;