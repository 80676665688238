import {  Tooltip } from 'bootstrap';
import React,{useEffect} from 'react';
import moment from 'moment';


const ViewLeadHistoryModal = ({data}:any) => {
    const { alumniData, viewSentHistory,getValuableQaModal}=data;
   
    useEffect(() => {
      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    });


    const adminNameList = (row: any) => {
       const mainarr= (row.admin_name==null) ? '' : row.admin_name.split(',');
       const firstTwoItems = mainarr.slice(0, 2); // Get the first two items
       const remainingItems = mainarr.slice(2);

      const firsttwoitems_html = Array.isArray(firstTwoItems) && firstTwoItems.map((item:any, index:any) => {
              return (
                  <li style={{ listStyleType: 'none' }} key={index}>
                    {item}
                  </li>
                );
        });
        
      //  const listItems = remainingItems.map((exam:any) => `<li>${exam}</li>`).join('');
      const listItems = Array.isArray(remainingItems) && remainingItems.map((item:any,index:any) => {
            return `<li>${item}</li>`;
        }).join('');

       let secondSpan = null; 
       if (listItems) {
          const html_inner = `<ol>${listItems}</ol>`;
          secondSpan = (<span>
              <a className="btn-html" href="javascript:;" data-bs-toggle="tooltip" data-bs-html="true" data-bs-original-title={html_inner}>
                  &amp; {remainingItems.length} More</a></span>
          );
      }
      const exam = (
        <span>{firsttwoitems_html}{secondSpan}</span>
      );
      return exam;
    };

return (
    <div className="modal fade px-3" id="view-lead-history" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" style={{ display: 'none' }} aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered max-800 mx-auto">
      <div className="modal-content">
        <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
          <h5 className="modal-title" id="staticBackdropLabel">View Send History</h5>
          <button type="button" className="btn-close btn-close-small" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body p-4">
          <form className="needs-validation" noValidate>
            <div className="row g-4">
              <div className="col-md-4">
                <label className="form-label">Name</label>
                <input type="text" className="form-control flex-1" id="skills" value={alumniData?.AlumniName} disabled />
              </div>
              <div className="col-md-4">
                <label className="form-label">Mobile</label>
                <input type="text" className="form-control flex-1" id="skills"  value={alumniData?.AlumniMobile}  disabled />
              </div>
              <div className="col-md-4">
                <div className="form-group mb-0">
                  <label className="form-label">Last Sent</label>
                  <div className="form-last-sent">
                  {alumniData?.LastSentDate ? (
                            <>
                              <a href="javascript:;">{alumniData?.ProfileSendUsers.length} users</a> on {moment(alumniData?.LastSentDate).format('DD-MMM-YYYY')}
                            </>
                          ) : (
                            <span>NA</span>
                          )}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <label className="form-label">User ID</label>
                <input type="text" className="form-control flex-1" id="skills" value={alumniData?.AlumniUserId} disabled  />
              </div>
              <div className="col-md-4">
                <label className="form-label">Email</label>
                <input type="text" className="form-control flex-1" id="skills"  value={alumniData?.AlumniEmail} disabled />
              </div>
              <div className="col-md-12 mb-4">
                <div className="view-vertical">
                  <div className="table-responsive">
                    <table className="table w-750" style={{ minWidth: '750px' }}>
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: '130px' }}>Sent on</th>
                          <th scope="col" style={{ width: '150px' }}>Sent by</th>
                          <th scope="col" style={{ width: '200px' }}>Recipients</th>
                          <th scope="col">Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                      {Array.isArray(viewSentHistory) &&
                            viewSentHistory
                              .map((item: any,index) => (                           
                           <tr key={item.id || index}>
                            <td>{moment(item?.created_at).format('DD-MMM-YYYY')}</td>
                            <td>{item?.added_by}</td>
                            {/* <td>
                              <div className="d-flex flex-column">
                                <span>{item?.admin_name}</span>
                              </div>
                            </td> */}

                            <td>
                            <div className="d-flex flex-column">{adminNameList(item)}</div>
                            </td>

                            <td>
                              <div className="d-flex flex-wrap align-items-center">
                                <div className="me-3 font-500">
                                 {item?.sending_reason.replace('_',' ')}
                                 {item.sending_reason === 'Others' ? (
                                  <span>- { item?.comment}</span> 
                                 ) : null }
                                </div>
                                {item?.sending_reason === 'Valuable_Q&A' ? (
                                        <button type="button" className="btn btn-log" onClick={() => getValuableQaModal(item?.id)}>View Details</button>
                                ) : null}
                              </div>
                            </td>
                        </tr>
                         ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
                  <a href="javascript:;" className="btn-good-qa" data-bs-toggle="modal">Close</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
    );
  };

  export default ViewLeadHistoryModal;