import { useContext, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';

import { Modal } from 'bootstrap';
import groupImage from '../../../../src/styles/images/group-43080.svg';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import PeerReviewService from '../../alumni_review/PeerReviewService';
import AlumniCountryReport from './AlumniCountryReport';
import AlumniOnboarding from './AlumniOnboarding';
import AlumniPresence from './AlumniPreference';
import AlumniPresentStatus from './AlumniPresentStatus';
import DashboardAccordionItem from './DashboardAccordianItem';
import ReportLayoutComponent from './ReportLayoutComponent';
import StudentCareerReport from './StudentCareerReport';
import StudentCountryReport from './StudentCountryReport';
import StudentOnboarding from './StudentOnboarding';
import useManagementDashboard from './useManagementDashboard';

const ManagementDashboard = () => {
    const { globalState } = useContext(GlobalContext);
    const { getCampuses } = useManagementDashboard();

    const [alumniJoined, setAlumniJoined] = useState(0);
    const [popupVisible, setPopupVisible] = useState(false);

    const loadInitialData = async () => {
        if (sessionStorage.getItem('peer-display') == 'done') {
            return false;
        }
        // Perform API calls using axiosClient to check if pending account request
        try {
            const userToApprove = await PeerReviewService.get();

            if (userToApprove.data.length > 0) {
                setAlumniJoined(userToApprove.data.length);
                const modalElement = document.getElementById('jobDescriptionModal');
                if (modalElement) {
                    const modal = new Modal(modalElement);
                    modal.show();
                    sessionStorage.setItem('peer-display', 'done');
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        loadInitialData();
    }, []);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { formConfig, domain, data, internal, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        data.campuses = getCampuses();
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain: new Map([...globalState.domain]), internal, routeInfo },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <div
                className="modal fade"
                id="jobDescriptionModal"
                tabIndex={-1}
                aria-labelledby="jobDescriptionModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered max-700 mx-auto">
                    <div className="modal-content">
                        <div className="modal-header border-0 pt-5">
                            <h5 className="modal-title font-24 text-dark-blue text-center w-100" id="staticBackdropLabel">
                                Your immediate attention required!!
                            </h5>
                            <button type="button" className="btn-close btn-close-small" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-0 px-md-5 pb-4 pb-sm-5">
                            <div className="placeholder-img text-center my-4">
                                <img src={groupImage} className="img-fluid" width="168" />
                            </div>
                            <div className="max-470 mx-auto">
                                <p className="text-center">
                                    <span className="font-600 text-dark-blue"> {alumniJoined} Newly joined</span> users are requesting for
                                    their accounts to be fully activated on this network as students and alumni of your school.
                                </p>
                                <p className="text-center">Help us speed up the process, by validating the requests.</p>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center py-3">
                                <a href="/alumni-review/internal" className="btn-green max-190 mx-auto">
                                    <span>View Requests</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!state.flags.isDataLoading && (
                <ReportLayoutComponent>
                    <div className="accordion dashboardAccordion" id="accordionPanelsStayOpenStudent">
                        {state.data.selectedTab === 'ALUMNI' && (
                            <DashboardAccordionItem
                                reportKey={'ALUMNI_ONBOARDING'}
                                tooltipTxt="Here's a snapshot of all onboarded alumni as per your selected duration">
                                <AlumniOnboarding />
                            </DashboardAccordionItem>
                        )}

                        {state.data.selectedTab === 'STUDENT' && (
                            <DashboardAccordionItem
                                reportKey={'STUDENT_ONBOARDING'}
                                tooltipTxt="Take a look at the number of students joining the student dashboard in your selected year">
                                <StudentOnboarding />
                            </DashboardAccordionItem>
                        )}

                        {state.data.selectedTab === 'STUDENT' && (
                            <DashboardAccordionItem
                                reportKey={'STUDENT_COUNTRY'}
                                tooltipTxt="Get an idea where your students prefer to study the most!">
                                <StudentCountryReport />
                            </DashboardAccordionItem>
                        )}

                        {state.data.selectedTab === 'STUDENT' && (
                            <DashboardAccordionItem
                                reportKey={'STUDENT_CAREER'}
                                tooltipTxt="Take a look at the popular careers that your students have chosen!">
                                <StudentCareerReport />
                            </DashboardAccordionItem>
                        )}

                        {state.data.selectedTab === 'ALUMNI' && (
                            <DashboardAccordionItem
                                reportKey={'ALUMNI_PRESENCE'}
                                tooltipTxt="From prestigious colleges or non-prestigious colleges, STEM or non-STEM, take a look at your alumni's choices and presence in the world">
                                <AlumniPresence />
                            </DashboardAccordionItem>
                        )}

                        {state.data.selectedTab === 'ALUMNI' && (
                            <DashboardAccordionItem
                                reportKey={'ALUMNI_PRESENT_STATUS'}
                                tooltipTxt="Here's how your alumni are placed in the present day!">
                                <AlumniPresentStatus />
                            </DashboardAccordionItem>
                        )}

                        {state.data.selectedTab === 'ALUMNI' && (
                            <DashboardAccordionItem
                                reportKey={'ALUMNI_COUNTRY'}
                                tooltipTxt="Where are your alumni based? Take a look at the countries where you can find your alumni">
                                <AlumniCountryReport />
                            </DashboardAccordionItem>
                        )}
                    </div>
                </ReportLayoutComponent>
            )}
            <DebugBarControl />
        </SmartContext.Provider>
    );
};

export default ManagementDashboard;
