import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../../library/Core/SmartContext';
import smartReducer from '../../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../../library/Service/domainService';
import { handleCancel, handleUpdate } from './AdminPreferencesActions';
import AdminPreferencesLayout from './AdminPreferencesLayout';

const AdminPreferences = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { UPDATE: handleUpdate, CANCEL: handleCancel, ROUTER_NAVIGATE: navigate },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        data['alumniProfile'] = data['alumniProfile'] || {};
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <AdminPreferencesLayout>{!state.flags.isDataLoading && <PageBuilder />}</AdminPreferencesLayout>
        </SmartContext.Provider>
    );
};

export default AdminPreferences;
