import { useContext } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';

const SignupSocialLayout = (props: any) => {
    const schoolSettings = localStorage.getItem('school_settings');
    const parsedSchoolSettings = schoolSettings ? JSON.parse(schoolSettings) : null;
    const instituteId = parsedSchoolSettings?.id;
    const { state, dispatch } = useContext(SmartContext);

    // const schoolName = 'Podar International School';
    const schoolName = getDomainValueForCode(instituteId,'INSTITUTE_DOMAIN',state);
    
return (
    <div className="main flex-1">
        <section className="bg-white container-fluid alumni_profile_brife_wrap" id="alumni_profile_brife_wrap">
            <div className="max-1140 mx-auto alumni_profile_brife">
                <div className="row">
                    <div className="col-12">
                        <div className="row g-4 align-items-center">
                            <div className="col-auto">
                                <div className="welcome-user-image ">
                                    {state?.data?.user?.profilePicture ? <img src={state?.data?.user?.profilePicture} width={100} /> : <i className="icon-user"></i>}
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="welcome-title aos-init aos-animate" data-aos="fade-right">
                                    <h1 className="mb-0">Hi {state?.data?.user?.firstName} {state?.data?.user?.lastName} </h1>
                                    <p className="font-24 font-600">Welcome to {schoolName} Alumni Network!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="alumni_profile_detail_wrap container-fluid" id="alumni_profile_detail_wrap">
            <div className="max-1140 mx-auto alumni_profile_detail">
                <div className="tab-content w-100" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-underGraduate" role="tabpanel" aria-labelledby="pills-underGraduate-tab">
                        <div className="underGraduate-detail mb-4">
                            <div className="form needs-validation max-600 mx-auto mt-5" id="login-form">
                                <div className="container-fluid p-0">
                                    <div className="row g-4">
                                    {state?.businessValidationErrors?.length && (
                                        <div
                                            className={`bg ${state?.businessValidationErrors[0].success ? 'success-message' : 'fail-message'}`}>
                                            {state?.businessValidationErrors?.map((item: any) => (
                                                <p key={item.message}>{item.message}</p>
                                            ))}
                                        </div>
                                    )}
                                    {props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    );
};

export default SignupSocialLayout;
