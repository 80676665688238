import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import { isEmpty, isValidBusinessObject, isFormValid, setError } from '../../../../library/Core/SmartFunctions';
import { axiosClient } from '../../../../library/Service/axiosClient';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';
import axios from 'axios';

export const handleSave = (
    // event: React.SyntheticEvent<HTMLButtonElement>,
    // state: State,
    // dispatch: (dispatchEvent: DispatchEvent) => void

    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    // if (state.data.educationPreferenceInfo.doYouRequireApplicationSupportForUgAdmission === true && !customValidationPassed) {
    if (!customValidationPassed) {
        // alert('Please correct the form errors!');
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state.formValidationErrors);
        return;
    }

     // Send event to Moengage student preference
     sendMoEngageEvent(state?.data);

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            educationPreferenceInfo: state?.data['educationPreferenceInfo'],
            educationCountryPreferences: state?.data['educationCountryPreferences'],
            educationFieldOfStudyPreferences: state?.data['educationFieldOfStudyPreferences'],
            educationUniversityPreferences: state?.data['educationUniversityPreferences'],
        })
    ).then(async () => {
        const resp = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/profile/update-student-status`);
        if (resp?.status == 200) {
            // sessionStorage.setItem('user-profile-status-code', 'COMPLETED');
            // let userData = JSON.parse(localStorage.getItem('univariety-user')??'');
            // if (userData) {
            //     userData.profileStatusCode = 'COMPLETED';
            //     // localStorage.setItem('univariety-user', JSON.stringify(userData));
            // }
        }
        navigateToNextStep(AlumniProfileEditScreens.STUDENT_PREFERENCE, state, dispatch, sessionState, sessionDispatch);
        // state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile/preference`);
    });
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile/parent-info`);
};

export const isFormValidBasedOnCustomValidation = (state: State, dispatch: any) => {
    let isFormValid = true;
    let countryValid = false;

    if (state?.data?.educationPreferenceInfo?.isCountryChoiceSkipped == 1) {
        countryValid = true;
    } else {
        state?.data['educationCountryPreferences']?.map((country: any, index: number) => {
            if (!isEmpty(country?.countryCode)) {
                countryValid = true;
                return;
            }
        });
    }

    let hasDuplicateCountryCodes = false;
    if (countryValid) {
        // Duplicate country validation
        const countryCodeArray = state?.data['educationCountryPreferences'].map((country: any) => country.countryCode);
        // checks if there is any element in the countryCodeArray that has a different index
        hasDuplicateCountryCodes = countryCodeArray.some(
            (countryCode: string, idx: number) => countryCode && countryCodeArray.indexOf(countryCode) !== idx
        );

        if (hasDuplicateCountryCodes) {
            countryValid = false;
            setError(
                `educationCountryPreferences`,
                ['Duplicate Country: Country already exists. Please enter unique Countries.'],
                dispatch
            );
        }
    } else {
        // setError(`educationCountryPreferences.0.countryCode`, ['Please select country'], dispatch);
        setError(`educationCountryPreferences`, ['Please select country'], dispatch);
    }

    countryValid = countryValid && !hasDuplicateCountryCodes;
    if (countryValid) {
        setError('educationCountryPreferences', [], dispatch);
    }

    // checking department validation;
    let careerValid = false;
    if (state?.data?.educationPreferenceInfo?.isCareerChoiceSkipped == 1) {
        careerValid = true;
    } else {
        state?.data['educationFieldOfStudyPreferences']?.map((career: any, index: number) => {
            if (!isEmpty(career?.fieldOfStudyCode)) {
                careerValid = true;
                return;
            }
        });
    }

    let hasDuplicateCareerCodes = false;
    if (careerValid) {
        // Duplicate career validation
        const careerCodeArray = state?.data['educationFieldOfStudyPreferences'].map((career: any) => career.fieldOfStudyCode);
        // checks if there is any element in the careerCodeArray that has a different index
        hasDuplicateCareerCodes = careerCodeArray.some(
            (fieldOfStudyCode: string, idx: number) => fieldOfStudyCode && careerCodeArray.indexOf(fieldOfStudyCode) !== idx
        );

        if (hasDuplicateCareerCodes) {
            setError(
                `educationFieldOfStudyPreferences`,
                ['Duplicate Career: Career already exists. Please enter unique courses.'],
                dispatch
            );
        }
    } else {
        setError(`educationFieldOfStudyPreferences`, ['Please select course'], dispatch);
    }

    careerValid = careerValid && !hasDuplicateCareerCodes;
    if (careerValid) {
        setError('educationFieldOfStudyPreferences', [], dispatch);
    }

    // checking university validation;
    let universityValid = false;

    if (state?.data?.classCode == 11 || state?.data?.classCode == 12) {
        if (state?.data?.educationPreferenceInfo?.isCollegeChoiceSkipped == 1) {
            universityValid = true;
        } else {
            state?.data['educationUniversityPreferences'].map((university: any, index: number) => {
                if (!isEmpty(university?.universityId)) {
                    universityValid = true;
                    return;
                }
            });
        }

        let hasDuplicateUniversity = false;
        if (universityValid) {
            // Duplicate university validation
            const universityIdArray = state?.data['educationUniversityPreferences'].map((university: any) => university.universityId);
            // checks if there is any element in the universityIdArray that has a different index
            hasDuplicateUniversity = universityIdArray.some(
                (universityId: string, idx: number) => universityId && universityIdArray.indexOf(universityId) !== idx
            );

            if (hasDuplicateUniversity) {
                setError(
                    `educationUniversityPreferences`,
                    ['Duplicate College: College already exists. Please enter unique colleges.'],
                    dispatch
                );
            }

            universityValid = universityValid && !hasDuplicateUniversity;
            if (universityValid) {
                setError('educationUniversityPreferences', [], dispatch);
            }
        } else {
            setError(`educationUniversityPreferences`, ['Please select college'], dispatch);
        }
    } else {
        universityValid = true;
        setError(`educationUniversityPreferences`, [], dispatch);
    }

    isFormValid = countryValid && careerValid && universityValid;

    return isFormValid;
};

const sendMoEngageEvent = async (user:any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-student-preference`, {
            userInfo       : user
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return false;
  };