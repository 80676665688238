import React, { useContext, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/css_pre_singup/style.css';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';

const ReferrenceOtp: React.FC = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);

    const schoolImage = localStorage.getItem('school-image') || sessionStorage.getItem('school-image');
    const groupId = localStorage.getItem('GroupId') || sessionStorage.getItem('Group-Id');
    const instId = localStorage.getItem('Inst-ID') || sessionStorage.getItem('Inst-ID');
    const lead_ref_id = localStorage.getItem('lead_ref_id') || sessionStorage.getItem('lead_ref_id');

    const instName = localStorage.getItem('school-name') || sessionStorage.getItem('school-name')
 
    const { schoolDomain } = useParams<{ schoolDomain: string }>();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const referalInsertId = queryParams.get('referalId'); // Retrieve referalInsertId from URL

    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        otp: '',
    });
    const [formErrors, setFormErrors] = useState({
        email: '',
        firstName: '',
        lastName: '',
        otp: '',
    });
    const [apiMessage, setApiMessage] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [referralInsertId, setReferralInsertId] = useState<string | null>(null); // Store referralInsertId

    const validateStepOne = () => {
        let errors = { email: '', firstName: '', lastName: '', otp: '' };
        let isValid = true;

        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'A valid email is required';
            isValid = false;
        }
        if (!formData.firstName) {
            errors.firstName = 'First name is required';
            isValid = false;
        }
        if (!formData.lastName) {
            errors.lastName = 'Last name is required';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const validateStepTwo = () => {
        let errors = { email: '', firstName: '', lastName: '', otp: '' };
        let isValid = true;

        if (!formData.otp || !/^\d{6}$/.test(formData.otp)) {
            errors.otp = 'A valid 6-digit OTP is required';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleSendOtp = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (validateStepOne()) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/referral/add-referral`, {
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    email_id: formData.email,
                    institute_id: instId,
                    institute_group_id: groupId,
                    lead_ref_id: lead_ref_id,
                });
                setReferralInsertId(response.data.referralInsertId); // Store the ID in state
                setApiMessage(response.data.message);
            } catch (error) {
                console.error('Error submitting referral:', error);
                setApiMessage('An error occurred while submitting the referral.');
            }

            try {
                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/referral/add-otp`, {
                    email_id: formData.email,
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    instLogo: schoolImage,
                    instName: instName,
                });
                setApiMessage(response.data.message);
                setOtpSent(true);
                setTimeout(() => {
                    setApiMessage('');
                }, 2000);
            } catch (error) {
                console.error('Error sending OTP:', error);
                setApiMessage('Error sending OTP');
                setTimeout(() => {
                    setApiMessage('');
                }, 2000);
            }
        }
    };

    const handleVerifyOtp = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (validateStepTwo()) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/referral/varify-otp`, {
                    otp: formData.otp,
                });
                setApiMessage(response.data.message);
                setTimeout(() => {
                    // Redirect to the page with referralInsertId in the URL
                    if (referralInsertId) {
                        window.location.href = `/reference/${schoolDomain}/verify-progress`;
                    }
                }, 2000);
            } catch (error) {
                console.error('Error verifying OTP:', error);
                setApiMessage('Invalid OTP');
                setTimeout(() => {
                    setApiMessage('');
                }, 2000);
            }
        }
    };

    return (
        <main className="wrapper">
            <section className="dark-section student-img d-flex">
                <div className="container d-flex flex-column">
                    <div className="logo">
                        <a href="#" className="h-100">
                            <img src={schoolImage || 'images/logo.svg'} className="img-fluid" alt="logo" />
                        </a>
                    </div>
                    <div className="flex-1 py-5 d-flex flex-column justify-content-center align-items-center text-white py-5">
                        <div className="id-title text-center">Thank you!</div>
                        <div className="sep blue"></div>
                        <div className="pb-5 w-100 max-600">
                            <form className="min-height-250 max-600 mx-auto" onSubmit={handleSendOtp}>
                                {!otpSent ? (
                                    <>
                                        <div className="row g-4 align-items-end">
                                            <div className="col-12 text-center font-24 font-500">
                                                Wish to get updates about the progress of <br className="d-none d-md-block" />
                                                your referred candidate, and earn some rewards?
                                            </div>
                                            <div className="col-12">
                                                <input
                                                    type="email"
                                                    className="form-control height-46 border-0"
                                                    id="email"
                                                    placeholder="Email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                {formErrors.email && <small className="text-danger">{formErrors.email}</small>}
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control height-46 border-0"
                                                    id="firstName"
                                                    placeholder="First Name"
                                                    value={formData.firstName}
                                                    onChange={handleChange}
                                                />
                                                {formErrors.firstName && <small className="text-danger">{formErrors.firstName}</small>}
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control height-46 border-0"
                                                    id="lastName"
                                                    placeholder="Last Name"
                                                    value={formData.lastName}
                                                    onChange={handleChange}
                                                />
                                                {formErrors.lastName && <small className="text-danger">{formErrors.lastName}</small>}
                                            </div>
                                            <div className="col-12">
                                                <div className="row flex-column align-items-center g-3">
                                                    <div className="col-auto">
                                                        <button type="submit" className="btn-green">
                                                            <span>Send OTP</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="col-md-5 col-sm-5 col-4 mx-auto">
                                            <input
                                                type="text"
                                                className="form-control height-46 border-0"
                                                id="otp"
                                                placeholder="Enter OTP"
                                                value={formData.otp}
                                                onChange={handleChange}
                                            />
                                            {formErrors.otp && <small className="text-danger">{formErrors.otp}</small>}
                                        </div>
                                        <div className="col-auto text-center mt-4">
                                            <button className="btn-green mx-auto" onClick={handleVerifyOtp}>
                                                <span>Verify OTP</span>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </form>
                            {apiMessage && <div className="alert alert-info mt-4">{apiMessage}</div>}
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center text-white pt-5 pb-3 d-none d-md-flex">
                        <div className="col-auto">Powered by</div>
                        <div className="col-auto">
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/univariety-white-logo.svg"
                                width="167"
                                alt="Univariety"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="white-section d-block d-md-none">
                <div className="container py-5">
                    <div className="text-center">Powered by</div>
                    <div className="text-center mb-2">
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/univariety-dark-logo.svg"
                            width="167"
                            alt="Univariety"
                        />
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ReferrenceOtp;
