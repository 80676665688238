import { useParams } from "react-router-dom";

const WidgetLayout = (props: any) => {
    const { code } = useParams();

    return (
        <div className="main flex-1">
            <style>
               {`
                    input[disabled] {
                        cursor: not-allowed;
                    }
                    .modal {
                        background: rgba(0, 0, 0, 0.8);
                    }
                    .modal-backdrop{display:none !important}
               `}
            </style>
            <section className="updatebasicinformation">
                <div className="container max-1140 px-lg-0 overflow-hidden">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius p-0 h-100">
                                <div className="border-bottom border-2 custom-border-gray p-3 p-sm-4 pb-3">
                                    <div className="d-flex flex-wrap justify-content-between align-items-center mx-0 mx-sm-1">
                                        <h1 className="font-26 font-600 my-0 my-sm-1 py-2">
                                            Manage Widgets <i className="icon-right-arrow font-16 position-relative me-1"></i> {props?.state?.internal?.widget_name}
                                        </h1>
                                    </div>
                                </div>

                                <div className="row m-0 pt-4 mt-2 JobInternshipListing">
                                    <div className="flex-1 polarcontsec tab-content" id="myTabContent">
                                        <form className="lstCustForm w-100 formrowpx mt-2 max-700 mx-auto">
                                            <div className="row g-4">
                                                {props.children}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default WidgetLayout;
