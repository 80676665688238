import React, { useContext, useEffect, useState, ChangeEvent, FormEvent } from 'react';
import { axiosClient } from '../../../../../../library/Service/axiosClient';
import { SmartContext } from '../../../../../../library/Core/SmartContext';
import PageBuilder from '../../../../../../library/Builders/PageBuilder/PageBuilder';
import AddGuidanceVideoLayout from './AddGuidanceVideoLayout';
import { DispatchEvent, State } from '../../../../../../library/Core/SmartTypes';
import { getDomainValueForCode, getFlexColDomainValueForCode, isEmpty, isFormValid, setError } from '../../../../../../library/Core/SmartFunctions';

const EditProfileInformationModal = ({ data }: any) => {
    const { state, dispatch } = useContext(SmartContext);

    const handleSave = async (
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void
    ) => {
        event.preventDefault();

        const isnotvalidTags = isTagsNotValid(state, dispatch);
        if (!isFormValid(state, dispatch) || isnotvalidTags) {
            dispatch({ type: 'SHOW_ERRORS' });
            return;
        }
        const link = `${process.env.REACT_APP_MEMORIES_GUIDANCE_SERVER}/guidance/add-new`;
        let tags:any = [];
        let guidance_video = state?.data?.guidanceVideo;
        let instituteId;
        if(!isEmpty(guidance_video?.instituteId)){
            instituteId=guidance_video?.instituteId
        }else{
            instituteId=state?.data?.userInstitutes[0]?.instituteId
        }

        const flexCol = getFlexColDomainValueForCode(instituteId, 'INSTITUTE_DOMAIN', state);
        const institute_group_id = isEmpty(flexCol) ? '' : JSON.parse(flexCol).institute_group_id;

        tags = [
            ...(guidance_video?.achievements_tags === undefined ? [] : guidance_video?.achievements_tags?.map((item:any) => item.achievements_tags)),
            ...(guidance_video?.alumni_guidance_tags === undefined ? [] : guidance_video?.alumni_guidance_tags?.map((item:any) => item.alumni_guidance_tags)),
            ...(guidance_video?.board_exams_tags === undefined ? [] : guidance_video?.board_exams_tags?.map((item:any) => item.board_exams_tags)),
            ...(guidance_video?.career_guidance_tags === undefined ? [] : guidance_video?.career_guidance_tags?.map((item:any) => item.career_guidance_tags)),
            ...(guidance_video?.college_admission_tags === undefined ? [] : guidance_video?.college_admission_tags?.map((item:any) => item.college_admission_tags)),
            ...(guidance_video?.college_life_tags === undefined ? [] : guidance_video?.college_life_tags?.map((item:any) => item.college_life_tags)),
            ...(guidance_video?.country_tags === undefined ? [] : guidance_video?.country_tags?.map((item:any) => item.country_tags)),
            ...(guidance_video?.course_selection_tags === undefined ? [] : guidance_video?.course_selection_tags?.map((item:any) => item.course_selection_tags)),
            ...(guidance_video?.curriculum_tags === undefined ? [] : guidance_video?.curriculum_tags?.map((item:any) => item.curriculum_tags)),
            ...(guidance_video?.entrance_exam_tags === undefined ? [] : guidance_video?.entrance_exam_tags?.map((item:any) => item.entrance_exam_tags)),
            ...(guidance_video?.importance_of_alumni_guidance_tags === undefined ? [] : guidance_video?.importance_of_alumni_guidance_tags?.map((item:any) => item.importance_of_alumni_guidance_tags)),
            ...(guidance_video?.pandemic_tags === undefined ? [] : guidance_video?.pandemic_tags?.map((item:any) => item.pandemic_tags)),
            ...(guidance_video?.role_of_extracurriculars_tags === undefined ? [] : guidance_video?.role_of_extracurriculars_tags?.map((item:any) => item.role_of_extracurriculars_tags)),
            ...(guidance_video?.school_life_tags === undefined ? [] : guidance_video?.school_life_tags?.map((item:any) => item.school_life_tags)),
            ...(guidance_video?.taking_a_gap_year_tags === undefined ? [] : guidance_video?.taking_a_gap_year_tags?.map((item:any) => item.taking_a_gap_year_tags)),
            ...(guidance_video?.univariety_tags === undefined ? [] : guidance_video?.univariety_tags?.map((item:any) => item.univariety_tags)),
            ...(guidance_video?.work_experience_tags === undefined ? [] : guidance_video?.work_experience_tags?.map((item:any) => item.work_experience_tags))
        ]

        // let colleges:any = [];
        // state.data?.colleges.map((coll:any) => {
        //     colleges.push(coll.value);
        // })

        // let courses:any = [];
        // state.data?.courses.map((course:any) => {
        //     courses.push(course.value);
        // })

        axiosClient().post(link, {
            student_id: state.data.id,
            instituteId:instituteId,
            institute_group_id:institute_group_id,
            title : state.data.guidanceVideo.videoTitle,
            video : state.data.guidanceVideo.video,
            tags : tags.join(','),
            // colleges: colleges.join(','),
            // courses: courses.join(',')
        }).then((res: any) => {
            const responseData = res.data as any;
            if ((res.status === 200) && responseData.success === true) {
                data.setDataLoaded(!data.isDataLoaded);
                // document.getElementById('edit_profile_info_close_btn')?.click();
                dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showGuidanceVideoAddModal' } });
            }
            else {
                dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [responseData] });
            }
        }).catch((err: any) => {
            // Handle other errors
            console.error(err);
            alert('An error occurred while Adding. Please try again later.');
        });
    }

    useEffect(() => {
        dispatch({ type: 'ADD_ACTIONS', payload: { ...state.actions, SAVE: handleSave } });
    }, []);

    const isTagsNotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        const guidanceVideo = state?.data?.guidanceVideo;
        if(
            isEmpty(guidanceVideo) ||
            ((guidanceVideo?.achievements_tags === undefined || guidanceVideo?.achievements_tags.length === 0) &&
            (guidanceVideo?.alumni_guidance_tags === undefined || guidanceVideo?.alumni_guidance_tags.length === 0) &&
            (guidanceVideo?.board_exams_tags === undefined || guidanceVideo?.board_exams_tags.length === 0) &&
            (guidanceVideo?.career_guidance_tags === undefined || guidanceVideo?.career_guidance_tags.length === 0) &&
            (guidanceVideo?.college_admission_tags === undefined || guidanceVideo?.college_admission_tags.length === 0) &&
            (guidanceVideo?.college_life_tags === undefined || guidanceVideo?.college_life_tags.length === 0) &&
            (guidanceVideo?.country_tags === undefined || guidanceVideo?.country_tags.length === 0) &&
            (guidanceVideo?.course_selection_tags === undefined || guidanceVideo?.course_selection_tags.length === 0) &&
            (guidanceVideo?.curriculum_tags === undefined || guidanceVideo?.curriculum_tags.length === 0) &&
            (guidanceVideo?.entrance_exam_tags === undefined || guidanceVideo?.entrance_exam_tags.length === 0) &&
            (guidanceVideo?.importance_of_alumni_guidance_tags === undefined || guidanceVideo?.importance_of_alumni_guidance_tags.length === 0) &&
            (guidanceVideo?.pandemic_tags === undefined || guidanceVideo?.pandemic_tags.length === 0) &&
            (guidanceVideo?.role_of_extracurriculars_tags === undefined || guidanceVideo?.role_of_extracurriculars_tags.length === 0) &&
            (guidanceVideo?.school_life_tags === undefined || guidanceVideo?.school_life_tags.length === 0) &&
            (guidanceVideo?.taking_a_gap_year_tags === undefined || guidanceVideo?.taking_a_gap_year_tags.length === 0) &&
            (guidanceVideo?.univariety_tags === undefined || guidanceVideo?.univariety_tags.length === 0) &&
            (guidanceVideo?.work_experience_tags === undefined || guidanceVideo?.work_experience_tags.length === 0))
            ){
            setError('guidanceVideo.achievements_tags', ['Please select atleast one tag'], dispatch);
            return true;
        }
        setError('guidanceVideo.achievements_tags', [], dispatch);
        return false;
    };

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <AddGuidanceVideoLayout>{!state.flags.isDataLoading && <PageBuilder />}</AddGuidanceVideoLayout>
        </SmartContext.Provider>
    );
};

export default EditProfileInformationModal;