import { useContext, useEffect } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import SelectControl from './SelectControl';
import TextControl from './TextControl';

const PhoneControl = (props: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);

    let phoneCountryCodeValue = getControlValueFromState(
        props.parentDataKey + '.' + props.control.controlGroup[0]['id'],
        state as State
    ) as string;

    // phoneCountryCodeValue = phoneCountryCodeValue?.length ? phoneCountryCodeValue : props.control.controlGroup[0].props.defaultValue;

    useEffect(() => {
        if (phoneCountryCodeValue === undefined && props.control.controlGroup[0].props.defaultValue !== undefined) {
            phoneCountryCodeValue = props.control.controlGroup[0].props.defaultValue;
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: {
                    dataKey: props.parentDataKey + '.' + props.control.controlGroup[0]['id'],
                    name: props.control.controlGroup[0]['id'],
                    value: props.control.controlGroup[0].props.defaultValue,
                    errorMessages: [],
                },
            });
        }
    });

    const phoneNumberValue = getControlValueFromState(
        props.parentDataKey + '.' + props.control.controlGroup[1]['id'],
        state as State
    ) as string;

    const getDataKey = (control: any, props: any) => {
        return control?.dataPath ? control?.dataPath : props.parentDataKey + '.' + control.id;
    };

    return (
        <div className="col-md-12">
            <label htmlFor={props.control.id} className="form-label">
                {`${props.control.props.label} ${props.control.props.required ? '*' : ''}`}
            </label>

            <div className="row g-4">
                <div className={`${props.control.controlGroup[0].className || 'col-auto'}`}>
                    <SelectControl
                        key={props.control.controlGroup[0]['id']}
                        control={props.control.controlGroup[0]}
                        // dataKey={props.parentDataKey + '.' + props.control.controlGroup[0]['id']}
                        dataKey={getDataKey(props.control.controlGroup[0], props)}
                        parentDataKey={props.parentDataKey}
                        handleChangeEvent={() => {}}
                    />
                    {/* <select
                        id={props.control.controlGroup[0]['id']}
                        data-testid={props.control.controlGroup[0]['id']}
                        value={phoneCountryCodeValue}
                        onChange={(event) =>
                            handleControlValueChange({
                                control: props.control.controlGroup[0],
                                value: event.target.value,
                                dataKey: props.parentDataKey + '.' + props.control.controlGroup[0]['id'],
                                parentDataKey: props.parentDataKey,
                                state,
                                dispatch,
                            })
                        }
                        className="form-select"
                        aria-label="Default select example">
                        {state?.domain?.get(props.control.controlGroup[0].props.domainCategoryCode)?.map((domain: DomainElement) => (
                            <option key={domain.code} value={domain.code}>
                                {domain.value}
                            </option>
                        ))}
                    </select> */}
                </div>
                <div className={`${props.control.controlGroup[1].className || 'col'}`}>
                    <TextControl
                        key={props.control.controlGroup[1]['id']}
                        control={props.control.controlGroup[1]}
                        // dataKey={props.parentDataKey + '.' + props.control.controlGroup[1]['id']}
                        dataKey={getDataKey(props.control.controlGroup[1], props)}
                        parentDataKey={props.parentDataKey}
                        handleChangeEvent={() => {}}
                    />
                    {/* <input
                        id={props.control.controlGroup[1]['id']}
                        data-testid={props.control.controlGroup[1]['id']}
                        type="tel"
                        value={phoneNumberValue || ''}
                        onChange={(event) =>
                            handleControlValueChange({
                                control: props.control.controlGroup[1],
                                value: event.target.value,
                                dataKey: props.parentDataKey + '.' + props.control.controlGroup[1]['id'],
                                parentDataKey: props.parentDataKey,
                                state,
                                dispatch,
                            })
                        }
                        className="form-control form-control-lg"
                        placeholder={props.control.controlGroup[1].props.placeholder}
                        aria-label={props.control.controlGroup[1].props.placeholder}
                        aria-describedby="basic-addon1"
                    /> */}
                </div>
            </div>
        </div>
    );
};

export default PhoneControl;
