import { Dropdown, Tooltip } from 'bootstrap';
import { Modal } from 'bootstrap';
import moment from 'moment';
import { format } from 'date-fns';
import { useContext, useEffect, useRef, useState } from 'react';
import useSmartState from '../../../common/hooks/useSmartState';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import { renderToString } from 'react-dom/server';
import ActionSettings from '../../../library/SimpleControls/ActionSettings';
//Popup list
import UpdateActionModal from './UpdateActionModal';
import ReassignPopupModal from './ReassignPopupModal';
import ViewLeadHistoryModal from './ViewLeadHistoryModal';
import UpdateLeadHistoryModal from './UpdateLeadHistoryModal';
import { REPORT_CONFIG } from '../../admin/reports/report-config';

const BATCH_SIZE = 10;
const DEFAULT_SORT_COLUMN = 'createdAt';
const DEFAULT_SORT_DIRECTION = 'desc';

function formatDate(dateString: any) {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

const getProgramlist = (row: any) => {
    // Logic to retrieve the value based on the ID
    // For example, you might fetch the value from an array or an API
    console.log(row.program);

    return 'Unknown Program'; // Default value if ID is not found
};

const ManageleadGrid = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const [sortedStudents, setSortedStudents] = useState<string[]>([]);
    const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
    const [bulkAction, setBulkAction] = useState<string>('' as any);
    const [visibleStudents, setVisibleStudents] = useState(state.data.leadList.slice(0, 10));
    const loadMoreRef: any = useRef();
    const { gridColumns, actions } = state.data.reportConfig;
    const dropdownRef = useRef(null);
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);
    const allRows = [...state.data.leadList] ?? [];
    const config = REPORT_CONFIG[state.routeInfo.pageName];

    useEffect(() => {
        console.log(`useEffect 1`);
        if (dropdownRef.current) {
            new (Dropdown as any)(dropdownRef.current);
        }
    }, []);

    useEffect(() => {
        console.log(`useEffect 2`);
        const sorted = [...allRows].sort(compareFunction);
        setSortedStudents(sorted);
    }, [state.data.leadList, sortColumn, sortDirection]);

    useEffect(() => {
        console.log(`useEffect 3`);
        setVisibleStudents(sortedStudents.slice(0, BATCH_SIZE));
    }, [sortedStudents]);

    useEffect(() => {
        console.log(`useEffect 4`);
        const loadMoreObserver = new IntersectionObserver((entries) => {
            // Check if all students are already visible
            if (sortedStudents.length <= visibleStudents.length) {
                if (loadMoreRef.current) {
                    loadMoreObserver.unobserve(loadMoreRef.current); // Stop observing
                }
                return;
            }

            if (entries[0].isIntersecting) {
                const nextBatch = sortedStudents.slice(0, visibleStudents.length + BATCH_SIZE);
                setVisibleStudents([...nextBatch]);
            }
        });

        if (loadMoreRef.current) loadMoreObserver.observe(loadMoreRef.current);
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });

        return () => {
            if (loadMoreRef.current) {
                loadMoreObserver.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, visibleStudents]);

    // Utility to determine if the column is a string type.
    const isStringColumn = typeof visibleStudents[0]?.[sortColumn] === 'string';

    const compareFunction = (a: any, b: any) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (isStringColumn) {
            return sortDirection === 'asc'
                ? aValue?.localeCompare(bValue, undefined, { sensitivity: 'accent' })
                : bValue?.localeCompare(aValue, undefined, { sensitivity: 'accent' });
        } else {
            return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
        }
    };

    const institute_tool = (row: any) => {
        const mainarr = row.instituteId;
        const instituteIdArray = Array.isArray(mainarr) ? mainarr : [mainarr];

        const firstTwoItems = instituteIdArray.slice(0, 2);
        const remainingItems = instituteIdArray.slice(2);

        const firstTwoItemsHtml = firstTwoItems.map((item: any, index: any) => {
            return (
                <li style={{ listStyleType: 'none' }} key={index}>
                    {getDomainValueForCode(item, 'INSTITUTE_DOMAIN', state) ?? ''}
                </li>
            );
        });

        const listItems = remainingItems.map((item: any, index: any) => {
            return <li key={index}>{getDomainValueForCode(item, 'INSTITUTE_DOMAIN', state) ?? ''}</li>;
        });

        let secondSpan = null;
        if (listItems.length > 0) {
            const tooltipContent = renderToString(<ol>{listItems}</ol>);
            secondSpan = (
                <span>
                    <a
                        className="btn-html"
                        href="javascript:;"
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        data-bs-original-title={tooltipContent}>
                        &amp; {listItems.length} More
                    </a>
                </span>
            );
        }

        const institute = (
            <span>
                {firstTwoItemsHtml}
                {secondSpan}
            </span>
        );

        return institute;
    };

    const subdepart_tool = (row: any) => {
        if (row.programSubDepartment === null) {
            return <span></span>;
        }

        const mainarr =
            row.programSubDepartment && row.programSubDepartment.length > 0
                ? row.programSubDepartment.split(',')
                : row.programSubDepartment;
        const SubDepartmentArray = Array.isArray(mainarr) ? mainarr : [mainarr];

        const firstTwoItemsHtml = () => (
            <li style={{ listStyleType: 'none' }}>
                {getDomainValueForCode(row.programDepartment, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state) ?? ''}
            </li>
        );

        const listItems = SubDepartmentArray.map((item: any, index: any) => {
            return <li key={index}>{getDomainValueForCode(item, 'FIELD_OF_STUDY_TYPE_CODE', state) ?? ''}</li>;
        });

        let secondSpan = null;
        if (listItems.length > 0) {
            const tooltipContent = renderToString(<ol>{listItems}</ol>);
            secondSpan = (
                <span>
                    <a
                        className="btn-html"
                        href="javascript:;"
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        data-bs-original-title={tooltipContent}>
                        {listItems.length} Specializations
                    </a>
                </span>
            );
        }

        const institute = (
            <span>
                {firstTwoItemsHtml()}
                {secondSpan}
            </span>
        );

        return institute;
    };

    const getActionNode = (row: any) => {
        const actions = config['ROW_ACTION'];
        //console.log(actions)
        return actions.length > 1 ? <ActionSettings id={row?.id} actions={actions} onClick={handleAction} /> : null;
    };

    const handleAction = (id: string, action: string) => {
        console.log(`handleAction: ${id} / ${action}`);
        handlePerformAction(action, [id]);
    };

    const handlePerformAction = async (action: string, students: any) => {
        const urlParams = new URLSearchParams(window.location.search);
        const templateId = urlParams.get('template_id');
      
        const temp_id = urlParams.get('temp_ref_id');
      
        const students_data = selectedStudents;
        console.log('Selected Students:', selectedStudents);
        const lengthOfSelectedStudents = selectedStudents.length;
        //  alert(lengthOfSelectedStudents);
        if (lengthOfSelectedStudents === 0) {
            alert('Please select atleast 1 user');
        } else {
            // Serialize the selectedStudents array to a string
            const selectedStudentsString = JSON.stringify(selectedStudents);

            // Store it in sessionStorage
            sessionStorage.setItem('student_data_for_email', selectedStudentsString);
            console.log(selectedStudentsString);
            //alert();
            if (temp_id) {
                window.location.href = `/broadcast/templatepreview?template_id=${templateId}&temp_id=${temp_id}`;
            } else {
                window.location.href = `/broadcast/emailpreview?template_id=${templateId}`;
            }
        }
    };

    const updateAction = async (lead: any) => {
        try {
            console.log('lead', lead);

            const modalElement = document.getElementById('update-action');
            if (modalElement) {
                const modal = new Modal(modalElement);
                modal.show();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    const Reassignpopup = async (lead: any) => {
        try {
            console.log('lead', lead);

            const modalElement = document.getElementById('reassign-lead');
            if (modalElement) {
                const modal = new Modal(modalElement);
                modal.show();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    const displayviewLeadHistory = async (lead: any) => {
        try {
            console.log('lead', lead);

            const modalElement = document.getElementById('view-lead-history');
            if (modalElement) {
                const modal = new Modal(modalElement);
                modal.show();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    const updateLeadHistory = async (lead: any) => {
        try {
            console.log('lead', lead);

            const modalElement = document.getElementById('update-lead-history');
            if (modalElement) {
                const modal = new Modal(modalElement);
                modal.show();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const students = event.target.checked ? allRows.map((item: any) => item.id) : [];
            setSelectedStudents(students);
        } else {
            // Deselect all students by clearing selectedStudents
            setSelectedStudents([]);
        }
    };

    const handleSelectStudent = (event: React.ChangeEvent<HTMLInputElement>, studentId: string) => {
        if (event.target.checked) {
            setSelectedStudents((prevSelected) => [...prevSelected, studentId]);
        } else {
            setSelectedStudents((prevSelected) =>
                prevSelected.filter((id) => id !== studentId)
            );
        }
    };

    const handleApplyClick = () => {
        if (selectedStudents.length > 0) {
            alert(`Selected Student IDs: ${selectedStudents.join(', ')}`);
        } else {
            alert('No students selected.');
        }
    };

    const handleColumnSort = (column: string) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    return (
        <>
            <div>
                <div className="ActiveListings_present">
                    <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                        <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">

                            <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks my-2">
                                <div className="d-flex justify-content-start align-items-center customselect_parnt">

                                    <button
                                        className="btn btn-darkblue"
                                        type="button"
                                        id="BulkClickBtn"
                                        onClick={() => handlePerformAction(bulkAction, selectedStudents)}>
                                        <span className="line-height-16">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-end">
                            <h3 className="font-14 font-500 mb-0 me-2">
                                Total {allRows.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} records
                            </h3>
                        </div>
                    </div>
                    <div className="table-responsive attendee_dtl">
                        <table className="table" style={{ minWidth: '1042px' }}>
                            <thead>
                                <tr>
                                    <th onClick={() => handleColumnSort('firstName')}>
                                        <div className="d-flex flex-wrap">
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedStudents.length === visibleStudents.length}
                                                    id="Antony"
                                                    className="form-check-input me-2 mt-0"
                                                    onChange={handleSelectAll}
                                                    ref={(el) => {
                                                        if (el) el.indeterminate = selectedStudents.length > 0 && selectedStudents.length < visibleStudents.length;
                                                    }}
                                                />
                                                Lead Name
                                            </div>
                                            {sortColumn === 'firstName' && <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>}
                                        </div>
                                    </th>
                                    <th>Interested in</th>
                                    <th>Contact</th>
                                    <th>Lead Marking</th>
                                    <th>Source</th>
                                    <th>Created on</th>
                                </tr>
                            </thead>
                            <tbody>
                                {visibleStudents.map((row: any) => (
                                    <tr key={row.id}>
                                        <td>
                                            <div className="d-flex flex-wrap">
                                                <div>
                                                    <input
                                                        className="form-check-input me-2 StudentId"
                                                        name="id"
                                                        type="checkbox"
                                                        value={row.id}
                                                        checked={selectedStudents.includes(row.id)}
                                                        onChange={(e) => handleSelectStudent(e, row.id)}
                                                    />
                                                </div>
                                                <div className="name d-flex flex-column line-height-18 flex-1">
                                                    <span
                                                        data-bs-toggle="tooltip"
                                                        title={`${row.firstName} ${row.lastName}`}
                                                        className="singlelinename font-16 lineHeight-16 font-500">
                                                        {row.firstName} {row.lastName}{' '}
                                                        <span className="d-block font-13 font-400 text-black">
                                                            {row.registrationBy} Registered
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="font-14 font-500 mb-1">{row.instituteName}</div>
                                            <div className="font-14 font-500">
                                                <span
                                                    data-bs-toggle="tooltip"
                                                    title={`${row.programNames}`}
                                                    className="singlelinename font-16 lineHeight-16 font-500">
                                                    {' '}
                                                    {row.programNames ? row.programNames.slice(0, 10) : ''}{' '}
                                                </span>
                                            </div>
                                            <div className="font-14 font-500">
                                                <span className="d-block">({row.intake ? row.intake : 'N/A'} Intake)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="font-14 font-500 mb-2 d-flex align-items-start">
                                                <i className="table_phone"></i> <span className="flex-1 line-height-13">{row.mobile}</span>
                                            </div>
                                            <div className="font-14 font-500 d-flex align-items-start">
                                                <i className="table_mail"></i> <span className="flex-1 line-height-13">{row.email}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="font-14 font-500 mb-1">
                                                Status - <span className="font-500">{row.responce}</span>{' '}
                                            </div>
                                            <div className="font-14 font-500">
                                                Last Updated -{' '}
                                                <span className="font-500">
                                                    {row.updatedOn ? moment(row.updatedOn).format('DD-MMM-YYYY') : 'N/A'}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="font-500">{row.source}</div>
                                        </td>
                                        <td>
                                            <div className="font-500">
                                                {row.addedOn ? moment(row.addedOn).format('DD-MMM-YYYY') : 'N/A'}
                                            </div>
                                            <div className="font-14 font-500">By Institute Admin </div>
                                            <a className="table-link" href="javascript:;"></a>
                                        </td>
                                    </tr>
                                ))}
                                <tr ref={loadMoreRef} />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <UpdateActionModal data={{}} />
            <ReassignPopupModal data={{}} />
            <ViewLeadHistoryModal data={{}} />
            <UpdateLeadHistoryModal data={{}} />
        </>
    );
};

export default ManageleadGrid;
