import GuidanceFilters from './GuidanceFilters';
import GuidanceGridControl from './GuidanceGridControl';

const GuidanceLayout = () => {
    return (
        <div>
            <div className="main flex-1">
                {/* <section className="pt-3 pt-sm-5 pb-3" id="alumni_directory">
                    <div className="container max-1140 px-3 px-sm-4 px-lg-0">
                        <div className="row px-4">
                            <div className="xscroll p-0">
                                <h3
                                    className="font-35 font-600 mb-0 mt-2 mt-sm-3 block-title aos-init aos-animate"
                                    data-aos="fade-left"
                                    data-aos-delay="600">
                                    Guidance Videos
                                </h3>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="notificationSection">
                    <div className="container max-1140 px-lg-0">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="white-block white-block-notopborderradius h-100 p-0 pt-2">
                                <div className="m-w-90 d-flex flex-wrap justify-content-between align-items-center mb-1 px-4 pt-4 pb-0">
                                    <h3 className="font-35 font-600 mb-0 mt-3 block-title aos-init aos-animate" data-aos="fade-left" data-aos-delay="600">Guidance Videos</h3>
                                    
                                </div>
                                    <div className="row m-0 px-3 px-sm-4 pt-4 pb-5 mt-0 JobInternshipListing">
                                        <GuidanceFilters />
                                        <GuidanceGridControl />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default GuidanceLayout;
