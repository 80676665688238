import { axiosClient } from '../../../library/Service/axiosClient';

export const getStudentList = (searchCriteria?: any) => {
    return axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/school-directory/search`, {
        ...searchCriteria,
        schoolId: sessionStorage.getItem('user-school-id'),
        // userTypeCode: 'STUDENT',
        userTypeCode: 'ALUMNI',
    });
};
