import courseAlumniProfile from '../../../../styles/images/course_alumni_profile.svg';
import studentCollegeImg from '../../../../styles/images/stephen_college.png';

import { useContext, useEffect, useRef, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import '../../../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import { dummyFemale, dummyMale } from '../../../../common/styles/Images';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { getDomainValueForCode, isEmpty } from '../../../../library/Core/SmartFunctions';
import { Link } from 'react-router-dom';

const WorkSimilarAlumni = () => {
    const { state, dispatch } = useContext(SmartContext);
    // const { selectedTab, label, data } = state.data?.matchingAlumni;
    const [alumni, setAlumni] = useState([]);
    const [pageInfo, setPageInfo] = useState({ page: 0, pageStart: 0, pageEnd: 4 });
    const selectedTab = state.data?.selectedTab;
    const [carouselKey, setCarouselKey] = useState(0);
    
    const options = {
        margin: 20,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        // navText: ['Prev', 'Next'],
        // navText: [`<i class="icon-left-arrow"></i>`, `<i class="icon-right-arrow"></i>`],
        smartSpeed: 1000,
        responsive: {
            0: { items: 1 },
            400: { items: 1 },
            600: { items: 2 },
            700: { items: 2 },
            1000: { items: 2 },
        },
    };

    // useEffect(() => {
    //     setAlumni([]);
    // }, [state.data.selectedTab]);

    useEffect(() => {
        setAlumni(state.data?.matchingAlumni?.data?.slice(0, 4));
        setCarouselKey((prevKey) => prevKey + 1);
    }, [state.data?.matchingAlumni?.data]);

    useEffect(() => {
        const interval = setInterval(() => {
            const owlNextElement = document.getElementsByClassName('owl-next')[0] as HTMLDivElement; // Adjust the type to match the actual element type
            if (owlNextElement) {
                owlNextElement.click();
            }
        }, 5000);
      
        return () => {
          clearInterval(interval);
        };
      }, []);

    const handlePrev = () => {
        let { page, pageStart, pageEnd } = pageInfo;
        let prevPageInfo;

        if (page - 1 <= 0) {
            prevPageInfo = { page: 0, pageStart: 0, pageEnd: 4 };
        } else {
            prevPageInfo = { page: page - 1, pageStart: (page - 1) * 2, pageEnd: page * 2 + 4 };
        }

        const prevAlumni = state.data?.matchingAlumni?.data?.slice(prevPageInfo.pageStart, prevPageInfo.pageEnd);

        setAlumni(prevAlumni);
        setPageInfo(prevPageInfo);
        setCarouselKey((prevKey) => prevKey + 1);
    };

    const handleNext = () => {
        const { page, pageStart, pageEnd } = pageInfo;
        if (pageEnd >= state.data?.matchingAlumni?.data?.length) return;

        const newPageInfo = { page: page + 1, pageStart: (page + 1) * 2, pageEnd: (page + 1) * 2 + 4 };
        const nextAlumni = state.data?.matchingAlumni?.data?.slice(newPageInfo.pageStart, newPageInfo.pageEnd);
        setAlumni(nextAlumni);
        setPageInfo(newPageInfo);
        setCarouselKey((prevKey) => prevKey + 1);
    };

    const getStudentImage = ({ genderCode, profilePictureFilePath }: any) => {
        if (profilePictureFilePath) return profilePictureFilePath;
        return genderCode === 'MALE' ? dummyMale : dummyFemale;
    };

    const getCard = (item: any) => (
        <div key={selectedTab + item.uuid} className="card_profiles card_profiles_pg">
            <div className="d-flex">
                <figure className="student">
                     <Link to={`/alumni/${item?.uuid}/profile`}  target="_blank" rel="noopener noreferrer">
                       <img src={getStudentImage(item)} />
                    </Link>
                </figure>
                <div className="student_details">
                    <h2>
                        {/* <a href={`/alumni/${item?.uuid}/profile`} target='_blank'>{item.name}</a> */}
                        <Link to={`/alumni/${item?.uuid}/profile`} target="_blank" rel="noopener noreferrer">
                            {item.name} 
                        </Link>
                        {/* <span>Class of {item.passOutYear}</span> */}
                        <span>{item.programName}</span>
                    </h2>
                    
                    <a className="btn-send" href="#" onClick={(event) => handleSendMessage(event, item?.uuid)} >
                        <i className="icon-send"></i>
                        <span>Send</span>
                    </a>
                    
                    <div className="sub_details sub_details_workExp">
                        <figure className="mt-0"><img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/suitcase_alumni.svg`} />
                        </figure>
                        <h3 className="d-flex flex-column">
                            <p className="mb-0">{item.jobDesignation} at {item.jobCompanyName ?? getDomainValueForCode(item.jobCompanyId, 'COMPANY_CODE', state)} </p>
                        </h3>
                    </div>
                    {! isEmpty(item?.universityName) && (
                        <div className="sub_details mt-2 pb-0 sub_details_workExp sub_details_workExp1">
                            <figure className="bg_white mt-0">
                                {/* <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/${item.collegeIconFilePath}`} /> */}
                                <img src={`${item.collegeIconFilePath}`} />
                            </figure>
                            <h3 className="d-flex flex-column">
                                <p className="mb-1" data-bs-toggle="tooltip" title="" data-bs-original-title={`${item?.universityName} (UG)`} aria-describedby="tooltip785134">{item?.universityName} (UG)</p>
                                <span className="course">
                                    <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/course_alumni_profile.svg`} />
                                    <span data-bs-toggle="tooltip" title="" data-bs-original-title="Architecture">{getDomainValueForCode(item.fieldOfStudyCode, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state)}</span> 
                                </span>
                            </h3>
                        </div>
                    )}
                    
                </div>
            </div>
        </div>
    );
    
    const handleSendMessage = (event: any, id: string) => {
        event.preventDefault();
        state.actions['ROUTER_NAVIGATE'](`/messages/${id}`);
    };

    const handleViewProfile = (event: any, id: string) => {
        event.preventDefault();
        state.actions['ROUTER_NAVIGATE'](`/alumni/${id}/profile`);
    };

    return (
        <>
            {alumni && alumni.length > 0 && (
                <div>
                    <div className="graduation_carousel_wrap">
                        <div className="seactionheader">
                            <h3>Other Alumni from Your school with <span>Work Experience</span></h3>
                            <div className="countnbtn">
                                <span className="count">{state.data?.matchingAlumni?.data?.length} alumni found</span>
                                <div className="custom-btns">
                                    <a className="btn-prev owl-prev icon-left-arrow" role="button" onClick={handlePrev}></a>
                                    <a className="btn-next owl-next icon-right-arrow" role="button" onClick={handleNext}></a>
                                </div>
                            </div>
                        </div>
                        <OwlCarousel key={selectedTab + carouselKey} className="owl-carousel" {...options}>
                            <div key={selectedTab + 'One'}>{alumni?.slice(0, 2)?.map((item: any) => getCard(item))}</div>
                            <div key={selectedTab + 'Two'}>{alumni?.slice(2, 4)?.map((item: any) => getCard(item))}</div>
                        </OwlCarousel>
                    </div>
                </div>
            )}
        </>
    );
};

export default WorkSimilarAlumni;