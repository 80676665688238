import courseAlumniProfile from '../../../../styles/images/course_alumni_profile.svg';
import studentCollegeImg from '../../../../styles/images/stephen_college.png';

import { useContext, useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import '../../../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import { dummyFemale, dummyMale } from '../../../../common/styles/Images';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { getDomainValueForCode, getIcon, isEmpty } from '../../../../library/Core/SmartFunctions';
import { stat } from 'fs';
import { Link } from 'react-router-dom';

const SkillSimilarAlumni = () => {
    const { state, dispatch } = useContext(SmartContext);
    // const { selectedTab, label, data } = state.data?.matchingAlumni;
    const [alumni, setAlumni] = useState([]);
    const [pageInfo, setPageInfo] = useState({ page: 0, pageStart: 0, pageEnd: 4 });
    const selectedTab = state.data?.selectedTab;
    const [carouselKey, setCarouselKey] = useState(0);
    
    const options = {
        margin: 20,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        // navText: ['Prev', 'Next'],
        // navText: [`<i class="icon-left-arrow"></i>`, `<i class="icon-right-arrow"></i>`],
        smartSpeed: 1000,
        responsive: {
            0: { items: 1 },
            400: { items: 1 },
            600: { items: 2 },
            700: { items: 2 },
            1000: { items: 2 },
        },
    };

    const levelMapping = ['', 'yellow', 'orange', 'green', 'yellow', 'orange', 'green'];

    // useEffect(() => {
    //     setAlumni([]);
    // }, [state.data.selectedTab]);

    useEffect(() => {
        let alumniData = state.data?.matchingAlumni?.data?.slice(0, 4) ?? [];
        let modifiedAlumniData = manageAlumni(alumniData);

        if(state?.data?.skills?.length > 0){
            setAlumni(modifiedAlumniData);
            setCarouselKey((prevKey) => prevKey + 1);
        }
        
    }, [state.data?.matchingAlumni?.data]);

    useEffect(() => {
        const interval = setInterval(() => {
            const owlNextElement = document.getElementsByClassName('owl-next')[0] as HTMLDivElement; // Adjust the type to match the actual element type
            if (owlNextElement) {
                owlNextElement.click();
            }
        }, 5000);
      
        return () => {
          clearInterval(interval);
        };
      }, []);

    const manageAlumni = (alumniData: any) => {
        let modifiedAlumniData = alumniData.map((eachAlumni: any) => {
            let skillList: any = [];
            let modifiedAlumni = { ...eachAlumni };

            let skillData = eachAlumni?.skillCodeData;
            let skillDataArr = skillData?.split('##');
            if ( Array.isArray(skillDataArr) && skillDataArr.length > 0 ) {
                skillDataArr.forEach((rec: any) => {
                    let skillRecArr = rec.split('::');
                    if ( isEmpty (skillRecArr[1]) ) {
                        return false;
                    }
    
                    skillList.push({
                        'skill': getDomainValueForCode(skillRecArr[0], 'SKILL_VIEW_CODE', state),
                        'level': skillRecArr[1]
                    })
                });
            }            

            skillData = eachAlumni?.otherSkillData;
            skillDataArr = skillData?.split('##');
            if ( Array.isArray(skillDataArr) && skillDataArr.length > 0 ) {
                skillDataArr.forEach((rec: any) => {
                    let skillRecArr = rec.split('::');
                    if ( isEmpty (skillRecArr[1]) ) {
                        return false;
                    }
    
                    skillList.push({
                        'skill': skillRecArr[0],
                        'level': skillRecArr[1]
                    })
                });
            }

            skillList.sort((a: any, b: any) => a.skill.localeCompare(b.skill))
            modifiedAlumni.skillList = skillList;

            return modifiedAlumni;
        });

        return modifiedAlumniData;
    }

    const handlePrev = () => {
        let { page, pageStart, pageEnd } = pageInfo;
        let prevPageInfo;

        if (page - 1 <= 0) {
            prevPageInfo = { page: 0, pageStart: 0, pageEnd: 4 };
        } else {
            prevPageInfo = { page: page - 1, pageStart: (page - 1) * 2, pageEnd: page * 2 + 4};
        }

        const prevAlumni = state.data?.matchingAlumni?.data?.slice(prevPageInfo.pageStart, prevPageInfo.pageEnd);
        let modifiedAlumniData = manageAlumni(prevAlumni);

        if(state?.data?.skills?.length > 0){
            setAlumni(modifiedAlumniData);
            setPageInfo(prevPageInfo);
            setCarouselKey((prevKey) => prevKey + 1);
        }
        
    };

    const handleNext = () => {
        const { page, pageStart, pageEnd } = pageInfo;
        if (pageEnd >= state.data?.matchingAlumni?.data?.length) return;

        const newPageInfo = { page: page + 1, pageStart: (page + 1) * 2, pageEnd: (page + 1) * 2 + 4 };
        const nextAlumni = state.data?.matchingAlumni?.data?.slice(newPageInfo.pageStart, newPageInfo.pageEnd);
        let modifiedAlumniData = manageAlumni(nextAlumni);

        if(state?.data?.skills?.length > 0){
            setAlumni(modifiedAlumniData);
            setPageInfo(newPageInfo);
            setCarouselKey((prevKey) => prevKey + 1);
        }
        
    };

    const getStudentImage = ({ genderCode, profilePictureFilePath }: any) => {
        if (profilePictureFilePath) return profilePictureFilePath;
        return genderCode === 'MALE' ? dummyMale : dummyFemale;
    };

    const getCard = (item: any) => (
        <div key={selectedTab + item.uuid} className="card_profiles card_profiles_pg">
            <div className="d-flex">
                <figure className="student">
                    <Link to={`/alumni/${item?.uuid}/profile`}  target="_blank" rel="noopener noreferrer">
                       <img src={getStudentImage(item)} />
                    </Link>
                </figure>
                <div className="student_details">
                    <h2>
                        {/* <a href={`/alumni/${item?.uuid}/profile`} target='_blank'>{item.name}</a>  */}
                        <Link to={`/alumni/${item?.uuid}/profile`} target="_blank" rel="noopener noreferrer">
                            {item.name} 
                        </Link>
                        {/* <span>Class of {item.passOutYear}</span> */}
                        <span>{item.programName}</span>
                    </h2>
                    
                    <a className="btn-send" href="#" onClick={(event) => handleSendMessage(event, item?.uuid)} >
                        <i className="icon-send"></i>
                        <span>Send</span>
                    </a>
                    
                    <div className="meter" data-bs-toggle="tooltip" title="" data-bs-original-title="Novice" aria-describedby="tooltip707464">
                        <span className="flex-1 me-3 singleline">{item?.skillList[0]?.skill}</span>
                        <span className="meterimg">
                            <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/${levelMapping[item?.skillList[0]?.level]}-meter.svg`} />
                        </span>
                    </div>
                    {! isEmpty(item?.universityName) && (
                        <div className="sub_details mt-2 pb-0 sub_details_workExp sub_details_workExp1">
                            <figure className="bg_white mt-0">
                                {/* <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/${item.collegeIconFilePath}`} /> */}
                                <img src={`${item.collegeIconFilePath}`} />
                            </figure>
                            <h3 className="d-flex flex-column">
                                <p className="mb-1" data-bs-toggle="tooltip" title="" data-bs-original-title={`${item?.universityName} (UG)`} aria-describedby="tooltip785134">{item?.universityName} (UG)</p>
                                <span className="course">
                                    <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/course_alumni_profile.svg`} />
                                    <span data-bs-toggle="tooltip" title="" data-bs-original-title="Architecture">{getDomainValueForCode(item.fieldOfStudyCode, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state)}</span> 
                                </span>
                            </h3>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
    
    const handleSendMessage = (event: any, id: string) => {
        event.preventDefault();
        state.actions['ROUTER_NAVIGATE'](`/messages/${id}`);
    };

    const handleViewProfile = (event: any, id: string) => {
        event.preventDefault();
        state.actions['ROUTER_NAVIGATE'](`/alumni/${id}/profile`);
    };

    return (
        <>
            {alumni && alumni.length > 0 && (
                <div>
                    <div className="graduation_carousel_wrap">
                        <div className="seactionheader">
                            <h3>Other Alumni with <span>Similar Skills</span></h3>
                            <div className="countnbtn">
                                <span className="count">{state.data?.matchingAlumni?.data?.length} alumni found</span>
                                <div className="custom-btns">
                                    <a className="btn-prev owl-prev icon-left-arrow" role="button" onClick={handlePrev}></a>
                                    <a className="btn-next owl-next icon-right-arrow" role="button" onClick={handleNext}></a>
                                </div>
                            </div>
                        </div>
                        <OwlCarousel key={selectedTab + carouselKey} className="owl-carousel graduationslider" {...options}>
                            <div key={selectedTab + 'One'}>{alumni?.slice(0, 2)?.map((item: any) => getCard(item))}</div>
                            <div key={selectedTab + 'Two'}>{alumni?.slice(2, 4)?.map((item: any) => getCard(item))}</div>
                        </OwlCarousel>
                    </div>
                </div>
            )}
        </>
    );
};

export default SkillSimilarAlumni;