import { useContext, useEffect, useRef } from 'react';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, handleControlValueChange, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const TextAreaControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl

    useEffect(() => {
        const errorMessages = validateFormField(
            control,
            data,
            state,
            control?.props?.label,
            dataKey,
            getControlValueFromState(parentDataKey as string, state as State)
        );
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });

        // @dt 5th.Aug,2K24 @by RG @note: making textarea control support the default value feature;
        if (isEmpty(data) && !isEmpty(control.props?.defaultValue)) {
            handleControlValueChange({ control, value: control.props.defaultValue, dataKey, parentDataKey, state, dispatch });
        }
    }, []);

    return (
        <>
            <label htmlFor={control.id} className="form-label m-0 mb-1 font-16 font-500 w-100">
                {`${control.props.label}`}
            </label>
            <textarea
                id={control.id}
                data-testid={control.id}
                className="form-control"
                placeholder={control.props.placeholder}
                value={data ?? ''}
                required={control.props.required}
                maxLength={control.props.maxLength}
                onChange={(event) =>
                    handleControlValueChange({ control, value: event.target.value, dataKey, parentDataKey, state, dispatch })
                }
                style={{ height: `${control.props.numLines * 50}px` }}></textarea>
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default TextAreaControl;
