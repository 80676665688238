import { DomainElement, State } from '../library/Core/SmartTypes';
import { DomainFlexColumEnum } from '../library/Service/domainService';

export const validateScore = (universityId: string, scoreTypeCode: string, rank: number, state: State): string => {
    if (scoreTypeCode === 'RANK' && rank > 1000000) return 'Rank must be between 1 - 1000000';
    if (scoreTypeCode === 'RANK') return '';

    const maxMarks =
        universityId == null
            ? undefined
            : (state.domain?.get('ENTRANCE_TEST_CODE')?.find((item) => item.code == universityId) as DomainElement)[
                  DomainFlexColumEnum.EXAM_MAX_MARKS
              ];

    if (maxMarks != null && rank > parseInt(maxMarks)) return `Score must be between 1 - ${maxMarks}`;

    return '';
};

export const getMaxMarksForGivenExamCode = (examCode: String, state: State) => state.domain?.get('');
