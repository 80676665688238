import { useContext, useState } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';

const TopFilterPillsControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey } = { ...args };

    // const controlDomain = state?.domain?.get(control.props.domainCategoryCode) as DomainElement[];
    const [selectedPill, setSelectedPill] = useState(control.controlGroup[0]['id']);

    const data = getControlValueFromState(dataKey, state as State);

    const handleClick = (code: string) => {
        setSelectedPill(code);
        state?.actions['TOP_FILTER_CHANGE_HANDLER'](code, state, dispatch);
    };

    return (
        <div className="row job_internship_tab polartabsec w-100">
            <div className="xscroll p-0">
                <ul className="nav nav-tabs" id="JobInternshipTab" role="tablist" data-aos="fade-left" data-aos-delay="600">
                    {control.controlGroup.map((subControl) => (
                        <li key={subControl.id} className="nav-item" role="presentation" id="ActiveTab">
                            <button
                                className={`nav-link ${selectedPill === subControl.id ? ' active ' : ''}`}
                                id={subControl.id}
                                data-bs-toggle="tab"
                                data-bs-target="#ActiveListings"
                                type="button"
                                role="tab"
                                aria-controls="ActiveListings"
                                aria-selected="true"
                                onClick={() => handleClick(subControl.id)}>
                                {subControl.props.label} <span id="ActiveCount"></span>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default TopFilterPillsControl;
