import { useContext, useEffect, useRef } from 'react';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState } from '../Core/SmartFunctions';
import { DomainElement, SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const CheckBoxControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const checkControl = control.controlGroup[0];
    const data = getControlValueFromState(dataKey, state as State);
    const controlDomain = state?.domain?.get(checkControl.props.domainCategoryCode) as DomainElement[];
    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl

    useEffect(() => {
        const errorMessages = validateFormField(
            control,
            data,
            state,
            control?.props?.label,
            dataKey,
            getControlValueFromState(parentDataKey as string, state as State)
        );
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const errorMessages = validateFormField(checkControl, event.target.value, state, checkControl.props.label, dataKey);

        const eventType = event.target.checked ? 'CHECKBOX_ADD_NEW_RECORD_IN_ARRAY' : 'CHECKBOX_DELETE_RECORD_IN_ARRAY';

        dispatch({
            type: eventType,
            payload: { dataKey, name: checkControl.id, value: { [event.target.id]: event.target.value }, errorMessages },
        });
    };

    return (
        <fieldset>
            {control.props.label && <legend className="form-label">{`${control.props.label} `}</legend>}
            {controlDomain?.length > 0 &&
                controlDomain.map((domain) => (
                    <div key={domain.code} className={`form-check form-check-inline ${checkControl.props.radioTextClassName} px-3`}>
                        <input
                            id={checkControl.id}
                            data-testid={checkControl.id}
                            className={`form-check-input p-1 m-1`}
                            type="checkbox"
                            name={dataKey} // Note: Name need to be different for each row so that selection does not span across the next line item
                            value={domain.code}
                            checked={data?.some((item: any) => item[checkControl.id] === domain.code)}
                            onChange={(event) => handleChange(event)}
                            ref={formControlRef}
                        />
                        <label className={`form-check-label ${checkControl.props.radioTextClassName}`} htmlFor={checkControl.id}>
                            {domain.value}
                        </label>
                    </div>
                ))}
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </fieldset>
    );
};

export default CheckBoxControl;
