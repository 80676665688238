import NotificationService from './NotificationService';
import React, { useState, useEffect } from 'react';
import { axiosClient } from '../../library/Service/axiosClient';
import NotificationList from './NotificationList';
import moment from 'moment';
import avatar from '../../styles/images/dummy_male.svg';

const AlumniNotification = ({pageTitle}:any) => {
    document.title=pageTitle
    
    const [notification, setNotification] = useState([]);

    const isPeerReviewed = sessionStorage.getItem('is-peer-reviewed') == 'true' ? true : false;
    const userType = sessionStorage.getItem('user-type-code');
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/notification/notification-list`);
                const data = await response.data; // Extract the data from the response
                setNotification(data);
            } catch (error) {
                console.error('Error fetching data:', error);
                // alert(error);
            }
        };

        fetchData();
    }, []);

    const handleMarkAll = async (event: any) => {
        const isRead = event.target.value === '1';
        try {
            await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/notification/mark-all`, { isRead });
            // Refresh the notifications after marking all as read/unread
            // fetchData();
        } catch (error) {
            console.error('Error marking all as read/unread:', error);
            // alert(error);
        }
    };

    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    return (
        <div className="main flex-1">
            <section className="notificationSection">
                <div className="container max-1140 px-lg-0">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius h-100">
                                <div className="d-flex flex-wrap justify-content-between align-items-center mb-4 border-bottom custom-border-gray pb-3">
                                    <h3
                                        className="font-35 font-600 mb-0 block-title aos-init aos-animate"
                                        data-aos="fade-left"
                                        data-aos-delay="600">
                                        Notifications
                                    </h3>
                                    <select
                                        onChange={handleMarkAll}
                                        className="form-select max-170 "
                                        name="selectval"
                                        id="selectval"
                                        aria-label="Mark Options">
                                        <option value="">Select the option </option>
                                        <option value="1">Mark all as read</option>
                                        <option value="0">Mark all as unread</option>
                                    </select>
                                </div>
                                <div className="max-940 mx-auto">
                                    {notification.length == 0 ? (
                                        <p>You are all caught up!</p>
                                    ) : (
                                        <ul className="school_notification">
                                            {notification.map((item: any) => (
                                                <li key={item?.id} className="read notficationsitems" id={`not_${item.id}`}>
                                                    <div className="d-flex flex-1">
                                                        <div className="newimg">
                                                            <img
                                                                src={item?.image?.length > 0 ? item.image : avatar}
                                                                alt={item.name}
                                                                className="img-fluid rounded-circle"
                                                                width="58"
                                                            />
                                                        </div>
                                                        <div className="detail flex-1 ms-3">
                                                            <p className="font-16 font-500 text-gray-41 mb-2">{item.message}</p>
                                                            {item.notification_type == 'user_registered'}
                                                            <a
                                                                href={getNotificationLink(item.notification_type, item.uuid)}
                                                                className="btn btn-green w-auto height-35 d-inline-flex text-white font-14 text-decoration-none">
                                                                <span>{getNotificationButtonText(item.notification_type)}</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="check notifychk">
                                                        <span className="font-14 font-500 text-dark-blue">
                                                            {getTimeAgo(item.date_created)}
                                                        </span>
                                                        <i className={`icon-checked ${item.is_read === 1 ? 'blue-tick' : ''}`}></i>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

function getTimeAgo(timestamp: any) {
    const eventTime = moment(timestamp); // Assuming 'timestamp' is the created_at value
    const currentTime = moment();
    const duration = moment.duration(currentTime.diff(eventTime));
    const daysAgo = duration.asDays();

    if (daysAgo < 1) {
        return 'Today';
    } else if (daysAgo < 2) {
        return 'Yesterday';
    } else {
        return `${Math.floor(daysAgo)} days ago`;
    }
}

function getNotificationLink(notificationType: string, uuid: string) {
    switch (notificationType) {
        case 'user_registered':
            return '/alumni/directory';
        case 'achievement_added':
            return `/alumni/${uuid}/profile`;
        case 'message_received':
            return '/messages';
        case 'album_created':
            return '/album';
        case 'achievement_created':
            return `/alumni/${uuid}/profile/achievements`;
        case 'job_posted':
            return '/jobs';
        // Add more cases for other notification types
        default:
            return `/alumni/${uuid}/profile`;
    }
}

function getNotificationButtonText(notificationType: string) {
    switch (notificationType) {
        case 'user_registered':
            return 'Click to view profile';
        case 'achievement_added':
            return 'Click to view their profile';
        case 'message_received':
            return 'Click to view';
        case 'album_created':
            return 'Click to view their album';
        case 'achievements':
            return 'Click to view their profile';
        case 'job_posted':
            return 'Click to view jobs';
        case 'message_received':
            return 'Click to view';
        // Add more cases for other notification types
        default:
            return 'Click to view';
    }
}

export default AlumniNotification;
