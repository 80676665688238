import { isFormValid } from '../../../../library/Core/SmartFunctions';
import { State, DispatchEvent, ControlOnChangeArguments } from '../../../../library/Core/SmartTypes';
import StudentService from '../../../../services/student.service';

export const handleSubmit = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    if (!isFormValid(state, dispatch)) {
        alert('Please correct the form errors!');
        return;
    }
    dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [] });

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            // firstName: state.data['firstName'],
            // lastName: state.data['lastName'],
            // mobileCountryCode: state.data['mobileCountryCode'],
            // mobileNumber: state.data['mobileNumber'],
            // registeredEmailId: state.data['registeredEmailId'],
            user: state?.data['user'],
            profile: state?.data['profile'],
            schools: state?.data['schools'],
            schoolCurrent: state?.data['schoolCurrent'],
            schoolClass10: state?.data['schoolClass10'],
            schoolClass12: state?.data['schoolClass12'],
        })
    ).then(() => {
        state.actions['ROUTER_NAVIGATE'](`/student/${state.routeInfo.id}/profile`);
    });
};

//Validation check for length based on country code
export const onMobileNumberChange = (props: ControlOnChangeArguments) => {
    console.log(props.value);
    const mobileCountryCode = props.row['mobileCountryCode'];
    const mobileNumber = props.value;

    if (mobileNumber?.length == 0) {
        props.errorMessages.push('Please enter mobile number');
        return;
    }

    if (mobileCountryCode != '100') return;

    const errorMessage = 'Please enter a valid mobile number';

    if ((mobileNumber?.length > 10 && mobileCountryCode == 100) || (mobileNumber?.length < 10 && mobileCountryCode == 100)) {
        props.errorMessages.push(errorMessage);
    }

    if (parseInt(props.value.charAt(0)) < 6) props.errorMessages.push(errorMessage);
};
