import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation
import useSmartState from '../../../common/hooks/useSmartState';
import { SmartContext } from '../../../library/Core/SmartContext';
import ManageAlumniService from './ManageAlumniService';
import SearchCriteria from './SearchCriteria';

const Layout = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const [fuzzySearchText, setFuzzySearchText] = useState('');
    const [showSearchCriteria, setShowSearchCriteria] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [userTypeCode, setUserTypeCode] = useState(sessionStorage.getItem('user-type-code'));

    const navigate = useNavigate(); // Initialize useNavigate
    const location = useLocation(); // Initialize useLocation

    // Parse query params to get the 'type'
    const queryParams = new URLSearchParams(location.search);
    const type = parseInt(queryParams.get('type') || '0', 10);
    let templateId = parseInt(queryParams.get('template_id') || '0', 10);
    

    // Get 'type' from query params, default to 0 if not found
    // alert(templateId);
    const isStudentPage = window.location.pathname.includes('student-profiles');

    const handleFuzzySearch = () => {
        if (!isInitialLoad) {
            const searchCriteria = {
                ...state.data.searchCriteria,
                name: fuzzySearchText,
            };
            ManageAlumniService.search(searchCriteria, state.routeInfo.pageName).then((response: any) =>
                setStateValue('alumniList', response.data)
            );
        }
    };

    const handleOnChange = (event: any) => {
        setFuzzySearchText(event.target.value);
    };

    useEffect(() => {
        setIsInitialLoad(false);
        handleFuzzySearch();
    }, [fuzzySearchText]);

    useEffect(() => {
        setFuzzySearchText('');
    }, [state.data.searchCriteria]);

    const handleToggleSearchCriteria = () => {
        setShowSearchCriteria(!showSearchCriteria);
    };

    const handleStudentClick = () => {
        navigate(`/broadcast/student-profiles?template_id=${templateId}&type=${type}`); // Redirect to /student
    };
    const handleAlumniClick = () => {
        navigate(`/broadcast/alumni-profiles?template_id=${templateId}&type=${type}`); // Redirect to /student
    };

    return (
        <div className="main flex-1">
            <section className="updateadditionalinformation">
                <div className="container max-1140 px-lg-0 overflow-hidden">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius p-0 h-100">
                                <div className="p-4 p-md-5">
                                    <div className="alumni_statistics">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
                                            <h2 className="font-26 font-600 mb-0 mt-2 mt-sm-3 block-title">
                                                {type === 5
                                                    ? 'Ask Google Review'
                                                    : type === 6
                                                    ? 'Ask for Referral'
                                                    : userTypeCode === 'SUPER_ADMIN'
                                                    ? 'Student Profile Report'
                                                    : state.formConfig?.title}
                                            </h2>
                                            <div className="dashboard innerpage smart-scroll">
                                                {(type === 5 || type === 6) && (
                                                    <>
                                                        {isStudentPage ? (
                                                            <>
                                                                <button className="btn btn-green me-2">
                                                                    <span>Student</span>
                                                                </button>
                                                                <button
                                                                    className="btn btn-border-gray height-40 overflow-hidden "
                                                                    onClick={handleAlumniClick}>
                                                                    <span>Alumni</span>
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <button
                                                                    className="btn btn-border-gray height-40 overflow-hidden"
                                                                    onClick={handleStudentClick}>
                                                                    <span>Student</span>
                                                                </button>

                                                                <button className="btn btn-green me-2 ms-2 height-40">
                                                                    <span>Alumni </span>
                                                                </button>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-between align-items-start align-items-md-center mb-2 filterSearch">
                                            <div className="d-flex flex-wrap flex-1 mb-0 justify-content-start">
                                                <button className="btn-grpfiter me-3" type="button" onClick={handleToggleSearchCriteria}>
                                                    <i className="icon-filter font-13 me-2"></i>
                                                    Filters
                                                </button>
                                                <div className="search alumniSearch m-0 open position-static flex-1">
                                                    <div className="form-inline d-flex flex-wrap justify-content-between d-block">
                                                        <input
                                                            value={fuzzySearchText}
                                                            placeholder={state.data.reportConfig.fuzzySearchPlaceholder}
                                                            aria-label="Search"
                                                            className="form-control flex-1 font-13 font-500 text-truncate"
                                                            onChange={handleOnChange}
                                                        />
                                                        <button
                                                            className="btn btn-search icon icon-search"
                                                            onClick={handleFuzzySearch}></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {showSearchCriteria && <SearchCriteria />}
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Layout;
