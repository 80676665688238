import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import {
    addUserSpecificDomains,
    getDomainElementsForGivenYearRange,
    getDomainWithYearRange,
} from '../../../../library/Service/domainService';
import { handleNext, onStartOrEndYearChange } from './AlumniBreakActions';
import AlumniBreakLayout from './AlumniBreakLayout';
import SessionContext from '../../../../library/Core/SessionContext';

const AlumniBreak = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { SAVE: handleNext, ROUTER_NAVIGATE: navigate, REDIRECT: setRedirectUrl, fromDate: onStartOrEndYearChange },
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    useEffect(() => {
        data['alumniBreak'] = data['alumniBreak'] || {};
        const domainMap = new Map([...globalState.domain]);
        domainMap.set('INSTITUTE_PROGRAM_CODE', internal);
        const domain = addUserSpecificDomains(new Map([...domainMap]), data);

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        domain.set(
            'BREAK_START_YEAR_CODE',
            getDomainElementsForGivenYearRange('BREAK_START_YEAR_CODE', parseInt(data.selectedInstitute.passOutYear) + 1, currentYear)
        );

        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <AlumniBreakLayout>{!state.flags.isDataLoading && <PageBuilder />}</AlumniBreakLayout>
        </SmartContext.Provider>
    );
};

export default AlumniBreak;
