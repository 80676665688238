import { axiosClient } from '../library/Service/axiosClient';

const API_URL = `${process.env.REACT_APP_JOB_SERVER}/jobs`;

class JobPostingService {
    static toDto(data) {
        const jobPostingData = { ...data };
        // jobPostingData.jobPostVisibleTo = jobPostingData?.jobPostVisibleTo.split(',').map((visibleTo) => ({ visibleTo })) ?? [];

        // jobPostingData.jobPostVisibleToAlumni = jobPostingData?.jobPostVisibleToAlumni
        //     .split(',')
        //     .map((jobPostVisibleToAlumni) => ({ jobPostVisibleToAlumni }));

        // jobPostingData.jobPostVisibleToStudents = jobPostingData?.jobPostVisibleToStudents
        //     ?.split(',')
        //     .map((jobPostVisibleToStudents) => ({ jobPostVisibleToStudents }));
        return jobPostingData;
    }

    static toEntity(data) {
        const jobPostingData = { ...data };
        //jobPostingData.jobPostVisibleTo = jobPostingData?.jobPostVisibleTo.map((item) => item.visibleTo).join(',');
        //jobPostingData.jobPostVisibleToAlumni = jobPostingData?.jobPostVisibleToAlumni.map((item) => item.jobPostVisibleToAlumni).join(',');
        // jobPostingData.jobPostVisibleToStudents = jobPostingData?.jobPostVisibleToStudents
        //     ?.map((item) => item.jobPostVisibleToStudents)
        //     .join(',');

        return jobPostingData;
    }

    static async saveJobPosting(data) {
        const jobPostingData = this.toEntity(data);

        let response = null;
        if (data.id == null) {
            response = await axiosClient().post(`${API_URL}`, {
                jobPosting: jobPostingData,
            });
        } else {
            response = await axiosClient().put(`${API_URL}/${data.id}`, {
                jobPosting: jobPostingData,
            });
        }

        return response.data;
    }

    static async getJobPostingById(id) {
        if (id === 'new') return Promise.resolve({ data: {} });
        const response = await axiosClient().get(`${API_URL}/${id}`);
        const jobPostingData = response.data;

        return { data: this.toDto(jobPostingData) };
    }
}

export default JobPostingService;
