import { useState } from 'react';
import TopSearchProperties from './TopSearchProperties';
import TopSearchGrid from './TopSearchGrid';

const TopSearchTab = (props: any) => {

  const [department, setDepartment] = useState('');
  const [course, setCourse] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [loading, setLoading] = useState('');

  const handleDepartmentChange = (value: string) => {
    setDepartment(value);
  };

  const handleCourseChange = (value: string) => {
    setCourse(value);
  };
  
  const handleCountryChange = (value: string) => {
    setCountry(value);
  };
  
  const handleStateChange = (value: string) => {
    setState(value);
  };

  const handleCityChange = (value: string) => {
    setCity(value);
  };

  const loadingChange = (value: string) => {
    setLoading(value);
  };

  return (
    <>
      <div className="row m-0 pt-4 pb-5 mt-2 JobInternshipListing">
            <div className="col-md-12 p-0">
                <p className="font-16 mb-4">College search tools to help you find colleges from a list of over 2000 in India and across the globe, matching your preferences.</p> 
            </div>
            <TopSearchProperties 
              onDepartmentChange={handleDepartmentChange}
              onCourseChange={handleCourseChange}
              onCountryChange={handleCountryChange}
              onStateChange={handleStateChange}
              onCityChange={handleCityChange}
              loading={loading}
              onLoaderChange={loadingChange}
            /> 
            <TopSearchGrid 
            fetchDepartment={department} 
            fetchCourse={course} 
            fetchCountry={country} 
            fetchState={state} 
            fetchCity={city} 
            hasRanking={props.hasRanking}
            loading={loading}
            onLoaderChange={loadingChange}
            />                                                                             
        </div>
    </>
  )
}

export default TopSearchTab;