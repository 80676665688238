import { useContext } from 'react';
import { courseAlumniProfile, getStudentImage, getUniversityImage, getCompanyImage } from '../../common/styles/Images';
import { SmartContext } from '../../library/Core/SmartContext';
import { getDomainValueForCode } from '../../library/Core/SmartFunctions';
import { Link } from 'react-router-dom';

const AlumniPostGradCardControl = (item: any) => {
    const { state, dispatch } = useContext(SmartContext);

    const handleSendMessage = (event: any, id: string) => {
        event.preventDefault();
        state.actions['ROUTER_NAVIGATE'](`/messages/send-message/${id}`);
    };

    return (
        <div key={item.uuid} className="card_profiles card_profiles_pg">
            <div className="d-flex">
                <figure className="student">
                    <Link to={`/alumni/${item?.uuid}/profile`} target="_blank" rel="noopener noreferrer">
                        <img src={getStudentImage(item)} />
                    </Link>
                </figure>
                <div className="student_details">
                    <h2>
                        {/* <a href={`/alumni/${item?.uuid}/profile`} target='_blank'>{item.name}</a> <span>Class of {item.passOutYear}</span> */}
                        <Link to={`/alumni/${item?.uuid}/profile`} target="_blank" rel="noopener noreferrer">
                            {item.name}
                        </Link>
                        {/* <span>Class of {item.passOutYear}</span> */}
                        <span>{item.programName}</span>
                    </h2>
                    <a className="btn-send" role="button" onClick={(event) => handleSendMessage(event, item.uuid)}>
                        <i className="icon-send"></i>
                        <span>Send</span>
                    </a>
                    <div className="sub_details">
                        <figure className="">
                            <a href="">
                                <img src={getUniversityImage(item.collegeIconFilePath)} alt="" />
                            </a>
                        </figure>
                        <h3 className="d-flex flex-column">
                            <p className="title">Post Graduate</p>
                            <p>
                                {/* <a href="">{item.pguniversityName}</a> */}
                                {/* <a href="">{item.universityName}</a> */}
                                {/* {item.universityName} */}
                                {item.universityName ? item.universityName : getDomainValueForCode(item.pgUniversityId, 'UNIVERSITY_CODE', state)}
                            </p>
                            <span className="course">
                                <img src={courseAlumniProfile} width="16" className="img-fluid" />
                                {getDomainValueForCode(item.pgFieldOfStudyCode, 'FIELD_OF_STUDY_TYPE_CODE', state)}
                                {/* {item.programName} */}
                            </span>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlumniPostGradCardControl;
