import { isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import logger from '../../../library/Core/logger';
import AlbumPostService from './album.service';

export const onAlbumPost = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    let albumData = {} as any;

    albumData = state.data['memories'];
    let id = state.routeInfo.id;

    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    if ( !isFormValid(state, dispatch) || ! customValidationPassed ) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    AlbumPostService.saveAlbum({ ...state.data['memories'], studentId: sessionState?.studentId }).then((response) => {
        // dispatch({ type: 'REFRESH_DATA', payload: { data: { jobPosting: JobPostingService.toDto(response) } } });
        state.actions['ROUTER_NAVIGATE'](`/memories`);
    });
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const memories = state.data.memories;

    if ( isEmpty(memories?.albumContentSet) ) {
        isFormInvalid = true;
        const err = `Please add atleast one photo or video`;
        // setError(`memories`, [`Please add atleast one photo or video`], dispatch);
        setError(`memories.albumContentSet`, [err], dispatch);
        errorMessages.push(err);
    } else {
        setError(`memories.albumContentSet`, [], dispatch);
    }

    // Dispatch the validation errors to the state
    if ( isFormInvalid ) {
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'memories.albumContentSet', errorMessages },
        });
    }
    
    return !isFormInvalid;
};

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    logger.log(state.data['achievements']);

    if (!isFormValid(state, dispatch)) {
        alert('Please correct the form errors!');
        return;
    }
    // pageSave(
    //     state.routeInfo.id,
    //     state.routeInfo.pageName,
    //     toStudentEntity({ id: state.routeInfo.id, achievements: state?.data['jobPosting'] })
    // ).then(() => navigateToNextStep('jobPosting', state));
};
