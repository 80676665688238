import { useContext, useRef, useState } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, handleControlValueChange } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const DateAndMonthSelector = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = args;
    const data = getControlValueFromState(dataKey, state as State);

    const [day, setDay] = useState(data == null ? undefined : new Date(data).getDate());
    const [month, setMonth] = useState(data == null ? undefined : new Date(data).getMonth() + 1);
    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let localDay: any = -1;
        let localMonth: any = -1;

        if (event.target.id === 'day') {
            localDay = parseInt(event.target.value);
            localMonth = month;
            setDay(localDay);
        } else {
            localDay = day;
            localMonth = parseInt(event.target.value);
            setMonth(localMonth);
        }

        if (localDay > 0 && localMonth > 0) {
            const date = `1971-${localMonth < 10 ? '0' + localMonth : localMonth}-${localDay < 10 ? '0' + localDay : localDay}`;
            handleControlValueChange({ control, value: date, dataKey, parentDataKey, state, dispatch });
        } else {
            handleControlValueChange({ control, value: null, dataKey, parentDataKey, state, dispatch });
        }
    };

    const getDayOptions = (size: number) =>
        Array.from(Array(size).keys()).map((number) => (
            <option key={number + 1} value={number + 1}>
                {number + 1}
            </option>
        ));

    const getMonthOptions = () => {
        const monthNames = ['-Select-', 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        return monthNames.map((month, index) => (
            <option key={index} value={index}>
                {month}
            </option>
        ));
    };

    return (
        <>
            <label htmlFor={control.id} className="form-label m-0 mb-1 font-16 font-500 w-100">
                {`${control.props.label} `}
            </label>
            <div className="input-group flex-nowrap">
                <span className="input-group-text" id="basic-addon1">
                    DD
                </span>
                <select
                    id="day"
                    className={`form-select`}
                    value={day}
                    required={control.props.required}
                    onChange={handleChange}
                    ref={formControlRef}>
                    {!data && <option value={''}>{'Select'}</option>}
                    {getDayOptions(31)}
                </select>
                <span className="input-group-text" id="basic-addon1">
                    MM
                </span>
                <select
                    id="month"
                    className={`form-select`}
                    value={month}
                    required={control.props.required}
                    onChange={handleChange}
                    ref={formControlRef}>
                    {getMonthOptions()}
                </select>
            </div>
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default DateAndMonthSelector;
