import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { axiosClient } from '../../../../library/Service/axiosClient';
import { Modal } from 'bootstrap';
import { classNames } from 'react-easy-crop/helpers';
import { getDomainValueForCode, getFlexColDomainValueForCode, isEmpty } from '../../../../library/Core/SmartFunctions';
import { getCompanyImage } from '../../../../common/styles/Images';

const UserInteraction = () => {
    const { state, dispatch } = useContext(SmartContext);
    const userid = state?.data?.id;
    const [popupVisible, setPopupVisible] = useState(false);
    const [studentImage, setStudentImage] = useState('');
    const [studentSchoolName, setStudentSchoolName] = useState('');
    const [studentClassCode, setStudentClassCode] = useState('');
    const [studentName, setStudentName] = useState('');
    const [conversationData, setConversationData] = useState([]);
    const [passoutYear, setPassoutYear] = useState('');

    const data = state.data;
    const model = state.internal.model;

    let current_name = '';
    let current_field = '';
    let current_status = '';
    if (data?.profile?.workingStatusCode === 'YES') {
        let job = data?.jobs?.filter((job: any) => job.isCurrent === true);
        job = job.length === 1 ? job : data?.jobs;
        let flexCol: any = getFlexColDomainValueForCode(job[0]?.companyId, 'COMPANY_CODE', state);
        current_name = job[0]?.companyId === null ? job[0]?.otherCompany : getDomainValueForCode(job[0]?.companyId, 'COMPANY_CODE', state);
        current_status = 'Working';
    } else if (!isEmpty(data?.postGradCollege)) {
        const pg = model?.postGradColleges[0];
        current_name = pg?.universityName;
        current_status = 'Pursuing PG';
    } else {
        current_name = model?.joinedCollege?.universityName;
        current_field = model?.joinedCollege?.fieldOfStudy;
        current_status = 'Pursuing UG';
    }

    const displayConversationWithstudent = async (
        conversationId: string,
        fullName: string,
        programName: string,
        imagePath: string,
        studentId: string,
        schoolName: string,
        passoutYear:string
    ) => {
        const response = await axiosClient().get(
            `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/view-message-by-id/${conversationId}`
        );
        if (response.status === 200) {
            setConversationData(response.data);
        }
        setPopupVisible(true);
        setStudentImage(imagePath);
        setStudentClassCode(programName);
        setPassoutYear(passoutYear);
        setStudentName(fullName);
        setStudentSchoolName(schoolName);
        const modalElement = document.getElementById('conversationList');
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }
    };

    const [selectedMessageType, setSelectedMessageType] = useState('');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [conversations, setConversations] = useState([]);

    useEffect(() => {
        fetchConversations(selectedMessageType, startDate, endDate);
    }, [selectedMessageType, startDate, endDate]);

    const fetchConversations = async (messageType: any, startDate: any, endDate: any) => {
        try {
            const response = await axiosClient().get(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/get-message/${userid}`, {
                params: {
                    messageType: messageType,
                    startDate: startDate,
                    endDate: endDate,
                },
            });

            if (response.status === 200) {
                setConversations(response.data);
            }
        } catch (error) {
            console.error('Error fetching conversations:', error);
            setConversations([]);
        }
    };
    function formatDate(dateString: any) {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    return (
        <>
            <div className="underGraduate-detail">
                <div className="d-flexb flex-wrap justify-content-between align-items-start align-items-md-center mb-2 filterSearch">
                    <div className="d-flex flex-wrap flex-1 mb-0 justify-content-start">
                        <button
                            className="btn-grpfiter me-3"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne">
                            <i className="icon-filter font-13 me-2"></i>
                            Filters
                        </button>
                    </div>
                </div>
                <div className="fitergroup mb-3">
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                        <div className="accordion-body p-4">
                            <form className="row g-3">
                                <div className="col-md-3">
                                    <select
                                        className="form-select"
                                        onChange={(e) => setSelectedMessageType(e.target.value)}
                                        name="message_type"
                                        aria-label="Default select example">
                                        <option value="">Status</option>
                                        <option value="received">Received message from student</option>
                                        <option value="replied">Replied to student</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <div className="datewrap">
                                        <label id="config-demo" className="daterangePickerList"></label>
                                        <div className="from">
                                            <input
                                                onChange={(e) => setStartDate(e.target.value)}
                                                value={startDate}
                                                type="date"
                                                data-name="from"
                                                placeholder="Filter messages from"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="arrow">
                                            <span className="icon-right"></span>
                                        </div>
                                        <div className="to">
                                            <input
                                                onChange={(e) => setEndDate(e.target.value)}
                                                type="date"
                                                data-name="to"
                                                placeholder="Filter messages to"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="table-responsive attendee_dtl">
                    <table className="table" style={{ minWidth: '958px' }}>
                        <thead>
                            <tr>
                                <th style={{ width: '250' }}>
                                    <div className="d-flex flex-wrap">Name &amp; Class</div>
                                </th>
                                <th style={{ width: '180' }}>Campus</th>
                                <th style={{ width: '400' }}>
                                    <a className="labelItem" href="javascript:;">
                                        Conversation
                                    </a>
                                </th>
                                <th style={{ width: '150px' }}>
                                    <a className="labelItem" href="javascript:;">
                                        Date
                                    </a>
                                </th>
                            </tr>
                        </thead>
                        <tbody id="StudentData">
                            {conversations?.map((conversation: any) => (
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center checkoption">
                                            <a className="tablink" href="javascript:;">
                                                <i className="me-2 position-relative">
                                                    <img
                                                        src={conversation.profilePictureFilePath}
                                                        className="rounded-circle img-fluid"
                                                        width="30"
                                                    />
                                                </i>
                                                <span
                                                    data-bs-toggle="tooltip"
                                                    title={conversation.fullName}
                                                    data-bs-original-title="Anuradha Gautam"
                                                    className="singlelinename font-16 lineHeight-16">
                                                    {conversation.fullName}
                                                    <span  title={conversation.programName} className="d-block font-11 fst-italic">{conversation.programName}-{conversation.passoutYear}</span>
                                                </span>
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                                            {conversation.schoolName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex flex-wrap align-items-center"> {conversation.messageStatus}</div>
                                        <button
                                            className="link"
                                            onClick={(e) =>
                                                displayConversationWithstudent(
                                                    conversation.conversationId,
                                                    conversation.fullName,
                                                    conversation.programName,
                                                    conversation.profilePictureFilePath,
                                                    conversation.studentId,
                                                    conversation.schoolName,
                                                    conversation.passoutYear
                                                )
                                            }>
                                            View entire conversation
                                        </button>
                                    </td>
                                    <td>{formatDate(conversation.lastMessageCreatedAt)} </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div
                className="modal fade show"
                id="conversationList"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-modal="true"
                role="dialog">
                <div className="modal-dialog modal-dialog-centered max-800 mx-auto">
                    <div className="modal-content half-gray">
                        <div className="modal-header">
                            <button type="button" className="btn-close btn-close-small" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-sm-6 mb-2 mb-sm-0">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <i className="me-2">
                                                <img src={studentImage} className="rounded-circle img-fluid" width="47" />
                                            </i>
                                            <span className="font-16 lineHeight-16 flex-1 d-flex flex-column align-items-end align-items-sm-start">
                                                <span className="font-16 font-500 text-dark-blue">
                                                    {studentName}, <span className="fst-italic">{studentClassCode},{passoutYear}</span>
                                                </span>
                                                <span className="d-block font-14 font-500 fst-italic text-dark-blue">
                                                    {studentSchoolName}{' '}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="d-flex flex-wrap align-items-center flex-row-reverse flex-lg-row pe-md-4">
                                            <i className="ms-2 ms-lg-0 me-0 me-lg-2">
                                                <img
                                                    src={state?.data.profile.profilePictureFilePath}
                                                    className="rounded-circle img-fluid"
                                                    width="47"
                                                />
                                            </i>
                                            <span className="lineHeight-16 flex-1">
                                                <span className="font-16 font-500 text-dark-blue">
                                                    {state?.data?.firstName + ' ' + state?.data?.lastName},{' '}
                                                    <span className="fst-italic">{state?.data?.programName}</span>
                                                </span>
                                                <span className="d-block font-14 font-500 fst-italic text-dark-blue">
                                                    {current_status} at {current_name}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body px-md-4 pb-4">
                            <div className="conversation gap">
                                {conversationData.map((message: any, index: number) => (
                                    <div
                                        className={
                                            message?.senderId == state?.data?.id ? 'row justify-content-end' : 'row justify-content-start'
                                        }>
                                        <div className="col-sm-6">
                                            <div className="d-flex flex-wrap">
                                                <div className="messages leftUser">
                                                    <p>
                                                        {/* <input className="form-check-input" type="checkbox" value="" id="Replied" /> */}
                                                        {message.messageContent}
                                                    </p>
                                                    <span className="date">{message.createdAt}</span>
                                                </div>
                                                {/* <div className="userimg ms-2">
                                                    <img src="images/ravi_verma_icon.png" className="rounded-circle img-fluid" width="47" />
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="modal-footer">
                            {/* <a
                                href="javascript:;"
                                className="btn-good-qa"
                                data-bs-dismiss="modal"
                                data-bs-toggle="modal"
                                data-bs-target="#update-marking">
                                Send as Good Q&amp;A
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserInteraction;
