import React, { useContext, useEffect } from 'react';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, getControlValueFromState, handleControlValueChange, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const SwitchWithInlineLabelControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const isHidden = evaluateExpression(control.hideExpression, state?.data);

    useEffect(() => {
        // TODO: Following change of "In case of the default values, update the state using the default value" to be added in all the controls
        if (control.props.defaultValue != null && data == null)
            handleControlValueChange({ control, value: control.props.defaultValue, dataKey, parentDataKey, state, dispatch });

        const errorMessages = validateFormField(control, data, state, control?.props?.label, dataKey);
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked ? true : false;
        handleControlValueChange({ control, value, dataKey, parentDataKey, state, dispatch });
    };

    //    if (isHidden) return <></>;

    return (
        <div>
            <div className="form-check form-switch">
                <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id={control.id}
                    checked={isEmpty(data) ? Boolean(control.props.defaultValue) : Boolean(eval(data))}
                    style={{ width: '70px', height: '32px' }}
                    onChange={(event) => handleChange(event)}
                />
                {control.props.label && <label className={`form-label w-100 ${control.props.labelCSS}`}>{`${control.props.label} `}</label>}  
            </div>
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </div>
    );
};

export default SwitchWithInlineLabelControl;
