import { AxiosResponse } from 'axios';
import { getValueFromSessionStore, storeAppConfig } from '../../../library/Core/SessionStore';
import { convertDomainArrayToMap, isEmpty } from '../../../library/Core/SmartFunctions';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
import AppSettingsService from '../../../services/app-settings.service';
import DomainDataService from '../../../services/domain-data.service';
import pageConfig from './PageConfig.json';

import { LocalStorageResponse } from '../../../library/Core/PageLoader';
const API_URL = `${process.env.REACT_APP_STUDENT_SERVER}/memories`;

class InstituteService {
    static getData = (
        pageName: string,
        id: string,
        searchBy: string
    ): Promise<any> | Promise<AxiosResponse<any, any>> | Promise<LocalStorageResponse> => {
        let data = [];

        if (id === 'new') {
            return Promise.resolve({});
        }

        return Promise.resolve(axiosClient().get(`${process.env.REACT_APP_ADMIN_API_SERVER}/institute/${id}`))
            .then((data) => {
                return InstituteService.getAppendData(data.data.data[0]);
                // return data.data.data[0];
            })
            .catch((error) => {
                // Handle error if needed
                console.error('Error fetching data:', error);
                throw error; // Re-throw the error to propagate it to the caller
            });
    };

    static pageLoader = (params: any) => {
        console.log("params",params)
        const state: any = {};
        const { id, pageName, searchBy } = params;
        return Promise.all([
            this.getPageConfig(pageName,id),
            InstituteService.getData(pageName, id, searchBy),
            DomainDataService.get(),
            AppSettingsService.get(),
        ])
            .then((values) => {
                state.formConfig = values[0];
                state.data = values[1];
                state.domain = values[2].data instanceof Map ? values[2].data : convertDomainArrayToMap(values[2].data);
                state.internal = { gridState: [] };
                state.routeInfo = { id, pageName };
                // storeDomainData(state.domain);
                storeAppConfig(values[3].data);
                return Promise.resolve(state);
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    static getPageConfig(pageName: string,id:string) {
        if(id==='new'){
            pageName='admin-institute-create'
        }
        const tabConfig = pageConfig.tabs.find(tab => tab.id === pageName);
        return Promise.resolve(tabConfig);
    }

    static getAppendData = (data: any) => {
        let currentStatus: { [key: string]: string }[] = [];
        
        // Safely parse the 'currentStatus' with better type-checking
        if (data.currentStatus) {
            try {
                currentStatus = JSON.parse(data.currentStatus);
            } catch (e) {
                console.error("Error parsing currentStatus:", e);
            }
        }
    
        // Helper function to process the status keys
        const processStatus = (key: string) => {
            const formattedKey = `is${key.charAt(0).toUpperCase()}${key.slice(1)}`;
    
            data[key] = false;
            data[formattedKey] = true;
    
            if (currentStatus.length > 0) {
                const statusValue = currentStatus[0][key];
                if (statusValue === 'Yes') {
                    data[key] = true;
                    data[formattedKey] = false;
                } else if (statusValue === 'No') {
                    data[key] = false;
                    data[formattedKey] = false;
                }
            }
        };
    
        // List of keys to process
        const currentStatusKeys = [
            'crmSubmitted', 'credSen', 'welcomeEmailSent', 'preSubmitted', 'preComplete', 
            'consentLetterSubmitted', 'consentLetterCorrection', 'awaitingMarketing', 'dataCaptured', 
            'escalationCoo', 'onboardingComplete', 'preApproved', 'alumniDataSubmitted', 
            'alumniDataClarification', 'hdLogoSubmitted', 'hdLogoCorrection', 
            'onboardingDelayed', 'raiseRedFlag', 'salesHead', 'preNotSubmitted'
        ];
    
        // Process each status field
        currentStatusKeys.forEach(processStatus);
    
        return data;
    };
    
    
}

export default InstituteService;
