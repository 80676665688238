import { useContext, useEffect } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import useStudentReport from '../student-management/useStudentReport';

const ReportLayout = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { test } = useStudentReport();

    useEffect(() => {
        dispatch({ type: 'ADD_ACTIONS', payload: { ...state.actions, BULK_ACTION: test } });
    }, []);

    return (
        <div className="main flex-1">
            <div className="max-1140 mx-auto d-flex mainContainer h-100">
                <div className="flex-1 d-flex flex-column">
                    <div className="pageContent flex-1 ps-0">
                        <div className="mt-4 mb-5 pb-4">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div className="white-block white-block-notopborderradius h-100 p-0">
                                        <div className="m-w-90 d-flex flex-wrap mt-3 ps-lg-5">
                                            <div className="headerTitle w-100">
                                                <h3 className="font-35 font-600 mb-0 block-title">Manage Students</h3>
                                            </div>
                                        </div>
                                        <div className="row m-0 pt-4 pb-5 JobInternshipListing ps-lg-5">
                                            <div className="d-flex flex-wrap justify-content-between align-items-start align-items-md-center mb-2 filterSearch">
                                                <div className="d-flex flex-wrap flex-1 mb-0 justify-content-start">
                                                    <button
                                                        className="btn-grpfiter me-3"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseOne"
                                                        aria-expanded="true"
                                                        aria-controls="collapseOne">
                                                        <i className="icon-filter font-13 me-2"></i> Filters
                                                    </button>
                                                    <div className="search alumniSearch m-0 open position-static flex-1">
                                                        <form className="form-inline d-flex flex-wrap justify-content-between d-block">
                                                            <input
                                                                type="search"
                                                                placeholder="Search by student name, email, mobile"
                                                                aria-label="Search"
                                                                className="form-control flex-1 font-13 font-500 text-truncate"
                                                            />
                                                            <button className="btn btn-search icon icon-search" type="submit"></button>
                                                        </form>
                                                        <a href="" className="btn-search-toggle icon-search"></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fitergroup mb-3">
                                                <div
                                                    id="collapseOne"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="headingOne">
                                                    <div className="accordion-body p-4">{props.searchCriteria}</div>
                                                </div>
                                            </div>
                                            {props.results}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportLayout;
