import { useContext, useRef, useState } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, getControlValueFromState, handleControlValueChange, debounce, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import { axiosClient } from '../Service/axiosClient';
import TextareaWithCursorManagement from './TextareaWithCursorManagement';
import ErrorControl from './ErrorControl';
import Swal from 'sweetalert2';

const AlbumPhotoUploderControl = (args: SimpleFormControlArguments) => {
    let { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };

    const data = getControlValueFromState(dataKey, state as State);
    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl
    const isHidden = evaluateExpression(control.hideExpression, state?.data);

    const [showModal, setShowModal] = useState(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<null | number>(null);

    const openVideoModalBox = () => {
        setShowModal(true);
    };

    function closeModal() {
        setShowModal(false);
    }

    const toggleModel = (index: number) => {
        setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    // const handleChangeDescription: any = (event: any, index: any) => {
    //     dispatch({
    //         type: 'CONTROL_VALUE_CHANGE',
    //         payload: { dataKey: `memories.albumContentSet.${index}.description`, value: event.target.value, errorMessages: [] },
    //     });
    // };
    // const handleChangeDescription = (event: any, index: any) => {
    //     const target = event.target;
    //     const value = target.value;
    //     const startPos = target.selectionStart;
    //     const endPos = target.selectionEnd;

    //     const updatedAlbumContentSet = state.data['memories']['albumContentSet'].map((album: any, idx: any) => {
    //         if (idx === index) {
    //             return { ...album, description: value };
    //         }
    //         return album;
    //     });

    //     handleControlValueChange({
    //         control,
    //         value: updatedAlbumContentSet,
    //         dataKey: 'memories.albumContentSet',
    //         parentDataKey,
    //         state,
    //         dispatch,
    //     });

    //     setTimeout(() => {
    //         if (target && document.activeElement === target) {
    //             target.setSelectionRange(startPos, endPos);
    //         }
    //     }, 0);
    // };

    // Assuming AlbumPhotoUploaderControl is a functional component

    const handleChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number, newValue: string) => {
        // Logic to prepare for the state update
        const updatedAlbumContentSet = state.data['memories']['albumContentSet'].map((album: any, idx: number) => {
            if (idx === index) {
                return { ...album, description: newValue };
            }
            return album;
        });

        // Perform the state update
        handleControlValueChange({
            control,
            value: updatedAlbumContentSet,
            dataKey: 'memories.albumContentSet',
            parentDataKey,
            state,
            dispatch,
        });
    };

    const debouncedHandleChangeDescription = debounce(handleChangeDescription, 1000);

    const deleteContent = (index: number) => {
        dispatch({ type: 'DELETE_RECORD_IN_ARRAY', payload: { dataKey: 'memories.albumContentSet', index } });
    };

    const setAsThumbnail = (index: number) => {
        state.data['memories']['albumContentSet'].map(function (each: any, index: number) {
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: `memories.albumContentSet.${index}.isThumbnail`, value: false, errorMessages: [] },
            });
        });
        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: `memories.albumContentSet.${index}.isThumbnail`, value: true, errorMessages: [] },
        });
    };

    function AlbumList() {
        return (
            <>
                {state.data['memories']['albumContentSet'].map((album: any, index: number) => (
                    <div key={`${album.id}-${index}`} className="d-flex align-items-center flex-wrap albumadd p-3">
                        <div className="position-relative">
                            {album.type === 'video' ? (
                                <img
                                    className="img-thumbnail"
                                    src={`${process.env.REACT_APP_IMAGE_BASEPATH}/20230216_230234_.png`}
                                    style={{ width: '200px', height: '110px' }}
                                />
                            ) : (
                                <img
                                    onClick={() => setAsThumbnail(index)}
                                    className="img-fluid img-thumbnail"
                                    src={album.link}
                                    style={{ width: '200px', height: '110px' }}
                                />
                            )}

                            {album.isThumbnail === true && (
                                <span className="list_badge thumbbadge font-16 font-500 position-absolute top-0 end-0">Thumbnail</span>
                            )}
                        </div>

                        <div className="flex-1 mx-3">
                            {/* <TextControl
                                key={index}
                                control={control}
                                dataKey="description"
                                parentDataKey={('memories.albumContentSet.description.' + index) as string}></TextControl> */}
                            {/* 
                            <input
                                id={control.id}
                                data-testid={control.id}
                                type="text"
                                // hidden={control.props?.isCode || isHidden}
                                className={`form-control pl-0`}
                                placeholder={control.props?.placeholder}
                                value={data || control.props.defaultValue || ''}
                                required={control.props?.required}
                                onChange={(event) =>
                                    handleControlValueChange({
                                        control,
                                        value: event.target.value,
                                        dataKey: parentDataKey + '.albumContentSet.' + index + '.' + 'description',
                                        parentDataKey,
                                        state,
                                        dispatch,
                                    })
                                }
                                minLength={control.props?.minLength}
                                maxLength={control.props?.maxLength}
                                min={control.props?.min}
                                max={control.props?.max}
                                ref={formControlRef}
                            /> */}

                            <TextareaWithCursorManagement
                                key={`textarea-${index}`}
                                value={album.description}
                                onChange={(event, index, newValue) => debouncedHandleChangeDescription(event, index, newValue)}
                                index={index}
                                className="form-control"
                                placeholder="Add Description (Optional)"
                                name={`image_description_${index}`}
                                id={`image_description_${index}`}
                                rows={3}
                            />
                        </div>

                        <div className="position-relative albm_edit_settings">
                            <i
                                className="icon-setting text-gray-84 pe-0"
                                data-bs-toggle="dropdown2"
                                id="img_options"
                                onClick={() => toggleModel(index)}></i>

                            {openDropdownIndex === index && (
                                <ul className="dropdown-menu show position-absolute text-end" aria-labelledby="img_options">
                                    {/* <li>
                                        <a className="dropdown-item" href="javascript:void(0)">
                                            Edit
                                        </a>
                                    </li> */}
                                    <li>
                                        <a className="dropdown-item" href="javascript:void(0)" onClick={() => deleteContent(index)}>
                                            Delete
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="javascript:void(0)" onClick={() => setAsThumbnail(index)}>
                                            Set As Thumbnail
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                ))}
            </>
        );
    }

    function getVideoId(url: string): string | null {
        const regex = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/;
        const match = url.match(regex);
        return match ? match[1] : null;
    }

    const addVideoLink = (state: State) => {
        const youtubeLinkInput = document.getElementById('youtube_link') as HTMLInputElement;
        const API_KEY_YOUTUBE = 'AIzaSyAIaK4smPD8Rdh_2nKJaWr_7GYTPh2tRec';

        const videoId = getVideoId(youtubeLinkInput.value);
        if ( isEmpty ( videoId ) ) {
            Swal.fire("Please provide a valid Youtube Link..!");
            return false;
        }
        const url = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${API_KEY_YOUTUBE}&part=snippet&fields=items/snippet/thumbnails/maxres`;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if ( isEmpty ( data?.items) ) {
                    Swal.fire("Please provide a valid Youtube Link..!");
                    return false;
                }

                const thumbnailUrl = data?.items[0]?.snippet?.thumbnails?.maxres?.url;
                const errorMessages:any = [];
                dispatch({
                    type: 'SET_FIELD_VALIDATION_ERRORS',
                    payload: { dataKey: dataKey, errorMessages },
                });
                dispatch({
                    type: 'ADD_NEW_RECORD_IN_ARRAY',
                    payload: {
                        dataKey,
                        name: control,
                        value: {
                            link: thumbnailUrl,
                            youtubeLink: youtubeLinkInput.value,
                            linkType: 'video',
                            isThumbnail: false,
                            description: '',
                        },
                    },
                });
                youtubeLinkInput.value = "";
            });
    };

    const handleFileControlUpload = async (state: State, e: any) => {
        const errorMessages: any = [];
        
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: dataKey, errorMessages },
        });

        const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/image`;
        const formData = new FormData();
        formData.append('image', e.target.files[0]);

        const response = await axiosClient().post(apiUrl, formData);
        let link = response.data.link;

        const eventType = 'ADD_NEW_RECORD_IN_ARRAY';

        dispatch({
            type: eventType,
            payload: {
                dataKey,
                name: control,
                value: { link: link, youtubeLink: '', linkType: 'image', isThumbnail: false, description: '' },
            },
        });
    };

    return (
        <>
            {
                <div className="row">
                    <div className="col-lg-12 col-md-12 mb-3">
                        <label htmlFor="formFileLg" className="form-label">
                            {control.props.label}
                        </label>
                        <AlbumList />
                    </div>

                    <div className="col-lg-12 col-md-12 mb-3">
                        <label htmlFor={control.id} className="form-label">
                            Photos
                        </label>
                        <input
                            id={control.id}
                            type="file"
                            hidden={control.props?.isCode || isHidden}
                            className="form-control pl-0"
                            name="cover"
                            onChange={(event) => handleFileControlUpload(state as State, event)}
                            style={{ width: '100%' }}
                        />
                    </div>

                    <div className="col-lg-12 col-md-12 mb-3">
                        <label htmlFor="youtube_link" className="form-label">
                            Video
                        </label>
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control"
                                name="youtube_link"
                                placeholder="Enter Youtube Link"
                                id="youtube_link"
                            />
                            <button className="btn btn-secondary" onClick={() => addVideoLink(state as State)}>
                                Add video
                            </button>
                        </div>
                    </div>
                </div>
            }
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Enter Videos links</h2>
                        <input type="text" name="video_link" className="form-control flex-1" placeholder="Enter Video Link"></input>
                        <button onClick={closeModal}>Close Modal</button>
                    </div>
                </div>
            )}
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default AlbumPhotoUploderControl;
