import moment from 'moment';

const ViewCallLogHistoryModal = ({ data }: any) => {
    const { viewLogHistory } = data;

    return (
        <div
            className="modal fade px-3"
            id="view-call-history"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            style={{ display: 'none' }}
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered max-800 mx-auto">
                <div className="modal-content">
                    <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            View Send History
                        </h5>
                        <button type="button" className="btn-close btn-close-small" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <form className="needs-validation" noValidate>
                            <div className="row g-4">
                                <div className="col-md-12 mb-4">
                                    <div className="view-vertical">
                                        <div className="table-responsive">
                                            <table className="table w-750" style={{ minWidth: '750px' }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ width: '200px' }}>
                                                            Commented By
                                                        </th>
                                                        <th scope="col" style={{ width: '200px' }}>
                                                            Talked To
                                                        </th>
                                                        <th scope="col" style={{ width: '200px' }}>
                                                            Disposition
                                                        </th>
                                                        <th scope="col" style={{ width: '200px' }}>
                                                            Comment
                                                        </th>
                                                        <th scope="col" style={{ width: '200px' }}>
                                                            Time
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(viewLogHistory) &&
                                                        viewLogHistory.map((item: any, index) => (
                                                            <tr key={item.id || index}>
                                                                <td>{item?.created_by}</td>
                                                                <td>{item?.talked_to}</td>
                                                                <td>{item?.comment}</td>

                                                                <td>
                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                        <div className="me-3 font-500">{item?.user_comments}</div>
                                                                    </div>
                                                                </td>
                                                                <td>{moment(item?.created_at).format('DD-MMM-YYYY H:mm:ss')}</td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn-good-qa" data-bs-dismiss="modal" aria-label="Close">
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewCallLogHistoryModal;
