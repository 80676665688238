import { useContext } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState } from '../Core/SmartFunctions';
import { FormControl, SimpleFormControlArguments, State } from '../Core/SmartTypes';
import TextControl from './TextControl';

const SocialNetworkControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(parentDataKey as string, state as State);

    const socialNetworkDropDownControl = control.controlGroup.find((ctrl) => ctrl.id === 'socialNetworkCode') as FormControl;
    const linkControl = control.controlGroup.find((ctrl) => ctrl.id === 'link') as FormControl;
    const icone: any = {
        LINKEDIN: 'bi bi-linkedin',
        FACEBOOK: 'bi bi-facebook',
        TWITTER: 'bi bi-twitter',
        INSTAGRAM: 'bi bi-instagram',
    };
    return (
        <>
            <label className="form-label w-100">{`${control.props.label} `}</label>
            {data.map((row: any, index: number) => (
                <div key={index} className="input-group mb-2">
                    <span className="input-group-text" id="basic-addon1">
                        <i className={icone[row['socialNetworkCode']]}></i>
                    </span>
                    <TextControl
                        control={linkControl}
                        dataKey={parentDataKey + '.' + index + '.' + linkControl.id}
                        parentDataKey={parentDataKey}
                    />
                </div>
            ))}
        </>
    );
};

export default SocialNetworkControl;
