import { getValueFromSessionStore } from '../library/Core/SessionStore';
import { decompressData } from '../library/Core/SmartFunctions';
import { axiosClient } from '../library/Service/axiosClient';

class DomainDataService {
    static async get() {
        const storedData = getValueFromSessionStore(`domain-data`);
        const storedDataObj = storedData ? new Map(JSON.parse(storedData as string)) : new Map();
        if (storedDataObj.size > 20) return Promise.resolve({ data: storedDataObj, mode: 'local-storage' }) as any;

        //return axiosClient().get(`${process.env.REACT_APP_PAGE_CONFIG_SERVER}/v1/appConfig/domain_data`) as any;

        const domainDataResult = (await axiosClient().get(`${process.env.REACT_APP_PAGE_CONFIG_SERVER}/v1/appConfig/domain_data`)) as any;
        console.log(domainDataResult?.data);
        //console.log(this.decompressData(domainDataResult?.data));
        return { data: decompressData(domainDataResult?.data) };
    }
}

export default DomainDataService;
