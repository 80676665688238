import { useContext,useState,useEffect } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import Results from './Results';
import { SearchCriteria } from './SearchCriteria';
import SummaryCardControl from './SummaryCardControl';
import { useNavigate } from 'react-router';
import LoadingSpinner from '../../../library/SimpleControls/LoadingSpinner';

const Layout = () => {
    const { state, dispatch } = useContext(SmartContext);
    const [isAllSchoolSummary,setisAllSchoolSummary] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(window.location.pathname === '/support/all-school-summary'){
            setisAllSchoolSummary(true);
        }
    },[]);
    
    return (
        <div className="main flex-1">
            {/* <section className="updateadditionalinformation"> */}
            {/* <div className="container max-1140 px-lg-0 overflow-hidden"> */}
            {!state?.data && <LoadingSpinner />}
            {state?.data && <div className="row">
                <div className="col-md-12 mb-4">
                {isAllSchoolSummary && <SummaryCardControl></SummaryCardControl>}
                    <div className="white-block white-block-notopborderradius p-0 h-100">
                        <div className="p-4 p-md-5">
                            <div className="alumni_statistics">
                                <div className='row g-2'>
                                    <div className='col'>
                                        <h2 className="font-26 font-600 mb-0 pb-4">{state.formConfig?.title}</h2>
                                    </div>
                                    {state.routeInfo.pageName == "internal-admin-all-alumni-report" && (
                                        <div className='col-auto'>
                                            <button className='btn btn-success' onClick={() => {navigate('/support/create-new-user')}}>Create New User</button>
                                        </div>
                                    )}
                                </div>
                                <div className="ActiveListings_present">
                                    <SearchCriteria />
                                    <Results />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {/* </div> */}
            {/* </section> */}
        </div>
    );
};

export default Layout;
