const NoEventsFound = () => {
    return (
        <div className="flex-1 polarcontsec tab-content mt-38 aos-init aos-animate">
            <div className="no_listed_items_blk">
                <div>
                    <div className="max-480 text-center mx-auto">
                        <img
                            className="custimg float_horizontal_animate"
                            src={`${process.env.REACT_APP_IMAGE_BASEPATH}/notification_img.jpg`}
                            alt=""
                        />
                        <p className="mt-2 mb-3 pb-3 font-18 font-400">
                            Do you know what is better than attending an event? Taking initiative to host one when there are few to do so.
                            Webinars are not only informative but also a great way to connect!
                        </p>

                        <div className="dashboard innerpage smart-scroll scrolled-up">
                            {/* <a
                                data-aos="fade-right"
                                data-aos-delay="600"
                                className="btn btn-green w-auto mt-3 height-40 d-inline-flex text-white font-14 text-decoration-none job_internship_btn aos-init aos-animate">
                                <span>Request to Conduct Webinar</span>
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NoEventsFound;
