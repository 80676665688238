import logger from '../../library/Core/logger';
import { axiosClient } from '../../library/Service/axiosClient';
import PeerReview from './peer_review';

class PeerReviewService {
    static pageLoader(params: any) {
        const state: any = {};
        const { id, pageName } = params;

        return Promise.all([PeerReviewService.get()])
            .then((values) => {
                console.log(values, " values ")
                logger.log(`Retrieved values successfully for ${pageName}`);
                //state.formConfig = values[0].data;
                state.data = {
                    peerProfile: values[0].data,
                    //peerProfile: {},
                };
                state.routeInfo = { id, pageName };
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static get() {
        return axiosClient().get(`${process.env.REACT_APP_COMMON_API}/users/review-profiles`);
    }

    static async updateMyPeer(uuid: string,schoolId:any) {
        return await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/approve-peer-profiles`, { uuid,schoolId });
    }

    static async bulkApproveMyPeer(uuids: string, action: string) {
        return new Promise<void>((resolve, reject) => {
            // Simulating API call with setTimeout
            axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/bulk-action-on-profiles`, { uuids, action });
            setTimeout(() => {
                console.log(`Bulk approval for ids: ${uuids}`);
                resolve();
            }, 1000); // Simulated delay of 1 second
        });
    }

    static async discardMyPeer(uuid: string,schoolId:any) {
        return await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/discard-user`, { uuid,schoolId });
    }
}

export default PeerReviewService;
