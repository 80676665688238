import { useContext, useState } from 'react';
import { SmartContext } from '../../library/Core/SmartContext';
import { numberToOrdinal } from '../../library/Core/SmartFunctions';
import SchoolApiService from './SchoolApiService';
import moment from 'moment';
import { Alert } from 'bootstrap';

// function MyComponent() {

// }

const MatchList = ({ data, slot }: any) => {
    const seriesData = data?.filter((item: any) => item.slot === slot);
    const [isExpanded, setIsExpanded] = useState(false);

    if (!seriesData || !seriesData.length) return null;

    let first, second, others;

    if (seriesData.length === 1) {
        [first] = seriesData;
        others = [];
    } else {
        [first, second, ...others] = seriesData;
    }

    return (
        <>
            <ul className="match-list">
                {first && (
                    <li>
                        {first.name} - {first.batch}
                    </li>
                )}
                {second && (
                    <li>
                        {second.name} - {second.batch}
                    </li>
                )}
            </ul>
            {isExpanded && (
                <ul className="match-list">
                    {others.map((item, index) => (
                        <li key={index}>
                            {item.name} - {item.batch}
                        </li>
                    ))}
                </ul>
            )}
            {others.length > 0 && (
                <button onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? '- Less' : `+ ${others.length} More`}</button>
            )}
        </>
    );
};

const Grid = () => {
    const { state, dispatch } = useContext(SmartContext);
    const studentList = state.data.studentList;
    const [alertVisible, setAlertVisible] = useState(false);

    const handleClick = function (value: any) {
        // setAlertVisible(true)
        SchoolApiService.apiKeyGenerate(value);
        window.location.reload();
        // alert(`Clicked with value: ${value}`);
    };

    //console.log(studentList);

    return (
        <div>
            <div className="table-responsive attendee_dtl">
                {/* Display the alert when alertVisible is true */}
                {alertVisible && (
                    <div className="alert alert-success" role="alert">
                        Successfully Api Key Generated!
                        <button type="button" className="btn-close" onClick={() => setAlertVisible(false)}></button>
                    </div>
                )}

                <table className="table" style={{ minWidth: '1360px' }}>
                    <thead>
                        <tr>
                            <th>School Name</th>
                            <th>Group Name</th>
                            <th>Api Keys</th>
                            <th>Created Date</th>
                            <th>Api Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {studentList.map((student: any) => (
                            <tr>
                                <td className="text-left">{student.schoolName}</td>
                                <td className="text-left">{student.schoolGroupName}</td>
                                <td className="text-left">{student.apiKeys}</td>
                                <td className="text-left">{student.created ? moment(student.created).format('DD-MMM-YYYY') : 'Null'}</td>
                                <td className="text-left">
                                    {!student.apiKeys ? (
                                        <button onClick={handleClick.bind(this, student.schoolId)} className="btn-green">
                                            <span>Genrate Api Key</span>
                                        </button>
                                    ) : (
                                        <button className="btn-green">
                                            <span>Done</span>
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Grid;
