import HeaderControl from '../../alumni/_common/HeaderControl';
import MenuBuilder from '../../../routes/MenuBuilder';
import PageFooterControl from '../../../library/SimpleControls/PageFooterControl';
import pageImg from '../../../styles/images/learning-style.svg';
import { axiosClient } from '../../../library/Service/axiosClient';
import { useEffect, useState } from 'react';
import HESClient from './HESClient';

const Learningstyletest = () => {
  const [buttonCondition, setbuttonCondition] = useState('1');
  const [viewreportlink, setviewReportLink] = useState();
  const [continueassesment, setContinueAssesment] = useState();
  const [userid, setUserId] = useState();
  const [assessmentid, setassessmentId] = useState();
  const [testResponse, setTestResponse] = useState(null);
  const [StudentId, setStudentId] = useState();
  const [studentemail, setStudentEmail] = useState();
  const [studentfirst, setStudentFirst] = useState();
  const [studentlast, setStudentLast] = useState();
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
    window.location.reload();
  };

  useEffect(() => {
    const userDataString = sessionStorage.getItem('user');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      console.log(userData.registeredEmailId, 'registeredEmailId');
      console.log(userData.studentId, 'studentId');
      setStudentId(userData.studentId);
      console.log(StudentId, 'setstudentId as studentId');
      setStudentEmail(userData.registeredEmailId);
      setStudentFirst(userData.firstName);
      setStudentLast(userData.lastName);
    }
  }, []); // Only run once on mount

  useEffect(() => {
    const fetchData = async () => {
      console.log('Fetching data');
      const userDataString = sessionStorage.getItem('user');
      if (userDataString) {
        const userData = JSON.parse(userDataString);
        try {
          console.log(StudentId, 'studentId');
          const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/test/get-test-details-bystudentid-examid`, {
            'studentId': userData.studentId,
            'examId': 101,
          });
          const data = await response.data;
          if (data.success) {
            const res = data.data;
            console.log(res);
            if (res.length > 0) {
              const case1 = (res[0].test_status === 'Completed' && res[0].end_date !== undefined) ? '3' : '2';
              const report_link = (res[0].test_status === 'Completed' && res[0].end_date !== undefined) ? res[0].report_html_path : '';
              const test_link_url = (res[0].test_status === null && res[0].test_link_url !== null) ? res[0].test_link_url : '';
              console.log(case1);
              setbuttonCondition(case1);
              setviewReportLink(report_link);
              setContinueAssesment(test_link_url);
              setUserId(res[0].key2succeed_userid);
              setassessmentId(res[0].key2_assessment_id);
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          alert(error);
        }
      }
    };
    fetchData();
    // Set up an interval to fetch data every 5 minutes (adjust as needed)
    const intervalId = setInterval(() => {
      fetchData();
    }, 1 * 60 * 1000); // 1 minute
    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const continueAssesmentlink = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_COMMON_API}/keytwosuccess/get-user-token`;
      const requestData = {
        userid: userid,
        assessmentId: assessmentid,
        testcode: 101,
      };
      const response = await axiosClient().post(apiUrl, requestData);
      if (response.data.assessmentId) {
        setbuttonCondition('2');
        console.log(response.data);
        setTestResponse(response.data);
        setShowModal(true);
      } else {
        console.error('API request failed:', response.data.message);
      }
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  const reportlink = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_COMMON_API}/keytwosuccess/get-user-token`;
      const requestData = {
        userid: userid,
        assessmentId: assessmentid,
        testcode: 101,
      };
      const response = await axiosClient().post(apiUrl, requestData);
      if (response.data.assessmentId) {
        setbuttonCondition('3');
        console.log(response.data);
        setTestResponse(response.data);
        setShowModal(true);
      } else {
        console.error('API request failed:', response.data.message);
      }
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  const takeTest = async () => {
    try {
      const user_name = studentfirst + ' ' + studentlast;
      const apiUrl = `${process.env.REACT_APP_COMMON_API}/keytwosuccess/create-user`;
      const requestData = {
        firstName: user_name,
        lastName: '',
        emailAddress: studentemail,
        testcode: 101,
      };
      const response = await axiosClient().post(apiUrl, requestData);
      if (response.data.assessmentId) {
        setbuttonCondition('2');
        console.log(response.data);
        setTestResponse(response.data);
        setShowModal(true);
      } else {
        console.error('API request failed:', response.data.status);
      }
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <header>
        <HeaderControl />
        <MenuBuilder />
      </header>

      {showModal && (
        <div className="modal show" style={{ display: 'block', background: 'rgba(0,0,0,0.5)' }} id="take_test" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabelvideo" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered max-1040 mx-auto" role="document">
            <div className="modal-content radius-20 overflow-hidden">
              <div className="modal-body p-4 text-capitalize">
                <button type="button" className="btn-close btn-modal" onClick={handleModalClose} aria-label="Close"></button>
                {testResponse && <HESClient testResponse={testResponse} />}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="main flex-1">
        <div className="container max-1140 px-lg-0">
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="white-block white-block-notopborderradius h-100 p-0">
                <div className="page_ttl_row border-bottom border-2 custom-border-gray p-4 px-md-5">
                  <div className="row g-3 align-items-center">
                    <div className="col">
                      <h3
                        className="font-35 font-600 mb-0 block-title aos-init aos-animate"
                        data-aos="fade-left"
                        data-aos-delay="300">
                        Learning Style Test
                      </h3>
                    </div>
                    <div className="col-auto">
                      {buttonCondition === '1' && (
                        <div className="dashboard innerpage smart-scroll scrolled-down">
                          <a href="javascript:;" className="btn-takeTest" data-aos="fade-right" onClick={takeTest}><span>Take a Test</span></a>
                        </div>
                      )}
                      {buttonCondition === '2' && (
                        <div className="dashboard innerpage smart-scroll scrolled-down">
                          <a href="javascript:;" className="btn-darkblue" data-aos="fade-right" onClick={continueAssesmentlink}><span>Continue Assessment</span></a>
                        </div>
                      )}
                      {buttonCondition === '3' && (
                        <div className="dashboard innerpage smart-scroll scrolled-down">
                          <a href="javascript:;" className="btn-takeTest" data-aos="fade-right" onClick={reportlink}><span>View Report</span></a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="p-4 p-md-5">
                  <div className="row country-info">
                    <div className="col-md-12">
                      <div className="country-deatils border-bottom mb-5">
                        <div className='row'>
                          <div className='col-md'>
                            <h1 className="font-28 font-900 text-dark-blue mb-3">About Assessment</h1>
                            <h3 className="font-24 font-900 mb-4">The assessment helps you better understand your strengths and personality. It helps you identify your best fit career.</h3>
                            <p className="font-18 font-500">More than 200+ career professionals have worked to design, test and update this career assessment. Thousands of students have been giving this assessment each year. The testing algorithm is constantly updated with emerging careers options.</p>
                          </div>
                          <div className='col-md-auto text-center'>
                            <img src={pageImg} className="img-fluid float-lg-end ms-lg-5 mx-auto" width="396" />
                          </div>
                        </div>
                      </div>

                      <div className="tuition-fees border-bottom mb-5 pb-4" id="Universities">
                        <h2 className="font-18 font-600 position-relative title pb-2 mt-4 mb-4">Assessment Output</h2>
                        <p>The assessment output is in the form of a 14 page detailed report assessing and identifying your natural inclination for further studies, areas of improvement, skills, values and personal characteristics. The report is divided into 4 sections. The motivation section identifies the top 3 factors that help you perform better at work. The aptitude section evaluates you on verbal, numerical, spatial, critical dissection and acuteness aptitude. Interest section utilises the RIASEC theory for suggestion that careers that will be of interest to you and keep you motivated. The personality section evaluates your personality type.</p>
                      </div>

                      <div className="visa mb-0 pb-0" id="visa">
                        <h2 className="font-18 font-600 position-relative title pb-2 mb-4">Instructions</h2>

                        <div className="basic-requirements">
                          <ul className="ms-3 mb-4 p-0">
                            <li className="mb-3">We advise you to complete the assessment in 25 minutes.</li>
                            <li className="mb-3">The assessment has 7 sections with around 20 questions in each section. The aptitude section focuses on your problem solving skills. All other questions require a 'yes' or a 'no' as answers.</li>
                            <li className="mb-3">At the end of the assessment you will get a detailed report which will give you a clear direction of where you should be going.</li>
                          </ul>
                        </div>
                      </div>

                      <div className="visa mb-0 pb-0" id="visa">
                        <div className="row">
                          <div className="col-auto">
                            {buttonCondition === '1' && (
                              <div className="dashboard innerpage smart-scroll scrolled-down">
                                <a href="javascript:;" className="btn-takeTest" data-aos="fade-right" onClick={takeTest}><span>Take a Test</span></a>
                              </div>
                            )}
                            {buttonCondition === '2' && (
                              <div className="dashboard innerpage smart-scroll scrolled-down">
                                <a href={continueassesment} target='_blank' className="btn-darkblue" data-aos="fade-right"><span>Continue Assessment</span></a>
                              </div>
                            )}
                            {buttonCondition === '3' && (
                              <div className="dashboard innerpage smart-scroll scrolled-down">
                                <a href="javascript:;" className="btn-takeTest" data-aos="fade-right" onClick={reportlink}><span>View Report</span></a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageFooterControl />
    </div>
  );
};

export default Learningstyletest;
