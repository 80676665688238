import { convertDomainArrayToMap } from '../../../library/Core/SmartFunctions';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
import DomainDataService from '../../../services/domain-data.service';
import PageConfigService from '../../../services/page-config.service';

class JobListingService {
    static filters = {
        jobTypeCode: [],
        cityCode: [],
        experienceLevelCode: [],
    };

    static pageLoader(params: any) {
        const state: any = {};
        const { id, pageName } = params;

        return Promise.all([PageConfigService.get(pageName), JobListingService.get(params), DomainDataService.get()])
            .then((values) => {
                logger.log(`Retrieved values successfully for ${pageName}`);
                state.formConfig = values[0].data;
                state.data = { ...values[1].data, filters: JobListingService.filters };
                state.domain = values[2].data instanceof Map ? values[2].data : convertDomainArrayToMap(values[2].data);
                state.routeInfo = { id, pageName };
                // storeDomainData(state.domain);
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static async get(params: any) {
        let apiUrl = `${process.env.REACT_APP_JOB_SERVER}/jobs?criteria=${params.searchBy}`;
        if (params.searchBy == "USER_LIST_BY_ADMIN" || params.searchBy == "LISTED_BY_OTHER_USER" ) {
            apiUrl += `&userId=${params.userId}`
        }
        return await axiosClient().get(apiUrl, params);
    }

    static async tabChange(criteria: string, dispatch: any) {
        const response = await axiosClient().get(`${process.env.REACT_APP_JOB_SERVER}/jobs?criteria=${criteria}`);
        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {
                    data: response.data.data,
                    pageDomain: response.data.domain,
                    filters: JobListingService.filters,
                    filteredData: response.data.data,
                    summaryCounts: response.data.summaryCounts,
                    selectedTab: criteria,
                },
            },
        });
    }

    static async performSearchWithUserSelectedCriteria(params: any) {
        return await axiosClient().get(`${process.env.REACT_APP_JOB_SERVER}/jobs?criteria=${params.searchBy}`, params);
    }

    static async savePreference(params: any) {
        return await axiosClient().post(`${process.env.REACT_APP_JOB_SERVER}/jobs/save-student-job-preference`, params);
    }
}
export default JobListingService;
