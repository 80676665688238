import { useContext, useRef, useState } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, getControlValueFromState, handleControlValueChange } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import logger from '../Core/logger';
import { axiosClient } from '../Service/axiosClient';
import ErrorControl from './ErrorControl';

const FileActionController = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl
    const isHidden = evaluateExpression(control.hideExpression, state?.data);
    const [imagelink, setImageLink] =useState('');
     if (data && !imagelink) {
        setImageLink(data);
    }

    const handleFileControlUpload = async (state: State, e: any) => {
        logger.log(state.data);
        console.log(e.target.files);
        const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/upload-institute-logo`;
      
        const formData = new FormData();
        formData.append('pdf_doc', e.target.files[0]);

        try {
            const response = await axiosClient().post(apiUrl, formData);
            if (response.status === 400) {
                alert(response.data.message);
                return;
            }
            let link = response.data.link;
            setImageLink(link);
            handleControlValueChange({ control, value: link, dataKey, parentDataKey, state, dispatch });
        } catch (error) {
            // Handle other errors if needed
            console.error("An error occurred:", error);
            alert("Image dimensions are too small or large. Minimum dimensions required: 200x200");
            e.target.value = '';
        }
    };

    if (isHidden) return <></>;
    const handleUpload = async (e: any) => {};
    return (
        <div className="w-100">
            <label htmlFor="formFileLg" className="form-label">
                {control.props.label}
            </label>

            <input
                id={control.id}
                data-testid={control.id}
                type="file"
                hidden={control.props?.isCode || isHidden}
                className={`form-control form-control-lg pl-0`}
                name="cover"
                onChange={(event) => handleFileControlUpload(state as State, event)}
            />
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
            <br/>
            <img src={imagelink}  width="160" alt="" className="img-fluid" />
        </div>
    );
};

export default FileActionController;
