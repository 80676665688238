import { useState,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartContext } from '../../../library/Core/SmartContext';



const Layout = (props:any) => {
    const { state, dispatch } = useContext(SmartContext);
    const queryParams = new URLSearchParams(window.location.search);
    const eventId = queryParams.get('id');
    const [userTypeCode, setUserTypeCode] = useState(sessionStorage.getItem('user-type-code'));
    //console.log(eventId)
    const navigate = useNavigate();

    return (
        <div className="main flex-1">
        <section className="updatebasicinformation">
            <div className="container max-1140 px-lg-0">
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <div className="white-block white-block-notopborderradius p-0 h-100">
                            <div className="border-bottom border-2 custom-border-gray p-3 p-sm-4 pb-3">
                                <div className="d-flex flex-wrap justify-content-between align-items-center mx-0 mx-sm-1">
                                    <h3 data-aos="fade-left" data-aos-delay="600" className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                                        Event 
                                    </h3>
                                </div>
                            </div>
                            <div className="job_internship_tab polartabsec radius-6 m-0 px-4">
                                <div className="xscroll p-0">
                                    <ul className="nav nav-tabs" id="JobInternshipTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="basic-details-tab"  onClick={() => {if (eventId) {
                                                    navigate(`/event/addnew?id=${eventId}`);
                                                } else {
                                                    navigate(`/event/addnew`);
                                                }}}  >Basic Details</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="speaker-info-tab" onClick={() => {if (eventId) {
                                                    navigate(`/event/speaker-info?id=${eventId}`);
                                                } else {
                                                    navigate(`/event/speaker-info`);
                                                }}} >Speaker Info</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="audience-category-tab" onClick={() => {if (eventId) {
                                                    navigate(`/event/audience-category?id=${eventId}`);
                                                } else {
                                                    navigate(`/event/audience-category`);
                                                }}} >Audience Category</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="theme-options-tab" onClick={() => {if (eventId) {
                                                    navigate(`/event/theme-options?id=${eventId}`);
                                                } else {
                                                    navigate(`/event/theme-options`);
                                                }}} >Theme Options</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="row m-0 pt-4 mt-2 JobInternshipListing">
                        <div className="flex-1 polarcontsec tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="basic-details" role="tabpanel" aria-labelledby="basic-details-tab">
                                <form className="lstCustForm w-100">
                                    <div className="row g-4">
                                        {props.children}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    );
};

export default Layout;
