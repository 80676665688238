import { convertDomainArrayToMap, isEmpty } from '../../library/Core/SmartFunctions';
import { DomainElement } from '../../library/Core/SmartTypes';
import logger from '../../library/Core/logger';
import { axiosClient } from '../../library/Service/axiosClient';
import DomainDataService from '../../services/domain-data.service';
import PageConfigService from '../../services/page-config.service';

class MessagesService {
    static pageLoader(params: any) {
        const state: any = {};
        const { id, pageName, newMessageMode, searchBy = '' } = params;

        return Promise.all([
            PageConfigService.get(pageName),
            MessagesService.get(searchBy),
            DomainDataService.get(),
            // MessagesService.getMailToUserList(),
            MessagesService.getUnreadMsgCnt(),
        ])
            .then((values) => {
                logger.log(`Retrieved values successfully for ${pageName}`);
                // console.log(values[3]);
                state.formConfig = values[0].data;
                state.data = {
                    ...values[1].data,
                    originalConversations: values[1].data?.conversations,
                    newMessageMode,
                    selectedConversationId: newMessageMode ? 'NEW' : null,
                    newMessage: {},
                    filters: { messageType: '', messageFrom: '' },
                    actions: { selectAll: false },
                    selectedConversation: [],
                    messageMode: 'LIST_VIEW',
                    refreshMessageFlag: 0,
                    searchBy,
                    pagination: {
                        currentPage: 0,
                        pageSize: 10, // Change the number to your desired page size
                    },
                    memberIds: [],
                    conversationWithUser: [],
                    selectedUserDetails: [],
                    unreadMsgCount: values[3]?.data[0] || {},
                };
                state.domain = values[2].data instanceof Map ? values[2].data : convertDomainArrayToMap(values[2].data);
                // state.domain.set('MAIL_TO_USER_LIST', values[3]);
                state.routeInfo = { id, pageName, newMessageMode };

                // if (values[3]?.data[0]) {
                //     state.data.unreadMsgCount = values[3]?.data[0];
                // }

                // storeDomainData(state.domain);
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static getUnreadMsgCnt() {
        const user = JSON.parse(localStorage.getItem('user') as string);
        // console.log('user')
        // console.log(user)
        const uuid = user.id;
        // alert(studentId);
        return axiosClient().get(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/get-unread-msg-cnt/${uuid}`);
    }

    static get(searchBy: string) {
        let token = sessionStorage.getItem('user-jwt-token');
        if (isEmpty(token)) {
            token = localStorage.getItem('user-jwt-token');
        }
        if (!isEmpty(token)) {
            return axiosClient().get(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations?userTypeCode=${searchBy}`);
        } else {
            return Promise.resolve({ data: { conversations: [] } });
        }
    }

    static getMailToUserList = async () => {
        // const queryString = sessionStorage.getItem('user-school-group-id')
        //     ? `schoolId=&schoolGroupId=${sessionStorage.getItem('user-school-group-id')}`
        //     : `schoolId=${sessionStorage.getItem('user-school-id')}&schoolGroupId=`;

        const userId = sessionStorage.getItem('user-id');

        // const response: any = await axiosClient().get(`${process.env.REACT_APP_STUDENT_SERVER}/api/student-snapshot?${queryString}`);

        let userNamesForSearch: any = JSON.parse(sessionStorage.getItem('user-names-for-search') || '[]');

        if (userNamesForSearch.length === 0) {
            const res = await axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/common/list-for-name-search`);
            userNamesForSearch = res.data?.students;
            // sessionStorage.setItem('user-names-for-search', JSON.stringify(res.data?.students));
        }

        const mailToUserList = [] as DomainElement[];

        // sessionStorage.setItem('mail-to-user-list', JSON.stringify(response.data));
        userNamesForSearch.map((item: any) => {
            if (item.uuid !== userId) {
                return mailToUserList.push({
                    ...item,
                    categoryCode: 'MAIL_TO_USER_LIST',
                    code: item.uuid,
                    value: `${item.firstName} ${item.lastName} - ${item.passOutYear} - ${item.mobile} - ${item.registeredEmailId}`,
                    parentCode: '',
                    displayOrder: 0,
                });
            }
        });

        return Promise.resolve(mailToUserList);
    };
}

export default MessagesService;
