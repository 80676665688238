import { useContext } from 'react';
import { axiosClient } from '../../../../../../library/Service/axiosClient';
import { SmartContext } from '../../../../../../library/Core/SmartContext';

const ProfileMarkerService = () => {
    const { state, dispatch } = useContext(SmartContext);
    async function fetchUserProfileMarkers(userId) {
        const markersData =
            (await axiosClient().get(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/markers/get-marker-by-id/${userId}`)) ?? [];

        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: 'marker', value: markersData?.data },
        });
        
        return markersData?.data || [];
    }

    // Function to update marker data
    async function saveWorkRelatedMarker(updatedMarker, userId) {
        const markersData =
            (await axiosClient().post(
                `${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/markers/save-work-prfile-marker/${userId}`,
                updatedMarker
            )) ?? [];
    }

    // Function to update marker data
    async function saveMarker(updatedMarker, userId) {
        const markersData =
            (await axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/markers/save-marker/${userId}`, updatedMarker)) ?? [];
    }

    async function fetchUserFromSameInstitute(userId,type) {
        const markersData =
            (await axiosClient().get(
                `${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/markers/get-student-from-same-institute-by-userid/${userId}/${type}`
            )) ?? [];

        return markersData?.data || [];
    }

    async function fetchAffinityRelatedCountsByUserId(userId) {
        const markersData =
            (await axiosClient().get(
                `${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/markers/get-affinity-relatedcount-by-userid/${userId}`
            )) ?? [];

        return markersData?.data || [];
    }

    async function fetchUserProfileMarkersLogs(userId) {
        const markersLogData =
            (await axiosClient().get(
                `${process.env.REACT_APP_ADMIN_API_SERVER}/markers/get-marker_logs-by-userid/${userId}`
            )) ?? [];

        return markersLogData?.data || [];
    }

    async function handleOpenLogsModal() {
        const LogsData = await fetchUserProfileMarkersLogs(state?.data?.id);
        dispatch({
            type: 'SET_MODAL_DATA',
            payload: { data: [LogsData] },
        });
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showprofileMarkerLogs' } });
    };
    

    return {
        fetchUserFromSameInstitute,
        saveMarker,
        saveWorkRelatedMarker,
        fetchUserProfileMarkers,
        fetchAffinityRelatedCountsByUserId,
        fetchUserProfileMarkersLogs,
        handleOpenLogsModal
    };
};

export default ProfileMarkerService;
