import React, { useState, useEffect } from 'react';
import { axiosClient } from '../../library/Service/axiosClient';
import { useParams, useNavigate } from 'react-router-dom';

const AlumniEmailVerification = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [verificationMessage, setMessage] = useState('Please wait ..');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/verify-user`, { id });
                const data = await response.data; // Extract the data from the response
                if (data.success === true) {
                    navigate(`/user/${data.domain}/login?m=v`);
                }
                setMessage(data.message);
            } catch (error) {
                setMessage('Facing issue while validating your email address, Please try again');
            }
        };

        fetchData();
    }, []);

    return (
        <div className="main flex-1">
            <section className="notificationSection">
                <div className="container max-1140 px-lg-0">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius h-100">
                                <div className="d-flex flex-wrap justify-content-between align-items-center mb-4 border-bottom custom-border-gray pb-3">
                                    <h3
                                        className="font-35 font-600 mb-0 block-title aos-init aos-animate"
                                        data-aos="fade-left"
                                        data-aos-delay="600">
                                        {verificationMessage}
                                    </h3>
                                </div>
                                <div className="max-940 mx-auto"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AlumniEmailVerification;
