import { useContext, useEffect, useState } from 'react';

import GuidanceVideos from './GuidanceVideos';
import Memories from './Memories';
import JobListing from './JobListing';
import { Tooltip } from 'bootstrap';


const ProfileContributions = () => {
    const [activeTab, setActiveTab] = useState('guidance-videos');

    const handleTabChange = (tab:any) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll(".defaultTooltip"));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, []);

    return (
        <div className="border-d2d2d2 border-radius-6 bg-white overflow-hidden">
        <div className="block-content">
            <div className="block-custom-tab">
                <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active" id="pills-Contributions" role="tabpanel" aria-labelledby="pills-Contributions-tab">
            <div className="d-flex flex-wrap flex-column flex-md-row">
                <div
                    className="nav flex-row flex-md-column nav-pills custom-sub-tab"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical">
                     <button
                        className={`nav-link ${activeTab === 'guidance-videos' ? 'active' : ''}`}
                        id="v-pills-guidance-videos-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-guidance-videos"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-guidance-videos"
                        aria-selected={activeTab === 'guidance-videos'}
                        onClick={() => handleTabChange('guidance-videos')}>
                       Guidance Videos
                    </button>
                    <button
                        className={`nav-link ${activeTab === 'memories' ? 'active' : ''}`}
                        id="v-pills-memories-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-memories"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-memories"
                        aria-selected={activeTab === 'memories'}
                        onClick={() => handleTabChange('memories')}>
                        Memories
                    </button>
                    <button
                        className={`nav-link ${activeTab === 'job-listings' ? 'active' : ''}`}
                        id="v-pills-job-listings-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-job-listings"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-job-listings"
                        aria-selected={activeTab === 'job-listings'}
                        onClick={() => handleTabChange('job-listings')}>
                       Job Listings
                    </button>
                </div>
                <div className="tab-content p-3 p-sm-4 p-md-5 bg-gray-F2F4F7 flex-1" id="v-pills-tabContent">
                    {activeTab === 'guidance-videos' && <GuidanceVideos />}
                    {activeTab === 'memories' && <Memories />}
                    {activeTab === 'job-listings' && <JobListing />}
                </div>
            </div>
        </div>
        </div>
        </div>
        </div></div>
    );
      
};

export default ProfileContributions;
