import React from 'react';
import { ApplicationContext, DispatchEvent } from './SmartTypes';

export const SmartContext = React.createContext({
    state: {
        flags: { isDataLoading: true, showFormErrors: 0 },
        modalFlags: { showEmailModal: false, showUserModal: false, showEmailPreviewModal: false, showWhatsAppPreviwModal: false,showsubjectmarksModal:false,showGuidanceVideoAddModal:false },
        modalData: { title: '', data: {}},
        formValidationErrors: {},
    },
    dispatch: (dispatchEvent: DispatchEvent) => {},
} as ApplicationContext);
