import { ArcElement, Chart as ChartJS, Legend, RadialLinearScale, Tooltip } from 'chart.js';
import { useContext, useEffect } from 'react';
import { PolarArea } from 'react-chartjs-2';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import useManagementDashboard from './useManagementDashboard';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const AlumniPresence = () => {
    const { state } = useContext(SmartContext);
    const { getAlumniPresence } = useManagementDashboard();

    useEffect(() => {
        getAlumniPresence();
    }, [state.data.searchCriteria.alumniPresence.batch, state.data.searchCriteria.alumniPresence.campus]);

    if (isEmpty(state.data?.reportData?.alumniPresence)) return null;

    const data = {
        labels: Object.keys(state.data?.reportData?.alumniPresence),
        datasets: [
            {
                label: '# of Students',
                data: Object.values(state.data?.reportData?.alumniPresence),
                backgroundColor: [
                    'rgba(255, 0, 0, 0.7)',
                    'rgba(0, 128, 0, 0.7)',
                    'rgba(255, 140, 0, 0.7)',
                    'rgba(128, 0, 128, 0.7)',
                    'rgba(0, 128, 128, 0.7)',
                    'rgba(255, 192, 203, 0.7)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false, // Turn off aspect ratio to fit the container
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '500px', // Explicitly set to 600px
                backgroundColor: '#f6f6f6',
                overflow: 'hidden', // Hide any overflow content
            }}>
            <PolarArea data={data} options={options} />
        </div>
    );
};

export default AlumniPresence;
