import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VerifyContactDetailsControl from '../../onboarding/VerifyContactDetailsControl';
import VerifyMobileControl from '../../onboarding/VerifyMobileControl';

const ProfileActionsControl = () => {
    const navigate = useNavigate();
    const [showVerifyContactDetails, setShowVerifyContactDetails] = useState(false);
    const [showVerifyMobile, setShowVerifyMobile] = useState(false);
    const id = sessionStorage.getItem('user-id');
    const handleVerifyContactDetails = (event: any) => {
        event.preventDefault();
        setShowVerifyContactDetails(true);
    };

    return (
        <>
            <div className="row">
                {/*<div className="col-md-12 mb-2 mb-sm-4">
                    <div className="white-block h-100 aos-init aos-animate" data-aos="fade-up" data-aos-delay="2400">
                        <div className="row" id="ProfileJuniorBlock">
                            <div className="col-md-6 mx-auto" id="PercentBlock">
                                <div className="d-flex flex-column flex-lg-row flex-md-row flex-sm-row align-items-center px-4 py-2">
                                    <div className="percentage-score me-2">
                                        <div
                                            className="GaugeMeter gaugeMeter"
                                            id="GaugeMeter_1"
                                            data-percent="100"
                                            data-size="113"
                                            data-theme="Custom-Green"
                                            data-color="#000"
                                            data-back="#5CAE4833"
                                            data-label="Your interests"
                                            data-width="5"
                                            data-animationstep="1"
                                            data-animate_gauge_colors="true"
                                            data-animate_text_colors="true"
                                            data-text_size="0.22"
                                            data-fill="#fff"
                                            data-showvalue="false"
                                            data-id="GaugeMeter_1"
                                            style={{ width: '113px' }}>
                                            <span style={{ lineHeight: '113px', fontSize: '24.86px', color: 'rgb(92, 174, 72)' }}>100</span>
                                            <b style={{ lineHeight: '156.462px', color: 'rgb(0, 0, 0)' }}>Your interests</b>
                                            <canvas width="113" height="113"></canvas>
                                        </div>
                                    </div>
                                    <div className="ps-0 ps-lg-4 ps-md-4">
                                        <h3 className="font-22 text-center text-lg-start text-md-start mt-3 mt-lg-0 mt-md-0 mb-3">
                                            Update your next level
                                            <br />
                                            interests.
                                        </h3>
                                        <a
                                            href=""
                                            className="btn-border max-120 m-lg-0 m-md-0 text-decoration-none"
                                            data-bs-toggle="modal"
                                            data-bs-target="#nextMovePreferences">
                                            <span>Review</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
                {/* <div className="col-md-6 mb-2 mb-sm-4">
                    <div className="white-block h-100 aos-init aos-animate" data-aos="fade-right" data-aos-delay="3000" id="ToDoList">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h3 className="font-22 font-600 mb-0 block-title">To-Do List</h3>
                             <a href="" id="Viewlistbtn" className="font-14 text-nowrap text-burgandi">
                                View All
                            </a> 
                        </div>
                        <div>
                            <ul className="orderlist">
                                <li id="todo_1">
                                    <p>Review and Approve peer profiles</p>
                                    <a href="/alumni-review/peer" className="btn-border">
                                        <span>Review</span>
                                    </a>
                                </li>
                                <li id="todo_2">
                                    <p>Your digits and email please!</p>
                                    <a href="" role="button" onClick={handleVerifyContactDetails} className="btn-border">
                                        <span>Add Here</span>
                                    </a>
                                </li>
                                <li id="todo_3">
                                    <p>Update your next move preferences</p>
                                    <a href={`/alumni/${id}/skills`} className="btn-border">
                                        <span>Update</span>
                                    </a>
                                </li>
                                <li id="todo_4">
                                    <p>Have a preference? Tell us</p>
                                    <a href={`/alumni/${id}/skills`} className="btn-border">
                                        <span>Share Here</span>
                                    </a>
                                </li>
                                <li id="todo_5">
                                    <p>Drop your CV here</p>
                                    <a href={`/alumni/${id}/skills`} className="btn-border">
                                        <span>Upload</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-md-6 mb-2 mb-sm-4">
                    <div className="white-block h-100 aos-init aos-animate" data-aos="fade-left" data-aos-delay="3000" id="LastVisit">
                        <div className="d-flex flex-column justify-content-between align-items-center my-2">
                            <img
                                src={`${process.env.REACT_APP_IMAGE_BASEPATH}/group-41725.svg`}
                                width="113 "
                                className="img-fluid mb-4"
                            />
                            <h3 className="font-22 font-600 mb-0 block-title font-24 text-dark-blue mb-2">You are all caught up!</h3>
                            <p className="mb-3 text-center">
                                Check out our events section for upcoming events. You can also nominate yourself to conduct a webinar
                            </p>
                            <a href="https://miles.univariety.com/alumni/meetings/1">
                                <button type="button" className="font-14 text-nowrap btn-border px-5 height-35">
                                    <span>Visit</span>
                                </button>
                            </a>
                        </div>
                    </div>
                </div> */}
            </div>
            {showVerifyContactDetails && (
                <VerifyContactDetailsControl
                    setShowVerifyContactDetails={setShowVerifyContactDetails}
                    setShowVerifyMobile={setShowVerifyMobile}
                />
            )}
            {showVerifyMobile && (
                <VerifyMobileControl setShowVerifyContactDetails={setShowVerifyContactDetails} setShowVerifyMobile={setShowVerifyMobile} />
            )}
        </>
    );
};

export default ProfileActionsControl;
