import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import SessionContext from '../../../library/Core/SessionContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import AlbumListingGridControl from './AlbumListingGridControl';
import AlbumListingSearchControl from './AlbumListingSearchControl';
import useAlbum from './useAlbum';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import { isSchoolAdmin, isSuperAdmin } from '../../../library/Core/SmartFunctions';

const AlbumListingLayout = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    const { tabChange } = useAlbum();
    const summaryCounts = state.data.summaryCounts;
    const selectedTab = state.data.selectedTab;
    const navigate = useNavigate();

    const handleTabChange = (tab: string) => {
        tabChange(tab);
    };

    const handleNewAlbum = () => {
        navigate('/memories/new');
    };

    const userTypeCode = sessionState?.userTypeCode;

    return (
        <div id="All">
            <div className="main flex-1">
                <section className="notificationSection">
                    <div className="container max-1140 px-lg-0">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="white-block white-block-notopborderradius h-100 p-0">
                                    <div className="m-w-90 d-flex flex-wrap justify-content-between align-items-center mb-1 px-4 pt-4 pb-4">
                                        <h3
                                            className="font-35 font-600 mb-0 mt-3 block-title aos-init aos-animate"
                                            data-aos="fade-left"
                                            data-aos-delay="600">
                                            Memories
                                        </h3>
                                        <div className="dashboard innerpage smart-scroll scrolled-down">
                                            {userTypeCode != 'STUDENT' &&  sessionState?.userTypeCode!=='SUPER_ADMIN' && (
                                                <a
                                                    role={'button'}
                                                    onClick={handleNewAlbum}
                                                    className="btn btn-green w-auto mt-2 mt-sm-3 height-40"
                                                    style={{ textDecoration: 'none' }}>
                                                    <span>Create Album</span>
                                                </a>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row job_internship_tab polartabsec">
                                        <div className="xscroll p-0">
                                            <ul
                                                className="nav nav-tabs aos-init aos-animate"
                                                id="JobInternshipTab"
                                                role="tablist"
                                                data-aos="fade-left"
                                                data-aos-delay="900">
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className={`nav-link ${selectedTab === 'ALL' ? 'active' : ''}`}
                                                        id="Albums-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#AllAlbums"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="AllAlbums"
                                                        aria-selected={selectedTab === 'ALL'}
                                                        onClick={() => handleTabChange('ALL')}>
                                                        All <span id="AllCount">({summaryCounts?.totalCount ?? 0})</span>
                                                    </button>
                                                </li>
                                                {userTypeCode != 'STUDENT' && !isSchoolAdmin() && sessionState?.userTypeCode!=='SUPER_ADMIN' && (
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className={`nav-link ${selectedTab === 'SAVED' ? 'active' : ''}`}
                                                        id="Saved-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#AllAlbums"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="AllAlbums"
                                                        aria-selected={selectedTab === 'SAVED'}
                                                        onClick={() => handleTabChange('SAVED')}>
                                                        Saved Albums <span id="SaveCount">({summaryCounts?.savedCount ?? 0})</span>
                                                    </button>
                                                </li>
                                                )}
                                                {userTypeCode != 'STUDENT' && !isSchoolAdmin() && sessionState?.userTypeCode!=='SUPER_ADMIN' && (
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={`nav-link ${selectedTab === 'MINE' ? 'active' : ''}`}
                                                            id="Mine-tab"
                                                            data-bs-target="#AllAlbums"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="AllAlbums"
                                                            aria-selected={selectedTab === 'MINE'}
                                                            onClick={() => handleTabChange('MINE')}>
                                                            Your Albums <span id="YourCount">({summaryCounts?.mineCount ?? 0})</span>
                                                        </button>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="row m-0 px-4 pt-4 pb-5 mt-2 JobInternshipListing">
                                        {/* Search Control */}
                                        <DebugBarControl />
                                        <AlbumListingSearchControl />
                                        {/* Grid Control */}
                                        <AlbumListingGridControl />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default AlbumListingLayout;
