// import '../../../styles/css/alumni-dashboard.css';
import { useNavigate } from 'react-router-dom';
import '../../../styles/css/alumni.css';
import { useEffect, useState, useContext } from 'react';
import SessionContext from '../../../library/Core/SessionContext';

const OnboardingLayout = (props: any) => {
    const [StudentId, setStudentId] = useState<any>('');
    const [name, setname] = useState('');
    const [profileStatus, setprofileStatus] = useState<any>('');
    const { sessionState } = useContext(SessionContext);
    console.log(sessionState, " sessionState ")
    useEffect(() => {
        const firstName = sessionState?.firstName;
        const lastName = sessionState?.lastName;
        const studentId = sessionState?.studentId;
        setname(firstName + ' ' + lastName);
        const userState = sessionState?.profileStatusCode;
        setprofileStatus(userState);
        setStudentId(studentId);
    // }, [sessionStorage.getItem('user-first-name')]);
    }, []);

    const schoolSettings = localStorage.getItem('school_settings');
    const parsedSchoolSettings = schoolSettings ? JSON.parse(schoolSettings) : null;
    const schoolName = (sessionState?.instituteName ?? '').replace(/^(.)/, (match) => match.toUpperCase());

    const navigate = useNavigate();
    return (
        <div className="main flex-1">
            <section className="milestoneSection">
                <div className="container max-1140 px-lg-0">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block m-w-90-parent white-block-notopborderradius h-100">
                                {profileStatus != 'COMPLETE' && profileStatus != null && profileStatus != '' && (
                                    <div className="relevantAlumniMatchWrap">
                                        <div className="relevantAlumniMatch">
                                            <div className="row g-2 g-md-4 align-items-center">
                                                <div className="col-md">
                                                    <p>
                                                        {name != ' ' ? name + ',' : ''} Your Profile Seems Dry! Add your information to
                                                        match with relevant Alumni
                                                    </p>
                                                </div>
                                                <div className="col-md-auto">
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            sessionStorage.setItem('user-status-code', 'PROFILE_IN_PROGRESS');
                                                            navigate(
                                                                '/alumni/' +
                                                                    StudentId +
                                                                    '/' +
                                                                    profileStatus
                                                            );
                                                        }}
                                                        className="btn-complete-now">
                                                        Complete Now
                                                    </button>
                                                    {/* <Link className="btn-complete-now" to={'/alumni/'+StudentId+"/"+profileStatus}>Complete Now</Link> */}
                                                    {/* <a href="javascript:;" className="icon-cross btn-close" id="closecm"></a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="m-w-90 max-1010 mx-auto">
                                    <div className="d-flex justify-content-between align-items-start py-4">
                                        <div className="welcome-title flex-1" data-aos="fade-right">
                                            <h1>Welcome to {schoolName}</h1>
                                            <p>We recommend below activities to get you started.</p>
                                        </div>
                                        <div className="dashboard smart-scroll" data-aos="fade-left">
                                            <div className="d-flex flex-wrap">
                                                {props.showDetailForGroup && (
                                                    <button className="btn-darkblue text-nowrap mx-0 mx-sm-3" onClick={props.handleBackToSummary}>
                                                        <span>Back to Summary</span>
                                                    </button>
                                                )}
                                                <button className="btn-green text-nowrap" onClick={props.handleGoToDashboard}>
                                                    <span>Go to Dashboard</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>{props.children}</div>

                                    {/* {!props.showDetailForGroup && <ActivityGroupControl onCompleteNowClick={setShowDetailForGroup} />}
                                    {props.showDetailForGroup && <ActivityControl selectedGroup={props.showDetailForGroup} />} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default OnboardingLayout;
