import React, { useState, useEffect, useContext } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor } from '@ckeditor/ckeditor5-core';
import { Modal } from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import ReactDOM from 'react-dom';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty } from '../../../library/Core/SmartFunctions';

const MyEditor = ClassicEditor as unknown as { create: (...args: any) => Promise<Editor> };

const EmailPreviewModal = ({ data }: any) => {
    const {
        selectedSendingReasonValue,
        EmailSubject,
        EmailBody,
        SubmitEmailPreview,
        communicationChannel,
        selectedItems,
        handleRemoveEmail,
        setEmailSubject,
        setEmailBody,
    } = data;
    const { state, dispatch } = useContext(SmartContext);
    const showFlag = state?.modalFlags?.showEmailPreviewModal;
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        console.log("selectedItems",selectedItems)
        const emailList = document.querySelector('.selectedEmailIds');
        if (emailList) {
            const liElements = emailList.querySelectorAll('li');
            const elementsToRemove: HTMLElement[] = [];
            liElements.forEach((liElement) => {
                const spanElement = liElement.querySelector('.email_input');
                 if (spanElement !== null) {
                    const emailValue = spanElement.textContent?.trim();
                    if (!isEmpty(emailValue)) {
                        const isMatched = selectedItems.some((item: any) => {
                            return maskEmail(item.registered_email_id.trim()) === emailValue;
                        });
                        if (!isMatched) {
                            emailList.removeChild(liElement);
                        }
                    }
                 }         
                });
          
        }
    }, [selectedItems]);

    const maskEmail = (email: string) => {
        const atIndex = email.indexOf('@');
        if (atIndex !== -1) {
            return email[0] + email.substring(1, atIndex).replace(/./g, 'X') + email.substring(atIndex);
        }
        return email; // Handle the case where '@' is not found, e.g., for invalid email addresses
    };

    const handleAddedEmailRemove = (liElement: any) => {
        const emailList = document.querySelector('.selectedEmailIds');
        if (emailList && liElement) {
            emailList.removeChild(liElement);

            const previousEmailFields = emailList.querySelectorAll('li');

            let allPreviousFieldsValid = true;

            previousEmailFields.forEach((liElement) => {
                const spanElement = liElement.querySelector('.email_input');
                if (spanElement !== null) {
                    const emailValue = spanElement.textContent;

                    if (emailValue !== null) {
                        const trimmedEmailValue = emailValue.trim();
                        if (trimmedEmailValue === '') {
                            setError('Please enter a valid email address.');
                            allPreviousFieldsValid = false;
                        }
                    }
                }
            });

            if (allPreviousFieldsValid) {
                setError('');
            }
        }
    };

    const addMoreEmailLabel = () => {
        const emailList = document.querySelector('.selectedEmailIds');

        if (emailList) {
            const previousEmailFields = emailList.querySelectorAll('li');

            let allPreviousFieldsValid = true;

            previousEmailFields.forEach((liElement) => {
                const spanElement = liElement.querySelector('.email_input');
                if (spanElement !== null) {
                    const emailValue = spanElement.textContent;
                    console.log(emailValue);

                    if (emailValue !== null) {
                        const trimmedEmailValue = emailValue.trim();
                        if (trimmedEmailValue === '' || !isValidEmailAddress(trimmedEmailValue)) {
                            setError('Please enter a valid email address.');
                            allPreviousFieldsValid = false;
                        }
                    }
                }
            });

            if (allPreviousFieldsValid) {
                const liElement = document.createElement('li');
                liElement.setAttribute('data-withoutmask', '');

                const spanElement = document.createElement('span');
                spanElement.setAttribute('contenteditable', 'true');
                spanElement.classList.add('email_input');

                const aElement = document.createElement('a');

                // Create a React component for the FontAwesome icon
                const liElements = emailList.querySelectorAll('li');
                const liCount = liElements.length;
                console.log(liCount);

                const iconComponent = <FontAwesomeIcon icon={faTimesCircle} onClick={() => handleAddedEmailRemove(liElement)} />;

                ReactDOM.render(iconComponent, aElement);

                spanElement.addEventListener('keyup', (event: any) => {
                    const newValue = event.target.textContent.trim();
                    const isValidEmail = isValidEmailAddress(newValue);

                    if (isValidEmail) {
                        liElement.setAttribute('data-withoutmask', newValue);
                        setError('');
                    } else {
                        setError('Please enter a valid email address.');
                        // spanElement.textContent = ''; // Clear the input
                    }
                });

                liElement.appendChild(spanElement);
                liElement.appendChild(aElement);
                emailList.appendChild(liElement);
            }
        }
    };

    const isValidEmailAddress = (email: string): boolean => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };

    const errorDiv = error ? <div className="alert alert-danger">{error}</div> : null;

    return (
        <div
            id="select-user-popup-review"
            className={`modal fade ${showFlag ? 'show' : ''}`} // removed vh-100 and show class
            tabIndex={-1}
            role="dialog"
            style={{ display: showFlag ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-dialog-centered  max-800 mx-auto">
                <div className="modal-content">
                    <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
                        <div className="d-flex flex-wrap">
                            <a
                                onClick={() => {
                                    dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showUserModal' } });
                                }}
                                href="javascript:;"
                                data-bs-dismiss="modal"
                                className="fa fa-arrow-circle-left icon icon-left-arrow mt-1 me-2 go-back text-black font-20 text-decoration-none"></a>

                            <h5 className="modal-title flex-1" id="staticBackdropLabel">
                            <span>Send Profile </span><span>&gt; </span><i className="fa fa-angle-right"></i><span> Review Email</span>
                            </h5>
                        </div>
                        <button
                            type="button"
                            className="btn-close btn-close-small"
                            onClick={() => {
                                dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showEmailPreviewModal' } });
                            }}></button>
                    </div>
                    <div className="modal-body p-4">
                        <form className="lstCustForm needs-validation">
                            <div className="row g-4">
                                <div className="col-md-12">
                                    <div className="sentEmail">
                                        <div className="sentEmailTo">To -</div>
                                        <div className="sentEmailToMailIds">
                                            <ul className="selectedEmailIds">
                                                {Array.isArray(selectedItems) &&
                                                    selectedItems.map((item, index) => (
                                                        <li data-withoutmask={item.registered_email_id} key={index}>
                                                            <span className="email_input">{maskEmail(item.registered_email_id)}</span>
                                                            <a onClick={() => handleRemoveEmail(index)}>
                                                                <FontAwesomeIcon icon={faTimesCircle} />
                                                            </a>
                                                        </li>
                                                    ))}
                                            </ul>
                                            <button
                                                type="button"
                                                className="btn btn-green max-135 addmore_email"
                                                onClick={() => addMoreEmailLabel()}>
                                                <span>+ Add More Recipients</span>
                                            </button>
                                        </div>
                                    </div>
                                    {errorDiv}
                                </div>
                                <div className="col-md-12">
                                    <div className="mailBody">
                                        <div className="row g-3">
                                            <div className="col-sm-3">
                                                <div className="label">Reason of sending</div>
                                            </div>
                                            <div className="col-sm-9">{selectedSendingReasonValue}</div>
                                            <div className="col-sm-3">
                                                <div className="label">Subject</div>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control flex-1"
                                                    id="email_subject"
                                                    value={EmailSubject}
                                                    onChange={(e) => setEmailSubject(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="label">Body</div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="mailBodyContainer">
                                                    <CKEditor
                                                        editor={MyEditor}
                                                        data={EmailBody}
                                                        onChange={(event, editor) => {
                                                            const data = (editor as any).getData();
                                                            setEmailBody(data); // Update the email body content
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row justify-content-center align-items-center">
                                <div className="col-auto">
                                    <button
                                        type="button"
                                        className={`btn btn-green max-135 ${error ? 'disabled-button' : ''}`}
                                        onClick={() => SubmitEmailPreview()}
                                        disabled={error !== null && error !== ''}>
                                        <span>{communicationChannel === 'Only_Email' ? 'Send' : 'Next'}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailPreviewModal;
