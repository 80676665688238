import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../styles/css_pre_singup/style.css';
import { useLocation } from 'react-router-dom';

const ViewTrack: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('lead_ref_id');

    const [referralData, setReferralData] = useState<{
        lead_first_name: string;
        lead_last_name: string;
        country_code: string;
        mobile: string;
        relationship: string | null;
    } | null>(null);

    const [trackData, setTrackData] = useState<{ track_on: string; track_status: number }[]>([]);
    const [currentStatus, setCurrentStatus] = useState<number | null>(null);
    const [nextStatus, setNextStatus] = useState<{ label: string; value: number } | null>(null);
    const [refreshKey, setRefreshKey] = useState(0); // Used to trigger re-renders

    const statusOptions = [
        { label: 'First Interaction Completed', value: 1 },
        { label: 'Application Completed', value: 2 },
        { label: 'Admission Offer Made', value: 3 },
        { label: 'Admission Offer Accepted', value: 4 },
        { label: 'Enrolled', value: 5 }
    ];
    const instName = localStorage.getItem('school_settings') 
    ? JSON.parse(localStorage.getItem('school_settings') as string).name 
    : '';
    const fetchData = async () => {
        try {
            const jwtToken = localStorage.getItem('user-jwt-token');
            const response = await axios.post(
                `${process.env.REACT_APP_BROADCAST_SERVER}/referral/get-referral`,
                { id: id },
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                }
            );
            if (response.data && response.data.data) {
                setReferralData(response.data.data);
            } else {
                console.error('No referral data found.');
            }
        } catch (error) {
            console.error('Error fetching referral data:', error);
        }
    };

    const fetchTrackData = async () => {
        try {
            const jwtToken = localStorage.getItem('user-jwt-token');
            const response = await axios.post(
                `${process.env.REACT_APP_BROADCAST_SERVER}/referral/get-track-referral`,
                { id: id },
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                }
            );
            if (response.data && response.data.data) {
                const track = response.data.data;
                setTrackData(track);

                // Get the current status based on the last track entry
                const latestStatus = track[track.length - 1]?.track_status || 0;
                setCurrentStatus(latestStatus);

                // Set the next status if available
                if (latestStatus < statusOptions.length) {
                    setNextStatus(statusOptions[latestStatus]); // Next status in sequence
                }
            } else {
                console.error('No track data found.');
            }
        } catch (error) {
            console.error('Error fetching track data:', error);
        }
    };

    useEffect(() => {
        if (id) {
            fetchData();
            fetchTrackData();
        } else {
            console.error('No referral id provided');
        }
    }, [id, refreshKey]); // Re-fetch data on refreshKey change

    const handleStatusUpdate = async () => {
        if (nextStatus && currentStatus !== null) {
            try {
                const jwtToken = localStorage.getItem('user-jwt-token');
                const response = await axios.post(
                    `${process.env.REACT_APP_BROADCAST_SERVER}/referral/update-referral`,
                    { id: id, track_status: nextStatus.value },
                    {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                if (response.data.success) {
                    alert(`Status updated to: ${nextStatus.label}`);
                    
                    // Trigger a re-fetch and re-render by updating refreshKey
                    setRefreshKey(prevKey => prevKey + 1);
                } else {
                    alert('Failed to update status.');
                }
            } catch (error) {
                console.error('Error updating status:', error);
                alert('An error occurred while updating status.');
            }
        }
    };

    return (
        <main className="wrapper">
            <section className="d-flex">
                <div className="container d-flex flex-column">
                    <div className="max-1140 pt-3 pt-sm-5 px-lg-0 pb-5 overflow-hidden mx-auto">
                        <div className="row g-2 mb-4 align-items-center">
                            <div className="col-sm">
                                <div className="d-flex flex-column">
                                    <h3 className="font-35 font-600 mb-0 block-title text-center">Referral Progress Tracker</h3>
                                    <p className="mb-0 text-center">
                                        You referred a candidate for admission to
                                        <span className="font-500"> {instName}</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="referral-progress-wrap pt-5">
                            <div className="row g-4">
                                <div className="col-md-4 col-sm-6">
                                    {referralData && (
                                        <div className="referral-progress-card">
                                            <div className="profileimg-name ">
                                                <div className="profile-name ">
                                                    {referralData.lead_first_name} {referralData.lead_last_name}
                                                </div>
                                            </div>
                                            <div className="referral-profile-detail">
                                                <ul>
                                                    <li>
                                                        <i className="hand-share"></i>
                                                        <div className="d-flex flex-column">
                                                            <span className="font-500">Relationship</span>
                                                            <span>{referralData.relationship}</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <i className="mobile"></i>
                                                        <div className="d-flex flex-column">
                                                            <span className="font-500">Mobile</span>
                                                            <a href={`tel:+${referralData.country_code}${referralData.mobile}`}>
                                                                {referralData.country_code} {referralData.mobile}
                                                            </a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-8 col-sm-6">
                                    <div className="referral-progress-track p-5">
                                        <ul>
                                            {statusOptions.map((status, index) => (
                                                <li key={index} className={index < trackData.length ? 'completed' : ''}>
                                                    <div className="icon interaction">
                                                        <img
                                                            src={`https://www.univariety.xyz/prototype/school_dashboard/images/${
                                                                index === 0
                                                                    ? 'first-interaction-completed'
                                                                    : index === 1 || index === 3
                                                                    ? 'application-completed'
                                                                    : index === 2
                                                                    ? 'admission-offer-made'
                                                                    : 'enroll'
                                                            }.svg`}
                                                            className="img-fluid"
                                                            width="37"
                                                            alt={status.label}
                                                        />
                                                    </div>
                                                    <div className="dot">
                                                        <i></i>
                                                    </div>
                                                    <div className="track-label">
                                                        <div className="font-16">{status.label}</div>
                                                        <div className="font-14">
                                                            {index < trackData.length
                                                                ? new Date(trackData[index].track_on).toLocaleDateString()
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ViewTrack;
