import React, { useContext, useState, useEffect, useRef } from 'react';
import { SimpleFormControlArguments, State } from '../../../library/Core/SmartTypes';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { Link } from 'react-router-dom';
import axios from 'axios';

// const Draftmail = (args: SimpleFormControlArguments) => {
const Draftmail = ({ pageTitle, ...args }: { pageTitle: any }) => {
    document.title = pageTitle;

    let sesinstituteId = 0;
    const userDataString = sessionStorage.getItem('user');
    if (userDataString) {
        // Parse JSON string to object
        const userData = JSON.parse(userDataString);
        sesinstituteId = userData.primaryInstitute;
    }
    const limitMessage = (message: string, maxLength: number) => {
        if (message.length > maxLength) {
            return message.substring(0, maxLength) + '...'; // Display an ellipsis if the message is longer
        }
        return message;
    };
    const formatDate = (timestamp: string) => {
        const date = new Date(timestamp);
        const options: Intl.DateTimeFormatOptions = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
        };

        const formattedDate = date.toLocaleString('en-US', options);

        // Replace " at " with an empty string
        return formattedDate.replace(' at ', ' ');
    };
    const schoolId = sessionStorage.getItem('user-school-id');
    const currentDate = new Date();
    const date = currentDate.toDateString();
    const urlParams = new URLSearchParams(window.location.search);
    const templateId = urlParams.get('template_id');
    const [templateData, setTemplateData] = useState<any[]>([]);
    const fetchData = async () => {
        try {
            const jwtToken = localStorage.getItem('user-jwt-token');
            const response = await axios.post(
                `${process.env.REACT_APP_BROADCAST_SERVER}/email/get-schedule-mail`,
                {
                    school_ref_id: sesinstituteId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`, // Using Bearer token authentication
                    },
                }
            );

            setTemplateData(response.data.templates);
            const responseData = response.data;
            // Extract the "message" and "subject" from the response

            const messageFromAPI = responseData.message;
            const subjectFromAPI = responseData.subject;

            // Use the extracted data as needed
            console.log('Message from API:', messageFromAPI);
            console.log('Subject from API:', subjectFromAPI);

            //setAttachedFilePath(attachedFilePathFromAPI);
            // Rest of your code
        } catch (error) {
            // Handle errors here
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData(); // Call the async function when the component mounts
    }, []); // Empty dependency array to run once on mount

    const selectedStudentsString = sessionStorage.getItem('student_data_for_email');

    if (selectedStudentsString) {
        // Parse it back to an array
        const selectedStudents: string[] = JSON.parse(selectedStudentsString);
        const parsedStudents: string[] = selectedStudents.map((uuid: string) => {
            // Replace 'parseUuid' with your parsing logic
            return uuid;
        });
        console.log('user', parsedStudents);
    }

    return (
        <div className="main flex-1">
            <div className="max-1140 mx-auto d-flex h-100">
                <div className="flex-1 d-flex flex-column mb-5 w-100">
                    <div className="pageContent flex-1">
                        <div className="mb-5 pb-4">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div
                                        className="white-block
                                        white-block-notopborderradius h-100
                                        p-0">
                                        <div className="d-flex justify-content-between align-items-center my-3 px-4">
                                            <h3
                                                data-aos="fade-left"
                                                data-aos-delay="600"
                                                className="font-35 font-600 mb-0
                                                block-title aos-init
                                                aos-animate">
                                                Broadcast
                                            </h3>
                                            <div
                                                className="dashboard innerpage
                                                smart-scroll">
                                                <Link
                                                    to={`/broadcast/createnew`}
                                                    className="btn btn-green w-auto
                                                height-40 d-inline-flex
                                                text-white font-14
                                                text-decoration-none
                                                job_internship_btn aos-init
                                                aos-animate">
                                                    <span> Create New</span>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="job_internship_tab polartabsec px-4 mx-0">
                                            <div className="xscroll p-0  ">
                                                <ul
                                                    className="nav nav-tabs"
                                                    id="JobInternshipTab"
                                                    role="tablist"
                                                    data-aos="fade-left"
                                                    data-aos-delay="900">
                                                    <li className="nav-item" role="presentation">
                                                        <a href="/broadcast" className="nav-link">
                                                            Drafts
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a href="/broadcast/schedule-mail" className="nav-link active">
                                                            Scheduled
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a href="/broadcast/sent-mail" className="nav-link">
                                                            Completed
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div
                                            className="row m-0 pt-4 pb-5 mt-2
                                            JobInternshipListing px-3">
                                            <div
                                                className="flex-1 polarcontsec
                                                tab-content"
                                                id="myTabContent"
                                                data-aos="fade-left"
                                                data-aos-delay="1200">
                                                <div
                                                    className="tab-pane fade show
                                                    active"
                                                    id="draftsbroadcast"
                                                    role="tabpanel"
                                                    aria-labelledby="draftsbroadcast-tab">
                                                    <div className="row EventsBlk">
                                                        <div
                                                            className="col-lg-12
                                                            col-md-12
                                                            broadcast_table">
                                                            <div
                                                                className="table-responsive
                                                                attendee_dtl">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Broadcast Title</th>
                                                                            <th>Created DateTime</th>
                                                                            <th>Scheduled Datetime</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {templateData.map((template, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    {template.template_ref_id ? (
                                                                                        <Link
                                                                                            to={`/broadcast/template-preview-later?template_id=${template.id}&temp_id=${template.template_ref_id}`}>
                                                                                            {limitMessage(template.subject, 100)}
                                                                                        </Link>
                                                                                    ) : (
                                                                                        <Link
                                                                                            to={`/broadcast/preview-later?template_id=${template.id}`}>
                                                                                            {limitMessage(template.subject, 100)}
                                                                                        </Link>
                                                                                    )}
                                                                                </td>
                                                                                <td>{template.created_date}</td>
                                                                                <td>{formatDate(template.schedule_for_later)}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Draftmail;
