import { useContext, useEffect, useState } from "react";
import { SmartContext } from "../../../../../../library/Core/SmartContext";
import EditInfluencePageConfig from './FormConfiguration/EditInfluencePageConfig.json'
import EditInfluenceModal from "./EditInfluenceModal";
import { Tooltip } from "bootstrap";
import { isEmpty } from "../../../../../../library/Core/SmartFunctions";
import ProfileMarkerService from "./ProfileMakerService";

type InfluenceProps = {
    officeBearerofAlumniAssociation: { label: string,isComment:string,toolTip:string };
    positionHolderdursingInstitute: { label: string,isComment:string,toolTip:string };
    socialInfluence: { label: string,isComment:string,toolTip:string };
    popularityinBatch:{ label: string,isComment:string,toolTip:string };

};

const InfluenceProps: InfluenceProps = {
    officeBearerofAlumniAssociation: { label: 'Office Bearer of Alumni Association', isComment: 'officeBearerofAlumniAssociationComment', toolTip:"Alumnus is or was holding a position in the alumni association" },
    positionHolderdursingInstitute: { label: 'Position holders during time at institute', isComment: 'positionHolderdursingInstituteComement', toolTip:"Alumnus was holding a position like school captain, sports captain, Prefect, Head of Club/ Committee, Student Council etc during their time at institute" },
    socialInfluence: { label: 'Social Media Influencer', isComment: 'positionHolderdursingInstituteComement123', toolTip:"Alumnus is a social media influencer with lakhs of followers on Facebook/Instagram/LinkedIn/ Twitter etc" },
    popularityinBatch: { label: 'Popular in Batch', isComment: 'popularityinBatchComment', toolTip:"Alumnus has been one of the popular personalities within the batch and faculty members" }
};


const keys = Object.keys(InfluenceProps) as (keyof InfluenceProps)[];

const Influence = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { handleOpenLogsModal } = ProfileMarkerService();
    
    const handleOpenModal = async (key: string) => {
        const socialInfluence = JSON.parse(state.data.marker?.socialInfluence?.socialInfluence || '[{}]');
        // Generate socialInfluenceData from socialLinks
        const socialInfluenceData = state.data.socialLinks
            .filter((link: any) => link.link && link.link.trim() !== '')
            .map((link: any) => ({
                socialInfluence: link.socialNetworkCode,
                socialInfluenceProfileLink: link.link,
                socialInfluenceFollowerCount: '' 
            }));
            const finalSocialInfluence = socialInfluence.length > 0 ? socialInfluence : socialInfluenceData;
            console.log("finalSocialInfluence",finalSocialInfluence)
    
        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {
                    ...state.data,
                    visibleControl: key,
                    socialInfluence: finalSocialInfluence,
                },
            },
        });
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showInfluenceModal' } });
    };
    

    const model = state.internal.model;
    const [isConfigAvailable, setisConfigAvailable] = useState(false);
    const markers = state.data.marker || [];

    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, [state.data.influence]);

    useEffect(() => {
        Promise.resolve(EditInfluencePageConfig)
            .then((config) => {
                const socialInfluence = JSON.parse(state.data.marker?.socialInfluence?.socialInfluence || '[]');
               const socialInfluenceData = state.data.socialLinks
               .filter((link:any) => link.link && link.link.trim() !== '')
               .map((link:any) => ({
                   socialInfluence: link.socialNetworkCode,
                   socialInfluenceProfileLink: link.link,
                   socialInfluenceFollowerCount: '' // Placeholder or default value
               }));

               const finalSocialInfluence = socialInfluence.length > 0 ? socialInfluence : socialInfluenceData;

                dispatch({
                    type: 'DATA_INIT',
                    payload: {
                        formConfig: config,
                        data: {
                            ...state.data,
                            influence: state.data.marker?.influence || {},
                            socialInfluence: finalSocialInfluence
                        },
                        domain: new Map([...state.domain]),
                        internal: state.internal,
                        routeInfo: state.routeInfo,
                    },
                });
                setisConfigAvailable(true);
            })
            .catch((error) => {
                console.error('Error fetching page config:', error);
            });
    }, []);

    useEffect(() => {
        const influenceData = { ...state.data.influence };
        Object.keys(InfluenceProps).forEach((key) => {
            const influenceKey = key as keyof InfluenceProps;
            const prop = InfluenceProps[influenceKey];
            if (prop && typeof prop === 'object' && 'isComment' in prop) {
                const isCommentField = prop.isComment;
                if (influenceData[influenceKey] === false) {
                    if (isCommentField) {
                        influenceData[isCommentField as keyof typeof influenceData] = '';
                    }
                    if (influenceKey === 'officeBearerofAlumniAssociation') {
                        influenceData['officeBearerofAlumniAssociationRemark'] = '';
                    }
                }
            }
        });
    
       
        if (JSON.stringify(state.data.influence) !== JSON.stringify(influenceData)) {
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: 'influence', value: influenceData },
            });
        }
    }, [state.data.influence, dispatch]);

    const comment = (key: string) => {
        const prop = InfluenceProps[key as keyof InfluenceProps];
        return prop.isComment ? state.data.influence?.[prop.isComment] : '';
    };

    const isMarkerEnabled = (key: string): boolean => {
        const value = markers?.influence?.[key];
        if (value === true) return true;
        if (typeof value === 'string') {
          if (!isEmpty(value)) {
            return true;
          }
        }

        if (key === 'socialInfluence') {
            const nestedValue = markers?.socialInfluence?.socialInfluence;
            if (typeof nestedValue === 'string') {
                try {
                    const parsedValue = JSON.parse(nestedValue);
                    if (Array.isArray(parsedValue)) {
                        return parsedValue.length > 0 && parsedValue.some(item => {
                            if (typeof item === 'object' && item !== null) {
                                return Object.keys(item).length > 0;
                            }
                            return true;
                        });
                    } else if (typeof parsedValue === 'object' && parsedValue !== null) {
                        return Object.keys(parsedValue).length > 0;
                    }
                } catch (e) {
                    console.error('Failed to parse nestedValue as JSON:', e);
                }
            } else if (Array.isArray(nestedValue)) {
                return nestedValue.length > 0;
            } else if (typeof nestedValue === 'object' && nestedValue !== null) {
                return Object.keys(nestedValue).length > 0;
            }
        }
        return false;
      };

      const formatStringValue = (val:string) => {
        if (!val) return '';
        // Convert to lowercase, replace underscores with spaces, and capitalize each word
        return val
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const renderMeter = (key: any) => {
        const displayValue = key === 'popularityinBatch' && markers?.influence?.popularityinBatch 
            ? formatStringValue(markers.influence.popularityinBatch) 
            : '';
        
        const meterType = (displayValue === 'Very Popular') 
            ? 'positive' 
            : (displayValue === 'Somewhat Popular') 
            ? 'neutral' 
            : 'negative';
    
            return (
                <div className="col-auto">
                    <div className={`d-flex flex-column justify-content-center align-items-center ${key === 'popularityinBatch' && !isEmpty(displayValue) ? '' : 'fade'}`}>
                        <div className={`meter-wrap ${meterType}`}>
                            <span className="meter-icon">
                                <i className="pointer-pin"></i>
                            </span>
                        </div>
                        <div className="meter-title">{displayValue}</div>
                    </div>
                </div>
            );
    };
    

    return (
        <>
            <div className="tab-pane fade show active" id="v-pills-influence" role="tabpanel" aria-labelledby="v-pills-influence-tab">
                <div className="max-540 mx-auto">
                    <div className="row g-3">
                        {keys.map((key) => {
                             const markerExists =markers?.influence && key in markers.influence;

                             const markerEnabled = isMarkerEnabled(key);
                             const comm = comment(key);
                             const value = markerExists ? markers.influence[key] : null;
                             const displayValue = key === 'popularityinBatch' ? formatStringValue(value) : (value == 1 ? 'Yes' : '');
                             const meterType=(displayValue=='Very Popular') ? 'positive' : ((displayValue=='Somewhat Popular')) ? 'neutral' : 'negative'
                             
                            //  let socialInfluenceCount = 0;
                            //  const socialInfluence = markers?.socialInfluence?.socialInfluence;
                            // if (key === 'socialInfluence') {
                            //     if (Array.isArray(socialInfluence)) {
                            //         socialInfluenceCount = socialInfluence.length;
                            //     } else if (typeof socialInfluence === 'string') {
                            //         try {
                            //             const parsed = JSON.parse(socialInfluence);
                            //             if (Array.isArray(parsed)) {
                            //                 socialInfluenceCount = parsed.length;
                            //             }
                            //         } catch (e) {
                            //             console.error('Error parsing mediaMentions string:', e);
                            //         }
                            //     }
                            // }
                            const ispopularityinBatch = key === 'popularityinBatch';

                            return ( // Use return here to return the JSX
                                <div className="col-12" key={key}>
                                    <div className="row g-3 align-items-center">
                                        {renderMeter(key)}
                                        <div className="col">
                                        <div className={`listed-border ${markerEnabled ? '' : 'disabled'}`}>                                            <i className="tick"></i>
                                                <p onClick={() => handleOpenModal(key)}>
                                                    {InfluenceProps[key].label}{' '}
                                                    {key === 'socialInfluence' && markerEnabled ? `` : ''}
                                                    {displayValue ? ` - ${displayValue}` : ''}
                                                </p>
                                                {comm && (
                                                    <span className="note-wrap" data-bs-toggle="tooltip" data-bs-html="true" title={comm}>
                                                        <i className="icon-note"></i>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i
                                                className="icon-info"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                data-bs-original-title={InfluenceProps[key].toolTip}
                                                aria-label={InfluenceProps[key].toolTip}></i>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                      <div className="col-12 pt-3">
                        <button className="btn btn-dark-blue w-auto height-40 px-4 d-flex align-items-center mx-auto" onClick={() => handleOpenLogsModal()}>
                        <span className="text-white">View Update Logs</span>
                        </button>
                    </div>
                    </div>
                </div>
                {isConfigAvailable && <EditInfluenceModal userdata={{ model }} />}
            </div>
        </>
    );
};

export default Influence;