import { useContext } from 'react';
import { SmartContext } from '../../library/Core/SmartContext';
import { formatFullName, isEmpty } from '../../library/Core/SmartFunctions';
import { DomainElement } from '../../library/Core/SmartTypes';
import { axiosClient } from '../../library/Service/axiosClient';
import SessionContext from '../../library/Core/SessionContext';

const useMessages = () => {
    const { state, dispatch } = useContext(SmartContext);
    const pageSize = state.data.pagination.pageSize;
    const { sessionState } = useContext(SessionContext);

    const setState = (type: string, dataKey: string, value: any) => dispatch({ type, payload: { dataKey, value } });

    const setConversationsInState = (conversations: any) => {
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'conversations', value: conversations } });
        paginateConversations(0, pageSize);
    };

    async function performAction(action: string, conversationIds: any) {
        // const result = await axiosClient().post(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/${action}`, conversationIds);
        const result = await axiosClient().post(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/action/${action}`, conversationIds);
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'originalConversations', value: result.data.conversations } });
        setConversationsInState(result.data.conversations);
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: `actions.selectAll`, value: false } });
    }

    function resetNewMessageMode() {
        dispatch({ type: 'ROUTE_INFO', payload: { ...state.routeInfo, newMessageMode: null, id: null } });
        const currentUrl = window.location.pathname;
        const modifiedUrl = '/messages';
        if (currentUrl.startsWith('/messages/send-message/')) {
            // Replace the current URL with the modified one
            window.history.replaceState(null, currentUrl, '/messages');
        }
    }

    function closeNewMessageWindow() {
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'selectedConversation', value: {} } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'newMessageMode', value: false } });
    }

    async function applyFilterByMessageType(criteria: string) {
        let result: any = [];

        const response = await refreshConversations();
        console.log(response, ' response 474747');

        const conversations = response.data.conversations;

        if (criteria === '' || criteria === 'ALL_MESSAGES') {
            result = conversations;
        } else if (criteria === 'UNSEEN_MESSAGES') {
            result = conversations.filter((conversation: any) => conversation.isSeen === 0);
        } else if (criteria === 'STARRED_MESSAGES') {
            result = conversations.filter((conversation: any) => conversation.isStarred === 1);
        }
        setConversationsInState(result);
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'filters.messageType', value: criteria } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'selectedConversation', value: {} } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'newMessageMode', value: false } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'selectedFilter', value: criteria } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'originalConversations', value: response.data?.conversations } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'memberProfiles', value: response.data?.memberProfiles } });
    }

    async function applyFilter() {
        applyFilterByMessageType(state?.data?.filters?.messageType);
    }

    function applyFilterBySchool(schools: any) {
        let result: any = [];
        const conversations = state.data.originalConversations;

        result = conversations.filter((conversation: any) => {
            const members = conversation.memberInfoJson;
            let isSchoolMatched = false;
            members.forEach((member: any) => {
                const memberDetails = getUserInfo(member);
                if (schools.includes(memberDetails?.schoolId?.toString())) {
                    isSchoolMatched = true;
                }
            });
            return isSchoolMatched;
        });
        setConversationsInState(result);
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'newMessageMode', value: false } });
    }

    function applyFilterBySearchString(searchText: string) {
        const conversations = state.data.originalConversations;
        const iSearchText = searchText.toLowerCase();

        const result = conversations.filter((conversation: any) => {
            const foundInMessage = conversation.message.toLowerCase().includes(iSearchText);
            let foundInMembers = conversation.memberInfoJson.some((member: any) => {
                const memberInfo = getUserInfo(member);

                let name = `${memberInfo?.firstName} ${memberInfo?.lastName}`;
                return (
                    name?.toLowerCase().includes(iSearchText) ||
                    memberInfo?.firstName?.toLowerCase().includes(iSearchText) ||
                    memberInfo?.lastName?.toLowerCase().includes(iSearchText) ||
                    memberInfo?.companyName?.toLowerCase().includes(iSearchText)
                );
            });
            return foundInMessage || foundInMembers;
        });

        setConversationsInState(result);
    }

    async function getById(conversationId: string) {
        const result = await axiosClient().get(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/${conversationId}`);
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'selectedConversation', value: result.data } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'selectedConversationId', value: conversationId } });
    }

    async function applyUserTypeFilter(criteria: string) {
        console.log('criteria', criteria);
        let result: any = [];
        const currentUserId = sessionState?.studentId ?? sessionState?.userId;
        const conversations = state.data.originalConversations;
        if (!isEmpty(criteria)) {
            conversations.forEach((conversation: any) => {
                const members = conversation.memberInfoJson;
                members.forEach((member: any) => {
                    if (member === currentUserId) return;
                    const memberDetails = getUserInfo(member);
                    if (memberDetails?.userTypeCode === criteria) {
                        result.push(conversation);
                    } else if (
                        criteria == 'SCHOOL_MGMT' &&
                        ['INSTITUTE_ADMIN', 'INSTITUTE_SUPPORT_MANAGER', 'INSTITUTE_MARKETING_MANAGER'].indexOf(
                            memberDetails?.userTypeCode
                        ) !== -1
                    ) {
                        result.push(conversation);
                    } else if (criteria == 'STUDENTS' && memberDetails?.userTypeCode == 'STUDENT') {
                        result.push(conversation);
                    }
                });
            });
        } else {
            result = conversations;
        }

        setConversationsInState(result);
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'selectedConversation', value: {} } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'newMessageMode', value: false } });
    }

    const getUserInfo = (userId: string) => state.data.memberProfiles.find((member: any) => member?.id === userId);

    const getConversationMembers = (conversationId: any) => {
        const members = state.data.conversations.find((conversation: any) => conversation.id === conversationId)?.memberInfoJson ?? [];
        const memberDetails: any = [];
        members.forEach((member: any) => {
            const userInfo = getUserInfo(member);
            memberDetails.push({
                ...userInfo,
                name: formatFullName(userInfo?.firstName, userInfo?.lastName),
                isFromCurrentUser: member === sessionStorage.getItem('user-id'),
            });
        });
        return memberDetails;
    };

    const openNewMessage = () => {
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'newMessageMode', value: true } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'selectedConversationId', value: 'NEW' } });
    };

    const sendMessage = async (message: string) => {
        const selectedConversationId = state.data.selectedConversationId;
        const result: any = await axiosClient().post(
            `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/${selectedConversationId}/message`,
            {
                message,
            }
        );
        // dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'selectedConversation', value: result.data } });
        // closeNewMessageWindow();
        getById(selectedConversationId);

        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: 'selectedConversationId', value: selectedConversationId },
        });

        return result;
    };

    const createNewConversation = async (newMessage: string, memberIds: string[], userTypeCode: string[]) => {
        const codes = ['STUDENT', 'SCHOOL', 'ALUMNI'];
        let uTypeCode = codes.find((code) => userTypeCode.includes(code)) || 'ALUMNI';

        const result: any = await axiosClient().post(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/`, {
            memberIds,
            message: newMessage,
            userTypeCode: uTypeCode,
        });
        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: 'selectedConversationId', value: result.data.conversationId },
        });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'selectedConversation', value: result.data.conversation } });
        return result;
    };

    const getMyGroupSchools = () => {
        const schoolGroupId = sessionState?.instituteGroupId;
        // return state.domain.get('SCHOOL_CODE')?.filter((school: DomainElement) => school.parentCode == schoolGroupId) ?? [];
        return state.domain.get('INSTITUTE_DOMAIN')?.filter((school: DomainElement) => school.parentCode == schoolGroupId) ?? [];
    };

    const paginateConversations = (page: number, size: number) => {
        const start = page * size;
        const end = start + size;

        const paginatedConversations = state.data.conversations.slice(start, end);
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'paginatedConversations', value: paginatedConversations } });
    };

    const goToNextPage = () => {
        const totalConversations = state.data.originalConversations.length;
        const totalPages = Math.ceil(totalConversations / state.data.pagination.pageSize);

        if (state.data.pagination.currentPage + 1 < totalPages) {
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: 'pagination.currentPage', value: state.data.pagination.currentPage + 1 },
            });
            paginateConversations(state.data.pagination.currentPage + 1, state.data.pagination.pageSize);
        }
    };

    const goToPreviousPage = () => {
        if (state.data.pagination.currentPage > 0) {
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: 'pagination.currentPage', value: state.data.pagination.currentPage - 1 },
            });
            paginateConversations(state.data.pagination.currentPage - 1, state.data.pagination.pageSize);
        }
    };

    const getNoRecordsMessage = () => {
        if (state.data.selectedFilter === 'UNSEEN_MESSAGES') return 'You are up to date! There are no unread conversations.';
        return 'When was the last time you spoke to someone from your school? Days, months, or years ago? Re-connecting with batchmates, seniors, the staff is always nostalgic and feels like a trip down the memory lane.';
    };

    const refreshConversations = async () => {
        const response = await axiosClient().get(
            `${process.env.REACT_APP_MESSAGE_SERVER}/conversations?userTypeCode=${state.data.searchBy}`
        );

        console.log(response, ' response 252 ');

        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'originalConversations', value: response.data?.conversations } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'conversations', value: response.data?.conversations } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'memberProfiles', value: response.data?.memberProfiles } });
        // applyFilter();
        return response;
    };

    return {
        performAction,
        getById,
        setState,
        getUserInfo,
        getConversationMembers,
        applyFilterByMessageType,
        applyFilterBySearchString,
        sendMessage,
        applyUserTypeFilter,
        openNewMessage,
        createNewConversation,
        getMyGroupSchools,
        applyFilterBySchool,
        paginateConversations,
        goToPreviousPage,
        goToNextPage,
        pageSize,
        getNoRecordsMessage,
        refreshConversations,
        resetNewMessageMode,
        closeNewMessageWindow,
    };
};

export default useMessages;
