import { Modal, Tooltip } from 'bootstrap';
import React, { useContext, useEffect, useState } from 'react'; // Add this line to import React
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../library/Core/GlobalContext';
import { getDomainValueForCode, getWhiteLabelUrl, isEmpty } from '../../library/Core/SmartFunctions';
import { State } from '../../library/Core/SmartTypes';
import PeerReviewService from './PeerReviewService';
import SessionContext from '../../library/Core/SessionContext';

const AdminPeerReview = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    // const { state } = useContext(SmartContext);

    const userTypeCode = sessionStorage.getItem('user-type-code');
    const { data } = useLoaderData() as State;
    const { sessionState } = useContext(SessionContext);

    useEffect(() => {
        data.peerProfile = data.peerProfile.map((student: any) => {
            student.schoolName = getDomainValueForCode(student.schoolId, 'INSTITUTE_DOMAIN', { domain: globalState.domain } as State);
            student.profilePictureFilePath = student.profilePictureFilePath ?? 
            (student.genderCode === 'MALE'
                ? `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student-boy.svg`
                : `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student.svg`);

            return student;
        });
    }, []);

    const students = data.peerProfile;
    console.log(students, ' students ');
    const [displayedStudents, setDisplayedStudents] = useState(students.slice(0, 10));
    const [popupVisible, setPopupVisible] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        setDisplayedStudents(students.slice(0, 10));
    }, [students]);

    const fetchMoreStudents = () => {
        console.log("displayedStudents.length",displayedStudents.length)
        console.log("students.length",students.length)

        if (displayedStudents.length >= students.length) {
            setHasMore(false);
            return;
        }

        const startIndex = displayedStudents.length;
        const endIndex = startIndex + 10;
        const newDisplayedStudents = students.slice(startIndex, endIndex);

        setDisplayedStudents((prevStudents: any) => [...prevStudents, ...newDisplayedStudents]);
    };

    const [hasMore, setHasMore] = useState(true);
    const [approvedRows, setApprovedRows] = useState<string[]>([]);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [selectedAction, setSelectedAction] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    // State to keep track of the total number of newly joined users
    const [newlyJoinedCount, setNewlyJoinedCount] = useState(data?.peerProfile?.length);

    const handleViewProfile = (id: string) => {
        navigate(`/alumni/${id}/profile`);
    };
    const getMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value);
    };
    const handleCheckboxChange = (id: string) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(id)) {
                return prevSelectedRows.filter((rowId) => rowId !== id);
            } else {
                return [...prevSelectedRows, id];
            }
        });
    };

    const handleActionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedAction(event.target.value);
    };

    const applyAction = () => {
        if (!selectedAction) {
            // If no action is selected, return
            return;
        }

        // Combine selected IDs into a comma-separated string and then send all uuuid in single API call
        const selectedIdsString = selectedRows.join(',');
        if (selectedAction === 'approve') {
            PeerReviewService.bulkApproveMyPeer(selectedIdsString, selectedAction)
                .then(() => {
                    // On success, remove the selected rows from the displayed students
                    const updatedDisplayedStudents = displayedStudents.filter((student: any) => !selectedRows.includes(student.uUID));
                    setDisplayedStudents(updatedDisplayedStudents);
                    setNewlyJoinedCount((prevCount: any) => prevCount - selectedRows.length);
                })
                .catch((error) => {
                    // Handle any errors that occur during the API call
                    console.error('Error:', error);
                });
            //selectedRows.forEach((id) => bulkApproveMyPeer(id));
        } else if (selectedAction === 'discard') {
            PeerReviewService.bulkApproveMyPeer(selectedIdsString, selectedAction)
                .then(() => {
                    // On success, remove the selected rows from the displayed students
                    const updatedDisplayedStudents = displayedStudents.filter((student: any) => !selectedRows.includes(student.uUID));
                    setDisplayedStudents(updatedDisplayedStudents);
                    setNewlyJoinedCount((prevCount: any) => prevCount - selectedRows.length);
                })
                .catch((error) => {
                    // Handle any errors that occur during the API call
                    console.error('Error:', error);
                });
            //selectedRows.forEach((id) => discardPeer(id));
        } else if (selectedAction === 'message') {
            const modalElement = document.getElementById('messageBoxModal');
            if (modalElement) {
                const modal = new Modal(modalElement);
                modal.show();
            }
        }
        setSelectedRows([]);
        setSelectedAction('');
    };

    const sendMessage = () => {
        const selectedIdsString = selectedRows.join(',');
    };

    // const bulkApproveMyPeer = (ids: string) => {
    //     PeerReviewService.bulkApproveMyPeer(ids);
    // };

    const approveMyPeer = (id: string,schoolId:any) => {
        PeerReviewService.updateMyPeer(id,schoolId);
        setApprovedRows((prevApprovedRows) => [...prevApprovedRows, id]);
        setSelectedRows((prevSelectedRows) => prevSelectedRows.filter((rowId) => rowId !== id));
        setNewlyJoinedCount((prevCount: any) => prevCount - 1);
        // On success, remove the selected rows from the displayed students
        const updatedDisplayedStudents = displayedStudents.filter((student: any) => student.uUID != id);
        console.log("updatedDisplayedStudents",updatedDisplayedStudents)
        setDisplayedStudents(updatedDisplayedStudents);
      
    };

    const discardPeer = (id: string,schoolId:any) => {
        PeerReviewService.discardMyPeer(id,schoolId);
        setApprovedRows((prevApprovedRows) => [...prevApprovedRows, id]);
        setSelectedRows((prevSelectedRows) => prevSelectedRows.filter((rowId) => rowId !== id));
        const updatedDisplayedStudents = displayedStudents.filter((student: any) => student.uUID != id);
        setDisplayedStudents(updatedDisplayedStudents);
        setNewlyJoinedCount((prevCount: any) => prevCount - 1);
    };

    const approveSelectedPeers = () => {
        selectedRows.forEach((id) => {
           // PeerReviewService.updateMyPeer(id);
        });
        setApprovedRows((prevApprovedRows) => [...prevApprovedRows, ...selectedRows]);
        setSelectedRows([]);
    };

    const discardSelectedPeers = () => {
        selectedRows.forEach((id) => {
           // PeerReviewService.discardMyPeer(id,schoolId);
        });
        setApprovedRows((prevApprovedRows) => [...prevApprovedRows, ...selectedRows]);
        setSelectedRows([]);
    };

    const handleSelectAll = () => {
        if (selectedRows.length === data?.peerProfile?.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(data?.peerProfile?.map((person: any) => person.uUID) || []);
        }
    };

    const handleSendMessage = (id: string) => {
        //state.actions['ROUTER_NAVIGATE'](`/messages/send-message/${id}`);
        navigate(`/messages/send-message/${id}`);
    };

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl, {
                customClass: 'tooltip-html',
                trigger: 'click',
                html: true,
            });
        });
    }, []);

    const createPersonHTML = (person: any) => {
        let educationstring = '';
        let education_type = 'Pursuing';
        if(person.presentEducationStatusCode == 'COMPLETED_STUDIES')
        {
            education_type = 'Completed';
        }
        if(!isEmpty(person.programLevel) && (person.programLevel == 'k12' || person.programLevel <= 12))
        {
            educationstring = person.schoolName ? `${education_type} ${person.programLevel} from ${person.schoolName}` : `${education_type} ${person.programLevel}`;
        }
        else if(person.programLevel == 'UG')
        {
            educationstring = person.ugName ? `${education_type} ${person.programLevel} from ${person.ugName}` : `${education_type} ${person.programLevel}`;
        }
        else if(person.programLevel == 'PG' || person.programLevel == 'Doctoral' || person.programLevel == 'Ph.d')
        {
            educationstring = person.pgName ? `${education_type} ${person.pgType} from ${person.pgName}` : `${education_type} ${person.pgType}`;
        }
        else
        {
            educationstring = 'NA';
        }
        let workstring = 'NA';
        if(person.workingStatusCode == 'PROFESSIONAL_PRACTICE' || person.workingStatusCode == 'WORKING_FOR_A_COMPANY' || person.workingStatusCode == 'ENTREPRENEURSHIP')
        {
            if(person.companyName && person.designation){
                workstring = `Working in ${person.companyName} as ${person.designation}`; 
            }
            else if(person.companyName){
                workstring = `Working in ${person.companyName}`;
            }
            else if(person.designation){
                workstring = `Working as ${person.designation}`;
            }
        }

        const facebookLink = (person?.facebookLink?.length <= 20 || person?.facebookLink == null) ? person?.facebookLink : `<a href="${person?.facebookLink}" target="_blank">View Link</a>` ;
        const instagramLink = (person?.instagramLink?.length <= 20 || person?.instagramLink == null) ? person?.instagramLink : `<a href="${person?.instagramLink}" target="_blank">View Link</a>` ;
        const linkedInLink = (person?.linkedInLink?.length <= 20 || person?.linkedInLink == null) ? person?.linkedInLink : `<a href="${person?.linkedInLink}" target="_blank">View Link</a>` ;
        const twitterLink = (person?.twitterLink?.length <= 20 || person?.twitterLink == null) ? person?.twitterLink : `<a href="${person?.twitterLink}" target="_blank">View Link</a>` ;

        return (
            `<div>
                <p><strong>Enrollment Number:</strong> ${person?.enrollmentNumber ?? 'NA'}</p>
                <p><strong>Education Status:</strong> ${educationstring}</p>
                <p><strong>Working Status:</strong> ${workstring}</p>
                <p><strong>Facebook:</strong> ${facebookLink?.length > 0 ? facebookLink : 'NA'}</p>
                <p><strong>Instagram:</strong> ${instagramLink?.length > 0 ? instagramLink : 'NA'}</p>
                <p><strong>LinkedIn:</strong> ${linkedInLink?.length > 0 ? linkedInLink : 'NA'}</p>
                <p><strong>X (Twitter):</strong> ${twitterLink?.length > 0 ? twitterLink : 'NA'}</p>
            </div>`
        );
    };

    const base_path=getWhiteLabelUrl(sessionState)

    return (
        <div className="container max-1140 px-lg-0">
            <div className="row">
                <div className="col-md-12">
                    <div className="white-block white-block-notopborderradius h-100 p-0">
                        <div className="d-flex flex-wrap justify-content-between align-items-center mb-1 px-4 py-3 py-sm-4">
                            <h3
                                className="font-35 font-600 mb-0 mt-2 mt-sm-3 block-title aos-init aos-animate"
                                data-aos="fade-left"
                                data-aos-delay="300">
                                Peer Account Review
                            </h3>
                            {/* <div className="d-flex">
                                <label className="form-check-label mx-3">
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.length === data?.peerProfile?.length}
                                        onChange={handleSelectAll}
                                        className="form-check-input"
                                    />
                                    Select All
                                </label>
                                <select value={selectedAction} onChange={handleActionChange} className="form-select me-2">
                                    <option value="">Select Action</option>
                                    <option value="approve">Approve</option>
                                    <option value="message">Ask More Info</option>
                                    <option value="discard">Discard</option>
                                </select>

                                <button
                                    onClick={applyAction}
                                    className="btn-green height-40 w-max-content text-white"
                                    disabled={!selectedAction}>
                                    <span>Apply</span>
                                </button>
                            </div> */}
                        </div>

                        <div className="row job_internship_tab polartabsec mx-0">
                            <div className="col-12">
                                <p className="font-18 font-500 mb-0 text-black">
                                    <span>{newlyJoinedCount} </span>newly joined users are waiting for their accounts to be fully activated
                                    on this network.
                                    <br />
                                    Help us speed up the process, by identifying the people you know.
                                </p>
                            </div>
                        </div>

                        <div className="row mx-0 mb-0 px-4 pb-5 mt-40 JobInternshipListing">
                            <div className="col-12">
                                <div className="max-850 mx-auto">
                                    <InfiniteScroll
                                        dataLength={displayedStudents.length}
                                        next={fetchMoreStudents}
                                        hasMore={displayedStudents.length < students.length}
                                        // loader={<h4>Loading...</h4>}
                                        loader={''}
                                        endMessage={<p>No more students to load</p>}>
                                        {displayedStudents?.map((person: any) => (
                                          !approvedRows.includes(person.uUID) && (
                                            <div key={person.uUID} className="row uni_profiles_100" id="datascene">
                                                <div id="user_493763" className="USERS">
                                                    <div className="row g-3">
                                                        <div className="col-md">
                                                            <div className="check_link d-flex align-items-center">
                                                                <div className="d-flex flex-wrap align-items-center w-100">
                                                                    {/* <input
                                                                        type="checkbox"
                                                                        className="form-check-input customCheckBox me-2 mt-0"
                                                                        checked={selectedRows.includes(person.uUID)}
                                                                        onChange={() => handleCheckboxChange(person.uUID)}
                                                                    /> */}
                                                                    
                                                                        <div data-bs-toggle="tooltip" data-bs-html="true" data-bs-original-title={createPersonHTML(person)} className='cursor-pointer'>
                                                                        <span className="peerprofile">
                                                                            <img
                                                                                src={person.profilePictureFilePath}
                                                                                alt={person.name}
                                                                                width={60}
                                                                            />
                                                                        </span>
                                                                        </div>
                                                                        
                                                                        <div className="d-flex flex-column justify-content-center">
                                                                            <p className="font-16 font-500 mb-0 text-black">
                                                                            <a
                                                                        href={`${base_path}alumni/${person.uUID}/profile`}
                                                                        className="d-inline-flex justify-content-start align-items-center text-decoration-none text-black">{person.name},{' '}</a>
                                                                                <span className="font-14 fst-italic">
                                                                                    {person.classCode}
                                                                                </span>
                                                                            </p>
                                                                            <p className="font-14 fst-italic font-500 text-gray-64 mb-0">
                                                                            {person.schoolName ?? person.schoolName}{person.schoolName && person.passOutYear ? ', '+person.passOutYear :  person.passOutYear ?? person.passOutYear}
                                                                            </p>
                                                                        </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-auto d-flex align-items-center">
                                                            <div className="paddings-67 d-flex flex-wrap uniprof_btntab_align uniprof_btnmbl_align">
                                                                <button
                                                                    onClick={() => approveMyPeer(person.uUID,person.schoolId)}
                                                                    className="btn-green height-40 w-max-content text-white me-2 my-2">
                                                                    <span>Yes, I know this person!</span>
                                                                </button>

                                                                <button
                                                                    onClick={() => handleSendMessage(person.uUID)}
                                                                    className="btn-darkblue height-40 w-max-content text-white me-2 my-2">
                                                                    <span>Ask More Info</span>
                                                                </button>

                                                                <button
                                                                    onClick={() => discardPeer(person.uUID,person.schoolId)}
                                                                    className="btn-darkblue height-40 w-max-content text-white my-2">
                                                                    <span>Not Sure</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mt-20 mb-20">
                                                        <div className="border-gray-e2"></div>
                                                    </div>
                                                </div>
                                                <div id="loadmore"></div>
                                            </div>
                                          )
                                        ))}
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="messageBoxModal" tabIndex={-1} aria-labelledby="jobDescriptionModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="jobDescriptionModalLabel">
                                Enter the information you waiting!
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setPopupVisible(false)}></button>
                        </div>
                        <div className="modal-body pt-0 px-md-5 pb-4 pb-sm-5">
                            <div className="max-470 mx-auto">
                                <p className="text-center">
                                    <textarea onChange={getMessage}></textarea>
                                </p>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center py-3">
                                <button onClick={sendMessage} className="btn-green max-190 mx-auto">
                                    <span>Send Message</span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                onClick={() => setPopupVisible(false)}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPeerReview;
