import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../library/Core/GlobalContext';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import smartReducer from '../../library/Core/SmartReducer';
import { addUserSpecificDomains } from '../../library/Service/domainService';
import { SmartContext } from '../../library/Core/SmartContext';
import TopCollegesLayout from './TopCollegesLayout';
import PageBuilder from '../../library/Builders/PageBuilder/PageBuilder';
import DebugBarControl from '../../library/SimpleControls/DebugBarControl';

const TopColleges = () => {
    const { globalState } = useContext(GlobalContext);
    const { id, pageName } = useParams();
    const navigate = useNavigate();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;
    // console.log('data in main component');
    // console.log(data);
    useEffect(() => {
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain: new Map([...globalState.domain]), internal, routeInfo },
        });
    }, [data]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <DebugBarControl />
            {!state.flags.isDataLoading && <TopCollegesLayout>{!state.flags.isDataLoading && <PageBuilder />}</TopCollegesLayout>}
        </SmartContext.Provider>
    );
};

export default TopColleges;
