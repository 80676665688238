import { useContext } from 'react';
import { isFormValid } from '../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import logger from '../../../../library/Core/logger';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
import StudentService from '../../../../services/student.service';
import axios from 'axios';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    if (!isImpersonatedSession && !isFormValid(state, dispatch)) {
        alert('Please correct the form errors!');
        return;
    }

      // Send event to Moengage Achievement
      sendMoEngageEvent(state?.data);

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({ id: state.routeInfo.id, achievements: state?.data['achievements'] })
    ).then(() => {
        // if (state?.data?.userStatusCode != 'ACTIVE') {
            // ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.ACHIEVEMENTS, state, dispatch);
        // } else {
        //     state.actions['ROUTER_NAVIGATE'](`/alumni/${sessionStorage.getItem('user-id')}/profile/achievements`);
        // }
        state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile/achievements`);
    });
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.ACHIEVEMENTS, state, dispatch);
};

const sendMoEngageEvent = async (user:any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-achievements`, {
            userInfo       : user
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};