import { useContext, useEffect } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import PieChartComponent from '../../../library/SimpleControls/PieChartControl';
import useManagementDashboard from './useManagementDashboard';

const AlumniPresentStatus = () => {
    const { state } = useContext(SmartContext);
    const { getAlumniPresentStatus } = useManagementDashboard();

    useEffect(() => {
        getAlumniPresentStatus();
    }, [state.data.searchCriteria.alumniPresentStatus.batch, state.data.searchCriteria.alumniPresentStatus.campus]);

    if (isEmpty(state.data?.reportData?.alumniPresentStatus)) return null;

    return (
        <>
            <PieChartComponent data={state.data.reportData.alumniPresentStatus} />
        </>
    );
};

export default AlumniPresentStatus;
