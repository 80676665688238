import { activateUserSession, isValidBusinessObject, setError } from '../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
import StudentService from '../../../../services/student.service';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    if (
        !isImpersonatedSession &&
        state.data.educationPreferenceInfo.doYouRequireApplicationSupportForPgAdmission == true &&
        !customValidationPassed
    ) {
        // alert('Please correct the form errors!');
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state.formValidationErrors);
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            educationPreferenceInfo: state?.data['educationPreferenceInfo'],
            educationCountryPreferences: state?.data['educationCountryPreferences'],
            educationFieldOfStudyPreferences: state?.data['educationFieldOfStudyPreferences'],
            educationUniversityPreferences: state?.data['educationUniversityPreferences'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            // Call the API here to update the status as ACTIVE & COMPLETE.
            activateUserSession(sessionState, sessionDispatch).then(() => {
                state.actions['REDIRECT'](`/alumni/${state.routeInfo.id}/profile`);
            });
        }
    });
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.HIGHER_EDUCATION_PREFERENCE, state, dispatch);
};

export const isFormValidBasedOnCustomValidation = (state: State, dispatch: any) => {
    let isFormValid = true;
    var isFormValid1 = true;
    var isFormValid2 = true;
    var isFormValid3 = true;

    if (
        !state.data['educationPreferenceInfo']?.preferredPgProgramTypeCode == null ||
        state.data['educationPreferenceInfo']?.preferredPgProgramTypeCode == ''
    ) {
        setError(`preferredPgProgramTypeCode`, ['Please enter "The Program Type I Prefer"'], dispatch);
        isFormValid1 = false;
    }

    if (!state.data['educationPreferenceInfo']?.startOn == null || state.data['educationPreferenceInfo']?.startOn == '') {
        setError(`startOn`, ['Please enter "I Aim to Start On"'], dispatch);
        isFormValid2 = false;
    }

    if (
        !state.data['educationPreferenceInfo']?.preferredModeOfProgramCode == null ||
        state.data['educationPreferenceInfo']?.preferredModeOfProgramCode == ''
    ) {
        setError(`preferredModeOfProgramCode`, ['Please enter "My Ideal Program Mode Is"'], dispatch);
        isFormValid3 = false;
    }

    let country_preferenceCode = [
        { id: 'countryCode', label: 'Preferred Location of Study' },
        // { id: 'countryPreferenceCode', label: 'Country Preferences' },
    ];

    if(state?.data?.profile?.userTypeCode == 'ALUMNI') {
        country_preferenceCode.push({ id: 'countryPreferenceCode', label: 'Country Preferences' });
    }

    let isFormValid4 = isValidBusinessObject(
        state?.data['educationCountryPreferences'],
        country_preferenceCode,
        { id: 'countryCode', label: 'Preferred Location of Study' },
        'educationCountryPreferences',
        dispatch
    );
    
    
    let fieldOfStudy_PreferenceCode = [
        { id: 'fieldOfStudyCode', label: 'Course of Study' },
        // { id: 'fieldOfStudyPreferenceCode', label: 'Course Preferences' },
    ];

    if(state?.data?.profile?.userTypeCode == 'ALUMNI') {
        fieldOfStudy_PreferenceCode.push({ id: 'fieldOfStudyPreferenceCode', label: 'Course Preferences' });
    }

    let isFormValid5 = isValidBusinessObject(
        state?.data['educationFieldOfStudyPreferences'],
        fieldOfStudy_PreferenceCode,
        { id: 'fieldOfStudyCode', label: 'Course of Study' },
        'educationFieldOfStudyPreferences',
        dispatch
    );

    let university_PreferenceCode = [
        { id: 'universityId', label: 'University' },
        // { id: 'universityPreferenceCode', label: 'University Preferences' },
    ];

    if(state?.data?.profile?.userTypeCode == 'ALUMNI') {
        university_PreferenceCode.push({ id: 'universityPreferenceCode', label: 'University Preferences' });
    }

    let isFormValid6 = isValidBusinessObject(
        state?.data['educationUniversityPreferences'],
        university_PreferenceCode,
        { id: 'universityId', label: 'University' },
        'educationUniversityPreferences',
        dispatch
    );

    isFormValid = isFormValid1 && isFormValid2 && isFormValid3 && isFormValid4 && isFormValid5 && isFormValid6;
    return isFormValid;
};
