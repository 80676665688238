import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../library/Core/SmartContext';
import { debounce, isSchoolAdmin } from '../../library/Core/SmartFunctions';
import useMessages from './useMessages';

const MessagesLeftBar = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { applyFilterByMessageType, getMyGroupSchools, applyFilterBySchool, applyFilterBySearchString } = useMessages();
    const [selectedSchools, setSelectedSchools] = useState([] as string[]);
    const [selectAll, setSelectAll] = useState(true);

    const schools = getMyGroupSchools();

    const handleMessagesFilter = (criteria: string) => {
        applyFilterByMessageType(criteria);
    };

    useEffect(() => {
        setSelectedSchools(schools.map((school) => school.code));
        setSelectAll(true);
    }, []);

    // useEffect(() => {
    //     applyFilterBySchool(selectedSchools);
    // }, [selectedSchools]);

    const handleCampusSchoolFilter = (event: any) => {
        const schoolCode = event.target.value;
        if (event.target.checked) {
            setSelectedSchools([...selectedSchools, schoolCode]);
            if (selectAll && selectedSchools.length + 1 === schools.length) {
                setSelectAll(true);
            } else {
                setSelectAll(false);
            }
        } else {
            setSelectedSchools(selectedSchools.filter((s) => s !== schoolCode));
            setSelectAll(false);
        }
    };

    const handleRestSchoolFilter = (event: any) => {
        if (event.target.checked) {
            setSelectedSchools(schools.map((school) => school.code));
            setSelectAll(true);
        } else {
            setSelectedSchools([]);
            setSelectAll(false);
        }
    };

    const handleSearchChange = (event: any) => {
        event.preventDefault();
        debounce(applyFilterBySearchString(event.target.value), 500);
    };

    return (
        <div className="col-md-auto ji_filtersec p-0 aos-init aos-animate" data-aos="fade-right" data-aos-delay="900">
            <div className="sideMenu rightpane" id="accordionExample">
                <div className="accordion-item border-0 bg-transparent w-100">
                    <div
                        className="accordion-header d-flex justify-content-between align-items-center d-block d-md-none mobilesidebar"
                        id="headingMainOne">
                        <button
                            className="accordion-button collapsed ms-4"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseMainOne"
                            aria-expanded="true"
                            aria-controls="collapseMainOne">
                            All Messages <span className="icon-down-arrow-invert"></span>
                        </button>
                        <div className="me-4 position-relative overflow-hidden">
                            <a href="" className="toggleSearch icon-search"></a>
                            <form className="filterSearch position-relative">
                                <input
                                    className="form-control flex-1 font-14 font-500 w-100"
                                    type="search"
                                    placeholder="Search"
                                    aria-label="Search"
                                />
                                <button className="btn icon-search position-absolute" type="submit"></button>
                            </form>
                        </div>
                    </div>

                    <div
                        id="collapseMainOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingMainOne"
                        data-bs-parent="#accordionExample">
                        <div className="p-4 d-none d-md-block">
                            <form className="filterSearch position-relative">
                                <input
                                    className="form-control flex-1 font-14 font-500 w-100"
                                    placeholder="Search"
                                    aria-label="Search"
                                    onChange={handleSearchChange}
                                />
                                <button className="btn icon-search position-absolute" type="submit"></button>
                            </form>
                        </div>

                        <div className="accordion" id="accordionSideMenu">
                            {/* {(schools.length > 0 && userTypeCode=="SCHOOL_ADMIN") && ( */}
                            {(schools.length > 0 && isSchoolAdmin() ) && (
                                <div className="accordion-item bg-transparent">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapsecampus"
                                            aria-expanded="true"
                                            aria-controls="collapsecampus">
                                            Campus <span className="icon-down-arrow"></span>
                                        </button>
                                    </h2>
                                    <div
                                        id="collapsecampus"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionSideMenu">
                                        <div className="accordion-body">
                                            <div className="overflow-hidden fiterItem border-0">
                                                <div className="scrollMe">
                                                    <div className="form-check d-flex flex-wrap">
                                                        <input
                                                            className="form-check-input me-2"
                                                            type="checkbox"
                                                            value=""
                                                            id="Select_All"
                                                            onChange={handleRestSchoolFilter}
                                                        />
                                                        <label
                                                            className="form-check-label font-16 font-400 flex-1 ps-1 pt-0"
                                                            htmlFor="Select_All">
                                                            Select All
                                                        </label>
                                                    </div>
                                                    {schools?.map((school) => (
                                                        <div key={school.code} className="form-check d-flex flex-wrap">
                                                            <input
                                                                className="form-check-input me-2"
                                                                type="checkbox"
                                                                id="schoolId"
                                                                value={school.code}
                                                                checked={selectedSchools.includes(school.code)}
                                                                onChange={handleCampusSchoolFilter}
                                                            />
                                                            <label
                                                                className="form-check-label font-16 font-400 flex-1 ps-1 pt-0"
                                                                htmlFor="Ryan_Chandigarh">
                                                                {school.value}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="accordion-item bg-transparent">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="false"
                                        aria-controls="collapseOne">
                                        Message <span className="icon-down-arrow"></span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionSideMenu">
                                    <div className="accordion-body">
                                        <ul className="sidebar_links">
                                            {state.domain.get('MESSAGE_VIEW_TYPE_CATEGORIES')?.map((item) => (
                                                <li key={item.code}>
                                                    <a role="button" onClick={() => handleMessagesFilter(item.code)}>
                                                        {item.value}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* {userTypeCode != "STUDENT" && (
                            <div className="accordion-item bg-transparent">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        Invitations <span className="icon-down-arrow"></span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseTwo"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionSideMenu">
                                    <div className="accordion-body">
                                        <ul className="sidebar_links">
                                            <li>
                                                <a href="">All Invitations</a>
                                            </li>
                                            <li>
                                                <a href="">Unseen Invitations</a>
                                            </li>
                                            <li>
                                                <a href="">Unresponded Invitations</a>
                                            </li>
                                            <li>
                                                <a href="">Accepted Invitations</a>
                                            </li>
                                            <li>
                                                <a href="">Ignored Invitations</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            )} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessagesLeftBar;
