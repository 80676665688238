import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartContext } from '../../../../../library/Core/SmartContext';

const Preferences = () => {
    const { state, dispatch } = useContext(SmartContext);
    const model = state.internal.model;
    const navigate = useNavigate();

    return (
        <div className="tab-pane fade" id="preferences" role="tabpanel" aria-labelledby="pills-postGraduate-tab">
            <div className="graduation_carousel_wrap">
                <div className="InterestsPreferences position-relative">
                    <a
                        className="btn-edit-pop ms-4 position-absolute top-0 right-10 text-decoration-none"
                        role="button"
                        onClick={() => navigate(`/management/${model.id}/preferences`)}>
                        <i className="icon-edit-pencil text-gray-64"></i>
                    </a>
                    <div className="col-12 mb-4">
                        <h2 className="font-24 border-bottom pb-2">Preferences</h2>
                    </div>

                    <div className="row mb-4">
                        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
                            <label className="flex-1 pe-3 font-14 font-500 text-gray-53">Preferred time to get calls from Univariety</label>
                            <span>:</span>
                        </div>
                        <div className="col-sm-7">
                            {model.timeSlots.map((timeSlot: any) => (
                                <div className="mb-3">
                                    <p className="m-0 font-16 font-500">{timeSlot.time}</p>
                                    <div className="days d-flex flex-wrap mt-2">
                                        {timeSlot.days.map((day: any) => (
                                            <div className="white-block">{day}</div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
                            <label className="flex-1 pe-3 font-14 font-500 text-gray-53">Counselling Sessions for current students</label>
                            <span>:</span>
                        </div>
                        <div className="col-sm-7">
                            <p className="m-0 font-16 font-500">{model.counsellingSessionsForCurrentStudents}</p>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
                            <label className="flex-1 pe-3 font-14 font-500 text-gray-53">
                                Do you wish to connect with university representatives on the platform?
                            </label>
                            <span>:</span>
                        </div>
                        <div className="col-sm-7">
                            <p className="m-0 font-16 font-500">{model.wishToConnectWithUniversityRepresentatives}</p>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
                            <label className="flex-1 pe-3 font-14 font-500 text-gray-53"> Interested in upskilling? </label>
                            <span>:</span>
                        </div>
                        <div className="col-sm-7">
                            <p className="m-0 font-16 font-500">{model.interestedInUpskilling}</p>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
                            <label className="flex-1 pe-3 font-14 font-500 text-gray-53"> Updates from Univariety </label>
                            <span>:</span>
                        </div>
                        <div className="col-sm-7">
                            <p className="m-0 font-16 font-500">{model.updateFrequencyFromPlatform}</p>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
                            <label className="flex-1 pe-3 font-14 font-500 text-gray-53"> Additional Email </label>
                            <span>:</span>
                        </div>
                        <div className="col-sm-7">
                            <p className="m-0 font-16 font-500">
                                <a className="innerpageLink" href="mailto:ashish.g2@gmail.com">
                                    {' '}
                                    {model.additionalEmailId}{' '}
                                </a>
                            </p>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
                            <label className="flex-1 pe-3 font-14 font-500 text-gray-53"> Additional Contact Number </label>
                            <span>:</span>
                        </div>
                        <div className="col-sm-7">
                            <p className="m-0 font-16 font-500">N/A</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Preferences;
