import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/css_pre_singup/style.css';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';

const Screening: React.FC = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const [instName, setInstName] = useState<string>('');
    const { schoolDomain } = useParams<{ schoolDomain: string }>();

    const [schoolImage, setSchoolImage] = useState<string | null>(null);

    useEffect(() => {
        const fetchSchoolInfo = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-info/${schoolDomain}`);
                const logoUrl = response.data.institute.logo;
                setSchoolImage(logoUrl);
                localStorage.setItem('school-image', logoUrl);
                sessionStorage.setItem('school-image', logoUrl);
                const instName = response.data.institute.name;
                setInstName(instName);
            } catch (error) {
                console.error('Error fetching school info:', error);
            }
        };

        fetchSchoolInfo();

        // Google Analytics
        const gtagScript = document.createElement('script');
        gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=G-98FDWM9P90`;
        gtagScript.async = true;
        document.head.appendChild(gtagScript);

        const gtagConfigScript = document.createElement('script');
        gtagConfigScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-98FDWM9P90');
        `;
        document.head.appendChild(gtagConfigScript);

        // MoEngage
        const moengageScript = document.createElement('script');
        moengageScript.type = 'text/javascript';
        moengageScript.src = `https://cdn.moengage.com/release/dc_1/moe_webSdk.min.latest.js`; // Adjust 'dc_1' with your actual data center
        moengageScript.async = true;
        
        moengageScript.onload = () => {
            // Ensure MoEngage is initialized after the script has loaded
            (window as any).Moengage = (window as any).Moengage || {};
            (window as any).Moengage = (window as any).moe({
                app_id: "E9LBR9Q15QPZQCZ29HR4Q8RX", // Replace with your actual App ID
                debug_logs: 1
            });
        };

        moengageScript.onerror = () => {
            console.error('MoEngage Web SDK loading failed.');
        };

        document.head.appendChild(moengageScript);
        
    }, [schoolDomain]);
 
    return (
        <main className="wrapper">
            <section className="dark-section d-flex">
                <div className="container d-flex flex-column">
                    <div className="logo">
                        <a href="javascript:;" className="d-flex justify-content-center w-100 h-100"><img src={schoolImage || 'images/logo.svg'} className="" alt="logo" /></a>
                    </div>
                    <div className="flex-1 py-5 d-flex flex-column justify-content-center align-items-center text-white py-5">
                        <div className="home-page-title">In the end, you always go back to where it all began.</div>
                        <div className="sep"></div>
                        <div className="pb-5">
                            <div className="school-name mb-3">{instName}</div>
                            <div className="sub-school-tag">students are spread across the globe,<br className="d-none d-md-block" /> doing well in their personal & professional lives.</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="white-section">
                <div className="container py-5">
                    <p className="footer-text-1 text-dark-gray text-center">Just like Harvard, Oxford & the IIT’s, we too are growing our<br className="d-none d-md-block" /> Past Student Community with the help of Technology.</p>
                    <p className="footer-text-2 text-dark-gray text-center mb-0 mb-sm-5">Click here to see the exciting benefits of the community!</p>
                </div>
            </section>
            <div className="navigation_footer_wrap">
                <div className="navigation_footer justify-content-center">
                    {/* <a href="javascript:;" className="left-link btn-back"><i></i> <span>Back</span></a> */}
                    <a href="info" className="right-link btn-next"><span>Next</span><i></i></a>
                </div>
            </div>
        </main>
    );
};

export default Screening;
