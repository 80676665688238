import { useContext, useEffect, useRef, useState } from 'react';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, getControlValueFromState, getDomainValueForCode, handleControlValueChange, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const TextControl = (args: SimpleFormControlArguments) => {
    const viewIcon = `${process.env.REACT_APP_IMAGE_BASEPATH}/view_icon.svg`;
    const viewStrikeIcon = `${process.env.REACT_APP_IMAGE_BASEPATH}/view_strike_icon.svg`;

    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const [showPassword, setShowPassword] = useState(false);

    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl
    const readOnly =
        control.readOnly ||
        evaluateExpression(control.readOnlyExpression, state?.data, getControlValueFromState(parentDataKey as string, state as State));

    useEffect(() => {
        const errorMessages = validateFormField(
            control,
            data,
            state,
            control?.props?.label,
            dataKey,
            getControlValueFromState(parentDataKey as string, state as State)
        );
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });

        if (isEmpty(data) && !isEmpty(control.props.defaultValue)) {
            handleControlValueChange({ control, value: control.props.defaultValue, dataKey, parentDataKey, state, dispatch });
        }
    }, []);

    // if (isHidden) return <></>;
    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };
    const getTextControl = () => {
        return (
            <>
                <input
                    id={control.id}
                    data-testid={control.id}
                    type={showPassword ? 'text' : control.props.type}
                    disabled={readOnly ?? false}
                    // hidden={control.props?.isCode || isHidden}
                    className={`form-control pl-0`}
                    placeholder={control.props?.placeholder}
                    // value={data || control.props.defaultValue || ''} //@by RG @note unable to remove the default value from the input & just displayig default value in UI, Not in state;
                    value={data || ''}
                    required={control.props?.required}
                    onChange={(event) =>
                        handleControlValueChange({ control, value: event.target.value, dataKey, parentDataKey, state, dispatch })
                    }
                    minLength={control.props?.minLength}
                    maxLength={control.props?.maxLength}
                    min={control.props?.min}
                    max={control.props?.max}
                    ref={formControlRef}
                />
                {(control.props?.type as string) === 'password' && (
                    <span className="password-toggle" onClick={togglePasswordVisibility}>
                        {showPassword ? <img src={viewStrikeIcon} alt="Hide Password" /> : <img src={viewIcon} alt="Show Password" />}
                    </span>
                )}
                {control?.props?.isCode && (
                    <>
                        <input
                            type={control.type}
                            className={`form-control`}
                            value={getDomainValueForCode(data as string, control.props.domainCategoryCode, state)}
                            onChange={() => {}}
                            ref={formControlRef}
                        />
                    </>
                )}
            </>
        );
    };

    return (
        <>
            {control.props?.label && (
                <label htmlFor={control.id} className="form-label w-100">
                    {`${control.props.label} `}
                </label>
            )}
            {control?.props?.icon && (
                <div className="input-group mb-3">
                    <span className="input-group-text">
                        <i className={control.props?.icon}></i>
                    </span>
                    {getTextControl()}
                </div>
            )}
            {!control.props?.icon && getTextControl()}
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default TextControl;
