import { getDashboardProgress } from './getDashboardProgress';
import './styles.css';

const ActivityGroupControl = (props: any) => {
    const activitiesConfig: any = JSON.parse(sessionStorage.getItem('activity-config') as string);
    return (
        <>
            {activitiesConfig && (
                <div className="row justify-content-center mt-4 mt-sm-5 m-3">
                    {activitiesConfig?.map((activityGroup: any) => (
                        <div key={activityGroup.id} className="col-lg-4 col-md-6 col-sm-6 mb-5">
                            <div className="milestone" data-aos="fade-up" data-aos-delay="400">
                                <div className="icon">
                                    <span className={activityGroup.icon}></span>
                                </div>
                                <div className="count">{activityGroup.order}</div>
                                <h3>{activityGroup.name}</h3>
                                <div className="status_buttun">
                                    <ul className="progress-status">
                                        {activityGroup?.activities?.map((activity: any) => (
                                            <li
                                                key={`${activity.id}`}
                                                className={`${getDashboardProgress(
                                                    activity.id,
                                                    props.activityProgressMapper?.[activityGroup.id],
                                                    props.dashboardData
                                                )} `}></li>
                                        ))}
                                    </ul>
                                    <div className="activities_status"></div>
                                    <button
                                        className="btn btn-complete-now mt-4"
                                        onClick={() => props.onCompleteNowClick(activityGroup.id)}>
                                        <span>Complete Now</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default ActivityGroupControl;
