import { activateUserSession, isFormValid, isValidBusinessObject } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
import { validateScore } from '../../../../services/student-validation.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';
import axios from 'axios';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const formValidationPassed = isFormValid(state, dispatch, ['']);
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    if (!isImpersonatedSession && !state.data.profile.haveNotAppearedUgEntranceTest && (!formValidationPassed || !customValidationPassed)) {
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state.formValidationErrors);
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            profile: state?.data['profile'],
            entranceTests: state?.data['entranceTests'],
           // entranceTestForJoined: state?.data['entranceTestForJoined'],
            //entranceTestForApplied: state?.data['entranceTestForApplied'],
        })
    ).then((response) => {
        if (response?.status === 200) {

             // Send event to Moengage job outcome
             sendMoEngageEvent(state?.data);

            navigateToNextStep(AlumniProfileEditScreens.EXAMS, state, dispatch, sessionState, sessionDispatch);

            // // Call the API here to update the status as ACTIVE & COMPLETE.
            // activateUserSession(sessionState, sessionDispatch).then(() => {
            //     state.actions['REDIRECT'](`/alumni/${sessionState.id}/profile`);
            // });
        }
    });
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.EXAMS, state, dispatch);
};

export const onScoreChangeValidateRank = (props: ControlOnChangeArguments) => {
    const errorMessage = validateScore(props.row['entranceTestCode'], props.row['scoreTypeCode'], props.value, props.state);

    props.dispatch({
        type: 'SET_FIELD_VALIDATION_ERRORS',
        payload: { dataKey: props.parentDataKey, errorMessages: errorMessage.length === 0 ? [] : [errorMessage] },
    });
};

export const onScoreTypeCodeChange = (props: ControlOnChangeArguments) => {
    const errorMessage = validateScore(props.row['entranceTestCode'], props.value, props.row['score'], props.state);

    props.dispatch({
        type: 'SET_FIELD_VALIDATION_ERRORS',
        payload: { dataKey: props.parentDataKey, errorMessages: errorMessage.length === 0 ? [] : [errorMessage] },
    });
};

export const isFormValidBasedOnCustomValidation = (state: State, dispatch: any) => {
    let isFormValid = true;
    isFormValid = isValidBusinessObject(
        state?.data['entranceTests'],
        [
            { id: 'entranceTestCode', label: 'Entrance Test', manualColumnId: 'otherExam' },
            { id: 'scoreTypeCode', label: 'Score Type' },
            { id: 'score', label: 'Score' },
            { id: 'year', label: 'Year' },
        ],
        { id: 'entranceTestCode', label: 'Entrance Test' },
        'entranceTests',
        dispatch,
        'Entrance Test'
    );
    return isFormValid;
};

const sendMoEngageEvent = async (user: any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-entrance-test`, {
            userInfo: user,
        });
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return false;
};