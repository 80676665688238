import { useContext, useEffect, useState } from 'react';
import WelcomeLayout from './WelcomeLayout';
import { sendRequest } from './WelcomeActions';
import { axiosClient } from '../../../library/Service/axiosClient';
import SessionContext from '../../../library/Core/SessionContext';
import { useNavigate } from 'react-router-dom';
import { getMenuType } from '../../../library/Core/SmartFunctions';

const Welcome = () => {
    const [alumniList, setAlumniList] = useState([]);
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const sessResp = await axiosClient().post(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/token/${sessionState?.instituteId}`, {});
               
                let menuType = getMenuType(sessResp?.data?.selectedInstitute);

                sessResp.data.selectedInstitute.menuType = menuType;
                sessionDispatch({ type: 'SET', payload: sessResp?.data?.selectedInstitute });

                if (sessResp?.data?.selectedInstitute?.isPeerReviewed) {
                    setRedirectUrl(`/alumni/${sessResp?.data?.selectedInstitute?.studentId}/profile`);
                } else {
                    const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/get-alumni-welcome-screen-users`);
                    const data = await response.data; // Extract the data from the response
                    setAlumniList(data.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                alert(error);
            }
        };

        fetchData();
    }, []);
    
    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    const handleRequest = async (userId: string,approvalType:string) => {
        const updatedList = await sendRequest(alumniList, userId,approvalType);
        if (updatedList) {
            setAlumniList(updatedList);
        }
    };
    return <WelcomeLayout alumniList={alumniList} sendRequest={handleRequest} />;
};

export default Welcome;
