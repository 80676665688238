import { useContext, useEffect, useState } from "react";
import { SmartContext } from "../../../../library/Core/SmartContext";
import SessionContext from "../../../../library/Core/SessionContext";
import JobListingService from "../../../jobs/listing/JobListingService";
import { useParams } from "react-router-dom";
import { getDomainValueForCode, isSchoolAdmin } from "../../../../library/Core/SmartFunctions";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from "bootstrap";

const JobListing = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);
    const { id, pageName } = useParams();
    const [jobs, setJobs] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state to manage the loading spinner

    useEffect(() => fetchJobs(), [state.internal?.refreshJobs]);

    useEffect(() => {
        // Initialize tooltips after jobs are loaded
        if (jobs.length > 0) {
            const tooltipTriggerList = [].slice.call(document.querySelectorAll(".defaultTooltip"));
            tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
        }
    }, [jobs]); // Runs after jobs are updated

    const fetchJobs = () => {
        let searchBy = 'LISTED_BY_USER';
        const schoolAdminRoles = ['INSTITUTE_ADMIN', 'INSTITUTE_SUPPORT_MANAGER', 'INSTITUTE_MARKETING_MANAGER'];

        if (schoolAdminRoles.includes(sessionState?.userTypeCode as string) || sessionState?.userTypeCode === 'SUPER_ADMIN') {
            searchBy = "USER_LIST_BY_ADMIN";
        }

        if (searchBy === 'LISTED_BY_USER' && id !== sessionState?.userId) {
            searchBy = 'LISTED_BY_OTHER_USER';
        }

        JobListingService.get({ searchBy: searchBy, userId: id }).then((jobsResponse) => {
            setJobs(jobsResponse.data.data);
            setIsLoading(false); // Set loading state to false once data is fetched
        });
    };

    const handleSavePreference = (params: any) => {
        JobListingService.savePreference(params);
        setTimeout(() => refresh(), 0);
    };

    const refresh = () => dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'refreshJobs', value: uuidv4() } });

    return (
        <>
            <div className="tab-pane fade show active" id="pills-job-listings" role="tabpanel" aria-labelledby="pills-job-listings-tab" tabIndex={0}>
                <div className="contributions-block p-0 bg-transparent disable-boxshadow">
                    <div className="jobsandInternshipssection">
                        <div className="jobsandInternshipslist">
                            {isLoading ? (
                                // Show loading spinner while data is being fetched
                                <div className="loading-spinner">
                                    <span>Loading...</span> {/* You can replace this with a spinner icon */}
                                </div>
                            ) : jobs.length > 0 ? (
                                // Show job listings when data is loaded
                                jobs.map((job) => (
                                    <div className="act_lst position-relative bg-white" key={job.id}>
                                        <span className="list_badge font-14 font-500">{job.jobTypeCode?.replace("_", " ")}</span>
                                        <div className="row m-0 d-flex flex-wrap justify-content-between align-items-start postedrow">
                                            <div className="flex-1 ps-0">
                                                <h4 className="font-20 font-500 mb-2"> {job.title} @ {job.companyName}</h4>
                                                <div className="d-flex flex-wrap exp_loc">
                                                    <span className="custom_badge">
                                                        <span className="icon-brife-case font-12 text-dark-blue icon"></span>
                                                        <span>
                                                            {job.minExperience} - {job.maxExperience} Year Experience
                                                        </span>
                                                    </span>
                                                    <span className="custom_badge">
                                                        <a href="javascript:void(0)" className="btn btnicon btn-icon-bg">
                                                            <span className="icon-location-pin font-14 text-dark-blue icon"></span>
                                                            <span>{getDomainValueForCode(job.cityCode, 'CITY_CODE', state)}</span>
                                                        </a>
                                                    </span>
                                                    <a
                                                        role="button"
                                                        className="btn btnicon btn-icon-bg defaultTooltip"
                                                        title={job.description}
                                                        style={{ display: 'flex' }}
                                                    >
                                                        <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/file_icon.png`} style={{ width: '10px' }} />
                                                        <span>View Job Description</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="pe-0 text-end">
                                                <div className="px-0 text-end">
                                                    <p className="font-14 font-500 text-dark-blue mb-0 mb-sm-3 postedtxt">
                                                        Posted{' '}
                                                        {moment().diff(job.createdAt, 'days') > 0
                                                            ? moment().diff(job.createdAt, 'days') + ' days ago'
                                                            : ' today'}
                                                    </p>
                                                </div>
                                                <div className="d-flex flex-wrap justify-content-end">
                                                    {!job.isInterested && job.studentId !== sessionState?.id && !isSchoolAdmin() && sessionState?.userTypeCode !== 'SUPER_ADMIN' && (
                                                        <a
                                                            className={`btn ${job.isInterested ? 'btn-dark-blue' : 'btn-green'} height-40 d-inline-flex text-white font-500 font-16 text-decoration-none justify-content-center`}
                                                            id="MakeInterest_42"
                                                            role="button"
                                                            onClick={() => handleSavePreference({ jobId: job.id, isInterested: 1 })}
                                                        >
                                                            <span id="Interest_Icon_42">Send Interest</span>
                                                        </a>
                                                    )}
                                                    {job.studentId !== sessionState?.id && !isSchoolAdmin() && sessionState?.userTypeCode !== 'SUPER_ADMIN' && (
                                                        <a
                                                            className={`btn btnicon listblk_icon me5 circle-effect ${job.isSaved ? 'btn-dark-blue' : ''}`}
                                                            id="MakeSave_42"
                                                            role="button"
                                                            onClick={() => handleSavePreference({ jobId: job.id, isSaved: !job.isSaved })}
                                                        >
                                                            <span className={`icon-ribbon-invert font-18 icon ${job.isSaved ? 'text-white' : ''}`} id="Icon_42"></span>
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="col-12">
                                    <span className="video_block_name">
                                        <label>No Jobs Available</label>
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default JobListing;
