import './FunnelChart.css';
const FunnelChart = ({ keys, values }: any) => {
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div id="stdonboardgraph">
                        <div className="d-flex flex-wrap justify-content-center">
                            <div className="triangleGraph">
                                <ul key={JSON.stringify(values)}>
                                    {values.map((item: any) => (
                                        <li key={item} className="justify-content-center">
                                            <span>{item}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="triangleGraphLabel">
                                <ul>
                                    {keys.map((item: any) => (
                                        <li key={item}>
                                            <span>{item}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FunnelChart;
