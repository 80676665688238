import { useState, useContext, useEffect, useRef } from 'react';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { ControlOnChangeArguments,DispatchEvent, State } from '../../../library/Core/SmartTypes';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { onMobileCountryCodeChange, onMobileNumberChange, onHandleSwitchChange } from './InstituteAction';
import InstituteLayout from './InstituteLayout';
import InstituteService from './InstituteService';
import Swal from 'sweetalert2';
import { isEmpty, isFormValid, setError, updateMoEngageEvent } from '../../../library/Core/SmartFunctions';
import { axiosClient } from '../../../library/Service/axiosClient';
import { isConstructorDeclaration } from 'typescript';

const Institute = () => {
    const { id,groupId } = useParams();
    const { globalState } = useContext(GlobalContext);
    const location = useLocation();
    let pageName: string;
    if (location.pathname.includes('basic-info')) {
        pageName = 'admin-institute-create';
    } else if (location.pathname.includes('online-preference')) {
        pageName = 'institute-online-preference';
    }else if (location.pathname.includes('institute-onboarding')) {
        pageName = 'admin-institute-onboarding';
    } else {
        pageName = 'institute-contacts';
    }
    const instTypes=["UNIVERSITY", "DEEMED_UNIVERSITY", "AFFILIATED_INSTITUTE", "AUTONOMOUS_INSTITUTE", "DISTANCE_AND_ONLNE_LEARNING"]
    const schoolTypes=["SCHOOL", "PRE_SCHOOL", "K12_SCHOOL"]
    const initialInstituteTypeCode = useRef('');

    const navigate = useNavigate();
    type SocialLink = {
        socialNetworkCode: string;
        link: string;
      };

      const currentStatusKeys = [
        'crmSubmitted', 'credSen', 'welcomeEmailSent', 'preSubmitted', 'preComplete', 
        'consentLetterSubmitted', 'consentLetterCorrection', 'awaitingMarketing', 'dataCaptured', 
        'escalationCoo', 'onboardingComplete', 'preApproved', 'alumniDataSubmitted', 
        'alumniDataClarification', 'hdLogoSubmitted', 'hdLogoCorrection', 
        'onboardingDelayed', 'raiseRedFlag', 'salesHead', 'preNotSubmitted'
    ];


    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            SAVE: handleSave,
            mobileNumber: onMobileNumberChange,
            mobileCountryCode: onMobileCountryCodeChange,
            ...Object.fromEntries(currentStatusKeys.map(field => [field, onHandleSwitchChange])),
        },
    } as any);

    let { formConfig, data } = useLoaderData() as State;

    const getSocialProfileLinks = () => {
        return globalState.domain?.get('SOCIAL_NETWORK_CODE')?.map((item: any) => ({ socialNetworkCode: item.code, link: '' }));
    };

    useEffect(() => {
        const domain = globalState.domain;
        console.log("links",getSocialProfileLinks())
        if (id === 'new') {
            dispatch({
                type: 'DATA_INIT',
                payload: {
                    formConfig,
                    data: { institute: {}, socialLinks: getSocialProfileLinks(), onlinePreference: {}, onboarding:{}, contacts: {} },
                    domain,
                    routeInfo: { id, pageName: pageName },
                },
            });
        } else {
            console.log(data.instituteTypeCode);
            initialInstituteTypeCode.current = data.instituteTypeCode; // Set the initial institute type code here
            console.log(initialInstituteTypeCode.current);
            let socialData= (data?.socialLinks) ? JSON.parse(data?.socialLinks) : getSocialProfileLinks();
            console.log(socialData);

            dispatch({
                type: 'DATA_INIT',
                payload: {
                    formConfig,
                    data: { institute: data, socialLinks: socialData, onlinePreference: data, onboarding:data, contacts: {} },
                    domain,
                    routeInfo: { id, pageName: pageName },
                },
            });

        }
        // console.log("Updated state.formConfig:", state.formConfig);
    }, [pageName]);


    const handleAddInstitute = () => {
        dispatch({ type: 'RESET_VALIDATION_ERRORS' });
        navigate(`/institute/${id}/basic-info`, { replace: false });
    };
    const handleOnlinePreference = () => {
        dispatch({ type: 'RESET_VALIDATION_ERRORS' });
        navigate(`/institute/${id}/online-preference`, { replace: false });
    };
    const handleContacts = () => {
        dispatch({ type: 'RESET_VALIDATION_ERRORS' });
        // navigate(`/institute/${id}/contact-listing`, { replace: false });
        navigate(`/institute/${id}/${state?.data?.institute.instituteGroupId}/contact-listing`, { replace: false });
    };
    const handleInstituteOnboarding = () => {
        dispatch({ type: 'RESET_VALIDATION_ERRORS' });
        navigate(`/institute/${id}/institute-onboarding`, { replace: false });
    };

    const ishaveWebsite = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        if (state?.routeInfo.pageName == 'institute-online-preference') {
            console.log("state?.data?.onlinePreference?.haveWebsite", state?.data?.onlinePreference?.haveWebsite)
            if (state?.data?.onlinePreference?.haveWebsite) {
                const pattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
                const websiteUrl = state?.data?.onlinePreference?.websiteUrl || '';
                if (!pattern.test(websiteUrl.trim())) {
                    setError('onlinePreference.websiteUrl', ['Please enter a valid Institute Website'], dispatch);
                    return true;
                }
            }
        }
        return false;
    };

    useEffect(() => {
        if (!state?.data?.onlinePreference?.haveWebsite) {
            setError('onlinePreference.websiteUrl', [], dispatch);
        }
    }, [state.data?.onlinePreference?.haveWebsite]);

    const isLogo = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        if (state?.routeInfo.pageName=='admin-institute-create') {
            if (state?.data?.institute?.instituteTypeCode && instTypes.includes(state.data.institute.instituteTypeCode)) {
                if (!state?.data?.institute?.logo || state.data.institute.logo.trim() === '') {
                setError('institute.logo', ['Please select Institute Logo'], dispatch);
                return true;
                }
            }
        }
        return false;
    };

    // const checkisSchoolInstitute = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    //     if (state?.routeInfo.pageName=='admin-institute-create') {
    //         if (state?.data?.institute?.instituteTypeCode && schoolTypes.includes(state.data.institute.instituteTypeCode)) {
    //             if (state?.data?.institute?.instituteTypeCode || state.data.institute.instituteTypeCode.trim() !== '') {
    //             setError('institute.instituteTypeCode', ['You do not have the necessary permissions to add a school from here...'], dispatch);
    //             return true;
    //             }
    //         }
    //     }
    //     return false;
    // };

    const checkisSchoolInstitute = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        console.log("initialInstituteTypeCode", initialInstituteTypeCode.current);
        console.log("afterInstituteTypeCode", state?.data?.institute?.instituteTypeCode);
    
        if (state?.routeInfo.pageName == 'admin-institute-create') {
            // Check for new institute creation
            if (id === 'new' && state?.data?.institute?.instituteTypeCode && schoolTypes.includes(state.data.institute.instituteTypeCode)) {
                if (state?.data?.institute?.instituteTypeCode.trim() !== '') {
                    setError('institute.instituteTypeCode', ['You do not have the necessary permissions to add a school from here...'], dispatch);
                    return true;
                }
            }
    
            // Check for updating an existing institute
            if (id !== 'new' && initialInstituteTypeCode.current && !schoolTypes.includes(initialInstituteTypeCode.current) 
                && state?.data?.institute?.instituteTypeCode && schoolTypes.includes(state.data.institute.instituteTypeCode)) {
                setError('institute.instituteTypeCode', ['You cannot change the institute type to a school from here...'], dispatch);
                return true;
            }
        }
        return false;
    };
    
    useEffect(() => {
        if (state?.data?.institute?.instituteTypeCode && !instTypes.includes(state.data.institute.instituteTypeCode)) {
            setError('institute.logo', [], dispatch);
        } 
        // if(state.data?.institute?.instituteTypeCode && schoolTypes.includes(state.data?.institute?.instituteTypeCode)){
        //     Swal.fire({
        //         title: 'Error!',
        //         text: "You do not have the necessary permissions to add a school from here...",
        //         icon: 'error',
        //         confirmButtonText: 'Close',
        //     });
        // }else{
        //     setError('institute.instituteTypeCode', [], dispatch);
        // }
    }, [state.data?.institute?.instituteTypeCode]);
    
    

    function handleSave(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void
        ) {
        console.log('state?.data');
        event.preventDefault();

        const ischeckedhaveWebsite = ishaveWebsite(state, dispatch);
        const islogoRequired = isLogo(state, dispatch);
        const isSchoolInstitute=checkisSchoolInstitute(state,dispatch)
        console.log("isSchoolInstitute",isSchoolInstitute)

        if (!isFormValid(state, dispatch) || ischeckedhaveWebsite || islogoRequired || isSchoolInstitute) {
            dispatch({ type: 'SHOW_ERRORS' });
            alert('Please correct the form errors!');
            return;
        }
        if(state?.routeInfo.pageName=='admin-institute-create'){
           updateMoEngageEvent(state?.data,'moengage-admin-basic-info'); 
           saveInstitute(state, 'online-preference'); 
        }else if(state?.routeInfo.pageName=='institute-online-preference'){
          updateMoEngageEvent(state?.data,'moengage-admin-online-preference');  
          saveOnlinePreference(state);
        }else if(state?.routeInfo.pageName=='admin-institute-onboarding'){
            updateMoEngageEvent(state?.data,'moengage-admin-onboarding'); 
            saveInstitute(state, 'basic-info'); 
         }else{
            console.log("form_submit")
        }
    
    };

    const saveInstitute = async (state: any, redirectUrl:any) => {
        let response = null;
        if (isEmpty(state?.data?.institute?.id)) {
         const response = await axiosClient().post(`${process.env.REACT_APP_ADMIN_API_SERVER}/institute/create`, state.data);
         const data = await response.data;
         if (data.success) {
            Swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                showConfirmButton: false, 
                timer: 1000, 
                timerProgressBar: true 
            }).then((result) => {
                dispatch({ type: 'RESET_VALIDATION_ERRORS' });
                navigate(`/institute/${data.instituteId}/${redirectUrl}`, { replace: false });
            });
         } else {
             Swal.fire({
                 title: 'Error!',
                 text: data.message,
                 icon: 'error',
                 confirmButtonText: 'Close',
             });
         }
        } else {
            console.log('put');
          const response = await axiosClient().put(`${process.env.REACT_APP_ADMIN_API_SERVER}/institute/create/${state?.data?.institute?.id}`, state.data);
          const data = await response.data;
          if (data.success) {
            Swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                showConfirmButton: false, 
                timer: 1000, 
                timerProgressBar: true 
            }).then((result) => {
                dispatch({ type: 'RESET_VALIDATION_ERRORS' });
                navigate(`/institute/${state?.data?.institute?.id}/${redirectUrl}`, { replace: false });
            });
          } else {
              Swal.fire({
                  title: 'Error!',
                  text: data.message,
                  icon: 'error',
                  confirmButtonText: 'Close',
              });
          }
        }
        return response;
    }

    const saveOnlinePreference = async (state: any) => {
        const response = await axiosClient().post(`${process.env.REACT_APP_ADMIN_API_SERVER}/institute/updateonlinepreference`, {data: state.data});
        const data = await response.data;
        if (data.success) {
            Swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                showConfirmButton: false, 
                timer: 1000, 
                timerProgressBar: true 
            }).then((result) => {
                dispatch({ type: 'RESET_VALIDATION_ERRORS' });
                navigate(`/institute/${state?.data?.institute?.id}/${state?.data?.institute.instituteGroupId}/contact-listing`, { replace: false });
            });
        } else {
            Swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Close',
            });
        }
    }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <InstituteLayout
                id={id}
                mode={pageName}
                handleAddInstitute={handleAddInstitute}
                handleOnlinePreference={handleOnlinePreference}
                handleContacts={handleContacts}
                handleInstituteOnboarding={handleInstituteOnboarding}>
                {!state.flags.isDataLoading && <PageBuilder />}
            </InstituteLayout>
        </SmartContext.Provider>
    );
};

export default Institute;
