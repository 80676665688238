import moment from 'moment';
import { useContext, useState, useEffect } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, handleControlValueChange } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';

const DateRangeSelectorControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };

    const startDateControl = control.controlGroup[0];
    const endDateControl = control.controlGroup[1];

    const startDateDataKey = `${parentDataKey}.${startDateControl.id}`;
    const endDateDataKey = `${parentDataKey}.${endDateControl.id}`;

    const startDate = getControlValueFromState(startDateDataKey, state as State) ?? '';
    const endDate = getControlValueFromState(endDateDataKey, state as State) ?? '';

    const [rangeOption, setRangeOption] = useState('custom');

    const formatDate = (date: Date | null) => {
        if (!date) return null;
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        if (startDate && endDate && startDate !== '1970-01-01' && endDate !== '1970-01-01') {
            setRangeOption(rangeOption);
        } else {
            setRangeOption(''); // or any other default value
        }
    }, [startDate, endDate]);

    const handleDateRangeSelect = (rangeOption: string) => {
        const today = new Date();
        let start: Date | null = new Date();
        let end: Date | null = new Date();

        setRangeOption(rangeOption);

        switch (rangeOption) {
            case 'today':
                start = new Date();
                end = new Date();
                break;
            case 'yesterday':
                start.setDate(today.getDate() - 1);
                end = new Date(start);
                break;
            case 'last_7_days':
                start.setDate(today.getDate() - 6);
                end = new Date(today);
                break;
            case 'last_1_month':
                start.setMonth(today.getMonth() - 1);
                end = new Date(today);
                break;
            case 'last_3_months':
                start.setMonth(today.getMonth() - 3);
                end = new Date(today);
                break;
            case 'this_month':
                start.setDate(1);
                end = new Date(today);
                break;
            case 'till_date':
                start = new Date('2023-11-01T00:00:00');
                end = new Date(today);
                break;
            case '':
                start = null;
                end = null;
                break;
            case 'custom':
                return;
            default:
                return;
        }

        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: startDateDataKey, value: formatDate(start), errorMessages: [] } });
        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: endDateDataKey, value: end ? formatDate(end) + ' 23:59:59.999' : null, errorMessages: [] },
        });
    };

    return (
        <div className="mb-3">
            {control.props?.label && (
                <label htmlFor={control.id} className="form-label">
                    {`${control.props.label} `}
                </label>
            )}
            <div className="input-group mb-3">
                <select
                    id="rangeOption"
                    className="form-select"
                    value={rangeOption}
                    onChange={(e) => handleDateRangeSelect(e.target.value)}>
                    <option value="">Select Range</option>
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="last_7_days">Last 7 Days</option>
                    <option value="last_1_month">Last 1 Month</option>
                    <option value="last_3_months">Last 3 Months</option>
                    <option value="this_month">This Month</option>
                    <option value="till_date">Till Date</option>
                    <option value="custom">Custom</option>
                </select>
                <input
                    id={startDateControl.id}
                    type="date"
                    className="form-control"
                    value={startDate}
                    onChange={(event) => {
                        setRangeOption('custom');
                        handleControlValueChange({
                            control: startDateControl,
                            value: event.target.value,
                            dataKey: startDateDataKey,
                            parentDataKey,
                            state,
                            dispatch,
                        });
                    }}
                />
                <input
                    id={endDateControl.id}
                    type="date"
                    className="form-control"
                    value={moment(endDate).format('YYYY-MM-DD')}
                    onChange={(event) => {
                        setRangeOption('custom');
                        handleControlValueChange({
                            control: endDateControl,
                            value: event.target.value,
                            dataKey: endDateDataKey,
                            parentDataKey,
                            state,
                            dispatch,
                        });
                    }}
                />
            </div>
        </div>
    );
};

export default DateRangeSelectorControl;
