import React, { useContext, useState, useEffect, useRef } from 'react';
import { SimpleFormControlArguments, State } from '../../../library/Core/SmartTypes';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { Link } from 'react-router-dom';
import axios from 'axios';
//import { saveAs } from 'file-saver';

interface TemplateData {
    subject: string;
    date: string;
    opens_count: number;
    clicks_count: number;
    to_mail: string; // Added field
    status: string; // Added field
    last_event_time: string;
}
// const SentMail = (args: SimpleFormControlArguments) => {
const SentMail = ({ pageTitle, ...args }: { pageTitle: any; }) => {
    document.title=pageTitle;

    let sesinstituteId = 0;
    const userDataString = sessionStorage.getItem('user');
    if (userDataString) {
        // Parse JSON string to object
        const userData = JSON.parse(userDataString);
        sesinstituteId = userData.primaryInstitute;
    }
    const exportToCSV = (templateData: TemplateData[], filename: string): void => {
        if (templateData.length === 0) {
            alert('Report not available');
            return;
        }

        // Define CSV header
        const csvHeader = 'Subject,To Mail,Status,Open,Click,Date \n';
        // Convert data to CSV rows
        const csvRows = templateData
            .map(
                (item) =>
                    `"${item.subject}","${item.to_mail}","${item.status}","${item.opens_count}","${item.clicks_count}","${item.last_event_time}"`
            )
            .join('\n');

        // Combine header and rows
        const csvString = csvHeader + csvRows;

        // Convert to Blob
        const csvBlob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

        // Create a hidden link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(csvBlob);
        link.setAttribute('download', `${filename}.csv`);
        document.body.appendChild(link); // Required for Firefox

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up by removing the link
        document.body.removeChild(link);
    };

    const fetchAndExportReport = async (subject: string, sent_date: any) => {
        try {
            // Example API URL - replace with your actual URL
            const apiUrl = `${process.env.REACT_APP_BROADCAST_SERVER}/email/get-deliver-report`;
            const requestBody = {
                subject: subject,
                sent_date: sent_date
            };

            const response = await axios.post(apiUrl, requestBody);

            // Check if the response data has an array property, e.g., 'templates'
            if (response.data && Array.isArray(response.data.templates)) {
                let reportData = response.data.templates.map((item: any) => ({
                    subject: item.subject,
                    to_mail: item.to_mail,
                    status: item.status,
                    opens_count: item.opens_count,
                    clicks_count: item.clicks_count,
                    last_event_time: item.last_event_time,
                }));

                exportToCSV(reportData, 'email_report');
            } else {
                console.error('Expected an array but got:', response.data);
                alert('Report not available');
            }
        } catch (error) {
            console.error('Error fetching report data:', error);
            alert('Error fetching report data');
        }
    };

    const schoolId = sesinstituteId;
    const limitMessage = (message: string, maxLength: number) => {
        if (message.length > maxLength) {
            return message.substring(0, maxLength) + '...'; // Display an ellipsis if the message is longer
        }
        return message;
    };
    const isWithin72Hours = (sentDateTime: string): boolean => {
        const sentDate = new Date(sentDateTime);
        const currentDate = new Date();
        const seventyTwoHoursAgo = new Date(currentDate.getTime() - 72 * 60 * 60 * 1000);

        return sentDate < seventyTwoHoursAgo;
    };
    const jwtToken = localStorage.getItem('user-jwt-token');
    const currentDate = new Date();
    const date = currentDate.toDateString();
    const urlParams = new URLSearchParams(window.location.search);
    const templateId = urlParams.get('template_id');
    const [templateData, setTemplateData] = useState<any[]>([]);
    const fetchData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/get-sent-mail`, {
                school_ref_id: sesinstituteId},
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`, // Using Bearer token authentication
                    },
                }
            );

            setTemplateData(response.data.templates);
            const responseData = response.data; // Assuming that the response is a JSON object
            console.log(responseData);
            // Extract the "message" and "subject" from the response

            const messageFromAPI = responseData.message;
            const subjectFromAPI = responseData.subject;

            // Use the extracted data as needed
            console.log('Message from API:', messageFromAPI);
            console.log('Subject from API:', subjectFromAPI);

            //setAttachedFilePath(attachedFilePathFromAPI);
            // Rest of your code
        } catch (error) {
            // Handle errors here
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData(); // Call the async function when the component mounts
    }, []); // Empty dependency array to run once on mount

    const selectedStudentsString = sessionStorage.getItem('student_data_for_email');

    if (selectedStudentsString) {
        // Parse it back to an array
        const selectedStudents: string[] = JSON.parse(selectedStudentsString);
        const parsedStudents: string[] = selectedStudents.map((uuid: string) => {
            // Replace 'parseUuid' with your parsing logic
            return uuid;
        });
        console.log('user', parsedStudents);
    }

    return (
        <div className="main flex-1">
            <div className="max-1140 mx-auto d-flex h-100">
                <div className="flex-1 d-flex flex-column mb-5 w-100">
                    <div className="pageContent flex-1">
                        <div className="mb-5 pb-4">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div
                                        className="white-block
                                        white-block-notopborderradius h-100
                                        p-0">
                                        <div
                                            className="d-flex
                                            justify-content-between
                                            align-items-center my-3 px-4">
                                            <h3
                                                data-aos="fade-left"
                                                data-aos-delay="600"
                                                className="font-35 font-600 mb-0
                                                block-title aos-init
                                                aos-animate">
                                                Broadcast
                                            </h3>
                                            <div
                                                className="dashboard innerpage
                                                smart-scroll">
                                                <Link
                                                    to={`/broadcast/createnew`}
                                                    className="btn btn-green w-auto
                                                height-40 d-inline-flex
                                                text-white font-14
                                                text-decoration-none
                                                job_internship_btn aos-init
                                                aos-animate">
                                                    <span> Create New</span>
                                                </Link>
                                            </div>
                                        </div>

                                        <div
                                            className="job_internship_tab
                                            polartabsec px-4 mx-0">
                                            <div className="xscroll p-0">
                                                <ul
                                                    className="nav nav-tabs"
                                                    id="JobInternshipTab"
                                                    role="tablist"
                                                    data-aos="fade-left"
                                                    data-aos-delay="900">
                                                    <li className="nav-item" role="presentation">
                                                        <a href="/broadcast" className="nav-link">
                                                            Drafts
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a href="/broadcast/schedule-mail" className="nav-link">
                                                            Scheduled
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a href="/broadcast/sent-mail" className="nav-link active">
                                                            Completed
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div
                                            className="row m-0 pt-4 pb-5 mt-2
                                            JobInternshipListing px-3">
                                            <div
                                                className="flex-1 polarcontsec
                                                tab-content"
                                                id="myTabContent"
                                                data-aos="fade-left"
                                                data-aos-delay="1200">
                                                <div
                                                    className="tab-pane fade show
                                                    active"
                                                    id="draftsbroadcast"
                                                    role="tabpanel"
                                                    aria-labelledby="draftsbroadcast-tab">
                                                    <div className="row EventsBlk">
                                                        <div
                                                            className="col-lg-12
                                                            col-md-12
                                                            broadcast_table">
                                                            <div
                                                                className="table-responsive
                                                                attendee_dtl">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Broadcast Title</th>
                                                                            <th>Created Date Time</th>
                                                                            <th>Sent Date Time</th>
                                                                            <th>DOWNLOAD </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {templateData.map((template, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                {template.template_ref_id ? (
    <Link to={`/broadcast/template-preview-later?template_id=${template.id}&temp_id=${template.template_ref_id}`}>
        {limitMessage(template.subject, 100)}
    </Link>
) : (
    <Link to={`/broadcast/preview-later?template_id=${template.id}`}>
        {limitMessage(template.subject, 100)}
    </Link>
)}

                                                                                </td>
                                                                                <td>{template.created_date}</td>
                                                                                <td>{template.sent_date_time}</td>
                                                                                <td>
                                                                                    {' '}
                                                                                    {isWithin72Hours(template.sent_date_time) && (
                                                                                        <input
                                                                                            className="form-control flex-1"
                                                                                            type="button"
                                                                                            id="report"
                                                                                            value="Report"
                                                                                            onClick={() =>
                                                                                                fetchAndExportReport(template.subject, template.sent_date_time)
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SentMail;
