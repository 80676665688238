import { isEmpty, isFormValid, isValidBusinessObject, setError } from '../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import StudentService from '../../../../services/student.service';
import axios from 'axios';

export const handleSubmit = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const formValidationPassed = isFormValid(state, dispatch, ['parents']);
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    if (!customValidationPassed || !formValidationPassed) {
        dispatch({ type: 'SHOW_ERRORS' });
        // alert('Please correct the form errors!');
        return;
    }

    // Send event to Moengage parent info
    sendMoEngageEvent(state?.data);

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            parents: state?.data['parents'],
        })
    ).then(() => {
        state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile/parent-info`);
    });
};

export const isFormValidBasedOnCustomValidation = (state: State, dispatch: any) => {
    let isFormValid = true;
    isFormValid = isValidBusinessObject(
        state?.data['parents'],
        [
            { id: 'parentTypeCode', label: 'RELATION' },
            { id: 'mobileCountryCode', label: 'COUNTRY CODE' },
            { id: 'mobileNumber', label: 'MOBILE' }
        ],
        { id: 'parentTypeCode', label: 'RELATION' },
        'parents',
        dispatch,
        'Relation'
    );

    state?.data['parents'].map((parent: any, index: number) => {
        if ( parent.mobileCountryCode == "" ) {
            isFormValid = false;
            setError(`parents.${index}`, ['Please select country code'], dispatch);
        }

        if ( parent.mobileNumber == "" ) {
            isFormValid = false;
            setError(`parents.${index}`, ['Please enter phone number'], dispatch);
        }
        
        if (parent.mobileCountryCode == 100 && (parent.mobileNumber.length != 10 || parent.mobileNumber.charAt(0) < 6)) {
            isFormValid = false;
            setError(`parents.${index}`, ['Please enter valid phone number'], dispatch);
        }

        if ( isEmpty(parent.parentTypeCode) ) {
            isFormValid = false;
            setError(`parents.${index}.parentTypeCode`, ['Please select the relation'], dispatch);
        }
    });

    return isFormValid;
};

const sendMoEngageEvent = async (user:any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-parent-info`, {
            userInfo       : user
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return false;
  };