import { isFormValid, isValidBusinessObject } from '../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import StudentService from '../../../../services/student.service';
export const handleSubmit = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const formValidationPassed = isFormValid(state, dispatch, ['books']);
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    if (!customValidationPassed || !formValidationPassed) {
        dispatch({ type: 'SHOW_ERRORS' });
        alert('Please correct the form errors!');
        return;
    }

    if (!isFormValid(state, dispatch)) {
        alert('Please correct the form errors!');
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            books: state?.data['books'],
        })
    ).then(() => {
        state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile/extra-curricular`);
    });
};

export const isFormValidBasedOnCustomValidation = (state: State, dispatch: any) => {
    let isFormValid = true;
    isFormValid = isValidBusinessObject(
        state?.data['books'],
        [
            { id: 'title', label: 'Title' },
            { id: 'author', label: 'Author' },
            { id: 'completionDate', label: 'Completion Date' },
        ],
        { id: 'title', label: 'Title' },
        'books',
        dispatch,
        'Book'
    );
    return isFormValid;
};
