import { useContext } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import useManagementDashboard from './useManagementDashboard';

const ReportLayoutComponent = (prop: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useManagementDashboard();

    const handleTabChange = (tab: string) => {
        setStateValue('selectedTab', tab);
    };

    const toggleAccordionState = () => {
        const newState = state.data.accordionState === 'EXPANDED' ? 'COLLAPSED' : 'EXPANDED';
        setStateValue('accordionState', newState);
    };

    return (
        <div className="main flex-1">
            <div className="fixed_srtipe" style={{ display: 'none' }}>
                <i className="reg-srtipe" style={{ height: '342.2px' }}></i>
                <i className="filter-srtipe" style={{ height: '469.8px' }}></i>
            </div>
            <div className="container max-1140 px-lg-0 overflow-hiddens">
                <div className="white-block white-block-notopborderradius p-0 h-100">
                    <div className="p-4 p-md-5">
                        <div className="d-flex justify-content-between align-items-start">
                            <div className="welcome-title">
                                <h1 className="font-26 font-600 mb-0 pb-4">My Dashboard</h1>
                            </div>
                        </div>
                        <ul className="nav nav-pills mb-3" id="dashboard-pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    onClick={() => handleTabChange('STUDENT')}
                                    className="nav-link"
                                    id="pills-student-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-student"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-student"
                                    aria-selected={state.data.selectedTab === 'STUDENT'}>
                                    Student
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    onClick={() => handleTabChange('ALUMNI')}
                                    className="nav-link active"
                                    id="pills-alumni-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-alumni"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-alumni"
                                    aria-selected={state.data.selectedTab === 'ALUMNI'}>
                                    Alumni
                                </button>
                            </li>
                            <li className="nav-item ms-auto" role="presentation">
                                <button className="nav-link" onClick={toggleAccordionState}>
                                    {state.data.accordionState === 'EXPANDED' ? 'Collapse All' : 'Expand All'}
                                </button>
                            </li>
                        </ul>

                        <div className="container mt-4">
                            <div className="row justify-content-center">
                                <div className="col-lg-12 px-lg-0">{prop.children}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportLayoutComponent;
