import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import SessionContext from '../../../library/Core/SessionContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
import { handleNext } from '../profile-edit/achievements/AchievementActions';
import ActivityControl from './ActivityControl';
import ActivityGroupControl from './ActivityGroupControl';
import OnboardingLayout from './OnboardingLayout';

const Onboarding = () => {
    const { id, pageName } = useParams();
    const { globalState } = useContext(GlobalContext);
    const { sessionState } = useContext(SessionContext);
    const navigate = useNavigate();
    const [showDetailForGroup, setShowDetailForGroup] = useState();
    const [dashboardData, setDashboardData] = useState([]);

    // TO-DO: Better to configure this data in the activity config JSON;
    const activityProgressMapper: { [index: string]: any } = {
        INTRODUCTIONS: {
            MY_CONTACT_DETAILS: {
                AND: ['is_email_verified', 'is_mobile_verified'],
            },
            MY_SELFIE: {
                AND: ['profile_picture_file_path'],
            },
            MY_INTERESTS: {
                AND: ['skill_cnt'],
            },
        },
        COMMUNICATIONS: {
            BE_A_MENTOR: {
                AND: ['junior_msg_cnt'],
            },
            CHECK_FRIENDS_BATCHMATES: {
                AND: ['batch_msg_cnt'],
            },
            CONNECT_WITH_SCHOOL: {
                AND: ['school_msg_cnt'],
            },
        },
        CONTRIBUTING: {
            PEER_REVIEW: {
                AND: ['peer_review_cnt'],
            },
            MEMORIES: {
                AND: ['album_cnt'],
            },
            OPPORTUNITY_FOR_OTHERS: {
                AND: ['job_post_cnt'],
            },
        },
        CAREER: {
            PREFERENCES: {
                // @170-start @dt 11th.July,2K23 @byRG;
                // "AND": ["interested_in_upskilling", "interested_in_job_referrals", "higher_study_pref"]
                AND: ['benefit_id'],
                // @170-end;
            },
            EVENTS: {
                AND: [],
            },
            UPSKILL: {
                AND: ['upskill_cnt'],
            },
        },
    };

    useEffect(() => {
        // const profileStatusCode = sessionStorage.getItem('user-profile-status-code');
        const isPeerReviewed = sessionState?.isPeerReviewed;

        // if(profileStatusCode !== 'step-two' && profileStatusCode !== 'COMPLETE'){
        if (!isPeerReviewed) navigate('/alumni/welcome');

        const fetchUserDashboardData = async () => {
            const response = (await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/get-dashboard-data`))?.data;
            setDashboardData(response.data[0]);
        };
        fetchUserDashboardData();
    }, []);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { NEXT: handleNext, ROUTER_NAVIGATE: navigate },
    } as any);

    const { formConfig, data } = useLoaderData() as State;

    const handleBackToSummary = () => setShowDetailForGroup(undefined);
    const handleGoToDashboard = () => {
        navigate('/alumni/dashboard');
    };

    useEffect(() => {
        const domain = globalState.domain;
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo: { id, pageName } },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <OnboardingLayout
                showDetailForGroup={showDetailForGroup}
                handleBackToSummary={handleBackToSummary}
                handleGoToDashboard={handleGoToDashboard}>
                {!showDetailForGroup && (
                    <ActivityGroupControl
                        activityProgressMapper={activityProgressMapper}
                        dashboardData={dashboardData}
                        onCompleteNowClick={setShowDetailForGroup}
                    />
                )}
                {showDetailForGroup && (
                    <ActivityControl
                        activityProgressMapper={activityProgressMapper}
                        dashboardData={dashboardData}
                        selectedGroup={showDetailForGroup}
                    />
                )}
            </OnboardingLayout>
        </SmartContext.Provider>
    );
};

export default Onboarding;
