import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';

const ForgotPassword = (props: any) => {
    const [email, setEmail] = useState('');
    const [isEmailValid, setisEmailValid] = useState(false);
    const [otp, setOtp] = useState('');
    const [showCodeForm, setShowCodeForm] = useState(false);
    const [showNewPasswordForm, setShowNewPasswordForm] = useState(false);
    const [codeInputs, setCodeInputs] = useState(['', '', '', '', '', '']);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatchError, setPasswordMatchError] = useState(false);
    const codeInputRefs = useRef<Array<HTMLInputElement | null>>([]);
    const [isPasswordChanged, setisPasswordChanged] = useState(false);
    const [passwordChangedMessage, setpasswordChangedMessage] = useState('');
    // const navigate = useNavigate();

    useEffect(() => {
        if (showCodeForm && codeInputRefs.current[0]) {
            codeInputRefs.current[0].focus();
        }
    }, [showCodeForm]);

    const sendEmailToUser = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const sendForgotPAssLink = `${process.env.REACT_APP_COMMON_API}/email/send-forgot-pass-email`;
        axios
            .post(sendForgotPAssLink, {
                email: email,
            })
            .then((res: any) => {
                console.log(res.data);
                console.log(res.data.otp);
                setOtp(res.data.otp);
                setShowCodeForm(true);
            });
    };

    const handleCodeInputChange = (index: number, value: string) => {
        const updatedCodeInputs = [...codeInputs];
        updatedCodeInputs[index] = value;
        setCodeInputs(updatedCodeInputs);

        if (value && index < codeInputs.length - 1) {
            codeInputRefs.current[index + 1]?.focus();
        }

        const enteredCode = updatedCodeInputs.join('');

        if (enteredCode == otp && enteredCode.length == 6) {
            setShowCodeForm(false);
            setShowNewPasswordForm(true);
        }
    };

    const handleSubmitNewPassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newPassword = event.currentTarget.newPassword.value;
        const confirmPassword = event.currentTarget.confirmPassword.value;
        // const school_settings = localStorage.getItem('school_settings');
        // const school_domain = JSON.parse(school_settings?school_settings:'')?.schoolDomain;
        // const domain = school_domain ? school_domain : 'miles'; 

        if (newPassword === confirmPassword) {
            const setNewPasswordUrl = `${process.env.REACT_APP_COMMON_API}/users/reset-user-password`;
            try {
                const response = await axios.post(setNewPasswordUrl, {
                    email: email,
                    newPassword: newPassword,
                    otp: otp,
                });
                console.log(response.data); // Handle the response from the API
                setisPasswordChanged(true);
                setpasswordChangedMessage(response?.data?.message);
                // console.log('/student/' + domain + '/login');
                // navigate('/student/' + domain + '/login');
                window.location.reload();
            } catch (error) {
                console.error(error); // Handle error cases
            }
        } else {
            setPasswordMatchError(true);
            setisPasswordChanged(false);
        }
    };

    const handleCheckEmailValid = async(value:string) => {
        setEmail(value);
        const validRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (validRegex.test(value) == false) {
            setisEmailValid(false);
            return;
        }

        setisEmailValid(true);
    }

    return (
        <>
            {showCodeForm ? (
                <div className="row justify-content-center  text-center align-items-center mb-4">
                    <div className="w-100 mb-3">
                    <h2 className="font-22 text-white text-center">Verify OTP</h2>
                    </div>
                    {codeInputs.map((input, index) => (
                        <input
                            key={index}
                            className="input-fieldss input-otp text-center border-0 font-500 font-14"
                            type="text"
                            id={`otp-${index}`}
                            name="otp[]"
                            value={input}
                            onChange={(e) => handleCodeInputChange(index, e.target.value)}
                            ref={(ref) => (codeInputRefs.current[index] = ref)}
                        />
                    ))}
                    <div className="invalid-feedback w-100 mt-3">Enter Valid OTP</div>
                </div>
            ) : showNewPasswordForm ? (
                <div>
                    <form onSubmit={handleSubmitNewPassword}>
                        <div className="form-group">
                            <div className="row g-4">
                                <div className="col-md-12">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="newPassword"
                                        name="newPassword"
                                        placeholder="New Password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </div>

                                <div className="col-md-12 text-center">
                                {(!isPasswordChanged && passwordMatchError) && <div className="error-message">New password and confirm password do not match</div>}
                                {isPasswordChanged && <div className="sucess-message">{passwordChangedMessage}</div>}
                                
                                    <button type="submit" className="btn btn-setPassWord mb-4">
                                        Set New Password
                                    </button>
                        
                                </div>
                            </div>
                        </div>
                        
                    </form>
                </div>
            ) : (
                
                <div className="form-group">
                    <div className="mb-3">
                    <h2 className="font-22 text-white text-center">Forgot Password</h2>
                </div>
                    <input
                        type="email"
                        className="form-control email"
                        id="Email"
                        name="Email"
                        placeholder="Email *"
                        aria-describedby="emailHelp"
                        onChange={(e) => handleCheckEmailValid(e.target.value)}
                    />
                    {!isEmailValid && <div className="error-message">Enter valid email address</div>}
                    <div className="form_submissions action_btns my-4">
                        <button onClick={(e) => isEmailValid ? sendEmailToUser(e) : ''} className="secondary_btn forgotSubmit btn-login py-2 border-0">
                            Submit
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default ForgotPassword;
