import { useContext } from 'react';
import { Link } from 'react-router-dom';
import SessionContext from '../../../library/Core/SessionContext';
import { SessionStateType } from '../../../library/Core/SmartTypes';
import AppSettingsService from '../../../services/app-settings.service';

function HeaderControl() {
    const { sessionState } = useContext(SessionContext);

    const { userStatusCode, studentId, userTypeCode, genderCode, instituteLogo: schoolImage } = sessionState as SessionStateType;
    let profileImage = sessionState?.profilePictureFilePath;

    if (profileImage == '' || profileImage == null || profileImage == 'null') {
        profileImage =
            genderCode == 'MALE'
                ? 'https://s3.ap-south-1.amazonaws.com/media.univariety.com/uploads/alumni/yellow-student-boy.svg'
                : 'https://s3.ap-south-1.amazonaws.com/media.univariety.com/uploads/alumni/yellow-student.svg';
    }

    const handleLogout = () => {
        localStorage.setItem('univariety-user', JSON.stringify({}));
        localStorage.setItem('user-logged-out', 'TRUE');
        localStorage.removeItem('user-type-code');
        localStorage.removeItem('selectedInstitute');
        localStorage.removeItem('user');
        localStorage.removeItem('user-jwt-token');
        sessionStorage.clear();
        // window.location.href = AppSettingsService.getLoginUrlFromStorage() as string;
        window.location.href = `${process.env.REACT_APP_APP_BASE_URL}/` + AppSettingsService.getLoginUrl(sessionState) as string;
    };

    const getProfilePath = (userTypeCode: string, studentId: string): string => {
        switch (userTypeCode) {
            case 'STUDENT':
                return `/student/${studentId}/profile`;
            case 'ALUMNI':
                return `/alumni/${studentId}/profile`;
            case 'SCHOOL_ADMIN':
                return `/management/${studentId}/profile`;
        }
        return '';
    };

    return (
        <>
            <div className="container max-1140">
                <div className="row justify-content-between align-items-center custom-header">
                    <div className="univarsity_logo manager_profile" data-aos="fade-left">
                        <div className="logo">
                            <button
                                className="mobileonly btn-offcanvas"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasMobile"
                                aria-controls="offcanvasMobile">
                                <span className="navbar-toggler-icon">
                                    <b>#</b>
                                </span>
                            </button>
                            {/* <img src={schoolLogo?.school_image} /> */}
                            {/* <img src={schoolImage} width="160" alt="" className="img-fluid" /> */}
                        </div>
                    </div>

                    <div className="col-5 headerRightSide aos-init aos-animate" data-aos="fade-right">
                        <ul>
                            {/* <li>
                            {userStatusCode === 'PREFERENCES_INCOMPLETE' && userTypeCode?.toLocaleLowerCase() == 'student' ? (
                                <Link to={`/student/${studentId}/student-preference`} className="mail icon-mail"></Link>
                            ) : (
                                <Link to={`/messages`} className="mail icon-mail"></Link>
                            )}
                        </li> */}
                            {userTypeCode?.toLocaleLowerCase() === 'alumni' && (
                                <li>
                                    <Link to={`/notification`} className="notification icon-notification"></Link>
                                </li>
                            )}

                            <li>
                                <div className="username d-flex align-items-center position-relative">
                                    <i className="icon-down-arrow-invert position-absolute"></i>
                                    <label className="">
                                        <span
                                            className={
                                                profileImage != 'null'
                                                    ? 'userimg position-relative'
                                                    : 'userimg position-relative icon-user-invert'
                                            }>
                                            <img src={profileImage ? profileImage : ''} />
                                        </span>
                                    </label>
                                    <div className="user-dropdown">
                                        <ul>
                                            {userTypeCode?.toLocaleLowerCase() !== 'super_admin' && (
                                                <li>
                                                    {userStatusCode === 'PREFERENCES_INCOMPLETE' &&
                                                    userTypeCode?.toLocaleLowerCase() == 'student' ? (
                                                        <Link to={`/student/${studentId}/student-preference`}>My Profile</Link>
                                                    ) : (
                                                        <Link to={getProfilePath(userTypeCode as string, studentId as string)}>
                                                            My Profile
                                                        </Link>
                                                    )}
                                                </li>
                                            )}
                                            
                                            <li className="d-flex d-md-none">
                                                <a href="/alumni-invite/peer">Invite</a>
                                            </li>
                                            <li>
                                                <a onClick={handleLogout}>Logout</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="mobile-select">
                <select className="form-select assessment-select fordesktop" name="" id="">
                    <option value="learning-style-test">Learning Style Test</option>
                    <option value="multiple-intelligence-test">Multiple Intelligence Test</option>
                    <option value="personality-assessment-test">Personality Assessment Test</option>
                    <option value="ideal-career-test">Ideal Career Test</option>
                    <option value="stream-selector-test">Stream Selector Test</option>
                    <option value="engineering-branch-assessment-test">engineering-branch Assessment Test</option>
                    <option value="commerce-branch-assessment-test">Commerce Branch Assessment Test</option>
                    <option value="humanities-branch-assessment-test">Humanities Branch Assessment Test</option>
                </select>
            </div>
        </>
    );
}

export default HeaderControl;
