import React, { useContext, useState } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, handleControlValueChange } from '../Core/SmartFunctions';
import { DomainElement, SimpleFormControlArguments, State } from '../Core/SmartTypes';
import logger from '../Core/logger';

const ListControl = (args: SimpleFormControlArguments) => {
    logger.log('Rendering List Control');
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey, handleChangeEvent } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const [filterText, setFilterText] = useState('');
    const localPageDomain = state.pageDomain
        ? state.pageDomain[control.id].map((item: any) => {
              const localVal = (state?.domain?.get(control.props.domainCategoryCode) as DomainElement[]).find(
                  (val) => val.code == item.code
              ) as any;
              return {
                  ...item,
                  value: localVal?.value ? `${localVal?.value} (${item.recordCount})` : '',
              };
          })
        : state?.domain?.get(control.props.domainCategoryCode);

    const [pageDomain, setPageDomain] = useState(localPageDomain);

    const [domainValues, setDomainValues] = useState(localPageDomain);

    const handleSearchClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFilterText = event.target.value;
        setFilterText(newFilterText);
        setDomainValues(
            pageDomain
                ?.get(control.props.domainCategoryCode)
                ?.filter((element: any) => element.value.toUpperCase().includes(newFilterText.toUpperCase()))
        );
    };

    const handleItemClick = (event: any, element: any) => {
        handleChangeEvent
            ? handleChangeEvent(control.id, element.code, dataKey, event.target.checked)
            : handleControlValueChange({ control, value: event.target.value, dataKey, parentDataKey, state, dispatch });
    };

    // const constructList = useMemo((): React.ReactNode => {
    //     return domainValues?.map((element: DomainElement) => (
    //         <li key={element.code} className="list-group-item overflow-x-hidden overflow-y-auto border-0 text-nowrap">
    //             <input
    //                 className="form-check-input me-2"
    //                 type="checkbox"
    //                 value={element.value || ''}
    //                 id={element.code}
    //                 checked={data?.indexOf(element.code) >= 0}
    //                 onChange={(event) => handleItemClick(event, element)}
    //             />
    //             <label className="form-check-label" htmlFor="firstCheckbox">
    //                 {element.value}
    //             </label>
    //         </li>
    //     ));
    // }, [data]) as ReactNode;

    return (
        <div className="border border-secondary-subtle bg-white w-100 p-3" style={{ display: 'block' }}>
            <label className="form-check-label fs-5 col-md-12 mb-3">{control.props.label}</label>
            <div className="input-group flex-nowrap w-100 mb-3">
                <input className="flex-fill bg-light border border-secondary-subtle p-1" value={filterText} onChange={handleSearchClick} />
                <span className="input-group-text" id="basic-addon1">
                    <i className="bi bi-search"></i>
                </span>
            </div>
            <ul className="list-group" style={{ maxHeight: '200px', marginBottom: '10px', overflowY: 'auto' }}>
                {(domainValues as DomainElement[])?.slice(0, 50).map((element: DomainElement) => (
                    <li key={element.code} className="list-group-item overflow-x-hidden overflow-y-auto border-0 text-nowrap">
                        <input
                            className="form-check-input me-2"
                            type="checkbox"
                            value={element.value || ''}
                            id={element.code}
                            checked={data?.indexOf(element.code) >= 0}
                            onChange={(event) => handleItemClick(event, element)}
                        />
                        <label className="form-check-label" htmlFor="firstCheckbox">
                            {element.value}
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default React.memo(ListControl);
