import moment from 'moment';
import { isFormValid, setError } from '../../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../../library/Core/SmartTypes';
import StudentService from '../../../../../services/student.service';

export const handleUpdate = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const formValidationPassed = isFormValid(state, dispatch, []);
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    if (!formValidationPassed || !customValidationPassed) {
        alert('Please correct the form errors!');
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state.formValidationErrors);
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            profile: state?.data?.profile,
            firstName: state.data?.firstName,
            lastName: state.data?.lastName,
            schoolId: state.data?.schoolId,
            adminAdditionalInfo:state?.data?.adminAdditionalInfo,
            pageName: state.routeInfo.pageName,
        })
    ).then(() => {
        state.actions['ROUTER_NAVIGATE'](`/management/${sessionState.userId}/profile`);
    });
};

export const handleCancel = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    state.actions['ROUTER_NAVIGATE'](`/management/${sessionState.userId}/profile`);
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    let isFormValid = true;

    if (state.data.profile?.dateOfBirth) {
        const fifteenYearsAgo = moment().subtract(15, 'years');

        if (moment(state.data.profile.dateOfBirth).isAfter(fifteenYearsAgo)) {
            isFormValid = false;
            setError('profile.dateOfBirth', [`Please enter valid "Date Of Birth"`], dispatch);
        }
    }

    if (state.data?.adminAdditionalInfo?.schoolJoinDate) {
        if (moment(state.data.adminAdditionalInfo?.schoolJoinDate).isAfter(moment())) {
            isFormValid = false;
            setError('adminAdditionalInfo.schoolJoinDate', [`Please enter valid date`], dispatch);
        }
    }

    return isFormValid;
};
