import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../library/Core/SmartTypes';
import WidgetConfigurationLayout from './WidgetConfigurationLayout';
import PageBuilder from '../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../library/Core/GlobalContext';
import { arrayColumn, getAssociativeArray, getDomainValueForCode, getMultiAssociativeArray, isEmpty, triggerGridDataOnChange } from '../../library/Core/SmartFunctions';
import { axiosClient } from '../../library/Service/axiosClient';
import { handleAddNew, handlePreview, handleReset, handleSave, handleViewList } from './WidgetConfigurationActions';
import ListPopup from './ListPopup';
import AddPopup from './AddPopup';
import SessionContext from '../../library/Core/SessionContext';

const WidgetConfiguration = () => {
    const navigate = useNavigate();
    const { globalState } = useContext(GlobalContext);
    const { sessionState } = useContext(SessionContext);
    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        internal: internal,
        data: data,
        actions: {
            RESET: handleReset,
            SAVE: handleSave,
            PREVIEW: handlePreview,
            REDIRECT: navigate,
            metricEntity: onCareerChange,
            metricEntity2: onMetricEntity2Change,
            statMetricEntity: onStatMetricEntityChange,
            statMetricType: onStatMetricEntityChange,
            value: onCareerChange,
            metricType: onCareerChange,
            programLevel: onProgramLevelChange,
            VIEWLIST: handleViewList,
            sourceType: onSourceTypeChange,
            ADDNEW: handleAddNew
        },
    } as any);
    let {id, code} = useParams();
    const isEditing = !!id;
    if ( typeof code == 'undefined') {
        code = '';
    }
    useEffect(() => {
        let popupData;
        switch ( code ) {
            case 'top_companies':
                if ( data?.widgetData?.sourceType != 'external_data' ) {
                    popupData = getMultiAssociativeArray(state?.internal?.top_company_list, 'industryCode')
                    data.popupData = popupData;
                }
                break;
            
            case 'top_colleges':
                if ( data?.widgetData?.sourceType != 'external_data' ) {
                    popupData = getMultiAssociativeArray(state?.internal?.top_college_list, 'countryCode')
                    data.popupData = popupData;
                }
                break;

            case 'directory_stats':
                if ( isEmpty (data.widgetData.largeHeading) ) {
                    let domainCode =  'SCHOOL_GROUP';
                    if ( sessionState?.primaryInstitute == sessionState?.instituteGroupId ) {
                        domainCode = 'INSTITUTE_DOMAIN';
                    }
    
                    const headingPrefix = getDomainValueForCode(sessionState?.instituteGroupId as any, domainCode, {domain:globalState.domain} as State);
                    data.widgetData.largeHeading = `${headingPrefix} Alumni Statistics`;
                }
                
                break;
        }

        const fetchData = async () => {
            try {
                if (isEditing) {
                    dispatch({
                        type: 'DATA_INIT',
                        payload: {
                            formConfig, //: {...formConfig, "buttons": "SAVE,PREVIEW"},
                            data: data,
                            domain: new Map([...globalState.domain]),
                            internal,
                            routeInfo,
                        },
                    });
                    dispatch({ type: 'RESET_VALIDATION_ERRORS' });
                } else {
                    dispatch({
                        type: 'DATA_INIT',
                        payload: {
                            formConfig,
                            // data: {
                            //     ...data,
                            //     // widgetData: {
                            //     //     heading: ""
                            //     // },
                            //     // metricData: []
                            // },
                            data,
                            domain: new Map([...globalState.domain]),
                            internal,
                            routeInfo,
                        },
                    });
                }    
            } catch (error) {
                console.error('Error fetching program data:', error);
            }
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        if (isEditing) {
            let btnList = "RESET,SAVE,PREVIEW";
            switch ( code ) {
                case 'top_companies':
                case 'top_colleges':
                    btnList = "SAVE,PREVIEW";
                    break;
            }
            dispatch({ type: 'FORM_CONFIG', payload: { ...formConfig, "buttons": btnList } });
        }
    }, [isEditing]);

    useEffect(() => {

        if ( isEditing ) {
            return;
        }

        const allowedWidgets = ['top_careers', 'top_study_country']

        if (!code || !state?.internal?.[code] || !allowedWidgets.includes(code)) {
            return;
        }

        let ctr = 0;
        const metricData = state?.data?.metricData;

        if (!Array.isArray(metricData)) {
            console.error('metricData is not an array', metricData);
            return;
        }

        const updatedMetricData = metricData.map(item => ({ ...item }));
        state.internal[code].forEach((rec: any, idx: any) => {
            if (rec?.metricEntityCode == 0 || ctr >= 4) return;
            if (updatedMetricData[ctr]) {
                updatedMetricData[ctr]['metricEntity'] = rec?.metricEntityCode;
                ctr++;
            }
        });

        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {...state.data, metricData: updatedMetricData}
            },
        });

        setTimeout(() => {
            triggerGridDataOnChange(updatedMetricData, "metricData", "metricEntity");
        }, 500);
    }, [state?.internal?.top_careers, state?.internal?.top_study_country])

    
    useEffect(() => {

        if ( isEditing ) {
            return;
        }

        const allowedWidgets = ['directory_stats']

        if (!code || !state?.internal?.[code] || !allowedWidgets.includes(code)) {
            return;
        }

        let ctr = 0;
        const metricData = state?.data?.metricData;

        if (!Array.isArray(metricData)) {
            console.error('metricData is not an array', metricData);
            return;
        }

        const updatedMetricData = metricData.map(item => ({ ...item }));
        updatedMetricData[0]['statMetricEntity'] = 'joined_alumni';

        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {...state.data, metricData: updatedMetricData}
            },
        });

        setTimeout(() => {
            triggerGridDataOnChange(updatedMetricData, "metricData", "metricEntity");
        }, 500);
    }, [state?.internal?.directory_stats])


    useEffect(() => {
        setTimeout(() => {
            switch ( code ) {
                case 'top_companies':
                case 'top_colleges':
                   triggerGridDataOnChange(state?.data?.metricData, "metricData", "metricEntity2");
                    break;
                
                default:
                    triggerGridDataOnChange(state?.data?.metricData, "metricData", "metricEntity");
            }          
        }, 500);
    }, [state?.data?.metricData, state?.data?.widgetData?.sourceType])

    useEffect(() => {
        if ( isEditing ) {
            return;
        }

        const allowedWidgets = ['top_companies', 'top_colleges']

        if (!code || !state?.internal?.[code] || !allowedWidgets.includes(code)) {
            return;
        }

        let ctr = 0;
        const metricData = state?.data?.metricData ?? [];

        if (!Array.isArray(metricData)) {
            console.error('metricData is not an array', metricData);
            return;
        }

        const updatedMetricData = metricData.map(item => ({ ...item }));
        state.internal[code].forEach((rec: any, idx: any) => {
            if (rec?.metricEntityCode == 0 || ctr >= 1) return;
            if (updatedMetricData[ctr]) {
                updatedMetricData[ctr]['metricEntity2'] = rec?.metricEntityCode;
                ctr++;
            } else {
                updatedMetricData[ctr] = {
                    "metricEntity2": rec?.metricEntityCode
                }
                ctr++;
            }
        });

        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {...state.data, metricData: updatedMetricData}
            },
        });

        setTimeout(() => {
            triggerGridDataOnChange(updatedMetricData, "metricData", "metricEntity2");
        }, 500);
    }, [state?.internal?.top_companies, state?.internal?.top_colleges])

    function onCareerChange(props: ControlOnChangeArguments) {
        if ( isEmpty(props.state.internal) ) {
            return false;
        }

        let metricEntity = props?.value;
        let value = (!isNaN(props?.value)  && props?.value > 0) ? props?.value : 0;
        let metricType= props?.value;
        
        if (props.control.id != 'metricType') {
            metricType = props?.row?.metricType;
        }

        if (props.control.id != 'value') {
            value = props?.row?.value;   
        }
        
        if (props.control.id != 'metricEntity') {
            metricEntity = props?.row?.metricEntity;   
        }

        if (!code || !props?.state?.internal[code]) {
            return;
        }

        const errorMessages = [] as any[];
        let countData = getAssociativeArray(props.state?.internal?.[props.state?.internal?.widget_code], 'metricEntityCode');
        const totalAlumniCount = props.state?.internal?.[code].reduce((sum: any, item: any) => sum + item.userCount, 0);

        let currentCareerUserCount = countData[metricEntity]?.userCount ?? 0;
        let currentPercentage: any = 0;
        let totalPercentage: any = 0;
        
        currentPercentage = Math.round((currentCareerUserCount/totalAlumniCount)*100)

        if (metricType == 'override') {
            currentPercentage = (!isNaN(value)  && value > 0) ? value : 0;
        }

        currentPercentage = parseFloat(currentPercentage);

        props.state.data?.metricData?.map((data: any) => {
            if ( ! isEmpty(data?.metricEntity) && ! isEmpty(data?.value) && data?.metricEntity != metricEntity ) {
                totalPercentage += parseFloat(data.value);
                // if ( data?.metricEntity > 0 ) totalCareerSelected++; // skipping the free text/other careers as separate section as calculating other career separately;
            }
        })

        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: `${props.parentDataKey}.value`,
                value: currentPercentage,
            },
        });

        totalPercentage += currentPercentage;
        let remainingValue: any = 100-parseFloat(totalPercentage); // calculating other career percentage;
        let remainingValueDisp = remainingValue%1 == 0 ? remainingValue.toFixed(0) : remainingValue.toFixed(2); // calculating other career percentage;

        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: `widgetData.other`,
                value:  remainingValueDisp
            },
        });
    };

    async function onSourceTypeChange(props: ControlOnChangeArguments) {
        if ( isEmpty(props.state.internal) ) {
            return false;
        }        
        const sourceType = props.value;
        let popupData;
        
        if ( sourceType == 'external_data' ) {
            dispatch({
                type: 'REFRESH_DATA',
                payload: {
                    data: {...props.state.data, popupData: {}, widgetData: {...props.state.data.widgetData, otherList: []}}
                },
            });

        } else {
            if ( isEmpty(props.state.data.widgetData?.largeHeading) ) {
                return;
            }

            if ( code == 'top_companies' ) {
                popupData = getMultiAssociativeArray(props.state?.internal?.top_company_list, 'industryCode')
            }
            if ( code == 'top_colleges' ) {
                popupData = getMultiAssociativeArray(props.state?.internal?.top_college_list, 'countryCode')
            }

            dispatch({
                type: 'REFRESH_DATA',
                payload: {
                    data: {...props.state.data, popupData: popupData, widgetData: {...props.state.data.widgetData, otherList: []}}
                },
            });
        }
    };

    async function onMetricEntity2Change(props: ControlOnChangeArguments) {
        if ( isEmpty(props.state.internal) ) {
            return false;
        }
        
        if ( !code || !props?.state?.internal[code] ) {
            return;
        }
        
        const metricEntity  = props?.value;
        const sourceType    = props?.state?.data?.widgetData?.sourceType ?? "";
        let currentDisplayCount    = 0;
        let currenrDisplayValue    = '';
        let otherCount      = 0;
        let otherDisplay    = '';
        let entityListCode  = '';        
        let displayEntity;
        let entityKey: any;
        let metricCode: any;

        currentDisplayCount =  props?.state?.data?.popupData?.[metricEntity]?.length ?? 0;

        switch ( code ) {
            case 'top_companies':
                displayEntity = "Companies";
                entityListCode = 'top_company_list';
                entityKey = 'companyId';
                metricCode = 'industryCode';
                
                break;
        
            case 'top_colleges':
                displayEntity = "Colleges";
                entityListCode = 'top_college_list';
                entityKey = 'instituteId';
                metricCode = 'countryCode';

                break;
            
            default:
                displayEntity = "";
        }

        currenrDisplayValue    = `${currentDisplayCount} ${displayEntity}`

        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: `${props.parentDataKey}.metricCount`,
                value: currenrDisplayValue,
            },
        });

        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: `${props.parentDataKey}.cnt`,
                value: currentDisplayCount,
            },
        });

        let otherList: any = [];
        if ( sourceType != 'external_data' ) {
            let selectedItems = arrayColumn(props.state.data.metricData,'metricEntity2');        
            let entityIds: any = [];
            // Iterate over the keys of popupData
            for (const key in props.state.data.popupData) {
                if (Array.isArray(props.state.data.popupData[key])) {
                    const filteredArray = props.state.data.popupData[key].filter((rec: any) => {
                        let filterFlag = !selectedItems.includes(String(rec?.[metricCode])) && !entityIds.includes(rec?.[entityKey]);
                        entityIds.push(rec?.[entityKey]);
                        return filterFlag;
                    });

                    otherList = otherList.concat(filteredArray);
                }
            }
        } else {
            otherList = props.state.data.widgetData.otherList;
        }

        // otherCount = totalCompanies-totalSelected;
        otherCount = otherList.length ?? 0;
        otherDisplay = `${otherCount} ${displayEntity}`
        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: `widgetData.otherDisp`,
                value:  otherDisplay
            },
        });

        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: `widgetData.other`,
                value:  otherCount
            },
        });


        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: `widgetData.otherList`,
                value:  otherList
            },
        });

    };
    
    async function onStatMetricEntityChange(props: ControlOnChangeArguments) {
        if ( isEmpty(props.state.internal) ) {
            return false;
        }
        
        if ( !code || !props?.state?.internal[code] ) {
            return;
        }

        const METRIC_ENTITY_MAP: any = {
            joined_alumni: 'totalAccessibleAlumni',
            different_career_chosen: 'differentCareer',
            companies_represented: 'companiesRepresented',
            countries_represented: 'countriesRepresented',
            alumni_at_top_colleges: 'topColleges',
            alumni_at_top_companies: 'topCompanies',
            scholarship_received: 'scholarshipReceived'
        }
        
        let metricEntity = props?.value;
        let metricType= props?.value;
        let value = "";

        if (props.control.id != 'statMetricType') {
            metricType = props?.row?.statMetricType;
        }
        
        if (props.control.id != 'statMetricEntity') {
            metricEntity = props?.row?.statMetricEntity;   
        }

        if (metricType == 1) {
            value = props.state.internal.directory_stats?.[0]?.[METRIC_ENTITY_MAP?.[metricEntity]];
        }

        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: `${props.parentDataKey}.displayValue`,
                value: value,
            },
        });

    };

    async function onProgramLevelChange(props: ControlOnChangeArguments) {
        let programLevel = props?.value;
        let api = 'get-top-career-counts';
        if ( code == 'top_study_country') {
            api = 'get-top-study-country-counts';
        } else if ( code == 'top_colleges' ) {
            api = 'get-top-college-counts';
        }

        const response = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/${api}/${programLevel}`);
        const countsData = response.data;

        dispatch({
            type: 'SET_INTERNAL_STATE',
            payload: { key: code, value:countsData },
        });
    }
    
    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <WidgetConfigurationLayout state={state} >{!state.flags.isDataLoading && <PageBuilder />}</WidgetConfigurationLayout>
            {code == 'top_companies' && <ListPopup id="top_companies_list" />}
            {code == 'top_companies' && <AddPopup id="add_company" />}
            {code == 'top_colleges' && <ListPopup id="top_colleges_list" />}
            {code == 'top_colleges' && <AddPopup id="add_college" />}
        </SmartContext.Provider>
    );
};

export default WidgetConfiguration;
