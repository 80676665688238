import { useState, useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { State } from '../../library/Core/SmartTypes';
import logger from '../../library/Core/logger';
import PeerReviewService from './PeerReviewService';
import { SmartContext } from '../../library/Core/SmartContext';
import { getDomainValueForCode, getWhiteLabelUrl, isEmpty } from '../../library/Core/SmartFunctions';
import SessionContext from '../../library/Core/SessionContext';
import { Tooltip } from 'bootstrap';
import { GlobalContext } from '../../library/Core/GlobalContext';


const PeerReview = () => {
    const navigate = useNavigate();
    const { dispatch } = useContext(SmartContext);
    const { globalState } = useContext(GlobalContext);

    const userTypeCode = sessionStorage.getItem('user-type-code');
    let { data } = useLoaderData() as any;
    const [approvedRows, setApprovedRows] = useState<string[]>([]);
    const [showMessage, setShowMessage] = useState(false);
    const { sessionState } = useContext(SessionContext);

    useEffect(() => {
        data.peerProfile = data.peerProfile.map((student: any) => {
            student.schoolName = getDomainValueForCode(student.schoolId, 'INSTITUTE_DOMAIN', { domain: globalState.domain } as State);
            console.log(student.schoolName,'schoolName');
            student.profilePictureFilePath = student.profilePictureFilePath ?? 
            (student.genderCode === 'MALE'
                ? `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student-boy.svg`
                : `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student.svg`);

            return student;
        });
    }, []);
    
    const handleViewProfile = (id: string) => {
        navigate(`/alumni/${id}/profile`);
    };
    const approveMyPeer = (id: string,schoolId:any) => {
        PeerReviewService.updateMyPeer(id,schoolId);
        setApprovedRows([...approvedRows, id]);
   
        data.peerProfile = data.peerProfile.filter((person: any) => {
            return person.uUID !== id;
        });
        
        setShowMessage(true);

        setTimeout(() => {
            setShowMessage(false);
        }, 5000); // Hide the message after 5 seconds
    };

    const discardPeer = (id: string,schoolId:any) => {
        PeerReviewService.discardMyPeer(id,schoolId);
        setApprovedRows([...approvedRows, id]);
    };

    const base_path=getWhiteLabelUrl(sessionState)

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl, {
                customClass: 'tooltip-html',
                trigger: 'click',
                html: true,
            });
        });
    }, []);

    const createPersonHTML = (person: any) => {
        let educationstring = '';
        let education_type = 'Pursuing';
        if(person.presentEducationStatusCode == 'COMPLETED_STUDIES')
        {
            education_type = 'Completed';
        }
        if(!isEmpty(person.programLevel) && (person.programLevel == 'k12' || person.programLevel <= 12))
        {
            educationstring = person.schoolName ? `${education_type} ${person.programLevel} from ${person.schoolName}` : `${education_type} ${person.programLevel}`;
        }
        else if(person.programLevel == 'UG')
        {
            educationstring = person.ugName ? `${education_type} ${person.programLevel} from ${person.ugName}` : `${education_type} ${person.programLevel}`;
        }
        else if(person.programLevel == 'PG' || person.programLevel == 'Doctoral' || person.programLevel == 'Ph.d')
        {
            educationstring = person.pgName ? `${education_type} ${person.pgType} from ${person.pgName}` : `${education_type} ${person.pgType}`;
        }
        else
        {
            educationstring = 'NA';
        }
        let workstring = 'NA';
        if(person.workingStatusCode == 'PROFESSIONAL_PRACTICE' || person.workingStatusCode == 'WORKING_FOR_A_COMPANY' || person.workingStatusCode == 'ENTREPRENEURSHIP')
        {
            if(person.companyName && person.designation){
                workstring = `Working in ${person.companyName} as ${person.designation}`; 
            }
            else if(person.companyName){
                workstring = `Working in ${person.companyName}`;
            }
            else if(person.designation){
                workstring = `Working as ${person.designation}`;
            }
        }
        
        const facebookLink = (person?.facebookLink?.length <= 20 || person?.facebookLink == null) ? person?.facebookLink : `<a href="${person?.facebookLink}" target="_blank">View Link</a>` ;
        const instagramLink = (person?.instagramLink?.length <= 20 || person?.instagramLink == null) ? person?.instagramLink : `<a href="${person?.instagramLink}" target="_blank">View Link</a>` ;
        const linkedInLink = (person?.linkedInLink?.length <= 20 || person?.linkedInLink == null) ? person?.linkedInLink : `<a href="${person?.linkedInLink}" target="_blank">View Link</a>` ;
        const twitterLink = (person?.twitterLink?.length <= 20 || person?.twitterLink == null) ? person?.twitterLink : `<a href="${person?.twitterLink}" target="_blank">View Link</a>` ;

        return (
            `<div>
                <p><strong>Enrollment Number:</strong> ${person?.enrollmentNumber ?? 'NA'}</p>
                <p><strong>Education Status:</strong> ${educationstring}</p>
                <p><strong>Working Status:</strong> ${workstring}</p>
                <p><strong>Facebook:</strong> ${facebookLink?.length > 0 ? facebookLink : 'NA'}</p>
                <p><strong>Instagram:</strong> ${instagramLink?.length > 0 ? instagramLink : 'NA'}</p>
                <p><strong>LinkedIn:</strong> ${linkedInLink?.length > 0 ? linkedInLink : 'NA'}</p>
                <p><strong>X (Twitter):</strong> ${twitterLink?.length > 0 ? twitterLink : 'NA'}</p>
            </div>`
        );
    };

    return (
        <div className="container max-1140 px-lg-0">
            <div className="row">
                <div className="col-md-12">
                    <div className="white-block white-block-notopborderradius h-100 p-0">
                        <div className="d-flex flex-wrap justify-content-between align-items-center mb-1 px-4 py-3 py-sm-4">
                            <h3
                                className="font-35 font-600 mb-0 mt-2 mt-sm-3 block-title aos-init aos-animate"
                                data-aos="fade-left"
                                data-aos-delay="300">
                                Peer Account Review
                            </h3>
                        </div>

                        <div className="row job_internship_tab polartabsec mx-0">
                            <div className="col-12">
                                <p className="font-18 font-500 mb-0 text-black">
                                    <span id="pcount">{data?.peerProfile?.length} </span>newly joined users are waiting for their accounts
                                    to be fully activated on this network.
                                    <br />
                                    Help us speed up the process, by identifying the people you know.
                                </p>
                            </div>
                        </div>

                        <div className="row mx-0 mb-0 px-4 pb-5 mt-40 JobInternshipListing">
                            <div className="col-12">
                                {showMessage && (
                                    <div className="alert alert-success alert-dismissible fade show mb-0" role="alert">
                                        Profile approved successfully!
                                        {/* <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> */}
                                    </div>
                                )}{' '}
                                <div className="max-850 mx-auto">
                                    {data?.peerProfile?.map(
                                        (person: any, idx: number, peerProfile: any) =>
                                            !approvedRows.includes(person.uUID) && (
                                                <div key={person.uUID} className="row uni_profiles_100" id="datascene">
                                                    <div key={person.uUID} className="USERS">
                                                        <div className="col-12">
                                                            <div className="uni_profiles_sec d-flex profiles_sec_brk justify-content-between align-items-start align-items-md-center flex-column flex-md-row">
                                                                
                                                                    <div data-bs-toggle="tooltip" data-bs-html="true" data-bs-original-title={createPersonHTML(person)} className='cursor-pointer'>
                                                                    <img src={person.profilePictureFilePath} width={60} alt={person.name}></img>
                                                                    </div>
                                                                    <div>
                                                                        <p className="font-16 font-500 mb-0 text-black">
                                                                        <a
                                                                    //href={`${process.env.REACT_APP_APP_BASE_URL}/alumni/${person.uUID}/profile`}
                                                                    href={`${base_path}alumni/${person.uUID}/profile`}
                                                                    className="d-inline-flex justify-content-start align-items-center text-decoration-none text-black">{person.name},{' '}</a>
                                                                            <span className="font-14 fst-italic">
                                                                            {person.classCode}
                                                                            </span>
                                                                        </p>
                                                                        <p className="font-14 fst-italic font-500 text-gray-64 mb-0">
                                                                        {person.schoolName ?? person.schoolName}{person.schoolName && person.passOutYear ? ', '+person.passOutYear :  person.passOutYear ?? person.passOutYear}
                                                                        </p>
                                                                    </div>
                                                                <div className="paddings-67 d-flex uniprof_btntab_align uniprof_btnmbl_align">
                                                                    <button
                                                                        onClick={(event) => {
                                                                            // approveMyPeer(person.uUID, peerProfile);
                                                                            approveMyPeer(person.uUID,person.schoolId);
                                                                        }}
                                                                        className="btn-green height-40 w-max-content text-white me-2">
                                                                        <span>Yes, I know this person!</span>
                                                                    </button>
                                                                    {userTypeCode?.toLowerCase() === 'ADMIN' && (
                                                                        <button
                                                                            onClick={(event) => {
                                                                                discardPeer(person.uUID,person.schoolId);
                                                                            }}
                                                                            className="btn-darkblue height-40 w-max-content text-white">
                                                                            <span>Ask More Info</span>
                                                                        </button>
                                                                    )}

                                                                    <button
                                                                        onClick={(event) => {
                                                                            discardPeer(person.uUID,person.schoolId);
                                                                        }}
                                                                        className="btn-darkblue height-40 w-max-content text-white">
                                                                        <span>Not Sure</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 mt-20 mb-20">
                                                            <div className="border-gray-e2"></div>
                                                        </div>
                                                    </div>

                                                    <div id="loadmore"></div>
                                                </div>
                                            )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PeerReview;
