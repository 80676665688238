import React, { useContext, useState, useEffect } from 'react';
import  HeaderControl  from '../../alumni/_common/HeaderControl';
import MenuBuilder from '../../../routes/MenuBuilder';
import { axiosClient } from '../../../library/Service/axiosClient';
import PageFooterControl from '../../../library/SimpleControls/PageFooterControl';
import { SmartContext } from '../../../library/Core/SmartContext';

const Idealcareertest = () => {
    const [buttonCondition, setbuttonCondition] = useState('1');
    const [viewreportlink, setviewReportLink] = useState();
    const [continueassesment, setContinueAssesment] = useState();
    const [StudentId, setStudentId] = useState(null);
    const [studentemail, setStudentEmail] = useState(null);
    const [studentfirst, setStudentFirst] = useState(null);
    const [studentlast, setStudentLast] = useState(null);

    useEffect(() => {
        const userDataString = sessionStorage.getItem('user');
        if (userDataString) {
            const userData = JSON.parse(userDataString);
            console.log(userData.registeredEmailId, 'registeredEmailId');
            console.log(userData.studentId, 'studentId');
            setStudentId(userData.studentId);
            console.log(StudentId, 'setstudentId as studentId');
            setStudentEmail(userData.registeredEmailId);
            setStudentFirst(userData.firstName);
            setStudentLast(userData.lastName);
        }
    }, []); // Only run once on mount

    useEffect(() => {
        
        const fetchData = async () => {
            console.log('Fetching data')
            // if (!StudentId) return;
            // console.log('Fetching data for studentId:', StudentId);
            const userDataString = sessionStorage.getItem('user');
            if (userDataString) {
                const userData = JSON.parse(userDataString);
                try {
                    console.log(userData.studentId, 'studentId value')
                    const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/test/get-test-details-bystudentid-examid`, {
                        'studentId': userData.studentId,
                        'examId': 114
                    });
                    const data = await response.data;
                    if (data.success) {
                        const res = data.data;
                        if (res.length > 0) {
                            const case1 = (res[0].test_status === 'Completed' && res[0].end_date !== undefined) ? '3' : '2';
                            const report_link=(res[0].test_status === 'Completed' && res[0].end_date !== undefined) ? res[0].report_html_path : '';
                            const test_link_url=(res[0].test_status === null && res[0].test_link_url !== null) ? res[0].test_link_url : '';
                            setbuttonCondition(case1);
                            setviewReportLink(report_link);
                            setContinueAssesment(test_link_url);
                        }
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    alert(error);
                }
            }
        };
            fetchData();
            // Set up an interval to fetch data every 5 minutes (adjust as needed)
            const intervalId = setInterval(() => {
                fetchData();
            }, 1 * 60 * 1000); // 1 minutes
            // Clean up the interval when the component is unmounted
            return () => clearInterval(intervalId);
    }, []);

    const takeTest = async () => {
            try {
               // const authToken = 'e4a8f994646cd630e14d27bed8762957';
               const user_name=studentfirst+" "+studentlast
                const apiUrl = `${process.env.REACT_APP_COMMON_API}/test/usertestinsert`;
                const requestData = {
                    "admin" : {
                        // this will be static if There is only one who wants the webhook
                        "admin_id" : 5,
                        "organization_id" : 3,
                        "superadmin_id": 2,
                        "associate_id": 2
                    },                    
                    "uniquefactor" : {
                        "validation" : true, 
                        "field": "emailid", 
                        "value": studentemail
                    },
                    "exam" : {
                        "exam_id" : 114, // for Ideal Career & 115 for Stream Selector
                        "set_id" : 91 // for Ideal Career & 92 for Stream Selector
                    },
                    "oem": {
                        "header" : {
                            "logo" : "", // full https image path  & Univariety Image as Default
                            "name" : "", // Brand Name if any   & Nothing as Default 
                            "color" : { // basic Univariety Theme as Default
                                "primary" : "",
                                "background" : "",
                                "cta" : "",
                                "cta_text_color" : "",
                                "cta_text" : ""
                            }
                        },
                        "footer" : {
                            "copyright_text" : "", // "International Educational Gateway Pvt. Ltd. © 2023. All Rights Reserved. Univariety" as Default
                            "test_name" : "" // "Discover Ideal Career Test"
                            
                        },
                        "links": {
                            "backtodashboard" : "" // Platform Link which you want user to go.
                        }
                    },
                    "user": {
                        // basic user details
                        "username" : user_name, // User name which will reflect in Report
                        "emailid" : studentemail, // User email Addres which will reflect in report
                        "contact_no" : "" // User contact which will reflect in report
                    },
                    // not in use untill you want us to store your logs and unique user identity
                    "client_id" : "INT -> <UINVARIETY USER UNIQUE IDENTITY>",
                    "client_log" : "{} -> <UINVARIETY USER UNIQUE LOG FOR REFERENCE in OBJECT>"
                };
                // const headers = {
                //     Authorization: `Bearer ${authToken}`,
                // };

                const response = await axiosClient().post(apiUrl, requestData);
                if (response.data.success) {
                    setbuttonCondition('2');
                    setContinueAssesment(response.data.testurl);
                    window.open(response.data.testurl, '_blank');
                } else {
                    console.error('API request failed:', response.data.message);
                }
            } catch (error) {
                console.error('Error calling API:', error);
            }
    }


    return (       
        <div className="d-flex flex-column min-vh-100">
                <header>
                    <HeaderControl />
                    <MenuBuilder />
                </header>
           
            {/* https://stackoverflow.com/questions/56660153/how-to-listen-to-localstorage-value-changes-in-react */}
            <div className='main flex-1 over-hidden'>
                <div className='container max-1140 px-lg-0'>
                    <div className='row'>
                        <div className='col-md-12 mb-4'>
                        <div className="white-block white-block-notopborderradius h-100 p-0">
                  
                  <div className="page_ttl_row border-bottom border-2 custom-border-gray p-4 px-md-5">
                   <div className="row g-3 align-items-center ">
                      <div className="col">
                        <h3 className="font-35 font-600 mb-0 block-title aos-init aos-animate" >Ideal Career Test</h3>
                      </div>
                      <div className="col-auto">
                       {buttonCondition === '1' && (
                            <div className="dashboard innerpage smart-scroll scrolled-down">
                                <a href="javascript:;"  className="btn-takeTest" data-aos="fade-right" data-aos-delay="300" onClick={takeTest}><span>Take a Test</span></a>
                            </div>
                        )}

                        {buttonCondition === '2' && (
                            <div className="dashboard innerpage smart-scroll scrolled-down">
                                <a href={continueassesment} target='_blank' className="btn-darkblue" data-aos="fade-right" data-aos-delay="300"><span>Continue Assessment</span></a>
                            </div>
                        )}

                        {buttonCondition === '3' && (
                            <div className="dashboard innerpage smart-scroll scrolled-down">
                                <a href={viewreportlink} target="_blank" className="btn-green" data-aos="fade-right" data-aos-delay="300"><span>View Report</span></a>
                            </div>
                        )}
                      </div>
                   </div>
                </div>
            
                  <div className="p-4 p-md-5">
                  <div className="row">
                    <div className="col-md-12">
                        <div className="pb-3">
                            <div className="country-deatils border-bottom mb-5 pb-4">
                                {/* <img src="/static/media/multiple-intelligence.780d2319221f013613c7999814daff10.svg" className="img-fluid float-lg-end ms-lg-5 mx-auto mb-3" width="396"/> */}
                                <img width="400" className="img-fluid float-lg-end ms-lg-5 mx-auto mb-3" src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/new_website/group-46380.jpg"/>
                                <h1 className="font-28 font-900 text-dark-blue  mb-3">About Assessment</h1>
                                <h3 className="font-22 font-500 mb-4">Ideal Career Test helps students to identify their ideal careers based on their strengths and personality.</h3>
                                <p className="font-18 font-500">Ideal Career Test is perfect for those who want to find their true calling and discover the right career options. The assessment output of the Ideal Career Test is in the form of a 14-page detailed report assessing and identifying a student’s natural inclination for further studies, areas of improvement, skills, values and personal characteristics. The report is divided into 4 sections. The motivation section identifies the top 3 factors that help to perform better. The aptitude section evaluates the verbal, numerical, spatial, critical dissection and acuteness aptitude. The interest section suggests the careers that will be of interest to keep the students motivated. The personality section evaluates the personality type.</p>
                            </div>
                            <div className="tuition-fees " id="Universities">
                                <h2 className="font-18 font-600 position-relative title pb-2 mt-4 mb-4">Assessment Output</h2>
                                <ul className='green-tick'>
                                    <li>Gives a detailed report of career motivators. Eg: Interest, Respect, Stability</li>
                                    <li>Identifies Strengths and Potential Weaknesses</li>
                                    <li>Gives a direction based on Motivation, Aptitude, Interest, and Personality</li>
                                    <li>Suggests Top 3 Ideal Careers</li>
                                </ul>
                            </div>
                            
                            
                            

                        </div>

                        {buttonCondition === '1' && (
                            <div className="dashboard innerpage smart-scroll scrolled-down">
                                <a href="javascript:;" className="btn-takeTest" data-aos="fade-right" data-aos-delay="300" onClick={takeTest}><span>Take a Test</span></a>
                            </div>
                        )}

                        {buttonCondition === '2' && (
                            <div className="dashboard innerpage smart-scroll scrolled-down">
                                <a href={continueassesment} target='_blank' className="btn-darkblue" data-aos="fade-right" data-aos-delay="300"><span>Continue Assessment</span></a>
                            </div>
                        )}

                        {buttonCondition === '3' && (
                            <div className="dashboard innerpage smart-scroll scrolled-down">
                                <a href={viewreportlink} target="_blank" className="btn-green" data-aos="fade-right" data-aos-delay="300"><span>View Report</span></a>
                            </div>
                        )}

                    </div>
                   
                </div>                       
                </div>
                  </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageFooterControl/>
        </div>                         
    );
};

export default Idealcareertest;
