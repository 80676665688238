import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import { handleCompleteLater, handleNext } from './AlumniSkillAction';
import AlumniSkillLayout from './AlumniSkillLayout';

const AlumniSkill = () => {
    const { globalState } = useContext(GlobalContext);
    const { id } = useParams();
    const navigate = useNavigate();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { NEXT: handleNext, I_LL_DO_THIS_LATER: handleCompleteLater, ROUTER_NAVIGATE: navigate },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        data.skills = data.skills ?? {};
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, [id]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <AlumniSkillLayout>{!state.flags.isDataLoading && <PageBuilder />}</AlumniSkillLayout>
        </SmartContext.Provider>
    );
};
export default AlumniSkill;
