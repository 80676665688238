import { useContext } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const CambridgeGrade = (args: SimpleFormControlArguments) => {
    console.log(args, " args ");
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const { AStar, A, B, C, D, E, F, G } = getControlValueFromState(dataKey, state as State) ?? {};

    const handleChange = (event: any, grade: string) => {
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: `${dataKey}.${grade}`, value: event.target.value } });
    };

    return (
        <>
            {control.props?.label && (
                <label htmlFor={control.id} className="form-label w-100">
                    {`${control.props.label} `}
                </label>
            )}
            <div className="row g-2">
                <div className="col-2 position-relative">
                    <select
                        className="form-select height-45 select_overall_grade_10"
                        id="overall_grade_10_294"
                        onChange={(event) => handleChange(event, 'AStar')}
                        value={AStar}
                        data-id="294"
                        name="overall_grade_10[294]">
                        <option value="">A*</option>
                        <option value="1">1 A*</option>
                        <option value="2">2 A*</option>
                        <option value="3">3 A*</option>
                        <option value="4">4 A*</option>
                        <option value="5">5 A*</option>
                        <option value="6">6 A*</option>
                        <option value="7">7 A*</option>
                    </select>
                </div>
                <div className="col-2 position-relative">
                    <select
                        className="form-select height-45 select_overall_grade_10"
                        id="overall_grade_10_295"
                        onChange={(event) => handleChange(event, 'A')}
                        value={A}
                        data-id="295"
                        name="overall_grade_10[295]">
                        <option value="">A</option>
                        <option value="1">1 A</option>
                        <option value="2">2 A</option>
                        <option value="3">3 A</option>
                        <option value="4">4 A</option>
                        <option value="5">5 A</option>
                        <option value="6">6 A</option>
                        <option value="7">7 A</option>
                    </select>
                </div>
                <div className="col-2 position-relative">
                    <select
                        className="form-select height-45 select_overall_grade_10"
                        id="overall_grade_10_296"
                        onChange={(event) => handleChange(event, 'B')}
                        value={B}
                        data-id="296"
                        name="overall_grade_10[296]">
                        <option value="">B</option>
                        <option value="1">1 B</option>
                        <option value="2">2 B</option>
                        <option value="3">3 B</option>
                        <option value="4">4 B</option>
                        <option value="5">5 B</option>
                        <option value="6">6 B</option>
                        <option value="7">7 B</option>
                    </select>
                </div>
                <div className="col-2 position-relative">
                    <select
                        className="form-select height-45 select_overall_grade_10"
                        id="overall_grade_10_297"
                        onChange={(event) => handleChange(event, 'C')}
                        value={C}
                        data-id="297"
                        name="overall_grade_10[297]">
                        <option value="">C</option>
                        <option value="1">1 C</option>
                        <option value="2">2 C</option>
                        <option value="3">3 C</option>
                        <option value="4">4 C</option>
                        <option value="5">5 C</option>
                        <option value="6">6 C</option>
                        <option value="7">7 C</option>
                    </select>
                </div>
                <div className="col-2 position-relative">
                    <select
                        className="form-select height-45 select_overall_grade_10"
                        id="overall_grade_10_298"
                        onChange={(event) => handleChange(event, 'D')}
                        value={D}
                        data-id="298"
                        name="overall_grade_10[298]">
                        <option value="">D</option>
                        <option value="1">1 D</option>
                        <option value="2">2 D</option>
                        <option value="3">3 D</option>
                        <option value="4">4 D</option>
                        <option value="5">5 D</option>
                        <option value="6">6 D</option>
                        <option value="7">7 D</option>
                    </select>
                </div>
                <div className="col-2 position-relative">
                    <select
                        className="form-select height-45 select_overall_grade_10"
                        id="overall_grade_10_299"
                        onChange={(event) => handleChange(event, 'E')}
                        value={E}
                        data-id="299"
                        name="overall_grade_10[299]">
                        <option value="">E</option>
                        <option value="1">1 E</option>
                        <option value="2">2 E</option>
                        <option value="3">3 E</option>
                        <option value="4">4 E</option>
                        <option value="5">5 E</option>
                        <option value="6">6 E</option>
                        <option value="7">7 E</option>
                    </select>
                </div>
                {/* {parentDataKey == "schoolClass10" && ( */}
                    <div className="col-2 position-relative">
                        <select
                            className="form-select height-45 select_overall_grade_10"
                            id="overall_grade_10_301"
                            onChange={(event) => handleChange(event, 'F')}
                            value={F}
                            data-id="301"
                            name="overall_grade_10[301]">
                            <option value="">F</option>
                            <option value="1">1 F</option>
                            <option value="2">2 F</option>
                            <option value="3">3 F</option>
                            <option value="4">4 F</option>
                            <option value="5">5 F</option>
                            <option value="6">6 F</option>
                            <option value="7">7 F</option>
                        </select>
                    </div>
                {/* )} */}

                {/* {parentDataKey == "schoolClass10" && ( */}
                    <div className="col-2 position-relative">
                        <select
                            className="form-select height-45 select_overall_grade_10"
                            id="overall_grade_10_302"
                            onChange={(event) => handleChange(event, 'G')}
                            value={G}
                            data-id="302"
                            name="overall_grade_10[302]">
                            <option value="">G</option>
                            <option value="1">1 G</option>
                            <option value="2">2 G</option>
                            <option value="3">3 G</option>
                            <option value="4">4 G</option>
                            <option value="5">5 G</option>
                            <option value="6">6 G</option>
                            <option value="7">7 G</option>
                        </select>
                    </div>
                {/* )} */}
            </div>
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default CambridgeGrade;
