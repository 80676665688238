import { useContext, useEffect } from 'react';
import { useLoaderData, Outlet, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import  HeaderControl  from '../_common/HeaderControl';
import MenuBuilder from '../../../routes/MenuBuilder';
import PageFooterControl from '../../../library/SimpleControls/PageFooterControl';
import ErrorPage from '../../../library/SimpleControls/ErrorPage';

const Schooltestmapping = () => {
    const { id, pageName } = useParams();
    const { globalState } = useContext(GlobalContext);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        data: [],
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const handleNavigateToHome = () => {
        // resetErrorBoundary();
        return <ErrorPage />;
    };

    // const { formConfig, data } = useLoaderData() as State;

    // useEffect(() => {
    //     const domain = globalState.domain;
    //     dispatch({
    //         type: 'DATA_INIT',
    //         payload: {
    //             formConfig,
    //             data: { ...data, pageDomain: data.domain, summaryCounts: data.summaryCounts, selectedTab: 'ACTIVE_LIST' },
    //             pageDomain: data.domain,
    //             domain,
    //             routeInfo: { id, pageName },
    //         },
    //     });
    // }, []);

    

    return (
        <div className="d-flex flex-column min-vh-100">
                <header>
                <HeaderControl />
                {/* <MenuLayout /> */}
                <MenuBuilder />
            </header>
            <div className='main flex-1'>
                <div className='container max-1140'>
                    <div className='row'>
                        <div className='col-md-12 mb-4'>
                        <div className="white-block white-block-notopborderradius h-100 p-0">
                  
                  <div className="page_ttl_row border-bottom border-2 custom-border-gray p-4 px-md-5">
                   <div className="row g-3 align-items-center ">
                      <div className="col"> 
                        <h3 className="font-35 font-600 mb-0 block-title aos-init aos-animate" data-aos="fade-left" data-aos-delay="300">School Test Mapping</h3>
                      </div>
                 
                   </div>
                      
                      
                 
                </div>
            
                    <div className="p-4 p-md-5">
                        <div className='row g-4'>
                            <div className='col-8'>
                                <div className='input-group select-search-group'>
                                    <select className="form-select" name="complete Class 12" aria-label="Default select example">
                                        <option value="">Select Client School</option>
                                    </select>
                                    <button className='btn btn-darkblue'><span>Search</span></button>
                                </div>
                            </div>
                            <div className='col-md-12'>
                            <div className="package-wrap">
                        <div className="package-list">
                          <div className="package-list-title">
                            <label>Test Name</label> 
                           
                          </div>
                          <ul>
                            <li><span>Ideal Career Test</span></li>
                            <li><span>Stream-Selector</span></li>
                            <li><span>Learning Style Test</span></li>
                            <li><span>Multiple Intellegence Test</span></li>
                            <li><span>Personality Style Test</span></li>
                            <li><span>Commerce Test</span></li>
                            <li><span>Engineering Test</span></li>
                            <li><span>Humanities Test</span></li>
                                  
                          </ul>
                        </div>
                        <div className="package-list-type">
                          <div className="package-type">
                            <div className="package-type-header defaultHeader">8<sup>th</sup></div>
                            <div className="package-type-body">
                              <ul>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                               <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                               <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                               <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/></li>
                               <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                               <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                               <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/></li>
                               <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/></li>
                       
                              </ul>
                            </div>
                          </div>

                          <div className="package-type">
                            <div className="package-type-header defaultHeader">9<sup>th</sup></div>
                            <div className="package-type-body">
                              <ul>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                        
                               </ul>
                            </div>
                          </div>

                          <div className="package-type">
                            <div className="package-type-header defaultHeader">10<sup>th</sup></div>
                            <div className="package-type-body">
                              <ul>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                        
                               </ul>
                            </div>
                          </div>

                          <div className="package-type">
                            <div className="package-type-header defaultHeader">11<sup>th</sup></div>
                            <div className="package-type-body">
                              <ul>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                        
                               </ul>
                            </div>
                          </div>

                          <div className="package-type">
                            <div className="package-type-header defaultHeader">12<sup>th</sup></div>
                            <div className="package-type-body">
                              <ul>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                                <li><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked/></li>
                        
                               </ul>
                            </div>
                          </div>
        
                         
                        </div>
                      </div>
                            </div>
                        </div>                         
                    </div>
                  </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageFooterControl />
        </div>
        
    );
};

export default Schooltestmapping;
