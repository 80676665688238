import { useContext,useState,useEffect } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { useNavigate } from 'react-router';
import LoadingSpinner from '../../../library/SimpleControls/LoadingSpinner';
import { SearchCriteria } from './SearchCriteria';
import Results from './Results';

const Layout = () => {
    const { state, dispatch } = useContext(SmartContext);
    const handleOpenModal = async () => {
        dispatch({ type: 'SET_MODAL_DATA', payload: {data:{}} });
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showNotesModal' } });
    };
    
    return (
        <div className="main flex-1">
            <section className="updateadditionalinformation">
              <div className="container max-1140 px-lg-0 overflow-hidden">
            {state?.data && <div className="row">
                <div className="col-md-12 mb-4">
                    <div className="white-block white-block-notopborderradius p-0 h-100">
                        <div className="p-4 p-md-5">
                            <div className="alumni_statistics">
                                <div className='row g-2'>
                                    <div className='col'>
                                        <h2 className="font-26 font-600 mb-0 pb-4">{state.formConfig?.title}</h2>
                                    </div>
                                    {state.routeInfo.pageName == "profilemarker-notes" && (
                                         <div className='col-auto'>
                                            {/* <button className='btn btn-success'  onClick={() => handleOpenModal()}>New Note</button> */}
                                            <button className="btn btn-border-lite-blue" onClick={handleOpenModal}>
                                                <i className="me-2 d-flex align-items-center"><img src='https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/add.svg'/></i>
                                                <span>Add Notes</span>
                                            </button>
                                         </div>
                                       
                                    )}
                                </div>
                                <div className="ActiveListings_present">
                                    <SearchCriteria />
                                    <Results />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            </div>
            </section>
        </div>
    );
};

export default Layout;
