import { useState, useEffect } from 'react';
import { axiosClient } from '../../../../library/Service/axiosClient';
import { Link } from 'react-router-dom';
const NOTIFICATION_API_URL = `${process.env.REACT_APP_COMMON_API}/notification/achievement-notification`;

const NewsUpdatesControl = () => {
    const [notification, setNotification] = useState([]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axiosClient().post(NOTIFICATION_API_URL);
                console.log(response);
                setNotification(response.data);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };
        fetchNotifications();
    }, []);

    const formattedDate = (item: string) => {
        const date = new Date(item);
        return date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    };

    if (notification.length === 0) {
        return null; // Return null when the notification array is empty
    }

    console.log(notification);
    return (
        <div className="row mb-2 mb-sm-4" id="LatestNewsBlock">
            <div className="col-md-12">
                <div className="white-block pb-3 h-100 aos-init aos-animate" data-aos="fade-up">
                    <div className="d-flex flex-wrap justify-content-between align-items-center mb-3 mb-sm-4">
                        <h3 className="font-22 font-600 mb-3 mb-lg-0 mb-mb-0 block-title">
                            News and Updates from school and alumni networks
                        </h3>
                        <Link to="/notification" className="font-14 text-nowrap text-burgandi">
                            View All
                        </Link>
                    </div>
                    <div className="row" id="LatestNews">
                        {notification.map((item: any) => (
                            <div className="col-lg-4 mb-4" key={item.uuid}>
                                <div className="gray-radius-box p-4 d-flex h-100">
                                    <div className="newimg">
                                        <Link to={`/alumni/${item.uuid}/profile`} className="text-decoration-none">
                                            <img
                                                src={`${item.profile_picture_file_path}`}
                                                alt="User"
                                                width="49"
                                                className="img-fluid rounded-circle"
                                            />
                                        </Link>
                                    </div>
                                    <div className="detail flex-1 ms-3">
                                        <Link
                                            to={`/alumni/${item.uuid}/profile`}
                                            className="text-decoration-none"
                                            style={{ color: 'black' }}>
                                            <p className="font-16 font-500 text-gray-41 mb-0">{item?.message}</p>
                                        </Link>
                                        <div className="date">
                                            <i className="icon-calendar text-gray-84 me-2"></i>
                                            <span className="font-14 font-500 text-gray-84">{formattedDate(item.date_created)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsUpdatesControl;
