import { getDomainValueForCode } from "../../../library/Core/SmartFunctions";

const CardPreparingExam = ({ alumni, state }: { alumni: any; state: any }) => {
    console.log(alumni, ' alumni ');
    const exam = alumni?.preparingEntranceTestResult?.exam ?? '';
    const preparationStatusCode = alumni?.preparingEntranceTestResult?.preparationStatusCode ?? '';

    return (
        <div key={alumni.uuid} className="alumni_univarsity_details examtaken">
            <div className="alumni_univarsity_details_inner_cover">
                <div className="alumni_univarsity_logo">
                    <img src="https://www.univariety.xyz/prototype/alumni-university/images/icon-note.svg" alt="" />
                </div>
                <div className="alumni_univarsity_brife">
                    <div className="alumni_education">
                        <div className="row g-2">
                            <div className="col">Preparing for Exam</div>
                        </div>
                    </div>
                    <div className="alumni_univarsity_name">
                        <span>
                            {exam}
                        </span>
                    </div>
                    {preparationStatusCode && (
                    <div className="alumni_univarsity_subject">
                        <i className=""></i>
                        <span>
                            {getDomainValueForCode(preparationStatusCode, 'ENTRANCE_EXAM_PREPARATION_STATUS', state)}
                        </span>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CardPreparingExam;
