import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const GoogleReview = () => {
    const navigate = useNavigate();
    const sesinstituteId = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user') as string).primaryInstitute : 0;
    const instName = localStorage.getItem('school_settings') ? JSON.parse(localStorage.getItem('school_settings') as string).name : '';
    const instPrimaryId = localStorage.getItem('school_settings') ? JSON.parse(localStorage.getItem('school_settings') as string).id : '';
    //  alert(instPrimaryId);
    const jwtToken = localStorage.getItem('user-jwt-token');
    let googleLink = '';
    useEffect(() => { 

        
            const saveTemplate = async (schoolLogo: string) => {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/pre-signup/get-google-link`, {
                        id: instPrimaryId
                    });
                    if (response.data.success) {
                       
                        if (response.data.success) {
                            googleLink = response.data.result; // Store the Google link in the variable
                        }
                    }
                } catch (error) {
                    console.error('Error fetching file URL:', error);
                }    
            const currentDate = new Date();
            const dateOptions: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const formattedDate = currentDate.toLocaleDateString(undefined, dateOptions);
            const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
            const formattedTime = currentDate.toLocaleTimeString(undefined, timeOptions);
            const date = `${formattedDate} ${formattedTime}`;
       
            const body =`<p><strong>Dear @First_Name @Last_Name,</strong></p><p>I hope this message finds you well! As a cherished member of our alumni community, your journey and achievements are a testament to the quality of education and experiences at ${instName}. We are reaching out to ask for your help in sharing your story with others. </p><p>Would you be willing to post a review about your time at ${instName}  on Google. Your insights and experiences can inspire prospective students and their families, and help them make informed decisions about their education.</p><p>Your honest feedback is invaluable to us and to those considering joining our community.</p><p>Thank you for your continued support and for helping us spread the word about ${instName} </p><p><a href="${googleLink}" rel="noopener noreferrer" target="_blank" style="color: rgb(255, 255, 255); background-color: rgb(92, 177, 72);">Review Us on Google</a></p><p>Warm regards,</p><p><strong>Alumni Helpdesk</strong></p>`;
            console.log(body);
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BROADCAST_SERVER}/email/save-template`,
                    {
                        subject: " Your review is precious to us!",
                        message: body,
                        school_ref_id: sesinstituteId,
                        status: 0,
                        created_date: date,
                        sender_mail: "Write your institute's review",
                        university_logo: schoolLogo || '',
                        template_ref_id:4,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                const insertedId = response.data.insertedId;
                if (insertedId) {
                    // Redirect to another page immediately after successful response
                    navigate(`/broadcast/alumni-profiles?template_id=${insertedId}&type=5`);
                } else {
                    console.error('Inserted ID not found in the response');
                }
            } catch (error) {
                console.error('Error saving template:', error);
            }
        };

        const schoolLogoFromSession = sessionStorage.getItem('school-image');
        if (schoolLogoFromSession) {
            saveTemplate(schoolLogoFromSession);
        } else {
            // Optionally handle the case where the logo is not in session storage
        }
    }, [sesinstituteId, jwtToken, navigate]);

    return null; // Ensure no content is rendered
};

export default GoogleReview;
