import { useContext, useEffect } from 'react';
import useSmartState from '../../../common/hooks/useSmartState';
import FormBuilder from '../../../library/Builders/FormBuilder';
import { SmartContext } from '../../../library/Core/SmartContext';
import { FormSection } from '../../../library/Core/SmartTypes';
import AlumniMatchStudentsService from './AlumniMatchStudentsService';
import FiltersApplied from '../../../library/SimpleControls/FiltersApplied';

const SearchCriteria = () => {
    const { state } = useContext(SmartContext);
    const searchFormConfig = state.formConfig?.sectionRepository[0] as FormSection;
    const { setStateValue } = useSmartState();

    const resetFilters = () => {
        var defaultSearchCriteria = AlumniMatchStudentsService.getDefaultSearchCriteria('');
        console.log(defaultSearchCriteria, " defaultSearchCriteria ")
        defaultSearchCriteria.schoolId = '';
        defaultSearchCriteria.schoolGroupId = '';
        defaultSearchCriteria.totalStudentCountFrom = '';
        defaultSearchCriteria.totalStudentCountTo = '';
        defaultSearchCriteria.totalAlumniCountFrom = '';
        defaultSearchCriteria.totalAlumniCountTo = '';
        defaultSearchCriteria.updatedToMoeFrom = '';
        defaultSearchCriteria.updatedToMoeTo = '';
        defaultSearchCriteria.clientType = '';
        defaultSearchCriteria.programLevel = '';
        defaultSearchCriteria.instituteTypeCode = 'K12_SCHOOL';
        
        var buttons = document.querySelectorAll('.row.g-3 button');
        buttons.forEach(function(button) {
            button.textContent = '';
        });

        document.querySelectorAll('.css-1dimb5e-singleValue').forEach(element => {
            element.textContent = ''; 
        }); //universityId text not removed after clear
        setStateValue('searchCriteria', defaultSearchCriteria);
    };

    useEffect(() => {
        AlumniMatchStudentsService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
            setStateValue('schoolList', response.data);
        });
    }, [state.data.searchCriteria]);

    return (
            <>
            <div className="fitergroup mb-3">
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" style={{}}>
                    <div className="accordion-body p-4">
                        <div className="row g-3">
                            <FormBuilder key="searchCriteria" section={searchFormConfig} dataKey="searchCriteria" />
                        </div>
                        {/* <button className="btn btn-reset field" onClick={resetFilters}><i className="icon-reset"></i> Reset Filters</button> */}
                    </div>
                </div>
            </div>

            <div className="filterresult">
               <FiltersApplied dataKey="searchCriteria" onClearAll={resetFilters} />
            </div>
            </>
    );
};

export default SearchCriteria;
