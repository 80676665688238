import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
import DomainDataService from '../../../services/domain-data.service';
import AlumniDirectoryService from '../alumni-directory/AlumniDirectoryService';

class DashboardService {
    static pageLoader(params: any) {
        const state: any = {};
        const { id, pageName } = params;

        return Promise.all([AlumniDirectoryService.getSummaryCounts(), DashboardService.getDocuments(params), DomainDataService.get()])
            .then((values) => {
                state.data = { ...values[1].data, summaryCounts: values[0].data, directoryLevel: 'MY_SCHOOL' };
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static async getDocuments(params: any) {
        return axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-dashboard`);
    }
}

export default DashboardService;
