import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import DebugBarControl from '../../../../library/SimpleControls/DebugBarControl';
import { handleCompleteLater, handleNext } from './BenefitsActions';
import BenefitsLayout from './BenefitsLayout';

const Benefits = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { NEXT: handleNext, I_LL_DO_THIS_LATER: handleCompleteLater, ROUTER_NAVIGATE: navigate, REDIRECT: setRedirectUrl },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        data['alumniProfile'] = data['alumniProfile'] || {};
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);
    
    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <BenefitsLayout />
            <DebugBarControl />
        </SmartContext.Provider>
    );
};

export default Benefits;
