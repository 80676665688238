export function getDashboardProgress(id: any, mapperData: any, dashboardData: any) {
    let ret_class = 'incomplete';
    mapperData?.[id]?.['AND'].forEach((key: string) => {
        if (typeof dashboardData[key] == 'undefined' || dashboardData[key] < 1) {
            ret_class = 'incomplete';
            return ret_class;
        } else {
            ret_class = 'completed';
        }
    });

    return ret_class;
}
