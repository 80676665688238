import { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';

const CarouselComponent = ({ data, cardRenderer, itemsPerPage = 2, itemsPerSlide = 2, title= '', responsive= {} }) => {
    const [carouselData, setCarouselData] = useState([]);
    const [pageInfo, setPageInfo] = useState({ page: 0, pageStart: 0, pageEnd: itemsPerSlide });
    const [carouselKey, setCarouselKey] = useState(0);

    const options = {
        margin: 20,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: false,
        smartSpeed: 1000,
        // responsive: {
        //     0: { items: 1 },
        //     400: { items: 1 },
        //     600: { items: 2 },
        //     700: { items: 2 },
        //     1000: { items: 1 },
        // },
        responsive: responsive
    };

    useEffect(() => {
        setCarouselData(data?.slice(0, itemsPerPage));
        setCarouselKey((prevKey) => prevKey + 1);
    }, [data]);

    const handlePrev = () => {
        let { page, pageStart, pageEnd } = pageInfo;
        let prevPageInfo;

        if (page - 1 <= 0) {
            prevPageInfo = { page: 0, pageStart: 0, pageEnd: itemsPerPage };
        } else {
            prevPageInfo = {
                page: page - 1,
                pageStart: (page - 1) * itemsPerSlide,
                pageEnd: (page - 1) * itemsPerSlide + itemsPerPage,
            };
        }

        const prevCarouselData = data?.slice(prevPageInfo.pageStart, prevPageInfo.pageEnd);

        setCarouselData(prevCarouselData);
        setPageInfo(prevPageInfo);
        setCarouselKey((prevKey) => prevKey + 1);
    };

    const handleNext = () => {
        const { page, pageStart, pageEnd } = pageInfo;
        if (pageEnd >= data?.length) return;

        const newPageInfo = {
            page: page + 1,
            pageStart: (page + 1) * itemsPerSlide,
            pageEnd: (page + 1) * itemsPerSlide + itemsPerPage,
        };

        const nextCarouselData = data?.slice(newPageInfo.pageStart, newPageInfo.pageEnd);
        setCarouselData(nextCarouselData);
        setPageInfo(newPageInfo);
        setCarouselKey((prevKey) => prevKey + 1);
    };

    return (
        <>
        {carouselData && carouselData.length > 0 && (
            <>
            <div className="seactionheader">
                <h3>{title}</h3>
                <div className="countnbtn">
                    <span className="count">{data?.length} {data?.length == 1 ? `record` : `records`} found</span>
                    <div className="custom-btns">
                        <a className="btn-prev owl-prev icon-left-arrow" role="button" onClick={handlePrev}></a>
                        <a className="btn-next owl-next icon-right-arrow" role="button" onClick={handleNext}></a>
                    </div>
                </div>
            </div>
            <OwlCarousel className="owl-carousel" {...options}>
                <div>{carouselData?.slice(0, itemsPerSlide)?.map(cardRenderer)}</div>
                <div>{carouselData?.slice(itemsPerSlide, 2 * itemsPerSlide)?.map(cardRenderer)}</div>
            </OwlCarousel>
            </>
        )}
        </>
    );
};

export default CarouselComponent;
