import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
// import { handleNext } from '../alumni/alumni-skills/AlumniSkillAction';
// import {
//     handleRowSelectionChange,
//     handleSendMessage,
//     handleStartNewConversation,
//     handleToggleStar,
//     loadMailToUserList,
// } from '../messages/MessageActions';
import DebugBarControl from '../../library/SimpleControls/DebugBarControl';
import MessagesLeftBar from './MessagesLeftBar';
import MessagesListContainer from './MessagesListContainer';
import MessagesTopBar from './MessagesTopBar';
import SessionContext from '../../library/Core/SessionContext';

const Messages = () => {
    const { ids } = useParams();
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);

    // const isPeerReviewed = sessionStorage.getItem('is-peer-reviewed') == 'true' ? true : false;
    // const userType = sessionStorage.getItem('user-type-code');
    
    const isPeerReviewed = sessionState?.isPeerReviewed;
    const userType = sessionState?.userTypeCode;
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
    // const [showNewMessage, setShowNewMessage] = useState(false);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        internal: { messagesFromCode: null, messageTypeCode: null, filteredMessages: [], searchText: null },
        actions: { ROUTER_NAVIGATE: navigate },
    } as any);

    const { formConfig, domain, data, internal, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo: { ...routeInfo, ids } },
        });
    }, []);

    if (
        userType === 'STUDENT' &&
        sessionState?.profileStatusCode === 'PREFERENCES_INCOMPLETE'
    ) {
        window.location.href = `/student/${sessionStorage.getItem('user-id')}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            {!state.flags.isDataLoading && (
                <>
                    <div className="main flex-1 d-flex">
                        <section className="notificationSection w-100 d-flex flex-1">
                            <div className="container max-1140 px-lg-0 flex-1">
                                <div className="row h-100">
                                    <div className="col-md-12 mb-0 mb-md-4">
                                        <div className="white-block white-block-notopborderradius h-100 p-0 d-flex flex-column">
                                            <div className="m-w-90  mb-1 px-4 py-3 py-sm-4">
                                                <MessagesTopBar />
                                            </div>
                                            <div className="row m-0 JobInternshipListing newmsg border-top flex-1">
                                                <MessagesLeftBar />
                                                <MessagesListContainer />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <DebugBarControl />
                </>
            )}
        </SmartContext.Provider>
    );
};

export default Messages;
