import { useContext, useEffect } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import EventsLayout from './EventsLayout';

const EventsListing = () => {
    const { globalState } = useContext(GlobalContext);
    const { searchBy } = useParams();
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        data: { filters: {} },
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { formConfig, data, internal } = useLoaderData() as State;

    useEffect(() => {
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain: globalState.domain, internal },
        });
    }, [searchBy]);

    if(sessionStorage.getItem('user-type-code') === 'STUDENT' && sessionStorage.getItem('user-profile-status-code') === 'PREFERENCES_INCOMPLETE')
    {
        window.location.href = `/student/${sessionStorage.getItem('user-id')}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    return <SmartContext.Provider value={{ state, dispatch }}>{!state?.flags.isDataLoading && <EventsLayout />}</SmartContext.Provider>;
};

export default EventsListing;
