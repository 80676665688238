import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../library/Core/SmartTypes';
import { GlobalContext } from '../../library/Core/GlobalContext';
import { axiosClient } from '../../library/Service/axiosClient';
import Swal from 'sweetalert2';
import useManagementDashboard from '../management/dashboard/useManagementDashboard';

import GuidingAlumniList from './alumni_guiding/GuidingAlumniList';
// import { getinstituteList } from '../../library/Service/domainService';
import SessionContext from '../../library/Core/SessionContext';
import { handleSave } from './WidgetConfigurationActions';

const WidgetConfigurationList = () => {
    const navigate = useNavigate();
    const { globalState } = useContext(GlobalContext);
    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;
    console.log(formConfig, 'formConfig')
    console.log(data, 'data')
    console.log(internal, 'internal')
    console.log(routeInfo, 'routeInfo')
    // const universityArray=['UNIVERSITY','DEEMED_UNIVERSITY']
    return (
        <>
        <GuidingAlumniList></GuidingAlumniList>
        </>
            
       
    );
};

export default WidgetConfigurationList;
