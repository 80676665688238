import { useContext, useState } from 'react';
import Grid from './Grid';
import SearchCriteria from './SearchCriteria';
import { SmartContext } from '../../../library/Core/SmartContext';
const Layout = () => {
    const { state, dispatch } = useContext(SmartContext);
    const [showSearchCriteria, setShowSearchCriteria] = useState(true);
    const handleToggleSearchCriteria = () => {
        setShowSearchCriteria(!showSearchCriteria);
    };

    return (
        <div className="main flex-1">
            <section className="updateadditionalinformation">
                <div className="container max-1140 px-lg-0 overflow-hidden">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius p-0 h-100">
                                <div className="p-4 p-md-5">
                                    <div className="alumni_statistics">
                                        <h2 className="font-26 font-600 mb-0 pb-4">Alumni Match for Students</h2>

                                        <div className="ActiveListings_present">
                                            <div className="d-flex flex-wrap justify-content-between align-items-start align-items-md-center mb-2 filterSearch">
                                                <div className="d-flex flex-wrap flex-1 mb-0 justify-content-start">
                                                    <button
                                                        className="btn-grpfiter me-3"
                                                        type="button"
                                                        onClick={handleToggleSearchCriteria}>
                                                        <i className="icon-filter font-13 me-2"></i>
                                                        {showSearchCriteria ? `Hide Filters` : `Show Filters`}
                                                    </button>
                                                </div>
                                            </div>
                                            {showSearchCriteria && <SearchCriteria />}
                                            {state.flags.isDataLoading == false && <Grid />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Layout;
