import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import AlumniCardGridControl from './AlumniCardGridControl';
import AlumniDirectoryService from './AlumniDirectoryService';
import DirectorySearchControl from './DirectorySearchControl';
import DirectorySummaryCardControl from './DirectorySummaryCardControl';

const AlumniDirectory = () => {
    const { pageName } = useParams();
    const { globalState } = useContext(GlobalContext);

    const [searchParams] = useSearchParams();
    const universityId = searchParams.get('universityId');

    const navigate = useNavigate();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        data: { filters: {} },
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { ROUTER_NAVIGATE: navigate },
    } as any);

    const { formConfig, data, internal } = useLoaderData() as State;

    useEffect(() => {
        const domain = globalState.domain;
        const searchFilterDomains = AlumniDirectoryService.getSearchFilterDomains(domain, internal.searchFilterDomains);
        const filters = {
            ...AlumniDirectoryService.getDefaultSearchCriteria(data.searchBy),
            universityId: universityId ? [universityId] : [],
        };
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: { ...data, searchDomain: searchFilterDomains, filters, cardViewMode: 'CURRENT' },
                internal: {...internal, searchFilterDomains },
                domain,
                routeInfo: { pageName },
            },
        });
    }, []);

    if (
        sessionStorage.getItem('user-type-code') === 'STUDENT' &&
        sessionStorage.getItem('user-profile-status-code') === 'PREFERENCES_INCOMPLETE'
    ) {
        window.location.href = `/student/${sessionStorage.getItem('user-id')}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            {!state?.flags.isDataLoading && (
                <>
                    <DebugBarControl />
                    <DirectorySummaryCardControl />
                    <DirectorySearchControl />
                    <AlumniCardGridControl />
                    <DebugBarControl />
                </>
            )}
        </SmartContext.Provider>
    );
};

export default AlumniDirectory;
