import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import { handleCompleteLater, handleNext } from './ScholarshipActions';
import ScholarshipLayout from './ScholarshipLayout';

const Scholarship = () => {
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');
    const { globalState } = useContext(GlobalContext);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { 
            NEXT: handleNext, 
            I_LL_DO_THIS_LATER: handleCompleteLater, 
            ROUTER_NAVIGATE: navigate,
            REDIRECT: setRedirectUrl 
        },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        // safelyAssign(formConfig, ['sectionRepository', 1, 'controlGroup', 0, 'controlGroup', 1, 'props', 'defaultValue'], false);
        data['underGradCollegeJoined'] = data['underGradCollegeJoined'] || {};
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <ScholarshipLayout>{!state.flags.isDataLoading && <PageBuilder />}</ScholarshipLayout>
        </SmartContext.Provider>
    );
};

export default Scholarship;
