import { useContext, useState } from 'react';
import { getStudentImage } from '../../common/styles/Images';
import { SmartContext } from '../../library/Core/SmartContext';
import { getDateDDMMYYYYHHMM } from '../../library/Core/SmartFunctions';
import useMessages from './useMessages';
import SessionContext from '../../library/Core/SessionContext';

const MessageView = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    const selectedConversationId = state.data.selectedConversationId;
    const { getConversationMembers, sendMessage, getById } = useMessages();
    const currentUser = sessionState?.studentId ?? sessionState?.userId;
    const messages = state.data.selectedConversation ?? [];
    const [newMessage, setNewMessage] = useState('');
    const schoolAdminRoles = ['INSTITUTE_ADMIN', 'INSTITUTE_SUPPORT_MANAGER', 'INSTITUTE_MARKETING_MANAGER'];

    const handleTypedMessageChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the form from submitting
            handleSendMessage(event);
        }
    };

    const handleSendMessage = (event: any) => {
        if (newMessage.trim() === '') return;
        sendMessage(newMessage);
        setNewMessage('');
    };

    const handleNextConversation = (event: any, mode: string) => {
        event.preventDefault();
        const conversations = state.data.conversations;
        const index = conversations.findIndex((conversation: any) => conversation.id === selectedConversationId);

        let nextConversationId;

        if (mode === 'NEXT' && index < conversations.length - 1) {
            nextConversationId = conversations[index + 1]?.id;
        } else if (mode === 'PREV' && index > 0) {
            nextConversationId = conversations[index - 1]?.id;
        } else {
            return;
        }

        getById(nextConversationId);

        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: 'selectedConversationId', value: nextConversationId },
        });
    };

    return (
        <div className="msgbox msgboxconvo extmar position-relative">
            <div className="search_ttlblk ps-4 pe-3">
                <div className="msg_title d-flex flex-wrap flex-column flex-sm-row justify-content-start justify-content-sm-between align-items-start align-items-sm-center">
                    <div className="d-flex flex-wrap justify-content-start align-items-center flex-1">
                        {getConversationMembers(selectedConversationId)
                            .filter((member: any) => member.id != currentUser)
                            .map((member: any, index: number) => (
                                <p
                                    key={member.id}
                                    className="university_ttl d-flex flex-wrap align-items-center font-16 line-height-18 font-500 text-dark-blue mb-0 pb-3 pb-sm-2">
                                    <span className="me-3">
                                        <img
                                            src={getStudentImage({
                                                profilePictureFilePath: member.profilePictureFilePath,
                                                genderCode: member.genderCode,
                                            })}
                                            alt=""
                                            className="rounded-circle"
                                            style={{ width: '32px', height: '32px' }}
                                        />
                                    </span>
                                    <span className="flex-1">
                                    {schoolAdminRoles.includes(member?.userTypeCode) && (member?.salutationCode ? member?.salutationCode + " " : "" )}
                                        {member.name}
                                        {!schoolAdminRoles.includes(member?.userTypeCode) && member?.passOutYear && ', '}
                                         {!schoolAdminRoles.includes(member?.userTypeCode) && (
                                            <span className="fst-italic">
                                                 {member?.programName ? '' + member?.programName : ''} 
                                                 {member?.passOutYear ? ', ' + member?.passOutYear : ''}
                                            </span>
                                        )}
                                         {schoolAdminRoles.includes(member?.userTypeCode) && (
                                            <span className="fst-italic">
                                            {member?.designation ? ', ' + member?.designation : ''}
                                        </span>
                                        )}
                                    </span>
                                </p>
                            ))}
                    </div>
                    <div className="convo_btns mt-1">
                        <a href="" className="btn mb-2 me-1" role="button" onClick={(event) => handleNextConversation(event, 'PREV')}>
                            <i className="icon-prev font-12 font-700 me-2"></i>
                            <span>Previous Conversation</span>
                        </a>
                        <a href="" className="btn mb-2 me-1" role="button" onClick={(event) => handleNextConversation(event, 'NEXT')}>
                            <i className="icon-next font-12 font-700 me-2"></i>
                            <span>Next Conversation</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="search_btmshadow"></div>

            <div className="search_cont px-4 pt-3">
                {messages.map((message: any, index: number) => (
                    <div key={message.id} className={message.isFromCurrentUser ? 'profile_person' : 'non-profile_person'}>
                        <p>{message.message}</p>
                        <p className="date">{message.sentDate}</p>
                    </div>
                ))}
            </div>

            <div className="msgbox_send position-absolute">
                <div className="position-relative">
                    <input
                        type="text"
                        className="form-control flex-1"
                        id="msgsend"
                        onChange={(e) => {
                            setNewMessage(e.target.value);
                        }}
                        onKeyUp={handleTypedMessageChange}
                        placeholder="Write a message"
                        value={newMessage}
                    />
                    <button className="btn btn-complete-now msgsendbtn  text-white" onClick={handleSendMessage}>
                        <span>Send</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MessageView;
