import React, { useState } from 'react';

const BrowseFile: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [headers, setHeaders] = useState<string[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [firstRowIsHeader, setFirstRowIsHeader] = useState<boolean>(true);
  const [updateExisting, setUpdateExisting] = useState<boolean>(true);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0] || null;
    setFile(uploadedFile);
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const text = event.target?.result;
        if (typeof text === 'string') {
          parseCSV(text);
        }
      };
      reader.readAsText(uploadedFile);
    }
  };

  const parseCSV = (text: string) => {
    const rows = text.split('\n').filter(row => row.trim() !== '');
    const parsedData = rows.map(row => row.split(','));
    if (firstRowIsHeader) {
      setHeaders(parsedData[0]);
      setData(parsedData.slice(1));
    } else {
      setData(parsedData);
    }
  };

  const handleSubmit = async () => {
    if (data.length === 0) {
      alert('No data to submit');
      return;
    }
    
    const payload = {
      headers: firstRowIsHeader ? headers : null,
      data,
      updateExisting,
    };

    try {
      const response = await fetch('/api/bulk_import', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to upload data');
      }

      alert('Data uploaded successfully');
    } catch (error) {
      console.error('Error uploading data:', error);
      alert('Error uploading data');
    }
  };

  return (
    <div className="main flex-1">
      <section className="alumni_directory_details container-fluid">
        <div className="max-1140 pt-3 pt-sm-5 px-lg-0 pb-5 overflow-hidden mx-auto">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="font-35 font-600 mb-0 block-title">Import Leads</h3>
            <div className="dashboard innerpage smart-scroll scrolled-down">
              <a href="create_event.html" className="btn btn-border-blue height-40 d-inline-flex font-14 text-decoration-none px-3 me-2">
                <span className="d-flex align-items-center font-500">
                  <img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/import.svg" width="19" className="me-2" alt="" />
                  Import History
                </span>
              </a>
            </div>
          </div>
          <div className="job_internship_tab polartabsec bulk_imports_header">
            <ul className="steps_flow">
              <li className="active"><a href="#">Upload</a></li>
              <li><a href="#">Map</a></li>
            </ul>
          </div>
          <div className="row g-3">
            <div className="col-12">
              <div className="import-title">
                <h3>Step 1 - Upload Users</h3>
                <p>Select the type of users you want to upload</p>
              </div>
            </div>
            <div className="col-sm-6 mx-auto">
              <input className="form-check-input d-none" type="radio" name="BulkImports" id="RegisteredUsers" />
              <div className="import-block">
                <div className="icon icon_user"></div>
                <div className="import-block-detail">
                  <h4>External Users</h4>
                  <ul>
                    <li>Users added through this method don't get to login or create their own profiles.</li>
                    <li>They will only be stored as leads for marketing activities, and not receive any other alumni or student engagement emails.</li>
                    <li>Adding users in this method has no limit..</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <input className="form-check-input d-none" type="radio" name="BulkImports" id="DisplayUsers" />
            </div>
            <div className="col-12">
              <div className="inputfile_parent">
                <input type="file" className="d-none" id="fileUpload_" onChange={handleFileChange} />
                <label htmlFor="fileUpload_" className="height-80">
                  <span className="me-1">
                    <img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/upload-gallery.svg" alt="" />
                  </span>
                  <span className="uploadlabel">Drag and drop a csv file here or <u className="text-skyblue">Browse</u></span>
                </label>
              </div>
            </div>
            <div className="col-12">
              <div className="row g-4 justify-content-center">
                <div className="col-sm-auto">
                  <div className="form-check form-check-tick">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={firstRowIsHeader}
                      onChange={() => setFirstRowIsHeader(!firstRowIsHeader)}
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label font-18 font-500" htmlFor="flexCheckDefault">
                      First row contains column names
                    </label>
                  </div>
                </div>
                <div className="col-sm-auto">
                  <div className="form-check form-check-tick">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={updateExisting}
                      onChange={() => setUpdateExisting(!updateExisting)}
                      id="flexCheckChecked"
                    />
                    <label className="form-check-label font-18 font-500" htmlFor="flexCheckChecked">
                      Update existing users only
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="action_btns mt-3 d-flex justify-content-center align-items-center">
                <a className="btn-skip" href="#">BACK TO IMPORTS</a>
                <button className="btn btn-darkblue px-5" onClick={handleSubmit}><span className="px-2">Next</span></button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BrowseFile;
