import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
import { onMobileCountryCodeChange, onMobileNumberChange, twelthPassoutYear } from './CreateAlumniActions';
import Layout from './Layout';
import { getinstituteList } from '../../../library/Service/domainService';
import useManagementDashboard from '../../management/dashboard/useManagementDashboard';
import SessionContext from '../../../library/Core/SessionContext';
import axios from 'axios';

const CreateNewAlumni = () => {
    const { globalState } = useContext(GlobalContext);
    const { getCampuses } = useManagementDashboard();
    const navigate = useNavigate();
    const { internal } = useLoaderData() as State;
    const { sessionState } = useContext(SessionContext);

    // const campuses = getCampuses();
    const campuses =(sessionState?.userTypeCode=='INSTITUTE_ADMIN') ?  getCampuses() : globalState.domain.get('INSTITUTE_DOMAIN')?.map((data) => {
        return {label:data.value, value:data.code};
    });

    const [instituteList, setinstituteList] = useState(getinstituteList('INSTITUTE_GROUP_CODE', campuses));

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            CREATE: handleCreate,
            passOutYear: twelthPassoutYear,
            mobileNumber: onMobileNumberChange,
            mobileCountryCode: onMobileCountryCodeChange,
        },
    } as any);

    function handleCreate(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        
        event.preventDefault();
        const isnotvalidMobile = isMobileNotValid(state, dispatch);
        const isnotvalidEmail = isEmailNotValid(state, dispatch);
        // const istenthdataNotvalid = isTenthDataNotValid(state, dispatch);
        const isInstituteNamenotValid = isInstituteNameNotValid(state, dispatch);

        if (!isFormValid(state, dispatch) || isnotvalidMobile || isnotvalidEmail || isInstituteNamenotValid) {
            return;
        }

        dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [] });

        let signupLink = `${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/admin/create-alumni`;
        let userStatusCode = 'PROFILE_IN_PROGRESS';

       

        axiosClient()
            .post(`${signupLink}`, {
                firstName: state['data']['user']['firstName'],
                lastName: state['data']['user']['lastName'],
                mobileCountryCode: state['data']['user']['mobileCountryCode'],
                mobileNumber: state['data']['user']['mobileNumber'],
                provideUpdatesOnWhatsapp: true,
                registeredEmailId: state['data']['user']['registeredEmailId'],
                instituteId: state['data']['user']['instituteId'],
                schoolId: state['data']['user']['schoolId'],
                password: 'uni123',
                invitedUserID: 111,
                userTypeCode: state['data']['user']['userTypeCode'],
                programId: state['data']['user']['programId'],
                genderCode: state['data']['user']['genderCode'],
                profileStatusCode: 'START',
                userStatusCode,
            })
            .then((res: any) => {
                const responseUserData = res.data as any;
                // dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [responseUserData] });


                if (responseUserData.success === false) {
                    if (responseUserData.message === 'Registration failed: Email already exists') {
                        setError('user.registeredEmailId', [responseUserData.message], dispatch);
                        dispatch({ type: 'SHOW_ERRORS' });
                    }

                    if (responseUserData.message === 'Registration failed: Mobile number already exists') {
                        setError('user.mobileNumber', [responseUserData.message], dispatch);
                        dispatch({ type: 'SHOW_ERRORS' });
                    }
                    return;
                }
                if (!responseUserData.data.userId) return alert('There is some issue in registration. Please try after sometime!');

                // User registered successfully
                 
                 // Send event to Moengage Sign up
                 sendMoEngageEvent(state['data']['user']);

                dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [responseUserData] });
                // If data saved then redict to alumni profile
                sessionStorage.setItem('is-impersonated-session', 'YES');
                navigate(`/alumni/${responseUserData.data.userId}/profile`);
            })
            .catch((err: any) => {
                if (err.response && err.response.status === 409) {
                    // Handle conflict error (HTTP 409)
                    alert('The user already exists.');
                } else {
                    // Handle other errors
                    console.error(err);
                    alert('An error occurred during registration. Please try again later.');
                }
            });
    }

    const isEmailNotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        const email = state?.data?.user?.registeredEmailId;

        const validRegex = state?.formConfig?.sectionRepository[0]?.controlGroup[2]?.props?.pattern
            ? new RegExp(state?.formConfig?.sectionRepository[0]?.controlGroup[2]?.props?.pattern)
            : /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (validRegex.test(email ? email : '') == false) {
            setError('user.registeredEmailId', ['Please enter a valid email address'], dispatch);
            return true;
        }

        return false;
    };

    const isMobileNotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        if (isEmpty(state?.data?.user?.mobileCountryCode)) {
            setError('user.mobileCountryCode', ['Please enter mobile code'], dispatch);
            return true;
        }

        if (isEmpty(state?.data?.user?.mobileNumber)) {
            setError('user.mobileNumber', ['Please enter mobile number'], dispatch);
            return true;
        }

        if (
            state?.data?.user?.mobileCountryCode === 91 &&
            (state?.data?.user?.mobileNumber.length !== 10 || state?.data?.user?.mobileNumber < 6000000000)
        ) {
            setError('user.mobileNumber', ['Please enter Valid mobile number'], dispatch);
            return true;
        }

        return false;
    };

    const isInstituteNameNotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        var returnVal = false;

        console.log(state.data?.user?.instituteId);

        if (state.data?.user?.instituteId === null || state.data?.user?.instituteId === undefined) {
            setError('user.instituteId', ['Please enter "Institute name"'], dispatch);
            returnVal = true;
        }

        return returnVal;
    };

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: { 
                    user: { },
                    institute : {
                        instituteGrouId: sessionState?.instituteGroupId,
                        instituteIdSession: sessionState?.institutes
                    },
                    userTypeCode : sessionState?.userTypeCode
                },
                domain,
                routeInfo,
            },
        });

        const setDomainData = async () => { 
            try {
                // console.log(instituteList,'instituteList');
                if(instituteList.length > 1) {
                    dispatch({
                        type: 'SET_DOMAIN',
                        payload: { key: 'INSTITUTE_GROUP_CODE', value: instituteList },
                    });
                }
                else if(instituteList.length == 1)
                {
                    dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey :'user.instituteId',value: instituteList[0].code } });
                    await fetchData();
                }
            } catch (error) {
                console.error('Error fetching program data:', error);
            }
        };
    
        setDomainData();
    }, []);

    async function fetchData() {
        try {
            const response = await axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/admin/get-programs-by-institute-id`, {
                institute_id: state.data?.user?.instituteId,
            });
            const programs = response.data.data.programs;
            let program_values = [];
            for(let i = 0; i < programs.length;i++)
            {
                program_values[i] = {'label' : programs[i]['name'],'value': programs[i]['id']};
            }
            
            dispatch({
                type: 'SET_DOMAIN',
                payload: { key: 'INSTITUTE_ATTENDED_PROGRAM_CODE', value: getinstituteList('INSTITUTE_ATTENDED_PROGRAM_CODE', program_values) },
            });
        } catch (error) {
            console.error('Error fetching program data:', error);
        }
    }

    useEffect(() => {
        if(state.data?.user?.instituteId != undefined){
            fetchData();
        }
    }, [state.data?.user?.instituteId]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <Layout>{!state.flags.isDataLoading && <PageBuilder />}</Layout>
        </SmartContext.Provider>
    );
};

const sendMoEngageEvent = async (user:any) => {
    try {
        const updatedUser = {
            ...user,
            reqType: 'Admin', // New key-value pair
          };
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-signup`, {
            userInfo       : updatedUser
        });
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

export default CreateNewAlumni;
