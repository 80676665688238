import { useContext, useEffect,useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import useGuidance from './useGuidance';

interface CollapsedFilters {
    [key: string]: boolean;
}

const GuidanceFilters = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { getCategoryName, getSubCategories } = useGuidance();

    const subCategories: any = [
        ...new Set(
            state.data.guidanceVideos
                .map((video: any) => video?.tags?.split(','))
                .flat()
                .map((tag: any) => tag?.trim())
        ),
    ];
    const [collapsedFilters, setCollapsedFilters] = useState<CollapsedFilters>({});

    const allSubCategories: any = state.domain.get('GUIDANCE_SUB_CATEGORY');

    const categoryCodes = subCategories.reduce((acc: any, subCategory: string) => {
        const foundSubCategory = allSubCategories.find((item: any) => item.code == subCategory);
        if (foundSubCategory) {
            acc.push(foundSubCategory.parentCode);
        }
        return acc;
    }, []);

    const uniqueCategoryCodes = [...new Set(categoryCodes)];

    const handleSubCategoryClick = (event: any) => {
        event.preventDefault();
        const actionType = event.target.checked ? 'ADD_NEW_RECORD_IN_ARRAY' : 'DELETE_RECORD_IN_SIMPLE_ARRAY';
        dispatch({
            type: actionType,
            payload: { dataKey: `filters`, name: event.target.id, value: event.target.value, errorMessages: [] },
        });
    };

    const handleClear = () => {
        dispatch({ type: 'SET_FILTERED_DATA', payload: state.data.guidanceVideos });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'filters', value: [] } });
    };

    useEffect(() => {
        let filteredData: any = state.data.guidanceVideos;
        let finalFilteredData: any = [];

        if (state.data.filters.length === 0) {
            finalFilteredData = state.data.guidanceVideos;
        } else {
            finalFilteredData = filteredData.filter((item: any) => {
                const tags = item?.tags?.split(',').map((tag: string) => tag.trim());
                return state.data?.filters.some((element: any) => tags?.includes(element));
            });
        }

        dispatch({ type: 'SET_FILTERED_DATA', payload: finalFilteredData });
    }, [state.data.filters]);

    const [isOpen, setOpen] = useState(false);

    const handleFilterToggle = () => {
        setOpen(!isOpen);
    };

    const toggleCollapse = (filterCode:any) => {
        console.log("collapsedFilters",collapsedFilters)
        setCollapsedFilters({
            ...collapsedFilters,
            [filterCode]: !collapsedFilters[filterCode]
        });
    };

    return (
        <>
            <div className="col-md-4 ji_filtersec ps-1">
                <div className="mobileFilter d-flex justify-content-between align-items-center d-md-none mobileonly text-end">
                    <h3 className="font-18 font-500 mb-0" id="MobHeadblock">
                    {state?.data?.filteredData.length} videos found
                    </h3>
                    <button
                        className="btn-filter btn-unscroll font-18 font-500"
                        type="button"
                        onClick={handleFilterToggle}
                        aria-expanded={isOpen ? 'true' : 'false'}
                        aria-controls="filter_collapse">
                        <span className="icon-filter"></span>Filters
                    </button>
                </div>
                <div className={`filters collapse ${isOpen ? '' : 'show'}`} id="filter_collapse">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <button
                                className="btn-filter btn-unscroll mobileonly"
                                type="button"
                                onClick={handleFilterToggle}
                                aria-expanded={isOpen ? 'true' : 'false'}
                                aria-controls="filter_collapse">
                                Filters
                            </button>
                            <h3 className="font-18 font-500 mb-2 mt-2">
                                <span className="icon-filter"></span>Filters
                            </h3>
                        </div>
                        <a className="font-14 font-500 text-burgandi text-decoration-underline" role="button" onClick={handleClear}>
                            Clear
                        </a>
                    </div>

                    <div className="filterGroup" id="accordionFilters">
                        {uniqueCategoryCodes.map((categoryCode: any) => (
                            <div key={categoryCode} className="fiterItem mb-3" id="CourseBlock">
                                <div className="filterHeader">
                                    <div className="d-flex justify-content-between align-items-center position-relative">
                                        <button
                                        className={`btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center ${collapsedFilters[categoryCode] ? 'collapsed' : ''}`}
                                        type="button"
                                            data-bs-toggle="collapse"
                                            onClick={() => toggleCollapse(categoryCode)} 
                                            aria-expanded={collapsedFilters[categoryCode]} 
                                            aria-controls={`collapse${categoryCode}`}>
                                            <span className="font-18 font-600">{getCategoryName(categoryCode)}</span>
                                            <span className="toggle"></span>
                                        </button>
                                    </div>
                                </div>
                                {/* <div className="collapse" id={`collapse${categoryCode}`}> */}
                                <div className={`collapse mt-2 ${collapsedFilters[categoryCode] ? '' : 'show'}`} id={`collapse${categoryCode}`}>
                                    <div className="card card-body mt-2">
                                        <div className="overflow-hidden">
                                            <div className="scrollMe">
                                                <div className="courses">
                                                    <div id="courses">
                                                        {getSubCategories(categoryCode, subCategories).map((subCategory: any) => (
                                                            <div
                                                                key={subCategory.code}
                                                                className="form-check"
                                                                style={{ display: 'flex !important' }}>
                                                                <input
                                                                    className="form-check-input me-2 Courses_class"
                                                                    type="checkbox"
                                                                    value={subCategory.code}
                                                                    checked={state.data?.filters.includes(subCategory.code)}
                                                                    onChange={handleSubCategoryClick}
                                                                    id={subCategory.code}
                                                                />
                                                                <label
                                                                    className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                                    htmlFor={subCategory.code}>
                                                                    {subCategory.value}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default GuidanceFilters;
