import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { canEditProfile } from '../../../../library/Core/SmartFunctions';
import SessionContext from '../../../../library/Core/SessionContext';

const Preferences = () => {
    const { state, dispatch } = useContext(SmartContext);
    const model = state.internal.model;
    const { id, pageName } = useParams();
    const numberStrings = ['First', 'Second', 'Third'];
    //const classCode = parseInt(state?.data?.classCode);
    const educationPreferenceInfo = state?.data?.educationPreferenceInfo;
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);

    if (sessionState?.userTypeCode === 'STUDENT' && sessionState?.profileStatusCode === 'PREFERENCES_INCOMPLETE') {
        window.location.href = `/alumni/${sessionState?.id}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    return (
        // <div className="tab-pane" id="pills-preferences" role="tabpanel" aria-labelledby="pills-preferences-tab">
        <div>
            <div className="preferences-detail tab-white-block">
                {canEditProfile(model.id, sessionState) && (
                    <a className="pencil-edit" role="button" onClick={() => navigate(`/alumni/${id}/student-preference`)}></a>
                )}

                <div className="customRow">
                    <div className="customRow-header">
                        <div className="icon icon-global"></div>
                        <div className="customRow-header-title">
                            <span className="font-20 font-500">Country</span>
                        </div>
                    </div>
                    <div className="customRow-detail">
                        <div className="row g-3">
                            {!educationPreferenceInfo?.isCountryChoiceSkipped &&
                                model.locationPreferences.map((item: any, index: number) => (
                                    <div key={item.countryName} className="col-md-4">
                                        <div className="d-flex flex-column">
                                            <div className="font-16 font-400 lineHeight-22 mb-1">{numberStrings[index]} Preference</div>
                                            <div className="font-18 font-500 lineHeight-22">{item.countryName}</div>
                                        </div>
                                    </div>
                                ))}
                            {educationPreferenceInfo?.isCountryChoiceSkipped && <div>I Havn't Finalized Country Yet</div>}
                        </div>
                    </div>
                </div>

                <div className="customRow">
                    <div className="customRow-header">
                        <div className="icon icon-book"></div>
                        <div className="customRow-header-title">
                            <span className="font-20 font-500">Course</span>
                        </div>
                    </div>
                    <div className="customRow-detail">
                        <div className="row g-3">
                            {!educationPreferenceInfo?.isCareerChoiceSkipped &&
                                model.fieldOfStudyPreferences.map((item: any, index: number) => (
                                    <div key={item.fieldOfStudyName} className="col-md-4">
                                        {item.fieldOfStudyName && (
                                            <div className="d-flex flex-column">
                                                <div className="font-16 font-400 lineHeight-22 mb-1">{numberStrings[index]} Preference</div>
                                                <div className="font-18 font-500 lineHeight-22">{item.fieldOfStudyName}</div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            {educationPreferenceInfo?.isCareerChoiceSkipped && <div>I haven't finalized the course yet</div>}
                        </div>
                    </div>
                </div>

                <>
                    <div className="customRow">
                        <div className="customRow-header">
                            <div className="icon icon-university-college"></div>
                            <div className="customRow-header-title">
                                <span className="font-20 font-500">College</span>
                            </div>
                        </div>
                        <div className="customRow-detail">
                            <div className="row g-3">
                                {!educationPreferenceInfo?.isCollegeChoiceSkipped &&
                                    model.universityPreferences.map((item: any, index: number) => (
                                        <div key={item.universityName} className="col-md-4">
                                            <div className="d-flex flex-column">
                                                <div className="font-16 font-400 lineHeight-22 mb-1">{numberStrings[index]} Preference</div>
                                                <div className="font-18 font-500 lineHeight-22">{item.universityName}</div>
                                            </div>
                                        </div>
                                    ))}
                                {educationPreferenceInfo?.isCollegeChoiceSkipped && <div>I haven't finalized the college yet</div>}
                            </div>
                        </div>
                    </div>

                    {/* <div>
                        <span>Planning to join in :</span>{' '}
                        <span className="font-18 font-500">
                            {model?.preferredProgramStartOn != '' ? model?.preferredProgramStartOn : '-'}
                        </span>
                    </div> */}
                </>
            </div>
        </div>
    );
};

export default Preferences;
