import { useContext, useState } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, handleControlValueChange } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import qrCode from '../../styles/images/qr.jpg';
import { axiosClient } from '../Service/axiosClient';

const FileUploader = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);

    const [image, setImage] = useState(data);

    const handleImageUpload = async (e: any) => {
        let link = '';
        if (e.target.files != null && e.target.files.length > 0) {
            const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/profile`;
            const formData = new FormData();
            formData.append('image', e.target.files[0]);
            // const response = await axiosClient().post(apiUrl, formData);
            // link = response.data.link;
            // setImage(link);
            // handleControlValueChange({ control, value: link, dataKey, parentDataKey, state, dispatch });
            const file = e.target.files[0];
            if (file.type.startsWith('image/')) {
                const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/profile`;
                const formData = new FormData();
                formData.append('image', file);
                
                try {
                    const response = await axiosClient().post(apiUrl, formData);
                    link = response.data.link;
                    setImage(link);
                    handleControlValueChange({ control, value: link, dataKey, parentDataKey, state, dispatch });
                } catch (error) {
                    // Handle error
                }
            } else {
                alert("File type is not supported to Upload Profile Picture")            
            }
        }
    };
    return (
        <>
            <div className="profile_picture_qr">
                <div className="profile_picture_section">
                    <label className="block" htmlFor="choose_image"></label>
                    <div className="profile_picture_edit">
                        <img alt="" src={image} />
                        <a className="image-edit" href=""></a>
                    </div>
                    <div className="profile_picture_edit_btn">
                        <input
                            type="file"
                            className="d-none"
                            id="choose_image"
                            onChange={handleImageUpload}
                            accept="img/*"
                            style={{ opacity: '0.1' }}
                        />
                        <label>CHOOSE IMAGE</label>
                    </div>
                </div>
                <div className="or">
                    <span>or</span>
                </div>
                <div className="qr_section">
                    <div className="qr">
                        <img src={qrCode} alt="" />
                    </div>
                    <p>Scan and send it to us on</p>
                    <div className="whatapp_text">
                        <i className="icon-whatsapp"></i>
                        <span>WhatsApp</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FileUploader;
