import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
import Congrats from '../../../../styles/images/mask-group-155@2x.png';
import { handleNext } from '../achievements/AchievementActions';

const StepOneCompletion = () => {
    const { globalState } = useContext(GlobalContext);
    const { id, pageName } = useParams();
    const navigate = useNavigate();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { NEXT: handleNext, ROUTER_NAVIGATE: navigate },
    } as any);

    const { formConfig, data } = useLoaderData() as State;

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo: { id, pageName } },
        });
    }, []);

    const handleLetsGo = () => {
        ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.STEP_1_COMPLETION_POPUP, state, dispatch);
    };

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <div className="" style={{ minHeight: '100vh' }}></div>
            <div
                className="modal fade show"
                id="step1complete_pop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="skip_popLabel"
                tabIndex={-1}
                aria-modal="true"
                role="dialog"
                style={{ display: 'block', minHeight: '100vh' }}>
                <div className="modal-dialog pop-dialog modal-dialog-centered">
                    <div className="modal-content bg-transparent">
                        <div className="pop-content">
                            <div className="pop-body">
                                <div className="brife">
                                    <h4>Great Going! </h4>

                                    <p>
                                        Coming Up Next -<br /> Unlock Your Exclusive Alumni Benefits
                                    </p>
                                </div>
                                <div className="img_pop">
                                    <img src={Congrats} alt="" width="350" />
                                </div>
                            </div>

                            <div className="pop-footer">
                                <button
                                    className="btn btn-link btn-goback d-inline-flex align-items-center justify-content-center"
                                    onClick={handleLetsGo}>
                                    LET’S GO!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SmartContext.Provider>
    );
};

export default StepOneCompletion;
