import Header from './Header';
import PersonalInfo from './PersonalInfo';
import Preferences from './Preferences';

const Layout = () => {
    return (
        <div className="main flex-1">
            <section className="bg-white container-fluid alumni_profile_brife_wrap myProfile" id="alumni_profile_brife_wrap">
                <div className="max-1140 mx-auto alumni_profile_brife">
                    <div className="row py-4">
                        {/* Here is the header */}
                        <Header />
                    </div>
                </div>
            </section>

            <section className="alumni_profile_detail_wrap container-fluid" id="alumni_profile_detail_wrap">
                <div className="max-1140 mx-auto alumni_profile_detail">
                    <div className="alumni_profile_brife_tab">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="personalInfo-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#personalInfo"
                                    type="button"
                                    role="tab"
                                    aria-controls="personalInfo"
                                    aria-selected="true">
                                    Personal Info
                                </button>
                            </li>
                            {/* <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="preferences-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#preferences"
                                    type="button"
                                    role="tab"
                                    aria-controls="preferences"
                                    aria-selected="false">
                                    Preferences
                                </button>
                            </li> */}
                        </ul>
                    </div>

                    <div className="tab-content" id="pills-tabContent">
                        {/* Personal Info and Preferences */}
                        <PersonalInfo />
                        <Preferences />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Layout;
