import { useContext, useEffect } from 'react';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import AdminProfileService from './AdminProfileService';
import Layout from './sections/Layout';

const AdminProfile = () => {
    const { globalState } = useContext(GlobalContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { ROUTER_NAVIGATE: navigate },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo, internal: { model: AdminProfileService.getProfileView({ data, domain }) } },
        });
    }, []);

    return <SmartContext.Provider value={{ state, dispatch }}>{!state?.flags.isDataLoading && <Layout />}</SmartContext.Provider>;
};

export default AdminProfile;
