import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty, toSentenceCase } from '../../../library/Core/SmartFunctions';
import NoEventsFound from './NoEventsFound';
import useEvents from './useEvents';

const EventsGridControl = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { performSearch } = useEvents();
    const events = state?.data?.events;
    const pageSize = 10;

    const [isVideoModalOpen, setVideoModalOpen] = useState(false);
    const [player, setPlayer] = useState<any>(null);
    const [videoLink, setVideoLink] = useState('');
    const playerRef = useRef<any>(null);

    function extractVideoId(url: string) {
        if (url === undefined) return '';
        const videoIdMatch = url.match(/(?:youtu.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/);
        if (videoIdMatch) {
            return videoIdMatch[1];
        }
        return '';
    }

    useEffect(() => {
        if (window.YT && typeof window.YT.Player !== 'undefined' && videoLink) {
            const videoId = extractVideoId(videoLink);
            playerRef.current = new window.YT.Player('youtube-player', {
                videoId: videoId,
                playerVars: {
                    origin: window.location.origin,
                },
                events: {
                    onReady: onPlayerReady,
                },
            });
        }
    }, [videoLink]);

    const onPlayerReady = (event: any) => {
        setPlayer(event.target);
        if (isVideoModalOpen) {
            event.target.playVideo();
        }
    };

    const openVideoModal = (link: string) => {
        setVideoLink(link);
        window.scrollTo(0, 0);
        setVideoModalOpen(true);
    };

    const closeVideoModal = () => {
        setVideoModalOpen(false);
        setVideoLink(''); //@note Same video is not playing again once closed the player;

        if (playerRef.current && playerRef.current.destroy) {
            playerRef.current.destroy();
            playerRef.current = null;
            setPlayer(null);
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const [numEventsDisplayed, setNumEventsDisplayed] = useState(pageSize);
    const hasMore = numEventsDisplayed < events.length;

    const fetchMoreData = () => {
        if (numEventsDisplayed >= events.length) return;
        setNumEventsDisplayed(numEventsDisplayed + pageSize); // Display 6 more events each time the user scrolls down
    };

    // useEffect(() => {
    //     performSearch('TAB_CHANGE');
    // }, [state?.data?.searchBy]);

    useEffect(() => {
        performSearch('FILTER_CHANGE');
    }, [state?.data?.filters]);

    if (events.length === 0) return <NoEventsFound />;
    console.log(events, ' events ');
    return (
        <div
            className="flex-1 polarcontsec tab-content mt-38 aos-init aos-animate"
            id="myTabContent"
            data-aos="fade-left"
            data-aos-delay="1200">
            <div className="tab-pane fade show active" id="UpcomingEvents" role="tabpanel" aria-labelledby="UpcomingEvents-tab">
                <h3 className="font-18 font-500 mb-3 d-none d-md-block">
                    <span id="ucount">{events.length}</span> Events Found
                </h3>
                <InfiniteScroll
                    dataLength={numEventsDisplayed} // length of data already rendered
                    next={fetchMoreData} // function to load more data
                    hasMore={hasMore} // boolean to control whether InfiniteScroll should call the 'next' function
                    loader={''}>
                    <div className="row EventsBlk" id="loadmore">
                        {events.slice(0, numEventsDisplayed).map((event: any) => (
                            <div key={event.EventID} className="col-lg-6 col-md-12 album_blk_parent pfusers">
                                <div className="album_blk">
                                    <div className="album_imgblk position-relative">
                                        <span className="album_badge font-16 font-500 text-white d-inline-block position-absolute">
                                            {event.event_type_name}
                                        </span>
                                        <img src={event.event_image} alt=""  />
                                    </div>
                                    <div className="album_contblk eventsbox">
                                        <div className="d-flex flex-wrap justify-content-between align-items-start">
                                            <div className="flex-1">
                                                <h3 className="font-20 font-500 mb-2 title">{toSentenceCase(event.EventTitle)}</h3>
                                            </div>
                                        </div>
                                        <p className="d-flex align-items-center justify-content-start mContCenter mb-0">
                                            <span className="icon_bluecir me-2">
                                                <span className="icon-calender-time font-18 text-dark-blue icon"></span>
                                            </span>
                                            <span className="font-28 font-600 text-dark-blue me-2">
                                                {new Date(event.FromDateTime).getDate()}
                                            </span>
                                            <span className="font-16 font-500 text-dark-blue">
                                                {moment(event.FromDateTime).format('MMM YYYY, h:mm A')}
                                            </span>
                                        </p>
                                        {event.event_speakers.map((speaker: any) => (
                                            <div
                                                key={speaker.SpeakerID}
                                                className="d-flex align-items-center justify-content-start mContCenter flex-wrap mb-3">
                                                <p className="d-flex align-items-center justify-content-start mt-2 mb-0">
                                                    <span className="icon_bluecir me-2">
                                                        <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/speech_icon.jpg`} />
                                                    </span>
                                                    <span className="font-16 line-height-16 font-500 text-dark-blue me-2 me-sm-4 m-max-105">
                                                        {toSentenceCase(speaker.SpeakerName)}, <br />
                                                        <span className="font-12 text-capitalize">
                                                            <em>
                                                                {speaker.Designation}, {speaker.Institution}
                                                            </em>
                                                        </span>
                                                    </span>
                                                </p>
                                                <div className="text-center mt-2"></div>
                                            </div>
                                        ))}
                                        {!isEmpty(event?.video_link?.trim()) && (
                                            <p className="d-flex align-items-center justify-content-start mContCenter flex-wrap">
                                                <a
                                                    className="btn btn-green text-white height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center me-3 max-170"
                                                    role="button"
                                                    onClick={() => openVideoModal(event.video_link)}>
                                                    <span>Watch</span>
                                                </a>

                                                <br />
                                            </p>
                                        )}

                                        {isEmpty(event?.video_link?.trim()) && (
                                            <p className="d-flex align-items-center justify-content-start mContCenter flex-wrap">
                                                <a
                                                    className="btn btn-green disable height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center me-3 max-170"
                                                    style={{ cursor: 'not-allowed' }}
                                                    data-bs-toggle="tooltip"
                                                    title="Coming soon..."
                                                    role="button">
                                                    <span>Watch</span>
                                                </a>

                                                <br />
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div
                            id="video_pop"
                            className={`modal fade show vh-100 ${isVideoModalOpen ? 'show' : ''}`}
                            tabIndex={-1}
                            role="dialog"
                            style={{ display: isVideoModalOpen ? 'block' : 'none' }}>
                            <div className="modal-dialog modal-lg " role="document">
                                <div className="modal-content">
                                    <div className="modal-body p-0">
                                        <button type="button" className="btn-close" onClick={closeVideoModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <div className="ratio ratio-16x9">
                                            <div id="youtube-player"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default EventsGridControl;
