import moment from 'moment';
import { useContext, useEffect } from 'react';
import { getStudentImage } from '../../common/styles/Images';
import SessionContext from '../../library/Core/SessionContext';
import { SmartContext } from '../../library/Core/SmartContext';
import { formatFullName } from '../../library/Core/SmartFunctions';
import useMessages from './useMessages';

// import dummayMale from '../../css/images/dummayFemale.png';

const MessageList = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);
    const { performAction, getById, paginateConversations, pageSize, getNoRecordsMessage } = useMessages();

    useEffect(() => {
        paginateConversations(0, pageSize);
    }, [state.data?.conversations]);

    const buildConversationRow = (conversation: any, index: number) => {
        const messageMembers = conversation?.memberInfoJson ?? [];
        const userId = sessionState?.studentId ?? sessionState?.userId;
        const toUserId = messageMembers?.find((member: any) => member !== userId);
        const toUserProfile = state.data.memberProfiles.find((member: any) => member?.id === toUserId);
        const schoolAdminRoles = ['INSTITUTE_ADMIN', 'INSTITUTE_SUPPORT_MANAGER', 'INSTITUTE_MARKETING_MANAGER'];
        console.log(`toUserId: ${toUserId}:: toUserProfile: ${toUserProfile}`);

        const handleActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: `conversations.${index}.action`, value: event.target.checked, errorMessages: [] },
            });
        };

        const handleStarClick = () => {
            const action = conversation.isStarred ? 'REMOVE_STAR' : 'MARK_STARRED';
            performAction(action, [conversation.id]);
        };

        const handleConversationSelect = (conversationId: string) => getById(conversationId);

        return (
            <div
                key={conversation.id}
                className="msg_title msg_title_row px-4 pt-2 d-flex flex-wrap justify-content-between align-items-center"
                style={{ backgroundColor: !conversation.isSeen ? 'aliceblue' : '' }}>
                <div className="d-flex flex-wrap justify-content-start align-items-center flex-1">
                    <div className="form-check me-2 pb-3 pb-sm-2">
                        <input
                            className="form-check-input me-2"
                            type="checkbox"
                            id="action"
                            onChange={handleActionChange}
                            checked={conversation?.action ?? false}
                        />
                    </div>
                    <i
                        className={`icon-star pb-3 pb-sm-2 font-20 me-3 ${conversation.isStarred ? ' selected ' : ''}`}
                        role="button"
                        onClick={handleStarClick}></i>
                    <p
                        className="university_ttl pb-3 pb-sm-2 d-flex align-items-center font-16 line-height-18 font-500 text-dark-blue mb-0 me-3"
                        role="button"
                        onClick={() => handleConversationSelect(conversation.id)}>
                        <span className="me-3">
                            <img
                                src={getStudentImage({
                                    profilePictureFilePath: toUserProfile?.profilePictureFilePath,
                                    genderCode: toUserProfile?.genderCode,
                                })}
                                // alt="No image"
                                // alt={formatFullName(toUserProfile?.firstName, toUserProfile?.lastName)}
                                className="rounded-circle"
                                style={{ width: '32px', height: '32px' }}
                            />
                        </span>
                        <span className="flex-1 text-dark-blue">
                            {schoolAdminRoles.includes(toUserProfile?.userTypeCode) && (toUserProfile?.salutationCode ? toUserProfile?.salutationCode + " " : "")}
                            {formatFullName(toUserProfile?.firstName, toUserProfile?.lastName)}
                            {!schoolAdminRoles.includes(toUserProfile?.userTypeCode) && toUserProfile?.passOutYear && ', '}
                            {!schoolAdminRoles.includes(toUserProfile?.userTypeCode) && (
                                <span className="font-14 d-block fst-italic">
                                    {/* {toUserProfile.passOutYear ? 'Class of ' + toUserProfile.passOutYear : ''} */}
                                    {/* {toUserProfile?.campusName ? ', ' + toUserProfile?.campusName : ''} */}
                                    {toUserProfile?.programName ? '' + toUserProfile?.programName : ''} 
                                    {toUserProfile?.passOutYear ? ', ' + toUserProfile?.passOutYear : ''}
                                </span>
                            )}
                            {schoolAdminRoles.includes(toUserProfile?.userTypeCode) && (
                                 <span className="font-14 d-block fst-italic">
                                 {toUserProfile?.designation ? '' + toUserProfile?.designation : ''}
                             </span>
                            )}
                        </span>
                    </p>

                    <p
                        className="msg_title_txt mb-2 mb-0 font-16 foont-400 text-black line-height-18 max-240 tabw-100 me-2 two-line-ellipsis"
                        role="button"
                        onClick={() => handleConversationSelect(conversation.id)}>
                        {`${conversation?.message?.substring(0, 90)}..`}
                    </p>
                </div>
                <div className="d-flex flex-column  align-items-start pb-2 msgacpign">
                    <p className="font-14 font-500 text-dark-blue mb-0">{moment(conversation?.sentDate).format('DD-MM-YYYY')}</p>
                    {/* <span className="alumni_message">{conversation?.typeCode ?? 'ALUMNI'}</span> */}
                    <span className={`${getUserTypeDisplay(toUserProfile?.userTypeCode)?.toLocaleLowerCase() ?? 'alumni'}_message`}>
                        {getUserTypeDisplay(toUserProfile?.userTypeCode) ?? 'ALUMNI'}
                    </span>
                </div>
            </div>
        );
    };

    const getUserTypeDisplay = (type: string) => {
        let retVal = '';

        switch (type) {
            case 'INSTITUTE_ADMIN':
            case 'SCHOOL_ADMIN':
            case 'INSTITUTE_SUPPORT_MANAGER':
            case 'INSTITUTE_MARKETING_MANAGER':
            case 'SUPPORT':
                retVal = 'SCHOOL';
                break;

            default:
                retVal = type;
                break;
        }

        return retVal;
    };

    const getNoConversationsToShow = () => {
        return (
            <div className="no_listed_items_blk">
                <div className="mt-0">
                    <div className="max-470 mx-auto">
                        <img
                            className="mt-2 mb-4 float_horizontal_animate"
                            src={`${process.env.REACT_APP_IMAGE_BASEPATH}/no_messages.svg`}
                        />
                        <p className="font-18 font-400 mb-4 text-center">{getNoRecordsMessage()}</p>
                        {/* <div className="xscroll">
                            <div className="send_msg_text d-flex justify-content-between">
                                <a href="https://miles.univariety.com/alumni/yes_school_connect">
                                    Send Message to
                                    <br />1 School Rep
                                </a>
                                <a href="https://miles.univariety.com/alumni/directory/2/c2VuaW9ycw==">
                                    Send Message to
                                    <br /> 1162 Seniors
                                </a>
                                <a href="https://miles.univariety.com/alumni/directory/2/YmF0Y2g">
                                    Send Message to
                                    <br /> 14 Batchmates
                                </a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="msg_title_row_wrapper">
                {state.data?.paginatedConversations?.map((conversation: any, index: number) => buildConversationRow(conversation, index))}
            </div>
            {state.data?.conversations?.length === 0 && getNoConversationsToShow()}
        </>
    );
};

export default MessageList;
