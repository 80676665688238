import { useContext, useEffect } from 'react';
import useSmartState from '../../common/hooks/useSmartState';
import FormBuilder from '../../library/Builders/FormBuilder';
import { SmartContext } from '../../library/Core/SmartContext';
import { FormSection } from '../../library/Core/SmartTypes';
import SchoolApiService from './SchoolApiService';

const SearchCriteria = () => {
    const { state, dispatch } = useContext(SmartContext);
    const searchFormConfig = state.formConfig?.sectionRepository[0] as FormSection;
    const { setStateValue } = useSmartState();

    const resetFilters = () => {
        var defaultSearchCriteria = SchoolApiService.getDefaultSearchCriteria(0,0,''); 
        console.log(defaultSearchCriteria, " defaultSearchCriteria ")
        defaultSearchCriteria.classCode = [];
        defaultSearchCriteria.passOutYear = [];
        defaultSearchCriteria.schoolGroupId = [];
        defaultSearchCriteria.schoolId = [];
        defaultSearchCriteria.searchBy = [];
      
        var buttons = document.querySelectorAll('.row.g-3 button');
        buttons.forEach(function(button) {
            button.textContent = '';
        });
        setStateValue('searchCriteria', defaultSearchCriteria);
    };

    useEffect(() => {
        SchoolApiService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
            setStateValue('studentList', response.data);
        });
    }, [state.data.searchCriteria]);

    return (
        <div>
            <div className="fitergroup mb-3">
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" style={{}}>
                    <div className="accordion-body p-4">
                        <div className="row g-3">
                            <FormBuilder key="searchCriteria" section={searchFormConfig} dataKey="searchCriteria" />
                        </div>
                        <button className="btn btn-reset field w-auto" onClick={resetFilters}><i className="icon-reset"></i> Reset Filters</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchCriteria;
