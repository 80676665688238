import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../../../../../library/Core/SmartContext';
import { Modal } from 'bootstrap';
import InterestsConfig from './FormConfiguration/InterestsConfig.json';
import ProfileMarkerService from './ProfileMakerService';
import { Tooltip } from 'bootstrap';
import { isEmpty } from '../../../../../../library/Core/SmartFunctions';
import EditInterestsModal from './EditInterestsModal';

type IntrestsProps = {
    isFundingStartUp: { label: string; isComment: string; toolTip: string };
    engagedWithDonorCampaigns: { label: string; isComment: string; toolTip: string };
    engagedWithRecruiterCampaigns: { label: string; toolTip: string; isComment: string };
    engagedWithGeneralInstituteCampaigns: { label: string; toolTip: string; isComment: string };
    engagedWithRecognitionCampaigns: { label: string; toolTip: string; isComment: string };
    engagedWithNetworkingCampaigns: { label: string; toolTip: string; isComment: string };
    engagedWithEmotionalConnectCampaigns: { label: string; toolTip: string; isComment: string };
    engagedWithRecreationEventsCampaigns: { label: string; toolTip: string; isComment: string };
    engagedWithLearningAndDevelopmentCampaigns: { label: string; toolTip: string; isComment: string };
    engagedWithHumorCampaigns: { label: string; toolTip: string; isComment: string };
};

const IntrestsProps: IntrestsProps = {
    isFundingStartUp: {
        label: 'Funding Start-ups',
        toolTip: "Alumnus's interest level in funding start-ups",
        isComment: 'fundingStartUpsComment',
    },
    engagedWithDonorCampaigns: {
        label: 'Engaged with Donor Campaigns',
        toolTip: "Alumnus' interest level with Donation Driving Campaigns",
        isComment: 'engagedWithDonorCampaignsComment',
    },
    engagedWithRecruiterCampaigns: {
        label: 'Engaged with Recruiter Campaigns',
        toolTip: "Alumnus' interest level with Campus Recruitment Related Campaigns",
        isComment: 'engagedWithRecruiterCampaignsComment',
    },
    engagedWithGeneralInstituteCampaigns: {
        label: 'Engaged with General Institute Update Campaigns',
        toolTip: "Alumnus' interest level with General Institute Updates ",
        isComment: 'engagedWithGeneralInstituteCampaignsComment',
    },
    engagedWithRecognitionCampaigns: {
        label: 'Engaged with Recognition Campaigns',
        toolTip: "Alumnus' interest level with Alumni Recognition Campaigns",
        isComment: 'engagedWithRecognitionCampaignsComment',
    },
    engagedWithNetworkingCampaigns: {
        label: 'Engaged with Networking Campaigns',
        toolTip: "Alumnus' interest level with Alumni Networking Campaigns",
        isComment: 'engagedWithNetworkingCampaignsComment',
    },
    engagedWithEmotionalConnectCampaigns: {
        label: 'Engaged with Emotional Connect Campaigns',
        toolTip: "Alumnus' interest level with Emotional Appeals",
        isComment: 'engagedWithEmotionalConnectCampaignsComment',
    },
    engagedWithRecreationEventsCampaigns: {
        label: 'Engaged with Recreation & Events Campaigns',
        toolTip: "Alumnus' interest level with Recreational activities and events related Campaigns",
        isComment: 'engagedWithRecreationEventsCampaignsComment',
    },
    engagedWithLearningAndDevelopmentCampaigns: {
        label: 'Engaged with Learning & Development Campaigns',
        toolTip: "Alumnus' interest level with Career Progression, Learning and upskilling related Campaigns",
        isComment: 'engagedWithLearningAndDevelopmentCampaignsComment',
    },
    engagedWithHumorCampaigns: {
        label: 'Engaged with Humor Campaigns',
        toolTip: "Alumnus' interest level with Humor themed Campaigns",
        isComment: 'engagedWithHumorCampaignsComment',
    },
};

const keys = Object.keys(IntrestsProps) as (keyof IntrestsProps)[];
const Interests = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { handleOpenLogsModal} = ProfileMarkerService();
    const handleOpenModal = async (key: string) => {
        dispatch({
            type: 'REFRESH_DATA',
            payload: { data: { ...state.data, visibleControl: key } },
        });
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showInterestsModal' } });
    };

    const model = state.internal.model;
    const [isConfigAvailable, setisConfigAvailable] = useState(false);
    const markers = state.data.marker || [];

    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, [state.data.interests]);

    useEffect(() => {
        // Make a shallow copy of the interests state
        const interestsData = { ...state.data.interests };

        // Iterate over each key in IntrestsProps
        Object.keys(IntrestsProps).forEach((key) => {
            const intrestsKey = key as keyof IntrestsProps;
            const prop = IntrestsProps[intrestsKey];

            if (prop && typeof prop === 'object' && 'isComment' in prop) {
                const isCommentField = prop.isComment;
                if (interestsData[intrestsKey] === false) {
                    // Reset comment field if interest is false
                    if (isCommentField) {
                        interestsData[isCommentField as keyof typeof interestsData] = '';
                    }

                    // reset the interest value itself
                    interestsData[intrestsKey] = '';
                }
            }
        });

        // Check if the new interestsData is different from the current state
        if (JSON.stringify(state.data.interests) !== JSON.stringify(interestsData)) {
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: 'interests', value: interestsData },
            });
        }
    }, [state.data.interests, dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const domainMap = new Map([...state.domain]);

                const config = await Promise.resolve(InterestsConfig);

                dispatch({
                    type: 'DATA_INIT',
                    payload: {
                        formConfig: config,
                        data: {
                            ...state.data,
                            interests: {
                                ...state.data.marker?.interests,
                            },
                            visibleControl: {},
                        },
                        domain: domainMap,
                        internal: state.internal,
                        routeInfo: state.routeInfo,
                    },
                });
                setisConfigAvailable(true);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const isMarkerEnabled = (key: string): boolean => {
        const value = markers?.interests?.[key];
        if (value === true) return true;
        if (typeof value === 'string') {
            if (!isEmpty(value)) {
                return true;
            }
        }
        return false;
    };

    const marketMeterValue = (key: string): string => {
        const value = markers?.interests?.[key];
        if (value === true) return '';
        if (typeof value === 'string') {
            return value;
        }
        return '';
    };

    // Return JSX to show Meter
    const DisplayMeter = (props: { meterkey: string }) => {
        const key = props?.meterkey;

        let meterValue = marketMeterValue(key);

        // if key is  funding startup return empty meter
        if (key === 'isFundingStartUp' || meterValue === 'Unsubscribed')
            return (
                <div className="col-auto">
                    <div className="d-flex flex-column justify-content-center align-items-center fade">
                        <div className="meter-wrap positive">
                            <span className="meter-icon">
                                <i className="pointer-pin"></i>
                            </span>
                        </div>
                        <div className="meter-title">Positive</div>
                    </div>
                </div>
            );

        meterValue =
            meterValue === 'Very Engaged'
                ? 'positive'
                : meterValue === 'Somewhat Engaged'
                ? 'neutral'
                : meterValue === 'Not Engaged'
                ? 'negative'
                : meterValue === 'Unsubscribed'
                ? ''
                : '';
        return (
            <div className="col-auto">
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className={`meter-wrap  ${meterValue}`}>
                        <span className="meter-icon">
                            <i className="pointer-pin"></i>
                        </span>
                    </div>
                    <div className="meter-title">{meterValue}</div>
                </div>
            </div>
        );
    };
    return (
        <div className="tab-pane fade show active" id="pills-interests" role="tabpanel" aria-labelledby="pills-interests-tab">
            <div className="d-flex flex-wrap flex-column flex-md-row">
                <div
                    className="nav flex-row flex-md-column nav-pills custom-sub-tab"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"></div>
                <div className="tab-content p-3 p-sm-4 p-md-5 bg-gray-F2F4F7 flex-1" id="v-pills-tabContent">
                    {/* Campaign */}
                    <div className="tab-pane fade show active" id="v-pills-campaign" role="tabpanel" aria-labelledby="v-pills-campaign-tab">
                        <div className="max-540 mx-auto">
                            <div className="row g-3">
                                {/* Replace this block with dynamic data */}
                                {keys.map((key) => {
                                    const markerExists = key in markers?.interests;
                                    const markerEnabled = isMarkerEnabled(key);
                                    const markerClass = markerEnabled ? (markers?.interests?.[key] == 'Unsubscribed' ? 'red' : '') : '';
                                    const comment = (IntrestsProps[key] as { label: string; isComment?: string }).isComment
                                        ? state.data.interests?.[
                                              (IntrestsProps[key] as { label: string; isComment?: string }).isComment!
                                          ] || ''
                                        : '';

                                    const value = markerExists ? markers.interests[key] : null;

                                    return (
                                        <div className="col-12" key={key}>
                                            <div className="row g-3 align-items-center">
                                                {markerEnabled ? (
                                                    <DisplayMeter meterkey={key}></DisplayMeter>
                                                ) : (
                                                    <div className="col-auto">
                                                        <div className="d-flex flex-column justify-content-center align-items-center fade">
                                                            <div className="meter-wrap positive">
                                                                <span className="meter-icon">
                                                                    <i className="pointer-pin"></i>
                                                                </span>
                                                            </div>
                                                            <div className="meter-title">Positive</div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="col">
                                                    <div className={`listed-border ${markerEnabled ? markerClass : 'disabled'}`}>
                                                        {' '}
                                                        <i className="tick"></i>
                                                        <p onClick={() => handleOpenModal(key)}>{IntrestsProps[key].label} </p>
                                                        {comment !== undefined && comment !== null && comment.trim() !== '' && (
                                                            <span
                                                                className="note-wrap"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-html="true"
                                                                title={comment}>
                                                                <i className="icon-note"></i>
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <i
                                                        className="icon-info"
                                                        data-bs-toggle="tooltip"
                                                        title=""
                                                        data-bs-original-title={IntrestsProps[key].toolTip}
                                                        aria-label={IntrestsProps[key].toolTip}></i>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="col-12 pt-3">
                                    <button className="btn btn-dark-blue w-auto height-40 px-4 d-flex align-items-center mx-auto" onClick={() => handleOpenLogsModal()}>
                                    <span className="text-white">View Update Logs</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isConfigAvailable && <EditInterestsModal userdata={{ model }} />}
        </div>
    );
};

export default Interests;
