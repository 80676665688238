import { Tooltip } from 'bootstrap';
import { useContext, useEffect, useRef, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import useSmartState from '../../../common/hooks/useSmartState';
import ContactLisingService from './ContactLisingService';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import ActionSettings from '../../../library/SimpleControls/ActionSettings';



const ContactGrid = () => {
    const { state } = useContext(SmartContext);
    const { setStateValue } = useSmartState();

    const navigate = useNavigate();
    const handleAddContact = () => {
       const groupId     =  state?.data?.groupId;
       const instituteId =  state?.data?.id;
       navigate(`/institute/${instituteId}/${groupId}/new/contact-add`, { replace: false });
    };

    const getActionNode = (row: any) => {
        // Define actions as an array
        const actions = ['Edit']; 
        // Check if actions array has any elements
        return actions.length > 0 ? (
            <ActionSettings id={row} actions={actions} onClick={handleAction} />
        ) : null;
    };
    
    const handleAction = (row: any, action: string) => {
        handlePerformAction(action, row);
    };
    
    const handlePerformAction = async (action: string, row: any) => {

       const instituteId =  row.primaryInstituteId;
       const uUID    =  row.uUID;
       const groupId     =  state?.data?.contactList[0].instituteId[0].instituteId;

        switch (action) {
            case 'EDIT':
                window.open(`${process.env.REACT_APP_APP_BASE_URL}/institute/${uUID}/${instituteId}/${groupId}/contact-edit`);
                break;
            default:
                console.log('Action not recognized:', action);
        }
    };
    

    return (
        <>
           <div className="main flex-1">
            <section className="updateadditionalinformation">
    <div className="container max-1140 px-lg-0 overflow-hidden">
      <div className="row">
        <div className="col-md-12 mb-4">
          <div className="white-block white-block-notopborderradius p-0 h-100">
            <div className="p-4 p-md-5">
              {/* Institute Category Header and Button */}
              <div className="alumni_statistics">
                <h2 className="font-26 font-600 mb-0 pb-4">Manage Contact Profile</h2>
                <button className="btn btn-green float-end" onClick={handleAddContact}><i className="icon-plus"></i><span>Add Contact</span></button>
              </div>
              
              {/* Active Listings Section */}
              <div className="ActiveListings_present">
                <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                  <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks my-2">
                    <div className="d-flex justify-content-start align-items-center customselect_parnt"></div>
                  </div>
                  <div className="d-flex flex-wrap align-items-center justify-content-end">
                    {/* <h3 className="font-14 font-500 mb-0 me-2">Total 4 records</h3> */}
                  </div>
                </div>
                
                {/* Institute Listings Table */}
                <div className="table-responsive attendee_dtl">
                  <table className="table" style={{ minWidth: '1042px' }}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Contact Details</th>
                        <th>Designation</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Example Rows */}
                      {state.data.contactList.map((contact:any) => (
                        <tr key={contact.uUID}>
                        <td>{contact.firstName} {contact.lastName}</td>
                        <td>
                           <div className="d-flex flex-column">
                            <div className="d-flex flex-wrap align-items-center"><i className="icon-phone me-2"></i><span>+{contact.mobileCountryCode} {contact.mobileNumber}</span></div>
                            <div className="d-flex flex-wrap align-items-center"><i className="icon-mail me-2"></i><span>{contact.registeredEmailId}</span></div>
                          </div>
                        </td>
                        <td>{contact.designation}</td>
                        <td>{getActionNode(contact)}</td>
                        </tr>
                       ))}
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
            </section>
           </div>

        </>
    );
};

export default ContactGrid;
