import { axiosClient } from '../../../library/Service/axiosClient';
import { REPORT_CONFIG } from '../../admin/reports/report-config';
import pageConfig from './PageConfigList.json';

class ManageReferralService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = ManageReferralService.getDefaultSearchCriteria();
        
        return Promise.all([
            this.getPageConfig(),
            this.search(searchCriteria, params.pageName),
        ]).then((values) => {
            console.log(values)
            state.formConfig = values[0];
            state.data = {
                leadList: values[1].data ?? [],
                searchCriteria: { ...searchCriteria, schoolId: sessionStorage.getItem('user-school-id') },
                reportConfig: REPORT_CONFIG[params.pageName],
            };
            state.routeInfo = params;
            state.internal = { gridState: {} };
            return Promise.resolve(state);
        });
    }

    static search(searchCriteria: any, pageName: string): any {
        const config = REPORT_CONFIG[pageName];
        
        // Ensure lead_marketing.lead_status = 1 is always included in the search criteria
        const payload = {
            ...ManageReferralService.sanitizeSearchCriteria(searchCriteria),
            lead_marketing: { lead_status: 1 }
        };

        return axiosClient().post(`${process.env.REACT_APP_LEAD_SERVER}/${config.SEARCH_API}`, payload);
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    static sanitizeSearchCriteria(searchCriteria: any) {
        return searchCriteria;
    }

    static getDefaultSearchCriteria(): any {
        return {
            instituteId: [],
            programId: [],
            leadSource: [],
            registrationType: [],
            leadStatus: [],
            leadCreationDate: [],
            leadUpdationDate: [],
            intakeBatch: [],
            createdFrom: [],
            createdTo: [],
            updateFrom: [],
            updateTo: [],
            referral: null,
            lead_status: 1 // Default condition for lead status
        };
    }
}

export default ManageReferralService;
