import { useEffect, useRef } from 'react';
const LoginCarouselControl = () => {
    const data = JSON.parse(sessionStorage.getItem('carousel-data') as string)?.login ?? [];
    const schoolSettings = localStorage.getItem('school_settings');
    const parsedSchoolSettings = schoolSettings ? JSON.parse(schoolSettings) : null;
    const schoolName = parsedSchoolSettings?.name ?? '';

    const carouselRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (carouselRef.current) {
                const activeSlide = carouselRef.current.querySelector('.carousel-item.active') as HTMLDivElement;
                const slides = carouselRef.current.querySelectorAll('.carousel-item') as NodeListOf<HTMLDivElement>;
                const activeIndex = Array.from(slides).indexOf(activeSlide);
                const nextIndex = (activeIndex + 1) % slides.length;

                slides[activeIndex].classList.remove('active');
                slides[nextIndex].classList.add('active');

                const statusBtn = carouselRef.current.querySelectorAll('.status-btn') as NodeListOf<HTMLButtonElement>;
                statusBtn[activeIndex].classList.remove('active');
                statusBtn[nextIndex].classList.add('active');
            }
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="d-flex flex-column justify-content-center w-75 text-center align-items-center">
            <h2>Welcome To {schoolName} Alumni Network</h2>
            <div id="carouselLogin" className="carousel slide" data-bs-ride="carousel" ref={carouselRef}>
                <div className="carousel-indicators">
                    {data.map((item: any, index: number) => (
                        <button
                            key={index}
                            type="button"
                            data-bs-target="#carouselLogin"
                            data-bs-slide-to={`${index}`}
                            className={`status-btn bg-danger ${index === 0 ? ' active ' : ''}`}
                            aria-current="true"
                            aria-label={`Slide ${index + 1}`}></button>
                    ))}
                </div>
                <div className="carousel-inner mb-5">
                    {data.map((item: any, index: number) => (
                        <div key={item['id']} className={`carousel-item ${index === 0 ? ' active ' : ''}`}>
                            <figure>
                                <img className="mx-auto" src={item['imageUrl']} alt="" />
                            </figure>
                            <h4>{item['messageTitle']}</h4>
                            <p>{item['message']}</p>
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselLogin" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselLogin" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
};

export default LoginCarouselControl;
