import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
const ViewProfile: React.FC = () => {
    const [leadData, setLeadData] = useState<any>(null); // State to hold the lead data
    const [error, setError] = useState<string>(''); // State to hold any potential error
    let sesinstituteId = useState<string>('');
    const { search } = useLocation();
    const [preferredCampuses, setPreferredCampuses] = useState<string[]>([]);
    const [preferredPrograms, setPreferredPrograms] = useState<string[]>([]);
    const userDataString = sessionStorage.getItem('user');
    if (!userDataString) {
        window.location.href = "/404";
    }
    if (userDataString) {
        // Parse JSON string to object
        const userData = JSON.parse(userDataString);
        sesinstituteId = userData.institutes;
    }
    const searchParams = new URLSearchParams(search); // Use search directly
    const id = searchParams.get('id');
    useEffect(() => {
        // Function to fetch lead data from the API
        const fetchLeadData = async () => {
            try {
                // alert(id);
                // Make a POST request with the ID
                const response = await axios.post(`${process.env.REACT_APP_LEAD_SERVER}/addlead/getsinglelead`, { id: id });
                // Set the lead data in state
                setLeadData(response.data.data[0]); // Assuming response.data.data is an array with one element
                // alert(leadData.registration_by});
                const emailId = response.data.data[0].email;
                const instituteId = response.data.data[0].program;
                //  alert(instituteId);

                const campusResponse = await axios.post(`${process.env.REACT_APP_LEAD_SERVER}/addlead/getinstitutebyid`, {
                    id: id,
                    instituteId: sesinstituteId,
                });

                // Assuming the campus names are returned in an array under response.data.campuses
                const campuses = campusResponse.data.data.map((campus: any) => campus.name);
                setPreferredCampuses(campuses);

                const programResponse = await axios.post(`${process.env.REACT_APP_LEAD_SERVER}/addlead/getprogrambyid`, {
                    instituteId: instituteId,
                });

                const programs = programResponse.data.names;
                setPreferredPrograms(programs);
            } catch (error) {
                // Handle any errors
                setError('Error fetching lead data');
                console.error('Error fetching lead data:', error);
            }
        };

        // Call the fetchLeadData function when the component mounts
        fetchLeadData();

        // Clean-up function
        return () => {
            // Any clean-up code here, if needed
        };
    }, []); // Empty dependency array ensures this effect runs only once after the initial render

    // Function to parse the classInterested string into a formatted string

    return (
        <div className="main flex-1 bg-white">
            {leadData && (
                <section className="alumni_directory_details container-fluid">
                    <div className="max-1140 pt-3 pt-sm-5 px-lg-0 pb-5 overflow-hidden mx-auto">
                        <div className="d-flex justify-content-between align-items-center mb-3 pb-2 custom-bottom-liteblue">
                            <h3
                                data-aos="fade-left"
                                data-aos-delay="600"
                                className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                                Lead Profile
                            </h3>
                        </div>

                        <div className="row g-3 align-items-center mb-4">
                            <div className="col d-flex align-items-center">
                                <div className="contact_detail">
                                    <div className='font-18 font-600 mb-1'>{leadData.first_name} {leadData.last_name}</div>
                                    <div className="phone">
                                        <i></i>{' '}
                                        <a >
                                            {leadData.country_code} {leadData.mobile}
                                        </a>
                                    </div>
                                    <div className="email">
                                        <i></i> <a >{leadData.email}</a>
                                    </div>
                                </div>
                                <br />
                            </div>

                            <div className="col-sm-auto">
                                <div className="lead_items">
                                    <div>
                                        <i>Lead Type:</i> <b>{leadData.registration_by}</b>
                                    </div>
                                    <div>
                                        <i>Lead Source:</i> <b>{leadData.source}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="job_internship_tab polartabsec radius-6 mx-0">
                            <div className="xscroll p-0">
                                <ul className="nav nav-tabs" id="JobInternshipTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link active"
                                            id="target-class-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#target-class"
                                            type="button"
                                            role="tab"
                                            aria-controls="target-class"
                                            aria-selected="true">
                                            Target Program
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="JobInternshipListing">
                            <div className="flex-1 polarcontsec tab-content" id="myTabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="target-class"
                                    role="tabpanel"
                                    aria-labelledby="target-class-tab">
                                    <div className="d-flex justify-content-end mt-4 pb-3 pb-sm-0">
                                        <Link to={`/lead/update-personal-info?id=${id}`} className="btn btn-border-blue px-3">
                                            {' '}
                                            <span>
                                                <i className="icon-edit me-1"></i> Edit
                                            </span>
                                        </Link>
                                    </div>

                                    <div className="mt-2 max-570 mx-auto">
                                        <div className="row g-2">
                                            
                                            <div className="col-sm-6">
                                                <label className="font-16 font-500">Preferred Campus </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <span className="font-16">
                                                    {preferredCampuses.map((campus, index) => (
                                                        <span key={index}>
                                                            {campus}
                                                            {index !== preferredCampuses.length - 1 && ', '}
                                                        </span>
                                                    ))}
                                                </span>
                                            </div>
                                            <div className="col-md-12">
                                                <hr className="my-2 hrBg" />
                                            </div>

                                            <div className="col-sm-6">
                                                <label className="font-16 font-500">Program Interested</label>
                                            </div>

                                            <div className="col-sm-6">
                                                <span className="font-16">{preferredPrograms && preferredPrograms.join(', ')}</span>
                                            </div>

                                            <div className="col-md-12">
                                                <hr className="my-2 hrBg" />
                                            </div>

                                            <div className="col-sm-6">
                                                <label className="font-16 font-500">Planned Intake </label>
                                            </div>

                                            <div className="col-sm-6">
                                                <span className="font-16">{leadData.intake}</span>
                                            </div>

                                            <div className="col-md-12">
                                                <hr className="my-2 hrBg" />
                                            </div>

                                            <div className="col-sm-6">
                                                <label className="font-16 font-500">Gender </label>
                                            </div>

                                            <div className="col-sm-6">
                                                <span className="font-16">{leadData.gender} </span>
                                            </div>

                                            <div className="col-md-12">
                                                <hr className="my-2 hrBg" />
                                            </div>

                                            <div className="col-sm-6">
                                                <label className="font-16 font-500">Lead Status</label>
                                            </div>

                                            <div className="col-sm-6">
                                                <span className="font-16"> {leadData.responce && leadData.responce}</span>
                                            </div>

                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
};
export default ViewProfile;
