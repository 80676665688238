import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartContext } from '../../../../library/Core/SmartContext';
import SessionContext from '../../../../library/Core/SessionContext';
import ProfileMarkerService from './admin/ProfileMarker/ProfileMakerService';
import Ability from './admin/ProfileMarker/Ability';
import Willingness from './admin/ProfileMarker/Willingness';
import Interests from './admin/ProfileMarker/Interests';
import OfficeUsage from './OfficeUsage';
import ProfileMarker from './admin/ProfileMarker/ProfileMarker';
import OfficeUsageNew from './OfficeUsageNew';
;

const OfficeMarker = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);
    const { fetchUserProfileMarkers } = ProfileMarkerService();

    const navigate = useNavigate();
    const [isPersonate, setIsPersonate] = useState(sessionStorage.getItem('is-impersonated-session') === 'YES');
    const [activeMarkerTab, setActiveMarkerTab] = useState('office-usage');

    useEffect(() => {
        const fetchUserMarkers = async () => {
            const userMarkers = await fetchUserProfileMarkers(state?.internal?.model?.id);
        };
        fetchUserMarkers();
    }, []);

    const renderActiveTab = () => {
        switch (activeMarkerTab) {
            case 'office-usage':
                return <OfficeUsageNew />;
            case 'profile-marker':
                return <ProfileMarker />;
            default:
                return <OfficeUsageNew />;
        }
    };

    return (
        <div className="border-d2d2d2 border-radius-6 bg-white overflow-hidden">
            <div className="block-content">
                <div className="block-custom-tab">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${activeMarkerTab === 'office-usage' ? 'active' : ''}`}
                                id="pills-office-usage-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-office-usage"
                                type="button"
                                role="tab"
                                aria-controls="pills-office-usage"
                                aria-selected={activeMarkerTab === 'office-usage'}
                                onClick={() => setActiveMarkerTab('office-usage')}>
                                OFFICE USAGE
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${activeMarkerTab === 'profile-marker' ? '' : ''}`}
                                id="pills-profile-marker-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile-marker"
                                type="button"
                                role="tab"
                                aria-controls="pills-profile-marker"
                                aria-selected={activeMarkerTab === 'profile-marker'}
                                onClick={() => setActiveMarkerTab('profile-marker')}>
                               PROFILE MARKER
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        {activeMarkerTab == 'office-usage' && <OfficeUsageNew />}
                        {activeMarkerTab === 'profile-marker' && <ProfileMarker />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OfficeMarker;
