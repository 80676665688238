import axios from 'axios';

class VerificationService {
    static async verifyMyEmail(uuid) {
        alert(`${process.env.REACT_APP_COMMON_API}/users/verify-user`);
        const response = await axios.post(`${process.env.REACT_APP_COMMON_API}/users/verify-user`, { uuid: uuid });
        //console.log(response.data);
        return response.data;
    }
}

export default VerificationService;
