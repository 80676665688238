// import config from '../config/config';
import { AxiosResponse } from 'axios';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
// import PageConfigService from './page-config.service';
import pageConfig from './PageConfignew.json';
import { useContext } from 'react';
import SessionContext from '../../../library/Core/SessionContext';
import { SessionStateType } from '../../../library/Core/SmartTypes';
import { format } from 'date-fns';


class AddnewService {
    static userStatusCode: string;
    static eventId: string;
    static userTypeCode: string;
    static genderCode: string;
    static eventImage: string;
    static instituteGroupId: string;
    
    constructor() {
        // IIFE (Immediately Invoked Function Expression)
        (() => {
            const { sessionState } = useContext(SessionContext);
            // AddnewService.userStatusCode = sessionState?.userStatusCode as any;
            // AddnewService.studentId = sessionState?.studentId as any;
            // AddnewService.userTypeCode = sessionState?.userTypeCode as any;
            // AddnewService.genderCode = sessionState?.genderCode as any;
            // AddnewService.instituteLogo = sessionState?.instituteLogo as any;
            // AddnewService.instituteGroupId = sessionState?.instituteGroupId as any;
            //const { userStatusCode, studentId, userTypeCode, genderCode, instituteLogo: schoolImage } = sessionState as SessionStateType;
        })();

        // Rest of the constructor
    }

    static pageLoader(params: any) {
        const state: any = {};
        const { pageName } = params;
        

        return Promise.all([this.getPageConfig(), AddnewService.get()])
            .then((values) => {
                
                state.formConfig = values[0];
                state.data= values[1];
                state.routeInfo = { pageName };
                
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    static async get(): Promise<any> {
        // TODO: Fix the issue with sessionStorage
        let BasicInfo = { };
        const queryParams = new URLSearchParams(window.location.search);
        const eventId = queryParams.get('id');
        
        if (eventId) {
            BasicInfo = (await axiosClient().get(`${process.env.REACT_APP_LEAD_SERVER}/marketevent/geteventinfo/${eventId}`))?.data;            
            BasicInfo = AddnewService.toEventto(BasicInfo);
        }
        //console.log(BasicInfo)

        return Promise.resolve(BasicInfo);
    }


    static toEventto(eventinfo: any) {
       
        const dateFromDate = new Date(eventinfo.data.from);
        const toFromDate = new Date(eventinfo.data.to);

        // Format the date and time as required by MySQL (YYYY-MM-DD HH:MM:SS)
        // const formattedFromDateTime = dateFromDate ? dateFromDate.toISOString().slice(0, 19).replace('T', ' ') : '';
        // const formattedToDateTime = toFromDate ? toFromDate.toISOString().slice(0, 19).replace('T', ' ') : '';

        const formattedFromDateTime = format(dateFromDate, 'yyyy-MM-dd HH:mm:ss');
        const formattedToDateTime = format(toFromDate, 'yyyy-MM-dd HH:mm:ss');
        //2024-04-22T22:15
        // Define the type for items in related_institute
        interface RelatedInstituteItem {
            value: string; // Define the type of the 'value' property
            label: string; // If there are other properties, define them too
        }

        eventinfo.eventTitle = eventinfo.data.event_title;
        eventinfo.eventMode = eventinfo.data.event_mode;
        eventinfo.activeEvent = eventinfo.data.status;
        eventinfo.dateFrom = formattedFromDateTime;
        eventinfo.toFrom = formattedToDateTime;
        eventinfo.registrationsActive = eventinfo.data.reg_status;
        eventinfo.eventType = eventinfo.data.event_type;
        eventinfo.registrationLink = eventinfo.data.reg_link;
        eventinfo.videoLink = eventinfo.data.video_link;
        eventinfo.presenterCategory = eventinfo.data.presenter_category;
        eventinfo.instituteId = eventinfo.data.related_institute;
//         eventinfo.instituteId = ( eventinfo.data.related_institute
//   .map((item: RelatedInstituteItem) => item.value) // Specify the type of 'item'
//   .join(',') );

            
//   eventinfo.instituteId = eventinfo.data.related_institute
//             ?.split(',')
//             ?.map((instId: any) => ({ value: instId }));
        eventinfo.eventDescription = eventinfo.data.event_desc;
        eventinfo.eventImage = eventinfo.data.event_image;


        

        

        return eventinfo;
    }

    static async save(id: string, type: string, data: any): Promise<any> {
        let result;

        alert(id)
        console.log(data)

        //result = await axiosClient().put(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`, data);
       

        return Promise.resolve(result);
    }

    // static toStudentDto(event: any) {
    //     //const userTypeCode = sessionStorage.getItem('user-type-code');
    //     event.profile = event.profile ?? {};
    //     event.underGradColleges = event.underGradColleges ?? [];
    //     event.schools = event.schools ?? [];
        

    //     event.user = {
    //         firstName: event.firstName,
    //         lastName: event.lastName,
    //         classCode: event.classCode,
    //         curriculumCode: event.curriculumCode,
    //         mobileCountryCode: event.mobileCountryCode,
    //         mobileNumber: event.mobileNumber,
    //         registeredEmailId: event.registeredEmailId,
    //         isMobileVerified: event.isMobileVerified,
    //         isEmailVerified: event.isEmailVerified,
    //         isPeerReviewed: event.isPeerReviewed,
    //         schoolId: event.schoolId,
    //         schoolGroupId: AddnewService.instituteGroupId,
    //     };

    //     return event;
    // }

    static toStudentEntity(student: any) {
        console.log(student);

        return { ...student };
    }

    static toStudentAdditionalInfoDto(studentAdditionalInfo: any) {
        

        return studentAdditionalInfo;
    }

    static async pageSave(id: string, pageName: string, data: any): Promise<AxiosResponse<any, any>> {
        // TODO: Refactor this to depend on teh nodes rather than the pages
        const pagesBasedOnStudentProfile = [
            'alumni-basic-info',
            'alumni-profile-picture',
            'alumni-undergraduate',
            'alumni-scholarship',
            'alumni-entrance-test',
            'alumni-postgraduate',
            'alumni-professional-work',
            'alumni-benefits',
            'alumni-mentoring-interests',
            'student-basic-info',
            'student-education',
            'student-entrance-exams',
            'admin-basic-info',
            'admin-additional-info',
            'admin-preferences',
        ];

        const url = pagesBasedOnStudentProfile.includes(pageName)
            ? `${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/profile`
            : `${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`;

        if (['alumni-benefits', 'alumni-mentoring-interests'].includes(pageName)) {
            axiosClient().put(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`, data);
        }

        return axiosClient().put(url, data);
    }

    static async SaveProfileImagePath(state: any): Promise<AxiosResponse<any, any>> {
        const imagePath = state.data.profile.profilePictureFilePath;
        const id = state.routeInfo.id;
        const data = { id: id, imagePath: imagePath };
        const url = `${process.env.REACT_APP_COMMON_API}/users/update-profile-image-path`;
        return axiosClient().post(`${url}`, data);
    }

    static getStudentPassOutYear = (student: any) => student.profile.passOutYear ?? 2000;
}
export default AddnewService;
