import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import TemplateListLayout from './TemplateListLayout';
import { getInstituteProgramDomain } from '../../../library/Service/domainService';
import SessionContext from '../../../library/Core/SessionContext';
import { GlobalContext } from '../../../library/Core/GlobalContext';

const TemplateList = () => {
    const navigate = useNavigate();
    const { globalState } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
       
    } as any);

    const { domain, internal, routeInfo } = useLoaderData() as State;


        

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <TemplateListLayout>{!state.flags.isDataLoading && <PageBuilder />}</TemplateListLayout>
        </SmartContext.Provider>
    );
};

export default TemplateList;