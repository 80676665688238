import React, { useContext } from 'react';
import moment from 'moment';
import { SmartContext } from '../../../library/Core/SmartContext';

const SendEmailModal = ({ data }: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const showFlag = state?.modalFlags?.showEmailModal;
    console.log('state?.modalFlags');
    console.log(state?.modalFlags);
    const {
        alumniData,
        options,
        handleNextPreviewAdminSelection,
        errors,
        sendingReason,
        handleSendingReasonChange,
        comment,
        handleCommentChange,
        communicationChannel,
        handleCommunicationChannelChange,
        alumni,
        getSentHistory,
        valuablePage,
    } = data;
    return (
        <div
            id="send-email"
            className={`modal fade ${showFlag ? 'show' : ''}`} // removed vh-100 and show class
            tabIndex={-1}
            role="dialog"
            style={{ display: showFlag ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-dialog-centered max-600 mx-auto">
                <div className="modal-content">
                    <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Send Profile
                        </h5>
                        <button
                            type="button"
                            className="btn-close btn-close-small"
                            onClick={() => {
                                dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showEmailModal' } });
                            }}
                            aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <form className="needs-validation" noValidate>
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <label className="form-label">Name</label>
                                    <input
                                        type="text"
                                        className="form-control flex-1"
                                        id="alumni_name"
                                        value={alumniData?.AlumniName}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Mobile</label>
                                    <input
                                        type="text"
                                        className="form-control flex-1"
                                        id="alumni_mobile"
                                        value={alumniData?.AlumniMobile}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0">
                                        <label className="form-label">Last Sent</label>
                                        <div className="form-last-sent">
                                            {alumniData?.LastSentDate ? (
                                                <>
                                                    <a href="javascript:;" onClick={() => getSentHistory(alumni)}>
                                                        {alumniData?.ProfileSendUsers.length} users
                                                    </a>{' '}
                                                    on {moment(alumniData?.LastSentDate).format('DD-MMM-YYYY')}
                                                </>
                                            ) : (
                                                <span>NA</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">User ID</label>
                                    <input
                                        type="text"
                                        className="form-control flex-1"
                                        id="alumni_user_id"
                                        value={alumniData?.AlumniUserId}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Email</label>
                                    <input
                                        type="text"
                                        className="form-control flex-1"
                                        id="alumni_email"
                                        value={alumniData?.AlumniEmail}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">Reason for sending</label>
                                    <div className="row g-2">
                                        <div className="col">
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                id="sending_reason"
                                                value={sendingReason}
                                                onChange={(e) => handleSendingReasonChange(e.target.value)}>
                                                {!valuablePage && (
                                                    <option value="" disabled>
                                                        Select Sending Reason
                                                    </option>
                                                )}
                                                {options?.map((option: any) => (
                                                    <option value={option.code} key={option.code}>
                                                        {option.value}
                                                    </option>
                                                ))}
                                            </select>
                                            {/* {errors.sendingReason && <div className="error-message">{errors.sendingReason}</div>} */}
                                            <div className="error-message">{errors.sendingReason}</div>
                                        </div>
                                        <div className="col-auto">
                                            <a onClick={() => getSentHistory(alumni)} className="btn-log" href="javascript:;">
                                                View Send History
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <label htmlFor="skills" className="form-label m-0 mb-1 font-16 font-500">
                                            Comment
                                        </label>
                                    </div>
                                    <textarea
                                        className="form-control"
                                        id="comment"
                                        style={{ height: '100px' }}
                                        value={comment}
                                        onChange={(e) => handleCommentChange(e.target.value)}
                                        placeholder="Ex: The achievement is a rare one, and worth highlighting."></textarea>
                                    {/* {errors.comment && <div className="error-message">{errors.comment}</div>} */}
                                    <div className="error-message">{errors.comment}</div>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <label htmlFor="skills" className="form-label m-0 mb-1 font-16 font-500">
                                            Communication channel
                                        </label>
                                    </div>
                                    <div className="row g-3">
                                        <div className="col-auto">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input modal-custom-radio ms-0"
                                                    type="radio"
                                                    name="communication_channel"
                                                    id="communication_channelRadio1"
                                                    value="Only_Email"
                                                    checked={communicationChannel === 'Only_Email'}
                                                    onChange={(e) => handleCommunicationChannelChange(e.target.value)}
                                                />{' '}
                                                <label className="form-check-label" htmlFor="inlineRadio1">
                                                    Only Email
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input modal-custom-radio ms-0"
                                                    type="radio"
                                                    disabled={sendingReason === '6'}
                                                    checked={communicationChannel === 'Only_WhatsApp'}
                                                    name="communication_channel"
                                                    id="communication_channelRadio2"
                                                    value="Only_WhatsApp"
                                                    onChange={(e) => handleCommunicationChannelChange(e.target.value)}
                                                />
                                                <label className="form-check-label" htmlFor="inlineRadio2">
                                                    Only WhatsApp
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input modal-custom-radio ms-0"
                                                    type="radio"
                                                    disabled={sendingReason === '6'}
                                                    name="communication_channel"
                                                    checked={communicationChannel === 'Both_Email_WA'}
                                                    id="communication_channelRadio3"
                                                    value="Both_Email_WA"
                                                    onChange={(e) => handleCommunicationChannelChange(e.target.value)}
                                                />
                                                <label className="form-check-label" htmlFor="inlineRadio3">
                                                    Both Email + WA
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {errors.communicationChannel && <div className="error-message">{errors.communicationChannel}</div>} */}
                                    <div className="error-message">{errors.communicationChannel}</div>
                                </div>
                            </div>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-auto">
                                    {/* <button type="button" className="btn btn-cancel" data-bs-dismiss="modal">Cancel</button> */}
                                </div>
                                <div className="col-auto">
                                    <button
                                        type="button"
                                        className="btn btn-green max-135"
                                        onClick={() => handleNextPreviewAdminSelection()}>
                                        <span>Next</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendEmailModal;
