import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { SmartContext } from '../../../library/Core/SmartContext';
import { formatFullName, getDomainValueForCode, getTimeAgo, isEmpty, isSchoolAdmin, isSuperAdmin } from '../../../library/Core/SmartFunctions';
import AlbumListingService from './AlbumListingService';
import useAlbum from './useAlbum';
import SessionContext from '../../../library/Core/SessionContext';
import ReactImageVideoviewer from 'react-image-video-viewer'

const AlbumListingGridControl = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);
    // let box = ReactImageVideoviewer;
    const { likeAlbum, saveAlbum } = useAlbum();
    const filteredAlbums = state.data.filteredData;
    const navigate = useNavigate();
    const [showAlbum, setShowAlbum] = useState(false);
    const [images, setImages] = useState([] as any);

    const handleSaveAlbum = (event: any, id: string) => {
        event.preventDefault();
        saveAlbum(id);
    };

    const handleLikeAlbum = (event: any, id: string) => {
        event.preventDefault();
        likeAlbum(id);
    };

    const extractVideoId = (url: string) => {
        if (url === undefined) return '';
        const videoIdMatch = url?.match(/(?:youtu.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/);
        return videoIdMatch ? videoIdMatch[1] : '';
    };

    const handleShowAlbum = async (id: string) => {
        const response = await AlbumListingService.getById(id);
        console.log(response.data?.albumContent, " ==-=-==== response.data?.albumContent -=-=-=-=-")
        // const selectedAlbumImages = response.data?.albumContent?.map((content: any) => ({ src: content?.link }));
        const selectedAlbumImages = response.data?.albumContent?.map((content: any) => {
            let link_type = 'photo';
            if ( content?.link_type == 'video' ) {
                const videoId = extractVideoId(content?.youtube_link);
                content.link = `https://www.youtube.com/embed/${videoId}`;
                link_type = 'video';
            }
            return {
                url: content?.link,
                type: link_type,
                altTag: 'No Image'
            }
        });

        console.log(selectedAlbumImages, " selectedAlbumImages ")
        setImages(selectedAlbumImages);
        setShowAlbum(true);
    };

    // const userTypeCode = sessionStorage.getItem('user-type-code');
    const userTypeCode = sessionState?.userTypeCode;

    return (
        <>
            <div
                className="flex-1 polarcontsec tab-content mt-38 aos-init aos-animate"
                id="myTabContent"
                data-aos="fade-left"
                data-aos-delay="1200">
                <div className="tab-pane fade show active" id="AllAlbums" role="tabpanel" aria-labelledby="AllAlbums-tab">
                    <h3 className="font-18 font-500 mb-3 d-none d-md-block" id="ucc">
                        <span id="ucount">{filteredAlbums.length}</span> Memories Found
                    </h3>
                    {filteredAlbums.length > 0 && (
                        <div className="row allAlbumsBlk" id="loadmore">
                            {filteredAlbums?.map((album: any, index: number) => (
                                <div key={album.id} className="col-lg-6 col-md-12 album_blk_parent pfusers">
                                    <div className="album_blk d-flex flex-column h-100">
                                        <div
                                            className="album_imgblk position-relative"
                                            // style={{ height: '200px' }}
                                            role="button"
                                            onClick={() => handleShowAlbum(album.id)}>
                                            <span className="album_badge font-16 font-500 text-white d-inline-block position-absolute">
                                                Added by {getDomainValueForCode(album.userTypeCode, 'USER_TYPE_CODE', state)}
                                            </span>

                                            {/* <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_albumimg.jpg`} alt="" /> */}
                                            <img src={album.link ?? `${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_albumimg.jpg`} alt="" />

                                            <div className="album_imgvdo position-absolute"></div>
                                        </div>
                                        <div className="album_contblk d-flex flex-wrap justify-content-between align-items-start">
                                            <div className="Wrdrow">
                                                <a style={{ textDecoration: 'none', cursor: 'pointer' }}>
                                                    <h3 className="font-20 font-500 mb-2 text-start">{album.title}</h3>
                                                </a>
                                                {album.year && (
                                                    <p className="text-gray-64 font-14 font-500 mb-2 text-start">
                                                        Taken in - {`${album.month ?? ''}  ${album.year ?? ''}`}
                                                    </p>
                                                )}
                                                <span className="border-lightgray mb-2"></span>
                                                <a href="javascript:void(0)" className="d-flex align-items-start person_detls text-decoration-none">
                                                    <img
                                                        src={`${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_male.svg`}
                                                        width="32"
                                                        className="rounded-circle mt-1 me-2"
                                                    />

                                                    <div className="lstperdetails text-start">
                                                        <p className="mb-1 font-14 font-500 line-height-18 text-black text-wrap text-break">
                                                            <span className="font-16">
                                                                {formatFullName(album.studentFirstName, album.studentLastName)}
                                                            </span>
                                                            , <em>Class of {album.studentPassOutYear}</em>
                                                        </p>

                                                        {!isEmpty(album.companyName) && (
                                                            <p className="font-14 fst-italic font-500 text-gray-64 mb-0">
                                                                Working As{' '}
                                                                <span className="font-600 text-black">
                                                                    {album.studentJobDesignation} At {album.companyName}
                                                                </span>
                                                            </p>
                                                        )}

                                                        {isEmpty(album.companyName) && !isEmpty(album.universityName) && (
                                                            <p className="font-14 fst-italic font-500 text-gray-64 mb-0">
                                                                Pursuing UG At{' '}
                                                                <span className="font-600 text-black">{album.universityName}</span>
                                                            </p>
                                                        )}
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="album_shareblk">
                                                {album.studentId != sessionState?.id && !isSchoolAdmin() && !isSuperAdmin() && (
                                                    <a
                                                        className={`btn btnicon listblk_icon me-2 me-sm-0 circle-effect ${
                                                            album.isFavorite ? 'btn-dark-blue' : ''
                                                        }`}
                                                        id="MakeSave_34"
                                                        role="button"
                                                        onClick={(event) => handleSaveAlbum(event, album.id)}>
                                                        <span
                                                            className={`icon-ribbon-invert font-18 icon ${
                                                                album.isFavorite ? 'text-white' : ''
                                                            }`}
                                                            id="SaveIcon_34"></span>
                                                    </a>
                                                )}
                                                
                                                {album.studentId != sessionState?.id && !isSchoolAdmin() && !isSuperAdmin() && (
                                                <a
                                                    className={`btn btnicon listblk_icon circle-effect ${
                                                        album.isLiked ? 'btn-dark-blue' : ''
                                                    }`}
                                                    id="MakeLike_34"
                                                    role="button"
                                                    onClick={(event) => handleLikeAlbum(event, album.id)}>
                                                    <span
                                                        className={`icon-like font-18 icon ${album.isLiked ? 'text-white' : ''}`}
                                                        id="LikeIcon_34"></span>
                                                </a>
                                                )}
                                                
                                            </div>
                                        </div>
                                        <div className="albumblk_footer d-flex justify-content-between align-items-center mt-auto">
                                            <p className="m-0 font-14 font-500 text-gray-64">
                                                {/* <em>Posted 1 month ago</em> */}
                                                <em>Posted {getTimeAgo(album.createdAt)}</em>
                                                {/* <em>Posted {moment(album.createdAt).fromNow()}</em> */}
                                            </p>
                                        </div>
                                    </div>
                                    <div id="success_save_34"></div>
                                </div>
                            ))}
                        </div>
                    )}
                    {filteredAlbums.length === 0 && (
                        <div className="no_listed_items_blk">
                            <div>
                                <div className="max-480 text-center mx-auto">
                                    <img
                                        className="custimg float_horizontal_animate"
                                        src={`${process.env.REACT_APP_IMAGE_BASEPATH}/no_album_img.svg`}
                                        alt=""
                                    />
                                    <p className="mt-2 mb-4 font-18 font-400">
                                        Are you also suffering from NOSTALGIA? Well, we got good news! Share your memories through your old
                                        photos and let everyone suffer with you!
                                    </p>
                                    {userTypeCode != 'STUDENT' &&  !isSchoolAdmin() && (
                                        <a
                                            onClick={() => navigate('/memories/new')}
                                            className="btn btn-greenm btn-greenm252 text-white mx-auto d-block height-40">
                                            Create Album
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* {showAlbum && <Lightbox open={showAlbum} close={() => setShowAlbum(false)} slides={images} />} */}
            {showAlbum && <ReactImageVideoviewer 
            open={showAlbum}
            close={() => setShowAlbum(false)} 
            data={images}
            startIndex={0}
            showResourceCount={true}
            onCloseCallback={() => setShowAlbum(false)}
            // onNavigationCallback={(currentIndex) =>
            //     console.log(`Current index: ${currentIndex}`)
            // }
             />}

            
            {/* <ReactImageVideoviewer
                data={[
                    {
                    url: "https://placekitten.com/450/300",
                    type: "photo",
                    altTag: "some image",
                    },
                    {
                    url: "https://www.youtube.com/embed/ScMzIvxBSi4",
                    type: "video",
                    title: "some video",
                    },
                    {
                    url: "https://file-examples.com/storage/fea9880a616463cab9f1575/2017/04/file_example_MP4_480_1_5MG.mp4",
                    type: "video",
                    poster: "posterImg.jpg",
                    },
                    {
                    url: "https://www.youtube.com/embed/ScMzIvxBSi4",
                    type: "video",
                    title: "some other video",
                    },
                ]}
                startIndex={0}
                showResourceCount={true}
                // onCloseCallback={this.callbackFunction}
                // onNavigationCallback={(currentIndex) =>
                //     console.log(`Current index: ${currentIndex}`)
                // }
                /> */}
            {/* <ReactImageVideoviewer
                data={[
                    {
                    url: "https://placekitten.com/450/300",
                    type: "photo",
                    altTag: "some image",
                    },
                    {
                    url: "https://www.youtube.com/embed/ScMzIvxBSi4",
                    type: "video",
                    title: "some video",
                    },
                    {
                    url: "https://file-examples.com/storage/fea9880a616463cab9f1575/2017/04/file_example_MP4_480_1_5MG.mp4",
                    type: "video",
                    poster: "posterImg.jpg",
                    },
                    {
                    url: "https://www.youtube.com/embed/ScMzIvxBSi4",
                    type: "video",
                    title: "some other video",
                    },
                ]}
                startIndex={0}
                showResourceCount={true}
                // onCloseCallback={this.callbackFunction}
                // onNavigationCallback={(currentIndex) =>
                //     console.log(`Current index: ${currentIndex}`)
                // }
                /> */}
        </>
    );
};

export default AlbumListingGridControl;
