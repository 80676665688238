import { useContext, useState, useEffect } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { handleControlValueChange } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const DateTimeSelectorControl = (args: SimpleFormControlArguments) => {
    
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };

    const dateTimeControl = control.controlGroup[0];
    const dateTimeDataKey = `${parentDataKey}.${dateTimeControl.id}`;
    // const dateTimeValue = state.data[dateTimeDataKey] || '';
    const dateTimeValue = state.data[parentDataKey][dateTimeControl.id] || '';
   // const dateTimeValue = dateTimeDataKey || '';

    const [dateTimeOption, setDateTimeOption] = useState('custom');

    useEffect(() => {
        if (dateTimeValue) {
            setDateTimeOption(dateTimeOption);
        } else {
            setDateTimeOption('');
        }
    }, [dateTimeValue]);

    const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setDateTimeOption('custom');
        handleControlValueChange({
            control: dateTimeControl,
            value: value,
            dataKey: dateTimeDataKey,
            parentDataKey,
            state,
            dispatch,
        });
    };

    return (
        <div className="mb-3">
            {control.props?.label && (
                <label htmlFor={dateTimeControl.id} className="form-label">
                    {`${control.props.label} `}
                </label>
            )}
            <input
                id={dateTimeControl.id}
                type="datetime-local" // Use datetime-local input type
                className="form-control"
                value={dateTimeValue}
                onChange={handleDateTimeChange}
            />
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </div>
    );
};

export default DateTimeSelectorControl;
