import '../../../styles/css/alumni.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
//const schoolSettings = localStorage.getItem('school_settings');

//const schoolName = parsedSchoolSettings?.schoolName ?? '';
const dummy_female = `${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_female.svg`;
const dummy_male = `${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_male.svg`;

const WelcomeLayout = (props: any) => {
    const [StudentId, setStudentId] = useState<any>('');
    const [name, setname] = useState('');
    const [schoolSettings, setSchoolSettings] = useState<any>(localStorage.getItem('school_settings'));
    const parsedSchoolSettings = schoolSettings ? JSON.parse(schoolSettings) : null;
    const [profileStatus, setprofileStatus] = useState<any>('');
    const [schoolName, setSchoolName] = useState(parsedSchoolSettings?.schoolName);
    useEffect(() => {
        const firstName = sessionStorage.getItem('user-first-name') ? sessionStorage.getItem('user-first-name') : '';
        const lastName = sessionStorage.getItem('user-last-name') ? sessionStorage.getItem('user-last-name') : '';
        setname(firstName + ' ' + lastName);
        const userState = sessionStorage.getItem('user-profile-status-code') ? sessionStorage.getItem('user-profile-status-code') : '';
        setprofileStatus(userState);
        const userData = JSON.parse(sessionStorage.getItem('user') ?? '');
        setStudentId(userData.id);
    }, [sessionStorage.getItem('user-first-name')]);

    const navigate = useNavigate();
    return (
        <div className="main flex-1">
            <DebugBarControl/>
            <section className="notificationSection">
                <div className="container max-1140 px-lg-0">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius h-100 p-0 overflow-hidden">
                                {profileStatus != 'COMPLETE' && profileStatus != null && profileStatus != '' && (
                                    <div className="relevantAlumniMatchWrap">
                                        <div className="relevantAlumniMatch">
                                            <div className="row g-2 g-md-4 align-items-center">
                                                <div className="col-md">
                                                    <p>
                                                        {name != ' ' ? name + ',' : ''} Your Profile Seems Dry! Add your information to
                                                        match with relevant Alumni
                                                    </p>
                                                </div>
                                                <div className="col-md-auto">
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            sessionStorage.setItem('user-status-code', 'PROFILE_IN_PROGRESS');
                                                            navigate(
                                                                '/alumni/' +
                                                                    StudentId +
                                                                    '/' +
                                                                    sessionStorage.getItem('user-profile-status-code')
                                                            );
                                                        }}
                                                        className="btn-complete-now">
                                                        Complete Now
                                                    </button>
                                                    {/* <Link className="btn-complete-now" to={'/alumni/'+StudentId+"/"+profileStatus}>Complete Now</Link> */}
                                                    {/* <a href="javascript:;" className="icon-cross btn-close" id="closecm"></a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="m-w-90 welcome_pg">
                                    <div className="d-flex flex-wrap justify-content-between align-items-center mb-1 py-3 py-sm-4">
                                        <h3
                                            className="font-35 font-600 mb-0 mt-2 mt-sm-3 block-title max-700 px-0 aos-init aos-animate"
                                            data-aos="fade-left"
                                            data-aos-delay="300">
                                            Welcome to {schoolName} Alumni Network!
                                        </h3>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="pb-2">
                                                <p className="font-18 font-500 mb-0 text-black px-0">
                                                    Before you get started, please send a profile validation request to your school
                                                    management.You can also request some of the existing alumni who can confirm your
                                                    association with the school.
                                                </p>
                                                <button
                                                    className="btn-green height-40 w-max-content text-white"
                                                    onClick={() => {
                                                        navigate(`/alumni/${StudentId}/profile-picture`);
                                                    }}>
                                                    <span>Add More Info</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <input type="hidden" name="ncount" id="ncount" value="860"> */}
                                    <div className="row mx-0 mb-0 pb-5 mt-40 JobInternshipListing">
                                        <div className="col-12 px-0">
                                            <div className="max-850 mx-auto">
                                                <div className="row uni_profiles_100 mb-3 mb-sm-4">
                                                    <div className="col-12">
                                                        <p className="font-24 font-500 text-dark-blue mb-3">Your school management</p>
                                                    </div>

                                                    {props.alumniList.map((data: any, id: number) => {
                                                        var image = data.image
                                                            ? data.image
                                                            : data.gender == 'MALE'
                                                            ? dummy_male
                                                            : dummy_female;
                                                        var name =
                                                            (data.first_name != null ? data.first_name : '') +
                                                            ' ' +
                                                            (data.last_name != null ? data.last_name : '');
                                                        if (data.user_type != 'ALUMNI') {
                                                            return (
                                                                <div key={data.uid}>
                                                                    <div className="col-12">
                                                                        <div className="uni_profiles_sec d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row">
                                                                            <div className="d-flex justify-content-start align-items-center pe-0 pe-md-3 mb-2 mb-md-0 uniprof_tab_align">
                                                                                <img
                                                                                    src={image}
                                                                                    alt={name}
                                                                                    className="me-2 rounded-circle alumni-welcome-image-logo"
                                                                                />
                                                                                <div>
                                                                                    <p className="font-16 font-500 mb-0 text-black">
                                                                                        {name},{' '}
                                                                                        <span className="font-14 fst-italic">
                                                                                            {data.user_type}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex uniprof_btntab_align uniprof_btnmbl_align paddings-67">
                                                                                {data.requestSent ? (
                                                                                    <button
                                                                                        className="btn-blue height-40 w-max-content text-white"
                                                                                        disabled>
                                                                                        Sent
                                                                                    </button>
                                                                                ) : (
                                                                                    <button
                                                                                        className="btn-blue height-40 w-max-content text-white"
                                                                                        onClick={() => {
                                                                                            props.sendRequest(data.uid,data.user_type);
                                                                                        }}>
                                                                                        <span>Send Request</span>
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 mt-20 mb-20">
                                                                        <div className="border-gray-e2"></div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>

                                                <div className="row uni_profiles_100">
                                                    <div className="col-12">
                                                        <p className="font-24 font-500 text-dark-blue mb-3">
                                                            Alumni who may be knowing you
                                                        </p>
                                                    </div>

                                                    {props.alumniList.map((data: any, id: number) => {
                                                        if (data.user_type == 'ALUMNI') {
                                                            var status = data.is_working == 0 ? 'Studing' : 'Working';
                                                            var uni_work = data.is_working == 0 ? data.university_name : data.company_name;
                                                            var image = data.image
                                                                ? data.image
                                                                : data.gender == 'MALE'
                                                                ? dummy_male
                                                                : dummy_female;
                                                            var name =
                                                                (data.first_name != null ? data.first_name : '') +
                                                                ' ' +
                                                                (data.last_name != null ? data.last_name : '');
                                                            return (
                                                                <div key={data.uid}>
                                                                    <div className="col-12">
                                                                        <div className="uni_profiles_sec d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row">
                                                                            <div className="d-flex justify-content-start align-items-center pe-0 pe-md-3 mb-2 mb-md-0 uniprof_tab_align">
                                                                                <a
                                                                                    href="javascript:;"
                                                                                    className="d-flex justify-content-start align-items-start pe-0 pe-md-3 mb-2 mb-md-0 uniprof_tab_align text-decoration-none">
                                                                                    <img
                                                                                        src={image}
                                                                                        alt={name}
                                                                                        className="me-2 rounded-circle alumni-welcome-image-logo"
                                                                                    />
                                                                                    <div>
                                                                                        <p className="font-16 font-500 mb-0 text-black">
                                                                                            {name},
                                                                                            <span className="font-14 fst-italic">
                                                                                                 {data.passoutYear}
                                                                                            </span>
                                                                                        </p>

                                                                                        <p className="font-14 fst-italic font-500 text-gray-64 mb-0">
                                                                                            {status} At{' '}
                                                                                            <span className="font-600 text-black">
                                                                                                {uni_work}{' '}
                                                                                            </span>
                                                                                        </p>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                            <div className="d-flex uniprof_btntab_align uniprof_btnmbl_align paddings-67">
                                                                                {data.requestSent ? (
                                                                                    <button
                                                                                        className="btn-blue height-40 w-max-content text-white"
                                                                                        disabled>
                                                                                        Sent
                                                                                    </button>
                                                                                ) : (
                                                                                    <button
                                                                                        className="btn-blue height-40 w-max-content text-white"
                                                                                        onClick={() => {
                                                                                            props.sendRequest(data.uid,data.user_type);
                                                                                        }}>
                                                                                        <span>Send Request</span>
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 mt-20 mb-20">
                                                                        <div className="border-gray-e2"></div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    })}

                                                    <div id="loadmore"></div>
                                                    {/* <input type="hidden" name="start" id="start" value="0"> */}
                                                    {/* <input type="hidden" name="end" id="end" value="10"> */}
                                                    {/* <input type="hidden" name="total" id="total" value="859"> */}
                                                    {/* <div id="showhideloadmore">
                                                <a href="javascript:;" onclick="loadmore();" className="load_more_btn"><img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/load_more_icon.jpg`}>Load More</a>
                                            </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default WelcomeLayout;
