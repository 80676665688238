import { useContext, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import HeaderControl from '../../features/alumni/_common/HeaderControl';
import SessionContext from '../../library/Core/SessionContext';
import ErrorPage from '../../library/SimpleControls/ErrorPage';
import PageFooterControl from '../../library/SimpleControls/PageFooterControl';
import MenuBuilder from '../../routes/MenuBuilder';
import { isSchoolAdmin } from '../../library/Core/SmartFunctions';

function StandardLayout() {
    // const gUserStatusCode = useAppContext()?.getSelectedInstitute()?.userStatusCode;
    const { sessionState } = useContext(SessionContext);
    const [userStatusCode, setUserStatusCode] = useState(sessionState?.userStatusCode ?? 'INACTIVE');
    // console.log(sessionState?.pursuingEducationLevelCode != null, " typeof sessionState?.pursuingEducationLevelCode ")
    const enableHeader =
        (userStatusCode === 'ACTIVE' ||
            sessionState?.userStatusCode == 'ACTIVE' ||
            sessionStorage.getItem('is-impersonated-session') === 'YES' ||
            isSchoolAdmin()) ??
        false;
    // console.log(userStatusCode, " userStatusCode *********************************")
    const handleUpdateUserStatusCode = () => {
        setUserStatusCode('ACTIVE');
    };

    const handleNavigateToHome = () => {
        // resetErrorBoundary();
        return <ErrorPage />;
    };

    const handleError = (error: any, errorInfo: any) => {
        // Log the error to the console
        // console.error('Logging error:', error, errorInfo);
    };

    return (
        <div className="d-flex flex-column min-vh-100">
            {enableHeader && (
                <header>
                    <HeaderControl />
                    <MenuBuilder />
                </header>
            )}
            <ErrorBoundary fallbackRender={handleNavigateToHome} onReset={(details) => {}} onError={handleError}>
                <Outlet />
            </ErrorBoundary>
            <PageFooterControl />
            {/* https://stackoverflow.com/questions/56660153/how-to-listen-to-localstorage-value-changes-in-react */}
            <button style={{ display: 'none' }} onClick={handleUpdateUserStatusCode} id="updateUserStatusCode">
                Hidden Button
            </button>
        </div>
    );
}

export default StandardLayout;
