import { useContext, useEffect } from 'react';
import { Outlet, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
// import '../../../styles/css/alumni-directory.css';
import { ErrorBoundary } from 'react-error-boundary';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { addUserSpecificDomains } from '../../../library/Service/domainService';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import ErrorPage from '../../../library/SimpleControls/ErrorPage';
import MenuBuilder from '../../../routes/MenuBuilder';
import HeaderControl from '../../alumni/_common/HeaderControl';
import { getProfileView } from '../../alumni/alumni-profile/service/student-profile-view.service';
import ProfileHeader from './sections/ProfileHeader';
import PageFooterControl from '../../../library/SimpleControls/PageFooterControl';
import SessionContext from '../../../library/Core/SessionContext';

const StudentProfile = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState } = useContext(SessionContext);
    const { id, pageName } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { ROUTER_NAVIGATE: navigate },
    } as any);

    const { formConfig, data } = useLoaderData() as State;

    let tabConfig = [
        { id: 'preferences', label: 'Preference', route: 'preference' },
        { id: 'parentInformation', label: 'Parent Information', route: 'parent-info' },
        { id: 'educationDetails', label: 'Education Details', route: 'education' },
        { id: 'entranceExams', label: 'Entrance Exams', route: 'entrance-exams' },
        { id: 'extraCurricular', label: 'Extra Curricular', route: 'extra-curricular' },
    ];

    let currentTab: any = tabConfig.filter((tab) => {
        const targetPath = `/student/${id}/profile/${tab.route}`;
        return location.pathname == targetPath;
    });

    if (typeof currentTab[0]?.id == 'undefined' || currentTab[0]?.id == '') {
        // currentTab[0]['id'] = 'preferences';
        currentTab = [
            {
                id: 'preferences',
            },
        ];
    }

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data,
                domain,
                routeInfo: { id, pageName },
                internal: { model: getProfileView({ data, domain, context: sessionState }) },
            },
        });
    }, []);

    const handleTabNavigation = (tab: any) => {
        const targetPath = `/student/${id}/profile/${tab.route}`;
        if (location.pathname !== targetPath) {
            navigate(targetPath, { replace: true });
        }
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'activeTab', value: tab.id } });
    };

    // Conditionally modify the tabConfig based on the student's class
    if (state?.data?.classCode == '8') {
        // Show/hide specific tabs for 8th class
        // Example: Hide the 'Preferences' tab
        tabConfig = tabConfig.filter((tab) => tab.id !== 'entranceExams' && tab.id !== 'educationDetails');
    } else if (state?.data?.classCode == '9' || state?.data?.classCode == '10') {
        // Show/hide specific tabs for 9th class
        // Example: Hide the 'Parent Information' tab
        tabConfig = tabConfig.filter((tab) => tab.id !== 'educationDetails');
    }

    if (
        sessionStorage.getItem('user-type-code') === 'STUDENT' &&
        sessionStorage.getItem('user-profile-status-code') === 'PREFERENCES_INCOMPLETE'
    ) {
        window.location.href = `/student/${sessionStorage.getItem('user-id')}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    const handleNavigateToHome = () => {
        // resetErrorBoundary();
        return <ErrorPage />;
    };

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            {!state?.flags.isDataLoading && (
                <>
                    <header>
                        <HeaderControl />
                        <MenuBuilder />
                    </header>
                    <ErrorBoundary fallbackRender={handleNavigateToHome} onReset={(details) => {}}>
                        <div className="alumni-directory-parent-wrap">
                            <ProfileHeader />
                            <section className="alumni_profile_detail_wrap container-fluid bg-gray-f3" id="alumni_profile_detail_wrap">
                                <div className="max-1140 mx-auto alumni_profile_detail">
                                    <div className="alumni_profile_brife_tab">
                                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                            {tabConfig.map((tab: any) => (
                                                <li key={tab.id} className="nav-item" role="presentation">
                                                    <button
                                                        className={`nav-link ${state?.internal?.activeTab === tab.id ? 'active' : ''}`}
                                                        id={`pills-${tab.id}-tab`}
                                                        type="button"
                                                        role="tab"
                                                        onClick={() => handleTabNavigation(tab)}>
                                                        {tab.label}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <Outlet />
                                </div>
                            </section>
                            <DebugBarControl />
                        </div>
                    </ErrorBoundary>
                    <PageFooterControl />
                </>
            )}
        </SmartContext.Provider>
    );
};

export default StudentProfile;
