import axios from 'axios';
import { isEmpty } from '../Core/SmartFunctions';
// https://dev.to/mihaiandrei97/jwt-authentication-using-axios-interceptors-55be

export function axiosClient() {
    const client = axios.create();

    client.interceptors.request.use(
        (config) => {
            if (config.authorization !== false) {
                let token = sessionStorage.getItem('user-jwt-token');
                if ( isEmpty (token) ) {
                    token = localStorage.getItem('user-jwt-token');
                }
                if (token) config.headers.Authorization = 'Bearer ' + token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    client.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            let errorMessage;

            if (error.response) {
                errorMessage = error.response.data.message || 'An error occurred while processing your request.';
            } else if (error.request) {
                errorMessage = 'The server did not respond to the request.';
            } else {
                errorMessage = 'An error occurred while setting up the request.';
            }

            console.error('Error:', errorMessage);

            return Promise.reject({
                isError: true,
                message: errorMessage,
                status: error.response?.status,
                data: error.response?.data,
            });
        }
    );

    return client;
}
