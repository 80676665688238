import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import { handleCompleteLater, handleNext, onCurrentJobSelectionChange } from './JobOutcomeActions';
import JobOutcomeLayout from './JobOutcomeLayout';

const JobOutcome = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            NEXT: handleNext,
            I_LL_DO_THIS_LATER: handleCompleteLater,
            ROUTER_NAVIGATE: navigate,
            isCurrent: onCurrentJobSelectionChange,
            REDIRECT: setRedirectUrl
            //startDate: onStartDateChange,
            //endDate: onEndDateChange,
        },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    // const getSocialProfileLinks = () => {
    //     return (domain as any)?.get('SOCIAL_NETWORK_CODE').map((item: any) => ({ socialNetworkCode: item.code, link: '' }));
    // };

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        data['jobOutcome'] = data['jobOutcome'] || {};
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <JobOutcomeLayout>{!state.flags.isDataLoading && <PageBuilder />}</JobOutcomeLayout>
        </SmartContext.Provider>
    );
};

export default JobOutcome;
