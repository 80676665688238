import { useContext, useEffect } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import DashboardReportControl from './controls/DashboardReportControl';
import NewsUpdatesControl from './controls/NewsUpdatesControl';
import ProfileActionsControl from './controls/ProfileActionsControl';
import SummaryControl from './controls/SummaryControl';

const Dashboard = () => {
    const { id, pageName } = useParams();
    const { globalState } = useContext(GlobalContext);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        data: [],
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { formConfig, data } = useLoaderData() as State;

    useEffect(() => {
        const domain = globalState.domain;
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: { ...data, pageDomain: data.domain, summaryCounts: data.summaryCounts, selectedTab: 'ACTIVE_LIST' },
                pageDomain: data.domain,
                domain,
                routeInfo: { id, pageName },
            },
        });
    }, []);

    if(sessionStorage.getItem('user-type-code') === 'STUDENT' && sessionStorage.getItem('user-profile-status-code') === 'PREFERENCES_INCOMPLETE')
    {
        window.location.href = `/student/${sessionStorage.getItem('user-id')}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            {!state?.flags.isDataLoading && (
                <div className="main flex-1">
                    <SummaryControl />
                    <section className="alumni_directory_details">
                        <div className="container max-1140 pt-3 pt-sm-5 px-lg-0 overflow-hidden myCampus active bothCampus">
                            <ProfileActionsControl />
                            <NewsUpdatesControl />
                            <DashboardReportControl
                                schoolDomainName={`FIELD_OF_STUDY_REPORT_BY_SCHOOL`}
                                schoolGroupDomainName={`FIELD_OF_STUDY_REPORT_BY_SCHOOL_GROUP`}
                                reportName={`Present Field of Study of Alumni`}
                                domainName={`FIELD_OF_STUDY_TYPE_CODE`}
                                color={`dark-blue`}
                            />
                            <DashboardReportControl
                                schoolDomainName={`JOB_FUNCTIONAL_AREA_REPORT_BY_SCHOOL`}
                                schoolGroupDomainName={`JOB_FUNCTIONAL_AREA_REPORT_BY_SCHOOL_GROUP`}
                                reportName={`Present Career of Alumni`}
                                domainName={`INDUSTRY_CODE`}
                                color={`cyan`}
                            />
                        </div>
                    </section>
                </div>
            )}
        </SmartContext.Provider>
    );
};

export default Dashboard;
