import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
//import { onMobileCountryCodeChange, onMobileNumberChange, twelthPassoutYear } from './CreateAlumniActions';
import Layout from './Layout';



const SpeakerInfo = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const { routeInfo, formConfig, data } = useLoaderData() as State;
    const queryParams = new URLSearchParams(window.location.search);
    const eventId = queryParams.get('id');
   
    if(!eventId){
        navigate(`/event/addnew`);
    }
    
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            SAVE: handleCreate,
            
        },
    } as any);

    function handleCreate(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();

        console.log(state['data'])
        if (!isFormValid(state, dispatch)) {
            return;
        }

        let eventAdd = `${process.env.REACT_APP_LEAD_SERVER}/marketevent/updatespeakerinfo`;
        

        axiosClient()
            .post(`${eventAdd}`, {
                eventId : eventId,
                speakerinfo: JSON.stringify(state['data']['speakerinfo']),
                
                 
             })
             .then((res: any) => {

                console.log(res.data.eventId)
                alert(res.data.message)

                if (!res.data.eventId) return alert('There is some issue in event management. Please try after sometime!');
                navigate(`/event/audience-category?id=${res.data.eventId}`);

            });

        
    }



    
    

    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: { speakerinfo: data },
                domain,
                routeInfo,
            },
        });
    }, []);

    // useEffect(() => {
    //     if(state.data?.user?.instituteId != undefined){
    //         // alert('Test')
    //         const fetchData = async () => {
    //             try {
    //                 const response = await axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/admin/get-programs-by-institute-id`, {
    //                     institute_id: state.data?.user?.instituteId,
    //                 });
    //                 const programs = response.data.data.programs;
    //                 let program_values = [];
    //                 for(let i = 0; i < programs.length;i++)
    //                 {
    //                     program_values[i] = {'label' : programs[i]['name'],'value': programs[i]['id']};
    //                 }
                    
    //                 dispatch({
    //                     type: 'SET_DOMAIN',
    //                     payload: { key: 'INSTITUTE_ATTENDED_PROGRAM_CODE', value: getinstituteList('INSTITUTE_ATTENDED_PROGRAM_CODE', program_values) },
    //                 });
    //             } catch (error) {
    //                 console.error('Error fetching program data:', error);
    //             }
    //         }

    //         fetchData();
    //     }
    // }, [state.data?.user?.instituteId]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
             
            <Layout>{!state.flags.isDataLoading && <PageBuilder />}</Layout>
        </SmartContext.Provider>
    );
};

export default SpeakerInfo;
