import { GlobalDispatchEvent } from './SmartTypes';

const globalReducer = (state: any, action: GlobalDispatchEvent) => {
    switch (action.type) {
        case 'INITIALIZE_APP':
            state.domain = action.payload.domain;
            state.userNameForSearch = action.payload.userNameForSearch;
            state.appSettings = action.payload.appSettings;
            state.isLoading = false;
            break;
        case 'SET_USER_NAME_FOR_SEARCH':
            state.userNameForSearch = action.payload;
            break;
        // case 'SET_AUTH_DATA':
        //     state.authData = action.payload;
        //     break;
        // case 'SET_USER_NAME_FOR_SEARCH':
        //     state.userNameForSearch = action.payload;
        //     break;
        // case 'SET_DOMAIN_DATA':
        //     state.domain = action.payload;
        //     break;
        // case 'SET_APP_SETTINGS':
        //     state.appSettings = action.payload;
        //     break;
        // case 'SET_LOADING_DONE':
        //     state.isLoading = false;
        //     break;
        case 'SET_USER_STATUS':
            state.userStatusCode = action.payload;
            break;
        case 'SET_USER_CONTEXT':
            state.context = action.payload;
            break;
        case 'SET_SELECTED_INSTITUTE':
            state.context.set('SELECTED_INSTITUTE', action.payload);
            break;
        default:
            throw new Error();
    }
};

export default globalReducer;
