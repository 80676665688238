const VerifyContactDetailsControl = (props: any) => {
    const handleClose = (event: any) => {
        event.preventDefault();
        props.setShowVerifyContactDetails(false);
    };

    const handleVerifyMobile = (event: any) => {
        event.preventDefault();
        props.setShowVerifyContactDetails(false);
        props.setShowVerifyMobile(true);
    };

    return (
        <>
            <div
                className="modal fade show"
                id="Verify_email_mobile"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                style={{ display: 'block' }}
                aria-modal="true"
                role="dialog">
                <div className="modal-dialog modal-dialog-centered max-700 mx-auto">
                    <div className="modal-content">
                        <div className="modal-header border-0 pt-5">
                            <h5 className="modal-title font-24 text-dark-blue text-center w-100">Verify your contact details</h5>
                            <button type="button" className="btn-close btn-close-small" aria-label="Close" onClick={handleClose}></button>
                        </div>
                        <div className="modal-body pt-0">
                            <div className="px-0 pb-4 pt-4">
                                <div className="d-flex justify-content-center mb-4">
                                    <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/varify.png`} alt="" />
                                </div>
                                <p className="text-center max-360 mx-auto mb-4 pb-3 font-16">
                                    While you’re creating your account, you’ll get an email from Univariety. Open the email and find the
                                    verification code.
                                </p>
                                <div className="d-flex justify-content-center">
                                    <a className="btn-green max-190 mx-1 text-nowrap btnverified">
                                        <i className="icon-varified text-white font-16 me-1"></i>
                                        <span>Email Verified </span>
                                    </a>

                                    {/* <a href="" className="btn-green max-190 mx-1 text-nowrap" role="button" onClick={handleVerifyMobile}>
                                        <span>Verify Mobile</span>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VerifyContactDetailsControl;
