import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddReferral = () => {
    const navigate = useNavigate();
    const sesinstituteId = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user') as string).primaryInstitute : 0;
    const instName = localStorage.getItem('school_settings') ? JSON.parse(localStorage.getItem('school_settings') as string).name : '';
    const instPrimaryId = localStorage.getItem('school_settings') ? JSON.parse(localStorage.getItem('school_settings') as string).id : '';
    const instDomain = localStorage.getItem('school_settings') ? JSON.parse(localStorage.getItem('school_settings') as string).domain : ''; 
    const instLogo = localStorage.getItem('school_settings') ? JSON.parse(localStorage.getItem('school_settings') as string).logo : '';// Get domain from school_settings
    const jwtToken = localStorage.getItem('user-jwt-token');
    
    // Get the base URL (e.g., http://localhost:3000)
    const baseUrl = window.location.origin;
    let referLink = baseUrl+'/reference/'+instDomain+'/users';
   // alert(referLink);
    useEffect(() => {
        const saveTemplate = async (schoolLogo: string) => {
           

            const currentDate = new Date();
            const dateOptions: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const formattedDate = currentDate.toLocaleDateString(undefined, dateOptions);
            const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
            const formattedTime = currentDate.toLocaleTimeString(undefined, timeOptions);
            const date = `${formattedDate} ${formattedTime}`;

            const body = `<p><strong>Dear @First_Name @Last_Name,</strong></p><p>We hope this message finds you well! As a valued member of the ${instName} family, you know firsthand the enriching experience and opportunities we offer. As we begin our admissions season, we’d love your help in spreading the word to potential students who could benefit from our programs and curricula.<br/><br/> If you have friends, family members, or colleagues who are considering taking admission and might be a great fit for ${instName}, please encourage them to explore our offerings. </p><p><a href="${referLink}" rel="noopener noreferrer" target="_blank" style="color: rgb(255, 255, 255); background-color: rgb(92, 177, 72);">Refer Now</a></p><p>We appreciate your support in helping us grow the  ${instName} family.</p><br><p>Warm regards,</br><strong>Alumni Helpdesk</strong></p>`;
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BROADCAST_SERVER}/email/save-template`,
                    {
                        subject: "Refer Friends and Family to study at your alma mater!",
                        message: body,
                        school_ref_id: sesinstituteId,
                        status: 0,
                        created_date: date,
                        sender_mail: "Refer Friends and Family",
                        university_logo: schoolLogo || '',
                        template_ref_id: 4,
                        base_url: baseUrl // Optionally send the base URL in the API call
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                const insertedId = response.data.insertedId;
                if (insertedId) {
                    // Uncomment to navigate after successful response
                     navigate(`/broadcast/alumni-profiles?template_id=${insertedId}&type=6`);
                } else {
                    console.error('Inserted ID not found in the response');
                }
            } catch (error) {
                console.error('Error saving template:', error);
            }
        };

        const schoolLogoFromSession = sessionStorage.getItem('school-image');
        if (schoolLogoFromSession) {
            saveTemplate(schoolLogoFromSession);
        }
    }, [sesinstituteId, jwtToken, navigate, baseUrl]);

    return null; // Ensure no content is rendered
};

export default AddReferral;
