import moment from 'moment';
import { DomainElement, State } from '../library/Core/SmartTypes';
import { axiosClient } from '../library/Service/axiosClient';

export const AlumniProfileEditScreens = {
    BASIC_INFORMATION: 'info',
    OUTCOME: 'outcome',
    UG_JOURNEY: 'ug',
    SCHOLARSHIPS: 'scholarship',
    EXAMS: 'entrance-test',
    ENTRANCE_EXAM: 'entrance-exam',
    BREAK: 'student-took-a-break',
    SCHOOL: 'student-school',
    POST_GRADUATE_DETAILS: 'pg',
    MENTORING_INTEREST: 'mentoring-interests',
    PROFILE_IMAGE_AND_SOCIAL_PROFILES: 'profile-picture',
    WORK_DETAILS: 'work',
    STEP_1_COMPLETION_POPUP: 'step-one',
    BENEFITS_OPT_IN: 'benefits',
    STUDENT_PREFERENCE: 'student-preference',
    HIGHER_EDUCATION_PREFERENCE: 'pg-education-preference',
    JOB_PREFERENCE: 'job-preferences',
    ACHIEVEMENTS: 'achievements',
    SKILLS: 'skills',
    STEP_2_COMPLETION_POPUP: 'step-two',
};

const currentStatusEnum = {
    PURSUING_UG: '1',
    PURSUING_PG: '2',
    IN_DEFENSE: '3',
    ENTREPRENEURSHIP: '6',
    WORKING: '7',
    HOME_MAKER: '8',
    NOT_WORKING: '9',
    OTHERS: '10',
    PROFESSIONAL_PRACTICE: '21',
};

const pages: any = [
    {
        name: AlumniProfileEditScreens.BASIC_INFORMATION,
        profileCompletionPercent: 0,
        stepMessage: '',
    },
    {
        name: AlumniProfileEditScreens.UG_JOURNEY,
        profileCompletionPercent: 0,
        stepMessage: '',
        precondition: (data: Conditionals) => !(data.gapYear && data.passOutYearCategory === 'RECENT'),
    },
    {
        name: AlumniProfileEditScreens.SCHOLARSHIPS,
        profileCompletionPercent: 48,
        stepMessage: '',
        // precondition: (data: Conditionals) => data.passOutYearCategory !== 'RECENT' && data.gapYear !== true,
        precondition: (data: Conditionals) => !(data.gapYear && data.passOutYearCategory === 'RECENT'),
    },
    {
        name: AlumniProfileEditScreens.EXAMS,
        profileCompletionPercent: 63,
        stepMessage: '',
        // precondition: (data: Conditionals) => data.passOutYearCategory !== 'RECENT' && data.gapYear !== true,
        precondition: (data: Conditionals) => !(data.gapYear && data.passOutYearCategory === 'RECENT'),

    },
    {
        name: AlumniProfileEditScreens.POST_GRADUATE_DETAILS,
        profileCompletionPercent: 53,
        stepMessage: '',
        precondition: (data: Conditionals) => !(data.gapYear && data.passOutYearCategory === 'RECENT') && data.currentStatus != currentStatusEnum.PURSUING_UG,
    },
    {
        name: AlumniProfileEditScreens.MENTORING_INTEREST,
        profileCompletionPercent: 38,
        stepMessage: '',
        precondition: (data: Conditionals) => data.prestigiousCollege != false,
    },
    {
        name: AlumniProfileEditScreens.PROFILE_IMAGE_AND_SOCIAL_PROFILES,
        profileCompletionPercent: 79,
        stepMessage: '',
        precondition: (data: Conditionals) => data.prestigiousCollege === true && data.mentoringInterest === true,
    },
    {
        name: AlumniProfileEditScreens.WORK_DETAILS,
        profileCompletionPercent: 61,
        stepMessage: '',
        precondition: (data: Conditionals) => !(data.gapYear && data.passOutYearCategory === 'RECENT'),
    },
    { name: AlumniProfileEditScreens.STEP_1_COMPLETION_POPUP, profileCompletionPercent: 0, stepMessage: '' },
    { name: AlumniProfileEditScreens.BENEFITS_OPT_IN, profileCompletionPercent: 0, stepMessage: '' },
    {
        name: AlumniProfileEditScreens.HIGHER_EDUCATION_PREFERENCE,
        profileCompletionPercent: 90,
        stepMessage: '',
        precondition: (data: Conditionals) => data.higherEducationPreference === true,
    },
    {
        name: AlumniProfileEditScreens.JOB_PREFERENCE,
        profileCompletionPercent: 0,
        stepMessage: 'Just 2 more steps',
        precondition: (data: Conditionals) => data.jobPreference === true,
    },
    { name: AlumniProfileEditScreens.ACHIEVEMENTS, profileCompletionPercent: 84, stepMessage: 'Just 1 more step' },
    { name: AlumniProfileEditScreens.SKILLS, profileCompletionPercent: 96, stepMessage: 'Last step' },
    {
        name: AlumniProfileEditScreens.PROFILE_IMAGE_AND_SOCIAL_PROFILES,
        profileCompletionPercent: 79,
        stepMessage: '',
        // 188-start @dt 11th.July,2K23 @by RG;
        // precondition: (data: Conditionals) => data.prestigiousCollege === true && data.mentoringInterest === true,
        // precondition: (data: Conditionals) => data.prestigiousCollege === false || data.mentoringInterest === false,
        precondition: (data: Conditionals) => data.mentoringInterest === false,
        // 188-end;
    },
    { name: AlumniProfileEditScreens.STEP_2_COMPLETION_POPUP, profileCompletionPercent: 100, stepMessage: '' },
];

type Conditionals = {
    passOutYearCategory: string;
    gapYear: boolean;
    prestigiousCollege: boolean;
    mentoringInterest: boolean;
    currentStatus: string;
    higherEducationPreference: boolean;
    jobPreference: boolean;
    userTypeCode: string;
};

type Page = {
    name: string;
    precondition?: (state: State) => boolean;
    stepMessage?: string;
    profileCompletionPercent?: number;
};

class ScreenNavigation2Service {
    static navigateToNextStep = (currentPageName: string, state: State, dispatch: any) => {
        if(sessionStorage.getItem('is-impersonated-session') === 'YES' || state?.data?.userStatusCode == 'ACTIVE')
        {
            return state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile/${currentPageName}`);
        }

        const conditionals = ScreenNavigation2Service.getVariables(state);
        const nextPage: Page = ScreenNavigation2Service.findNextPage(currentPageName, conditionals);
        sessionStorage.setItem('profile-percentage', nextPage?.profileCompletionPercent?.toString() as string);
        sessionStorage.setItem('step-message', nextPage?.stepMessage as string);
        sessionStorage.setItem('user-profile-status-code', nextPage.name);
        // if (nextPage.name === 'step-two') {
        if ( nextPage.name === 'step-two' && sessionStorage.getItem('is-peer-reviewed') == "true" ) {
            sessionStorage.setItem('user-status-code', 'ACTIVE');
            document.getElementById('updateUserStatusCode')?.click();
        }

        axiosClient().post(`${process.env.REACT_APP_COMMON_API}/profile/update-status`, { profileStatusCode: nextPage.name });

        state.actions['ROUTER_NAVIGATE'](`/alumni/${sessionStorage.getItem('user-id')}/${nextPage.name}`);
    };

    static findNextPage = (currentPageName: string, conditionals: Conditionals) => {
        const currentIndex = pages.findIndex((page: Page) => page.name === currentPageName);
        console.log(conditionals, " conditionals ");
        // If current page is not found, return null or handle the error appropriately
        if (currentIndex === -1) return null;

        // If social profile page rendering as a last screen. @by RG;
        if ( conditionals.userTypeCode == 'ALUMNI' && currentPageName == 'profile-picture' && !conditionals.mentoringInterest ) {
            return pages[pages.length - 1];
        }

        // Try finding the next page that meets its precondition
        for (let i = currentIndex + 1; i < pages.length; i++) {
            const nextPage = pages[i];
            if ( ! nextPage.precondition || nextPage.precondition(conditionals) ) {
                return nextPage;
            }
        }

        // If no next page found, return null or handle it appropriately
        return null;
    };

    static getVariables = (state: State): Conditionals => {
        let isPrestigiousCollege = ScreenNavigation2Service.evaluatePrestigiousCollege(state) ?? false;

        return {
            passOutYearCategory: ScreenNavigation2Service.getPassOutYearCategory(state),
            gapYear: state?.data?.profile?.didYouTakeGapAfter12 ?? false,
            prestigiousCollege: isPrestigiousCollege,
            mentoringInterest:
                (state?.data?.mentoringInterests?.shareMyStory == 'true' ||
                    state?.data?.mentoringInterests?.interestedInWebinar == 'true') ??
                false,
            currentStatus: state?.data?.profile?.educationOrEmploymentCurrentStatusCode,
            higherEducationPreference: state?.data?.benefits.eventsPursuePgDegree ?? false,
            jobPreference: state?.data?.benefits?.eventsNotifyJobReferrals ?? false,
            userTypeCode: state?.data?.userTypeCode
        };
    };

    static getPassOutYearCategory = (state: State): string => {
        const passOutYear = state?.data?.profile?.passOutYear;
        const fromPassOutYear: number = moment().year() - 10;
        const toPassOutYear: number = moment().year() - 2;

        if (passOutYear > toPassOutYear) {
            return 'RECENT';
        } else if (passOutYear >= fromPassOutYear) {
            return 'BETWEEN_2_TO_10_YEARS';
        } else {
            return 'MORE_THAN_10_YEARS';
        }
    };

    static evaluatePrestigiousCollege(state: State): boolean {
        let universityId = state?.data?.underGradCollegeJoined?.universityId;
        let isPrestigiousCollege = ScreenNavigation2Service.isPrestigiousCollege(universityId, state);

        if (isPrestigiousCollege) {
            return isPrestigiousCollege;
        }

        state?.data?.postGradCollege.forEach((college: any) => {
            universityId = college.universityId;
            isPrestigiousCollege = ScreenNavigation2Service.isPrestigiousCollege(universityId, state);
            if (isPrestigiousCollege) {
                return isPrestigiousCollege;
            }
        });

        return isPrestigiousCollege;
    }

    static isPrestigiousCollege(universityId: any, state: State): boolean {
        if (universityId == null) return false;
        const universityMasterData = state.domain.get('UNIVERSITY_CODE') as DomainElement[];
        const university = universityMasterData.find((item: any) => item.code == universityId) as DomainElement;
        const flexData = JSON.parse(university?.flexCol as string);
        const isPrestigious: boolean = university ? flexData.isPrestigious == '1' : false;
        return isPrestigious;
    }

    static getConditionals = (state: State) => {};
}

export default ScreenNavigation2Service;
