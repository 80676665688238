import { useContext, useRef, useState } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, getControlValueFromState, handleControlValueChange } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import logger from '../Core/logger';
import { axiosClient } from '../Service/axiosClient';
import ErrorControl from './ErrorControl';

const FileAction = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl
    const isHidden = evaluateExpression(control.hideExpression, state?.data);
    const [imagelink, setImageLink] = useState('');
    const [doclink, setDocLink] = useState('');
    const imgArray = ['jpg', 'jpeg', 'png', 'gif'];
    const validExtensions = [...imgArray, 'pdf', 'doc', 'docx'];

    if (data && !imagelink && !doclink) {
        const extension = data.split('.').at(-1);
        if (imgArray.includes(extension)) {
            setImageLink(data);
            setDocLink('');
        } else {
            setDocLink(data);
            setImageLink('');
        }
    }

    const handleFileControlUpload = async (state: State, e: any) => {
        e.preventDefault();
        logger.log(state.data);

        const file = e.target.files[0];
        const extension = file.name.split('.').at(-1).toLowerCase();

        if (!validExtensions.includes(extension)) {
            alert('Please upload a valid file [jpg, png, jpeg, gif, pdf, doc, docx]');
            e.target.value = ''; // Clear the file input
            return;
        }

        const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/get-attached`;

        const formData = new FormData();
        formData.append('pdf_doc', file);

        try {
            const response = await axiosClient().post(apiUrl, formData);
            if (response.status === 400) {
                alert(response.data.message);
                return;
            }

            const link = response.data.link;
            if (imgArray.includes(link.split('.').at(-1))) {
                setImageLink(link);
                setDocLink('');
            } else {
                setDocLink(link);
                setImageLink('');
            }

            handleControlValueChange({ control, value: link, dataKey, parentDataKey, state, dispatch });
        } catch (error) {
            console.error("An error occurred:", error);
            alert("Error uploading file");
            e.target.value = ''; // Clear the file input in case of error
        }
    };

    if (isHidden) return <></>;

    return (
        <div className="w-100">
            <label htmlFor="formFileLg" className="form-label">
                {control.props.label}
            </label>

            <input
                id={control.id}
                data-testid={control.id}
                type="file"
                hidden={control.props?.isCode || isHidden}
                className="form-control form-control-lg pl-0"
                accept=".jpg, .jpeg, .gif, .pdf, .doc, .docx"
                name="cover"
                onChange={(event) => handleFileControlUpload(state as State, event)}
            />
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
            <br />
            {imagelink && <img src={imagelink} width="160" alt="" className="img-fluid" />}
            {doclink && <a href={doclink} target='_blank' rel="noopener noreferrer">View File</a>}
        </div>
    );
};

export default FileAction;
