import { useEffect } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import JobListingLayout from './JobListingLayout';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';

const JobListing = () => {
    const { pageName } = useParams();
    const navigate = useNavigate();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        data: [],
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            ROUTER_NAVIGATE: navigate
        },
    } as any);

    const { formConfig, domain, data, internal, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: {
                    data: data.data,
                    pageDomain: data.domain,
                    filters: data.filters,
                    filteredData: data.data,
                    summaryCounts: data.summaryCounts,
                    selectedTab: 'ACTIVE_LIST',
                },
                pageDomain: data.domain,
                domain,
                internal,
                routeInfo,
            },
        });
    }, []);

    if(sessionStorage.getItem('user-type-code') === 'STUDENT' && sessionStorage.getItem('user-profile-status-code') === 'PREFERENCES_INCOMPLETE')
    {
        window.location.href = `/student/${sessionStorage.getItem('user-id')}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            {!state?.flags.isDataLoading && (
                <>
                    <JobListingLayout />
                    <DebugBarControl />
                </>
            )}
        </SmartContext.Provider>
    );
};

export default JobListing;
