import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { isEmpty } from '../../../../library/Core/SmartFunctions';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import { handleNext } from './AlumniProfileImageActions';
import AlumniProfileImageLayout from './AlumniProfileImageLayout';
import SessionContext from '../../../../library/Core/SessionContext';

const AlumniProfileImage = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { NEXT: handleNext, ROUTER_NAVIGATE: navigate },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    const getSocialProfileLinks = () => {
        return globalState.domain?.get('SOCIAL_NETWORK_CODE')?.map((item: any) => ({ socialNetworkCode: item.code, link: '' }));
    };

    useEffect(() => {
        data['alumniProfile'] = data['alumniProfile'] || {};
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        if (isEmpty(data?.socialLinks)) data['socialLinks'] = getSocialProfileLinks();
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    if ( sessionState?.userTypeCode === 'STUDENT' && sessionState?.profileStatusCode === 'PREFERENCES_INCOMPLETE' ) {
        window.location.href = `/alumni/${sessionState?.id}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <AlumniProfileImageLayout>{!state.flags.isDataLoading && <PageBuilder />}</AlumniProfileImageLayout>
        </SmartContext.Provider>
    );
};

export default AlumniProfileImage;
