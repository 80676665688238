import InviteService from './InviteService';
import React, { useState, useRef, useContext, useEffect } from 'react';

import axios from 'axios';
import UserSearch from '../header-search/UserSearch';
import { axiosClient } from '../../library/Service/axiosClient';
import whatsappImg from './../../styles/images/whatsapp.svg';
import SessionContext from '../../library/Core/SessionContext';
import DebugBarControl from '../../library/SimpleControls/DebugBarControl';
import useManagementDashboard from '../management/dashboard/useManagementDashboard';
import { getWhiteLabelUrl, isEmpty, isSchoolAdmin } from '../../library/Core/SmartFunctions';

const PeerInvite = ({pageTitle}:any) => {
    document.title=pageTitle;
    
    const [selectedInstitute, setSelectedInstitute] = useState('');
    const [campuses, setCampuses] = useState([]); 
    const [urlToShare, seturlToShare] = useState('');

    const [mobileInputValue, setInputValue] = useState('');
    const [emailInputValue, setEmailInput] = useState('');
    const [deliverStatus, setDeliverStatus] = useState('');
    const [deliverStatusMessage, setDeliverStatusMessage] = useState('');

    const [inviteSent, setInviteSent] = useState(false);
    const [emailInviteSent, setEmailInviteSent] = useState(false);
    const { sessionState }: any = useContext(SessionContext);
    console.log("sessionState",sessionState)
    // const schoolDomain = localStorage.getItem('user-school-domain');
    const schoolDomain = sessionState?.instituteDomain;
    // console.log(sessionState, " sessionState ");
    const userId = sessionState?.id ? sessionState?.id : sessionState?.userId;
    const instId = sessionState.instituteId;
    const base64Id = userId ? btoa(`${userId}##${instId}`) : '';

    console.log(userId, " userId ");
    console.log(instId, " INST ID");
    console.log(urlToShare, " urlToShare ");
    console.log(base64Id, " base64Id ");

    const { getCampuses } = useManagementDashboard();

    useEffect(() => {
        if(isSchoolAdmin() ){
            if(!(sessionState?.instituteGroupId && sessionState?.institutes.includes(String(sessionState?.instituteGroupId)))){
                const fetchCampuses = async () => {
                    try {
                        const campuses=getCampuses();
                        setCampuses(campuses);
                        console.log("campuses",campuses)
                    } catch (error) {
                        console.error('Error fetching campuses:', error);
                    }
                }
                fetchCampuses();
           }else{
            setInstituteWiseLink(sessionState?.instituteGroupId)
           }
        }else{
           // seturlToShare(process.env.REACT_APP_APP_BASE_URL + '/user/' + schoolDomain + '/signup/' + base64Id);
            const base_path=getWhiteLabelUrl(sessionState)
            seturlToShare(base_path + 'user/' + schoolDomain + '/signup/' + base64Id);
        }
    }, [sessionState.role]);

   
    const sendMobileInvite = async () => {
        const response: any = await InviteService.sendInvitesByPhone(mobileInputValue);
        setInviteSent(true);

        if (response.data.success === true) {
            setDeliverStatus('success');
            setDeliverStatusMessage(response.data.message);
        } else {
            setDeliverStatus('danger');
            setDeliverStatusMessage(response.data.message);
        }
    };

    const updateEmailInput = (value: string) => {
        setEmailInput(value);
    };
    const setMobileInputValue = (value: string) => {
        setInputValue(value);
    };

    const sendEmailInvite = async () => {
        const instituteId= (isSchoolAdmin())  ? selectedInstitute : sessionState.instituteId
        console.log("instituteId",instituteId)
        if(instituteId == ''){
            alert('Please select atleast one Institute'); // You can log an error or show a message to the user
            return;
        }
        if (emailInputValue.trim() === '') {
            alert('Share On Email address is required.'); // You can log an error or show a message to the user
            return;
        }
        const response: any = await InviteService.sendEmailTOInvitees(emailInputValue,instituteId);
        console.log(response.data.success);
        setEmailInviteSent(true);
        if (response.data.success === true) {
            setDeliverStatus('success');
            setDeliverStatusMessage(response.data.message);
        } else {
            setDeliverStatus('danger');
            setDeliverStatusMessage(response.data.message);
        }
    };

    const shareOnFacebook = () => {
        //const urlToShare = 'https://miles.univariety.com/alumni/social_invite/NDkwNzY4IyMjR29waSBMaW5r'; // Replace with your URL to share
        const quoteText = `${sessionState.firstName} ${sessionState.lastName} is inviting you to join your school’s alumni network powered by Univariety. Join now to connect with fellow alumni, teachers and current students of your school`;
        const facebookSharerUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            urlToShare
        )}&quote=${encodeURIComponent(quoteText)}`;

        window.open(facebookSharerUrl, '_blank');
    };

    const shareOnLinkedIn = () => {
        const title = 'Alumni Invite';
        const summary = `${sessionState.firstName} ${sessionState.lastName} is inviting you to join your school’s alumni network powered by Univariety. Join now to connect with fellow alumni, teachers and current students of your school`;
        const source = 'Univariety';
        const linkedinSharerUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            urlToShare
        )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(summary)}&source=${encodeURIComponent(source)}`;

        window.open(linkedinSharerUrl, '_blank');
    };

    const shareOnTwitter = () => {
        const quoteText = `${sessionState.firstName} ${sessionState.lastName} is inviting you to join your school’s alumni network powered by Univariety. Join now to connect with fellow alumni, teachers and current students of your school`;

        const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(quoteText)}&url=${encodeURIComponent(urlToShare)}`;
        window.open(shareUrl, '_blank');
    };

    const shareOnWhatsApp = () => {
        const textToShare = `${sessionState.firstName} ${sessionState.lastName} is inviting you to join your school’s alumni network powered by Univariety. Join now to connect with fellow alumni, teachers, and current students of your school.`;

        const encodedText = encodeURIComponent(`${textToShare}\n${urlToShare}`);

        const whatsAppSharerUrl = `https://api.whatsapp.com/send?text=${encodedText}`;

        window.open(whatsAppSharerUrl, '_blank');
    };

    const inputRef = useRef<HTMLInputElement>(null);
    function handleCopy() {
        if (inputRef.current) {
            inputRef.current.select();
            document.execCommand('copy');
        }
    }

    // console.log(sessionState, " sessionState ");
    const isPeerReviewed = sessionState?.isPeerReviewed == true ? true : false;
    const userType = sessionState?.userTypeCode;
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;

    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    const handleInstituteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log(event.target.value)
        setInstituteWiseLink(event.target.value)
    };

    const setInstituteWiseLink = async (instituteId: any) => {
        try {
            setSelectedInstitute(instituteId)
            const response = await axiosClient().get(`${process.env.REACT_APP_ADMIN_API_SERVER}/institute/${instituteId}`);
            console.log(response);
            if (response.status === 200) { // Ensure successful response
                const base64Id = userId ? btoa(`${userId}##${instituteId}`) : '';
                const instituteData = response.data.data[0]; 
                const domain = instituteData.domain;

                const base_url = (!isEmpty(instituteData.whitelabelUrl)) ? instituteData.whitelabelUrl : process.env.REACT_APP_APP_BASE_URL + "/";
                const newUrl = `${base_url}user/${domain}/signup/${base64Id}`;
        
                // const newUrl = `${process.env.REACT_APP_APP_BASE_URL}/user/${domain}/signup/${base64Id}`;
                console.log("newUrl", newUrl);
                seturlToShare(newUrl);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    return (
        <div className="col-md-12">
            <div className="white-block white-block-notopborderradius p-0 h-100">
                <div className="max-1140 d-flex flex-wrap justify-content-between align-items-center mx-auto  py-3 py-sm-4">
                    <h2
                        className="font-35 font-600 mb-0 mt-2 mt-sm-3 block-title aos-init aos-animate"
                        data-aos="fade-left"
                        data-aos-delay="300">
                        Send Invites
                    </h2>
                </div>
                <div className="bg-lighter-blue">
                    <div
                        data-aos="fade-up"
                        data-aos-delay="900"
                        id="no-notification"
                        className="d-flex flex-column justify-content-between align-items-center mx-auto max-630 py-4 aos-init aos-animate">
                        <form className="lstCustForm w-100 my-2">
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <label className="form-label m-0 mb-1 font-16 font-500 w-100">
                                        Search for your fellow alumni using their name, email or mobile numbers. If they are not on the
                                        platform, you can invite them to join. You can choose to type in the contact details or share the
                                        invite link on Social Media
                                    </label>
                                    <UserSearch />
                                </div>
                            </div>
                            <div className="SearchInviteBox">
                                <div id="SearchInviteBox"></div>
                            </div>
                        </form>
                    </div>
                </div>

                {
                    (isSchoolAdmin()) && 
                    !(sessionState?.instituteGroupId && sessionState?.institutes.includes(String(sessionState?.instituteGroupId))) &&
                    ( 
                        <div className="d-flex flex-column justify-content-between align-items-center mx-auto max-630 pt-4 pt-sm-5 pb-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="900" id="no-notification">
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* added institute-wise end */}
                                        <label className="form-label m-0 mb-1 font-16 font-500 w-100">Select Institute</label>
                                        <div className="input-group mb-3">
                                            <select
                                                value={selectedInstitute}
                                                onChange={handleInstituteChange}
                                                className="form-select custom-border-gray-848484 font-16 font-500">
                                                <option value="">Select Institute</option>
                                                {campuses.map((campus:any) => (
                                                    <option key={campus.value} value={campus.value}>{campus.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                
                <div
                     className={`d-flex flex-column justify-content-between align-items-center mx-auto max-630 ${sessionState.role !== 'INSTITUTE_ADMIN' ? 'pt-4 pt-sm-5 pb-5' : ''} aos-init aos-animate`}
                     data-aos="fade-up"
                     data-aos-delay="900"
                    id="no-notification">
                    <div className="w-100">
                        <div className="row mb-1 pt-1">
                            <div className="col-md-12">
                                <label className="form-label m-0 mb-1 font-16 font-500 w-100">Social via social media</label>
                                <div className="input-group mb-3">
                                    <span
                                        onClick={handleCopy}
                                        id="basic-addon1"
                                        className="input-group-text d-flex align-items-center custom-border-gray-848484 height-45 font-16 font-500">
                                        <img className="me-2" src={`${process.env.REACT_APP_IMAGE_BASEPATH}/link-45deg.svg`} width="20" />
                                        Copy Link
                                    </span>
                                    <input
                                        type="text"
                                        value={urlToShare}
                                        ref={inputRef}
                                        readOnly
                                        className="form-control custom-border-gray-848484 font-16 font-500"
                                    />
                                </div>
                            </div>
                        </div>
                        <ul className="share_option flex-wrap">
                        {urlToShare ? (
                                <>
                                    <li>
                                        <a href="#" className="fb" target="_blank" onClick={shareOnFacebook}>
                                            <span className="me-1">
                                                <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/211902_social_facebook_icon.svg`} width="28" />
                                            </span>
                                            Share On Facebook
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={shareOnTwitter} target="_blank" className="tw">
                                            <span className="me-1">
                                                <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/216402_twitter_social_icon.svg`} width="28" />
                                            </span>
                                            Share On Twitter
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={shareOnLinkedIn} target="_blank" className="in">
                                            <span className="me-1">
                                                <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/710289_in_linked_social_icon.svg`} width="32" />
                                            </span>
                                            Share On LinkedIn
                                        </a>
                                    </li>
                                    <li className="d-block d-md-none">
                                        <a onClick={shareOnWhatsApp} target="_blank" className="wa">
                                            <span className="me-1">
                                                <img src={whatsappImg} width="32" />
                                            </span>
                                            Share On Whatsapp
                                        </a>
                                    </li>
                                </>
                            ) : null}
                        </ul>
                    </div>
                    {/* <form className="lstCustForm w-100 pb-1 border-top mt-4 pt-4" action="" method="post">
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <label className="form-label m-0 mb-1 font-16 font-500 w-100">Share on Mobile</label>
                                {inviteSent && <div className={`alert alert-${deliverStatus}`}>{deliverStatusMessage}</div>}
                                <input
                                    type="tel"
                                    value={mobileInputValue}
                                    onChange={(event) => setMobileInputValue(event.target.value)}
                                    className="form-control flex-1 removedisable"
                                    placeholder="Ex: +91-XXXXXXXXXX,+9034-XXXXXXXXXX"
                                    id="mobilenumbers"
                                    name="mobilenumbers"></input>
                            </div>
                            <p id="newerrormsg"></p>
                        </div>
                        <div className="row justify-content-end align-items-center">
                            <div>
                                <button
                                    onClick={(event) => sendMobileInvite()}
                                    type="button"
                                    className="btn btn-green text-white d-block height-40"
                                    id="mobbtn">
                                    <span>Send Invites!</span>
                                </button>
                            </div>
                        </div>
                    </form> */}
                    <div className="d-block w-100" id="newsuc">
                        {emailInviteSent && <div className={`alert alert-${deliverStatus}`}>{deliverStatusMessage}!</div>}
                    </div>
                    <form className="lstCustForm w-100 pb-1 border-top mt-4 pt-4" action="" method="post">
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <label className="form-label m-0 mb-1 font-16 font-500 w-100">Share On Email</label>
                                <textarea
                                    onChange={(event) => updateEmailInput(event.target.value)}
                                    className="form-control height-125 removedisable"
                                    placeholder="Ex: abc@gmail.com, pqr@yahoo.com"
                                    id="multipleinvites"
                                    name="multipleinvites"
                                    value={emailInputValue} required></textarea>
                            </div>
                            <p id="errormsg"></p>
                        </div>
                        <div className="row justify-content-end align-items-center">
                            <div>
                                <button
                                    onClick={(event) => sendEmailInvite()}
                                    type="button"
                                    className="btn btn-green text-white d-block height-40"
                                    id="sendbutn">
                                    <span>Send Invites!</span>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="d-block w-100" id="successmsg"></div>
                </div>
            </div>
            <DebugBarControl/>
        </div>
    );
};

export default PeerInvite;
