import { useContext } from 'react';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import toolVisual from '../../../../styles/images/course_alumni_profile.svg';

const ScholarshipCarouselControl = () => {
    const { globalState } = useContext(GlobalContext);
    // const data = JSON.parse(sessionStorage.getItem('carousel-data') as string)['scholarship'];
    const data = globalState.appSettings.carouselData['scholarship'];
    const pages = Array.from(new Set(data.map((item: any) => item['pageNum']) as number[]));

    const getItems = (pageNum: number) => {
        const pageItems = data.filter((item: any) => item['pageNum'] === pageNum);
        return pageItems.map((item: any, index: number) => (
            <div key={index} className="card_profiles">
                <div className="card_profiles_univarsity">
                    <div className="card_profiles_name">
                        <a href="">
                            <img src={item['candidateImageUrl']} />
                        </a>
                        <div className="student_detail">
                            <h2>
                                <a href="">{item['name']}</a>
                                <span>{item['classTitle']}</span>
                            </h2>
                        </div>
                    </div>
                    <div className="univarsity-block">
                        <div className="univarsity-block">
                            <div className="univarsity-block-cover">
                                <a href="">
                                    <img src={item['universityUrl']} />
                                </a>
                                <h3 className="d-flex flex-column">
                                    <p>
                                        <a href="">{item['universityName']}</a>
                                    </p>
                                    <span className="course">
                                        <img src={toolVisual} className="img-fluid" width="16" />
                                        <span>{item['specialization']}</span>
                                    </span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="scholarship_amt_detail">
                    <div className="scholarship_label">
                        <i></i>Scholarship
                    </div>
                    <div className="scholarship_amt">
                        {item['scholarshipCurrencyCode']}: {item['scholarshipAmount']}
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div id="carouselScholarship" className="carousel slide" data-bs-touch="true" data-bs-ride="carousel">
            <div className="carousel-inner mb-3">
                {pages.map((item: any, index: number) => (
                    <div key={index} className={`carousel-item ${index === 0 ? ' active ' : ''}`}>
                        <div className="item-wrap">{getItems(index)}</div>
                    </div>
                ))}
            </div>
            <div className="carousel-indicators position-static">
                {pages.map((page: any, index: number) => (
                    <button
                        key={index}
                        type="button"
                        data-bs-target="#carouselScholarship"
                        data-bs-slide-to={`${index}`}
                        className={`dot ${index === 0 ? ' active ' : ''}`}
                        aria-current="true"
                        aria-label={`Slide ${index + 1}`}></button>
                ))}
            </div>
        </div>
    );
};

export default ScholarshipCarouselControl;
