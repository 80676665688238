import { useContext, useEffect, useState } from "react";
import AlbumListingService from "../../../album/listing/AlbumListingService";
import { SmartContext } from "../../../../library/Core/SmartContext";
import SessionContext from "../../../../library/Core/SessionContext";
import { useParams } from "react-router-dom";
import { extractVideoId, formatFullName, getDomainValueForCode, isEmpty, isSchoolAdmin, isSuperAdmin } from "../../../../library/Core/SmartFunctions";

const Memories = () => {
    const { state, dispatch } = useContext(SmartContext);
    const [albums, setAlbums] = useState<any[]>([]);
    const { sessionState } = useContext(SessionContext);
    const { id, pageName } = useParams();
    const [images, setImages] = useState([] as any);
    const [showAlbum, setShowAlbum] = useState(false);

    useEffect(() => fetchAlbums(), [state.internal?.refreshAlbum]);

    const fetchAlbums = () => {
        let type = 'STUDENT';
        const schoolAdminRoles = ['INSTITUTE_ADMIN', 'INSTITUTE_SUPPORT_MANAGER', 'INSTITUTE_MARKETING_MANAGER'];

        if (schoolAdminRoles.includes(sessionState?.userTypeCode as string) || sessionState?.userTypeCode === 'SUPER_ADMIN') {
            type = "SCHOOL_ADMIN_ALUMNI";
        }

        AlbumListingService.get(type, id).then((albumsResponse) => {
            setAlbums(albumsResponse?.data.albums);
        });
    };

    const handleShowAlbum = async (id: string) => {
        const response = await AlbumListingService.getById(id);
        const selectedAlbumImages = response.data?.albumContent?.map((content: any) => {
            let link_type = 'photo';
            if (content?.link_type == 'video') {
                const videoId = extractVideoId(content?.youtube_link);
                content.link = `https://www.youtube.com/embed/${videoId}`;
                link_type = 'video';
            }
            return {
                url: content?.link,
                type: link_type,
                altTag: 'No Image'
            }
        });

        setImages(selectedAlbumImages);
        setShowAlbum(true);
    };

    return (
        <>
            <div className="tab-pane fade show active" id="pills-memories" role="tabpanel" aria-labelledby="pills-memories-tab" tabIndex={0}>
                <div className="row g-4">
                    {/* Conditionally render the albums */}
                    {albums.length > 0 ? (
                        albums.map((album) => (
                            <div className="col-sm-6" key={album.id}>
                                <div className="album_blk bg-white">
                                    <div
                                        className="album_imgblk position-relative"
                                        role="button"
                                        onClick={() => handleShowAlbum(album.id)}
                                        style={{ height: '200px' }}
                                    >
                                        <span className="album_badge font-16 font-500 text-white d-inline-block position-absolute">
                                            Added by {getDomainValueForCode(album.userTypeCode, 'USER_TYPE_CODE', state)}
                                        </span>
                                        <img src={album.link ?? `${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_albumimg.jpg`} alt="" />
                                        <div className="album_imgvdo position-absolute"></div>
                                    </div>
                                    <div className="album_contblk d-flex flex-wrap justify-content-between align-items-start">
                                        <div className="flex-1 Wrdrow">
                                            <a style={{ textDecoration: 'none', cursor: 'pointer' }}>
                                                <h3 className="font-20 font-500 mb-2 text-start">{album.title}</h3>
                                            </a>
                                            <p className="text-gray-64 font-14 font-500 mb-2 text-start">Taken in - {`${album.year}`}</p>
                                            <span className="border-lightgray mb-2"></span>
                                            <a href="" className="d-flex align-items-start person_detls text-decoration-none">
                                                <img
                                                    src={`${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_male.svg`}
                                                    style={{ height: '32px', width: '32px' }}
                                                    className="rounded-circle mt-1 me-2"
                                                />
                                                <div className="lstperdetails text-start">
                                                    <p className="mb-1 font-14 font-500 line-height-18 text-black">
                                                        <span className="font-16">{formatFullName(album.studentFirstName, album.studentLastName)}</span>,{' '}
                                                        <em>{album.programName}</em>
                                                    </p>
                                                    {!isEmpty(album.companyName) && (
                                                        <p className="font-14 fst-italic font-500 text-gray-64 mb-0">
                                                            Working As{' '}
                                                            <span className="font-600 text-black">
                                                                {album.studentJobDesignation} At {album.companyName}
                                                            </span>
                                                        </p>
                                                    )}
                                                    {isEmpty(album.companyName) && !isEmpty(album.universityName) && (
                                                        <p className="font-14 fst-italic font-500 text-gray-64 mb-0">
                                                            Pursuing UG At{' '}
                                                            <span className="font-600 text-black">{album.universityName}</span>
                                                        </p>
                                                    )}
                                                </div>
                                            </a>
                                        </div>
                                        <div className="album_shareblk">
                                            {album.studentId != sessionState?.id && !isSchoolAdmin() && sessionState?.userTypeCode !== 'SUPER_ADMIN' && (
                                                <a
                                                    className={`btn btnicon listblk_icon me-2 me-sm-0 circle-effect ${album.isFavorite ? 'btn-dark-blue' : ''}`}
                                                    id="MakeSave_34"
                                                    role="button"
                                                >
                                                    <span className={`icon-ribbon-invert font-18 icon ${album.isFavorite ? 'text-white' : ''}`} id="SaveIcon_34"></span>
                                                </a>
                                            )}
                                            {album.studentId != sessionState?.id && !isSchoolAdmin() && sessionState?.userTypeCode !== 'SUPER_ADMIN' && (
                                                <a
                                                    className={`btn btnicon listblk_icon circle-effect ${album.isLiked ? 'btn-dark-blue' : ''}`}
                                                    id="MakeLike_34"
                                                    role="button"
                                                >
                                                    <span className={`icon-like font-18 icon ${album.isLiked ? 'text-white' : ''}`} id="LikeIcon_34"></span>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        // Display message if no albums available
                          <div className="col-12">
                          <span className="video_block_name">
                              <label>No Albums Available</label>
                          </span>
                      </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Memories;
