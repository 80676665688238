import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartContext } from '../../../../../library/Core/SmartContext';

const PersonalInfo = () => {
    const { state, dispatch } = useContext(SmartContext);
    const model = state.internal.model;
    const navigate = useNavigate();

    return (
        <div className="tab-pane fade active show" id="personalInfo" role="tabpanel" aria-labelledby="pills-underGraduate-tab">
            <div className="graduation_carousel_wrap">
                <div className="additionalinformation position-relative">
                    <a
                        className="btn-edit-pop ms-4 position-absolute top-0 right-10 text-decoration-none"
                        role="button"
                        onClick={() => navigate(`/management/${model.id}/additional-info`)}>
                        <i className="icon-edit-pencil text-gray-64"></i>
                    </a>
                    <div className="row">
                        <div className="col-12 mb-4">
                            <h2 className="font-24 border-bottom pb-2">Additional Information</h2>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
                            <label className="flex-1 pe-3 font-14 font-500 text-gray-53">Working with school since</label>
                            <span>:</span>
                        </div>
                        <div className="col-sm-7">
                            <p className="m-0 font-16 font-500">{model.schoolJoinDate}</p>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
                            <label className="flex-1 pe-3 font-14 font-500 text-gray-53">Date of Birth</label>
                            <span>:</span>
                        </div>
                        <div className="col-sm-7">
                            <p className="m-0 font-16 font-500">{model.dateOfBirth}</p>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
                            <label className="flex-1 pe-3 font-14 font-500 text-gray-53">Office Address (Building/ Street Name)</label>
                            <span>:</span>
                        </div>
                        <div className="col-sm-7">
                            <p className="m-0 font-16 font-500">{model.addressLineOne}</p>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
                            <label className="flex-1 pe-3 font-14 font-500 text-gray-53">Office Address (Locality/ Area)</label>
                            <span>:</span>
                        </div>
                        <div className="col-sm-7">
                            <p className="m-0 font-16 font-500">{model.addressLineTwo}</p>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-sm-5 d-block d-sm-flex flex-wrap">
                            <label className="flex-1 pe-3 font-14 font-500 text-gray-53">Pin Code/Zip Code</label>
                            <span>:</span>
                        </div>
                        <div className="col-sm-7">
                            <p className="m-0 font-16 font-500">{model.pin}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalInfo;
