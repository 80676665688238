import moment from 'moment';
import { toSentenceCase } from '../../../library/Core/SmartFunctions';
import { axiosClient } from '../../../library/Service/axiosClient';
import PageConfigService from '../../../services/page-config.service';
import { reportConfig } from './report-config';

class ManageAlumniServiceEngage {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = ManageAlumniServiceEngage.getDefaultSearchCriteria();

        return Promise.all([
            PageConfigService.get(params.pageName),
            ManageAlumniServiceEngage.search(searchCriteria, params.pageName),
            ManageAlumniServiceEngage.getSchoolSearchFilterDomains(params.pageName),
        ]).then((values) => {
            state.formConfig = values[0].data;
            state.data = {
                alumniList: values[1].data ?? [],
                searchCriteria: { ...searchCriteria, schoolId: sessionStorage.getItem('user-school-id') },
                filterDomains: values[2].data,
                reportConfig: reportConfig[params.pageName],
            };
            state.routeInfo = params;
            state.internal = { gridState: {} };
            return Promise.resolve(state);
        });
    }

    static search(searchCriteria: any, reportType: string): any {
        const payload = ManageAlumniServiceEngage.sanitizeSearchCriteria(searchCriteria, reportType);
        return axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/management/manage-alumni`, payload);
    }

    // Notes: This is key function in forming the search criteria for the report. Handle with care.
    static sanitizeSearchCriteria(searchCriteria: any, reportType: string) {
        const reportTypesToRemoveDates = [
            'track-student-career-preferences',
            'track-alumni-education-preferences',
            'track-alumni-engagement-with-students',
            'track-alumni-highlights-with-students-engagement',
        ];

        if (reportTypesToRemoveDates.includes(reportType)) {
            delete searchCriteria.createdFrom;
            delete searchCriteria.createdTo;
        }

        if (reportType != 'track-alumni-highlights-with-students-engagement') {
            delete searchCriteria.conversationFromDate;
            delete searchCriteria.conversationToDate;
        }

        const schoolIdFromSession = sessionStorage.getItem('user-school-id');

        if (reportType.includes('student')) {
            searchCriteria = { ...searchCriteria, schoolId: schoolIdFromSession, userTypeCode: 'STUDENT' };
        }

        if (reportType.includes('alumni')) {
            searchCriteria = { ...searchCriteria, schoolId: schoolIdFromSession, userTypeCode: 'ALUMNI' };
        }

        if (!reportType.includes('profile')) {
            searchCriteria = { ...searchCriteria, userStatusCode: 'ACTIVE' };
        }

        if (searchCriteria.hasOwnProperty('notUpdated') && searchCriteria?.notUpdated) {
            searchCriteria = { ...searchCriteria, [`notUpdated${toSentenceCase(searchCriteria?.notUpdated)}`]: 1 };
        }

        if (searchCriteria.hasOwnProperty('notSure') && searchCriteria?.notSure) {
            searchCriteria = { ...searchCriteria, [`notSure${toSentenceCase(searchCriteria?.notSure)}`]: 1 };
        }

        return searchCriteria;
    }

    static getSchoolSearchFilterDomains(reportName: string = '') {
        switch (reportName) {
            case 'track-alumni-highlights-with-students-engagement':
                return axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/school-group-search-filter-domains`);
            default:
                return axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/school-search-filter-domains`);
        }
    }

    static deactivateStudent = async (students: any) => {
        return axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/school-directory/deactivate`, { students });
    };

    static activateStudent = (students: any) => {
        return axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/school-directory/activate`, { students });
    };

    static getDefaultSearchCriteria(): any {
        return {
            passOutYear: [],
            classCode: [],
            campusCode: [],
            curriculumCode: [],
            educationOrEmploymentCurrentStatusCode: [],
            countryCode: [],
            cityCode: [],
            residingCityCode: [],
            residingCountryCode: [],
            collegeCountryCode: [],

            preferenceCountryCode: [],
            countryPreferenceOrder: null,
            preferenceFieldOfStudy: [],
            careerPreferenceOrder: null,

            collegeCityCode: [],
            fieldOfStudyCode: [],
            notableCode: [],
            createdFrom: moment().subtract(3, 'months').format('YYYY-MM-DD'),
            createdTo: moment().format('YYYY-MM-DD'),

            conversationFromDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
            conversationToDate: moment().format('YYYY-MM-DD'),
        };
    }
}

export default ManageAlumniServiceEngage;
