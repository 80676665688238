import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
import { UUID } from 'crypto';

class GuidanceService {
    static pageLoader(params: any) {
        const state: any = {};
        const { id, pageName } = params;

        return Promise.all([GuidanceService.get()])
            .then((values) => {
                logger.log(`Retrieved values successfully for ${pageName}`);
                state.formConfig = {};
                state.data = {
                    ...values[0].data,
                    filteredData: values[0].data.guidanceVideos,
                    filters: [],
                    selectedTab: 'ALL',
                };
                // state.domain = values[2].data instanceof Map ? values[2].data : convertDomainArrayToMap(values[2].data);
                state.routeInfo = { id, pageName };
                // storeDomainData(state.domain);
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static async get() {
        return await axiosClient().get(`${process.env.REACT_APP_MEMORIES_GUIDANCE_SERVER}/guidance/search`);
    }

    static async getById(id: UUID) {
        return await axiosClient().get(`${process.env.REACT_APP_MEMORIES_GUIDANCE_SERVER}/guidance/search-by-student/${id}`);
    }
}

export default GuidanceService;
