import { useContext, useEffect, useRef, useState } from "react";
import { SmartContext } from "../../library/Core/SmartContext";
import { isEmpty } from "../../library/Core/SmartFunctions";
import { DispatchEvent, State } from "../../library/Core/SmartTypes";
import { Modal } from "bootstrap";
import { handleUpdate } from "./WidgetConfigurationActions";

interface ListPopupProps {
    id: string;
}

const ListPopup: React.FC<ListPopupProps> = (args) => {
    const { id } = { ...args };
    const { state, dispatch } = useContext(SmartContext);
    const modalRef = useRef<HTMLDivElement>(null);

    let data: any = [];
    if ( ! isEmpty(state?.internal?.selectedEntityId) && state?.internal?.selectedEntityId > 0 ) {
        data = state?.data?.popupData[state?.internal?.selectedEntityId];
    } else {
        data = state?.data?.widgetData?.otherList;
    }
    const count = data?.length ?? 0;
    
    const handleClosePopup = () => {
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'addNew', value: {} } });

        if (modalRef.current) {
            const modal = Modal.getInstance(modalRef.current);
            if (modal) {
                dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isListModalOpen', value: false } });
                modal.hide();
            }
        }
    }

    const handleDeleteItem = (id: any) => {
        const selectedEntityId = state?.internal?.selectedEntityId;
    
        if (!isEmpty(selectedEntityId) && selectedEntityId > 0) {
            let data = state?.data?.popupData?.[selectedEntityId];
            let metricData = state?.data?.metricData;
    
            if (!isEmpty(data) && Array.isArray(data)) {
                const newData = data.filter((item: any) => item.id !== id); // Create a new array without the item to delete
                const newMetricData = metricData.map((item: any) => {
                    if (item.metricEntity2 === selectedEntityId) {
                        const newItem = {
                            ...item, // Create a new object based on the existing item
                            cnt: item.cnt - 1, // Update the count
                            metricCount: `${item.cnt - 1} ${state?.internal?.entityName}`, // Update the metric count
                        };
                        return newItem; // Return the new object
                    }
                    return item;
                })
                
                dispatch({
                    type: 'REFRESH_DATA',
                    payload: {
                        data: {
                            ...state.data,
                            popupData: {
                                ...state.data.popupData,
                                [selectedEntityId]: newData // Update with the new array
                            },
                            metricData: newMetricData
                        },
                    },
                });
            }
        } else {
            let data = state?.data?.widgetData?.otherList; // Ensure you're accessing the array correctly
    
            if (!isEmpty(data) && Array.isArray(data)) {
                const newData = data.filter((item: any) => item.id !== id); // Create a new array without the item to delete
    
                dispatch({
                    type: 'REFRESH_DATA',
                    payload: {
                        data: {
                            ...state.data,
                            widgetData: {
                                ...state.data.widgetData,
                                other: state.data.widgetData.other-1,
                                otherDisp: `${state.data.widgetData.other-1} ${state.internal.entityName}`,
                                otherList: newData // Directly assign newData, assuming otherList is supposed to be an array
                            },
                        },
                    },
                });
            }
        }
    };
    
    const handleEditItem = (id: any) => {
        console.log(id, " Editing id");
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'editId', value: id } });
        // may be we can set all the data in internal for the popup like name & logo as well
        handleUpdate(state?.internal?.selectedEntityId, state, dispatch)
    }
    // 
    return (
        <div 
            className="modal fade px-3" 
            id={id}
            data-bs-backdrop="static" 
            data-bs-keyboard="false" 
            tabIndex={-1} 
            aria-labelledby="staticBackdropLabel" 
            style={{ display: 'none' }}
            aria-modal="true"
            ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered max-900 mx-auto">
                <div className="modal-content">
                    <div className="modal-header p-4 height-70 border-0 bg-lite-blue d-flex align-items-center justify-content-between">
                        <h5 className="modal-title d-flex align-items-center" id="staticBackdropLabel">
                            <i className="me-2 d-flex align-items-center">
                                <img src={state.internal.widget_icon} width="30" alt="college icon"/>
                            </i>
                            {/* Top Colleges with Alumni - India (42) */}
                            {state?.internal?.widget_title} ({count})
                        </h5>
                        <button type="button" onClick={handleClosePopup} className="btn-close btn-close-small m-0 position-static" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="colleges-list">
                            <ul>
                                {data?.map((item: any, index: any) => (
                                    <li key={index}>
                                        <div className={`logoImg cls_${state?.internal?.widget_code}`}>
                                            <img src={!isEmpty(item.logo) ? item.logo : `${process.env.REACT_APP_IMAGE_BASEPATH}/building.svg`} width={90} alt={item.name} className="img-fluid rounded h-auto" />
                                        </div>
                                        <div className="logoName d-flex">
                                            <div className="d-flex align-items-center justify-content-between w-100">
                                            <div className="d-flex flex-column">
                                            <p className="me-2">{item.name}</p>
                                            {item.location && (
                                                <span><i className="icon icon-location-pin"></i> {item.location}</span>
                                            )}
                                            </div>
                                            {state?.data?.widgetData?.sourceType != 'system_data' && (
                                                <>
                                                <div className="d-flex">
                                                <a className="icon-edit text-gray-64 position-static me-2 text-decoration-none" 
                                                role="button" 
                                                onClick={() => handleEditItem(item.id)} 
                                                ></a>
                                                <a className="btn-delete position-static " 
                                                role="button" 
                                                onClick={() => handleDeleteItem(item.id)} 
                                                ></a>
                                                </div>
                                                </>
                                            )}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListPopup;
