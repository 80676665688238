import { useContext, useState } from 'react';
import { SmartContext } from '../../../../../../library/Core/SmartContext';
import { Modal } from 'bootstrap';
import Affinity1 from './Affinity1';
import Influence from './Influence';

const Willingness = () => {
    const { state } = useContext(SmartContext);

    const [activeTab, setActiveTab] = useState('affinity');

    const handleTabChange = (tab: any) => {
        setActiveTab(tab);
    };

    return (
        <div className="tab-pane fade show active" id="pills-willingness" role="tabpanel" aria-labelledby="pills-willingness-tab">
            <div className="d-flex flex-wrap flex-column flex-md-row">
                <div
                    className="nav flex-row flex-md-column nav-pills custom-sub-tab"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical">
                    <button
                        className={`nav-link ${activeTab === 'affinity' ? 'active' : ''}`}
                        id="v-pills-affinity-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-affinity"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-affinity"
                        aria-selected={activeTab === 'affinity'}
                        onClick={() => handleTabChange('affinity')}>
                        Affinity
                    </button>
                    <button
                        className={`nav-link ${activeTab === 'influence' ? 'active' : ''}`}
                        id="v-pills-influence-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-influence"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-influence"
                        aria-selected={activeTab === 'influence'}
                        onClick={() => handleTabChange('influence')}>
                        Influence
                    </button>
                </div>
                <div className="tab-content p-3 p-sm-4 p-md-5 bg-gray-F2F4F7 flex-1" id="v-pills-tabContent">
                    {activeTab === 'affinity' && <Affinity1 />}
                    {activeTab === 'influence' && <Influence />}
                </div>
            </div>
        </div>
    );
};

export default Willingness;
