import { useContext, useState } from 'react';
import { SmartContext } from '../../../../../../library/Core/SmartContext';
import { Modal } from 'bootstrap';
import WorkProfile from './WorkProfile';
import Demographics from './Demographics';
import PositionStrength from './PositionStrength';
const Ability = () => {
    const { state } = useContext(SmartContext);
    const [activeTab, setActiveTab] = useState('work-profile');

    const handleTabChange = (tab:any) => {
        setActiveTab(tab);
    };


    return (
        <div className="tab-pane fade show active" id="pills-ability" role="tabpanel" aria-labelledby="pills-ability-tab">
            <div className="d-flex flex-wrap flex-column flex-md-row">
                <div
                    className="nav flex-row flex-md-column nav-pills custom-sub-tab"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical">
                     <button
                        className={`nav-link ${activeTab === 'work-profile' ? 'active' : ''}`}
                        id="v-pills-work-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-work-profile"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-work-profile"
                        aria-selected={activeTab === 'work-profile'}
                        onClick={() => handleTabChange('work-profile')}>
                        Work Profile
                    </button>
                    <button
                        className={`nav-link ${activeTab === 'demographics' ? 'active' : ''}`}
                        id="v-pills-demographics-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-demographics"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-demographics"
                        aria-selected={activeTab === 'demographics'}
                        onClick={() => handleTabChange('demographics')}>
                        Demographics
                    </button>
                    <button
                        className={`nav-link ${activeTab === 'position-strength' ? 'active' : ''}`}
                        id="v-pills-position-strength-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-position-strength"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-position-strength"
                        aria-selected={activeTab === 'position-strength'}
                        onClick={() => handleTabChange('position-strength')}>
                        Position Strength
                    </button>
                </div>
                <div className="tab-content p-3 p-sm-4 p-md-5 bg-gray-F2F4F7 flex-1" id="v-pills-tabContent">
                    {activeTab === 'work-profile' && <WorkProfile />}
                    {activeTab === 'demographics' && <Demographics />}
                    {activeTab === 'position-strength' && <PositionStrength />}
                </div>
            </div>
        </div>
    );
};

export default Ability;
