import { useContext, useEffect } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import PieChartComponent from '../../../library/SimpleControls/PieChartControl';
import useManagementDashboard from './useManagementDashboard';

const StudentCareerReport = () => {
    const { state } = useContext(SmartContext);
    const { getStudentCareerReportData } = useManagementDashboard();

    useEffect(() => {
        getStudentCareerReportData();
    }, [state.data.searchCriteria.studentCareer.batch, state.data.searchCriteria.studentCareer.campus]);

    if (isEmpty(state.data?.reportData?.studentCareer)) return null;

    return (
        <>
            <PieChartComponent data={state.data.reportData.studentCareer} />
        </>
    );
};

export default StudentCareerReport;
