import { useContext } from 'react';
import { getControlFromFactory } from '../Core/ControlFactory';
import { SmartContext } from '../Core/SmartContext';
import { FormControl, State } from '../Core/SmartTypes';

export type ChildCompBuilderArguments = {
    controls: FormControl[];
    parentDataKey: string;
    padding: string;
};

const GridRowBuilder = (args: ChildCompBuilderArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { controls, parentDataKey, padding } = args;

    return (
        <>
            {controls.map((control) =>
                getControlFromFactory(control, parentDataKey, parentDataKey + '.' + control.id, state as State, undefined, '')
            )}
        </>
    );
};

export default GridRowBuilder;
