import React from 'react';

function FacebookAccount() {
  return (
    <>
      <div className="row g-4">
                                    <div className="col-md-3 col-sm-6">
                                    <div className="authorized_accounts_block new_account">
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#login_fb">
                                        <i className="plus"></i>
                                        <span>Add New</span>
                                        </a>
                                    </div>
                                    </div>
            
                                    <div className="col-md-3 col-sm-6">
                                    <div className="authorized_accounts_block">
                                        <div className="authorized_accounts_status active">
                                        <i></i> <span>Active</span>
                                        </div>
                                        <div className="authorized_accounts_profile_img">
                                            <img src="https://www.univariety.xyz/prototype/alumni-university/images/profileimg.jpg" alt=""/>
                                        </div>
                                        <div className="authorized_accounts_detail">
                                            <h3>Suresh R</h3>
                                            <p>Individual Account</p>
                                        </div>
            
                                        <a className="btn-disconnect" href="javascript:;">
                                            Disconnect
                                        </a>
                                    </div>
                                    </div>
            
                                    <div className="col-md-3 col-sm-6">
                                    <div className="authorized_accounts_block">
                                        <div className="authorized_accounts_status deactive">
                                        <i></i> <span>De-active</span>
                                        </div>
                                        <div className="authorized_accounts_profile_img logo">
                                            <img src="https://www.univariety.xyz/prototype/alumni-university/images/university_of_pennsylvania.jpg" alt=""/>
                                        </div>
                                        <div className="authorized_accounts_detail">
                                            <h3>VIT University</h3>
                                            <p>Brand Page</p>
                                        </div>
            
                                        <a className="btn-authorize" href="javascript:;">
                                            Re-authorize
                                        </a>
                                    </div>
                                    </div>
                                </div>
    </>
  )
}

export default FacebookAccount;
