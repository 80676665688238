import { useContext, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';

import Grid from './Grid';
import SearchCriteria from './SearchCriteria';

const Layout = (props: any) => {
    const [showSearchCriteria, setShowSearchCriteria] = useState(true);
    const { state, dispatch } = useContext(SmartContext);
    const handleToggleSearchCriteria = () => {
        setShowSearchCriteria(!showSearchCriteria);
    };

    return (
        <div>
            <div className="main flex-1">
                <section className="updateadditionalinformation">
                    <div className="container max-1140 px-lg-0 overflow-hidden">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="white-block white-block-notopborderradius p-0 h-100">
                                    <div className="p-4 p-md-5">
                                        <div className="alumni_statistics">
                                            {/* <h2 className="font-26 font-600 mb-0 pb-4">{state.routeInfo.pageName === "support-alumni-school" ? (state.internal.schoolGroupName ?? state.internal.schoolName) : state.internal.schoolName}</h2> */}
                                            {/* <h2 className="font-26 font-600 mb-0 pb-4">{(state.routeInfo.pageName === "support-alumni-school" && state.internal.schoolGroupName) ? state.internal.schoolGroupName : state.internal.schoolName}</h2> */}
                                            <h2 className="font-26 font-600 mb-0 pb-4">{state.internal.schoolName ? state.internal.schoolName : state.internal.schoolGroupName}</h2>

                                            <div className="ActiveListings_present">
                                                {/* Search Criteria */}
                                                {showSearchCriteria && <SearchCriteria />}

                                                {/* <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                                                    <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks my-2">
                                                        <div className="d-flex justify-content-start align-items-center customselect_parnt">
                                                            <select
                                                                name="complete Class 12"
                                                                aria-label="Default select example"
                                                                className="form-select w-120">
                                                                <option value="Action">Show 20</option>
                                                                <option value="Mark as Unseen">20</option>
                                                                <option value="Mark as Seen">30</option>
                                                                <option value="Mark Important">40</option>
                                                                <option value="Unmark Important">50</option>
                                                                <option value="Delete">60</option>
                                                                <option value="Ignore">70</option>
                                                                <option value="Block">80</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-wrap align-items-center justify-content-end">
                                                        <div className="d-flex justify-content-start align-items-center customselect_parnt me-auto mobile-100 mb-3 mb-sm-0"></div>
                                                        <h3 className="font-14 font-500 mb-0 me-2">Showing 1-20 of 220 records</h3>
                                                        <div className="d-flex flex-wrap custom-btn">
                                                            <a className="btn-prev icon-left-arrow" href=""></a>
                                                            <a className="btn-prev icon-right-arrow" href=""></a>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* Grid */}
                                                <Grid />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Layout;
