import { useEffect, useState } from 'react';

const ContactLayout = (props: any) => {

    return (
        <div className="main flex-1">
            <section className="updatebasicinformation">
                <div className="container max-1140 px-lg-0 overflow-hidden">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius p-0 h-100">
                                <div className="border-bottom border-2 custom-border-gray p-3 p-sm-4 pb-3">
                                    <div className="d-flex flex-wrap justify-content-between align-items-center mx-0 mx-sm-1">
                                        <h1 className="font-26 font-600 my-0 my-sm-1 py-2"> Manage Institute Contact</h1>
                                    </div>
                                </div>

                                <div className="job_internship_tab polartabsec radius-6">
                                    <div className="xscroll p-0">
                                    <ul className="nav nav-tabs" id="JobInternshipTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${props.mode === 'admin-institute-create' ? 'active' : ''}`}
                                                    id="basic-info-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#basic-info"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="basic-info"
                                                    aria-selected="false"
                                                    onClick={props.handleAddInstitute}>
                                                    Basic Info
                                                </button>
                                            </li>
                                            {props.id !== 'new' && (
                                                    <>
                                                       <li className="nav-item" role="presentation">
                                                            <button
                                                                className={`nav-link ${props.mode === 'institute-online-preference' ? 'active' : ''}`}
                                                                id="online-preference-tab"
                                                                data-bs-toggle="tab"
                                                                data-bs-target="#online-preference"
                                                                type="button"
                                                                role="tab"
                                                                aria-controls="online-preference"
                                                                aria-selected="false"
                                                                onClick={props.handleOnlinePreference}
                                                            >
                                                                Online Preference
                                                            </button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button
                                                                className={`nav-link ${props.mode === 'admin-institute-contacts' ? 'active' : ''}`}
                                                                id="inst-contacts-tab"
                                                                data-bs-toggle="tab"
                                                                data-bs-target="#inst-contacts"
                                                                type="button"
                                                                role="tab"
                                                                aria-selected="false"
                                                                onClick={props.handleContacts}
                                                            >
                                                                Contacts
                                                            </button>
                                                        </li>   
                                                        <li className="nav-item" role="presentation">
                                                            <button
                                                                className={`nav-link ${props.mode === 'admin-institute-onboarding' ? 'active' : ''}`}
                                                                id="inst-onboarding-tab"
                                                                data-bs-toggle="tab"
                                                                data-bs-target="#inst-onboarding"
                                                                type="button"
                                                                role="tab"
                                                                aria-selected="false"
                                                                onClick={props.handleInstituteOnboarding}
                                                            >
                                                                Institute Onboarding
                                                            </button>
                                                        </li>   
                                                    </>
                                            )}
                                        </ul>
                                    </div>
                                </div>

                                <div className="row m-0 pt-4 mt-2 JobInternshipListing">
                                    <div className="flex-1 polarcontsec tab-content" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="inst-contacts"
                                            role="tabpanel"
                                            aria-labelledby="inst-contacts-tab">
                                            <div className="lstCustForm w-100 formrowpx mt-2 max-700 mx-auto" id="inst-contacts">
                                                <div className="row g-4">{props.children}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ContactLayout;
