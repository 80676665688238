import { useContext, useEffect, useState } from 'react';
import useSmartState from '../../common/hooks/useSmartState';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, handleControlValueChange } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';

const NumberRangeSelectorControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const { setStateValue } = useSmartState();

    const fromControl = control.controlGroup?.[0];
    const toControl = control.controlGroup?.[1];

    const fromDataKey = `${parentDataKey}.${fromControl.id}`;
    const toDateDataKey = `${parentDataKey}.${toControl.id}`;

    const numberValue = getControlValueFromState(fromDataKey, state as State) ?? '';
    const endNumberValue = getControlValueFromState(toDateDataKey, state as State) ?? '';

    const [rangeOption, setRangeOption] = useState('between');

    useEffect(() => {
        if (rangeOption === 'gt') setStateValue(`${parentDataKey}.${toControl.id}`, '');
        else if (rangeOption === 'lt') setStateValue(`${parentDataKey}.${fromControl.id}`, '');
    }, [rangeOption]);

    const getPlaceholderText = (type: 'from' | 'to') => {
        const placeholders: any = {
            from: {
                between: 'From',
                gt: 'Greater than',
                default: '',
            },
            to: {
                between: 'To',
                lt: 'Less than',
                default: '',
            },
        };

        return placeholders[type][rangeOption] || placeholders[type].default;
    };

    return (
        <div className="mb-3">
            {control.props?.label && (
                <label htmlFor={control.id} className="form-label">
                    {`${control.props.label} `}
                </label>
            )}
            <div className="input-group mb-3">
                <select id="numberRangeOption" className="form-select" value={rangeOption} onChange={(e) => setRangeOption(e.target.value)}>
                    <option value="">Select Condition</option>
                    <option value="lt">Less than</option>
                    <option value="gt">Greater than</option>
                    <option value="between">Between</option>
                </select>

                {rangeOption !== 'lt' && (
                    <input
                        id={`${fromControl.id}_from`}
                        type="number"
                        className="form-control"
                        value={numberValue}
                        step="1"
                        placeholder={getPlaceholderText('from')}
                        onChange={(event) => {
                            handleControlValueChange({
                                control: fromControl,
                                value: event.target.value,
                                dataKey: fromDataKey,
                                parentDataKey,
                                state,
                                dispatch,
                            });
                        }}
                    />
                )}

                {rangeOption !== 'gt' && (
                    <input
                        id={`${toControl.id}_to`}
                        type="number"
                        className="form-control"
                        value={endNumberValue}
                        placeholder={getPlaceholderText('to')}
                        onChange={(event) => {
                            handleControlValueChange({
                                control: toControl,
                                value: event.target.value,
                                dataKey: toDateDataKey,
                                parentDataKey,
                                state,
                                dispatch,
                            });
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default NumberRangeSelectorControl;
