import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../../../../../library/Core/SmartContext';
import EditPositionStrengthModal from './EditPositionStrengthModal';
import EditPositionStrengthPageConfig from './FormConfiguration/EditPositionStrengthPageConfig.json';
import { Tooltip } from 'bootstrap';
import { isEmpty } from '../../../../../../library/Core/SmartFunctions';
import ProfileMarkerService from './ProfileMakerService';

type PositionStrengthProps = {
    celebrity: { label: string,isComment :string, toolTip:string};
    wealthyfamily: { label: string,isComment:string,toolTip:string };
    investedinaCompany: { label: string,isComment:string,toolTip:string };
    featuredinHighFortuneRichestList: { label: string,isComment:string,toolTip:string };
    politicalConnection: { label: string,isComment:string,toolTip:string };
    influentialPositionOfPower: { label: string,isComment:string,toolTip:string };
    ownsLuxuryCar: { label: string,isComment:string,toolTip:string };
    featuredinHighTaxPayerList: { label: string,isComment:string,toolTip:string };
    mediaMentions: { label: string,toolTip:string };
    donatedAnyCauseInPast:{label:string,toolTip:string};
    donatedToOtherInstitutes:{label:string,toolTip:string};
    recruitedInPast:{label:string,isComment:string,toolTip:string};
    recruitingFromOtherInstitutes:{label:string,isComment:string,toolTip:string};

};

const PositionStrengthProps: PositionStrengthProps = {
    celebrity: { label: 'Celebrity',isComment:'celebrityComment', toolTip:'Alumnus is a celebrity in any field like cinema, sports, art, business etc'},
    wealthyfamily: { label: 'Wealthy Family',isComment:'wealthyFamilyComment',toolTip:"Alumnus belongs to a wealthy family"},
    politicalConnection: { label: 'Political Connection',isComment:'politicalConnectionComment',toolTip:"Alumnus belongs to a family with politically strong connections" },
    influentialPositionOfPower: { label: 'Influential Position of Power',isComment:'influentialPositionOfPowerComment',toolTip:"Alumnus is in influential position of power like IAS, IPS, Justice etc" },
    ownsLuxuryCar: { label: 'Owns Luxury Car',isComment:'ownsLuxuryCarComment',toolTip:"Alumnus owns one or more luxury cars " },
    investedinaCompany: { label: 'Invested in a Company',isComment:'investedInAcompanyComment',toolTip:"Alumnus has been reported in media to have invested in a company in sole capacity" },
    featuredinHighFortuneRichestList: { label: 'Featured in Fortune Richest List',isComment:'featuredinHighFortuneRichestListComment',toolTip:"Alumnus has featured in any Richest People List published by Forbes Magazine or Bloomberg" },
    featuredinHighTaxPayerList: { label: 'Featured in High Tax Payer List',isComment:'featuredinHighTaxPayerListComment',toolTip:"Alumnus is one of the highest tax payers as per media reports" },
    mediaMentions: { label: 'Media Mentions',toolTip:"Alumnus has been covered in national or international media " },
    donatedAnyCauseInPast: { label: 'Donated to any cause in Past',toolTip:"Alumnus has donated in past for any social cause, political party or NGOs"},
    donatedToOtherInstitutes: { label: 'Donated to other Institutes',toolTip:"Alumnus has donated to any other educational institute"},
    recruitedInPast: { label: 'Recruited in Past',isComment:'recruitedInPastComment',toolTip:"Alumnus has recruited in past from the institute"},
    recruitingFromOtherInstitutes: { label: 'Recruiting from other Institutes',isComment:'recruitingFromOtherInstitutesComment',toolTip:"Alumnus has recruited or is recruiting from any other educational institute"},
};

const keys = Object.keys(PositionStrengthProps) as (keyof PositionStrengthProps)[];

const PositionStrength = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { handleOpenLogsModal } = ProfileMarkerService();

    const handleOpenModal = async (key: string) => {
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showPositionStrengthModal' } });
        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: { 
                    ...state.data, 
                    visibleControl: key,
                    mediaMentions: JSON.parse(state.data.marker?.mediaMentions?.mediaMentions || '[]')
                },
            },
        });
    };
    const model = state.internal.model;
    const [isConfigAvailable, setisConfigAvailable] = useState(false);
    const markers = state.data.marker || [];
    useEffect(() => {
        Promise.resolve(EditPositionStrengthPageConfig)
            .then((config) => {
                const mediaMentionsData = JSON.parse(state.data.marker?.mediaMentions?.mediaMentions || '[]');

                dispatch({
                    type: 'DATA_INIT',
                    payload: {
                        formConfig: config,
                        data: {
                            ...state.data,
                            positionStrength: state.data.marker?.positionStrength || {},
                            mediaMentions: mediaMentionsData
                        },
                        domain: new Map([...state.domain]),
                        internal: state.internal,
                        routeInfo: state.routeInfo,
                    },
                });
                setisConfigAvailable(true);
            })
            .catch((error) => {
                console.error('Error fetching page config:', error);
            });
    }, []);

    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, [state.data.positionStrength]);

    useEffect(() => {
        const positionStrengthData = { ...state.data.positionStrength };
        Object.keys(PositionStrengthProps).forEach((key) => {
            const positionStrengthKey = key as keyof PositionStrengthProps;
            const prop = PositionStrengthProps[positionStrengthKey];
            if (prop && typeof prop === 'object' && 'isComment' in prop) {
                const isCommentField = prop.isComment;
                if (positionStrengthData[positionStrengthKey] === false) {
                    if (isCommentField) {
                        positionStrengthData[isCommentField as keyof typeof positionStrengthData] = '';
                    }
                }
            }
        });

        const mediaMentionsPayload = state.data.positionStrength && state.data.positionStrength.ismediaMentions
        ? state.data.mediaMentions
        : [{}];
     
        // Compare current mediaMentions with the new payload
        if (JSON.stringify(state.data.mediaMentions) !== JSON.stringify(mediaMentionsPayload)) {
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: 'mediaMentions', value: mediaMentionsPayload },
            });
        }
    
        if (JSON.stringify(state.data.positionStrength) !== JSON.stringify(positionStrengthData)) {
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: 'positionStrength', value: positionStrengthData },
            });
        }
    },[state.data.positionStrength, state.data.mediaMentions, dispatch]);

    const isMarkerEnabled = (key: string): boolean => {
        const value = markers?.positionStrength?.[key];

        if (value === true) return true;
        if (typeof value === 'string') {
          if (!isEmpty(value)) {
            return true;
          }
        }

        if (key === 'mediaMentions') {
            const nestedValue = markers?.mediaMentions?.mediaMentions;
            if (typeof nestedValue === 'string') {
                try {
                    const parsedValue = JSON.parse(nestedValue);
                    if (Array.isArray(parsedValue)) {
                        return parsedValue.length > 0 && parsedValue.some(item => {
                            if (typeof item === 'object' && item !== null) {
                                return Object.keys(item).length > 0;
                            }
                            return true;
                        });
                    } else if (typeof parsedValue === 'object' && parsedValue !== null) {
                        return Object.keys(parsedValue).length > 0;
                    }
                } catch (e) {
                    console.error('Failed to parse nestedValue as JSON:', e);
                }
            } else if (Array.isArray(nestedValue)) {
                return nestedValue.length > 0;
            } else if (typeof nestedValue === 'object' && nestedValue !== null) {
                return Object.keys(nestedValue).length > 0;
            }
        }
        return false;
      };


    return (
        <div
            className="tab-pane fade active show"
            id="v-pills-position-strength"
            role="tabpanel"
            aria-labelledby="v-pills-position-strength-tab">
            <div className="max-470 mx-auto">
                <div className="row g-3 align-items-center">
                    {keys.map((key) => {
                        // const markerExists = key in markers?.positionStrength;
                        const markerExists = key === 'mediaMentions' 
                        ? markers?.mediaMentions?.mediaMentions?.length >= 1 
                        : key in markers?.positionStrength;

                        const markerEnabled = isMarkerEnabled(key);

                        const comment = (PositionStrengthProps[key] as { label: string; isComment?: string }).isComment 
                        ? state.data.positionStrength?.[(PositionStrengthProps[key] as { label: string; isComment?: string }).isComment!] 
                        : '';

                        const value = markerExists ? markers.positionStrength[key] : null;
                        let mediaMentionsCount = 0;
                        const mediaMentions = markers?.mediaMentions?.mediaMentions;
                            if (key === 'mediaMentions') {
                                if (Array.isArray(mediaMentions)) {
                                    mediaMentionsCount = mediaMentions.length;
                                } else if (typeof mediaMentions === 'string') {
                                    try {
                                        const parsed = JSON.parse(mediaMentions);
                                        if (Array.isArray(parsed)) {
                                            mediaMentionsCount = parsed.length;
                                        }
                                    } catch (e) {
                                        console.error('Error parsing mediaMentions string:', e);
                                    }
                                }
                            }

                        return (
                            <div className="col-12" key={key}>
                                <div className="row g-3 align-items-center">
                                    <div className="col">
                                        <div className={`listed-border ${markerEnabled ? '' : 'disabled'}`}>
                                            <i className="tick"></i>
                                            <p onClick={() => handleOpenModal(key)}>
                                                {PositionStrengthProps[key].label} 
                                                {key === 'mediaMentions' && markerEnabled ? ` - ${mediaMentionsCount}` : ''}
                                                {value ? ` - ${value == 1 ? `Yes` : value}` : ''}
                                            </p>
                                            {comment && (
                                            <span className="note-wrap" 
                                                data-bs-toggle="tooltip" 
                                                data-bs-html="true" 
                                                title={comment}>
                                                <i className="icon-note"></i>
                                            </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i
                                            className="icon-info"
                                            data-bs-toggle="tooltip"
                                            title=""
                                            data-bs-original-title={PositionStrengthProps[key].toolTip}
                                            aria-label={PositionStrengthProps[key].toolTip}></i>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div className="col-12 pt-3">
                        <button className="btn btn-dark-blue w-auto height-40 px-4 d-flex align-items-center mx-auto" onClick={() => handleOpenLogsModal()}>
                        <span className="text-white">View Update Logs</span>
                        </button>
                    </div>
                </div>
            </div>
            {isConfigAvailable && <EditPositionStrengthModal userdata={{ model }} />}
        </div>
    );
};

export default PositionStrength;
