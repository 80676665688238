import { isFormValid } from '../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../library/Core/SmartTypes';
import InstituteService from './InstituteService';
import { setError } from '../../../library/Core/SmartFunctions';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export const onMobileCountryCodeChange = (props: ControlOnChangeArguments) => {
    const mobileCountryCode = props.value;
    const mobileNumber = props.row['mobileNumber'];
    let errorMessage = '';
    if (mobileNumber?.length == 0) {
        errorMessage = 'Please enter mobile number';
        setError('socialLinks.mobileNumber', [errorMessage], props.dispatch);
        return;
    }

    if (mobileCountryCode != '91') {
        setError('socialLinks.mobileNumber', [], props.dispatch);
        return;
    }

    errorMessage = 'Please enter a valid mobile number';
    if (
        (mobileNumber?.length > 10 || mobileNumber?.length < 10 || parseInt(('' + mobileNumber).charAt(0)) < 6) &&
        mobileCountryCode == 91
    ) {
        setError('socialLinks.mobileNumber', [errorMessage], props.dispatch);
    }
};

export const onMobileNumberChange = (props: ControlOnChangeArguments) => {
    const mobileCountryCode = props.row['mobileCountryCode'];
    const mobileNumber = props.value;

    let errorMessage = '';

    if (mobileNumber?.length == 0) {
        errorMessage = 'Please enter mobile number';
        props.errorMessages.push(errorMessage);
        return;
    }
    if (mobileCountryCode != '91') {
        setError('socialLinks.mobileNumber', [], props.dispatch);
        return;
    }
    errorMessage = 'Please enter a valid mobile number';

    if ((mobileNumber?.length > 10 || mobileNumber?.length < 10 || parseInt(props.value.charAt(0)) < 6) && mobileCountryCode == 91) {
        props.errorMessages.push(errorMessage);
    }
};

export const onHandleSwitchChange = (props: ControlOnChangeArguments) => {
    const dataKey = `is${capitalizeFirstLetter(props.control.id)}`;
    props.dispatch({
        type: 'CONTROL_VALUE_CHANGE',
        payload: {
             dataKey : `onboarding.${dataKey}` , 
             value   : false 
        }
    });
};

const capitalizeFirstLetter = (string:any) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const handleCreate = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void
) => {
    if (!isFormValid(state, dispatch)) {
        alert('Please correct the form errors!');
        return;
    }
    console.log(state.data.socialLinks);
};
