import { useContext, useEffect, useRef, useState } from 'react';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, formatDate, getControlValueFromState, handleControlValueChange, parseDateStr } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const YearAndMonthSelector = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl
    const convertToMMYYYY = (val: string) => `${new Date(val).getFullYear()}-${new Date(val).getMonth() + 1}`;
    const data = getControlValueFromState(dataKey, state as State);
    const formattedDate = data ? formatDate(new Date(data), 'mo, yyyy') : '';
    // AGS-662-start @dt 3rd.Oct,2K23 @by RG;
    // const convertToDate = (val: string) => formatDate(new Date(`${val}-15`), 'yyyy-mm-dd');
    const convertToDate = (val: string) => {
        let inputDate = new Date(`${val}`);
        let resultDate: any = new Date(inputDate.setDate(inputDate.getDate() + 14));
        let retDate = formatDate(resultDate, 'yyyy-mm-dd');

        return retDate;
    };
    // AGS-662-end;

    const readOnly = evaluateExpression(
        control.readOnlyExpression,
        state?.data,
        getControlValueFromState(parentDataKey as string, state as State)
    );

    const maxDate = parseDateStr(control.props?.max);

    useEffect(() => {
        const errorMessages = validateFormField(
            control,
            data,
            state,
            control?.props?.label,
            dataKey,
            getControlValueFromState(parentDataKey as string, state as State)
        );
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });
    }, []);

    return (
        <>
            <label htmlFor={control.id} className="form-label w-100">
                {`${control.props.label} ${control.props.required ? '*' : ''}`}
            </label>
            {/* AGS-662-start @dt 3rd.Oct,2K23 @by RG; */}
            {/* <input
                type="month"
                id={control.id}
                className={`form-control `}
                min={parseDateStr(control.props?.min, 'YYYY-MM')}
                max={parseDateStr(control.props?.max, 'YYYY-MM')}
                value={formattedDate || control.props.defaultValue || ''}
                disabled={readOnly ?? false}
                required={control.props?.required}
                onChange={(event) =>
                    handleControlValueChange({ control, value: convertToDate(event.target.value), dataKey, parentDataKey, state, dispatch })
                }
                ref={formControlRef}
            /> */}
            {/* <div className="input-group date">
            <input type="text" className="form-control"><span className="input-group-addon"><i className="glyphicon glyphicon-th"></i></span>
            </div> */}

            <div className="react-datepicker-cnt">
                <DatePicker
                    disabled={readOnly ?? false}
                    id="month-year-picker"
                    className="form-control form-date"
                    minDate={control?.props?.customProperties?.minDate ? new Date('' + eval(`${control?.props.customProperties.minDate}`)) : null}
                    maxDate={control?.props?.customProperties?.minDate ? new Date('' + (eval(`${control?.props.customProperties.minDate}`)+1) + '-12-31') : null}
                    selected={control?.props?.customProperties?.minDate ? new Date('' + (eval(`${control?.props.customProperties.minDate}`)) + '-01-01') : null}
                    value={formattedDate || control.props.defaultValue || ''}
                    showMonthYearPicker
                    dateFormat="MMMM, yyyy"
                    onChange={(dateVal: any) =>
                        handleControlValueChange({ control, value: convertToDate(dateVal), dataKey, parentDataKey, state, dispatch })
                    }
                />
            </div>

            {/* AGS-662-end; */}
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default YearAndMonthSelector;
