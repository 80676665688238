import { useContext, useEffect, useState } from 'react';
import { ActionMeta, OnChangeValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState } from '../Core/SmartFunctions';
import { DomainElement, SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';
import './custom-control.css';

const SelectControlV2 = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const [options, setOptions] = useState([] as any[]);

    useEffect(() => {
        const errorMessages = validateFormField(
            control,
            data,
            state,
            control?.props?.label,
            dataKey,
            getControlValueFromState(parentDataKey as string, state as State)
        );
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });
    }, []);

    useEffect(() => {
        const controlDomain = state?.domain?.get(control.props.domainCategoryCode) as DomainElement[];
        setOptions(controlDomain.map((domain) => ({ value: domain.code, label: domain.value })));
    }, []);

    const tmpVal: DomainElement = (state?.domain?.get(control.props.domainCategoryCode) as DomainElement[]).find(
        (domain) => domain.code == data
    ) as DomainElement;

    const selectedValue = tmpVal?.code ? { value: tmpVal?.code, label: tmpVal?.value } : null;

    const loadOptions = (inputValue: string, callback: (options: any[]) => void) => {
        if (inputValue.length < 3) return;
        return Promise.resolve(options.filter((option) => option.label.toUpperCase().includes(inputValue.toUpperCase())));
    };

    const handleOnChange = (newValue: OnChangeValue<any, any>, actionMeta: ActionMeta<any>) => {
        const value = newValue ? newValue.value : null;
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey, name: control.id, value, errorMessages: [] } });
    };

    return (
        <>
            {control.props?.label && (
                <label htmlFor={control.id} className="form-label">
                    {`${control.props.label} `}
                </label>
            )}
            <div className="input-group">
                <div className="input-group-text bg-white rounded-start">
                    <i className="bi bi-search fs-6"></i>
                </div>
                <div className="flex-fill form-control border-start-0 p-0" style={{}}>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={loadOptions}
                        onChange={handleOnChange}
                        defaultValue={selectedValue}
                        placeholder={control.props.placeholder}
                        isClearable
                    />
                </div>
            </div>
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default SelectControlV2;
