import React, { useEffect } from 'react';
import { axiosClient } from '../../../library/Service/axiosClient';

const HESClient = (props) => {
    useEffect(() => {
        console.log('hes props', props);
        
        const loadScript = async () => {
            try {
                const script = document.createElement('script');
                script.type = 'module';
                script.src = 'https://pkg.humanesources.com/js/@humanesources.com/assessment-client@2/dist/assessment-client.es.js';
                script.async = true;
                const apiUrl = `${process.env.REACT_APP_COMMON_API}/keytwosuccess/usertestupdates`;
                document.body.appendChild(script);
                
                await new Promise((resolve, reject) => {
                    script.onload = resolve;
                    script.onerror = (error) => {
                        console.error('Script loading error:', error);
                        reject(error);
                    };
                });

                const checkClientReady = () => {
                    return new Promise((resolve, reject) => {
                        console.log('Checking if client is ready');
                        const maxAttempts = 10;
                        let attempts = 0;

                        const interval = setInterval(() => {
                            console.log('Interval attempt:', attempts);
                            if (window.startHesAssessmentClient) {
                                console.log('startHesAssessmentClient available');
                                clearInterval(interval);
                                resolve();
                            } else if (attempts >= maxAttempts) {
                                clearInterval(interval);
                                reject(new Error('startHesAssessmentClient is not available'));
                            }
                            attempts++;
                        }, 200);
                    });
                };

                await checkClientReady();

                window.startHesAssessmentClient({
                    apiEnvironment: 'sg_production',
                    configurationId: props.testResponse.testcode,
                    userId: props.testResponse.userId,
                    apiToken: props.testResponse.token,
                    assessmentId: props.testResponse.assessmentId,
                    displayBadging: true,
                    readOnly: false,
                    onAssessmentStart: (assessment) => {
                        console.log('Assessment started', assessment);
                        try {
                            window.history.replaceState({}, null, `${window.location}/${assessment.id}`);
                        } catch (error) {
                            console.error('Error updating history state:', error);
                        }                        
                    },
                    onAssessmentComplete: async (assessment) => {
                        console.log('Assessment Complete', assessment);
                        try {
                            const response = await axiosClient().post(apiUrl, {
                                id: assessment.id,
                                completed_at: assessment.completedAt
                            });
                            console.log("End Date Updated: ", response.data);
                        } catch (error) {
                            console.error('Error updating end date:', error);
                        }
                    },
                });
            } catch (error) {
                console.error('Error loading the test window:', error);
            }
        };
        
        if (props.testResponse) {
            loadScript();
        } else {
            console.error('props.testResponse is not available');
        }
    }, [props.testResponse]);

    return <div id="app"></div>;
};

export default HESClient;
