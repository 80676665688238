import { useState, useContext } from 'react';
import { SmartContext } from '../../../../../../library/Core/SmartContext';

const EditInterestsFormLayout = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const showFlag = state?.modalFlags?.showInterestsModal;

    return (
        <div
            id="update-influence-data"
            className={`modal fade ${showFlag ? 'show' : ''}`} // removed vh-100 and show class
            tabIndex={-1}
            role="dialog"
            style={{ display: showFlag ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-dialog-centered max-800 mx-auto">
                <div className="modal-content">
                    <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Add Remark{' '}
                        </h5>
                        <button
                            type="button"
                            className="btn-close btn-close-small"
                            onClick={() => {
                                dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showInterestsModal' } });
                            }}
                            aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4"> {props.children}</div>
                </div>
            </div>
        </div>
    );
};

export default EditInterestsFormLayout;
