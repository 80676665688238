import React, { useContext, useEffect, useState, ChangeEvent, FormEvent } from 'react';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
// import PageBuilderModal from '../../../../library/Builders/PageBuilder/PageBuilderModal';
import EditSubjectWiseMarksFormLayout from './EditSubjectWiseMarksFormLayout';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { isEmpty, isFormValid, setError } from '../../../../library/Core/SmartFunctions';
import StudentService from '../../../../services/student.service';

const EditSubjectWiseMarksModal = ({ userdata }: any) => {
    const { state, dispatch } = useContext(SmartContext);

    const handleSubmit = async (
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void
    ) => {
        event.preventDefault();

        const customValidationPassed = validateMarksByCurriculum(state, dispatch);
        const isSubjectEntered=isValidateSubject(state,dispatch)
        
        if (isSubjectEntered || customValidationPassed) {
            dispatch({ type: 'SHOW_ERRORS' });
            return;
        }

        const subjectWiseMarksUpdateId = state?.data?.subjectWiseMarksUpdateId;
        if (subjectWiseMarksUpdateId) {
            const filteredSchool = state?.data?.schools.find((school: any) => school.id === subjectWiseMarksUpdateId);

            if (filteredSchool) {
                const mutableSchool = { ...filteredSchool };
                mutableSchool.subjectWiseMarks = JSON.stringify(state?.data?.subjectWiseMarks);

                const updatedSchools = state.data.schools.map((school:any) => {
                    if (school.id === subjectWiseMarksUpdateId) {
                        return mutableSchool;
                    } else {
                        return school;
                    }
                });
    
                StudentService.pageSave(
                    state.routeInfo.id,
                    'student-education-subjectwise-marks',
                    StudentService.toStudentEntity({
                        id: state.routeInfo.id,
                        schools: updatedSchools,
                    })
                ).then(() => {
                    window.location.reload();
                    // dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showsubjectmarksModal' } });
                    // state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile/education`);
                });
            } else {
                console.log("Id not found1");
            }
        } else {
            console.log("Id not found2");
        }
        
    };

    const isValidateSubject = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        const schoolsData = state?.data?.subjectWiseMarks;
        if (schoolsData && Array.isArray(schoolsData)) {
            let errorFound = false; // Track if any error is found
            schoolsData.forEach((schoolsData, index) => {
                if (isEmpty(schoolsData?.subjectCode)) {
                    setError(`subjectWiseMarks.${index}.subjectCode`, ["Subject Name is required"], dispatch);
                    errorFound = true; 
                }
            });
    
            if (errorFound) {
                return true; 
            }
        } else {
            console.error("Data is missing or invalid");
        }
      }

      const validateMarksByCurriculum = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        const schoolsData = state?.data?.subjectWiseMarks;
        if (!schoolsData || !Array.isArray(schoolsData)) {
            console.error("Schools data is missing or invalid");
            return true;
        }
    
        let errorFound = false;
    
        schoolsData.forEach((school: any, index: any) => {
            const marks = school?.marks;
            let min, max;
            
            schoolsData.forEach((school: any, index: any) => {
                if(state?.data?.curriculum != 2){
                    const marks = school?.marks;
                    if (isNaN(marks)) { // Check if marks is not an integer here marks comes in string 
                        setError(`subjectWiseMarks.${index}.marks`, [`Marks must be an integer value`], dispatch);
                        errorFound = true;
                        return; 
                    }
               }
            });
    
            switch (state?.data?.curriculum) {
                case '2':
                    const cieGrade = school?.cieGrade;
                    let sum: number = Object.values(cieGrade).reduce((acc: number, currentValue) => acc + Number(currentValue), 0);
                    min = school?.classCode == 10 ? 5 : 3;
                    if (sum < min) {
                        setError(`subjectWiseMarks.${index}.cieGrade`, [`Total should sum to ${min} or higher in any combination from the dropdowns.`], dispatch);
                        errorFound = true;
                    } else {
                        setError(`subjectWiseMarks.${index}.cieGrade`, [``], dispatch);
                    }
                    break;
    
                case '5':
                    min = 24;
                    max = 45;
                    if (marks < min || marks > max) {
                        setError(`subjectWiseMarks.${index}.marks`, [`Please ensure that the marks provided fall within the acceptable range of ${min} to ${max}`], dispatch);
                        errorFound = true;
                    }
                    break;
    
                case '1':
                    min = 1;
                    max = 10;
                    if (marks < min || marks > max) {
                        setError(`subjectWiseMarks.${index}.marks`, [`Please ensure that the marks provided fall within the acceptable range of ${min} to ${max}`], dispatch);
                        errorFound = true;
                    }
                    break;
    
                default: // Default validation
                    min = 1;
                    max = 100;
                    if (marks < min || marks > max) {
                        setError(`subjectWiseMarks.${index}.marks`, [`Please ensure that the marks provided fall within the acceptable range of ${min} to ${max}`], dispatch);
                        errorFound = true;
                    }
                    break;
            }
        });
    
        console.log("errorFound", errorFound);
        return errorFound; 
    };

    useEffect(() => {
        dispatch({ type: 'ADD_ACTIONS', payload: { ...state.actions, SUBMIT: handleSubmit } });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <EditSubjectWiseMarksFormLayout>{!state.flags.isDataLoading && <PageBuilder />}</EditSubjectWiseMarksFormLayout>
        </SmartContext.Provider>
    );
};

export default EditSubjectWiseMarksModal;
