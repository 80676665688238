import { useContext } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { SimpleFormControlArguments } from '../Core/SmartTypes';
import SelectControl from './SelectControl';
import TextControl from './TextControl';

const AmountWithCurrencyControl = (props: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = props;

    return (
        <div className="d-flex flex-wrap justify-content-between align-content-start w-100">
            <label htmlFor={control.id} className="form-label w-100">
                {`${control.props.label} `}
            </label>
            {/* <FormBuilderChild controls={control.controlGroup} parentDataKey={parentDataKey as string} padding="" /> */}
            <div className="input-group">
                <SelectControl
                    control={control.controlGroup[0]}
                    dataKey={(parentDataKey as string) + '.' + control.controlGroup[0].id}
                    parentDataKey={parentDataKey as string}
                    handleChangeEvent={undefined}
                />
                <SelectControl
                    control={control.controlGroup[1]}
                    dataKey={(parentDataKey as string) + '.' + control.controlGroup[1].id}
                    parentDataKey={parentDataKey as string}
                    handleChangeEvent={undefined}
                />
                <TextControl
                    control={control.controlGroup[2]}
                    dataKey={(parentDataKey as string) + '.' + control.controlGroup[2].id}
                    parentDataKey={parentDataKey as string}
                    handleChangeEvent={undefined}
                />
            </div>
        </div>
    );
};

export default AmountWithCurrencyControl;
