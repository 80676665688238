import { useContext, useEffect, useRef, useState } from 'react';
import useSmartState from '../../../common/hooks/useSmartState';
import FormBuilder from '../../../library/Builders/FormBuilder';
import { SmartContext } from '../../../library/Core/SmartContext';
import { FormSection } from '../../../library/Core/SmartTypes';
import ManageAlumniService from './ManageAlumniService';
import FiltersApplied from '../../../library/SimpleControls/FiltersApplied';

const SearchCriteria = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const searchFormConfig = state.formConfig?.sectionRepository[0] as FormSection;
    const [isExpanded, setIsExpanded] = useState(true);

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        ManageAlumniService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) =>
            setStateValue('alumniList', response.data)
        );
    }, [state.data.searchCriteria]);

    const handleClearAll = () => {
        const defaultCriteria = ManageAlumniService.getDefaultSearchCriteria(state.routeInfo.pageName);

        defaultCriteria.campusCode = [];
        defaultCriteria.programId = [];
        defaultCriteria.passOutYear=[];
        defaultCriteria.workingStatusCode=[];
        defaultCriteria.userStatusCode=[];
        defaultCriteria.preferenceFieldOfStudy=[];
        defaultCriteria.careerPreferenceOrder=[];
        defaultCriteria.preferenceCountryCode=[];
        defaultCriteria.countryPreferenceOrder=[];
        defaultCriteria.notUpdated=[];
        defaultCriteria.notSure=[];
        defaultCriteria.preferenceUniversityId=[];
        defaultCriteria.passOutYear=[];
        defaultCriteria.educationOrEmploymentCurrentStatusCode=[];

        defaultCriteria.countryCode= [];
        defaultCriteria.cityCode= [];
        defaultCriteria.residingCityCode= [];
        defaultCriteria.residingCountryCode= [];
        defaultCriteria.collegeCountryCode= [];

        defaultCriteria.collegeCityCode= []
        defaultCriteria.fieldOfStudyCode= []
        defaultCriteria.notableCode= []
        defaultCriteria.instituteId= []
        defaultCriteria.pursuingEducationLevelCode= []
        defaultCriteria.workingStatusCode=[]

        document.querySelectorAll('.dropdown .form-select, .css-1dimb5e-singleValue').forEach(element => {
            element.textContent = ''; 
        });
        
        setStateValue('searchCriteria', defaultCriteria);
    };

    return (
        <>
            <div className="fitergroup mb-3">
                <div id="collapseOne" className="accordion-collapse collapse show">
                    <div className="accordion-body p-4">
                        <div className="row g-3">
                            <FormBuilder key="searchCriteria" section={searchFormConfig} dataKey="searchCriteria" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="filterresult">
                <FiltersApplied dataKey="searchCriteria" onClearAll={handleClearAll} />
            </div>
        </>
    );
};

export default SearchCriteria;
