import { axiosClient } from '../../../library/Service/axiosClient';
import { REPORT_CONFIG } from '../../admin/reports/report-config';
import pageConfig from './PageConfigList.json';

class InstituteLisingService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = InstituteLisingService.getDefaultSearchCriteria();

        return Promise.all([
            this.getPageConfig(),
            this.search(searchCriteria, params.pageName),
           // this.getSchoolSearchFilterDomains(params.pageName),
        ]).then((values) => {
            state.formConfig = values[0];
            state.data = {
                alumniList: values[1].data ?? [],
                searchCriteria: { ...searchCriteria, schoolId: sessionStorage.getItem('user-school-id') },
                //filterDomains: values[2].data,
                reportConfig: REPORT_CONFIG[params.pageName],
            };
            state.routeInfo = params;
            state.internal = { gridState: {} };
            return Promise.resolve(state);
        });
    }

    static search(searchCriteria: any, pageName: string): any {
        const config = REPORT_CONFIG[pageName];
        const payload = InstituteLisingService.sanitizeSearchCriteria(searchCriteria,);
        return axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/${config.SEARCH_API}`, payload);
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    static searchCount(searchCriteria: any, pageName: string) {
        const criteria = this.sanitizeSearchCriteria(searchCriteria);
        return axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/institute/all-institute-count`, criteria);
    }

    static sanitizeSearchCriteria(searchCriteria: any) {
        return searchCriteria;
    }

    static getDefaultSearchCriteria(): any {
        return {
            instituteGroupId: [],
            instituteCountryCode:[],
            instituteCityCode:[],
            instituteTypeCode:[],
            createdDateRange:[],
            programDupdatedDateRangeuration:[],
            hasActive:[]
        };
    }
}

export default InstituteLisingService;
