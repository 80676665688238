import React, { useContext, useState, useEffect } from 'react';
import Select from 'react-select';
import { SmartContext } from '../../library/Core/SmartContext';
import ImgCollegeLogo from '../../styles/images/university_melbourne.jpg';
import CollegeService from './CollegeService';
import { useLoaderData } from 'react-router-dom';
import { State } from '../../library/Core/SmartTypes';
import { GlobalContext } from '../../library/Core/GlobalContext';
import Pagination from './Pagination';
import loaderImg from '../../styles/images/load_more_icon.jpg';

const TopCollegeSearchGrid = ({ fetchDepartment , fetchCourse ,fetchCountry , fetchState, fetchCity , hasRanking , loading , onLoaderChange }: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { formConfig, data, internal } = useLoaderData() as State;
    const { globalState } = useContext(GlobalContext);
    const [search, setSearch] = useState('');
    // const [totalCnt, setTotalCntVal] = useState(0);
  
    const topCollegeList = state?.data?.topCollegeList;
    // var total_count = topCollegeList.total_count;
    // setTotalCntVal(topCollegeList.total_count);

    useEffect(() => {
        if(search.length>0){
            topCollegeFilter(search);
        }
    }, [search]); // Call the filter function whenever the search term changes


    function topCollegeFilter(searchVal:any): Promise<any> {
        onLoaderChange(true);
        var topCollegeUrl = `?has_ranking=1&search=${searchVal}&course_id=${fetchDepartment}&sub_course_id=${fetchCourse}&country_id=${fetchCountry}&state_id=${fetchState}&city_id=${fetchCity}`;
        // console.log(topCollegeUrl); 

        return Promise.all([
            CollegeService.getTopCollegeFilter(topCollegeUrl),
        ]).then((response) => {
            onLoaderChange(false);

            dispatch({
                type: 'DATA_INIT',
                payload: { formConfig,
                           data:{...data,topCollegeList: response[0]?.data.data ?? []}, 
                           domain: globalState.domain,
                           internal,
                           flags:{isDataLoading:false} },
            });

            return Promise.resolve(state);

        });
    }

    function courseOnChange(e:any) {
        setSearch(e.target.value);
        if(e.target.value.length==0){
          topCollegeFilter('');
        }
    }

    function clearInput(e:any) {
        setSearch('');
        topCollegeFilter('');
       
    }

    return (
     <>
     
    <div className="flex-1 polarcontsec tab-content" id="myTabContent">

        {/* Display loader if loading state is true */}
        {loading &&  
        <div className='text-center w-100'>
            <a className="load_more_btn">
                <img src={loaderImg} /> Load More
            </a>
        </div>
        
        }

        <div className="ActiveListings_present">
            
       
            {/* <div className="d-flex flex-wrap align-items-end mb-2 w-100">      
                <div className="d-flex flex-wrap align-items-center">
                                                                             
                </div>
                
            </div> */}

           <div className='row g-2 align-items-center mb-2'>
             
             {!loading &&  topCollegeList.total_count > 0 ? (  
                <div className='col-sm'>
                    <h3 className="font-18 font-500 mb-0">{topCollegeList.total_count} count of colleges found</h3>
                </div>
              ):(
                <div className='col-sm'>
                    <h3 className="font-18 font-500 mb-0">0 count of colleges</h3>
                </div>
              )}

                <div className='col-sm-auto position-relative search_college'>
             
                        <input type="text" placeholder='Search' className='ms-auto border-0 p-2 form-control form-search ps-5' value={search} required onChange={courseOnChange}/>
                        <i className='icon icon-cross' onClick={clearInput}></i>
              
                </div>
            </div>
        

        {!loading && topCollegeList.total_count > 0 ? (  
          <>     
           
           <div className="row mt-0 college-details mb-4">
                        <div className="col-md-12" id="collegeBlock">
                           
                         {topCollegeList.data?.map((data: any) => (

                
                            <div className="college_blk">                                                                
                                <div className="collegeName d-flex flex-wrap">
                                    <div className="colllegeLogo me-3">
                                        <img src={data.logo} alt="university melbourne" width="84" className="img-fluid"/>
                                    </div>                                                                  
                                    <div className="collegeNameDetail flex-1">
                                        <h3 className="mb-0">{data.name}</h3>
                                        <label className="tick font-13 font-500 d-block">Rank: #{data.college_rank}th Times Higher</label>  
                                        <label className="tick font-14 font-500 me-3"><i aria-hidden="true" className="icon-location-pin blue-text  mt-1 me-2"></i>{data.city},{data.state},{data.country}</label>
                                        
                                    </div>
                                    <div className="toggle-link">
                                        <a href="javascript:;" data-bs-toggle="collapse" data-bs-target={"#Kamran" + data.id} aria-expanded="false" className="btn btn-green height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center align-items-center collapsed"><span className="font-14 font-500 d-flex align-items-center justify-content-center">{data.campus_size} Rankings</span><span className="icon-down-arrow text-white font-13 ms-2"></span></a>
                                    </div>
                                    
                                    <div className="college_detail_price_deadline d-block w-100 mt-3">
                                        <div className="row w-auto mb-0">
                                            <div className="col-md-auto col-6 mb-4 mb-md-0">
                                                <div className="college_detail_price w-100 d-flex flex-wrap">
                                                    <div className="icon fee me-2"><i className="icon-dollor"></i></div>
                                                    <div className="font-13 font-500">Fees / Year
                                                      <label className="font-16 d-block">
                                                        {data.average_tuition_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                      </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-auto col-6 mb-4 mb-md-0">
                                                <div className="college_detail_price w-100 d-flex flex-wrap">
                                                    <div className="icon medal me-2"><i className="icon-loan"></i></div>
                                                    <div className="font-13 font-500">Scholarships
                                                        <label className="font-16 d-block">{data.scholrship_count}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-4 col-md-auto col-12">                                                                                         
                                                <div className="college_detail_price w-100 d-flex flex-wrap">
                                                    <div className="icon medal me-2"><i className="icon-book"></i></div>
                                                    <div className="font-13 font-500">Rankings
                                                        <label className="font-16 d-block">{data.rankings.ranking_given_by}</label>
                                                    </div>
                                                </div>
                                            </div> */}
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                                
                    <div className="college-list collapse" id={"Kamran" + data.id} data-bs-parent="#collegeBlock"> 
                        <div className="college_detail">
                           
                        {data.rankings?.map((res: any) => (  
                           <div className="row g-3">
                                <div className="col-lg-3 col-md-6 col-6">
                                    <div className="college px-3 h-100">
                                      <div className="college-brife m-0 h-100 d-flex flex-column justify-content-between"><label className="text-center w-100"><a className="font-15 font-500 text-center min-h-45 d-flex align-items-center justify-content-center">{res.ranking_given_by}</a></label>
                                       <div className="my-3 font-14 font-500 text-center">{res.year}</div>
                                       <div className="font-16 font-500 text-center">{res.ranking_from} </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                          ))}   
                            
                            <div className="row g-3 mt-2">
                                <div className="col-lg-6 col-md-6">
                                    <div className="colondetail">
                                        <div className="colondetailRow">
                                            <div className="colondetailRowLabel">Year of Establishment</div>
                                            <span className="colon">:</span>
                                            <div className="colondetailRowBrife">{data.establish_year}</div>
                                        </div>
                                        <div className="colondetailRow">
                                            <div className="colondetailRowLabel">Total Students</div>
                                            <span className="colon">:</span>
                                            <div className="colondetailRowBrife">{data.student_count > 0 ? data.student_count : '-'}</div>
                                        </div>
                                            <div className="colondetailRow">
                                                <div className="colondetailRowLabel">No. of relevant Courses</div>
                                                <span className="colon">:</span>
                                                <div className="colondetailRowBrife">{data.course_count}</div>
                                            </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-6 col-md-6">
                                    <div className="colondetail">
                                        <div className="colondetailRow">
                                            <div className="colondetailRowLabel">Related Scholarships</div>
                                            <span className="colon">:</span>
                                            <div className="colondetailRowBrife">{data.scholrship_count}</div>
                                            </div>
                                            <div className="colondetailRow">
                                                <div className="colondetailRowLabel">Acceptance Rate</div>
                                                <span className="colon">:</span>
                                                <div className="colondetailRowBrife">{data.acceptance_ratio > 0 ? data.acceptance_ratio : '-'}</div>
                                            </div>
                                    </div>
                                </div>

                            </div>  
                            
                        </div> 

                    </div>

                  </div>                                                
                        ))}
                       
                </div>
                        
            </div>
            
          

                {/* Pagination Call */}
                <Pagination 
                result={topCollegeList}
                search = {search}
                fetchDepartment = {fetchDepartment}
                fetchCourse = {fetchCourse}
                fetchCountry = {fetchCountry}
                fetchState = {fetchState}
                fetchCity = {fetchCity}
                hasRanking = {hasRanking}
                loading = {loading}
                onLoaderChange = {onLoaderChange}
                />
         </>
          ) : (
            <div></div>
          )}

           

          </div>
    </div>


    </>
    );


};

export default TopCollegeSearchGrid;
