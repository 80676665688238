import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { SmartContext } from '../../../library/Core/SmartContext';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import Layout from './Layout';
import ManageEventGrid from './ManageEventGrid';
import SessionContext from '../../../library/Core/SessionContext';
import useManagementDashboard from '../../management/dashboard/useManagementDashboard';



const ManageEvent = () => {
    //alert('manage event')
    const { globalState } = useContext(GlobalContext);
    const { sessionState } = useContext(SessionContext);
    const { schoolDomain, invitedBy } = useParams();
    console.log("sessionState",sessionState)
    const { getCampuses } = useManagementDashboard();

    const campuses = getCampuses();
    
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;


    useEffect(() => {
        // let schoolId = '';
        // let school_settings:any = JSON.parse(localStorage.getItem('school_settings') ?? '{}');
        
        const domain = new Map([...globalState.domain]);
        // domain.set('INSTITUTE_PROGRAM_CODE', getInstituteProgramDomain(school_settings?.programs));

        
        const loadEventSettings = async () => {            

            try {                     
                        
                        
                dispatch({
                    type: 'DATA_INIT',
                    payload: { formConfig, data, domain, internal, routeInfo },
                });
        
                

            } catch (error) {
                console.error('Error fetching school info:', error);
            }
        }

        loadEventSettings();

        

       

    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            
        <DebugBarControl />
        {!state.flags.isDataLoading && (
            <Layout>
                {/* Please check layout for the <SearchCriteria /> */}
                <ManageEventGrid />
            </Layout>
            
        )}
    </SmartContext.Provider>
    );
};

export default ManageEvent;
