import { useContext, useEffect, useRef, useState } from 'react';
import useSmartState from '../../../common/hooks/useSmartState';
import FormBuilder from '../../../library/Builders/FormBuilder';
import { SmartContext } from '../../../library/Core/SmartContext';
import { FormSection } from '../../../library/Core/SmartTypes';
import ManageAlumniService from './ManageAlumniService';

const SearchCriteria = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const searchFormConfig = state.formConfig?.sectionRepository[0] as FormSection;
    const [isExpanded, setIsExpanded] = useState(true);

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        ManageAlumniService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) =>
            setStateValue('alumniList', response.data)
        );
    }, [state.data.searchCriteria]);

    return (
        <>
            <div className="fitergroup mb-3">
                <div id="collapseOne" className="accordion-collapse collapse show">
                    <div className="accordion-body p-4">
                        <div className="row g-3">
                            <FormBuilder key="searchCriteria" section={searchFormConfig} dataKey="searchCriteria" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SearchCriteria;
