import { isFormValid, setError } from '../../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../../library/Core/SmartTypes';
import StudentService from '../../../../../services/student.service';

export const handleUpdate = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const formValidationPassed = isFormValid(state, dispatch, []);
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    if (!formValidationPassed || !customValidationPassed) {
        alert('Please correct the form errors!');
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state.formValidationErrors);
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            profile: { ...state.data?.profile },
            user: state.data?.user,
        })
    ).then((response) => {
        state.actions['ROUTER_NAVIGATE'](`/management/${sessionState.userId}/profile`);
    });
};

export const handleCancel = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    state.actions['ROUTER_NAVIGATE'](`/management/${sessionState.userId}/profile`);
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    let isFormValid = true;

    const INDIA_COUNTRY_CODE = 91;
    if (state.data.user?.mobileCountryCode == INDIA_COUNTRY_CODE && state.data.user?.mobileNumber?.length != 10) {
        isFormValid = false;
        setError('user.mobileNumber', [`Please enter valid mobile number`], dispatch);
    }

    return isFormValid;
};
