import { Outlet } from 'react-router-dom';
import PageFooterControl from '../../library/SimpleControls/PageFooterControl';

const AlumniProfileEditLayout = (props: any) => {
    return (
        <>
            <Outlet />
            <PageFooterControl />
        </>
    );
};

export default AlumniProfileEditLayout;
