import { useContext } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import useGuidance from './useGuidance';

const GuidanceSelectedFilters = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { getSubCategoryName } = useGuidance();

    const handleAppliedFilterClick = (event: any, item: any) => {
        event.preventDefault();
        dispatch({
            type: 'DELETE_RECORD_IN_SIMPLE_ARRAY',
            payload: { dataKey: `filters`, name: event.target.id, value: item, errorMessages: [] },
        });
    };

    return (
        <div>
            <div className="row">
                <div className="d-flex flex-wrap align-items-center col-10">
                    {state?.data?.filters.map((item: any) => {
                        return (
                            <div key={`${item}`} className="badge rounded-pill text-bg-primary p-2 m-1">
                                {getSubCategoryName(item)}
                                <i className="bi bi-x-circle p-2" onClick={(event) => handleAppliedFilterClick(event, item)}></i>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default GuidanceSelectedFilters;
