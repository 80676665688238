import * as XLSX from 'xlsx';
import { getDownloadFileName, isEmpty } from '../Core/SmartFunctions';
import ConversationReportService from '../../features/admin/conversation_report/ConversationReportService';
import { useState, useEffect } from 'react';
import moment from 'moment';

const columnConfig: any = {
    conversationId: 'Conversation ID',
    createdAt: 'Created At',
    createdBy: 'Created By',
    updatedAt: 'Updated At',
    updatedBy: 'Updated By',
    senderId: 'Sender UUID',
    senderFullName: 'Sender Name',
    senderRegisteredEmailId: 'Sender Email',
    senderProgramName: 'Sender Program',
    senderInstituteName: 'Sender Institute',
    senderUserType: 'Sender Type',
    message: 'Message',
    receiverUUID: 'Receiver UUID',
    receiverFullName: 'Receiver Name',
    receiverEmailId: 'Receiver Email',
    receiverProgramName: 'Receiver Program',
    receiverInstituteName: 'Receiver Institute',
    senderProfileAutologinlink: 'Sender Profile Autologin Link',
    receiverProfileAutologinlink: 'Receiver Profile Autologin Link',
};

const extractData = (data: any[], columns: string[]) => {
    const gridData = data.map((item) => {
        const filteredItem: { [key: string]: any } = {};
        columns.forEach((column) => {
            if (item.hasOwnProperty(column)) {
                if (column === 'createdAt' || column === 'updatedAt') {
                    filteredItem[columnConfig[column]] = item[column]
                        ? moment(item[column]).format('DD-MMM-YYYY h:mm A')
                        : ''; 
                } else if (column === 'senderProfileAutologinlink' || column === 'receiverProfileAutologinlink') {
                    filteredItem[columnConfig[column]] = `${process.env.REACT_APP_APP_BASE_URL}/sso/${item[column]}`;
                } else {
                    filteredItem[columnConfig[column]] = item[column];
                }
            }
        });
        return filteredItem;
    });

    const columnHeaders = columns.map((column) => columnConfig[column] || column);

    return { gridData, columnHeaders };
};

const ExportToExcelConversationReport = ({
    state,
    columns,
    pageName,
}: {
    state: any;
    columns: any;
    pageName?: any;
}) => {
    const [rawData, setRawData] = useState<any[]>([]);

    useEffect(() => {
        if (pageName === 'conversation-report') {
            const searchCriteriaWithExportFlag = {
                ...state.data.searchCriteria,
                isExportToExcel: 1, 
            };

            ConversationReportService.search(searchCriteriaWithExportFlag, state.routeInfo.pageName)
                .then((response: any) => {
                    setRawData(response.data);
                })
                .catch((error: any) => {
                    console.error('Error fetching conversation report data:', error);
                });
        }
    }, [state.data.searchCriteria, state.routeInfo.pageName, pageName]);

    if (isEmpty(columns)) return <></>;

    const { gridData, columnHeaders } = extractData(rawData, columns);

    const handleExport = () => {
        if (isEmpty(gridData)) {
            alert('No data available to export.');
            return;
        }

        const worksheet = XLSX.utils.json_to_sheet([], { skipHeader: true });

        XLSX.utils.sheet_add_aoa(worksheet, [columnHeaders]);

        XLSX.utils.sheet_add_json(worksheet, gridData, { origin: 'A2', skipHeader: true });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        let downloadFileName = 'GridData';

        if (!isEmpty(pageName)) {
            downloadFileName = getDownloadFileName(pageName, rawData);
        }
        XLSX.writeFile(workbook, `${downloadFileName}.xlsx`);
    };

    return (
        <button className="btn-update" onClick={handleExport}>
            Export to Excel
        </button>
    );
};

export default ExportToExcelConversationReport;