import { useContext, useEffect, useRef, useState } from "react";
import useSmartState from "../../../common/hooks/useSmartState";
import { SmartContext } from '../../../library/Core/SmartContext';
import { Dropdown, Modal } from "bootstrap";
import { axiosClient } from "../../../library/Service/axiosClient";
import DomainCategoryDataService from "./DomainCategoryDataService";
import { REPORT_CONFIG } from '../../admin/reports/report-config';
import ActionSettings from "../../../library/SimpleControls/ActionSettings";
import { toast } from "react-toastify";

const BATCH_SIZE = 10;
const DEFAULT_SORT_COLUMN = 'createdAt';
const DEFAULT_SORT_DIRECTION = 'desc';

const DomainDataGrid = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const [sortedStudents, setSortedStudents] = useState<string[]>([]);
    const [visibleStudents, setVisibleStudents] = useState(state.data.alumniList.slice(0, 10));
    const loadMoreRef: any = useRef();
    const dropdownRef = useRef(null);
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);
    const allRows = [...state.data.alumniList] ?? [];
    const [isEditMode, setIsEditMode] = useState(false);
    const config = REPORT_CONFIG[state.routeInfo.pageName];

    useEffect(() => {
        console.log(`useEffect 1`);
        if (dropdownRef.current) {
            new (Dropdown as any)(dropdownRef.current);
        }
    }, []);

    useEffect(() => {
        console.log(`useEffect 2`);
        const sorted = [...allRows].sort(compareFunction);
        setSortedStudents(sorted);
    }, [state.data.alumniList, sortColumn, sortDirection]);

    useEffect(() => {
        console.log(`useEffect 3`);
        setVisibleStudents(sortedStudents.slice(0, BATCH_SIZE));
    }, [sortedStudents]);

    useEffect(() => {
        console.log(`useEffect 4`);
        const loadMoreObserver = new IntersectionObserver((entries) => {
            // Check if all students are already visible
            if (sortedStudents.length <= visibleStudents.length) {
                if (loadMoreRef.current) {
                    loadMoreObserver.unobserve(loadMoreRef.current); // Stop observing
                }
                return;
            }

            if (entries[0].isIntersecting) {
                const nextBatch = sortedStudents.slice(0, visibleStudents.length + BATCH_SIZE);
                setVisibleStudents([...nextBatch]);
            }
        });

        if (loadMoreRef.current) loadMoreObserver.observe(loadMoreRef.current);

        return () => {
            if (loadMoreRef.current) {
                loadMoreObserver.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, visibleStudents]);

    const isStringColumn = typeof visibleStudents[0]?.[sortColumn] === 'string';

    const compareFunction = (a: any, b: any) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (isStringColumn) {
            return sortDirection === 'asc'
                ? aValue?.localeCompare(bValue, undefined, { sensitivity: 'accent' })
                : bValue?.localeCompare(aValue, undefined, { sensitivity: 'accent' });
        } else {
            return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
        }
    };

    var [domainData, setDomainData] = useState({
        id:'',
        categoryCode: '',
        code: '',
        value: '',
        parentCode: '',
        displayOrder: '',
        flexCol:''
    });

    const [errors, setErrors] = useState<{ categoryCode:String,code: string; value: string }>({
        categoryCode:'',
        code: '',
        value: '',
    });

    const getActionNode = (row: any) => {
        const actions = config['ROW_ACTION'];
        return actions.length > 1 ? (
            <ActionSettings id={row} actions={getValidActions(row?.isActive)} onClick={handleAction} />
        ) : (
            <a className="btn-update" href={config['UPDATE_ACTION_LINK'](row.uuid)} target="_blank" rel="noopener noreferrer">
                Update
            </a>
        );
    };

    const handleAction = (row: any, action: string) => {
        console.log(row,action)
        handlePerformAction(action,row)
    }

    const handlePerformAction = async (action: string, row: any) => {
        switch (action) {
            case 'DEACTIVATE':
                handleDelete(row.id,row.isActive);
                break;
            case 'ACTIVATE':
                handleDelete(row.id,row.isActive);
                break;
            case 'UPDATE':
                handleUpdateAction(row)
                break;
        }
    };

    const handleUpdateAction = (row: any) => {
        setDomainData({
                ...domainData,
                id:row.id,
                categoryCode: row.categoryCode,
                code: row.code,
                value: row.value,
                parentCode: row.parentCode,
                displayOrder: row.displayOrder,
                flexCol:row.flexCol,
            });
        
            setIsEditMode(true);
            setErrors({
                categoryCode: '',
                code: '',
                value: '',
            });
            const modalElement = document.getElementById('edit-domain');
            if (modalElement) {
                const modal = new Modal(modalElement);
                modal.show();
            }
    }

    const handleDelete=(id:number,status:number)=>{
        const msg=(status==1) ? 'Are you sure you want to Deactivate this record?' : 'Are you sure you want to Activate this record?';
        const isConfirmed = window.confirm(msg);
        if (isConfirmed) {
            deleteDomainData(id,status);
        }
    }

    const deleteDomainData = async (id: number,status:number) => {
        try {
          const response = await axiosClient().get(`${process.env.REACT_APP_ADMIN_API_SERVER}/domains/delete-domain-category/${id}/${status}`);
          const data = response.data;
          const toastMessage = (status === 1) ? 'Deactivated successfully' : 'Activated successfully';
          if (data.success) {
            DomainCategoryDataService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
              setStateValue('alumniList', response.data);
              toast.success(toastMessage);
            });
          }
        } catch (error) {
          console.error('Error deleting data:', error);
          alert(error);
        }
      };
       
    const handleCodeChange = (event:any) => {
        setDomainData({
          ...domainData,
          code: event.target.value,
        });
      };

      const handlecategoryCodeChange = (event:any) => {
        setDomainData({
          ...domainData,
          categoryCode: event.target.value,
        });
      };
      
      const handleValueChange = (event:any) => {
        setDomainData({
          ...domainData,
          value: event.target.value,
        });
      };

      const handleparentCodeChange = (event:any) => {
        setDomainData({
          ...domainData,
          parentCode: event.target.value,
        });
      };

      const handleFlexColChange = (event:any) => {
        setDomainData({
          ...domainData,
          flexCol: event.target.value,
        });
      };

      const handledisplayOrderChange = (event:any) => {
        setDomainData({
          ...domainData,
          displayOrder: event.target.value,
        });
      };
      
      const editDomainData= ()=>{
        const newErrors = {
            categoryCode:'',
            code: '',
            value: ''
        };
        if (domainData?.categoryCode=='') {
            newErrors.categoryCode = 'Please enter Category code field.';
        }
        if (domainData?.code=='') {
            newErrors.code = 'Please enter Code field.';
        }
        if (domainData?.value=='') {
            newErrors.value = 'Please enter Value field.';
        }
        setErrors(newErrors);
        if (Object.values(newErrors).some((error) => error)) {
            return;
        }
        updateDomainData(domainData)
      }

      const updateDomainData = async (domainData: any) => {
        try {
            const response: any = await axiosClient().post(`${process.env.REACT_APP_ADMIN_API_SERVER}/domains/update-domain-category`, {
               domainData:domainData
            });
            const data = await response.data;
            if (data.success) {
                console.log(data.message);        
                alert(data.message);
                var closeButton = document.getElementById('edit-domain')?.getElementsByClassName('btn-close')[0];
                if (closeButton instanceof HTMLButtonElement) {
                    closeButton?.click();
                }
                DomainCategoryDataService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
                    setStateValue('alumniList', response.data);
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    const addDomainCategory= ()=>{
        setDomainData({
            ...domainData,
            id:'',
            categoryCode: '',
            code: '',
            value:'',
            parentCode:'',
            displayOrder:'',
            flexCol:'',
        });
        setIsEditMode(false);
        setErrors({
            categoryCode: '',
            code: '',
            value: '',
        });
        const modalElement = document.getElementById('edit-domain');
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }
    }
    const getValidActions = (isActive: number) => {
        const actions = config['ROW_ACTION'];
        return actions.filter((item: any) => item !== (isActive == 1 ? 'ACTIVATE' : 'DEACTIVATE'));
    };
    
    return (
        <>
           <div className="d-flex justify-content-between">
                <span>Found {allRows.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} rows</span>
                <button className="btn btn-primary mb-2" onClick={() => addDomainCategory()}>
                    Add Domain Category
                </button>
            </div>
           
            <div className="table-responsive attendee_dtl">
                <div>Found {allRows.length} rows</div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Domain Category</th>
                            <th>Code</th>
                            <th>Value</th>
                            <th>Parent Code</th>
                            <th>Display Order</th>
                            <th>FlexCol</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                <tbody>
                     {visibleStudents.map((row: any) => (
                         <tr key={row.category_code}>
                            <td>{row.categoryCode}</td>
                            <td>{row.code}</td>
                            <td>{row.value}</td>
                            <td>{row.parentCode}</td>
                            <td>{row.displayOrder}</td>
                            <td>{row.flexCol}</td>
                            <td style={{ color: (row.isActive as boolean) ? 'green' : 'red' }}>  
                             {(row.isActive as boolean) ? 'Active' : 'De-active'}</td>
                            <td>{getActionNode(row)}</td>
                         </tr>
                     ))}
                      <tr ref={loadMoreRef} />
                </tbody>
            </table>
            </div>
          {/* edit modal */}
          <div className="modal fade px-3" id="edit-domain" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered max-600 mx-auto">
                <div className="modal-content">
                    <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
                    <h5 className="modal-title" id="staticBackdropLabel">  {isEditMode ? 'Edit Domain Category Data' : 'Add Domain Category Data'}</h5>
                    <button type="button" className="btn-close btn-close-small" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                    <form className="needs-validation" noValidate autoComplete="off">
                        <div className="row g-4">
                        <div className="col-md-6">
                            <label className="form-label">Category Name</label>
                            <input type="text" className="form-control flex-1" id="category_name"  value={domainData?.categoryCode} onChange={handlecategoryCodeChange}/>
                            <div className="error-message">{errors.categoryCode}</div>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Code</label>
                            <input type="text" className="form-control flex-1" id="code" value={domainData?.code} onChange={handleCodeChange}/>
                            <div className="error-message">{errors.code}</div>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Value</label>
                            <input type="text" className="form-control flex-1" id="value" value={domainData?.value} onChange={handleValueChange}/>
                            <div className="error-message">{errors.value}</div>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Parent Code</label>
                            <input type="text" className="form-control flex-1" id="parentCode" value={domainData?.parentCode} onChange={handleparentCodeChange}/>
                        </div>
                        
                        <div className="col-md-6">
                            <label className="form-label">Display Order</label>
                            <input type="text" className="form-control flex-1" id="displayOrder" value={domainData?.displayOrder} onChange={handledisplayOrderChange}/>
                        </div>
                        <div className="col-md-12">
                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                <label htmlFor="skills" className="form-label m-0 mb-1 font-16 font-500">FlexCol</label>
                            </div>
                            <textarea className="form-control" id="comment" style={{ height: '100px' }}  value={domainData?.flexCol}   placeholder="Enter FlexCol..." onChange={handleFlexColChange}></textarea>
                         </div>
                        </div><br/>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-auto">
                                {/* <button type="button" className="btn btn-cancel" data-bs-dismiss="modal">Cancel</button> */}
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-green max-135" onClick={() => editDomainData()}>
                                <span>{isEditMode ? 'Update' : 'Save'}</span>
                                </button>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
                </div>
        </>
    );
};

export default DomainDataGrid;
