import { useContext, useEffect, useState } from 'react';
import SearchCriteria from './SearchCriteria';
import { useNavigate } from 'react-router';
import { SmartContext } from '../../../library/Core/SmartContext';
import useManageAlumni from '../engagement-list/useManageAlumni';
import { getFuzzySearchCriteria } from '../../../library/Core/SmartFunctions';
import AlumniEngagementService from './AlumniEngagementService';


const Layout = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const [showSearchCriteria, setShowSearchCriteria] = useState(true);

    return (
        <div className="main flex-1">
            <section className="updateadditionalinformation">
                <div className="container max-1140 px-lg-0 overflow-hidden">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius p-0 h-100">
                                <div className="p-4 p-md-5">
                                    <div className="alumni_statistics">
                                    <h2 className="font-26 font-600 mb-0 pb-4">{state.formConfig?.title}</h2>
                                        {showSearchCriteria && <SearchCriteria />}
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Layout;
