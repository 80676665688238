import { isEmpty, isFormValid, setError } from '../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
import StudentService from '../../../../services/student.service';
import axios from 'axios';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    if (
        !isImpersonatedSession &&
        (!isFormValid(state, dispatch, ['skills', 'targetSkills']) || isFormInvalidBasedOnCustomValidation(state, dispatch))
    ) {
        alert('Please correct the form errors!');
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    // Send event to Moengage Skills
    sendMoEngageEvent(state?.data);

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            skillInfo: state?.data['skillInfo'],
            skills: state?.data['skills'],
            targetSkills: state?.data['targetSkills'],
        })
    ).then(() => {
        // if (state?.data?.userStatusCode != 'ACTIVE') {
            // ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.SKILLS, state, dispatch);
        // } else {
        //     state.actions['ROUTER_NAVIGATE'](`/alumni/${sessionStorage.getItem('user-id')}/profile/skills`);
        // }
        state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile/skills`);
    });
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.SKILLS, state, dispatch);
};

const isFormInvalidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    // At least one skill
    const atLeastOneSkillEntered = state?.data['skills'].some((skill: any) => (!isEmpty(skill.skillCode) || !isEmpty(skill.otherSkill)) && !isEmpty(skill.skillLevelCode));
    if (!atLeastOneSkillEntered) setError('skills', ['Please enter at least one skill'], dispatch);

    // All skills have levels
    const allSkillsHaveLevels = state?.data['skills'].every((skill: any) => (isEmpty(skill.skillCode) && isEmpty(skill.otherSkill)) || !isEmpty(skill.skillLevelCode));
    if (!allSkillsHaveLevels) setError('skills', ['All skills must have a proficiency level'], dispatch);

    // No duplicate skills
    const skillCodeArray = state?.data['skills'].map((skill: any) => skill.skillCode);
    // checks if there is any element in the skillCodeArray that has a different index
    const hasDuplicateSkillCodes = skillCodeArray.some(
        (skillCode: string, idx: number) => skillCode && skillCodeArray.indexOf(skillCode) !== idx
    );

    // If user selects interested_in_upskilling, then they must select at least one target skill need to be selected
    const interestedInUpskilling = state?.data['skillInfo'].interestedInUpskilling;
    const targetSkills = state?.data['targetSkills'];
    const atLeastOneTargetSkillSelected = targetSkills?.some((targetSkill: any) => !isEmpty(targetSkill.skillCode));
    let interestedInUpskillingButTargetSkillNotSelected = false;
    if (interestedInUpskilling && !atLeastOneTargetSkillSelected) {
        interestedInUpskillingButTargetSkillNotSelected = true;
        setError('targetSkills', ['Please select at least one target skill'], dispatch);
    }

    if (hasDuplicateSkillCodes) setError('skills', ['Duplicate Skill: Skill already exists. Please enter unique skills.'], dispatch);

    const skillHasError =
        !atLeastOneSkillEntered || !allSkillsHaveLevels || hasDuplicateSkillCodes || interestedInUpskillingButTargetSkillNotSelected;
    if (!skillHasError) setError('skills', [], dispatch);

    return skillHasError;
};

const sendMoEngageEvent = async (user:any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-skills`, {
            userInfo       : user
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};