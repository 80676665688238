import { isFormValid } from '../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import logger from '../../../../library/Core/logger';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    logger.log(state.data['profile']);

    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    if (!isImpersonatedSession && !isFormValid(state, dispatch)) {
        alert('Please correct the form errors!');
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            profile: state?.data['profile'],
            socialLinks: state?.data['socialLinks'],
            selectedInstitute: state?.data?.selectedInstitute,
            userInstitutes: state?.data?.userInstitutes
        })
    ).then(() => {
        StudentService.SaveProfileImagePath(state)
            .then((res) => {
                // 
                // sessionStorage.setItem('user-profile-image', state.data.profile.profilePictureFilePath);
                // if ( sessionStorage.getItem('user-type-code') == state.data.userTypeCode ) {
                //     sessionStorage.setItem('user-profile-image', state.data.profile.profilePictureFilePath);
                // }

                if ( sessionState.userTypeCode == state.data.userTypeCode ) {
                    // NEED TO CHECK THIS
                    sessionStorage.setItem('user-profile-image', state.data.profile.profilePictureFilePath);
                }

                // Handle the API response here if needed
                // if (state.data.userTypeCode === 'STUDENT') {
                //     state.actions['ROUTER_NAVIGATE'](`/student/${state.routeInfo.id}/profile/extra-curricular`);
                // } else if(sessionStorage.getItem('user-type-code') === 'SUPER_ADMIN') {
                //     state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile`);
                // } else {
                //     if (state?.data?.userStatusCode != 'ACTIVE') {
                //         ScreenNavigationService.navigateToNextStep(
                //             AlumniProfileEditScreens.PROFILE_IMAGE_AND_SOCIAL_PROFILES,
                //             state,
                //             dispatch
                //         );
                //     } else {
                //         state.actions['ROUTER_NAVIGATE'](`/alumni/${sessionStorage.getItem('user-id')}/profile`);
                //     }
                // }

                // if (state.data.profile.userTypeCode === 'STUDENT') {
                //     state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile/extra-curricular`);
                // } else {
                //     state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile`);
                // }
                state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile`);

            })
            .catch((error: any) => {
                // Handle API error here
                logger.log(error);
            });

        // if (state.data.userTypeCode === 'STUDENT') {
        //     state.actions['ROUTER_NAVIGATE'](`/student/${state.routeInfo.id}/profile/extra-curricular`);
        // } else {
        //     if(state?.data?.userStatusCode != 'ACTIVE'){
        //         ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.PROFILE_IMAGE_AND_SOCIAL_PROFILES, state, dispatch);
        //     }
        //     else
        //     {
        //         state.actions['ROUTER_NAVIGATE'](`/alumni/${sessionStorage.getItem('user-id')}/profile`);
        //     }
        // }
    });
};
