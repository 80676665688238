import { isFormValid, setError } from '../../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../../library/Core/SmartTypes';
import StudentService from '../../../../../services/student.service';

export const handleUpdate = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const formValidationPassed = isFormValid(state, dispatch, []);
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    if (!formValidationPassed || !customValidationPassed) {
        alert('Please correct the form errors!');
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state.formValidationErrors);
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            profile: { ...state.data?.profile, adminAdditionalInfo: JSON.stringify(state.data?.adminAdditionalInfo) },
        })
    ).then(() => {
        state.actions['ROUTER_NAVIGATE'](`/management/${sessionStorage.getItem('user-id')}/profile`);
    });
};

export const handleCancel = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    state.actions['ROUTER_NAVIGATE'](`/management/${sessionStorage.getItem('user-id')}/profile`);
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    let isFormValid = true;

    const INDIA_COUNTRY_CODE = 91;
    if (state.data.profile?.contactMobileCountryCode == INDIA_COUNTRY_CODE && state.data.profile?.contactMobileNumber?.length != 10) {
        isFormValid = false;
        setError('profile.contactMobileNumber', [`Please enter valid mobile number`], dispatch);
    }

    return isFormValid;
};
