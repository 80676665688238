import { useContext } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { axiosClient } from '../../../library/Service/axiosClient';
import AlbumListingService from './AlbumListingService';
import SessionContext from '../../../library/Core/SessionContext';

const useAlbum = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);
    const likeAlbum = async (id: string, refresh = true) => {
        const response = await axiosClient().post(`${process.env.REACT_APP_MEMORIES_GUIDANCE_SERVER}/memories/${id}/like`);
        if (response.status === 200 && refresh) refreshOnLikeOrSave(id, 'LIKE');
    };

    const saveAlbum = async (id: string, refresh = true) => {
        const response = await axiosClient().post(`${process.env.REACT_APP_MEMORIES_GUIDANCE_SERVER}/memories/${id}/save`);
        if (response.status === 200 && refresh) refreshOnLikeOrSave(id, 'SAVE');
    };

    const tabChange = async (criteria: string) => {
        // const response = await axiosClient().get(`${process.env.REACT_APP_MEMORIES_GUIDANCE_SERVER}/memories?searchBy=${criteria}`);
        let filteredData: any = [];
        const studentId = sessionState?.studentId;

        if (criteria === 'ALL') {
            filteredData = state.data.albums;
        } else if (criteria === 'SAVED') {
            filteredData = state.data.albums.filter((album: any) => album.isFavorite === 1);
        } else if (criteria === 'MINE') {
            filteredData = state.data.albums.filter((album: any) => album.studentId === studentId);
        }

        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {
                    albums: state.data.albums,
                    pageDomain: state.data.pageDomain,
                    filters: AlbumListingService.filters,
                    filteredData,
                    summaryCounts: state.data.summaryCounts,
                    selectedTab: criteria,
                },
            },
        });
    };

    const updateAlbumProperty = (album: any, action: string, property: string) => {
        if (action === 'LIKE' && property === 'isLiked') {
            return { ...album, isLiked: !album.isLiked };
        } else if (action === 'SAVE' && property === 'isFavorite') {
            return { ...album, isFavorite: !album.isFavorite };
        }
        return album;
    };

    const refreshOnLikeOrSave = (id: string, action: string) => {
        const studentId = sessionState?.studentId;
        const updatedAlbums = state.data.albums.map((album: any) =>
            album.id === id ? updateAlbumProperty(album, action, action === 'LIKE' ? 'isLiked' : 'isFavorite') : album
        );

        const updatedFilteredData = state.data.filteredData.map((album: any) =>
            album.id === id ? updateAlbumProperty(album, action, action === 'LIKE' ? 'isLiked' : 'isFavorite') : album
        );

        const summaryCounts = {
            totalCount: updatedAlbums.length,
            mineCount: updatedAlbums.filter((album: any) => album.studentId == studentId).length,
            savedCount: updatedAlbums.filter((album: any) => album.isFavorite).length,
        };

        dispatch({
            type: 'REFRESH_DATA',
            payload: { data: { ...state.data, albums: updatedAlbums, filteredData: updatedFilteredData, summaryCounts } },
        });
    };

    return { likeAlbum, saveAlbum, tabChange };
};

export default useAlbum;
