import { Dropdown, Tooltip } from 'bootstrap';
import { useContext, useEffect, useRef, useState } from 'react';
import useSmartState from '../../../common/hooks/useSmartState';
import { SmartContext } from '../../../library/Core/SmartContext';
import ManageAlumniServiceEngage from './ManageAlumniServiceEngage';

const AlumniListEngageGrid = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
    const [bulkAction, setBulkAction] = useState<string>('' as any);
    const allRows = [...state.data.alumniList] ?? [];
    const [visibleStudents, setVisibleStudents] = useState(state.data.alumniList.slice(0, 10));
    const loadMoreRef: any = useRef();
    const { gridColumns, actions } = state.data.reportConfig;
    const dropdownRef = useRef(null);
    const [sortColumn, setSortColumn] = useState('firstName');
    const [sortDirection, setSortDirection] = useState('asc');
    // const actions: any = ManageAlumniService.getActions(state.routeInfo.pageName);

    useEffect(() => {
        if (dropdownRef.current) {
            new (Dropdown as any)(dropdownRef.current);
        }
    }, []);

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });

        setVisibleStudents(state.data.alumniList.slice(0, 10));
    }, [state.data.alumniList]);

    useEffect(() => {
        if (visibleStudents.length === state.data.alumniList.length) return;

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setVisibleStudents((prevStudents: any) => [
                    ...prevStudents,
                    ...state.data.alumniList.slice(prevStudents.length, prevStudents.length + 10),
                ]);
            }
        });

        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }

        return () => {
            if (loadMoreRef.current) {
                observer.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, state.data.alumniList, visibleStudents]);

    const handleSelectStudent = (e: any) => {
        if (e.target.checked) {
            setSelectedStudents((prevStudents) => [...prevStudents, e.target.value]);
        } else {
            setSelectedStudents((prevStudents) => prevStudents.filter((id) => id !== e.target.value));
        }
    };

    // Utility to determine if the column is a string type.
    const isStringColumn = typeof visibleStudents[0]?.[sortColumn] === 'string';

    const handleNull = (aValue: any, bValue: any) => {
        if (aValue === null && bValue === null) return 0;
        if (aValue === null) return 1;
        if (bValue === null) return -1;
        return null;
    };

    const compareFunction = (a: any, b: any) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        // const nullCheck = handleNull(aValue, bValue);
        // if (nullCheck !== null) return nullCheck;

        if (isStringColumn) {
            return sortDirection === 'asc'
                ? aValue?.localeCompare(bValue, undefined, { sensitivity: 'accent' })
                : bValue?.localeCompare(aValue, undefined, { sensitivity: 'accent' });
        } else {
            return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
        }
    };

    const sortedStudents = allRows.sort(compareFunction);

    const handleColumnSort = (column: string) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const handlePerformAction = async (action: string, students: any) => {
        switch (action) {
            case 'DEACTIVATE':
                await ManageAlumniServiceEngage.deactivateStudent(students);
                setSelectedStudents([]);
                ManageAlumniServiceEngage.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) =>
                    setStateValue('alumniList', response.data)
                );
                break;
            case 'ACTIVATE':
                await ManageAlumniServiceEngage.activateStudent(students);
                setSelectedStudents([]);
                ManageAlumniServiceEngage.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) =>
                    setStateValue('alumniList', response.data)
                );
                break;
            case 'SEND_MESSAGE':
                setSelectedStudents([]);
                window.open(`/messages/send-message/${students}`, '_blank');
                break;
        }
    };

    const handleAction = (uuid: string, action: string) => {
        console.log(`handleAction: ${uuid} / ${action}`);
        handlePerformAction(action, [uuid]);
    };

    const getValidActions = (userStatusCode: string) => {
        return actions.filter((item: any) => item !== (userStatusCode === 'ACTIVE' ? 'Activate' : 'Deactivate'));
    };

    const handleSelectAll = (event: any) => {
        const students = event.target.checked ? allRows.map((item: any) => item.uuid) : [];
        setSelectedStudents(students);
    };

    const showStar = (row: any) => (row.isPrestigiousCollege || row.pgIsPrestigiousCollege || row.hasReceivedScholarship ? true : false);

    return (
        <>
            <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks my-2">
                    class{' '}
                    <div className="d-flex justify-content-start align-items-center customselect_parnt">
                        <select name="complete Class 12" aria-label="Default select example" className="form-select w-120">
                            <option value="Action">Bulk Action</option>
                            <option value="Mark as Unseen">Mark as Unseen</option>
                            <option value="Mark as Seen">Mark as Seen</option>
                            <option value="Mark Important">Mark Important</option>
                            <option value="Unmark Important">Unmark Important</option>
                            <option value="Delete">Delete</option>
                            <option value="Ignore">Ignore</option>
                            <option value="Block">Block</option>
                        </select>
                        <button className="btn btn-darkblue" type="submit">
                            <span className="line-height-16">Apply</span>
                        </button>
                    </div>
                </div>
                <div className="d-flex flex-wrap align-items-center justify-content-end">
                    <div className="d-flex justify-content-start align-items-center customselect_parnt me-auto mobile-100 mb-3 mb-sm-0">
                        <select name="complete Class 12" aria-label="Default select example" className="form-select w-120">
                            <option value="Action">Show 20</option>
                            <option value="Mark as Unseen">Mark as Unseen</option>
                            <option value="Mark as Seen">Mark as Seen</option>
                            <option value="Mark Important">Mark Important</option>
                            <option value="Unmark Important">Unmark Important</option>
                            <option value="Delete">Delete</option>
                            <option value="Ignore">Ignore</option>
                            <option value="Block">Block</option>
                        </select>
                    </div>
                    <h3 className="font-14 font-500 mb-0 me-2">Showing 1-20 of 220 records</h3>
                    <div className="d-flex flex-wrap custom-btn">
                        <a className="btn-prev icon-left-arrow" href="javascript:;"></a>
                        <a className="btn-prev icon-right-arrow" href="javascript:;"></a>
                    </div>
                </div>
            </div>

            <div className="table-responsive attendee_dtl">
                <table id="example1" className="table  sticky_table_3_columns" style={{ minWidth: '2500px' }}>
                    <thead>
                        <tr>
                            <th className="text-center" style={{ width: '140px' }}>
                                Actions
                            </th>
                            <th style={{ width: '250px' }}>
                                <input type="checkbox" id="select_all" className="form-check-input table-checkbox" /> Alumni Name
                            </th>
                            <th style={{ width: '180px' }}>Campus</th>
                            <th style={{ width: '180px' }}>Present Status</th>
                            <th>Course</th>
                            <th>University</th>
                            <th>Exam Details</th>
                            <th>Engagements</th>
                            <th>Last Interaction</th>
                            <th>Work-Ex</th>
                            <th>Location</th>
                            <th>Achievements</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">
                                <div className="d-flex flex-column">
                                    <a
                                        href="javascript:;"
                                        className="btn-setting-msg icon-setting font-20"
                                        data-bs-toggle="tooltip"
                                        data-bs-html="true"
                                        title="<ul>
                                                      <li><a href='#' className='btn-send-email' data-bs-toggle='modal' data-bs-target='#send-email'>Send Email</a></li>
                                                      <li><a href='#' className='btn-send-whatsapp' data-bs-toggle='modal' data-bs-target='#send-whatsapp'>Send WhatsApp</a></li>
                                                      <li><a href='#' className='btn-sent-email-wa' data-bs-toggle='modal' data-bs-target='#sent-email-wa'>Send Email + WA</a></li>
                                                      <li><a href='#' className='btn-view-sent-history' data-bs-toggle='modal' data-bs-target='#view-sent-history'>View Sent History</a></li>
                                                   
                                                      </ul>"></a>
                                    <span className="last-sent">Last Sent - NA</span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-wrap">
                                    <div className="d-flex flex-column mt-1 me-2">
                                        <input className="form-check-input table-checkbox" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                    <div className="name d-flex flex-column line-height-18">
                                        <a href="javascript:;" className="font-16 font-500">
                                            Anuradha Gautam
                                        </a>
                                        <span>Batch - 2017</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">Podar International School, Nerul</div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">
                                    <span>
                                        {' '}
                                        <b className="font-500">Pursuing PG</b>{' '}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-wrap">
                                    <div className="course-list">
                                        <div className="course-list-row">
                                            <span>UG - Science</span>
                                            <i
                                                className="icon-dollor font-20 defaultToolTIp"
                                                data-bs-toggle="tooltip"
                                                title="Scholarship Received - 15%"
                                                aria-label="Scholarship Received - 15%"></i>
                                        </div>
                                        <div className="course-list-row">
                                            <span>PG - Business</span>
                                        </div>
                                        <div>Management</div>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <div className="d-flex flex-wrap flex-column">
                                    <div className="universityName">
                                        <span>UG - Christ University</span>
                                        <i
                                            className="icon ms-1 defaultToolTIp"
                                            data-bs-toggle="tooltip"
                                            title=""
                                            data-bs-original-title="Prestigious College">
                                            <img src="images/subtraction.svg" width="16" className="sub-icon" />
                                        </i>
                                    </div>
                                    <div className="universityName">
                                        <span>PG - University of Sheffield</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">
                                    <span>
                                        JEE Main - <b>2017</b>{' '}
                                    </span>
                                    <span>
                                        CUCET - <b>2017</b>{' '}
                                    </span>
                                    <span>
                                        <a
                                            className="btn-html"
                                            href="javascript:;"
                                            data-bs-toggle="tooltip"
                                            data-bs-html="true"
                                            title=""
                                            data-bs-original-title="<ol><li>AILET - <b>2021</b></li></ol>">
                                            &amp; 1 More
                                        </a>
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div>
                                    5 Videos (
                                    <a href="javascript:;" className="btn-conview">
                                        View
                                    </a>
                                    )
                                </div>
                                <div>
                                    2 Conversation (
                                    <a href="javascript:;" className="btn-conview">
                                        View
                                    </a>
                                    )
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <span>21-Mar-2022</span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">NA</div>
                            </td>
                            <td>
                                <div className="d-flex flex-wrap">
                                    <div className="course-list">
                                        <div className="course-list-row">
                                            <span>Bangalore, India</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="">
                                <div>NA</div>
                            </td>
                        </tr>

                        <tr>
                            <td className="text-center">
                                <div className="d-flex flex-column">
                                    <a
                                        href="javascript:;"
                                        className="btn-setting-msg icon-setting font-20"
                                        data-bs-toggle="tooltip"
                                        data-bs-html="true"
                                        title="<ul>
                                                      <li><a href='#' className='btn-send-email' data-bs-toggle='modal' data-bs-target='#send-email'>Send Email</a></li>
                                                      <li><a href='#' className='btn-send-whatsapp' data-bs-toggle='modal' data-bs-target='#send-whatsapp'>Send WhatsApp</a></li>
                                                      <li><a href='#' className='btn-sent-email-wa' data-bs-toggle='modal' data-bs-target='#sent-email-wa'>Send Email + WA</a></li>
                                                      <li><a href='#' className='btn-view-sent-history' data-bs-toggle='modal' data-bs-target='#view-sent-history'>View Sent History</a></li>
                                                   
                                                      </ul>"></a>
                                    <span className="last-sent">Last Sent - NA</span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-wrap">
                                    <div className="d-flex flex-column mt-1 me-2">
                                        <input className="form-check-input table-checkbox" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                    <div className="name d-flex flex-column line-height-18">
                                        <a href="javascript:;" className="font-16 font-500">
                                            Aditya Sharma
                                        </a>
                                        <span>Batch - 2020</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">Podar International School, Nerul</div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">
                                    <span>
                                        {' '}
                                        <b className="font-500">Pursuing PG</b>{' '}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-wrap">
                                    <div className="course-list">
                                        <div className="course-list-row">
                                            <span>UG - Engineering & Technology</span>
                                        </div>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <div className="d-flex flex-wrap flex-column">
                                    <div className="universityName">
                                        <span>UG - IIT Bombay</span>
                                        <i
                                            className="icon ms-1 defaultToolTIp"
                                            data-bs-toggle="tooltip"
                                            title=""
                                            data-bs-original-title="Prestigious College">
                                            <img src="images/subtraction.svg" width="16" className="sub-icon" />
                                        </i>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">
                                    <span>
                                        JEE Main - <b>2020</b>{' '}
                                    </span>
                                    <span>
                                        NATA - <b>2020</b>{' '}
                                    </span>
                                    <span>
                                        <a
                                            className="btn-html"
                                            href="javascript:;"
                                            data-bs-toggle="tooltip"
                                            data-bs-html="true"
                                            title=""
                                            data-bs-original-title="<ol><li>AILET - <b>2021</b></li><li>AILET - <b>2021</b></li></ol>">
                                            &amp; 2 More
                                        </a>
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div>
                                    15 Videos (
                                    <a href="javascript:;" className="btn-conview">
                                        View
                                    </a>
                                    )
                                </div>
                                <div>
                                    8 Conversation (
                                    <a href="javascript:;" className="btn-conview">
                                        View
                                    </a>
                                    )
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <span>10-Mar-2022</span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">NA</div>
                            </td>
                            <td>
                                <div className="d-flex flex-wrap">
                                    <div className="course-list">
                                        <div className="course-list-row">
                                            <span>Hyderabad, India</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="">
                                <div>
                                    3 Achievements (
                                    <a
                                        href="javascript:;"
                                        className="btn-conview btn-html"
                                        data-bs-toggle="tooltip"
                                        data-bs-html="true"
                                        title=""
                                        data-bs-original-title="<ol><li>Promotion</li><li>Award</li><li>Publication</li></ol><a href='#'>View Details</a>">
                                        View
                                    </a>
                                    )
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td className="text-center">
                                <div className="d-flex flex-column">
                                    <a
                                        href="javascript:;"
                                        className="btn-setting-msg icon-setting font-20"
                                        data-bs-toggle="tooltip"
                                        data-bs-html="true"
                                        title="<ul>
                                                      <li><a href='#' className='btn-send-email' data-bs-toggle='modal' data-bs-target='#send-email'>Send Email</a></li>
                                                      <li><a href='#' className='btn-send-whatsapp' data-bs-toggle='modal' data-bs-target='#send-whatsapp'>Send WhatsApp</a></li>
                                                      <li><a href='#' className='btn-sent-email-wa' data-bs-toggle='modal' data-bs-target='#sent-email-wa'>Send Email + WA</a></li>
                                                      <li><a href='#' className='btn-view-sent-history' data-bs-toggle='modal' data-bs-target='#view-sent-history'>View Sent History</a></li>
                                                   
                                                      </ul>"></a>
                                    <span className="last-sent">
                                        Last Sent - <span className="d-block">23-Mar-2022</span>
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-wrap">
                                    <div className="d-flex flex-column mt-1 me-2">
                                        <input className="form-check-input table-checkbox" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                    <div className="name d-flex flex-column line-height-18">
                                        <a href="javascript:;" className="font-16 font-500">
                                            K C Ramaswamy
                                        </a>
                                        <span>Batch - 2016</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">Podar International School, Powai</div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">
                                    <span>
                                        {' '}
                                        <b className="font-500">Working</b>{' '}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-wrap">
                                    <div className="course-list">
                                        <div className="course-list-row">
                                            <span>UG - Arts and Humanities</span>
                                        </div>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <div className="d-flex flex-wrap flex-column">
                                    <div className="universityName">
                                        <span>UG - JB College of Arts</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">
                                    <span>
                                        CUCET - <b>2016</b>{' '}
                                    </span>
                                    <span>
                                        NIFT - <b>2017</b>{' '}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div>
                                    25 Videos (
                                    <a href="javascript:;" className="btn-conview">
                                        View
                                    </a>
                                    )
                                </div>
                                <div>
                                    1 Conversation (
                                    <a href="javascript:;" className="btn-conview">
                                        View
                                    </a>
                                    )
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <span>22-Feb-2022</span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">
                                    <span>
                                        <b className="blue-badge primary">Top Manager </b>
                                    </span>
                                    <span className="d-flex flex-wrap mb-1">Director of UX at Google </span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-wrap">
                                    <div className="course-list">
                                        <div className="course-list-row">
                                            <span>Toronto, Canada</span>
                                        </div>
                                    </div>
                                </div>
                            </td>

                            <td className="">
                                <div>
                                    1 Achievements (
                                    <a
                                        href="javascript:;"
                                        className="btn-conview btn-html"
                                        data-bs-toggle="tooltip"
                                        data-bs-html="true"
                                        title=""
                                        data-bs-original-title="<ol><li>Promotion</li></ol><a href='#'>View Details</a>">
                                        View
                                    </a>
                                    )
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td className="text-center">
                                <div className="d-flex flex-column">
                                    <a
                                        href="javascript:;"
                                        className="btn-setting-msg icon-setting font-20"
                                        data-bs-toggle="tooltip"
                                        data-bs-html="true"
                                        title="<ul>
                                                      <li><a href='#' className='btn-send-email' data-bs-toggle='modal' data-bs-target='#send-email'>Send Email</a></li>
                                                      <li><a href='#' className='btn-send-whatsapp' data-bs-toggle='modal' data-bs-target='#send-whatsapp'>Send WhatsApp</a></li>
                                                      <li><a href='#' className='btn-sent-email-wa' data-bs-toggle='modal' data-bs-target='#sent-email-wa'>Send Email + WA</a></li>
                                                      <li><a href='#' className='btn-view-sent-history' data-bs-toggle='modal' data-bs-target='#view-sent-history'>View Sent History</a></li>
                                                   
                                                      </ul>"></a>
                                    <span className="last-sent">
                                        Last Sent - <span className="d-block">03-Apr-2022</span>
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-wrap">
                                    <div className="d-flex flex-column mt-1 me-2">
                                        <input className="form-check-input table-checkbox" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                    <div className="name d-flex flex-column line-height-18">
                                        <a href="javascript:;" className="font-16 font-500">
                                            Namita Dube
                                        </a>
                                        <span>Batch - 2021</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">Podar International School, Ahmedabad</div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">
                                    <span>
                                        {' '}
                                        <b className="font-500">Entrepreneurship</b>{' '}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-wrap">
                                    <div className="course-list">
                                        <div className="course-list-row">
                                            <span>UG - Law</span>
                                            <i
                                                className="icon-dollor font-20 defaultToolTIp"
                                                data-bs-toggle="tooltip"
                                                title="Scholarship Received - 15%"
                                                aria-label="Scholarship Received - 15%"></i>
                                        </div>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <div className="d-flex flex-wrap flex-column">
                                    <div className="universityName">
                                        <span>UG - CNLU Patna</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">
                                    <span>
                                        JEE Main - <b>2017</b>{' '}
                                    </span>
                                    <span>
                                        CUCET - <b>2017</b>{' '}
                                    </span>
                                    <span>
                                        <a
                                            className="btn-html"
                                            href="javascript:;"
                                            data-bs-toggle="tooltip"
                                            data-bs-html="true"
                                            title=""
                                            data-bs-original-title="<ol><li>AILET - <b>2021</b></li></ol>">
                                            &amp; 1 More
                                        </a>
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div>
                                    3 Conversation (
                                    <a href="javascript:;" className="btn-conview">
                                        View
                                    </a>
                                    )
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <span>03-Jan-2022</span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">
                                    <span>
                                        <b className="blue-badge primary">Leadership</b>
                                    </span>
                                    <span className="d-flex flex-wrap mb-1">CEO at Aptronix </span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-wrap">
                                    <div className="course-list">
                                        <div className="course-list-row">
                                            <span>Boston, USA</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex flex-column">NA</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="pagination_custom">
                <a className="btn-first" href="javascript:;">
                    First
                </a>
                <div className="pagination_custom_inner">
                    <a className="btn-prev icon-left-arrow" href="javascript:;"></a>
                    <ul>
                        <li>
                            <a href="javascript:;" data-number="1">
                                1
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;" data-number="2">
                                2
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;" data-number="3">
                                3
                            </a>
                        </li>
                        <li className="current">
                            <a href="javascript:;" data-number="4">
                                4
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;" data-number="5">
                                5
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;" data-number="6">
                                6
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;" data-number="7">
                                7
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;" data-number="8">
                                8
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;" data-number="9">
                                9
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;" data-number="10">
                                10
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;" data-number="11">
                                11
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;" data-number="...">
                                ...
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;" data-number="19">
                                19
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;" data-number="20">
                                20
                            </a>
                        </li>
                    </ul>
                    <a className="btn-next icon-right-arrow" href="javascript:;"></a>
                </div>
                <a className="btn-last" href="javascript:;">
                    Last
                </a>
            </div>
        </>
    );
};

export default AlumniListEngageGrid;
