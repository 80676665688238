import { useEffect, useState } from 'react';

const useUserActivity = (inactivityDuration = 300000, onInactiveCallback: any) => {
    // default duration is 5 minutes (300000ms)
    const [lastActiveTime, setLastActiveTime] = useState(new Date().getTime());

    // Updates the last active time whenever user interacts with the document
    const updateUserLastActiveTime = () => {
        setLastActiveTime(new Date().getTime());
    };

    useEffect(() => {
        // Attach event listeners to update user's last active time
        document.addEventListener('mousemove', updateUserLastActiveTime);
        document.addEventListener('keydown', updateUserLastActiveTime);
        document.addEventListener('scroll', updateUserLastActiveTime);

        const checkInactivity = setInterval(() => {
            const currentTime = new Date().getTime();
            const durationSinceLastActivity = currentTime - lastActiveTime;

            if (durationSinceLastActivity > inactivityDuration && typeof onInactiveCallback === 'function') {
                onInactiveCallback();
            }
        }, inactivityDuration);

        // Cleanup on unmount
        return () => {
            document.removeEventListener('mousemove', updateUserLastActiveTime);
            document.removeEventListener('keydown', updateUserLastActiveTime);
            document.removeEventListener('scroll', updateUserLastActiveTime);
            clearInterval(checkInactivity);
        };
    }, [lastActiveTime, inactivityDuration, onInactiveCallback]);

    return lastActiveTime;
};

export default useUserActivity;
