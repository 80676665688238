import { getCompanyImage } from '../../../common/styles/Images';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';

const CardBreak = ({ alumni, cardType, state }: { alumni: any; cardType: string; state: any }) => {
    console.log(alumni, ' alumni ');
    const exam = alumni?.entranceTestResult?.exam ?? '';
    const score = alumni?.entranceTestResult?.score ?? '';
    const year = alumni?.entranceTestResult?.year ?? '';
    const score_type_code = alumni?.entranceTestResult?.score_type_code ?? '';

    const break_from_date = alumni?.alumniBreakFrom?.fromDate ?? '';
    const break_to_date = alumni?.alumniBreakFrom?.toDate ?? '';
    let currentName='';
    if (break_from_date && break_to_date) {
        currentName='Took a break'
    } else if (break_from_date) {
        currentName='Taking a break'
    }
    
    return (
        <div className="alumni_univarsity_details break">
            <div className="alumni_univarsity_details_inner_cover">
                <div className="alumni_univarsity_logo">
                    <img src="https://www.univariety.xyz/prototype/alumni-university/images/tea-cup.svg" alt="" />
                </div>
                <div className="alumni_univarsity_brife">
                    <div className="alumni_education">
                        <div className="row g-2"></div>
                    </div>
                    <div className="alumni_univarsity_name">
                        <span>{currentName}</span>
                    </div>
                    <div className="alumni_univarsity_subject">
                        <i className=""></i>
                        {break_to_date ? (
                            <span>From {break_from_date} to {break_to_date}</span>
                        ) : (
                            <span>Since {break_from_date}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardBreak;
