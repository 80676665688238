import { useContext, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import Layout from './Layout';
import useManagementDashboard from '../../management/dashboard/useManagementDashboard';
import { getinstituteList } from '../../../library/Service/domainService';

const AlumniMatchStudents = () => {
    const { globalState } = useContext(GlobalContext);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;
    const groupMappings = {
        K12_SCHOOL: 'K12_SCHOOL',
        UG: 'UG',
        PG: 'PG'
    };
    const getGroupMappingArray = (groupMappings:any) => {
        const response = Object.entries(groupMappings).map(([key, value]) => ({
            label: key.replace('_', ' '),
            value: value
        }));
        return response;
    };    
    const groupMappingArray = getGroupMappingArray(groupMappings);
    const [levelList, setLevelList] = useState(getinstituteList('PROGRAM_GROUP_LEVEL', groupMappingArray));

    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        domain.set('SCHOOL_GROUP_CODE', internal.schoolGroupDomain);
        domain.set('PROGRAM_GROUP_LEVEL', levelList);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, internal, routeInfo },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <DebugBarControl />
            {!state.flags.isDataLoading && <Layout />}
        </SmartContext.Provider>
    );
};

export default AlumniMatchStudents;
