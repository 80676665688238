export type StoredItem = {
    data: any;
    storedAt: Date;
};

export const storeDomainData = (data: any) => {
    sessionStorage.setItem('domain-data', JSON.stringify(Array.from(data.entries())));
};

// export const storeCarouselData = (data: any) => sessionStorage.setItem('carousel-data', JSON.stringify(data));
// export const storeActivityConfig = (data: any) => sessionStorage.setItem('activity-config', JSON.stringify(data));

export const storeAppConfig = (data: any[]) => {
    sessionStorage.setItem('activity-config', JSON.stringify(data.find((item: any) => item.data_key === 'ACTIVITY_CONFIG')['data_value']));
    sessionStorage.setItem('carousel-data', JSON.stringify(data.find((item: any) => item.data_key === 'CAROUSEL_DATA')['data_value']));
    sessionStorage.setItem('benefits-config', JSON.stringify(data.find((item: any) => item.data_key === 'BENEFITS_CONFIG')['data_value']));
};

export const storePageConfig = (pageName: string, data: any) => sessionStorage.setItem(`page-config-${pageName}`, JSON.stringify(data));

export const storePageData = (pageName: string, data: any, id: number) =>
    sessionStorage.setItem(`page-data-${id}-${pageName}`, JSON.stringify(data));

export const getValueFromSessionStore = (key: string) => sessionStorage.getItem(key);

export const storeInLocalStorage = (pageName: string, state: any, id: number) => {
    storeDomainData(state['domain']);
    storePageConfig(pageName, state['formConfig']);
    storePageData(pageName, state['data'], id);
};
