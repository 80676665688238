import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { Outlet, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../library/Service/domainService';
// import '../../../styles/css/alumniDashboard.css';
// import '../../../styles/css/form.css';
import SessionContext from '../../../library/Core/SessionContext';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import '../../../styles/css/revamp_style.css';
import AdminAlumniProfileHeader from './sections/AdminAlumniProfileHeader';
import ProfileHeader from './sections/ProfileHeader';
import StudentProfileHeader from '../../student/view/sections/ProfileHeader';
import { getProfileView, getRelatedAlumni, getTabsConfig } from './service/student-profile-view.service';
import { getMenuType, isEmpty, isSchoolAdmin } from '../../../library/Core/SmartFunctions';

const AlumniProfile = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);

    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    // const { getRelatedAlumni } = useDirectory();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        internal: {},
        actions: { ROUTER_NAVIGATE: navigate },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;
    useEffect(() => {
        const currentUser = sessionState?.studentId;
        let userInstitutes = data.userInstitutes;
        if (currentUser != id || isEmpty(sessionState?.tabType)) {
            // if ( isSchoolAdmin() ) {
            let tempSessionState = { ...sessionState };

            let tabType = getMenuType({
                ...data?.userHighestProgramData,
                pursuingEducationLevelCode: data?.profile?.pursuingEducationLevelCode,
                highestCompletedEducationLevelCode: data?.profile?.highestCompletedEducationLevelCode,
            });

            tempSessionState.tabType = tabType;
            sessionDispatch({ type: 'SET', payload: tempSessionState });
            // }
        }
    }, []);

    let tabConfig = [
        { id: 'profile-marker', label: 'Profile Marker', route: 'profile-marker' },
        { id: 'office-usage', label: 'Office Usage', route: 'office-usage' },
        { id: 'profile-information', label: 'Profile Information', route: 'profile-information' },
        { id: 'ug', label: 'UG', route: 'ug' },
        { id: 'pg', label: 'PG', route: 'pg' },
        { id: 'work', label: 'Work', route: 'work' },
        { id: 'mentoring-interests', label: 'Consent', route: 'mentoring-interests' },
        { id: 'achievements', label: 'Achievements', route: 'achievements' },
        { id: 'contributions', label: 'Contributions', route: 'contributions' },
        { id: 'skills', label: 'Skills', route: 'skills' },
        { id: 'preferences', label: 'Preferences', route: 'preferences' },
        { id: 'interaction', label: 'Interaction', route: 'interaction' },
        { id: 'benefits', label: 'Benefits', route: 'benefits' },

        { id: 'preference', label: 'Preference', route: 'preference' },
        { id: 'parent-info', label: 'Parent Information', route: 'parent-info' },
        { id: 'education', label: 'Education Details', route: 'education' },
        { id: 'entrance-exams', label: 'Entrance Exam', route: 'entrance-exams' },
        { id: 'extra-curricular', label: 'Extra Curricular', route: 'extra-curricular' },
        { id: 'guidance-videos', label: 'Guidance Videos', route: 'guidance-videos' },
    ];

    type TabRefs = {
        [key: string]: React.RefObject<HTMLButtonElement>; // Use the appropriate type for the elements you're referencing
    };

    const tabRefs: TabRefs = {};

    // tabConfig.forEach(tab => {
    //     tabRefs[tab.id] = useRef<HTMLButtonElement>(null); // Specify the type of the ref as HTMLButtonElement
    // });
    tabRefs['office-usage'] = useRef<HTMLButtonElement>(null);
    tabRefs['profile-information'] = useRef<HTMLButtonElement>(null);
    tabRefs['ug'] = useRef<HTMLButtonElement>(null);
    tabRefs['pg'] = useRef<HTMLButtonElement>(null);
    tabRefs['work'] = useRef<HTMLButtonElement>(null);
    tabRefs['mentoring-interests'] = useRef<HTMLButtonElement>(null);
    tabRefs['achievements'] = useRef<HTMLButtonElement>(null);
    tabRefs['contributions'] = useRef<HTMLButtonElement>(null);
    tabRefs['skills'] = useRef<HTMLButtonElement>(null);
    tabRefs['preferences'] = useRef<HTMLButtonElement>(null);
    tabRefs['interaction'] = useRef<HTMLButtonElement>(null);
    tabRefs['benefits'] = useRef<HTMLButtonElement>(null);

    tabRefs['preference'] = useRef<HTMLButtonElement>(null);
    tabRefs['parent-info'] = useRef<HTMLButtonElement>(null);
    tabRefs['education'] = useRef<HTMLButtonElement>(null);
    tabRefs['entrance-exams'] = useRef<HTMLButtonElement>(null);
    tabRefs['job-preferences'] = useRef<HTMLButtonElement>(null);
    tabRefs['extra-curricular'] = useRef<HTMLButtonElement>(null);
    tabRefs['guidance-videos'] = useRef<HTMLButtonElement>(null);

    let currentTab: any = tabConfig.filter((tab) => {
        const targetPath = `/alumni/${id}/profile/${tab.route}`;
        return location.pathname == targetPath;
    });

    let tabRef: any;

    useLayoutEffect(() => {
        const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
        const isPeerReviewed: boolean = sessionState?.isPeerReviewed ?? false;
        const userTypeCode = sessionState?.userTypeCode;
        if (!isPeerReviewed && isPersonate != true && userTypeCode == 'ALUMNI' && sessionState?.profileStatusCode == 'COMPLETE') {
            navigate('/alumni/welcome');
        }
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                // data: { ...data, matchingAlumni: {}, selectedTab: 'ug' },
                data: { ...data, matchingAlumni: {}, selectedTab: currentTab[0]?.id },
                domain,
                routeInfo,
                internal: { model: getProfileView({ data, domain, context: sessionState }) },
            },
        });
    }, []);

    useLayoutEffect(() => {
        if (isEmpty(sessionState?.tabType)) {
            return;
        }

        if (typeof currentTab[0]?.id == 'undefined' || currentTab[0]?.id == '') {
            const displayTabConfig = getTabsConfig(id, state, sessionState);
            // currentTab = [{id: displayTabConfig[0].route}]
            currentTab = [
                {
                    id:
                        (!isEmpty(sessionState?.pursuingEducationLevelCode) && sessionState?.pursuingEducationLevelCode < 12) ||
                        sessionState?.tabType == 'STUDENT'
                            ? displayTabConfig[0].route
                            : 'ug',
                },
            ];
        }

        const currentTabId = currentTab[0]?.id;
        tabRef = tabRefs[currentTabId];

        setTimeout(() => {
            if (tabRef && tabRef.current) {
                tabRef.current.click(); // Trigger a click event on the button
            }
        }, 100); // Delay the click event by 100 milliseconds
    }, [sessionState?.tabType]);

    const handleTabNavigation = (tab: any) => {
        const targetPath = `/alumni/${id}/profile/${tab.route}`;
        if (location.pathname !== targetPath) {
            navigate(targetPath, { replace: true });
        }
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'selectedTab', value: tab.route } });
        tab.id = id;
        if (tab.route == 'skills' && isEmpty(tab.filterCriteria.skillsIn)) {
            return false;
        }
        getRelatedAlumni(tab, dispatch);
    };

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            {!state?.flags.isDataLoading && (
                <div className="main flex-1">
                    {/* {sessionStorage.getItem('is-impersonated-session') === 'YES' ? <AdminAlumniProfileHeader /> : <ProfileHeader />} */}
                    {sessionStorage.getItem('is-impersonated-session') === 'YES' ? (
                        <AdminAlumniProfileHeader />
                    ) : sessionState?.tabType == 'ALUMNI' ? (
                        <ProfileHeader />
                    ) : sessionState?.tabType == 'STUDENT' ? (
                        <StudentProfileHeader />
                    ) : (
                        <ProfileHeader />
                    )}
                    <section className="alumni_profile_detail_wrap container-fluid" id="alumni_profile_detail_wrap">
                        <div className="max-1140 mx-auto alumni_profile_detail">
                            <div className="alumni_profile_brife_tab student_profile_brife_tab">
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    {getTabsConfig(id, state, sessionState).map((tab) => (
                                        <li key={`tabs-${tab.route}`} className={`nav-item`} role="presentation">
                                            <button
                                                className={`nav-link ${state.data?.selectedTab === tab.route ? 'active' : ''}`}
                                                id={`pills-${tab.route}-tab`}
                                                data-bs-toggle="pill"
                                                data-bs-target={`#pills-${tab.route}`}
                                                type="button"
                                                role="tab"
                                                aria-controls={`pills-${tab.route}`}
                                                aria-selected="true"
                                                // ref={eval(`${tab.route}Ref`)}
                                                ref={tabRefs[tab.route]}
                                                onClick={() => handleTabNavigation(tab)}>
                                                {tab.label}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <Outlet />
                        </div>
                    </section>
                </div>
            )}
            <DebugBarControl />
        </SmartContext.Provider>
    );
};

export default AlumniProfile;
