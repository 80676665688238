import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { onAlbumPost } from './AlbumAction';
import CreateAlbumLayout from './CreateAlbumLayout';

const CreateAlbum = () => {
    const { id, pageName } = useParams();
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { PUBLISH_ALBUM: onAlbumPost, ROUTER_NAVIGATE: navigate },
    } as any);

    let { formConfig, data } = useLoaderData() as State;

    useEffect(() => {
        const domain = globalState.domain;
        if (id === 'new') {
            dispatch({
                type: 'DATA_INIT',
                payload: { formConfig, data: { memories: { albumContentSet: [] } }, domain, routeInfo: { id, pageName } },
            });
        } else {
            dispatch({
                type: 'DATA_INIT',
                payload: { formConfig, data: { memories: data }, domain, routeInfo: { id, pageName } },
            });
        }
    }, []);

    // if (
    //     sessionStorage.getItem('user-type-code') === 'STUDENT' &&
    //     sessionStorage.getItem('user-profile-status-code') === 'PREFERENCES_INCOMPLETE'
    // ) {
    //     window.location.href = `/student/${sessionStorage.getItem('user-id')}/student-preference`;
    //     return null; // Redirection is initiated, so no need to render further
    // }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <CreateAlbumLayout>{!state.flags.isDataLoading && <PageBuilder />}</CreateAlbumLayout>
        </SmartContext.Provider>
    );
};
export default CreateAlbum;
