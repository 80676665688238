import { useContext, useEffect, useRef, useState } from 'react';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, getControlValueFromState, handleControlValueChange, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const SingleCheckBoxControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl
    const isHidden = evaluateExpression(control?.hideExpression, state?.data);
    const [defaultValue, setDefaultValue] = useState(control?.props?.customProperties?.default ?? "");

    const readOnly =
        control.readOnly ||
        evaluateExpression(control.readOnlyExpression, state?.data, getControlValueFromState(parentDataKey as string, state as State));

    useEffect(() => {
        const errorMessages = validateFormField(control, data, state, control?.props?.label, dataKey);
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });

        // Set the default value in the state if the data is empty
        if (isEmpty(data) && !isEmpty(defaultValue)) {
            handleControlValueChange({ control, value: defaultValue, dataKey, parentDataKey, state, dispatch });
        }
    }, []);

    if (isHidden) return <></>;

    return (
        <>
            <div className="form-check">
                <input
                    id={control.id}
                    data-testid={control.id}
                    type="checkbox"
                    className="form-check-input"
                    hidden={control.props?.isCode || isHidden}
                    checked={(!isEmpty(data) && data == 1) || (isEmpty(data) && defaultValue == 1)}
                    required={control.props?.required}
                    onChange={(event) =>
                        handleControlValueChange({ control, value: event.target.checked, dataKey, parentDataKey, state, dispatch })
                    }
                    disabled={readOnly ?? false}
                    ref={formControlRef}
                />
                {control?.props?.label && (
                    <label htmlFor={control.id} className={`form-check-label w-100 ${control.props.labelCSS}`}>
                        {/* {`${control.props.label} `} */}
                        <div dangerouslySetInnerHTML={{ __html: control.props.label }} />
                    </label>
                )}
                <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
            </div>
        </>
    );
};

export default SingleCheckBoxControl;
