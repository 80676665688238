import { useContext, useState } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const daysOfWeek = [
    { id: 1, label: 'Mon' },
    { id: 2, label: 'Tue' },
    { id: 3, label: 'Wed' },
    { id: 4, label: 'Thu' },
    { id: 5, label: 'Fri' },
    { id: 6, label: 'Sat' },
    // Add Sunday if needed
];

const TimeSlotsControl = (args: SimpleFormControlArguments) => {
    // const { dataKey } = props;
    const { dataKey } = args;
    const { state, dispatch } = useContext(SmartContext);
    const tmpData = getControlValueFromState(dataKey, state as State, [{}]);
    const data = tmpData?.length > 0 ? tmpData : [{}];
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    const validateTimeSlot = (slot: any) => {
        const errors: string[] = [];

        if (!slot.dayOfTheWeek || slot.dayOfTheWeek.length === 0) {
            errors.push('At least one day of the week must be selected.');
        }

        if (!slot.fromTime || !slot.toTime) {
            errors.push('Both start and end times must be entered.');
        } else {
            const fromDate = new Date(`1970-01-01 ${slot.fromTime}`);
            const toDate = new Date(`1970-01-01 ${slot.toTime}`);

            if (fromDate >= toDate) {
                errors.push('End time must be greater than start time.');
            }
        }

        setValidationErrors(errors);
        return !errors.length; // return true if no errors
    };

    const handleAddMore = () => {
        dispatch({ type: 'ADD_NEW_RECORD_IN_ARRAY', payload: { dataKey, value: {} } });
    };

    const handleDayChange = (index: number, dayId: number, checked: boolean) => {
        let updatedDays = [...(data[index].dayOfTheWeek || [])];
        if (checked && !updatedDays.includes(dayId)) {
            updatedDays.push(dayId);
        } else if (!checked && updatedDays.includes(dayId)) {
            updatedDays = updatedDays.filter((id) => id !== dayId);
        }
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: `${dataKey}.${index}.dayOfTheWeek`, value: updatedDays } });
        validateTimeSlot(data[index]);
    };

    const handleTimeChange = (index: number, type: string, value: string) => {
        if (type === 'from') {
            dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: `${dataKey}.${index}.fromTime`, value } });
        } else {
            dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: `${dataKey}.${index}.toTime`, value } });
        }
        validateTimeSlot(data[index]);
    };

    const handleRemove = (index: number) => {
        dispatch({ type: 'DELETE_RECORD_IN_ARRAY', payload: { dataKey, index } });
    };

    return (
        <div className="row">
            {/* {validationErrors.map((error, idx) => (
                <div key={idx} className="col-12 alert alert-danger">
                    {error}
                </div>
            ))} */}
            {data?.map((slot: any, index: number) => (
                <div key={index} className="mt-3">
                    <div className="col-md-12 time-slots">
                        <label htmlFor="MinExp" className="form-label m-0 mb-1 font-16 font-500 w-100">
                            Preferred time to get calls from Univariety
                        </label>
                        <div className="row">
                            {daysOfWeek?.map((day) => (
                                <div className="col-auto mb-3" key={day.id}>
                                    <div className="form-check d-flex flex-wrap pe-4">
                                        <input
                                            className="form-check-input me-2"
                                            type="checkbox"
                                            value={day.id}
                                            checked={slot.dayOfTheWeek?.includes(day.id)}
                                            onChange={(e) => handleDayChange(index, day.id, e.target.checked)}
                                        />
                                        <label className="form-check-label font-16 font-500 flex-1 pt-1" htmlFor={day.label}>
                                            {day.label}
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="bg-gray p-4 radius-6">
                            <div className="row">
                                <div className="col-sm">
                                    <input
                                        type="time"
                                        className="form-control flex-1 timepicker"
                                        value={slot.fromTime}
                                        onChange={(e) => handleTimeChange(index, 'from', e.target.value)}
                                    />
                                </div>
                                <div className="col-sm-auto px-0 py-2 py-sm-0 d-flex align-items-center justify-content-center">To</div>
                                <div className="col-sm">
                                    <input
                                        type="time"
                                        className="form-control flex-1 timepicker"
                                        value={slot.toTime}
                                        onChange={(e) => handleTimeChange(index, 'to', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {index > 0 && (
                        <label className="text-right w-100">
                            <a
                                role="button"
                                onClick={() => handleRemove(index)}
                                className="static_remove_row"
                                href="javascript:;"
                                id="ecremove_2"
                                style={{ marginLeft: '450px' }}>
                                {' '}
                                - Remove
                            </a>
                        </label>
                    )}
                </div>
            ))}
            <ErrorControl errorMessages={validationErrors} />
            <div className="col-md-12 mb-35 pt-1" id="changeaddmore">
                {/* The logic for "add_ec" should be translated to React, like creating a new slot in state */}
                <a className="font-16 text-gray-41 timelink" id="but_add" onClick={handleAddMore} role="button">
                    <span>+ Add More time Slots</span>
                </a>
            </div>
        </div>
    );
};

export default TimeSlotsControl;
