import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState,useEffect } from 'react';
import ReactDOM from 'react-dom';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty } from '../../../library/Core/SmartFunctions';

const WtappPreviewModal = ({ data }: any) => {
    const { selectedSendingReasonValue, communicationChannel, WtappBody, SubmitWtappPreview, selectedItems, handleRemoveEmail } = data;
    const { state, dispatch } = useContext(SmartContext);
    const [previousModal, setPreviousModal] = useState('');

    // const [previousModal, setPreviousModal] = useState(
    //     communicationChannel == 'Both_Email_WA' ? 'showEmailPreviewModal' : 'showUserModal'
    // );
    useEffect(() => {
        setPreviousModal(
            communicationChannel === 'Both_Email_WA' ? 'showEmailPreviewModal' : 'showUserModal'
        );
    }, [communicationChannel]);

    const showFlag = state?.modalFlags?.showWhatsAppPreviwModal;

    useEffect(() => {
        console.log("selectedItems",selectedItems)
        const emailList = document.querySelector('.selectedWtappIds');
        if (emailList) {
            const liElements = emailList.querySelectorAll('li');
            const elementsToRemove: HTMLElement[] = [];
            liElements.forEach((liElement) => {
                const spanElement = liElement.querySelector('.mobile_input');
                 if (spanElement !== null) {
                    const emailValue = spanElement.textContent?.trim();
                    console.log("emailValue",emailValue)
                    if (!isEmpty(emailValue)) {
                        const isMatched = selectedItems.some((item: any) => {
                           // return maskMobile(item.mobile_country_code,item.mobile_number.trim().toLowerCase()) === emailValue;
                           if (!isEmpty(item.mobile_number)) {
                                return maskMobile(
                                    item.mobile_country_code,
                                    item.mobile_number.trim().toLowerCase()
                                ) === emailValue;
                            }
                            return false; 
                        });
                        if (!isMatched) {
                            emailList.removeChild(liElement);
                        }
                    }
                 }         
                });
          
        }
    }, [selectedItems]);

    const maskMobile = (country_code: number | null, mobile: string) => {
        if (mobile !== null && !isEmpty(mobile)) {
            if (country_code !== null) {
                return `+${country_code} ${'X'.repeat(mobile.length - 4)}${mobile.slice(-4)}`;
            }
            return `${'X'.repeat(mobile.length - 4)}${mobile.slice(-4)}`;
        }
        return mobile;
    };

    const [error, setError] = useState<string | null>(null);

    const addMoreMobileLabel = () => {
        const mobileList = document.querySelector('.selectedWtappIds');

        if (mobileList) {
            const previousEmailFields = mobileList.querySelectorAll('li');

            let allPreviousFieldsValid = true;

            previousEmailFields.forEach((liElement) => {
                const spanElement = liElement.querySelector('.mobile_input');
                if (spanElement !== null) {
                    const emailValue = spanElement.textContent;
                    console.log(emailValue);

                    if (emailValue !== null) {
                        const trimmedEmailValue = emailValue.trim();
                        if (trimmedEmailValue === '') {
                            setError('Please enter a valid Mobile number.');
                            allPreviousFieldsValid = false;
                        }
                    }
                }
            });

            if (allPreviousFieldsValid) {
                const liElement = document.createElement('li');
                liElement.setAttribute('data-withoutmask', '');

                const spanElement = document.createElement('span');
                spanElement.setAttribute('contenteditable', 'true');
                spanElement.classList.add('mobile_input');

                const aElement = document.createElement('a');

                // Create a React component for the FontAwesome icon
                const liElements = mobileList.querySelectorAll('li');
                const liCount = liElements.length;
                console.log(liCount);

                const iconComponent = <FontAwesomeIcon icon={faTimesCircle} onClick={() => handleAddedEmailRemove(liElement)} />;

                ReactDOM.render(iconComponent, aElement);

                spanElement.addEventListener('keyup', (event: any) => {
                    const newValue = event.target.textContent.trim();
                    const isValidEmail = isValidPhoneNumber(newValue);

                    if (isValidEmail) {
                        liElement.setAttribute('data-withoutmask', newValue);
                        setError('');
                    } else {
                        setError('Please enter a valid Mobile Number.');
                        // spanElement.textContent = ''; // Clear the input
                    }
                });

                liElement.appendChild(spanElement);
                liElement.appendChild(aElement);
                mobileList.appendChild(liElement);
            }
        }
    };

    const handleAddedEmailRemove = (liElement: any) => {
        const mobileList = document.querySelector('.selectedWtappIds');
        if (mobileList && liElement) {
            mobileList.removeChild(liElement);

            const previousEmailFields = mobileList.querySelectorAll('li');

            let allPreviousFieldsValid = true;

            previousEmailFields.forEach((liElement) => {
                const spanElement = liElement.querySelector('.mobile_input');
                if (spanElement !== null) {
                    const mobileValue = spanElement.textContent;

                    if (mobileValue !== null) {
                        const trimmedEmailValue = mobileValue.trim();
                        if (trimmedEmailValue === '') {
                            setError('Please enter a valid Mobile number.');
                            allPreviousFieldsValid = false;
                        }
                    }
                }
            });

            if (allPreviousFieldsValid) {
                setError('');
            }
        }
    };

    const isValidPhoneNumber = (phoneNumber: any): boolean => {
        const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;
        console.log(phoneRegex.test(phoneNumber));
        return phoneRegex.test(phoneNumber);
    };

    const errorDiv = error ? <div className="alert alert-danger">{error}</div> : null;

    return (
        // <div
        //     className="modal fade px-3"
        //     id="select-user-popup-whatsapp-review"
        //     data-bs-backdrop="static"
        //     data-bs-keyboard="false"
        //     tabIndex={-1}
        //     aria-labelledby="staticBackdropLabel"
        //     style={{ display: 'none' }}
        //     aria-hidden="true">
        <div
            id="select-user-popup-whatsapp-review"
            className={`modal fade ${showFlag ? 'show' : ''}`} // removed vh-100 and show class
            tabIndex={-1}
            role="dialog"
            style={{ display: showFlag ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-dialog-centered max-800 mx-auto">
                <div className="modal-content">
                    <div className="modal-content">
                        <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
                            <div className="d-flex flex-wrap">
                                <a
                                    onClick={() => {
                                        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: previousModal } });
                                    }}
                                    href="javascript:;"
                                    data-bs-dismiss="modal"
                                    className="fa fa-arrow-circle-left icon icon-left-arrow mt-1 me-2 go-back text-black font-20 text-decoration-none"></a>
                                <h5 className="modal-title flex-1" id="staticBackdropLabel">
                                    <span>Send Profile</span> <span>&gt; </span><i className="fa fa-angle-right"></i> <span>Review Whatpp</span>
                                </h5>
                            </div>

                            <button
                                type="button"
                                className="btn-close btn-close-small"
                                onClick={() => {
                                    dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showWhatsAppPreviwModal' } });
                                }}></button>
                        </div>
                        <div className="modal-body p-4">
                            <form className="lstCustForm needs-validation" noValidate>
                                <div className="row g-4">
                                    <div className="col-md-12">
                                        <div className="sentEmail">
                                            <div className="sentEmailTo">To -</div>
                                            <div className="sentEmailToMailIds">
                                                <ul className="selectedWtappIds">
                                                    {Array.isArray(selectedItems) &&
                                                        selectedItems.map((item, index) =>
                                                            item.mobile_number !== null ? (
                                                                <li
                                                                    data-withoutmask={item.mobile_country_code + ' ' + item.mobile_number}
                                                                    key={index}>
                                                                    <span className="mobile_input">
                                                                        {maskMobile(item.mobile_country_code, item.mobile_number)}
                                                                    </span>
                                                                    <a onClick={() => handleRemoveEmail(index)}>
                                                                        <FontAwesomeIcon icon={faTimesCircle} />
                                                                    </a>
                                                                </li>
                                                            ) : (
                                                                ''
                                                            )
                                                        )}
                                                </ul>
                                                <button
                                                    type="button"
                                                    className="btn btn-green max-135 addmore_email"
                                                    onClick={() => addMoreMobileLabel()}>
                                                    <span>+ Add More Recipients</span>
                                                </button>
                                            </div>
                                        </div>
                                        {errorDiv}
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mailBody">
                                            <div className="row g-3">
                                                <div className="col-auto col-sm-3">
                                                    <div className="label">Reason of sending</div>
                                                </div>
                                                <div className="col col-sm-9"> {selectedSendingReasonValue}</div>

                                                <div className="col-sm-12">
                                                    <div className="label">Preview</div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <textarea
                                                        readOnly={true}
                                                        className="form-control"
                                                        style={{ height: '200px' }}
                                                        value={WtappBody}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-auto">
                                        <button
                                            type="button"
                                            className={`btn btn-green max-135 ${error ? 'disabled-button' : ''}`}
                                            onClick={() => SubmitWtappPreview()}
                                            disabled={error !== null && error !== ''}>
                                            <span>Send </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WtappPreviewModal;
