import axios from 'axios';
// import config from '../../../config/config';
import { isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../library/Core/SmartTypes';
import logger from '../../../library/Core/logger';

export const onSignUp = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void
    // setAuthData: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const isnotvalidMobile = isMobileNotValid(state, dispatch);
    if (!isFormValid(state, dispatch) || isnotvalidMobile) {
        return;
    }

    dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [] });

    let signupLink = `${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/register`;
    let directSignup = false;
    let userStatusCode = 'PENDING_EMAIL_VERIFICATION';

    // if registration is done using invitation then set another endpoint for registration

    axios
        .post(`${signupLink}`, {
            ...state['data']['user'],
            invitedUserID: 111,
            userTypeCode: 'ADMIN',
            profileStatusCode: 'START',
            userStatusCode,
        })
        .then((res: any) => {
            const responseUserData = res.data as any;
            dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [responseUserData] });

            if (responseUserData.success === false) {
                return;
            }

            // if mode was invitation signup then expect token and redirect to info page else show the message only and verify email
            if (directSignup == true) {
                if (!responseUserData.data.token) return alert('There is some issue in registration. Please try after sometime!');
                // setAuthData({ type: 'SET_AUTH_DATA', payload: res.data });
                storeLoginInfoInSessionStorage(responseUserData.data, state?.routeInfo?.schoolDomain);

                navigateToLandingPage({
                    studentId: responseUserData?.data?.id,
                    userTypeCode: responseUserData?.data?.userTypeCode,
                    profileStatusCode: responseUserData?.data?.profileStatusCode,
                    state,
                });
            } else {
                // alert(responseUserData.message);

                state.actions['ROUTER_NAVIGATE'](`/internal-dashboard/login`);
                return;
            }
        })
        .catch((err: any) => {
            if (err.response && err.response.status === 409) {
                // Handle conflict error (HTTP 409)
                alert('The user already exists.');
            } else {
                // Handle other errors
                console.error(err);
                alert('An error occurred during registration. Please try again later.');
            }
        });
};

const navigateToLandingPage = ({ studentId, userTypeCode, userStatusCode, profileStatusCode, state }: any) => {
    let routeToUrl;
    // TODO: Remove this map.
    const NAVIGATION_MAP: any = {
        'ALUMNI-START': `/alumni/${studentId}/info`,
        'ALUMNI-STEP_ONE_COMPLETE': `/alumni/${studentId}/info`,
        'ALUMNI-STEP_TWO_COMPLETE': `/alumni/${studentId}/info`,
        'ALUMNI-COMPLETE': `/alumni/${studentId}/info`,
        'STUDENT-PREFERENCES_INCOMPLETE': `/student/${studentId}/info`,
        'STUDENT-PREFERENCES_COMPLETE': `/student/${studentId}/info`,
        SCHOOL_ADMIN: `/management/dashboard`,
        SUPPORT: ``,
    };

    if (userTypeCode === 'SCHOOL_ADMIN') {
        routeToUrl = NAVIGATION_MAP[`SCHOOL_ADMIN`];
    } else {
        routeToUrl = NAVIGATION_MAP[`${userTypeCode}-${profileStatusCode}`];
    }

    state.actions['ROUTER_NAVIGATE'](routeToUrl);
};

export const onLogIn = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    isFormValid(state, dispatch) ? logger.log('Form is valid') : logger.log('Form is invalid. Please correct the errors.');

    const email = state?.data?.user?.registeredEmailId;
    const password = state?.data?.user?.password;

    const validRegex = state?.formConfig?.sectionRepository[0]?.controlGroup[0]?.props?.pattern
        ? new RegExp(state?.formConfig?.sectionRepository[0]?.controlGroup[0]?.props?.pattern)
        : /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (validRegex.test(email ? email : '') == false) {
        return alert('Please enter a valid email address');
    }

    const passwordMinLength = state?.formConfig?.sectionRepository[0]?.controlGroup[1]?.props?.minLength;
    const passwordMaxLength = state?.formConfig?.sectionRepository[0]?.controlGroup[1]?.props?.maxLength;
    if (
        (password ? password?.length : 0) < (passwordMinLength ? passwordMinLength : 6) ||
        (password ? password?.length : 0) > (passwordMaxLength ? passwordMaxLength : 50)
    ) {
        return alert('Please enter a valid password');
    }

    if (!isFormValid(state, dispatch)) return alert('Please fill in the user name and password');

    const response = await axios
        .post(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/authenticate`, state['data']['user'])
        .then((result: any) => {
            if (!result.data.token) {
                dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [result.data] });
                return;
            }

            const user = result.data as any;

            storeLoginInfoInSessionStorage(user, user?.schoolDomain);

            localStorage.setItem('school-image', user?.logo);
            sessionStorage.setItem('school-image', user?.logo);
            // navigateToNextStep('login', state);
            navigateToLandingPage({
                studentId: user?.id,
                userTypeCode: user?.userTypeCode,
                userStatusCode: user?.userStatusCode,
                profileStatusCode: user?.profileStatusCode,
                state,
            });
        });

    // const res = await axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/common/list-for-name-search`);
    // globalDispatch({ type: 'SET_USER_NAME_FOR_SEARCH', payload: res.data });
};

export const storeLoginInfoInSessionStorage = (user: any, schoolDomain: string) => {
    sessionStorage.setItem('user-id', user.id);
    sessionStorage.setItem('user-login-id', user.loginId ?? 448220);
    sessionStorage.setItem('user-jwt-token', user.token);
    sessionStorage.setItem('user-first-name', user.firstName);
    sessionStorage.setItem('user-last-name', user.lastName);
    sessionStorage.setItem('user-name', user.firstName + '' + user.lastName);
    sessionStorage.setItem('user-email-id', user.registeredEmailId);
    sessionStorage.setItem('user-school-id', user.schoolId);
    sessionStorage.setItem('user-profile-status-code', user.profileStatusCode);
    sessionStorage.setItem('user-status-code', user.userStatusCode);
    sessionStorage.setItem('user-school-group-id', user.schoolGroupId);
    sessionStorage.setItem('user-type-code', user.userTypeCode);
    sessionStorage.setItem('pass-out-year', user.passOutYear ?? 2010);
    sessionStorage.setItem('school-founding-year', user.schoolFoundingYear ?? 2000);
    sessionStorage.setItem('is-peer-reviewed', user.isPeerReviewed ?? false);
    sessionStorage.setItem('user-logged-out', 'FALSE');
    sessionStorage.setItem('user-profile-image', user.profileImage);
    sessionStorage.setItem('user-hash', user.userHash);

    localStorage.setItem('user-logged-out', 'FALSE');
    localStorage.setItem('user-type-code', user.userTypeCode);
    localStorage.setItem('univariety-user', JSON.stringify(user));
    localStorage.setItem('user-school-domain', schoolDomain);
    localStorage.setItem('user-jwt-token', user.token);

    if (user.userTypeCode == 'STUDENT') {
        sessionStorage.setItem('user-class', user.classCode);
        sessionStorage.setItem('user-board-ref-id', user.curriculumCode);
    }
};

//Validation check for length based on country code
export const onMobileNumberChange = (props: ControlOnChangeArguments) => {
    // console.log(props.value);
    const mobileCountryCode = props.row['mobileCountryCode'];
    const mobileNumber = props.value;

    if (mobileNumber?.length == 0) {
        props.errorMessages.push('Please enter mobile number');
        return;
    }

    if (mobileCountryCode != '91') return;

    const errorMessage = 'Please enter a valid mobile number';

    if ((mobileNumber?.length > 10 || mobileNumber?.length < 10 || parseInt(props.value.charAt(0)) < 6) && mobileCountryCode == 91) {
        props.errorMessages.push(errorMessage);
    }
};

export const onEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // if(props.value != '' && event.key === 'Enter')
    console.log(JSON.stringify(event) + '================================event========================');
    // console.log(JSON.stringify(props.value)+'============================props==============================');
};

const isMobileNotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    if (isEmpty(state?.data?.user?.mobileCountryCode)) {
        setError('user.mobileCountryCode', ['Please enter mobile code'], dispatch);
        return true;
    }

    if (isEmpty(state?.data?.user?.mobileNumber)) {
        setError('user.mobileNumber', ['Please enter mobile number'], dispatch);
        return true;
    }

    return false;
};
