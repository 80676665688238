import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SmartContext } from '../../../library/Core/SmartContext';
import JobCardsControl from './JobCardsControl';
import JobFiltersControl from './JobFiltersControl';
import JobListingService from './JobListingService';
import SessionContext from '../../../library/Core/SessionContext';
import { isSchoolAdmin, isSuperAdmin } from '../../../library/Core/SmartFunctions';

const JobListingLayout = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    const summaryCounts = state?.data?.summaryCounts || {};

    const handleActiveTabSelect = (tab: string) => {
        JobListingService.tabChange(tab, dispatch);
    };

    // const userTypeCode = sessionStorage.getItem('user-type-code');
    const userTypeCode = sessionState?.userTypeCode;
    
    return (
        <section className="notificationSection">
            <div className="container max-1140 px-lg-0">
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <div className="white-block white-block-notopborderradius h-100 p-0">
                            <div className="m-w-90 d-flex flex-wrap justify-content-between align-items-center mb-1 px-4 py-3 py-sm-4">
                                <h3
                                    className="font-35 font-600 mb-0 mt-2 mt-sm-3 block-title aos-init aos-animate"
                                    data-aos="fade-left"
                                    data-aos-delay="300">
                                    Jobs &amp; Internships
                                </h3>
                                <div className="dashboard innerpage smart-scroll scrolled-down">
                                    {/* <a
                                        href="/jobs/new"
                                        className="btn btn-green w-auto mt-2 mt-sm-3 height-40 d-inline-flex text-white font-14 text-decoration-none job_internship_btn aos-init aos-animate"
                                        data-aos="fade-right"
                                        data-aos-delay="300">
                                        <span>Post a Job/Internship</span>
                                    </a> */}
                                    {/* {userTypeCode != "STUDENT" && <Link */}
                                    {userTypeCode == "ALUMNI" && <Link
                                        to="/job-posting/new"
                                        className="btn btn-green w-auto mt-2 mt-sm-3 height-40 d-inline-flex text-white font-14 text-decoration-none job_internship_btn aos-init aos-animate"
                                        data-aos="fade-right"
                                        data-aos-delay="300">
                                        <span>Post a Job/Internship</span>
                                    </Link>}
                                </div>
                            </div>
                            <div className="row job_internship_tab polartabsec">
                                <div className="xscroll p-0">
                                    <ul
                                        className="nav nav-tabs aos-init aos-animate"
                                        id="JobInternshipTab"
                                        role="tablist"
                                        data-aos="fade-left"
                                        data-aos-delay="600">
                                        <li className="nav-item" role="presentation" id="ActiveTab">
                                            <button
                                                className="nav-link active"
                                                id="JobListing-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#JobListing"
                                                type="button"
                                                role="tab"
                                                aria-controls="JobListing"
                                                aria-selected="true"
                                                onClick={() => handleActiveTabSelect('ACTIVE_LIST')}>
                                                Active List <span id="ActiveCount">({summaryCounts?.activeList ?? 0})</span>
                                            </button>
                                        </li>
                                        {!isSchoolAdmin() && sessionState?.userTypeCode!=='SUPER_ADMIN' && (
                                            <li className="nav-item" role="presentation" id="SaveTab">
                                                <button
                                                    className="nav-link"
                                                    id="JobListing-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#JobListing"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="JobListing"
                                                    aria-selected="false"
                                                    onClick={() => handleActiveTabSelect('SAVED')}>
                                                    Saved Listings <span id="SavedCount">({summaryCounts?.saved ?? 0})</span>
                                                </button>
                                            </li>
                                        )}
                                        {! isSchoolAdmin() && sessionState?.userTypeCode!=='SUPER_ADMIN' && (
                                            <li className="nav-item" role="presentation" id="InterestTab">
                                                <button
                                                    className="nav-link"
                                                    id="JobListing-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#JobListing"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="JobListing"
                                                    aria-selected="false"
                                                    onClick={() => handleActiveTabSelect('SENT_INTEREST')}>
                                                    Sent Interest <span id="InterestCount">({summaryCounts?.sentInterest ?? 0})</span>
                                                </button>
                                            </li>
                                        )}
                                        
                                        {/* {userTypeCode != "STUDENT" && <li className="nav-item" role="presentation" id="ListedTab"> */}
                                        {userTypeCode == "ALUMNI" && <li className="nav-item" role="presentation" id="ListedTab">
                                            <button
                                                className="nav-link"
                                                id="JobListing-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#JobListing"
                                                type="button"
                                                role="tab"
                                                aria-controls="JobListing"
                                                aria-selected="false"
                                                onClick={() => handleActiveTabSelect('LISTED_BY_USER')}>
                                                Listed by You <span id="ListedCount">({summaryCounts?.listedByUser ?? 0})</span>
                                            </button>
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                            <div className="row m-0 px-4 pt-4 pb-5 mt-2 JobInternshipListing m-w-90">
                                <JobFiltersControl />
                                <JobCardsControl />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default JobListingLayout;
