import { Tooltip } from 'bootstrap';
import React, { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import ManagementDashboardService from './ManagementDashboardService';
import useManagementDashboard from './useManagementDashboard';

const DashboardAccordionItem = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const config: any = ManagementDashboardService.config[props.reportKey];
    const { batches, campuses } = state.data;
    const [expanded, setExpanded] = useState(false);
    const { setStateValue } = useManagementDashboard();
    const [selectedCampuses, setSelectedCampuses] = useState<string[]>([]);

    const reportBatch = ManagementDashboardService.getBatchesForSelectedTab(state.data.selectedTab, state.data.batches);
    const reportCampuses = ManagementDashboardService.getCampusForSelectedTab(state.data, config);

    const handleCampusSelection = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const campusValue = event.currentTarget.value;
        if (campusValue === 'ALL') {
            let campus_arr:any = [];
            if(event.currentTarget.checked)
            {
                reportCampuses.forEach((item:any) => {
                    campus_arr.push(item.value);
                })
            }
            setSelectedCampuses(campus_arr);
            handleSelectionChange(`searchCriteria.${config.key}.campus`, campus_arr.join(','));
            return;
        }
        setSelectedCampuses((prevSelectedCampuses) => {
            const updatedSelectedCampuses = prevSelectedCampuses.includes(campusValue)
                ? prevSelectedCampuses.filter((campus: string) => campus !== campusValue)
                : [...prevSelectedCampuses, campusValue];

            handleSelectionChange(`searchCriteria.${config.key}.campus`, updatedSelectedCampuses.join(','));

            return updatedSelectedCampuses;
        });
    };

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef: RefObject<HTMLDivElement> = useRef(null);
    const handleOutsideClick = (event: any) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const tooltipTxt = props.tooltipTxt ?? '';

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleSelectionChange = (dataKey: string, value: string) => setStateValue(dataKey, value);

    useEffect(() => {
        // Update individual accordion state based on the global accordion state
        setExpanded(state.data.accordionState === 'EXPANDED');
    }, [state.data.accordionState]);

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, []);

    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id={`panelsStayOpen-${props.reportKey}`} onClick={() => setExpanded(!expanded)}>
                <button
                    className={`accordion-button ${expanded ? 'collapsed' : ''}`}
                    type="button"
                    aria-expanded={expanded}
                    aria-controls={`${props.reportKey}`}>
                    {config.title}
                    <span className="icon-info" title="" data-bs-toggle="tooltip" data-bs-original-title={tooltipTxt} aria-label=""></span>
                </button>
            </h2>
            <div
                id={`item-${props.reportKey}`}
                className={`accordion-collapse collapse ${expanded ? 'show' : ''}`}
                aria-labelledby={`item-${props.reportKey}`}>
                <div className="accordion-body">
                    <div className="d-flex flex-wrap flex-1 mb-4">
                        {config.filters.includes('batch') && (
                            <select
                                key={`${props.reportKey}-batch`}
                                onChange={(event) => handleSelectionChange(`searchCriteria.${config.key}.batch`, event.target.value)}
                                className="form-select onboarding me-2"
                                aria-label="Default select example">
                                <option value="">Batch</option>
                                {reportBatch.map((item: any) => (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        )}

                        {/* {config.filters.includes('dateRange') && (
                            <select
                                key={`${props.reportKey}-dateRange`}
                                onChange={(event) => handleSelectionChange(`searchCriteria.${config.key}.dateRange`, event.target.value)}
                                className="form-select onboarding me-2"
                                aria-label="Default select example">
                                <option value="">Date</option>
                                {(state?.domain?.get('MGMT_DASH_DATE_RANGE') as any).map((item: any) => (
                                    <option key={item.code} value={item.code}>
                                        {item.value}
                                    </option>
                                ))}
                            </select>
                        )} */}

                        {config.filters.includes('campus') && campuses.length > 0 && (
                            // <select
                            //     key={`${props.reportKey}-campus`}
                            //     onChange={(event) => handleSelectionChange(`searchCriteria.${config.key}.campus`, event.target.value)}
                            //     className="form-select onboarding me-2"
                            //     aria-label="Default select example">
                            //     <option value="">Campus</option>
                            //     {campuses.map((item: any) => (
                            //         <option key={item.value} value={item.value}>
                            //             {item.label}
                            //         </option>
                            //     ))}
                            // </select>

                            <div className={`dropdown ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
                                {/* <label htmlFor={`campus-label`} className="form-label w-100">
                                    Select Campus
                                </label> */}
                                <button className="filter-custom-select dashboard-filter" type="button" onClick={() => setIsOpen(!isOpen)}>
                                    {selectedCampuses.length > 0
                                        ? selectedCampuses
                                              .map((selectedCampus) => campuses.find((item: any) => item.value === selectedCampus)?.label)
                                              .join(', ')
                                        : 'Select Campus'}
                                </button>
                                <div className="dropdown-menu" style={{ display: isOpen ? 'block' : 'none', background: 'white' }}>
                                    <div className="dropdown-wrap">
                                        <div key={`all-domain-select`} className="dropdown-item">
                                            <input
                                                type="checkbox"
                                                id={`${props.reportKey}-allCampus`}
                                                className="form-check-input me-1"
                                                checked={selectedCampuses.length === campuses.length}
                                                onClick={handleCampusSelection}
                                                value="ALL"
                                                onChange={() =>
                                                    setSelectedCampuses(
                                                        selectedCampuses.length === campuses.length
                                                            ? []
                                                            : campuses.map((item: any) => item.value)
                                                    )
                                                }
                                            />
                                            <label htmlFor={`${props.reportKey}-allCampus`} className="form-check-label">
                                                All Campuses
                                            </label>
                                        </div>
                                        {reportCampuses.map((item: { value: string; label: string }) => (
                                            <div key={item.value} className="dropdown-item">
                                                <input
                                                    type="checkbox"
                                                    id={`${props.reportKey}-campus-${item.value}`}
                                                    className="form-check-input me-1"
                                                    checked={selectedCampuses.includes(item.value)}
                                                    onClick={handleCampusSelection}
                                                    value={item.value}
                                                />
                                                <label htmlFor={`${props.reportKey}-campus-${item.value}`} className="form-check-label">
                                                    {item.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div id="stdonboardgraph">
                                <div className="d-block text-center">{props.children}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardAccordionItem;
