import { axiosClient } from '../../../library/Service/axiosClient';
import { REPORT_CONFIG } from '../../admin/reports/report-config';
import pageConfig from './pageConfig.json';

class ProfileMarkerNotesService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = this.getDefaultSearchCriteria();

        return Promise.all([
            this.getPageConfig(),
            this.search(searchCriteria, params.pageName,params.id),
            this.getSchoolSearchFilterDomains(params.pageName),
            // this.getaddedByDropdown(),
        ]).then((values) => {
            state.formConfig = values[0];
            state.data = {
                notesList: values[1].data ?? [],
                searchCriteria: { ...searchCriteria },
                filterDomains: values[2].data,
                reportConfig: REPORT_CONFIG[params.pageName],
            };
            state.routeInfo = params;
             state.internal = { gridState: {} };
            return Promise.resolve(state);
        });
    }

    static search(searchCriteria: any, reportType: string,id:any): any {
        const payload = this.sanitizeSearchCriteria(searchCriteria, reportType);
        if (id) {
            payload.searchById = id;
        }
        return axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/profielmarkernotes/get-details`, payload);
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    static sanitizeSearchCriteria(searchCriteria: any, reportType: string) {
        return searchCriteria;
    }
    
    static getaddedByDropdown(){
        return axiosClient().post(`${process.env.REACT_APP_ADMIN_API_SERVER}/markers/get-addedBy-details`);
    }

    static getSchoolSearchFilterDomains(reportName: string = '') {
        return axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/school-group-search-filter-domains`);
    }

    static getDefaultSearchCriteria(): any {
        return {
            addedBy:'',
            createdFrom: '',
            createdTo: ''
        };
    }
}

export default ProfileMarkerNotesService;
