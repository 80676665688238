import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains, getProgramLevelDomainForSchooling } from '../../../../library/Service/domainService';
import { handleSubmit, onSubjectWiseMarksChange } from './EducationActions';
import EducationLayout from './EducationLayout';
import SessionContext from '../../../../library/Core/SessionContext';
import { isEmpty, setError } from '../../../../library/Core/SmartFunctions';
import useManagementDashboard from '../../../management/dashboard/useManagementDashboard';

const Education = () => {
    const { globalState } = useContext(GlobalContext);
    const { id, pageName } = useParams();
    const navigate = useNavigate();

    const { getprogramLevels } = useManagementDashboard();


    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { SUBMIT: handleSubmit, ROUTER_NAVIGATE: navigate,
             SubjectWiseMarkstoggle: onSubjectWiseMarksChange
         },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;
    const { sessionState }: any = useContext(SessionContext);
    const [dispatchevent,setDispatchEvent]=useState(false)

    const levels = getprogramLevels(sessionState);
    const [programLevel, setprogramLevel] = useState(getProgramLevelDomainForSchooling('PROGRAM_LEVEL_SCHOOL', levels));

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        // data.schools = (data.schools.length > 0) ? data.schools : [{cieGrade:{}}];
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });

        dispatch({
            type: 'SET_DOMAIN',
            payload: { key: 'PROGRAM_LEVEL_SCHOOL', value: programLevel },
        });
    }, []);

    useEffect(() => {
        if (state?.data?.schools) {
            const updatedSchools = state.data.schools.map((school:any) => {
                if (!isEmpty(school?.cieGrade)) {
                    return school; 
                } else {
                    setDispatchEvent(true)
                    return { ...school, "cieGrade": {} }; 
                }
            });
    
             if (dispatchevent) {
                setDispatchEvent(false)
                // dispatch({
                //     type: 'REFRESH_DATA',
                //     payload: { data: { schools: updatedSchools } }, 
                // });
                dispatch({
                    type: 'REFRESH_DATA',
                    payload: { 
                        data: {
                            ...state.data,  // Spread the existing data
                            schools: updatedSchools  // Update only the schools node
                        }
                    }
                });
             }
        }
    }, [state?.data?.schools]);

   

    if ( sessionState?.userTypeCode === 'STUDENT' && sessionState?.profileStatusCode === 'PREFERENCES_INCOMPLETE' ) {
        window.location.href = `/alumni/${sessionState?.id}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }
    // console.log("updatedSchools",data.schools)

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <EducationLayout>{!state.flags.isDataLoading && <PageBuilder />}</EducationLayout>
        </SmartContext.Provider>
    );
};

export default Education;
