import { useNavigate } from 'react-router-dom';
import KudosPng from '../../../../styles/images/kudos.png';

const StepTwoCompletion = () => {
    const navigate = useNavigate();

    const handleViewProfile = () => {
        sessionStorage.setItem('user-status-code', 'ACTIVE');
        sessionStorage.setItem('user-profile-status-code', 'COMPLETE');
        navigate(`/alumni/${sessionStorage.getItem('user-id')}/profile`);
    };

    return (
        <>
            <div className="" style={{ minHeight: '100vh' }}></div>
            <div
                className="modal fade show"
                id="step1complete_pop"
                aria-labelledby="skip_popLabel"
                tabIndex={-1}
                style={{ display: 'block' }}
                aria-modal="true"
                role="dialog">
                <div className="modal-dialog modal-dialog-centered success_popup">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="brife">
                                <h4>Kudos To Your All-Star Profile! </h4>
                                <p>Yours is a complete profile bringing value to students, and benefits for you! </p>
                            </div>
                            <div className="img_pop">
                                <img src={KudosPng} alt="" />
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                className="btn btn-goback d-inline-flex align-items-center justify-content-center"
                                onClick={handleViewProfile}>
                                VIEW PROFILE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StepTwoCompletion;
