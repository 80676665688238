import { useContext, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import EventsService from './EventsService';

const EventFilters = () => {
    const { state, dispatch } = useContext(SmartContext);
    const filterConfig = EventsService.filterConfig;
    const filterDomain = state.data.filterDomain;
    const filters = state.data.filters;
    const [showFilter, setShowFilter] = useState(Object.fromEntries(Object.entries(filterConfig).map((item: any) => [item.id, false])));

    const handleFilterItemClick = (event: any, filterType: string, value: string) => {
        // Note: Having the event preventDefault here will cause the checkbox to not be checked
        // event.preventDefault();
        const isChecked = state.data?.filters[filterType]?.includes(value);
        const actionType = isChecked ? 'DELETE_RECORD_IN_SIMPLE_ARRAY' : 'ADD_NEW_RECORD_IN_ARRAY';
        dispatch({
            type: actionType,
            payload: { dataKey: `filters.${filterType}`, name: event.target.id, value, errorMessages: [] },
        });
    };

    const handleClear = () => {
        const filters = state.data.filters;
        const updatedFilters = Object.keys(filters).reduce((acc: any, filterType: string) => {
            acc[filterType] = [];
            return acc;
        }, {});

        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'filters', value: updatedFilters } });
    };

    const handleShowHideFilter = (filterId: string) => {
        setShowFilter({ ...showFilter, [filterId]: !showFilter[filterId] });
    };

    const [isOpen, setOpen] = useState(false);

    const handleFilterToggle = () => {
        setOpen(!isOpen);
    };

    return (
        <>
            <div className="col-md-4 ji_filtersec ps-1">
                <div className="mobileFilter d-flex justify-content-between align-items-center d-md-none mobileonly text-end">
                    <h3 className="font-18 font-500 mb-0" id="MobHeadblock">
                        videos found
                    </h3>
                    <button
                    className="btn-filter btn-unscroll font-18 font-500"
                    type="button"
                    onClick={handleFilterToggle}
                    aria-expanded={isOpen ? 'true' : 'false'}
                    aria-controls="filter_collapse">
                    <span className="icon-filter"></span>Filters
                </button>
                </div>
                <div className={`filters collapse ${isOpen ? '' : 'show'}`} id="filterCollapseToggle">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                        <button
                        className="btn-filter btn-unscroll mobileonly"
                        type="button"
                        onClick={handleFilterToggle}
                        aria-expanded={isOpen ? 'true' : 'false'}
                        aria-controls="filter_collapse">
                        Filters
                    </button>
                            <h3 className="font-18 font-500 mb-2 mt-2">
                                <span className="icon-filter"></span>Filters
                            </h3>
                        </div>
                        <a className="font-14 font-500 text-burgandi text-decoration-underline" role="button" onClick={handleClear}>
                            Clear
                        </a>
                    </div>

                    <div className="filterGroup" id="accordionFilters">
                        {Object.values(filterConfig)?.map((filter: any) => (
                            <div key={filter.id} className="fiterItem mb-3" id="CourseBlock">
                                <div className="filterHeader">
                                    <div className="d-flex justify-content-between align-items-center position-relative">
                                        <button
                                            className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center collapsed"
                                            type="button"
                                            onClick={() => handleShowHideFilter(filter.id)}
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse${filter.id}`}
                                            aria-expanded={`${showFilter[filter.id] ? 'true' : 'false'}`}
                                            aria-controls={`collapse${filter.id}`}>
                                            <span className="font-18 font-600">{filter.label}</span>
                                            <span className="toggle"></span>
                                        </button>
                                    </div>
                                </div>
                                {showFilter[filter.id] && (
                                    <div
                                        className="accordion-collapse collapse show"
                                        id={`collapse${filter.id}`}
                                        data-bs-parent="accordionFilters">
                                        <div className="card card-body mt-2">
                                            <div className="overflow-hidden">
                                                <div className="scrollMe">
                                                    <div className="courses">
                                                        <div id={filter.id}>
                                                            {filterDomain[filter.id]?.map((domain: any) => (
                                                                <div
                                                                    key={domain[filter.code]}
                                                                    className="form-check"
                                                                    style={{ display: 'flex !important' }}>
                                                                    <input
                                                                        id={filter.id}
                                                                        className="form-check-input me-2 Courses_class"
                                                                        type="checkbox"
                                                                        // value={domainValues[filter.code]}
                                                                        checked={filters[filter.searchParam]?.includes(domain[filter.code])}
                                                                        onChange={(event) =>
                                                                            handleFilterItemClick(
                                                                                event,
                                                                                filter.searchParam,
                                                                                domain[filter.code]
                                                                            )
                                                                        }
                                                                    />
                                                                    <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1">
                                                                        {`${domain[filter.displayText]} (${domain['count']})`}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventFilters;
