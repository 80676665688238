import React, { useContext,useState, useEffect, useRef  } from 'react';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';
import thumbImg from './../../styles/images/video-thumb-aishwarya-advice-to-juniors.jpg';
import { axiosClient } from '../../library/Service/axiosClient';
import { getControlValueFromState, handleControlValueChange } from '../../library/Core/SmartFunctions';
import { SmartContext } from '../../library/Core/SmartContext';
import { profile } from 'console';
import { SimpleFormControlArguments, State } from '../../library/Core/SmartTypes';
import qrCode from '../../styles/images/qr.jpg';
import uploadImg from '../../styles/images/upload.svg';

const ShareMyProfile =  (args: SimpleFormControlArguments) => {      
  interface Account {
    access_token: string;
    name: string;
    id: string;
  }
  const { state, dispatch } = useContext(SmartContext);
  const { control, dataKey, parentDataKey } = { ...args };
  const data = getControlValueFromState(dataKey, state as State);
  const [image, setImage] = useState(data);
  
  const handleImageUpload = async (e: any) => {
      let link = '';
      if (e.target.files != null) {
          const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/profile`;
          const formData = new FormData();
          formData.append('image', e.target.files[0]);

          const response = await axiosClient().post(apiUrl, formData);
          link = response.data.link;
          console.log(link, " LINK ")
          setImage(link);
          handleControlValueChange({ control, value: link, dataKey, parentDataKey, state, dispatch });
      }
  };

  const uploadImageAndGetUrl = async () => {
    let imageUrl;
    try {
      console.log(images, " images ");
      if (images.length > 0) {
        const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/profile`;
        const formData = new FormData();
        const response1 = await axiosClient().post(apiUrl, formData);
        imageUrl = response1.data.link; // Get the image URL from the API response
        return imageUrl;

      }
    } catch (error) {
      console.error('Error uploading image:', error);
      return null; // Return null in case of an error
    }
    
    return imageUrl; // Return null if no image is uploaded
  };

  
  // useEffect(() => {
  //   const fetchImgUrl = async () => {
  //     const urlTest = await uploadImageAndGetUrl();
  //   console.log(urlTest,'test');
  //   };
  
  //   fetchImgUrl();
  // }, []);

  // useEffect(() => {
  //   const fetchImgUrl = async () => {
  //     try {
  //       const urlTest = await uploadImageAndGetUrl();
  //       console.log(urlTest, ' urlTest ');
  //     } catch (error) {
  //       console.error('Error fetching image URL:', error);
  //     }
  //   };
  
  //   fetchImgUrl();
  // },);


  const [selectedOption, setSelectedOption] = useState('facebook');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };
  const handleFacebookLogin = () => {
    const clientId = '2237577053229786';
    const redirectUri = 'https://www.univariety.com/facebook/call_back.php';
    const state = '12345';

    const apiUrl = 'https://www.facebook.com/v16.0/dialog/oauth';
    const url = `${apiUrl}?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&state=${state}`;

    // Redirect to the Facebook login page
    window.location.href = url;
  };

  const [postText, setPostText] = useState('');
  const [images, setImages] = useState<File[]>([]);
  const currentURL: string = window.location.href;
  const urlObject = new URL(currentURL);

// Related to facebook api
  const code_url = urlObject.searchParams.get("code");
 // if (code_url) {
    //console.log(code_url);

  

// function to get access tokenk
    async function getAccessToken(): Promise<string | null> {

      const accessToken = 'EAAfzEIFqUtoBOxk6xsenBX7S65zjHjnABIzYza4xqQQMdI6TKIPxDW3UvhWg7OrFb1emX9zqJZABUQQ1XJfE46ihHnqgWYegh3gUDN0v3sHbk2riDfsWEX5Tw3IL8zn66uDZCr3pCFlCRbQVp5QLZAfw6WuwaV9ES4a3ym8cUAXJN1uSZA5K4osKUySClVZADC4ddVHOafF9hNX1PPKkZAMLOC3UwAwZCN8SXWXVFh7icMhB1A1uApiTW0QbObx';//For testing
      return accessToken;
      // const clientId = '2237577053229786';
      // const clientSecret = 'aa316e7020610c83ec231eea91847f54';
      // const redirectUri = 'http://localhost:3000/share-profile';
    
      // const url = `https://graph.facebook.com/v16.0/oauth/access_token`;
      // const params = {
      //   client_id: clientId,
      //   client_secret: clientSecret,
      //   redirect_uri: redirectUri,
      //   code: code_url,
      // };
    
      // try {
      //   const response = await axios.get(url, { params });

      //   const accessToken= response.data.access_token;

      //   return accessToken;
      // } catch (error) {
      //   console.error('Error getting access token:', error);
      //   return null;
      // }
    
    }
   

    //function to get userid
    async function getUserId() {
      const accessToken = await getAccessToken();
    
      if (accessToken) {
        //alert(accessToken);
        console.log(accessToken);
        const fields = 'id';
         
        const url = `https://graph.facebook.com/v16.0/me`;
          const params = {
            client_id: fields,
            access_token: accessToken,
            
          };
        
          try {
            const response = await axios.get(url, { params });
  
            const id= response.data.id;
           // alert(id);
            console.log("id:="+id);
            return id;
          } catch (error) {
            console.error('Error getting access token:', error);
            return null;
          }

      } 
    }

    //function to get pageid 
    async function getPagesId() {
      const accessToken = await getAccessToken();
      const id=await getUserId();
      if (accessToken) {
      //  alert(accessToken);
      //  alert(id);
          const url = `https://graph.facebook.com/v16.0/`+id+`/accounts`;
          const params = {
            access_token: accessToken
          };
        
          try {
            const response = await axios.get(url, { params });
            const accounts = response.data.data.map((account: any) => {
              return {
                access_token: account.access_token,
                name: account.name,
                id: account.id
              };
            });
        
            return accounts;
          } catch (error) {
            console.error('Error getting accounts:', error);
            return [];
          }
        
      } 
    }
    
    async function main() {
      //const accessToken = 'YOUR_ACCESS_TOKEN'; // Replace with your actual access token
      const accounts = await getPagesId();
    
      if (accounts.length > 0) {
        accounts.forEach((account: Account) => {
          console.log('Access Token:', account.access_token);
          console.log('Name:', account.name);
          console.log('ID:', account.id);

         
     
          
         
            // const fetchData = async () => {
            //     try {
            //         const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/profile/save-social-data`,{'page_id':account.id,'school_id':1234,'access_token':account.access_token,'page_name':account.name});
            //         const data = await response.data; // Extract the data from the response
                   
            //     } catch (error) {
            //         console.error('Error fetching data:', error);
            //         alert(error);
            //     }
            // };

    
          //  fetchData();
        
              
        
        });
      } else {
        console.log('No accounts found.');
      }
    }

    
    main();
    
    // Call anotherFunction
    async function uploadPhoto(url: string, message: string, accessToken: string, pageId :string, post_type: Number): Promise<void> {
      let api_url:string="test";
      //alert(post_type);
      if(post_type===1){
       api_url =`https://graph.facebook.com/v16.0/${pageId}/feed`; // Update with the correct URL
      }
      else{
        api_url =`https://graph.facebook.com/v16.0/${pageId}/photos`; // Update with the correct URL
      }
     // alert(api_url);
      const apiEndpoint=api_url;
      const data = {
        url: url,
        message: message,
        access_token: accessToken,
      };
    
      try {
        const response = await axios.post(apiEndpoint, data);
    
        if (response.status === 200) {
          console.log('Data Posted successfully.');
        } else {
          console.log('Photo upload failed.');
        }
      } catch (error) {
        console.error('Error uploading photo:', error);
      }
    }
  


    const [pageData, setPageData] = useState<any[]>([]);

    
const showPageName = async () => {
  try {
    const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/profile/get-social-data`, {'school_id': 1234});
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};
  
useEffect(() => {
  const fetchData = async () => {
    const pageData: any = await showPageName();
    setPageData(pageData.data);
  };

  fetchData();
}, []);

console.log(pageData);
 const [selectedPage, setSelectedPage] = useState<any | null>(null); // State to store the selected page details
 const [selectedOption1, setSelectedOption1] = useState('facebook');
const handleRadioChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
const selectedValue = event.target.value;
setSelectedOption1(selectedValue);

// Find the selected page in the pageData array
const page = pageData.find((page) => page.page_name === selectedValue);  
setSelectedPage(page);
};
  
const handlePublish = async () => {
if (selectedPage) {
  
  const photoUrl = image;
  const message = postText;
  const accessToken = selectedPage.access_token;
  const pageId = selectedPage.page_id;
  let post_type:number=0;
  if (photoUrl.length===0) {
    post_type=1;
  }
 
  await uploadPhoto(photoUrl, message, accessToken,pageId,post_type);
  alert('Data posted successfully.'); // You can show a success message or perform other actions here
} else {
  alert('Please select a page.'); // Handle the case when no page is selected
}
};
//const [postText, setPostText] = useState('');
const [isMessageFilled, setIsMessageFilled] = useState(false);

useEffect(() => {
  setIsMessageFilled(postText.trim() !== ''); // Update isMessageFilled when postText changes
}, [postText]);

 // } 
 




  const removeImage = (index: number) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };


    return (
      <div className='main flex-1'>
        
      <div className='container max-1140 px-lg-0 overflow-hidden'>
        <div className="row">
                <div className="col-md-12 mb-4">
                  
                  <div className="white-block white-block-notopborderradius h-100 p-0">
                  <div className=''>
                  <div className="headerTitle mb-4 pt-4 pt-md-5 px-4 px-md-5">
                    
                        <h2 className="font-35 font-600 m-0">Create New Post</h2>
                      </div>
                    <div className="row sd_formsec">
    
                      <div className="d-flex flex-column justify-content-between align-items-center mx-auto max-630 pt-4 pb-4 px-0" data-aos="fade-up" data-aos-delay="900" id="no-notification">
                        <div className="lstCustForm w-100 formrowpx mt-2">
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label className="form-label m-0 mb-2 font-16 font-500 w-100" htmlFor="CommunicationType">Post To</label>
                              <div className="row g-3">
                                <div className="col-sm-6 mb-2">
                                  <label className="social-block fb" htmlFor="facebook">
                                    <div className="d-flex flex-wrap">
                                      <img className="me-3" src="images/facebook.svg" width="24" alt="" />
                                    
                                      <span className="d-flex align-items-center">Facebook</span>
                                    </div>
                                    <div className="social-check">
                                    <input checked={true}
                                      type="radio"
                                      name="social"
                                      value="facebook"
                                      id="facebook" className="d-none"
                                      
                                      onChange={handleRadioChange}
                                    />
                                      <label htmlFor="facebook"><i className="icon-correct"></i></label>
                                    </div>
                                  </label>
                                </div>
                               {/* <div className="col-sm-6 mb-2">
                                  <label className="social-block linkedin" htmlFor="linkedin" >
                                    <div className="d-flex flex-wrap">
                                      <img className="me-3" src="images/linkedin_icon.svg" width="18" alt="" />
                                      <span className="d-flex align-items-center">LinkedIn</span>
                                    </div>
                                    <div className="social-check">
                                    <input
                                      type="radio"
                                      name="social"
                                      value="linkedin"
                                      id="linkedin" className="d-none" 
                                      checked={selectedOption === 'linkedin'}
                                      onChange={handleRadioChange}
                                    />
                                      <label htmlFor="linkedin"><i className="icon-correct"></i></label>
                                    </div>
                                  </label>
                                </div>*/}
                              </div>
                              <div className='col-12'>
                                  <div className='pagename'>
                                    <div className='row g-3'>
                                    {
                                    pageData.map((page: any) => (
                                      <div className="col-auto" key={page.id}>
                                        <div className="form-check">
                                        <input
      className="form-check-input"
      type="radio"
      name="flexRadioDefault"
      value={page.page_name}
      id={`flexRadioDefault${page.id}`}
      checked={selectedPage?.page_name === page.page_name}
      onChange={handleRadioChange1}
    />
    <label className="form-check-label" htmlFor={`flexRadioDefault${page.id}`}>
      {page.page_name}

                                        </label>
                                        </div>
                                      </div>
                                    ))}
                                </div>
  
                                  </div>
                              </div>
                            </div>
                          </div>

                          

                          
                          
    <>

    <div className="row mb-4">
            <div className="col-md-12">
              <label className="form-label m-0 mb-1 font-16 font-500 w-100" htmlFor="Message">Post Message</label>
              <textarea
                rows={3}
                className="form-control"
                placeholder="Ex: School is organizing alumni reunion on Campus on 15th September 2021."
                id="Message"
                value={postText}
                onChange={(e) => setPostText(e.target.value)}
              ></textarea>
            </div>
          </div>
          {selectedPage && (
  <div>
    <input id="page_id" type="hidden" value={selectedPage.page_id} readOnly />
    <input id="access_token" type="hidden" value={selectedPage.access_token} readOnly />
  </div>
)}
          <div className="row mb-1">
            <div className="col-md-12">
              <label className="form-label m-0 mb-1 font-16 font-500 w-100" htmlFor="UploadImage">Upload Image</label>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-12 inputfile_parent m-0">
            <div className="file file--upload w-100">
                                                            <label htmlFor="choose_image">
                                                              <i className="material-icons"><img src={uploadImg}/></i> Browse your files
                                                            </label>
                                                          
                                                            <input
                          type="file"
                          name='post_image'
                          className="d-none"
                          id="choose_image"
                          onChange={handleImageUpload}
                          accept="img/*"
                          style={{ opacity: '0.3' }}
                      />
                                                          </div>
              <div className="docselect">
                <span className="me-1">
                  <img src="images/doc_icon.svg" alt="" />
                </span>
                <input
                  type="file"
                  name="fileUpload"
                  id="fileUpload"
                  className="inputfile"
                  data-multiple-caption="{count} files selected"
                  multiple
                  required
                  onChange={(e) => handleImageUpload(e.target.files)}
                />
                <span className="fileclose">
                  <span className="icon-cross"></span>
                </span>
              </div>
            </div>
          </div>
       
      
    </>


                          
                        </div>
                      </div>

                      <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 lite-blue py-5">
                                                    <h2 className="text-center font-35 font-500 mb-4 line-height-26">Preview</h2>


                                                    
                                                  <div className="bg-white radius-5 max-630 mx-auto min-h-420 p-3 p-sm-5">
                                                  {selectedOption === 'facebook' && (
        <div>
                                                  <div className="card facebook" id="facebook-tab">
                                                      <div className='card-header d-flex flex-wrap align-items-center p-3'>
                                                      <span><img className="me-2" src="images/facebook.svg" width="30" alt="" /></span>
                                                      <h5 className="card-title m-0">Facebook</h5>
                                                      </div>
                                                      <div className="card-body">
                                                        
                                                      <p className="card-text">{postText}</p>
                                                        <div className='row g-2'>

                                                           <img alt="" src={image} />
                                                        

                                                        </div>
                                                      </div>
                                                      
                                                    </div>
                                                    </div>
                                                    )}
                                                    {selectedOption === 'linkedin' && (
                                                    <div>
                                                    <div className="card linkedin" id="linkedin-tab">
                                                      <div className='card-header d-flex flex-wrap align-items-center p-3'>
                                                      <span><img className="me-2" src="images/linkedin_icon.svg" width="30" alt="" /></span>
                                                      <h5 className="card-title m-0">Linkedin</h5>
                                                      </div>
                                                      <div className="card-body">
                                                        
                                                      <p className="card-text">{postText}</p>
                                                        <div className='row g-2'>

                                                            {images.map((image, index) => (
                                                            <div className="col" key={index}>
                                                            <img
                                                                src={URL.createObjectURL(image)}
                                                                className="card-img"
                                                                alt={`Uploaded image ${index + 1}`}
                                                            />
                                                            <button
                                                                className="btn btn-danger btn-sm"
                                                                onClick={() => removeImage(index)}
                                                            >
                                                                Remove
                                                            </button>
                                                            </div>
                                                        ))}
                                                        

                                                        </div>
                                                      </div>
                                                      
                                                    </div>
                                                    </div>
                                                      
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="row justify-content-end align-items-center mt-4 broadcast_submit pb-5">
                                        <div className="my-0 d-flex flex-wrap justify-content-center">
                                            {/*<button type="button" className="btn btn-draft"><span>Save as Draft</span></button>*/}
                                            {/*<button type="button" className="btn btn-discard"><span>Discard</span></button>*/}
                                            <button type="button" className="btn btn-publish" onClick={handlePublish}
        disabled={!isMessageFilled} ><span>Publish</span></button>
                                      </div>
                                        </div>
                    
    
                    </div>
                  </div>
                    
                  </div>
                </div>
              </div>
        </div>

        
      </div>
          );
};

export default ShareMyProfile;
