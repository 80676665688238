import { useEffect, useRef } from 'react';
import manageSvg from '../../../styles/images/manage.svg';
import trackSvg from '../../../styles/images/track.svg';
const LoginCarouselControl = () => {
    const data = [
        {
            id: 10,
            imageUrl: manageSvg,
            messageTitle: 'Manage',
            message:
                'Know the parents, students, and teachers who are a part of your school platform. Also stay updated, verify new joinees, and confirm them.',
        },
        {
            id: 20,
            imageUrl: `${process.env.REACT_APP_IMAGE_BASEPATH}/Connect.svg`,
            messageTitle: 'Connect',
            message:
                'Use the platform to connect to any student, parent, alumni, or university. Interact with them individually or by creating custom groups.',
        },
        {
            id: 30,
            imageUrl: trackSvg,
            messageTitle: 'Track',
            message:
                'Stay updated about student and parent journeys, the status of journey-wise tasks, student applications, and admission referrals. Also, get access to the latest reports.',
        },
        {
            id: 40,
            imageUrl: `${process.env.REACT_APP_IMAGE_BASEPATH}/Research.svg`,
            messageTitle: 'Analyze & Research',
            message:
                'Find out the latest career and college trends, scholarship offerings,  learning & development resources, guidance analytics, counselling sessions booked, our events, and helpful webinars.',
        },
        // Add more slides as needed
    ];

    
    const carouselRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (carouselRef.current) {
                const activeSlide = carouselRef.current.querySelector('.carousel-item.active') as HTMLDivElement;
                const slides = carouselRef.current.querySelectorAll('.carousel-item') as NodeListOf<HTMLDivElement>;
                const activeIndex = Array.from(slides).indexOf(activeSlide);
                const nextIndex = (activeIndex + 1) % slides.length;

                slides[activeIndex].classList.remove('active');
                slides[nextIndex].classList.add('active');
                
                const statusBtn = carouselRef.current.querySelectorAll('.status-btn') as NodeListOf<HTMLButtonElement>;
                statusBtn[activeIndex].classList.remove('active');
                statusBtn[nextIndex].classList.add('active');

            }
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="d-flex flex-column justify-content-center w-75 text-center align-items-center">
            <div id="carouselLogin" className="carousel slide" data-bs-ride="carousel"  ref={carouselRef}>
                <div className="carousel-indicators">
                    {data.map((item, index) => (
                        <button
                            key={index}
                            type="button"
                            data-bs-target="#carouselLogin"
                            data-bs-slide-to={index}
                            className={`status-btn bg-danger ${index == 0 ? 'active' : ''}`}
                            aria-current={index == 0 ? 'true' : 'false'}
                            aria-label={`Slide ${index + 1}`}></button>
                    ))}
                </div>
                <div className="carousel-inner mb-5">
                    {data.map((item, index) => (
                        <div key={item.id} className={`carousel-item ${index === 0 ? 'active' : ''}`} data-bs-interval="5000">
                            <figure>
                                <img className="mx-auto" src={item.imageUrl} alt="" />
                            </figure>
                            <h4>{item.messageTitle}</h4>
                            <p>{item.message}</p>
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselLogin" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselLogin" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
};

export default LoginCarouselControl;
