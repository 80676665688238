import {  Modal, Tooltip } from 'bootstrap';
import React,{useEffect, useState} from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


const ShareEmailActionModal = ({data}:any) => {
  const Navigate = useNavigate();
  console.log(data, 'data'); // Debug to see the incoming data
  const alumniData = Array.isArray(data) ? data : []; // Ensure it's an array
  console.log(alumniData, 'Received data'); // Debug to see the incoming data
  const [formData, setFormData] = useState({
    category: '',
    studentID: '', // Example alumni ID
  });
  console.log(formData)

  const [selectedCategory, setSelectedCategory] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
    // UseEffect to set the studentID with the first value from alumniData
  useEffect(() => {
    if (alumniData.length > 0) {
      setFormData((prev) => ({ ...prev, studentID: alumniData[0] })); // Set the studentID with the first item
    }
  }, [alumniData]); // Run this effect when alumniData changes

  const handleCategoryChange = (e:any) => {
    setFormData({ ...formData, category: e.target.value });
    setSelectedCategory(e.target.value);
    if (e.target.value !== '') {
      setValidationMessage(''); // Clear the validation message if the value is not empty
    }
  };


  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility

  const openModal = () => {
    setIsModalVisible(true); // Update state to open the modal
  };

  const closeModal = () => {
    setIsModalVisible(false); // Update state to close the modal
  };

  useEffect(() => {
    const modalElement = document.getElementById('social-share'); // Get the modal by its ID
    if (modalElement) {
      const modal = new Modal(modalElement); // Initialize the Bootstrap modal

      if (isModalVisible) {
        modal.show(); // Open the modal when state is true
      } else {
        modal.hide(); // Close the modal when state is false
      }
    }
  }, [isModalVisible]);

  const handleFormSubmit = (e : any) => {
    e.preventDefault(); // Prevent the default form submission
    if (selectedCategory === '') {
      setValidationMessage('Please select a category.'); // Set validation message if invalid
    } else {
      console.log('Form submitted with category:', selectedCategory);
      // Perform form submission logic here

      console.log('Form data:', formData);
    
      const modalElement = document.getElementById('social-share');
      //const modalElement = document.getElementById('custom-modal');
      if (modalElement) {
        const modal = new Modal(modalElement);
        modal.hide(); // Hide the modal
      }
    
      //     // get modal backdrop
      const modalBackdrops = document.getElementsByClassName('modal-backdrop');

      // remove opened modal backdrop
      document.body.removeChild(modalBackdrops[0]);

        // closeModal();
        Navigate('/broadcast/createnew', {
          state: formData, // Pass the form data to the target route
        });

    }

       // closeModal();
     



      
      // Here, you would send the form data to your server
    };


    useEffect(() => {
      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    });


   

return (
    <div className="modal fade px-3" id="social-email" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" style={{ display: 'none' }} aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered max-800 mx-auto">
      <div className="modal-content">
        <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
          <h5 className="modal-title" id="staticBackdropLabel">Send via Email</h5>
          <button type="button" className="btn-close btn-close-small" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body p-4">
          <form className="needs-validation" noValidate onSubmit={handleFormSubmit}>
            <div className="row g-4">
              <div className="col-md-4">
                <label className="form-label">Reason to Share</label>
                <select 
                className="form-select me-2"
                value={formData.category}
                onChange={handleCategoryChange}
                > 
                  <option value="">Select</option>
                  <option value="1">Remarkable Achievement</option>
                  <option value="2">Success Story</option>
                  <option value="3">Prestigious Institution</option>
                </select>
              </div>
              {/* Conditionally display the validation message */}
            {validationMessage && <div style={{ color: 'red' }}>{validationMessage}</div>}
            </div>
            <input type="hidden" name="studentID" value={alumniData} />
            
            <div className="action_btns mt-3">
            <button type="submit" className="btn btn-green height-45 px-4 me-2"><span>Submit</span></button> 
            <button type="button" className="btn btn-cancel px-4" data-bs-dismiss="modal" aria-label="Close">Close</button>
            {/* <a href="javascript:;" className="btn btn-cancel px-4" data-bs-toggle="modal"  aria-label="Close"><span>Close</span></a> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
    );
  };

  export default ShareEmailActionModal;