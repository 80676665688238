import moment from 'moment';
import { formatFullName, getDomainValueForCode, toSentenceCase } from '../../../../library/Core/SmartFunctions';

class AdminProfileService {
    static getProfileView(state: any) {
        const preferences = JSON.parse(state.data.profile.adminAdditionalInfo);
        const initCap = (str: string) => {
            if (!str) return '';
            return str?.charAt(0)?.toUpperCase() + str?.slice(1).toLowerCase();
        };

        const model: any = {};
        model.id = state.data.id;
        model.salutation = getDomainValueForCode(state.data.profile?.salutationCode, 'SALUTATION', state);
        model.name = formatFullName(state?.data?.firstName, state?.data?.lastName);
        model.residingCity = getDomainValueForCode(state?.data?.profile?.residingCityCode, 'CITY_CODE', state);
        model.residingCountry = getDomainValueForCode(state?.data?.profile?.residingCountryCode, 'COUNTRY_CODE', state);
        model.designation = state.data?.profile?.designation;
        model.schoolName = toSentenceCase(getDomainValueForCode(state?.data?.schoolId, 'SCHOOL_CODE', state));
        model.mobileCountryCode = getDomainValueForCode(state.data.user.mobileCountryCode, 'MOBILE_COUNTRY_CODE', state);
        model.mobileNumber = state.data.user.mobileNumber;
        model.contactEmailId = state.data.profile.contactEmailId;
        model.profilePicture = state.data.profile.profilePictureFilePath;

        // Preferences
        model.timeSlots = AdminProfileService.formatTimeSlots(preferences?.timeSlots ?? []);
        model.interestedInUpskilling = toSentenceCase(preferences?.interestedInUpskilling);
        model.updateFrequencyFromPlatform = getDomainValueForCode(preferences?.updateFrequencyFromPlatform, 'UPDATE_FROM_PLATFORM', state);
        model.counsellingSessionsForCurrentStudents = getDomainValueForCode(
            preferences?.counsellingSessionsForCurrentStudents,
            'COUNSELLING_SESSIONS_FOR_CURRENT_STUDENTS',
            state
        );
        model.wishToConnectWithUniversityRepresentatives = initCap(preferences?.wishToConnectWithUniversityRepresentatives);
        model.additionalEmailId = preferences?.additionalEmailId;

        // Additional Info
        model.schoolJoinDate = preferences?.schoolJoinDate ? moment(preferences?.schoolJoinDate).format('MMM YYYY') : '-';
        model.dateOfBirth =state.data.profile?.dateOfBirth ?  moment(state.data.profile?.dateOfBirth).format('DD-MMM-YYYY') : '-';
        model.addressLineOne = state.data.profile?.addressLineOne ?? '';
        model.addressLineTwo = state.data.profile?.addressLineTwo ?? '';
        model.pin = state.data.profile?.pin ?? '';

        return model;
    }

    static formatTimeSlots = (timeSlots: any) => {
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        return timeSlots.map((slot: any) => {
            const fromTimeFormatted = moment(slot.fromTime, 'HH:mm').format('h:mm A');
            const toTimeFormatted = moment(slot.toTime, 'HH:mm').format('h:mm A');

            // Map array of day IDs to their string representation and join with commas
            const days = Array.isArray(slot?.dayOfTheWeek) ? slot?.dayOfTheWeek.map((dayId: number) => daysOfWeek[dayId - 1]) : [];

            return { time: `${fromTimeFormatted} to ${toTimeFormatted}`, days };
        });
    };
}
export default AdminProfileService;
