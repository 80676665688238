import { useContext, useEffect, useState } from 'react';
import BasicInfoVisual from '../../../../styles/images/basic-info-visual.png';
import SchoolLogo from '../../../../styles/images/image-20.png';
import SessionContext from '../../../../library/Core/SessionContext';

const StudentBooksLayout = (props: any) => {
    const { sessionState } = useContext(SessionContext);
    // const [userStatusCode, setUserStatusCode] = useState(sessionStorage.getItem('user-status-code'));
    const [userStatusCode, setUserStatusCode] = useState(sessionState?.userStatusCode);

    useEffect(() => {
        setUserStatusCode(sessionState?.userStatusCode);
    }, []);
    return (
        <>
            <div className="main-wrap">
                <div className="leftPane">
                    <div className="leftPaneWrap">
                        {userStatusCode != 'ACTIVE' && (
                            <div className="leftPaneHeaderWrap">
                                <div className="leftPaneHeader">
                                    <img src={SchoolLogo} alt="" />

                                    <label className="user_name">
                                        Hey <span>{`${sessionState?.firstName}  ${sessionState?.lastName}`}</span>
                                    </label>
                                </div>
                            </div>
                        )}
                        <div className="leftPaneBodyWrap">
                            <div className="leftPaneBody">
                                <div className="qs_body aos-init aos-animate" data-aos="fade-right" data-nextpage="undergraduate.html">
                                    <div className="steps_questions">{props.children}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rightPane basic-information">
                    <div className="rightPaneWrap">
                        {userStatusCode != 'ACTIVE' && (
                            <div className="rightPaneHeaderWrap">
                                <div className="rightPaneHeader">
                                    <label className="user_name">
                                        <span>{`${sessionState?.firstName}  ${sessionState?.lastName}`}</span>
                                    </label>
                                </div>
                            </div>
                        )}
                        <div className="rightPaneBodyWrap">
                            <div className="rightPaneBody aos-init aos-animate" data-aos="fade-left">
                                <div className="step step_1">
                                    <h2 className="stepHeader">
                                        Updating The Books
                                        <br /> You Have Read -
                                    </h2>
                                    <div className="listed_points">
                                        <ul>
                                            <li>Provides a great picture about your interests and knowledge to others.</li>
                                            <li>Can pair you up with alumni of similar reading interests.</li>
                                        </ul>
                                    </div>
                                    <div className="step_img">
                                        <img src={BasicInfoVisual} className="img-fluid float_horizontal_animate" width="100%" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StudentBooksLayout;
