import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../../../library/Core/SmartContext';
import ButtonPalletControl from '../../../../library/SimpleControls/ButtonPalletControl';
import BenefitsCareerSupport from './BenefitsCareerSupport';
import BenefitsNetworking from './BenefitsNetworking';
import InfoVisual from '../../../../styles/images/mask-group-141.png';
import PopUpImg from '../../../../styles/images/pop-img.png';
import { useNavigate } from 'react-router-dom';
import SessionContext from '../../../../library/Core/SessionContext';

let SchoolLogo = sessionStorage.getItem('school-image') as any;

const BenefitsLayout = (props: any) => {
    const { sessionState } = useContext(SessionContext);
    const userStatusCode = sessionState?.userStatusCode;
    const userTypeCode = sessionState?.userTypeCode;
    // const [userStatusCode, setUserStatusCode] = useState(sessionStorage.getItem('user-status-code'));
    const { state, dispatch } = useContext(SmartContext);
    const navigate = useNavigate();
    // useEffect(() => {
    //     setUserStatusCode(sessionStorage.getItem('user-status-code'));
    // }, []);
    return (
        <>
            {userTypeCode != 'SUPER_ADMIN' && userStatusCode != 'ACTIVE' && (
                <header className="ful-header">
                    <div className="header_container">
                        <img src={SchoolLogo} alt="" />
                        <label className="user_name">
                            Hey <span>{`${sessionState?.firstName}  ${sessionState?.lastName}`}</span>
                        </label>
                    </div>
                </header>
            )}
            <div className="main-wrap">
                <section className="multiple_benefits_banner d-none d-md-block">
                    <div className="multiple_benefits_container">
                        <div className="row g-4">
                            <div className="col-md">
                                <h2>2 SETS OF ELEMENTS. MULTIPLE BENEFITS.</h2>
                                <p>The time has arrived! Unlock your exclusive Alumni Benefits under these 2 categories!</p>
                            </div>
                            <div className="col-md-auto text-center d-flex align-items-end justify-content-center">
                                <img src={InfoVisual} className="float_horizontal_animate" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <div className="step_header_wrap">
                    <a
                        data-bs-toggle="collapse"
                        data-bs-target="#scholarship_detail_list"
                        className="mobile-step-block"
                        aria-expanded="false"></a>
                    <div className="step_header_wrap_title">
                        <h2>2 SETS OF ELEMENTS. MULTIPLE BENEFITS.</h2>
                        <div className="listed_points collapse" id="scholarship_detail_list">
                            <p>The time has arrived! Unlock your exclusive Alumni Benefits under these 2 categories!</p>
                        </div>
                        <button className="step_header_wrap_link">
                            Read <span className="more">More</span>
                            <span className="less">Less</span>
                        </button>
                    </div>
                    <div className="step_header_wrap_img">
                        <img src="images/mask-group-141.png" className="img-fluid float_horizontal_animate" width="100%" alt="" />
                    </div>
                </div>
                <a data-bs-toggle="collapse" data-bs-target="#scholarship_detail_list" className="bg-drop" aria-expanded="false"></a>
                <section className="multiple_benefits_cards">
                    <div className="multiple_benefits_cards_container">
                        <div className="row g-4">
                            <div className="col-md-6">
                                <div className="card network-card">
                                    <div className="card-header">
                                        <h3 className="title">
                                            Networking and
                                            <br /> Alumni Pairing
                                        </h3>
                                        <div className="card-img">
                                            <img src="images/group-75982@2x.png" className="img-fluid" width="156" alt="" />
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="benefits_toggle">
                                            <a
                                                className="btn "
                                                data-bs-toggle="collapse"
                                                href="#networkbenefits"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="collapseExample">
                                                BENEFITS <span className="toggle"></span>
                                            </a>

                                            <div className="collapse" id="networkbenefits">
                                                <div className="benefit-list">
                                                    <ul>
                                                        <li>Pair and interact with alumni that matches your interests.</li>
                                                        <li>Engage with alumni with strong professional backgrounds</li>
                                                        <li>1-1 meetings with relevant alumni</li>
                                                    </ul>

                                                    {/* <button
                                                        className="video-btn"
                                                        data-bs-toggle="modal"
                                                        data-src="https://www.youtube.com/embed/pOoyWNzLm9M"
                                                        data-bs-target="#video_pop">
                                                        Watch Intro<b>#</b>
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="benefit_form">
                                            <div className="row g-3">
                                                <BenefitsNetworking />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card event-card">
                                    <div className="card-header">
                                        <h3 className="title">
                                            Events and <br /> Career Support
                                        </h3>
                                        <div className="card-img">
                                            <img
                                                src="images/study-computer-science-in-canada-capital-city -@2x.png"
                                                className="img-fluid"
                                                width="200"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="benefits_toggle">
                                            <a
                                                className="btn"
                                                data-bs-toggle="collapse"
                                                href="#eventbenefits"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="collapseExample">
                                                BENEFITS <span className="toggle"></span>
                                            </a>
                                            <div className="collapse" id="eventbenefits">
                                                <div className="benefit-list">
                                                    <ul>
                                                        <li>Connect directly with universities worldwide</li>
                                                        <li>Expert counsellor assistance in application process</li>
                                                        <li>Job referrals and upskilling options</li>
                                                    </ul>

                                                    {/* <button
                                                        className="video-btn"
                                                        data-bs-toggle="modal"
                                                        data-src="https://www.youtube.com/embed/pOoyWNzLm9M"
                                                        data-bs-target="#video_pop">
                                                        Watch Intro<b>#</b>
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="benefit_form">
                                            <div className="row g-3">
                                                <BenefitsCareerSupport />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="action_btns" id="buttonPallet">
                                    <ButtonPalletControl actions={state?.formConfig?.buttons as string} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade show" id="skip_pop" aria-labelledby="skip_popLabel" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="brife">
                                <h4>DON’T SKIP THIS!</h4>
                                <p>Why to miss out on the exclusive benefits that you get from the alumni network?</p>
                            </div>
                            <div className="img_pop">
                                <img src={PopUpImg} alt="" />
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                className="btn btn-goback"
                                data-bs-target="#exampleModalToggle"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:none');
                                }}>
                                GO BACK
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    sessionStorage.setItem('user-status-code', 'ACTIVE');
                                    document.getElementById('updateUserStatusCode')?.click();
                                    navigate('/alumni/onboarding');
                                }}
                                className="btn btn-later">
                                I'LL DO THIS LATER
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BenefitsLayout;
