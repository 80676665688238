// import InfoVisual from '../../../../styles/images/mentoring_visual.png';
// import unlockVisual from '../../../../styles/images/unlock.svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import PopUpImg from '../../../../styles/images/popup_visual2.png';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import SessionContext from '../../../../library/Core/SessionContext';

let SchoolLogo = sessionStorage.getItem('school-image') as any;

const IntrestLayout = (props: any) => {
    const [profilePercentage, setprofilePercentage] = useState<any>('');
    const { sessionState } = useContext(SessionContext);
    const userStatusCode = sessionState?.userStatusCode;
    const userTypeCode = sessionState?.userTypeCode;
    //const [userStatusCode, setUserStatusCode] = useState(sessionStorage.getItem('user-status-code'));
    useEffect(() => {
        const profilePercentage = sessionStorage.getItem('profile-percentage') ? sessionStorage.getItem('profile-percentage') : 0;
        setprofilePercentage(profilePercentage);
    }, [sessionStorage.getItem('profile-percentage')]);
    // useEffect(() => {
    //     setUserStatusCode(sessionStorage.getItem('user-status-code'));
    // }, []);
    const navigate = useNavigate();
    return (
        <>
            <div className="main-wrap">
                <div className="leftPane">
                    <div className="leftPaneWrap">
                        {userTypeCode != 'SUPER_ADMIN' && userStatusCode != 'ACTIVE' && (
                            <div className="leftPaneHeaderWrap">
                                <div className="leftPaneHeader">
                                    <img src={SchoolLogo} alt="" />
                                    <label className="user_name">
                                        Hey <span>{`${sessionState?.firstName} ${sessionState?.lastName}`}</span>
                                    </label>
                                </div>
                              
                            </div>
                        )}
                        <div className="leftPaneBodyWrap">
                            <div className="leftPaneBody">
                                <div className="qs_body" data-aos="fade-up" data-nextpage="test.html">
                                    <div className="steps showTab" date-bgclr="basic-information" id="step1">
                                        <div className="steps_questions">{props.children}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rightPane basic-information bg-purple-gradient">
                    <div className="rightPaneWrap">
                        {userTypeCode != 'SUPER_ADMIN' && userStatusCode != 'ACTIVE' && (
                            <div className="rightPaneHeaderWrap">
                                <div className="rightPaneHeader">
                                    <label className="user_name">
                                        Hey{' '}
                                        <span>{`${sessionState?.firstName} ${sessionState?.lastName}`}</span>
                                    </label>
                                </div>
                            </div>
                        )}
                        <div className="rightPaneBodyWrap">
                            <div className="rightPaneBody aos-init aos-animate" data-aos="fade-left">
                                <div className="step step_1">
                                    <a
                                        data-bs-toggle="collapse"
                                        data-bs-target="#listed_points"
                                        href=""
                                        className="mobile-step-block collapsed"
                                        aria-expanded="false"></a>
                                    <div className="textDetail">
                                        <h2 className="stepHeader">What Does Being a Mentor Mean?</h2>
                                        <div className="listed_points" id="listed_points">
                                            <ul>
                                                <li>Guide students and get rewarded</li>
                                                <li>Inspire others through your journey</li>
                                                <li>Conduct exclusive webinars</li>
                                            </ul>
                                        </div>
                                        <a href="" className="step_header_wrap_link">
                                            Read <span className="more">More</span>
                                            <span className="less">Less</span>
                                        </a>
                                    </div>
                                    <div className="step_img mt-0">
                                        <img
                                            src={`${process.env.REACT_APP_IMAGE_BASEPATH}/mentor_visual.svg`}
                                            className="img-fluid float_horizontal_animate"
                                            width="100%"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a
                    data-bs-toggle="collapse"
                    data-bs-target="#listed_points"
                    href=""
                    className="bg-drop collapsed"
                    aria-expanded="false"></a>
            </div>

            <div className="modal fade show" id="skip_pop" aria-labelledby="skip_popLabel" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="brife">
                                <h4>WHY LEAVE NOW?</h4>
                                <p>Your journey could inspire many! And, it starts with just 1 Video!</p>
                            </div>
                            <div className="img_pop">
                                <img src={PopUpImg} alt="" />
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                className="btn btn-goback"
                                data-bs-target="#exampleModalToggle"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:none');
                                }}>
                                GO BACK
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    sessionStorage.setItem('user-status-code', 'ACTIVE');
                                    document.getElementById('updateUserStatusCode')?.click();
                                    navigate('/alumni/onboarding');
                                }}
                                className="btn btn-later">
                                I'LL DO THIS LATER
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IntrestLayout;
