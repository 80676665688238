import { isFormValid, isValidBusinessObject } from '../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
// import { toStudentEntity } from '../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import axios from 'axios';

export const handleSubmit = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const formValidationPassed = isFormValid(state, dispatch, ['achievements']);
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    if (!customValidationPassed || !formValidationPassed) {
        dispatch({ type: 'SHOW_ERRORS' });
        alert('Please correct the form errors!');
        return;
    }

    // Send event to Moengage my basics
    sendMoEngageEvent(state?.data);

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            achievements: state?.data['achievements'],
        })
    ).then(() => {
        state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile/extra-curricular`);
    });
};

export const isFormValidBasedOnCustomValidation = (state: State, dispatch: any) => {
    let isFormValid = true;
    isFormValid = isValidBusinessObject(
        state?.data['achievements'],
        [
            { id: 'title', label: 'PROGRAM NAME', manualColumnId: 'otherExam' },
            { id: 'programCategoryCode', label: 'PROGRAM CATEGORY' },
            { id: 'levelCode', label: 'LEVEL' },
            { id: 'achievementDate', label: 'COMPLETION YEAR AND MONTH' },
        ],
        { id: 'title', label: 'title' },
        'achievements',
        dispatch,
        'Achievement'
    );
    return isFormValid;
};

const sendMoEngageEvent = async (user:any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-student-achievements`, {
            userInfo       : user
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return false;
  };