import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

const RedirectToUserSignup = () => {
    const { schoolDomain, invitedBy } = useParams();
    const targetUrl = invitedBy ? `/user/${schoolDomain}/signup/${invitedBy}` : `/user/${schoolDomain}/signup`;

    return <Navigate to={targetUrl} replace />;
};

export default RedirectToUserSignup;
