import React, { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import DebugBarControl from '../../library/SimpleControls/DebugBarControl';
import SessionContext from '../../library/Core/SessionContext';
import QnaApiService from './QnaApiService';
import { GlobalContext } from '../../library/Core/GlobalContext';

const StudentMyQueries: React.FC = () => {
    const { ids } = useParams<{ ids: string }>();
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);
    const { state, dispatch } = useContext(SmartContext);
    const { formConfig, data, internal } = useLoaderData() as State;
    const { globalState } = useContext(GlobalContext);
    const userId = sessionState?.studentId ?? sessionState?.userId;

    useEffect(() => {
        getStudentMyQuery({});
    }, []);

    function getStudentMyQuery(searchVal: any): Promise<any> {
        const params = `?user_id=${userId}`;
        return Promise.all([
            QnaApiService.getStudentMyQuery(params),
        ]).then((response) => {
            const questions = response[0]?.data.data.questions ?? [];
            dispatch({
                type: 'DATA_INIT',
                payload: {
                    formConfig,
                    data: { ...data, studenMyQuery: questions },
                    domain: globalState.domain,
                    internal,
                    flags: { isDataLoading: false }
                },
            });
            return Promise.resolve(state);
        });
    }

    function calculateDaysAgo(dateString: string) {
        const createdDate = new Date(dateString);
        const currentDate = new Date();
        const timeDifference = currentDate.getTime() - createdDate.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
        return daysDifference;
    }

    const formatDate = (isoDateString: string): string => {
        const createdDate = new Date(isoDateString); // Convert ISO string to Date object

        // Format the date as per your requirement
        const formattedDate = `${createdDate.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        })}`;

        return formattedDate;
    };

    const studenMyQuery = state.data?.studenMyQuery || [];
    return (
        <div>
            <div className="post-wrap">
                {studenMyQuery.map((query: any) => (
                    <div className="post-block" key={query.question_id}>
                        <div className="row g-2">
                            <div className="col-12">
                                <div className="row g-2">
                                    <div className="col-sm">
                                        <div className="row g-2">
                                            <div className="col-auto">
                                                <div className="post-head">
                                                    POSTED {calculateDaysAgo(query.created)} DAYS AGO
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <span className="font-14">
                                                {formatDate(query.created)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-auto">
                                        {/* <a href="#" className="btn-gray-edit">
                                            <i className="pencil-edit"></i>
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row g-2">
                                    <div className="col-sm">
                                        <p className="brife">{query.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row g-3">
                                    <div className="col-sm">
                                        <div className="mark-flag">
                                            <input type="checkbox" id={`mark-${query.question_id}`} className="d-none" />
                                            <label htmlFor={`mark-${query.question_id}`}>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-auto d-flex justify-content-end">
                                        <div className="tags-wrap d-flex flex-wrap">
                                            {query.tags.split(', ').map((tag: string) => (
                                                <span key={tag}>{tag}</span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <DebugBarControl />
        </div>
    );
};

export default StudentMyQueries;
