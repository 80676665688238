import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import SessionContext from '../../../library/Core/SessionContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { handleInstituteSelection } from './AdminInstituteSectionActions';
import AdminInstituteSelectionLayout from './AdminInstituteSelectionLayout';
import { addUserSpecificDomains } from '../../../library/Service/domainService';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';

const AdminInstituteSelection = ({pageTitle}:any) => {
    document.title=pageTitle;
    const { globalState, globalDispatch } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const navigate = useNavigate();
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            ROUTER_NAVIGATE: navigate,
        },
    } as any);

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    },[]);

    // useEffect(() => {
    //     if (shouldNavigate) {
    //         const institute = sessionState;
    //         if (institute && Object.keys(institute).length > 1) {
    //             const navigateToUrl: string = navigateToLandingPage(institute, institute.studentId) as string;
    //             navigate(navigateToUrl);
    //             setShouldNavigate(false);
    //         }
    //     }
    // }, [shouldNavigate, navigate, sessionState]);

    const handleSelection = (institute: any) => {
        handleInstituteSelection(institute, data?.id).then(() => {
            setShouldNavigate(true); 
        });
    };

    useEffect(() => {
        if (data?.userInstitutes.length === 1) {  //For only one Institute assign
            handleSelection(data?.userInstitutes[0]);
            setShouldNavigate(false); 
        }
    }, []);
    
    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <AdminInstituteSelectionLayout handleSelection={handleSelection} institutesLength={data?.userInstitutes.length} userData={data}>
            </AdminInstituteSelectionLayout>
            <DebugBarControl />
        </SmartContext.Provider>
    );
};

export default AdminInstituteSelection;
