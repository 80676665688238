import axios from 'axios';

// Create an instance of Axios with custom configurations
const qnaApiService = axios.create({
    baseURL: process.env.REACT_APP_QNA_API, // Set CMS/SFE base URL here
    // headers: {
    //     'Content-Type': 'application/json', // Set custom headers here in case for SFE API in Future
    //     Authorization: 'Bearer YourAccessToken',
    // },
});

// Define a function to handle errors
const errorHandler = (error) => {
    // Handle different types of errors here
    if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error('Response Error:', error.response.data);
    } else if (error.request) {
        // The request was made but no response was received
        console.error('Request Error:', error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error:', error.message);
    }
    return Promise.reject(error); // Reject the Promise to propagate the error further
};

// Add an interceptor to handle errors globally
qnaApiService.interceptors.response.use((response) => response, errorHandler);

export default qnaApiService;
