import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';


interface CollapsedFilters {
    [key: string]: boolean;
}

const AlbumListingSearchControl = () => {
    const { state, dispatch } = useContext(SmartContext);
    const filters = state.data.filters;

    const [filtersConfig] = useState([
        { code: 'userTypeCode', label: 'Uploaded By', domainCategoryCode: 'USER_TYPE_CODE' },
        { code: 'year', label: 'Taken Year', domainCategoryCode: 'PAST_YEAR_CODE' },
        { code: 'tags', label: 'Tags', domainCategoryCode: 'AUTO_GEN_BASED_ON_CODE' },
    ]);

    const [collapsedFilters, setCollapsedFilters] = useState<CollapsedFilters>({});

    const pageDomain = state.data.pageDomain;

    const handleFilterCriteriaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const actionType = event.target.checked ? 'ADD_NEW_RECORD_IN_ARRAY' : 'DELETE_RECORD_IN_SIMPLE_ARRAY';
        dispatch({
            type: actionType,
            payload: { dataKey: `filters.${event.target.id}`, name: event.target.id, value: event.target.value, errorMessages: [] },
        });
    };

    useEffect(() => {
        let filteredData: any = state.data.albums;
        Object.keys(filters).map((key) => {
            if (filters[key].length === 0) return;
            filteredData = filteredData.filter((item: any) => {
                if (key === 'tags') {
                    const itemTags = item[key]?.split(',').map((tag: string) => tag?.trim());
                    return filters[key].some((tag: string) => itemTags?.includes(tag));
                } else if ( key == 'year' ) {
                    const year = new Date(item[key]).getFullYear();
                    return filters[key].some((element: any) => element == year);                
                // handle year search here;
                } else {
                    return filters[key].some((element: any) => element == item[key]);
                }
            });
        });

        dispatch({ type: 'SET_FILTERED_DATA', payload: filteredData });
    }, [state.data.filters]);

    const [isOpen, setOpen] = useState(false);

    const handleFilterToggle = () => {
        setOpen(!isOpen);
    };

    const toggleCollapse = (filterCode:any) => {
        setCollapsedFilters({
            ...collapsedFilters,
            [filterCode]: !collapsedFilters[filterCode]
        });
    };

    return (
        <div className="col-md-4 ji_filtersec ps-1">
             <div className="mobileFilter d-flex justify-content-between align-items-center d-md-none mobileonly text-end">
                    <h3 className="font-18 font-500 mb-0" id="MobHeadblock">
                    {state?.data?.filteredData.length} Memories found
                    </h3>
                    <button
                        className="btn-filter btn-unscroll font-18 font-500"
                        type="button"
                        onClick={handleFilterToggle}
                        aria-expanded={isOpen ? 'true' : 'false'}
                        aria-controls="filter_collapse">
                        <span className="icon-filter"></span>Filters
                    </button>
                </div>
            {/* <div className="filters collapse show" id="filter_collapse"> */}
            <div className={`filters collapse ${isOpen ? '' : 'show'}`} id="filter_collapse">
            <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <button
                            className="btn-filter btn-unscroll mobileonly"
                            type="button"
                            // data-bs-toggle="collapse"
                            // data-bs-target="#filter_collapse"
                            onClick={handleFilterToggle}
                            aria-expanded={isOpen ? 'true' : 'false'}
                            aria-controls="filter_collapse">
                            Filters
                        </button>
                        <h3 className="font-18 font-500 mb-2 mt-2 aos-init aos-animate" data-aos="fade-left" data-aos-delay="1200">
                            <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/filter_icon.jpg`} />
                            Filters
                        </h3>
                    </div>
                    <a href="" className="font-14 font-500 text-burgandi text-decoration-underline">
                        Clear
                    </a>
                </div>

                <div className="filterGroup" id="accordionFilters">
                    {filtersConfig?.map((filterConfig) => (
                        <div key={filterConfig.code} className="fiterItem mb-3">
                            <div className="filterHeader">
                                <div className="d-flex justify-content-between align-items-center position-relative">
                                    <button
                                        className={`btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center ${collapsedFilters[filterConfig.code] ? 'collapsed' : ''}`}
                                        type="button"
                                        // data-bs-toggle="collapse"
                                        // data-bs-target={`#collapse${filterConfig.code}`}
                                        // aria-expanded="false"
                                        // aria-controls={`collapse${filterConfig.code}`}>
                                        // <span className="font-18 font-600">{filterConfig.label}</span>
                                        // <span className="toggle"></span>
                                        onClick={() => toggleCollapse(filterConfig.code)} 
                                        aria-expanded={collapsedFilters[filterConfig.code]} 
                                        aria-controls={`collapse${filterConfig.code}`}>
                                        <span className="font-18 font-600">{filterConfig.label}</span>
                                        <span className="toggle"></span>
                                    </button>
                                </div>
                            </div>
                            
                            <div className={`collapse mt-2 ${collapsedFilters[filterConfig.code] ? '' : 'show'}`} id={`collapse${filterConfig.code}`}>
                                <div className="card card-body">
                                    <div className="overflow-hidden">
                                        <div className="scrollMe">
                                            {pageDomain[filterConfig.code]?.map((filter: any) => (
                                                <div key={filter.code} className="form-check d-flex flex-wrap">
                                                    <input
                                                        className="form-check-input me-2"
                                                        type="checkbox"
                                                        value={filter.code ?? ''}
                                                        id={filterConfig.code}
                                                        name={filterConfig.code}
                                                        onChange={handleFilterCriteriaChange}
                                                    />
                                                    <label
                                                        className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                        htmlFor={filter.code}>
                                                        {getDomainValueForCode(filter.code, filterConfig.domainCategoryCode, state)}{' '}
                                                        <span>({filter.count})</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AlbumListingSearchControl;
