import { Dropdown,Tooltip } from 'bootstrap';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import useSmartState from '../../common/hooks/useSmartState';
import { SmartContext } from '../../library/Core/SmartContext';
import { getDomainValueForCode } from '../../library/Core/SmartFunctions';
import { renderToString } from 'react-dom/server';
import ActionSettings from '../../library/SimpleControls/ActionSettings';
import { REPORT_CONFIG } from '../admin/reports/report-config';

const BATCH_SIZE = 10;
const DEFAULT_SORT_COLUMN = 'createdAt';
const DEFAULT_SORT_DIRECTION = 'desc';

const ProgramGrid = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const [sortedStudents, setSortedStudents] = useState<string[]>([]);
    const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
    const [bulkAction, setBulkAction] = useState<string>('' as any);
    const [visibleStudents, setVisibleStudents] = useState(state.data.alumniList.slice(0, 10));
    const loadMoreRef: any = useRef();
    const { gridColumns, actions } = state.data.reportConfig;
    const dropdownRef = useRef(null);
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);
    const allRows = [...state.data.alumniList] ?? [];
    const config = REPORT_CONFIG[state.routeInfo.pageName];

    useEffect(() => {
        console.log(`useEffect 1`);
        if (dropdownRef.current) {
            new (Dropdown as any)(dropdownRef.current);
        }
    }, []);

    useEffect(() => {
        console.log(`useEffect 2`);
        const sorted = [...allRows].sort(compareFunction);
        setSortedStudents(sorted);
    }, [state.data.alumniList, sortColumn, sortDirection]);

    useEffect(() => {
        console.log(`useEffect 3`);
        setVisibleStudents(sortedStudents.slice(0, BATCH_SIZE));
    }, [sortedStudents]);

    useEffect(() => {
        console.log(`useEffect 4`);
        const loadMoreObserver = new IntersectionObserver((entries) => {
            // Check if all students are already visible
            if (sortedStudents.length <= visibleStudents.length) {
                if (loadMoreRef.current) {
                    loadMoreObserver.unobserve(loadMoreRef.current); // Stop observing
                }
                return;
            }

            if (entries[0].isIntersecting) {
                const nextBatch = sortedStudents.slice(0, visibleStudents.length + BATCH_SIZE);
                setVisibleStudents([...nextBatch]);
            }
        });

        if (loadMoreRef.current) loadMoreObserver.observe(loadMoreRef.current);
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });

        return () => {
            if (loadMoreRef.current) {
                loadMoreObserver.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, visibleStudents]);


    // Utility to determine if the column is a string type.
    const isStringColumn = typeof visibleStudents[0]?.[sortColumn] === 'string';

    const compareFunction = (a: any, b: any) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (isStringColumn) {
            return sortDirection === 'asc'
                ? aValue?.localeCompare(bValue, undefined, { sensitivity: 'accent' })
                : bValue?.localeCompare(aValue, undefined, { sensitivity: 'accent' });
        } else {
            return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
        }
    };

    const institute_tool = (row: any) => {
        const mainarr = row.instituteId;
        const instituteIdArray = Array.isArray(mainarr) ? mainarr : [mainarr];
    
        const firstTwoItems = instituteIdArray.slice(0, 2);
        const remainingItems = instituteIdArray.slice(2);
    
        const firstTwoItemsHtml = firstTwoItems.map((item: any, index: any) => {
            return (
                <li style={{ listStyleType: 'none' }} key={index}>{getDomainValueForCode(item, 'INSTITUTE_DOMAIN', state) ?? ''}</li>
            );
        });
    
        const listItems = remainingItems.map((item: any, index: any) => {
            return (
                <li key={index}>{getDomainValueForCode(item, 'INSTITUTE_DOMAIN', state) ?? ''}</li>
            );
        });
    
        let secondSpan = null;
        if (listItems.length > 0) {
            const tooltipContent = renderToString(<ol>{listItems}</ol>);
            secondSpan = (
                <span>
                    <a
                        className="btn-html"
                        href="javascript:;"
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        data-bs-original-title={tooltipContent}>
                        &amp; {listItems.length} More
                    </a>
                </span>
            );
        }
    
        const institute = (
            <span>
                {firstTwoItemsHtml}
                {secondSpan}
            </span>
        );
    
        return institute;
    };

    const subdepart_tool = (row: any) => {
        if (row.programSubDepartment === null) {
            return <span>{getDomainValueForCode(row.programDepartment, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state) ?? ''}</span>;
        }
        const mainarr = (row.programSubDepartment && row.programSubDepartment.length > 0) ? row.programSubDepartment.split(',') : row.programSubDepartment;
        const SubDepartmentArray = Array.isArray(mainarr) ? mainarr : [mainarr];
    
        const firstTwoItemsHtml = () => (
            <li style={{ listStyleType: 'none' }}>
                {getDomainValueForCode(row.programDepartment, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state) ?? ''}
            </li>
        );
    
        const listItems = SubDepartmentArray.map((item: any, index: any) => {
            return (
                <li key={index}>{getDomainValueForCode(item, 'FIELD_OF_STUDY_TYPE_CODE', state) ?? ''}</li>
            );
        });
    
        let secondSpan = null;
        if (listItems.length > 0) {
            const tooltipContent = renderToString(<ol>{listItems}</ol>);
            secondSpan = (
                <span>
                    <a
                        className="btn-html"
                        href="javascript:;"
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        data-bs-original-title={tooltipContent}>
                        {listItems.length} Specializations
                    </a>
                </span>
            );
        }
    
        const institute = (
            <span>
                {firstTwoItemsHtml()} 
                {secondSpan}
            </span>
        );
    
        return institute;
    };

    const getActionNode = (row: any) => {
        const actions = config['ROW_ACTION'];
        return actions.length > 1 ? (
            <ActionSettings id={row?.uuid} actions={actions} onClick={handleAction} />
        ) : (
            <a className="btn-update" href={config['UPDATE_ACTION_LINK'](row.id)}  rel="">
                Update
            </a>
        );
    };

    const handleAction = (uuid: string, action: string) => {
       //handle if multiple
    };
    

    return (
        <> 
         <div>
            <div className="ActiveListings_present">
                <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks my-2">
                            <div className="d-flex justify-content-start align-items-center customselect_parnt">
                            </div>
                    </div>
                    <div className="d-flex flex-wrap align-items-center justify-content-end">
                        <h3 className="font-14 font-500 mb-0 me-2">Total {allRows.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} records</h3>
                    </div>
                </div>
                <div className="table-responsive attendee_dtl">
                    <table className="table" style={{ minWidth: '1042px' }}>
                        <thead>
                            <tr>
                                <th>Program Details</th>
                                <th>Campus</th>
                                <th>Department</th>
                                <th>Duration</th>
                                <th>More Info</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {visibleStudents.map((row: any) => (
                            <tr key={row.id}>
                                <td>
                                    <div className="d-flex flex-wrap">
                                         <div className="name d-flex flex-column line-height-18 flex-1">
                                            <a href="javascript:;" className="font-16 font-500">{row.name}</a>
                                            <span><span>Type</span> - <b className="font-600">{getDomainValueForCode(row.programType, 'PROGRAM_TYPE', state) ?? ''}</b></span>
                                            <span><span>Mode</span> - <b className="font-600">{row.programDeliveryMode}</b></span>
                                        </div>
                                    </div>
                                </td>
                    
                                <td>{row.instituteId != null ? 
                                <div className="d-flex flex-column">{institute_tool(row)}</div> : 
                                <div>-</div>}</td>

                                <td>  <div className="d-flex flex-column">{subdepart_tool(row)}</div></td>
                                <td>
                                <div className="name d-flex flex-column line-height-18 flex-1">
                                    <span className="font-500">{row.progDurationDesc} {row.progDuration}</span>
                                    <span className="d-flex flex-column">
                                    <span>Intake -</span>
                                    <b className="font-500">
                                        {row.intakeFreqDesc && row.intakeFreqId ? (
                                        `${row.intakeFreqDesc} (${getDomainValueForCode(row.intakeFreqId, 'PROGRAM_INTAKE_FREQUENCY', state) ?? ''})`
                                        ) : (
                                        'NA'
                                        )}
                                    </b>
                                    </span>                                   
                                     </div>
                                </td>
                                <td>
                                    <div className="name d-flex flex-column line-height-18 flex-1">
                                        <span><span>Exam</span> - <b className="font-600">  {row.isExamConducted ? "YES" : "NO"}</b></span>
                                        <span><span>Eligibility</span> - <b className="font-600">
                                             {(row.eligibilityCriteriaId !== null && row.eligibilityCriteriaId !== 0) ?
                                            (getDomainValueForCode(row.eligibilityCriteriaId, 'PROGRAM_ELIGIBILITY', state) ?? '') :
                                            ''}
                                            </b></span>
                                    </div>
                                </td>
                                <td>{getActionNode(row)}</td>
                                
                            </tr>
                          ))}
                        <tr ref={loadMoreRef} />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
        );
    };
    
export default ProgramGrid;
