import { useContext, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../library/Core/GlobalContext';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import { SmartContext } from '../../library/Core/SmartContext';
import DebugBarControl from '../../library/SimpleControls/DebugBarControl';
import Layout from './Layout';
import ProgramGrid from './ProgramGrid';
import SessionContext from '../../library/Core/SessionContext';
import useManagementDashboard from '../management/dashboard/useManagementDashboard';
import { getinstituteList } from '../../library/Service/domainService';

const ProgramListing = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState } = useContext(SessionContext);
    console.log("sessionState",sessionState)
    const { getCampuses } = useManagementDashboard();

    const campuses = getCampuses();
    const [instituteList, setinstituteList] = useState(getinstituteList('INSTITUTE_GROUP_CODE', campuses));


    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, internal, routeInfo },
        });

        dispatch({
            type: 'SET_DOMAIN',
            payload: { key: 'INSTITUTE_GROUP_CODE', value: instituteList },
        });

    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
        <DebugBarControl />
        {!state.flags.isDataLoading && (
            <Layout>
                {/* Please check layout for the <SearchCriteria /> */}
                <ProgramGrid />
            </Layout>
        )}
    </SmartContext.Provider>
    );
};

export default ProgramListing;
