import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { canEditProfile, isSchoolAdmin } from '../../../../library/Core/SmartFunctions';
import SessionContext from '../../../../library/Core/SessionContext';

const ProfileHeader = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    const model = state.internal.model;
    const navigate = useNavigate();
    const genderCode = state?.data?.profile?.genderCode ?? 'MALE';
    const programName = state?.data?.programName ?? '';
    const userInstituteName = state?.data?.userInstituteName;
    let profileImage = model?.profileImage;
    if (profileImage == '' || profileImage == null) {
        profileImage =
            genderCode == 'MALE'
                ? `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student-boy.svg`
                : `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student.svg`;
    }

    const handleSendMessage = (event: any, id: string) => {
        event.preventDefault();
        // state.actions['ROUTER_NAVIGATE'](`/messages/send-message/${id}`);
        navigate(`/messages/send-message/${id}`);
    };

    return (
        <section className="bg-white container-fluid alumni_profile_brife_wrap" id="alumni_profile_brife_wrap">
            <div className="max-1140 mx-auto alumni_profile_brife">
                <div className="row">
                    <div className="col-12 mb-4">
                        {/* <a className="btn-back" href={`/student/${model.id}/info`}>
                            <i className="icon-left-arrow font-10"></i>
                            <span className="ms-1">Back to Directory</span>
                        </a> */}
                    </div>
                    <div className="col-12">
                        <div className="alumni_profile_name_college_other_detail_wrap student_profile">
                            <div className="alumni_profile_image_name_other_detail">
                                {/* {canEditProfile(model.id, sessionState) && (
                                    <a
                                        role="button"
                                        className="edit-profile-image position-relative overflow-hidden"
                                        onClick={() => navigate(`/alumni/${model.id}/profile-picture`)}
                                        title="Update Profile Picture">
                                        <div className="alumni_profile_image">
                                            <img src={`${profileImage}`} alt="" />
                                        </div>
                                        <span className="imgUpload">
                                            Upload <i className="icon icon-upload"></i>
                                        </span>
                                    </a>
                                )} */}
                                {/* {!canEditProfile(model.id, sessionState) && (
                                    <div className="alumni_profile_image">
                                        <img src={`${profileImage}`} alt="" />
                                    </div>
                                )} */}
                                 <div className="alumni_profile_image_wrap">
                                        <div className="alumni_profile_image">
                                            <img src={profileImage} alt="" />
                                        </div>
                                        {(canEditProfile(model.id, sessionState) || isSchoolAdmin()) && (
                                        <a className="pencil-edit" href="javascript:;" onClick={() => navigate(`/alumni/${model.id}/profile-picture`)}></a>
                                        )}
                                    </div>
                                <div className="alumni_profile_name_other_detail">
                                    <h2 className="mb-0">
                                        {model.firstName} {model.lastName}
                                    </h2>
                                    <p className="font-14 mb-3">{`Program: ${programName}, ${model?.schoolName ?? userInstituteName}`}</p>
                                    <div className="student_profile_details">
                                        <div className="alumni_profile_class_college">
                                            <div className="alumni_profile_college_phone mb-1">
                                                <span className="font-16 font-500 text-black line-height-18">{`${model.mobileCountryCode} - ${model.mobileNumber}`}</span>
                                            </div>
                                            <div className="alumni_profile_college_phone">
                                                <span className="font-16 font-500 text-black line-height-18">
                                                    {model.registeredEmailId}
                                                </span>
                                            </div>
                                            {isSchoolAdmin() && (
                                            <div className="send_link"  style={{ marginTop: '10px' }} >
                                                <a
                                                    role="button"
                                                    onClick={(event) => handleSendMessage(event, model.id)}
                                                    className="btn-send">
                                                    <i className="icon-alumni-send"></i>
                                                    <span>Send</span>
                                                </a>
                                            </div>
                                        )}
                                        </div>
                                        
                                        <ul className="school_other_details">
                                            {/* <li>
                                                <span>Board</span>
                                                <b>{model.curriculum}</b>
                                            </li> */}
                                            {/* <li>
                                                <span>Citizenship</span>
                                                {model.primaryCitizenship == '' && <b>TBA</b>}
                                                <b>{model.primaryCitizenship}</b>
                                            </li> */}
                                            <li>
                                                <span>Current Location</span>
                                                {model.residingCity == '' && model.residingCountry == '' && <b>TBA</b>}
                                                <b>
                                                    {model.residingCity ? `${model.residingCity}, ` : ``}
                                                    {model.residingCountry}
                                                </b>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {(canEditProfile(model.id, sessionState) || isSchoolAdmin()) && (
                                <a className="pencil-edit" role="button" onClick={() => navigate(`/alumni/${model.id}/info`)}></a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProfileHeader;
