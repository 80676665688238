import { useContext, useEffect, useState } from 'react';
import useSmartState from '../../../common/hooks/useSmartState';
import FormBuilder from '../../../library/Builders/FormBuilder';
import { SmartContext } from '../../../library/Core/SmartContext';
import { FormSection } from '../../../library/Core/SmartTypes';
import AlumniSchoolSupportService from './AlumniSchoolSupportService';
import FiltersApplied from '../../../library/SimpleControls/FiltersApplied';
import { getFuzzySearchCriteria, isEmpty } from '../../../library/Core/SmartFunctions';

const SearchCriteria = () => {
    const { state, dispatch } = useContext(SmartContext);
    const searchFormConfig = state.formConfig?.sectionRepository[0] as FormSection;
    const { setStateValue } = useSmartState();
    const [isExpanded, setIsExpanded] = useState(true);
    const [fuzzySearchText, setFuzzySearchText] = useState('');

    const handleClearAll = () => {
        var defaultSearchCriteria = AlumniSchoolSupportService.getDefaultSearchCriteria(0, 0, 0,'');
        defaultSearchCriteria.programLevel = [];
        defaultSearchCriteria.passOutYear = [];
        defaultSearchCriteria.schoolGroupId = [];
        defaultSearchCriteria.schoolId = [];
        defaultSearchCriteria.searchBy = [];

        var buttons = document.querySelectorAll('.row.g-3 button');
        buttons.forEach(function (button) {
            button.textContent = '';
        });
        document.querySelectorAll('.css-1dimb5e-singleValue').forEach(element => {
            element.textContent = ''; 
        }); //universityId text not removed after clear
        setStateValue('searchCriteria', defaultSearchCriteria);
    };

    const getBatchYearDropdown = () => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 16 }, (v, i) => {
            return {
                category_code: 'ALUMNI_MATCH_PASSOUT_YEAR',
                code: currentYear + i,
                value: currentYear + i,
                parent_code: null,
                display_order: null,
                flex_col: null
            };
        });
        return years;
    };

    useEffect(() => {
        AlumniSchoolSupportService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
            setStateValue('studentList', response.data);
        });
        fetchStudentCount(state)

        // const fetchData = async () => {
        //     await AlumniSchoolSupportService.getBatchYearDropdown(state.data.searchCriteria).then((response: any) => {
        //         dispatch({
        //             type: 'SET_DOMAIN',
        //             payload: { key: 'ALUMNI_MATCH_PASSOUT_YEAR', value: response?.data },
        //         });
        //     });
        // };
        const fetchData = async () => {
            const years = getBatchYearDropdown();
            dispatch({
                type: 'SET_DOMAIN',
                payload: { key: 'ALUMNI_MATCH_PASSOUT_YEAR', value: years },
            });
        };

        fetchData();
    }, [state.data.searchCriteria]);

    const handleFuzzySearch = (event: any) => {
        event.preventDefault();
        if (isEmpty(fuzzySearchText)) return;

        AlumniSchoolSupportService.search(getFuzzySearchCriteria(fuzzySearchText), state.routeInfo.pageName).then((response: any) => {
            setStateValue('studentList', response.data);
            const fetchAlumniCount = async () => {
                try {
                    const studentData = await AlumniSchoolSupportService.searchStudentCount(getFuzzySearchCriteria(fuzzySearchText), state.routeInfo.pageName);
                                    
                    dispatch({
                        type: 'SET_INTERNAL_STATE',
                        payload: { key:"studentCount", value:studentData?.data },
                    });
                } catch (error) {
                    console.error("Error fetching alumni count:", error);
                }
            };
        
            fetchAlumniCount();
        });
    };

    const fetchStudentCount = async (state:any) => {
        try {
            const studentDataCount = await AlumniSchoolSupportService.searchStudentCount(state.data.searchCriteria, state.routeInfo.pageName);
            await new Promise(resolve => setTimeout(resolve, 200));
            dispatch({
                type: 'SET_INTERNAL_STATE',
                payload: { key:"studentCount", value:studentDataCount?.data },
            });
        } catch (error) {
            console.error("Error fetching alumni count:", error);
        }
    }

    return (
        <>
            <div className="d-flex flex-wrap justify-content-between align-items-start align-items-md-center mb-2 filterSearch">
                <div className="d-flex flex-wrap flex-1 mb-0 justify-content-start">
                    <button className="btn-grpfiter me-3" onClick={() => setIsExpanded(!isExpanded)}>
                        <i className="icon-filter font-13 me-2"></i>
                        Filters
                    </button>
                    <div className="search alumniSearch m-0 open position-static flex-1 mb-2">
                        <div className="form-inline d-flex flex-wrap justify-content-between d-block">
                            <input
                                value={fuzzySearchText}
                                placeholder="Search by Student Name, Email, mobile number"
                                aria-label="Search"
                                className="form-control flex-1 font-13 font-500 text-truncate"
                                onChange={(event) => setFuzzySearchText(event?.target.value)}
                            />
                            <button className="btn btn-search icon icon-search" onClick={handleFuzzySearch}></button>
                        </div>
                        <a href="" className="btn-search-toggle icon-search"></a>
                    </div>
                    {isExpanded && (
                        <div className="col-12 fitergroup p-3 bg-body-secondary">
                            <FormBuilder key="searchCriteria" section={searchFormConfig} dataKey="searchCriteria" />
                        </div>
                    )}
                </div>
            </div>

            <div className="filterresult">
                <FiltersApplied dataKey="searchCriteria" onClearAll={handleClearAll} />
            </div>
        </>
    );
};
export default SearchCriteria;
