import moment from 'moment';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { canEditProfile, getDomainValueForCode, isEmpty } from '../../../../library/Core/SmartFunctions';
import DebugBarControl from '../../../../library/SimpleControls/DebugBarControl';
import SessionContext from '../../../../library/Core/SessionContext';

const ExtraCurricular = () => {
    const { state, dispatch } = useContext(SmartContext);
    const model = state.internal.model;
    const { id, pageName } = useParams();
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);
    const [isAchievementsCollapsed, setAchievementsIsCollapsed] = useState(true); 
    const [isBooksCollapsed, setBooksIsCollapsed] = useState(true); 
    const [isActivitiesCollapsed, setActivitiesIsCollapsed] = useState(true); 


    if ( sessionState?.userTypeCode === 'STUDENT' && sessionState?.profileStatusCode === 'PREFERENCES_INCOMPLETE' ) {
        window.location.href = `/alumni/${sessionState?.id}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    return (
        // <div className="tab-pane fade" id="pills-extraCurriculars" role="tabpanel" aria-labelledby="pills-extraCurriculars-tab">
        <div>
            <div className="extraCurriculars-list">
                <div className="tab-white-block mb-2 py-4">
                    {canEditProfile(model.id, sessionState) && (
                        <a className="pencil-edit" role="button" onClick={() => navigate(`/alumni/${id}/profile-picture`)}></a>
                    )}
                    <div className="row g-4">
                        <div className="col-md-12">
                            <div className="customRow-header align-items-start">
                                <div className="icon icon-exam-note"></div>
                                <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                                    <span className="font-20 font-600">About Me</span>
                                    <span className="font-16 text-black">{`${state?.data?.profile?.professionalHeadline ?? '-- --'}`}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="curriculars" id="accordionExample">
                    <div className="curriculars-item">
                        <div className="curriculars-header" id="headingTwo">
                        <div
                                className="customRow-header collapsed"
                                onClick={() => setAchievementsIsCollapsed(!isAchievementsCollapsed)} // Toggle collapse state
                                aria-expanded={!isAchievementsCollapsed}
                                aria-controls="collapseTwo"
                                    >
                                <div className="icon icon-achievements"></div>
                                <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                                    <span className="font-20 font-500">Achievements</span>
                                </div>
                                <i></i>
                            </div>
                            {canEditProfile(model.id, sessionState) && (
                                <a className="pencil-edit" role="button" onClick={() => navigate(`/student/${id}/achievements`)}></a>
                            )}
                        </div>

                        <div
                                id="collapseTwo"
                                className={`curriculars-collapse collapse ${isAchievementsCollapsed ? '' : 'show'}`} // Show collapse content based on state
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample"
                            >
                            <div className="curriculars-body">
                                <div className="customRow-detail">
                                    <div className="row g-3">
                                        <div className="col-md-12">
                                            <div className="xscroll">
                                                <table className="curriculars_table" style={{ minWidth: '1020px' }}>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                <div>Activity Title</div>
                                                            </th>
                                                            <th scope="col" style={{ width: '350px' }}>
                                                                <div>Category</div>
                                                            </th>
                                                            <th scope="col" style={{ width: '200px' }}>
                                                                <div>Level</div>
                                                            </th>
                                                            <th scope="col" style={{ width: '180px' }}>
                                                                <div>Completed In</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {model.achievements.map((item: any, index: number) => (
                                                            <tr key={item.id}>
                                                                <th scope="row">{item.title}</th>
                                                                <td>
                                                                    {getDomainValueForCode(
                                                                        item.programCategoryCode,
                                                                        'ACHIEVEMENT_CATEGORY_CODE',
                                                                        state
                                                                    )}
                                                                </td>
                                                                <td>{item.level}</td>
                                                                <td>{isEmpty(item.achievementDate) ? item.achievementDate : moment(item.achievementDate).format('MMM-YYYY')}</td>
                                                            </tr>
                                                        ))}
                                                        {model.achievements.length === 0 && (
                                                            <tr>
                                                                <td>No Achievements found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="curriculars-item">
                        <div className="curriculars-header" id="headingThree">
                        <div
                                className="customRow-header collapsed"
                                onClick={() => setBooksIsCollapsed(!isBooksCollapsed)} // Toggle collapse state
                                aria-expanded={!isBooksCollapsed}
                                aria-controls="collapseTwo"
                                >
                                <div className="icon icon-books"></div>
                                <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                                    <span className="font-20 font-500">Books</span>
                                </div>
                                <i></i>
                            </div>
                            {canEditProfile(model.id, sessionState) && (
                                <a className="pencil-edit" role="button" onClick={() => navigate(`/alumni/${id}/books`)}></a>
                            )}
                        </div>

                        <div
                                id="collapseTwo"
                                className={`curriculars-collapse collapse ${isBooksCollapsed ? '' : 'show'}`} // Show collapse content based on state
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample"
                            >
                            <div className="curriculars-body">
                                <div className="customRow-detail">
                                    <div className="row g-3">
                                        <div className="col-md-12">
                                            <div className="xscroll">
                                                <table className="curriculars_table" style={{ minWidth: '1020px' }}>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" style={{ width: '550px' }}>
                                                                <div>Book Title</div>
                                                            </th>
                                                            <th scope="col">
                                                                <div>Author</div>
                                                            </th>
                                                            <th scope="col" style={{ width: '180px' }}>
                                                                <div>Completed In</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {model.books.map((item: any, index: number) => (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    <div className="book-wrap">
                                                                        <div className="book-img">
                                                                            <img src="images/book_1.jpg" alt="" width="64" />
                                                                        </div>
                                                                        <div className="book-detial">
                                                                            <h4 className="font-18 font-500">{item.title}</h4>
                                                                            <p>{item.description}</p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className="font-18 font-500">{item.author}</span>
                                                                </td>
                                                                <td>{moment(item.completionDate).format('MMM-YYYY')}</td>
                                                            </tr>
                                                        ))}
                                                        {model.books.length === 0 && (
                                                            <tr>
                                                                <td>No Books found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="curriculars-item">
                        <div className="curriculars-header" id="headingFour">
                            <div
                                className="customRow-header collapsed"
                                onClick={() => setActivitiesIsCollapsed(!isActivitiesCollapsed)} // Toggle collapse state
                                aria-expanded={!isActivitiesCollapsed}
                                aria-controls="collapseTwo"
                                >
                                <div className="icon icon-activities"></div>
                                <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                                    <span className="font-20 font-500">Activities</span>
                                </div>
                                <i></i>
                            </div>
                            {canEditProfile(model.id, sessionState) && (
                                <a className="pencil-edit" role="button" onClick={() => navigate(`/alumni/${id}/activity`)}></a>
                            )}
                        </div>

                        <div
                                id="collapseTwo"
                                className={`curriculars-collapse collapse ${isActivitiesCollapsed ? '' : 'show'}`} // Show collapse content based on state
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample"
                            >
                            <div className="curriculars-body">
                                <div className="customRow-detail">
                                    <div className="row g-3">
                                        <div className="col-md-12">
                                            <div className="xscroll">
                                                <table className="curriculars_table" style={{ minWidth: '1020px' }}>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                <div>Activity Title</div>
                                                            </th>
                                                            <th scope="col-4" style={{ width: '180px' }}>
                                                                <div>Completed In</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {model.activities.map((item: any, index: number) => (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    <div className="font-18 font-500">{item.title}</div>
                                                                    <div className="font-16">{item.description}</div>
                                                                </td>
                                                                <td>{moment(item.completionDate).format('MMM-YYYY')}</td>
                                                            </tr>
                                                        ))}
                                                        {model.activities.length === 0 && (
                                                            <tr>
                                                                <td>No Activities found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DebugBarControl />
        </div>
    );
};

export default ExtraCurricular;
