import React, { useState, useEffect, useContext, useRef } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { getControlValueFromState, handleControlValueChange } from '../../library/Core/SmartFunctions';
import { axiosClient } from '../../library/Service/axiosClient';
import { useLocation } from 'react-router-dom';
import useManagementDashboard from '../management/dashboard/useManagementDashboard';
import { getinstituteList } from '../../library/Service/domainService'
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';
import { SmartContext } from '../../library/Core/SmartContext';
import { useNavigate } from 'react-router';
import uploadImg from '../../styles/images/upload.svg';
import { SimpleFormControlArguments, State } from '../../library/Core/SmartTypes'
import ImgCloudComputing from '../../styles/images/cloud-computing.svg';
import ReactQuill from 'react-quill';


const editorStyle = {
    // Adjust the height as needed
};

const SocialPost  = (args: SimpleFormControlArguments) => {
//variable initialization
interface Account {
    access_token: string;
    name: string;
    id: string;
  }
const { state, dispatch } = useContext(SmartContext);
const { control, dataKey, parentDataKey } = { ...args };
const data = getControlValueFromState(dataKey, state as State);
const [image, setImage] = useState(data);
// Onsubmit category based 

const location = useLocation(); // Get the current location, including state
const submittedData = location.state; // Retrieve the passed data

const [SchoolLogo, setSchoolLogo] = useState('');
const [postText, setPostText] = useState<string>('');
    const [attachments, setAttachments] = useState<File[]>([]);
    const [attached_file_path1, setAttachedFilePath1] = useState<string>(''); // Define attached_file_path state
    const [message, setMessage] = useState<string>(''); // State to hold rich text content
    const [attach_file, setAttach_file] = useState<string>(''); // State to hold rich text content
    const [error, setError] = useState<string | null>(null); // Add error state


const fetchSharedData = async () => {
    try {

        if (!submittedData) {
            console.log('No data received');
        }else{
            console.log(submittedData['category'], 'category');
            console.log(submittedData['studentID'], 'studentID');

            let response = await axiosClient().get(
                `${process.env.REACT_APP_LEAD_SERVER}/userinfo/getuserinfo/${submittedData['studentID']}`
            );

            //(await axiosClient().get(`${process.env.REACT_APP_LEAD_SERVER}/marketevent/getaudienceinfo/${eventId}`))?.data; 
            console.log(response?.data, 'response');
            console.log(response?.data.userinfo, 'userinfo');
            console.log(response?.data.pginfo, 'pginfo');
            console.log(response?.data.uginfo, 'uginfo');
            console.log(response?.data.achievement, 'achievement');


            const alumniName = response?.data.userinfo.first_name ? response?.data.userinfo.first_name : '';

            const alumnipic = response?.data.userinfo.profile_picture_file_path ? response?.data.userinfo.profile_picture_file_path : '';
            const programName = response?.data.userinfo.display_name ? response?.data.userinfo.display_name : '';
            const batch = response?.data.userinfo.passOutYear ? response?.data.userinfo.passOutYear : '';
            let AchiDetail = ''
            if (!response?.data.achievement || response?.data.achievement === '') {
                AchiDetail = ''; // If there's no achievement, set to empty
            } else {
                // If achievement exists, get the title or set to empty if undefined
                AchiDetail = response?.data.achievement.title || ''; 
            }
            const instituteName = response?.data.userinfo.institutename ? response?.data.userinfo.institutename : '';
            let universityName = '';
            if(response?.data.pginfo === 'undefined' || response?.data.pginfo === ''){
                universityName = response?.data.pginfo.display_name ? response?.data.pginfo.display_name : '';
            } else {
                universityName = response?.data.uginfo.display_name ? response?.data.uginfo.display_name : '';
            }
            
            const currentCompanyName = '';

            if(submittedData['category'] === '1' ){

               // setSubject(`Check out the achievement of our alumni ${alumniName}!`);
                setPostText(`We\'re thrilled to highlight the accomplishments of ${alumniName} from the ${programName} Batch of ${batch}. In their recent achievement of ${AchiDetail}, they made the institute proud. ${alumniName}, we miss your time at the institute! <p> It\'s moments like these that truly make the legacy of ${instituteName} shine with pride. <p> #ProudMoment #AlumniAchievements #AlumniImpactProgram #StudentAlumniNetwork #AlumniNetwork `);
                let link = alumnipic;
                console.log(link, " LINK ")
                setImage(link);
                console.log(image, " image data ")
                
                
                // setAttach_file(alumnipic); // Extract attached_file_path
                // setAttachedFilePath1(alumnipic);
            }else if(submittedData['category'] === '2' ){
                //setSubject(`Check out the career journey of our alumni ${alumniName}!`);
                setPostText(`Introducing ${alumniName}, our exceptional alumni from the ${programName} Batch of ${batch}! Started the journey from our institute\'s halls to excelling at the ${universityName} and has now reached remarkable heights at ${currentCompanyName}. Their success story inspires us all. <p> ${alumniName}, we take immense pride in having such remarkable alumni. All the best for your future Endeavors! <p> #AlumniSuccessStories #AlumniImpactProgram #StudentAlumniNetwork #AlumniNetwork `);
                let link = alumnipic;
                console.log(link, " LINK ")
                setImage(link);
                console.log(image, " image data ")
                

            }else if(submittedData['category'] === '3' ){
                //setSubject(`Check out our alumni ${alumniName} from the prestigious ${universityName}!`);
                setPostText(`Meet ${alumniName} from the ${programName} Batch of ${batch} who is now a student of ${universityName}. We are always proud to see our alumni succeed in their personal and professional lives. We remember ${alumniName}\'s time at institute and couldn\'t be more proud! <p> ${alumniName}, we can\'t wait to see what you\'ll do next! All the best for your future endeavors. <p> #PrestigiousAlumni #AlumniImpactProgram #StudentAlumniNetwork #AlumniNetwork `);
                let link = alumnipic;
                console.log(link, " LINK ")
                setImage(link);
                console.log(image, " image data ")
                

            }else{


            }
    
        }

    } catch (error) {
        console.error('Error fetching template data:', error);
    }
};

useEffect(() => {
    console.log(submittedData, 'submittedData')
    // Fetch template data when the component mounts
    if (submittedData !== null) {
        fetchSharedData();
    }
}, []); // The empty dependency array ensures this effect runs only once

useEffect(() => {
    console.log(submittedData, 'submittedData')
    // Fetch template data when the component mounts
    if (submittedData !== null) {
        fetchSharedData();
    }
}, []); // The empty dependency array ensures this effect runs only once

// Onsubmit category based 
 
 
    const navigate = useNavigate();

   
    const currentTime = new Date();
    let sesinstituteId = useState<string>(''); 
    let userId = useState<string>('');
    const { globalState } = useContext(GlobalContext);
    
    const { getCampuses } = useManagementDashboard();
    const campuses = getCampuses();
    const [instituteList, setinstituteList] = useState(getinstituteList('INSTITUTE_GROUP_CODE', campuses));
    //alert(instituteList);
   // console.log(instituteList);

    const { sessionState } = useContext(SessionContext);
   
    let [institutes, setInstitutes] = useState<string[]>([]);
 

    useEffect(() => {
      if (sessionState && sessionState.institutes) {
        setInstitutes(sessionState.institutes);
      }
    }, [sessionState]);
 
    console.log(institutes, 'institutes details')
    const queryParams = new URLSearchParams(window.location.search);
    
    const eventId = queryParams.get('id');
    console.log(eventId)
    
    let school_settings:any = JSON.parse(localStorage.getItem('school_settings') ?? '{}');      
    //console.log(school_settings)  
       
    const userDataString = sessionStorage.getItem('user');
    
   //console.log(userDataString,'test kjhjk');
    if (userDataString) {
        // Parse JSON string to object
        const userData = JSON.parse(userDataString);
        sesinstituteId = userData.institutes; 
        userId = userData.userId;       
    }
  //  console.log('userid',userId);

    const [pageData, setPageData] = useState<any[]>([]);

    const showPageName = async () => {
        try {
          const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/profile/get-social-data`, {'school_id': 1234});
          const responseData = await response.data;
          return responseData;
        } catch (error) {
          console.error('Error fetching data:', error);
          return [];
        }
      };


      useEffect(() => {
        const fetchData = async () => {
          const pageData: any = await showPageName();
          setPageData(pageData.data);
        };
      
        fetchData();
      }, []);
      const [selectedOption, setSelectedOption] = useState('facebook');
      const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
      };

      const [isMessageFilled, setIsMessageFilled] = useState(false);

        useEffect(() => {
        setIsMessageFilled(postText.trim() !== ''); // Update isMessageFilled when postText changes
        }, [postText]);


      // function to get access tokenk
    async function getAccessToken(): Promise<string | null> {

        const accessToken = 'EAAfzEIFqUtoBOxk6xsenBX7S65zjHjnABIzYza4xqQQMdI6TKIPxDW3UvhWg7OrFb1emX9zqJZABUQQ1XJfE46ihHnqgWYegh3gUDN0v3sHbk2riDfsWEX5Tw3IL8zn66uDZCr3pCFlCRbQVp5QLZAfw6WuwaV9ES4a3ym8cUAXJN1uSZA5K4osKUySClVZADC4ddVHOafF9hNX1PPKkZAMLOC3UwAwZCN8SXWXVFh7icMhB1A1uApiTW0QbObx';//For testing
        return accessToken;
        // const clientId = '2237577053229786';
        // const clientSecret = 'aa316e7020610c83ec231eea91847f54';
        // const redirectUri = 'http://localhost:3000/share-profile';
      
        // const url = `https://graph.facebook.com/v16.0/oauth/access_token`;
        // const params = {
        //   client_id: clientId,
        //   client_secret: clientSecret,
        //   redirect_uri: redirectUri,
        //   code: code_url,
        // };
      
        // try {
        //   const response = await axios.get(url, { params });
  
        //   const accessToken= response.data.access_token;
  
        //   return accessToken;
        // } catch (error) {
        //   console.error('Error getting access token:', error);
        //   return null;
        // }
      
      }

          //function to get userid
    async function getUserId() {
        const accessToken = await getAccessToken();
      
        if (accessToken) {
          //alert(accessToken);
          console.log(accessToken);
          const fields = 'id';
           
          const url = `https://graph.facebook.com/v16.0/me`;
            const params = {
              client_id: fields,
              access_token: accessToken,
              
            };
          
            try {
              const response = await axios.get(url, { params });
    
              const id= response.data.id;
             // alert(id);
              console.log("id:="+id);
              return id;
            } catch (error) {
              console.error('Error getting access token:', error);
              return null;
            }
  
        } 
      }

      //function to get pageid 
    async function getPagesId() {
        const accessToken = await getAccessToken();
        const id=await getUserId();
        if (accessToken) {
        //  alert(accessToken);
        //  alert(id);
            const url = `https://graph.facebook.com/v16.0/`+id+`/accounts`;
            const params = {
              access_token: accessToken
            };
          
            try {
              const response = await axios.get(url, { params });
              const accounts = response.data.data.map((account: any) => {
                return {
                  access_token: account.access_token,
                  name: account.name,
                  id: account.id
                };
              });
          
              return accounts;
            } catch (error) {
              console.error('Error getting accounts:', error);
              return [];
            }
          
        } 
      }

    //   function escapeHTML(html : any) {
    //     const element = document.createElement('div');
    //     element.innerText = html;
    //     return element.innerHTML;
    //   }

    function decodeHtmlEntities(text:any) {
        const element = document.createElement('div');
        element.innerHTML = text;
        return element.innerText || element.textContent;
      }
      
      // Usage
      //const decodedMessage = decodeHtmlEntities(postText);
      
      // Usage example
    //   const unsafeHTML = "<script>alert('Hello');</script>";
    //   const safeHTML = escapeHTML(unsafeHTML);
       // Output: &lt;script&gt;alert(&#39;Hello&#39;);&lt;/script&gt;
      

      const handlePublish = async () => {
        if (selectedPage) {
            // const safeHTML =  decodeHtmlEntities(postText)
            // console.log(safeHTML);
            
            
            if(image){
                const photoUrl = image;
                const message = decodeHtmlEntities(postText);
                const accessToken = selectedPage.access_token;
                const pageId = selectedPage.page_id;
                let post_type:number=0;
                if (photoUrl.length===0) {
                    post_type=1;
                }
                
                await uploadPhoto(photoUrl, message, accessToken,pageId,post_type);

                // add social post

                //   let socialpostAdd = `${process.env.REACT_APP_LEAD_SERVER}/socialpost/addsocailpost`;
                //   axiosClient()
                //     .post(`${socialpostAdd}`, {
                //         eventId : eventId ? eventId : '',
                //         eventTitle: state['data']['addevent']['eventTitle'],
                //         eventMode: state['data']['addevent']['eventMode'],
                //         activeEvent: state['data']['addevent']['activeEvent'],                
                //         registrationsActive: state['data']['addevent']['registrationsActive'],
                //         eventType: state['data']['addevent']['eventType'],
                //         registrationLink: state['data']['addevent']['registrationLink'],
                //         videoLink: state['data']['addevent']['videoLink'],
                //         presenterCategory: state['data']['addevent']['presenterCategory'],
                //         eventDescription: state['data']['addevent']['eventDescription'],
                //         instituteId: state['data']['addevent']['instituteId'],
                //         eventImage: state['data']['addevent']['eventImage'],
                //         created_by: userId,
                        
                //      })
                //      .then((res: any) => {

                //         console.log(res)
                //         alert(res.data.message)

                //         if (!res.data.eventId) return alert('There is some issue in event management. Please try after sometime!');
                //         navigate(`/socialmedia/socialpage`);

                //     });

                    navigate(`/socialmedia/socialpage`);

                alert('Data posted successfully.'); // You can show a success message or perform other actions here
            }else{

                alert('Please select a image.'); // Handle the case when no page is selected

            }
          
        } else {
          alert('Please select a page.'); // Handle the case when no page is selected
        }
        };
      async function main() {
        //const accessToken = 'YOUR_ACCESS_TOKEN'; // Replace with your actual access token
        const accounts = await getPagesId();
      
        if (accounts.length > 0) {
          accounts.forEach((account: Account) => {
            console.log('Access Token:', account.access_token);
            console.log('Name:', account.name);
            console.log('ID:', account.id);
  
           
       
            
           
              // const fetchData = async () => {
              //     try {
              //         const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/profile/save-social-data`,{'page_id':account.id,'school_id':1234,'access_token':account.access_token,'page_name':account.name});
              //         const data = await response.data; // Extract the data from the response
                     
              //     } catch (error) {
              //         console.error('Error fetching data:', error);
              //         alert(error);
              //     }
              // };
  
      
            //  fetchData();
          
                
          
          });
        } else {
          console.log('No accounts found.');
        }
      }
  
      
      main();

      console.log(pageData);
    const [selectedPage, setSelectedPage] = useState<any | null>(null); // State to store the selected page details
    const [selectedOption1, setSelectedOption1] = useState('facebook');
      const handleRadioChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = event.target.value;
        setSelectedOption1(selectedValue);
        
        // Find the selected page in the pageData array
        const page = pageData.find((page) => page.page_name === selectedValue);  
        setSelectedPage(page);
        };

      // Call anotherFunction
    async function uploadPhoto(url: string, message: any, accessToken: string, pageId :string, post_type: Number): Promise<void> {
        let api_url:string="test";
        //alert(post_type);
        if(post_type===1){
         api_url =`https://graph.facebook.com/v16.0/${pageId}/feed`; // Update with the correct URL
        }
        else{
          api_url =`https://graph.facebook.com/v16.0/${pageId}/photos`; // Update with the correct URL
        }
       // alert(api_url);
        const apiEndpoint=api_url;
        const data = {
          url: url,
          message: message,
          access_token: accessToken,
        };
      
        try {
          const response = await axios.post(apiEndpoint, data);
      
          if (response.status === 200) {
            console.log('Data Posted successfully.');
          } else {
            console.log('Photo upload failed.');
          }
        } catch (error) {
          console.error('Error uploading photo:', error);
        }
      }
    

    const handleImageUpload = async (e: any) => {
        let link = '';
        if (e.target.files != null) {
            const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/profile`;
            const formData = new FormData();
            formData.append('image', e.target.files[0]);
  
            const response = await axiosClient().post(apiUrl, formData);
            link = response.data.link;
            console.log(link, " LINK ")
            setImage(link);
           
        }
    };
   
    const fileInputRef = useRef(null);

    return (
        <>        
        <div className="main flex-1 bg-white">
            <div className='max-1140 pt-3 pt-sm-4 px-lg-0 pb-5 overflow-hidden mx-auto'>
                <div className="d-flex justify-content-between align-items-center mb-3 pb-3 custom-bottom-liteblue">
                    <h3 data-aos="fade-left" data-aos-delay="600" className="font-35 font-600 mb-0 block-title position-relative">
                        Social Post <span className="custom-tool">Create New</span>
                    </h3>  
                </div>
                <div className='ActiveListings_present'>
                    <div className='row g-4'>
                        <div className='col-md-6'>
                        <form action="" className="w-100 mx-auto">
                        <div className="row g-3">
                            <div className="col-md-12">
                            <label className="form-label m-0 mb-1 font-16 font-500 w-100" htmlFor="BroadcastTitle">Post To</label>
                            <div className="postOption">
                                <ul>
                                <li>
                                    {/* <input type="radio" name="Post" className="d-none" id="facebook"/> */}
                                    <input checked={true}
                                      type="radio"
                                      name="social"
                                      value="facebook"
                                      id="facebook" className="d-none"
                                      
                                      onChange={handleRadioChange}
                                    />
                                    <label htmlFor="facebook"><i></i> <span>Facebook</span><b></b></label>
                                </li>
                                {/* <li>
                                    <input type="radio" name="Post" className="d-none" id="Twitter"/>
                                    <label htmlFor="Twitter"><i></i> <span>Twitter X</span><b></b></label>
                                </li> */}
                                </ul>
                            </div>
                            <div className='col-12'>
                                  <div className='pagename'>
                                    <div className='row g-3'>
                                    {/* {
                                    pageData.map((page: any) => (
                                      <div className="col-auto" key={page.id}>
                                        <div className="form-check">
                                        <input
      className="form-check-input"
      type="radio"
      name="flexRadioDefault"
      value={page.page_name}
      id={`flexRadioDefault${page.id}`}
      checked={selectedPage?.page_name === page.page_name}
      onChange={handleRadioChange1}
    />
    <label className="form-check-label" htmlFor={`flexRadioDefault${page.id}`}>
      {page.page_name}

                                        </label>
                                        </div>
                                      </div>
                                    ))} */}
                                </div>
  
                                  </div>
                              </div>
                            </div>
            
                            <div className='col-md-12'>
                                <label className="form-label m-0 mb-1 font-16 font-500 w-100" htmlFor="Message">Post Message</label>
                                <ReactQuill
                                    style={editorStyle}
                                    value={postText}
                                    onChange={setPostText} // No need to use e.target.value, just the parameter value
                                    placeholder="Compose your message..."
                                    className='editor'
                                    />
                                    {/* <textarea
                                    rows={3}
                                    className="editor"
                                    placeholder="Ex: School is organizing alumni reunion on Campus on 15th September 2021."
                                    id="Message"
                                    value={postText}
                                    onChange={(e) => setPostText(e.target.value)}
                                    ></textarea> */}
                            </div>
            
                            <div className="col-md-12">
                            <div className="row mb-4">
                            <div className="col-md-12 inputfile_parent m-0">
                            <div className="file file--upload w-100">
                                <label htmlFor="choose_image">
                                    <i className="material-icons"><img src={uploadImg}/></i> Browse your image
                                </label>
                                
                                <input
                                type="file"
                                name='post_image'
                                className="d-none"
                                id="choose_image"
                                onChange={handleImageUpload}
                                accept="img/*"
                                style={{ opacity: '0.3' }}
                            />
                            </div>
                        <div className="docselect">
                            <span className="me-1">
                            <img src="images/doc_icon.svg" alt="" />
                            </span>
                            <input
                            type="file"
                            name="fileUpload"
                            id="fileUpload"
                            className="inputfile"
                            data-multiple-caption="{count} files selected"
                            multiple
                            required
                            onChange={(e) => handleImageUpload(e.target.files)}
                            />
                            <span className="fileclose">
                            <span className="icon-cross"></span>
                            </span>
                        </div>
                        </div>
                    </div>
                            {/* <label className="form-label m-0 mb-1 font-16 font-500 w-100" htmlFor="Message">Upload Image</label>
                            {/* <input type="file" id="cloud-computing" className="d-none"/> 

                            <input
                                type="file"
                                id="image-upload"
                                style={{ display: 'none' }}
                                onChange={handleImageUpload} // Triggered when a file is selected
                                accept="image/*" // Accept only image files
                            />
                            <div className="upload_section">
                                <div className="row g-2 align-items-center">
                                    <div className="col-sm">
                                    <label className="cloud-wrap px-3 px-sm-5" htmlFor="cloud-computing">
                                        <div className="cloud-img">
                                        <img src={ImgCloudComputing} alt="Upload" />
                                        </div>
                                        <div className="cloud-text">Drag and drop a file here or pick from Collaterals Library</div>
                                    </label>                          
                                    </div>
                                
                                </div>
                            </div> */}
                            </div>
                            
                    
                            <div className="col-md-12">
                            <div className="row g-2 justify-content-center align-items-center">
                                {/* <div className="col-auto"><button type="button" className="btn btn-dark-blue height-45 w-auto px-4 text-white">Save as Draft</button></div>
                                <div className="col-auto"><button type="button" className="btn btn-border-gray-de height-45 px-5"><span>Discard</span></button></div> */}
                                {/* <div className="col-auto"><button type="button" className="btn btn-green height-45 px-5"><span>Publish</span></button></div> */}
                                <div className="col-auto"><button type="button" className="btn btn-green height-45 px-5" onClick={handlePublish}
        disabled={!isMessageFilled} ><span>Publish</span></button></div> 
                            </div>
                            </div>
                        </div>
                        </form>
                        </div>
                        <div className='col-md-6'>
                            <div className="post-preview">
                                <h2>Post Preview</h2>
                                <div className="post-preview-data">
                                <div className="card facebook" id="facebook-tab">
                                    <div className='card-header d-flex flex-wrap align-items-center p-3'>
                                    <span><img className="me-2" src="images/facebook.svg" width="30" alt="" /></span>
                                    <h5 className="card-title m-0">Facebook</h5>
                                    </div>
                                    <div className="card-body">
                                    
                                    {/* <p className="card-text">{postText}</p> */}
                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: postText }} />
                                    <div className='post-img text-center'>

                                    <img alt="" src={image} className="img-fluid mx-auto"/>
                                    

                                    </div>
                                    </div>
                                    
                                </div>
                                    
                                    {/* <div className="post-img text-center">
                                        <img alt="" src={image} className="img-fluid mx-auto"/>
                                    </div> */}
                                </div>
                            </div>

                            
                            {/* <div>
                                <div className="card facebook" id="facebook-tab">
                                    <div className='card-header d-flex flex-wrap align-items-center p-3'>
                                    <span><img className="me-2" src="images/facebook.svg" width="30" alt="" /></span>
                                    <h5 className="card-title m-0">Facebook</h5>
                                    </div>
                                    <div className="card-body">
                                    
                                    <p className="card-text">{postText}</p>
                                    <div className='row g-2'>

                                        <img alt="" src={image} />
                                    

                                    </div>
                                    </div>
                                    
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        </>
    );
};
export default SocialPost;