import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStudentImage } from '../../../../../common/styles/Images';
import { SmartContext } from '../../../../../library/Core/SmartContext';
import { axiosClient } from '../../../../../library/Service/axiosClient';
import VerifyMobileControl from '../../../../alumni/onboarding/VerifyMobileControl';

const Header = () => {
    const { state } = useContext(SmartContext);
    const model = state.internal.model;
    const navigate = useNavigate();

    const [showVerifyMobile, setShowVerifyMobile] = useState(false);
    const [isMobileVerified, setIsMobileVerified] = useState(model.isMobileVerified); // Initialize the state based on model.is_mobile_verify

    const handleVerifyMobile = (event: any) => {
        event.preventDefault();
        sendMobileInvite(model.mobileNumber);
        setShowVerifyMobile(true);
    };

    const sendMobileInvite = async (mobile: string) => {
        try {
            const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/sms/send-verification-otp`);
            if (response?.status === 200) {
                setIsMobileVerified(true);
            }
        } catch (error) {
            console.error('Error sending SMS:', error);
        }
    };

    return (
        <div className="col-12 position-relative">
            <a
                className="btn-edit-pop position-absolute top-0 right-40 text-decoration-none"
                role="button"
                onClick={() => navigate(`/management/${model.id}/basic-info`)}>
                <i className="icon-edit-pencil text-dark-blue"></i>
            </a>
            <div className="alumni_profile_name_college_other_detail_wrap">
                <div className="alumni_profile_image_name_other_detail">
                    <div className="alumni_profile_image">
                        <img src={getStudentImage({ profilePictureFilePath: model.profilePicture, genderCode: model.genderCode })} alt="" />
                    </div>
                    <div className="alumni_profile_name_other_detail">
                        <h2>
                            {model.salutation} {model.name}
                        </h2>
                        <div className="alumni_profile_location">
                            <i className="icon-location-pin"></i>{' '}
                            <span>
                                {model.residingCity} , {model.residingCountry}
                            </span>
                        </div>
                        <div className="alumni_profile_class_college">
                            <div className="alumni_profile_college">
                                {model.designation} 
                                {/* at  */}
                                <br />
                                {model.schoolName}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="alumni_profile_college_name_other_detail_wrap align-items-center">
                    <div className="row flex-column">
                        <div className="col-md-12 mb-3">
                            <div className="w-100 d-flex flex-wrap align-items-center">
                                <div className="phone_number d-flex">
                                    <label className="font-14 text-white">Contact Number</label>
                                </div>
                                <div className="d-flex flex-wrap align-items-center">
                                    <span className="font-16 font-500 me-3">
                                        {model.mobileCountryCode} {model.mobileNumber}
                                    </span>

                                    {/* <a className="verifynow font-13 mt-0" role="button" onClick={handleVerifyMobile}>
                                        <span>Verify Mobile</span>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="w-100 d-flex flex-wrap align-items-center">
                                <div className="phone_number d-flex contemail">
                                    <label className="font-14 text-white">Contact Email</label>
                                </div>
                                <div className="d-flex flex-wrap align-items-center" style={{ flex: '1' }}>
                                    <span className="font-16 font-500 me-3 foremail">
                                        <a
                                            className="text-decoration-none foranchoremail text-black"
                                            href={`mailto:${model.contactEmailId}"`}
                                            data-bs-toggle="tooltip"
                                            title={model.contactEmailId}>
                                            {model.contactEmailId}
                                        </a>
                                    </span>

                                    {/* <a href="" className="verifynow font-13 mt-0">
                                        <span>Verify Email</span>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showVerifyMobile && <VerifyMobileControl setShowVerifyMobile={setShowVerifyMobile} mobileNumber={model.mobileNumber} />}
        </div>
    );
};

export default Header;
