import { axiosClient } from '../../../library/Service/axiosClient';
import { REPORT_CONFIG } from '../../admin/reports/report-config';
import pageConfig from './PageConfig.json';

class DomainCategoryDataService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = this.getDefaultSearchCriteria();

        return Promise.all([
            this.getPageConfig(),
            this.search(searchCriteria, params.pageName),
            this.getSchoolSearchFilterDomains(params.pageName),
            this.getDomainCategoryDropdown(),
        ]).then((values) => {
            state.formConfig = values[0];
            state.data = {
                alumniList: values[1].data ?? [],
                searchCriteria: { ...searchCriteria, schoolId: sessionStorage.getItem('user-school-id') },
                filterDomains: values[2].data,
                reportConfig: REPORT_CONFIG[params.pageName],
            };
            state.routeInfo = params;
            state.internal = { gridState: {}, DomainCategorycode: values[3].data.data };
            return Promise.resolve(state);
        });
    }

    static search(searchCriteria: any, reportType: string): any {
        const payload = DomainCategoryDataService.sanitizeSearchCriteria(searchCriteria, reportType);
        return axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/domain/get-domain-details`, payload);
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    // Notes: This is key function in forming the search criteria for the report. Handle with care.
    static sanitizeSearchCriteria(searchCriteria: any, reportType: string) {
        return searchCriteria;
    }

    static getSchoolSearchFilterDomains(reportName: string = '') {
        return axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/school-group-search-filter-domains`);
    }

    static getDomainCategoryDropdown() {
     return axiosClient().post(`${process.env.REACT_APP_ADMIN_API_SERVER}/domains/get-all-domaincategory`);
    }

    static getDefaultSearchCriteria(): any {
        return {
            domainCategory: [],
        };
    }
}

export default DomainCategoryDataService;
