import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';

class ManagementDashboardService {
    static pageLoader(params: any) {
        const state: any = {};
        const { id, pageName } = params;
        const schoolId = parseInt(sessionStorage.getItem('user-school-id') as string);
        const schoolGroupId = parseInt(sessionStorage.getItem('user-school-group-id') as string) ?? schoolId * -1;

        return Promise.all([ManagementDashboardService.getBatches()])
            .then((values) => {
                state.formConfig = {};
                state.data = {
                    selectedTab: 'ALUMNI',
                    searchCriteria: {
                        onBoarding: {},
                        department: {},
                        studentCountry: {},
                        studentCareer: {},
                        studentOnboarding: {},
                        alumniPresence: {},
                        alumniOnboarding: {},
                        alumniPresentStatus: {},
                        alumniCountry: {},
                    },
                    schoolId,
                    schoolGroupId,
                    batches: values[0].data,
                    accordionState: 'EXPANDED',
                    campuses: [],
                    reportData: {},
                };

                state.routeInfo = { id, pageName };
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static get(schoolId: number, schoolGroupId: number) {
        return axiosClient().post(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/dashboard/reports`, {
            schoolId,
            schoolGroupId,
        });
    }

    static getBatches = async () => {
        return axiosClient().get(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/dashboard/batches`);
    };

    // Get batches for a school. Current year and 4 years from that
    // static getBatchesForSelectedTab = (selectedTab: string, batches: any) => {
    //     return selectedTab === 'ALUMNI'
    //         ? ManagementDashboardService.getBatchesForAlumni(batches)
    //         : ManagementDashboardService.getBatchesForStudent();
    // };

    static getBatchesForSelectedTab = (selectedTab: string, batches: any) => {
        const uniqueLabels = new Set();
        const uniqueBatches = batches.filter((batch: any) => {
            if (!uniqueLabels.has(batch.label) && batch.userTypeCode === selectedTab) {
                uniqueLabels.add(batch.label);
                return true;
            }
            return false;
        });
        return uniqueBatches.map((batch: any) => ({ label: batch.label, value: batch.value }));
    };

    static getBatchesForStudent = () => {
        const currentYear = new Date().getFullYear();
        const batches = [];
        // @AGS-556-start @dt 20th.Sep,2K23 @by RG @note for 8th class students,
        // passout year will be current year + 5
        // for (let i = 0; i < 5; i++) {
        for (let i = 0; i < 6; i++) {
            const year = currentYear + i;
            const row: any = { label: year, value: year };
            batches.push(row);
        }
        return batches;
    };

    static getBatchesForAlumni = (batches: any) => {
        const uniqueLabels = new Set();
        const uniqueBatches = batches.filter((batch: any) => {
            if (!uniqueLabels.has(batch.label) && batch.userTypeCode === 'ALUMNI') {
                uniqueLabels.add(batch.label);
                return true;
            }
            return false;
        });
        return uniqueBatches.map((batch: any) => ({ label: batch.label, value: batch.value }));
    };

    static getCampusForSelectedTab = (state: any, config: any) => {
        if (state.selectedTab === 'STUDENT') return state.campuses;

        // Alumni
        const selectedBatch = state.searchCriteria[config.key].batch;
        if (!selectedBatch) return state.campuses;
        const filteredSchoolId = state.batches
            .filter((batch: any) => batch.userTypeCode === 'ALUMNI' && selectedBatch.split(',').includes(batch?.value?.toString()))
            ?.map((batch: any) => batch?.schoolId);
        return state.campuses.filter((campus: any) => filteredSchoolId.includes(parseInt(campus.value)));
    };

    static config: any = {
        STUDENT_ONBOARDING: { key: 'studentOnboarding', title: 'Student Onboarding', tab: 'STUDENT', filters: ['dateRange', 'campus'] },
        STUDENT_COUNTRY: { key: 'studentCountry', title: 'Students Preferences - Country', tab: 'STUDENT', filters: ['batch', 'campus'] },
        STUDENT_CAREER: { key: 'studentCareer', title: 'Students Preferences - Career', tab: 'STUDENT', filters: ['batch', 'campus'] },
        ALUMNI_ONBOARDING: { key: 'alumniOnboarding', title: 'Alumni Onboarding', tab: 'ALUMNI', filters: ['dateRange', 'campus'] },
        ALUMNI_PRESENCE: { key: 'alumniPresence', title: 'Alumni Preferences - Career', tab: 'ALUMNI', filters: ['batch', 'campus'] },
        ALUMNI_PRESENT_STATUS: {
            key: 'alumniPresentStatus',
            // title: 'Present Status of Alumni',
            title: 'Outcome of Alumni',
            tab: 'ALUMNI',
            filters: ['batch', 'campus'],
        },
        ALUMNI_CAREER_DISTRIBUTION: {
            key: 'alumniCareerDistribution',
            title: 'Career Distribution of Alumni',
            tab: 'ALUMNI',
            filters: ['batch', 'campus'],
        },
        ALUMNI_COUNTRY: {
            key: 'alumniCountry',
            title: 'Country Distribution of Alumni',
            tab: 'ALUMNI',
            filters: ['batch', 'campus'],
        },
    };
}

export default ManagementDashboardService;
