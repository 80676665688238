import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import FormBuilder from '../../../library/Builders/FormBuilder';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getDataKeyForSection, getSectionConfig } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, FormSection, State } from '../../../library/Core/SmartTypes';
import SmartControl from '../../../library/SimpleControls/SmartControl';
import { handleNext } from '../../album/posting/AlbumAction';
import ReportLayout from '../common/ReportLayout';
import useStudentReport from './useStudentReport';

const StudentManagementReport = () => {
    const navigate = useNavigate();
    const { pageName } = useParams();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { NEXT: handleNext, ROUTER_NAVIGATE: navigate },
    } as any);

    const { initialize, test } = useStudentReport();

    useEffect(() => {
        const init = async () => {
            const initialData = await initialize(pageName);
            dispatch({ type: 'DATA_INIT', payload: initialData });
        };

        init();
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            {!state?.flags.isDataLoading && (
                <ReportLayout
                    searchCriteria={
                        <FormBuilder
                            key={'searchCriteria'}
                            section={getSectionConfig('searchCriteria', state) as FormSection}
                            dataKey={getDataKeyForSection('searchCriteria', state)}
                        />
                    }
                    results={
                        <SmartControl key={`students`} sectionId="students" controlId="manageStudentsSearchResults" dataKey="students" />
                    }></ReportLayout>
            )}
        </SmartContext.Provider>
    );
};

export default StudentManagementReport;
