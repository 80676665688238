import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import ContactLayout from './ContactLayout';
import useManagementDashboard from '../../management/dashboard/useManagementDashboard';
import { getinstituteList } from '../../../library/Service/domainService';
import Swal from 'sweetalert2';
import { isFormValid, setError, updateMoEngageEvent } from '../../../library/Core/SmartFunctions';
import { axiosClient } from '../../../library/Service/axiosClient';

const Contact = () => {
    const { id,groupId,pageAction } = useParams();
    const { globalState } = useContext(GlobalContext);
    const location = useLocation();
    let pageName: string;
    if (location.pathname.includes('basic-info')) {
        pageName = 'admin-institute-create';
    } else if (location.pathname.includes('online-preference')) {
        pageName = 'institute-online-preference';
    }else if (location.pathname.includes('institute-onboarding')) {
        pageName = 'admin-institute-onboarding';
    } else {
        pageName = 'admin-institute-contacts';
    }
    const navigate = useNavigate();
    type SocialLink = {
        socialNetworkCode: string;
        link: string;
    };

    const { getInstitutes } = useManagementDashboard();
    const campuses = getInstitutes(groupId);
    const [instituteList, setinstituteList] = useState(getinstituteList('INSTITUTE_GROUP_CODE', campuses));

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            SAVE: handleSave,
        },
    } as any);

    let { formConfig, data } = useLoaderData() as State;

    const getSocialProfileLinks = () => {
        return globalState.domain?.get('SOCIAL_NETWORK_CODE')?.map((item: any) => ({ socialNetworkCode: item.code, link: '' }));
    };

    useEffect(() => {
        const domain = globalState.domain;
        let instituteId = [];
        if (pageAction === 'new') {
            dispatch({
                type: 'DATA_INIT',
                payload: {
                    formConfig,
                    data: { adminAccounts: [],id:id,groupId:groupId },
                    domain,
                    routeInfo: { id,groupId, pageName: pageName },
                },
            });
            dispatch({
                type: 'SET_DOMAIN',
                payload: { key: 'INSTITUTE_GROUP_CODE', value: instituteList },
            });

        } else {
            dispatch({
                type: 'DATA_INIT',
                payload: {
                    formConfig,
                    data: { adminAccounts: data,id:id,groupId:groupId },
                    domain,
                    routeInfo: { id,groupId, pageName: pageName },
                },
            });

            dispatch({
                type: 'SET_DOMAIN',
                payload: { key: 'INSTITUTE_GROUP_CODE', value: instituteList },
            });
        }
    }, []);

    const ischeckedpromaryInstitute = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        if (id != groupId) {
            const adminAccounts = state?.data?.adminAccounts;
            if (adminAccounts && Array.isArray(adminAccounts)) {
                let errorFound = false; // Track if any error is found
                adminAccounts.forEach((adminAccount, index) => {
                    const instituteIds = adminAccount.instituteId.map((institute: { instituteId: string }) => parseInt(institute.instituteId));
                    const primaryInstituteId = parseInt(adminAccount.primaryInstituteId); 
                    if (!instituteIds.includes(primaryInstituteId)) {
                        setError('adminAccounts.' + index + '.primaryInstituteId', ["Primary instituteId is not included in campus"], dispatch);
                        errorFound = true; 
                    }
                });
    
                if (errorFound) {
                    return true; 
                }
            } else {
                console.error("Admin accounts data is missing or invalid");
            }
        }
        return false; 
    };

    function handleSave(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void
        ) {
            const ischeckedpromaryInst = ischeckedpromaryInstitute(state, dispatch);
            console.log("ischeckedpromaryInst",ischeckedpromaryInst)
            event.preventDefault();
            if (!isFormValid(state, dispatch) || ischeckedpromaryInst) {
                dispatch({ type: 'SHOW_ERRORS' });
                alert('Please correct the form errors!');
                return;
            }
            saveContacts(state);
    };

    const saveContacts = async (state: any) => {
       const data=state?.data;
       const id=state?.routeInfo?.id;
       const groupId=state?.routeInfo?.groupId
       
       const response = await axiosClient().post(`${process.env.REACT_APP_ADMIN_API_SERVER}/institute/createContacts`, {...data, id,groupId});
       const res = await response.data;
        if (res.success === false) {
            if(res.field=='emailId'){
                setError('adminAccounts.'+res.data+'.registeredEmailId', [res.message], dispatch);
                dispatch({ type: 'SHOW_ERRORS' });
            }else if(res.field=='primaryInstituteId'){
                setError('adminAccounts.'+res.data+'.primaryInstituteId', [res.message], dispatch);
                dispatch({ type: 'SHOW_ERRORS' });
            }else{
                console.log(res.message);
            }
          return;
        }else{
            updateMoEngageEvent(data,'moengage-admin-contact');
            console.log(res)
            Swal.fire({
                title: 'Success!',
                text: res.message,
                icon: 'success',
                showConfirmButton: false, 
                timer: 1000, 
                timerProgressBar: true 
            }).then((result) => {
                dispatch({ type: 'RESET_VALIDATION_ERRORS' });
                navigate(`/institute/${id}/${groupId}/contact-listing`, { replace: false });
            });
        }  
    }
    


    const handleAddInstitute = () => {
        dispatch({ type: 'RESET_VALIDATION_ERRORS' });
        navigate(`/institute/${id}/basic-info`, { replace: false });
    };
    const handleOnlinePreference = () => {
        dispatch({ type: 'RESET_VALIDATION_ERRORS' });
        navigate(`/institute/${id}/online-preference`, { replace: false });
    };
    const handleContacts = () => {
        dispatch({ type: 'RESET_VALIDATION_ERRORS' });
        navigate(`/institute/${id}/${groupId}/contacts`, { replace: false });
    };
    const handleInstituteOnboarding = () => {
        dispatch({ type: 'RESET_VALIDATION_ERRORS' });
        navigate(`/institute/${id}/institute-onboarding`, { replace: false });
    };

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
        <ContactLayout id={id} groupId={groupId} mode={pageName}  handleAddInstitute={handleAddInstitute}
            handleOnlinePreference={handleOnlinePreference}
            handleContacts={handleContacts}
            handleInstituteOnboarding={handleInstituteOnboarding}>
            {!state.flags.isDataLoading && <PageBuilder />}
        </ContactLayout>
      </SmartContext.Provider>
    );
};

export default Contact;
