import { useContext } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';

const useManageAlumni = () => {
    const { state, dispatch } = useContext(SmartContext);

    const setStateValue = (dataKey: string, value: string) =>
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey, value, errorMessages: [] } });

    return { setStateValue };
};

export default useManageAlumni;
