import { axiosClient } from '../../../library/Service/axiosClient';
import pageConfig from './PageConfig.json';

class AlumniMatchStudentsService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = this.getDefaultSearchCriteria(params.pageName);

        return Promise.all([this.getPageConfig(), this.search(searchCriteria, params.pageName), this.getSchoolGroupDomain()]).then(
            (values) => {
                state.formConfig = values[0];
                state.data = {
                    studentList: values[1].data ?? [],
                    searchCriteria: { ...searchCriteria, schoolGroupId: '' },
                };
                state.routeInfo = params;
                state.internal = { gridState: {} };
                state.internal.schoolGroupDomain = values[2].data;
                return Promise.resolve(state);
            }
        );
    }

    static getDefaultSearchCriteria(pageName: string = '') {
        // return {};
        const searchCriteria: any = {};

        searchCriteria.schoolId = '';
        searchCriteria.schoolGroupId = '';
        searchCriteria.totalStudentCountFrom = '';
        searchCriteria.totalStudentCountTo = '';
        searchCriteria.totalAlumniCountFrom = '';
        searchCriteria.totalAlumniCountTo = '';
        searchCriteria.updatedToMoeFrom = '';
        searchCriteria.updatedToMoeTo = '';
        searchCriteria.clientType = '';
        searchCriteria.instituteTypeCode = 'K12_SCHOOL';

        return searchCriteria;
    }

    static search(searchCriteria: any, pageName: string = '') {
        return axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-matching/search-process-status`, searchCriteria);
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    static getSchoolGroupDomain() {
        return axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-matching/school-group-domain`);
    }
}

export default AlumniMatchStudentsService;
