import { axiosClient } from '../../../library/Service/axiosClient';
import { REPORT_CONFIG } from '../../admin/reports/report-config';
import pageConfig from './PageConfigList.json';

class ManageLeadService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = ManageLeadService.getDefaultSearchCriteria();
       
        return Promise.all([
            this.getPageConfig(),
            this.search(searchCriteria, params.pageName),
        ]).then((values) => {
            console.log(values)
            state.formConfig = values[0];
            state.data = {
                leadList: values[1].data ?? [],
                searchCriteria: { ...searchCriteria, schoolId: sessionStorage.getItem('user-school-id') },
                //filterDomains: values[2].data,
                reportConfig: REPORT_CONFIG[params.pageName],
            };
            state.routeInfo = params;
            state.internal = { gridState: {} };
            return Promise.resolve(state);
        });
    }

    static search(searchCriteria: any, pageName: string): any {
        const config = REPORT_CONFIG[pageName];
        const payload = ManageLeadService.sanitizeSearchCriteria(searchCriteria,);
        return axiosClient().post(`${process.env.REACT_APP_LEAD_SERVER}/${config.SEARCH_API}`, payload);
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    static sanitizeSearchCriteria(searchCriteria: any) {
        return searchCriteria;
    }

    static getDefaultSearchCriteria(): any {
        return {
            instituteId:[],
            programId: [],
            leadSource:[],
            registrationType:[],
            leadStatus:[],
            leadCreationDate:[],
            leadUpdationDate:[],
            intakeBatch:[],
            createdFrom:[],
            createdTo:[],
            updateFrom:[],
            updateTo:[]
        };
    }
}

export default ManageLeadService;
