import { axiosClient } from '../../library/Service/axiosClient';
import { REPORT_CONFIG } from '../admin/reports/report-config';
import pageConfig from './PageConfigList.json';

class ProgramLisingService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = ProgramLisingService.getDefaultSearchCriteria();

        return Promise.all([
            this.getPageConfig(),
            this.search(searchCriteria, params.pageName),
        ]).then((values) => {
            state.formConfig = values[0];
            state.data = {
                alumniList: values[1].data ?? [],
                searchCriteria: { ...searchCriteria, schoolId: sessionStorage.getItem('user-school-id') },
                //filterDomains: values[2].data,
                reportConfig: REPORT_CONFIG[params.pageName],
            };
            state.routeInfo = params;
            state.internal = { gridState: {} };
            return Promise.resolve(state);
        });
    }

    static search(searchCriteria: any, pageName: string): any {
        const config = REPORT_CONFIG[pageName];
        const payload = ProgramLisingService.sanitizeSearchCriteria(searchCriteria,);
        return axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/${config.SEARCH_API}`, payload);
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    static sanitizeSearchCriteria(searchCriteria: any) {
        return searchCriteria;
    }

    static getDefaultSearchCriteria(): any {
        return {
            instituteId:[],
            programType: [],
            programMode:[],
            courseEligibility:[],
            departId:[],
            subDepartmentId:[],
            programDuration:[],
        };
    }
}

export default ProgramLisingService;
