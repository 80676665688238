class MenuBuilderServiceAlumni {

    static buildAlumniMenu(arr: any[], sessionState: any) {
        // const isPeerReviewed = sessionStorage.getItem('is-peer-reviewed') == 'true' ? true : false;
        const isPeerReviewed = sessionState.isPeerReviewed;
        const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
        var i: number;
        var j: number;
        if (isPeerReviewed != true && isPersonate != true) {
            for (i = 0; i < arr.length; i++) {
                for (j = 0; j < arr[i]?.children?.length; j++) {
                    arr[i].children[j].path = 'alumni/welcome';
                }
            }
        }
        return arr;
    }

    static buildStudentMenu(arr: any[]) {
        const userStatusCode = sessionStorage.getItem('user-profile-status-code');
        const userType = sessionStorage.getItem('user-type-code');
        const userId = sessionStorage.getItem('user-id');
        if (userType == 'STUDENT') {
            var userData: any = sessionStorage.getItem('studentProfile-' + userId);
            userData = JSON.parse(userData);
            const classCode: any = userData?.classCode;

            if (classCode == 12) {
                arr[arr.length - 1].children = arr[arr.length - 1].children.filter(
                    (item: { id: string; label: string; path: string }) => item.id !== 'pbp'
                );
            }

            if (classCode >= 8 && classCode <= 10) {
                arr[arr.length - 1].children = arr[arr.length - 1].children.filter(
                    (item: { id: string; label: string; path: string }) => item.id !== 'cagp'
                );
            }
        }

        var i: number;
        var j: number;
        if (userStatusCode == 'PREFERENCES_INCOMPLETE') {
            for (i = 0; i < arr.length; i++) {
                for (j = 0; j < arr[i]?.children?.length; j++) {
                    arr[i].children[j].path = `/student/${userId}/student-preference`;
                }
            }
        }
        return arr;
    }
}

export default MenuBuilderServiceAlumni;
