import React, { useEffect, useRef, useState } from 'react';

interface TextareaWithCursorManagementProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>, index: number, value: string) => void;
    index: number;
    className?: string;
    placeholder?: string;
    name: string;
    id: string;
    rows?: number;
}

const TextareaWithCursorManagement: React.FC<TextareaWithCursorManagementProps> = ({
    value,
    onChange,
    index,
    className,
    placeholder,
    name,
    id,
    rows = 3, // Default value if not provided
}) => {
    const [text, setText] = useState<string>(value);
    const [cursorPosition, setCursorPosition] = useState<number | null>(null);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        setText(value);
    }, [value]);

    useEffect(() => {
        if (cursorPosition !== null && textareaRef.current) {
            textareaRef.current.selectionStart = textareaRef.current.selectionEnd = cursorPosition;
        }
    }, [text, cursorPosition]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value: newValue, selectionStart } = event.target;
        setText(newValue);
        if (selectionStart !== null) {
            setCursorPosition(selectionStart);
        }
        onChange(event, index, newValue);
    };

    return (
        <textarea
            ref={textareaRef}
            value={text}
            onChange={handleChange}
            className={className}
            placeholder={placeholder}
            name={name}
            id={id}
            rows={rows}
        />
    );
};

export default TextareaWithCursorManagement;
