import { activateUserSession, isValidBusinessObject } from '../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    if (
        !isImpersonatedSession &&
        state.data.educationPreferenceInfo.doYouRequireApplicationSupportForUgAdmission === true &&
        !customValidationPassed
    ) {
        alert('Please correct the form errors!');
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state.formValidationErrors);
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            educationPreferenceInfo: state?.data['educationPreferenceInfo'],
            educationCountryPreferences: state?.data['educationCountryPreferences'],
            educationFieldOfStudyPreferences: state?.data['educationFieldOfStudyPreferences'],
            educationUniversityPreferences: state?.data['educationUniversityPreferences'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            // Call the API here to update the status as ACTIVE & COMPLETE.
            activateUserSession(sessionState, sessionDispatch).then(() => {
                state.actions['REDIRECT'](`/alumni/${state.routeInfo.id}/profile`);
            });
        }
    });
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.HIGHER_EDUCATION_PREFERENCE, state, dispatch);
};

export const isFormValidBasedOnCustomValidation = (state: State, dispatch: any) => {
    let isFormValid = true;
    isFormValid = isValidBusinessObject(
        state?.data['educationCountryPreferences'],
        [
            { id: 'countryCode', label: 'Preferred Location of Study' },
            { id: 'countryPreferenceCode', label: 'Country Preferences' },
        ],
        { id: 'countryCode', label: 'Preferred Location of Study' },
        'educationCountryPreferences',
        dispatch
    );

    isFormValid = isValidBusinessObject(
        state?.data['educationFieldOfStudyPreferences'],
        [
            { id: 'fieldOfStudyCode', label: 'Course of Study' },
            { id: 'fieldOfStudyPreferenceCode', label: 'Course Preferences' },
        ],
        { id: 'countryCode', label: 'Preferred Location of Study' },
        'educationFieldOfStudyPreferences',
        dispatch
    );

    return isFormValid;
};
