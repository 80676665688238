import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/css_pre_singup/style.css';
import { useParams, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';

const VerifyProgress: React.FC = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState } = useContext(SessionContext);

    const schoolImage = localStorage.getItem('school-image') || sessionStorage.getItem('school-image');
    const instName = localStorage.getItem('school-name') || sessionStorage.getItem('school-name');

    const { schoolDomain } = useParams<{ schoolDomain: string }>();
    const lead_ref_id = localStorage.getItem('lead_ref_id') || sessionStorage.getItem('lead_ref_id');
    const location = useLocation();
 
    const queryParams = new URLSearchParams(location.search);
    const referalInsertId = queryParams.get('referalInsertId');
    const referalId = queryParams.get('referalId');

    const [relationship, setRelationship] = useState<string | null>(null);
    const [apiMessage, setApiMessage] = useState<{ text: string; isSuccess: boolean }>({ text: '', isSuccess: false });

    const baseUrl = window.location.origin;
    let referLink = baseUrl + '/reference/' + schoolDomain + '/users-track?user=' + lead_ref_id;
    //alert(schoolImage);
    const validateForm = (): boolean => {
        if (!relationship) {
            setApiMessage({ text: 'Please select any one.', isSuccess: false });
            return false;
        }
        return true;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
                try {
                    const response = await axios.post(
                        `${process.env.REACT_APP_BROADCAST_SERVER}/referral/add-track`,
                        { 
                            id: lead_ref_id, 
                            track:relationship
                        },
                   
                );

                window.location.href = `/reference/${schoolDomain}/users-track`;                
                    
                } catch (error) {
                    setApiMessage({ text: 'An error occurred. Please try again.', isSuccess: false });                
            }
        }
    };

    return (
        <main className="wrapper">
            <section className="dark-section student-img d-flex">
                <div className="container d-flex flex-column">
                    <div className="logo">
                        <a href="#" className="h-100">
                            <img src={schoolImage || 'images/logo.svg'} className="img-fluid" alt="logo" />
                        </a>
                    </div>
                    <div className="flex-1 py-5 d-flex flex-column justify-content-center align-items-center text-white py-5">
                        <div className="id-title text-center">Thank you!</div>
                        <div className="col-12 text-center font-16 font-500">
                            Wish to get updates about the progress of your referred candidate, and earn some rewards?
                        </div>
                        <div className="pb-5">
                            <form className="min-height-250 max-650 mx-auto" onSubmit={handleSubmit}>
                                <div className="row g-4 align-items-end">
                                    <div className="col-12">
                                        <div className="row g-4">
                                            <div className="col-auto">
                                                <div className="form-check p-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="IDENTIFY"
                                                        id="Friend"
                                                        value="1"
                                                        onChange={(e) => setRelationship(e.target.value)}
                                                    />
                                                    <label className="form-check-label" htmlFor="Friend">
                                                        <i></i>
                                                        <span>Yes</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check p-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="IDENTIFY"
                                                        id="Relative"
                                                        value="0"
                                                        onChange={(e) => setRelationship(e.target.value)}
                                                    />
                                                    <label className="form-check-label" htmlFor="Relative">
                                                        <i></i>
                                                        <span>No</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button type="submit" className="btn-green height-45 px-5 mx-auto">
                                            <span>Submit</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            {apiMessage.text && (
                                <div className={`alert ${apiMessage.isSuccess ? 'alert-success' : 'alert-danger'} mt-4`}>
                                    {apiMessage.text}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default VerifyProgress;
