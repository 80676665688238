import React, { useContext, useState, useEffect, useCallback, ChangeEvent, FormEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import Select, { MultiValue } from 'react-select';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import DebugBarControl from '../../library/SimpleControls/DebugBarControl';
import SessionContext from '../../library/Core/SessionContext';
import { updateMoEngageEvent } from '../../library/Core/SmartFunctions';

type TagOption = { value: string; label: string };

const AskQuestion: React.FC = () => {
    const { ids } = useParams<{ ids: string }>();
    const navigate = useNavigate();
    const { sessionState } = useContext(SessionContext);
    const userId = sessionState?.studentId ?? sessionState?.userId;

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        internal: { messagesFromCode: null, messageTypeCode: null, filteredMessages: [], searchText: null },
        actions: { ROUTER_NAVIGATE: navigate },
    } as any);

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        tags: [] as string[] 
    });

    const [tagsOptions, setTagsOptions] = useState<TagOption[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [tagError, setTagError] = useState<string | null>(null);

    useEffect(() => {
        fetchTags();
    }, []);

    const fetchTags = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_QNA_API}v1/question/fetchtags`);

            if (!response.ok) {
                throw new Error('Failed to fetch tags');
            }

            const responseData = await response.json();

            if (responseData && Array.isArray(responseData.data)) {
                const options = responseData.data.map((tag: { code: string; value: string }) => ({
                    value: tag.value,
                    label: tag.value
                }));
                setTagsOptions(options);
            } else {
                throw new Error('Unexpected data format: data array not found');
            }
        } catch (error) {
            console.error('Error fetching tags:', error);
            setError('Failed to load tags.');
        }
    }, []);

    const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
        
        // Clear error when user starts typing
        if (name === 'description' && error) {
            setError(null);
        }
    }, [error]);
    
    const handleTagsChange = useCallback((selectedOptions: MultiValue<TagOption>) => {
        if (selectedOptions.length > 5) {
            setTagError('You can select up to 5 tags only.');
            return;
        }
        setTagError(null);
        const tagsArray = selectedOptions.map(option => option.value);
        setFormData(prevFormData => ({
            ...prevFormData,
            tags: tagsArray
        }));
    
        // Clear tag error when user selects tags
        if (tagError) {
            setTagError(null);
        }
    }, [tagError]);    

    const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);
        setTagError(null); // Clear tag error if there was any
    
        // Validation
        if (!formData.description.trim()) {
            setError('Description is required.');
            return;
        }
        if (formData.tags.length === 0) {
            setTagError('At least one tag is required.');
            return;
        }
    
        const data = {
            user_id: userId, 
            title: formData.title,
            description: formData.description,
            tags: formData.tags.join(', ')
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_QNA_API}v1/question/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
    
            if (response.ok) {
                updateMoEngageEvent(data,'moengage-student-question-qna');
                // Clear form data on successful submission
                setFormData({
                    title: '',
                    description: '',
                    tags: []
                });
                navigate('/qna-dashboard');
            } else {
                const result = await response.json();
                setError(`Failed to submit question: ${result.message}`);
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred. Please try again later.');
        }
    }, [formData, navigate, userId]);
    

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <div className="main flex-1 bg-white">
                <section className="updateadditionalinformation">
                    <div className="max-1010 px-lg-0 py-4 my-2 mx-auto">
                        <div className="alumni_statistics">
                            <div className="d-flex mb-1">
                                <button className="btn btn-back text-dark-blue p-0" onClick={() => navigate('/qna-dashboard')}>
                                    <i className="icon-left-arrow me-1"></i>
                                    <span>Back to All Queries</span>
                                </button>
                            </div>
                            <div className="ActiveListings_present">
                                <form onSubmit={handleSubmit}>
                                    <div className="row g-2">
                                        <div className="col-12">
                                            <label htmlFor="title" className="form-label font-35 font-600 pt-3">
                                                Ask Your Queries to Alumni to Gain Clear Insights for Future
                                            </label>
                                            <textarea
                                                className="form-control h-auto"
                                                id="description"
                                                name="description"
                                                rows={6}
                                                value={formData.description}
                                                onChange={handleChange}
                                            ></textarea>
                                        </div>
                                        <div className="col-12">
                                            <div className="row g-3 align-items-end">
                                                <div className="col-lg-6 col-md-6 col-sm-5">
                                                    <label htmlFor="tags" className="form-label font-16 font-500 pt-3">
                                                        Add Tags
                                                    </label>
                                                    <Select
                                                        isMulti
                                                        name="tags"
                                                        options={tagsOptions}
                                                        className="basic-multi-select custom-multi-select"
                                                        classNamePrefix="select"
                                                        value={tagsOptions.filter(tag => formData.tags.includes(tag.value))}
                                                        onChange={handleTagsChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center d-flex justify-content-center pt-4">
                                            <button type="submit" className="btn btn-green">
                                                <span>Submit Question</span>
                                            </button>
                                        </div>
                                    </div>
                                    {error && <div className="error-message">{error}</div>}
                                    {tagError && <div className="error-message">{tagError}</div>}

                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <DebugBarControl />
        </SmartContext.Provider>
    );
};

export default AskQuestion;
