import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { getStudentImage } from '../../common/styles/Images';
import { SmartContext } from '../../library/Core/SmartContext';
import { getDateDDMMYYYYHHMM, isEmpty } from '../../library/Core/SmartFunctions';
import { DomainElement } from '../../library/Core/SmartTypes';
import useMessages from './useMessages';
import AsyncSelect from 'react-select/async';
import { axiosClient } from '../../library/Service/axiosClient';

const NewMessage = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { createNewConversation, sendMessage, getUserInfo, resetNewMessageMode, closeNewMessageWindow, refreshConversations } =
        useMessages();
    const [memberIds, setMembers] = useState([] as any[]);
    let defaultOptions = [] as any[];
    const [newMessage, setNewMessage] = useState('');
    const conversationId = state.data?.selectedConversationId;
    const messages = conversationId === 'NEW' ? [] : state.data?.selectedConversation;
    const [loading, setLoading] = useState(false);
    const [customHeight, setCustomHeight] = useState('45px');
    const [errorMessage, setErrorMessage] = useState('');

    // const controlDomain = state?.domain?.get('MAIL_TO_USER_LIST') as DomainElement[];
    // const options: any = controlDomain.map((domain) => ({ ...domain, value: domain.code, label: domain.value }));

    const handleOnChange = (selectedOptions: any) => {
        setMembers(selectedOptions);
        setCustomHeight(`${45 + selectedOptions.length * 0.6 * 40}px`);
    };

    useEffect(() => {
        const { ids, newMessageMode } = state?.routeInfo;

        const getUserDataById = async (ids: any) => {
            setLoading(true);
            try {
                const response = await axiosClient().get(
                    `${process.env.REACT_APP_REPORTS_SERVER}/common/list-for-name-search?userId=${ids}`
                );
                const userData = response?.data?.students || [];
                //setDefaultOptions
                defaultOptions = userData.map((option: any) => ({
                    value: option.uuid,
                    label: option.firstName + ` ` + option.lastName,
                    uuid: option.uuid,
                    firstName: option.firstName,
                    passOutYear: option.passOutYear,
                    lastName: option.lastName,
                    name: option.lastName,
                    profilePictureFilePath: option.profilePictureFilePath,
                    mobile: option.mobile,
                    registeredEmailId: option.registeredEmailId,
                    universityId: option.universityId,
                    companyName: option.companyName,
                    profileImage: option.profileImage,
                    userTypeCode: option.userTypeCode,
                    programName: option.programName,
                    programId: option.programId,
                    designation: option.designation,
                    schoolName: option.schoolName,
                }));
                setMembers(defaultOptions); // Set the fetched users as pre-selected options
                console.log('defaultOptions');
                console.log(defaultOptions);
            } catch (error) {
                console.log('error in api');
            } finally {
                // load the data
                setLoading(false);
            }
        };

        if (ids && ids.length > 0) {
            getUserDataById(ids);
        }
        // const userInfo: any = options.find((option: any) => option.uuid === id);
        // const userInfo: any = options.filter((option: any) => ids?.includes(option.uuid));

        // if (userInfo.length > 0) {
        //     setMembers(userInfo);
        // setLoading(false);
        //     resetNewMessageMode();
        // } else {
        //     setLoading(false);
        // }
    }, []);

    useEffect(() => {
        if (memberIds.length > 0) setErrorMessage('');
    }, [memberIds]);

    const handleSendMessage = async () => {
        if (memberIds.length === 0) {
            setErrorMessage('Please select at least one user to send message');
            return;
        }

        if (newMessage.trim() === '') return;

        setErrorMessage('');

        let result;
        if (conversationId === 'NEW') {
            result = await createNewConversation(
                newMessage,
                memberIds.map((member: any) => member.value),
                [...new Set(memberIds.map((member: any) => member.userTypeCode))]
            );
        } else {
            result = await sendMessage(newMessage);
        }

        if (result) {
            setNewMessage('');
            closeNewMessageWindow();
            if (state?.routeInfo?.ids?.length > 0) dispatch({ type: 'ROUTE_INFO', payload: { ...state?.routeInfo, ids: [] } });
            setTimeout(() => refreshConversations(), 1000);
        }
    };

    const handleNewMessageChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            height: customHeight,
            overflow: 'auto', // Make sure the overflow is set to 'auto' or 'visible'
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: customHeight,
            padding: '0 6px',
        }),
        input: (provided: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            height: customHeight,
        }),
        option: (provided: any, controlState: any) => ({
            ...provided,
            color: controlState.isSelected ? 'text-white' : provided.color,
            backgroundColor: controlState.isSelected ? 'bg-primary' : provided.backgroundColor,
        }),
    };

    const loadOptions = async (inputValue: string) => {
        if (isEmpty(inputValue) || inputValue.length <= 2) {
            return [];
        }
        try {
            const response = await axiosClient().get(
                `${process.env.REACT_APP_REPORTS_SERVER}/common/list-for-name-search?term=${inputValue}`
            );
            const userData = response.data?.students || [];
            // Assuming your API response contains an array of options
            return userData.map((option: any) => ({
                value: option.uuid,
                label: option.firstName + ` ` + option.lastName,
                uuid: option.uuid,
                firstName: option.firstName,
                passOutYear: option.passOutYear,
                lastName: option.lastName,
                name: option.lastName,
                profilePictureFilePath: option.profilePictureFilePath,
                mobile: option.mobile,
                registeredEmailId: option.registeredEmailId,
                universityId: option.universityId,
                companyName: option.companyName,
                profileImage: option.profileImage,
                userTypeCode: option.userTypeCode,
                programName: option.programName,
                programId: option.programId,
                designation: option.designation,
                schoolName: option.schoolName,
            }));
        } catch (error) {
            console.error('Error fetching options:', error);
            return [];
        }
    };

    const CustomOption = (props: any) => {
        const { data } = props;
        var designation = data?.designation;
        var schoolName = data?.schoolName;
        return (
            <components.Option {...props}>
                <p
                    key={data.id}
                    className="university_ttl d-flex flex-wrap align-items-center font-16 line-height-18 font-500 text-dark-blue mb-0 pb-3 pb-sm-2 one">
                    <span className="me-3">
                        <img
                            src={getStudentImage({ profilePictureFilePath: data.profilePictureFilePath, genderCode: data.genderCode })}
                            alt=""
                            className="rounded-circle"
                            style={{ width: '32px', height: '32px' }}
                        />
                    </span>
                    <span className="flex-1">
                        {`${data.firstName ? data.firstName : ''}${data.lastName ? ' ' + data.lastName : ''}`}
                        {data.userTypeCode != 'SCHOOL_ADMIN' && ', '}
                        {data.userTypeCode != 'SCHOOL_ADMIN' && (
                            <span className="fst-italic">
                                {/* {data.passOutYear ? 'Class of ' + data.passOutYear : ''}
                                {data?.campusName ? ', ' + data?.campusName : ''} */}
                                {data?.programName ? '' + data?.programName : ''}
                                {data?.passOutYear ? ', ' + data?.passOutYear : ''}
                            </span>
                        )}
                        {data.userTypeCode == 'SCHOOL_ADMIN' &&
                            designation != null &&
                            schoolName != null &&
                            ', ' + designation + ' at ' + schoolName}
                        {data.userTypeCode == 'SCHOOL_ADMIN' && designation == null && schoolName != null && ', ' + schoolName}
                        {data.userTypeCode == 'SCHOOL_ADMIN' && designation != null && schoolName == null && ', ' + designation}
                    </span>
                </p>
            </components.Option>
        );
    };

    const CustomMultiValueLabel = (props: any) => {
        const { data } = props;
        var designation = data?.designation;
        var schoolName = data?.schoolName;
        return (
            <div key={data.id} className="university_ttl">
                <span className="me-3">
                    <img
                        src={getStudentImage({ profilePictureFilePath: data.profilePictureFilePath, genderCode: data.genderCode })}
                        alt=""
                        className="rounded-circle"
                        style={{ width: '32px', height: '32px' }}
                    />
                </span>
                <span className="flex-1">
                    {`${data.firstName ? data.firstName : ''}${data.lastName ? ' ' + data.lastName : ''}`}
                    {data.userTypeCode != 'SCHOOL_ADMIN' && ', '}
                    {data.userTypeCode != 'SCHOOL_ADMIN' && (
                        <span className="fst-italic">
                            {/* {data.passOutYear ? 'Class of ' + data.passOutYear : ''}
                            {data?.campusName ? ', ' + data?.campusName : ''} */}
                            {data.programName ? '' + data.programName : ''}
                            {data.schoolName ? ', ' + data.schoolName : ''}
                        </span>
                    )}
                    {data.userTypeCode == 'SCHOOL_ADMIN' &&
                        designation != null &&
                        schoolName != null &&
                        ', ' + designation + ' at ' + schoolName}
                    {data.userTypeCode == 'SCHOOL_ADMIN' && designation == null && schoolName != null && ', ' + schoolName}
                    {data.userTypeCode == 'SCHOOL_ADMIN' && designation != null && schoolName == null && ', ' + designation}
                </span>
            </div>
        );
    };

    if (loading) {
        return <p>Loading...</p>; // Or replace with your custom loading component
    }

    return (
        <div id="New_Message" style={{ display: 'block' }}>
            <div className="msgbox position-relative">
                <div className="search_ttlblk px-4 pb-3">
                    <p className="font-16 font-500 text-black line-height-18 mb-1">Search for a user to start a new conversation</p>
                    {/* <Select
                        isMulti
                        name="colors"
                        onChange={handleOnChange}
                        options={options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        components={{
                            DropdownIndicator: null,
                            IndicatorSeparator: null,
                            Option: CustomOption,
                            MultiValueLabel: CustomMultiValueLabel,
                        }}
                        defaultValue={memberIds}
                        placeholder={`Search User by Name, Email, Contact Number`}
                        styles={customStyles}
                    /> */}
                    <AsyncSelect
                        isMulti
                        isClearable={false}
                        //components={{ MultiValueRemove }}
                        //components={{ Option: CustomOption }}
                        onChange={handleOnChange}
                        loadOptions={loadOptions} // Use the loadOptions function to fetch options dynamically
                        components={{
                            DropdownIndicator: null,
                            IndicatorSeparator: null,
                            Option: CustomOption,
                            MultiValueLabel: CustomMultiValueLabel,
                        }}
                        placeholder={`Search User by Name, Email, Contact Number`}
                        styles={customStyles}
                        defaultValue={memberIds}
                        defaultOptions={defaultOptions}
                    />
                    {errorMessage.length > 0 && <div className="text-danger">{errorMessage}</div>}
                </div>
                <div className="search_btmshadow"></div>

                <div className="search_cont px-4 pt-3">
                    {messages.map((message: any, index: number) => {
                        const dateStr = moment(message.sentDate).format('YYYY-MM-DD');
                        const isLastMessageOfDay = messages
                            .slice(index + 1)
                            .every((nextMsg: any) => moment(nextMsg.sentDate).format('YYYY-MM-DD') !== dateStr);

                        return (
                            <div key={message.id} className={message.isFromCurrentUser ? 'profile_person' : 'non-profile_person'}>
                                <p>{message.message}</p>
                                {isLastMessageOfDay && <p className="date">{getDateDDMMYYYYHHMM(message.sentDate)}</p>}
                            </div>
                        );
                    })}
                </div>

                <div className="msgbox_send position-absolute">
                    <div className="position-relative">
                        <input
                            type="text"
                            className="form-control flex-1"
                            placeholder="Write a message"
                            onChange={(e) => {
                                setNewMessage(e.target.value);
                            }}
                            onKeyUp={handleNewMessageChange}
                            value={newMessage}
                        />
                        <button type="button" className="btn btn-complete-now msgsendbtn  text-white" onClick={handleSendMessage}>
                            <span>Send</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewMessage;
