import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import { handleCompleteLater, handleNext, onScoreChangeValidateRank, onScoreTypeCodeChange } from './EntranceTestActions';
import EntranceTestLayout from './EntranceTestLayout';
import SessionContext from '../../../../library/Core/SessionContext';
import useCustomNavigate from '../../../../common/hooks/useCustomNavigate';

const EntranceTest = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);
    const [redirectUrl, setRedirectUrl] = useState('');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            NEXT: handleNext,
            I_LL_DO_THIS_LATER: handleCompleteLater,
            ROUTER_NAVIGATE: navigate,
            score: onScoreChangeValidateRank,
            scoreTypeCode: onScoreTypeCodeChange,
            // USE_STATE: setUpdateUserStatus,
            REDIRECT: setRedirectUrl
        },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);

        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);
    
    useEffect(() => {
        navigate(redirectUrl);
    }, [redirectUrl, navigate, sessionState.id]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <EntranceTestLayout>{!state.flags.isDataLoading && <PageBuilder />}</EntranceTestLayout>
        </SmartContext.Provider>
    );
};

export default EntranceTest;
