import moment from 'moment';
import { useContext, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getDomainValueForCode, isSchoolAdmin, isSuperAdmin } from '../../../library/Core/SmartFunctions';
import JobListingService from './JobListingService';
import { Modal } from 'bootstrap';
import SessionContext from '../../../library/Core/SessionContext';

const JobCardsControl = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    const jobs = state?.data?.filteredData;
    const [popupVisible, setPopupVisible] = useState(false);
    const [jobDescription, setJobDescription] = useState('');
    const id = sessionState?.id;

    const handleSavePreference = async (params: any) => {
        const isSaved = await JobListingService.savePreference(params);
        if (isSaved) {
            const jobs = state?.data?.filteredData;
            let modifiedJobs = jobs.map((job: any) => {
                let modifiedJob = { ...job };
                if (job.id == params.jobId) {
                    modifiedJob.isInterested = params.isInterested;
                    modifiedJob.isSaved = params.isSaved;
                }
                return modifiedJob;
            });

            dispatch({
                type: 'SET_FILTERED_DATA',
                payload: modifiedJobs,
            });
        }
    };

    const handleViewJobDescription = (description: string) => {
        setJobDescription(description);
        setPopupVisible(true);
        const modalElement = document.getElementById('jobDescriptionModal');
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }
        // const modal = new Modal(document.getElementById('jobDescriptionModal'));
        // modal.show();
    };

    const handleSendMessage = (event: any, id: string) => {
        event.preventDefault();
        state.actions['ROUTER_NAVIGATE'](`/messages/send-message/${id}`);
    };
    console.log(jobs, ' jobs ');
    return (
        <>
            <div
                className="flex-1 polarcontsec tab-content aos-init aos-animate"
                id="myTabContent"
                data-aos="fade-left"
                data-aos-delay="900">
                <div className="tab-pane fade show active" id="JobListing" role="tabpanel" aria-labelledby="JobListing-tab">
                    <div className="JobListing_present">
                        {jobs?.length > 0 && (
                            <div id="Activehtml">
                                <h3 className="font-18 font-500 mb-3 d-none d-md-block">{jobs?.length} Active Listings</h3>

                                {jobs.map((job: any, index: number) => (
                                    <div key={job.id} className="act_lst mb-3 position-relative">
                                        <span className="list_badge font-14 font-500">{job.jobTypeCode}</span>
                                        <div className="row m-0 d-flex flex-wrap justify-content-between align-items-end postedrow">
                                            <div className="flex-1 ps-0">
                                                <h4 className="font-20 font-500 mb-2">
                                                    {job.title} @ {job.companyName}
                                                </h4>
                                                <div className="d-flex flex-wrap exp_loc">
                                                    <a href="javascript:void(0)" className="btn btnicon btn-icon-bg">
                                                        <span className="icon-brife-case font-12 text-dark-blue icon"></span>
                                                        <span>
                                                            {' '}
                                                            {job.minExperience} - {job.maxExperience} Year Experience
                                                        </span>
                                                    </a>
                                                    <a href="javascript:void(0)" className="btn btnicon btn-icon-bg">
                                                        <span className="icon-location-pin font-14 text-dark-blue icon"></span>
                                                        <span>{getDomainValueForCode(job.cityCode, 'CITY_CODE', state)}</span>
                                                    </a>
                                                    <a
                                                        role="button"
                                                        className="btn btnicon btn-icon-bg"
                                                        onClick={() => handleViewJobDescription(job.description)}>
                                                        <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/file_icon.png`} />
                                                        <span>View Job Description</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="px-0 text-end">
                                                <p className="font-14 font-500 text-dark-blue mb-0 mb-sm-3 postedtxt">
                                                    Posted{' '}
                                                    {moment().diff(job.createdAt, 'days') > 0
                                                        ? moment().diff(job.createdAt, 'days') + 'days ago'
                                                        : 'today'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row m-0 d-flex flex-wrap justify-content-between align-items-end">
                                            <div className="flex-1 ps-0">
                                                <a href="javascript:void(0)" className="d-flex align-items-start person_detls text-decoration-none">
                                                    {/* <img
                                                src="https://s3.ap-south-1.amazonaws.com/media.univariety.com/uploads/alumni/profile/20230215_004656_.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&amp;X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Credential=AKIAQ4A7CWXKQ2FVEBPU%2F20230415%2Fap-south-1%2Fs3%2Faws4_request&amp;X-Amz-Date=20230415T063831Z&amp;X-Amz-SignedHeaders=host&amp;X-Amz-Expires=600&amp;X-Amz-Signature=b1553bca620f24f25cdd26b90967e7cb745ec3dce7a46a1d6cfd7e6527375499"
                                                className="rounded-circle me-2"
                                                width="40"
                                                height="40"
                                            /> */}
                                                    <div className="lstperdetails text-black">
                                                        <p className="m-0 font-14 font-500 line-height-18">
                                                            <span className="font-16">{`${job.firstName} ${job.lastName}`}</span>,{' '}
                                                            {/* <em>Class of {job.passOutYear}</em> */}
                                                            <em>{job.programName}</em>
                                                        </p>
                                                        <p className="m-0 font-14 font-500 line-height-18">
                                                            {/* <em>Running own professional practice</em> */}
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="pe-0 text-end">
                                                <div className="d-flex flex-wrap justify-content-end">
                                                    {job.studentId != sessionState?.id && !isSchoolAdmin() && sessionState?.userTypeCode!=='SUPER_ADMIN' &&(
                                                        <a
                                                            className={`btn btnicon listblk_icon me5 circle-effect ${
                                                                job.isSaved ? ' btn-dark-blue ' : ''
                                                            }}`}
                                                            id="MakeSave_42"
                                                            role="button"
                                                            onClick={() => handleSavePreference({ jobId: job.id, isSaved: ! job.isSaved, isInterested: job.isInterested })}>
                                                            <span
                                                                className={`icon-ribbon-invert font-18 icon ${job.isSaved ? 'text-white' : ''}`}
                                                                id="Icon_42"></span>
                                                        </a>
                                                    )}
                                                    
                                                    {!job.isInterested && job.studentId != sessionState?.id && !isSchoolAdmin() && sessionState?.userTypeCode!=='SUPER_ADMIN' && (
                                                        <a
                                                            className={`btn ${
                                                                job.isInterested ? 'btn-dark-blue' : 'btn-green'
                                                            } height-40 d-inline-flex text-white font-500 font-16 text-decoration-none justify-content-center`}
                                                            id="MakeInterest_42"
                                                            role="button"
                                                            onClick={() => handleSavePreference({ jobId: job.id, isInterested: 1, isSaved: job.isSaved })}>
                                                            <span id="Interest_Icon_42">Send Interest</span>
                                                        </a>
                                                    )}

                                                    {job.isInterested && job.studentId != sessionState?.id && !isSchoolAdmin() && sessionState?.userTypeCode!=='SUPER_ADMIN' && (
                                                        <a
                                                            className={`btn btn-dark-blue height-40 d-inline-flex text-white font-500 font-16 text-decoration-none justify-content-center`}
                                                            id="MakeInterest_42"
                                                            role="button"
                                                            onClick={(event) => handleSendMessage(event, job.studentId)}>
                                                            <span id="Interest_Icon_42">Follow - Up</span>
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {jobs.length === 0 && (
                            <div className="no_listed_items_blk">
                                <div className="mt-0">
                                    <div className="max-470 mx-auto">
                                        <img
                                            className="mt-2 mb-4 float_horizontal_animate"
                                            src={`${process.env.REACT_APP_IMAGE_BASEPATH}/no_messages.svg`}
                                        />
                                        <p className="font-18 font-400 mb-4 text-center">Sorry! No jobs found.</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="jobDescriptionModal"
                tabIndex={-1}
                aria-labelledby="jobDescriptionModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="jobDescriptionModalLabel">
                                Job Description
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setPopupVisible(false)}></button>
                        </div>
                        <div className="modal-body">
                            <p>{jobDescription}</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                onClick={() => setPopupVisible(false)}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default JobCardsControl;
