import { useContext } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { SimpleFormControlArguments } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';
import SelectControl from './SelectControl';

const YearRangePicker = (props: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = props;

    // let startYearValue = getControlValueFromState(
    //     props.parentDataKey + '.' + props.control.controlGroup[0]['id'],
    //     state as State
    // ) as string;

    // let endYearValue = getControlValueFromState(props.parentDataKey + '.' + props.control.controlGroup[1]['id'], state as State) as string;

    return (
        <>
            <label htmlFor={control.id} className="form-label m-0 mb-1 font-16 font-500 w-100">
                {`${control.props.label} `}
            </label>
            <div className="input-group flex-nowrap">
                <span className="input-group-text" id="basic-addon1">
                    Start
                </span>
                <SelectControl
                    control={control.controlGroup[0]}
                    dataKey={(parentDataKey as string) + '.' + control.controlGroup[0].id}
                    parentDataKey={parentDataKey as string}
                    handleChangeEvent={undefined}
                />
                <span className="input-group-text" id="basic-addon1">
                    End
                </span>
                <SelectControl
                    control={control.controlGroup[1]}
                    dataKey={(parentDataKey as string) + '.' + control.controlGroup[1].id}
                    parentDataKey={parentDataKey as string}
                    handleChangeEvent={undefined}
                />
            </div>
            <ErrorControl errorMessages={state?.formValidationErrors[`${parentDataKey}.${control.id}`]} />
        </>
    );
};

export default YearRangePicker;
