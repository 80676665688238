import React, { useContext, useState } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState } from '../Core/SmartFunctions';
import { DomainElement, SimpleFormControlArguments, State } from '../Core/SmartTypes';
import logger from '../Core/logger';

const SearchAsYouTypeInput = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey } = { ...args };
    const [showDropDown, setShowDropDown] = useState(false);
    const [filteredDomain, setFilteredDomain] = useState([] as DomainElement[]);
    const data = getControlValueFromState(dataKey, state as State);

    let controlDomain = state?.domain?.get(control.props.domainCategoryCode) as DomainElement[];

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowDropDown(true);
        if (event.target.value.length <= 3) return;
        setFilteredDomain(
            (state?.domain?.get(control.props.domainCategoryCode) as DomainElement[]).filter((domain) =>
                domain.value.includes(event.target.value)
            )
        );
    };

    const handleBlur = () => {
        setShowDropDown(false);
    };

    const handleDropDownSelect = (code: string, value: string) => {
        logger.log(`Selected code is ${code} & the value is ${value}`);
    };

    return (
        <>
            <label htmlFor={control.id} className="form-label m-0 mb-1 font-16 font-500 w-100">
                {`${control.props.label} `}
            </label>
            <div className="input-group col-md-12">
                <span className="input-group-text" id="basic-addon1">
                    <i className="bi bi-search fs-6"></i>
                </span>
                <input
                    type="text"
                    className="form-control "
                    aria-label="Text input with checkbox"
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </div>
            {showDropDown && (
                <select className="form-select" size={3} aria-label="size 3 select example">
                    {filteredDomain.map((domain) => (
                        <option
                            key={domain.code}
                            value={domain.code}
                            defaultValue={data}
                            onClick={() => handleDropDownSelect(domain.code, domain.value)}>
                            {domain.value}
                        </option>
                    ))}
                </select>
            )}
        </>
    );
};

export default SearchAsYouTypeInput;
