import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import AdminHeaderControl from '../../features/admin/_common/HeaderControl';
import PageFooterControl from '../../library/SimpleControls/PageFooterControl';
import MenuBuilder from '../../routes/MenuBuilder';
import ErrorPage from '../../library/SimpleControls/ErrorPage';
import AlumniHeaderControl from '../../features/alumni/_common/HeaderControl';
import SessionContext from '../../library/Core/SessionContext';
import { useContext } from 'react';
import { SessionStateType } from '../../library/Core/SmartTypes';

function EngagementLayout() {
    const handleNavigateToHome = () => {
        // resetErrorBoundary();
        return <ErrorPage />;
    };

    const { sessionState } = useContext(SessionContext);

    const { userTypeCode } = sessionState as SessionStateType;

    return (
        <>
            <header>
                {userTypeCode == 'SUPER_ADMIN' ? <AdminHeaderControl /> : <AlumniHeaderControl/>}
                {/* <MenuLayout /> */}
                <MenuBuilder />
            </header>
            <ErrorBoundary fallbackRender={handleNavigateToHome} onReset={(details) => {}}>
                <Outlet />
            </ErrorBoundary>
            <PageFooterControl />
        </>
    );
}

export default EngagementLayout;
