import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import { DomainElement } from '../../../library/Core/SmartTypes';
import JobListingService from './JobListingService';
interface CollapsedFilters {
    [key: string]: boolean;
}

const JobFiltersControl = () => {
    const { state, dispatch } = useContext(SmartContext);
    const [selectAllJobType, setSelectAllJobType] = useState(false);
    const [selectAllLocationType, setSelectAllLocationType] = useState(false);

    // const handleClearFilters = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    //     event.preventDefault();
    //     // Reset the selectAllJobType state and uncheck
    //     setSelectAllJobType(false);
    //     dispatch({
    //         type: 'REFRESH_DATA',
    //         payload: { data: { ...state.data, filters: JobListingService.filters } },
    //     });
    // };

    const [collapsedFilters, setCollapsedFilters] = useState<CollapsedFilters>({});


    const handleClearFilters = () => {
         setSelectAllJobType(false);
         setSelectAllLocationType(false)
         dispatch({
             type: 'REFRESH_DATA',
             payload: { data: { ...state.data, filters: JobListingService.filters } },
         });   
     };

    const handleSelectAllJobType = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;

        setSelectAllJobType(isChecked);

        if (isChecked) {
            const jobTypeCodes = state.data.pageDomain?.jobTypeCode.map((item: any) => item.code) || [];
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: 'filters.jobTypeCode', value: jobTypeCodes },
            });
        } else {
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: 'filters.jobTypeCode', value: [] },
            });
        }
    };

    const handleSelectAllLocationType = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;

        setSelectAllLocationType(isChecked);

        if (isChecked) {
            const locationCodes = state.data.pageDomain?.cityCode.map((item: any) => item.code) || [];
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: 'filters.cityCode', value: locationCodes },
            });
        } else {
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: 'filters.cityCode', value: [] },
            });
        }
    };

    const changeFilteredData = (filters: any) => {
        let filteredData: any = state.data.data;
        Object.keys(filters).map((key) => {
            if (filters[key].length === 0) return;

            if (key === 'minExperience' || key === 'maxExperience') {
                filteredData = filteredData.filter((item: any) => {
                    // const experience = item.experience; // Replace 'experience' with the actual property name in your data
                    const minExperienceFilter = filters.minExperience;
                    const maxExperienceFilter = filters.maxExperience;

                    // Check if the experience is within the specified range
                    return (
                        (isEmpty(minExperienceFilter) || item.minExperience <= minExperienceFilter) &&
                        (isEmpty(maxExperienceFilter) || item.maxExperience >= maxExperienceFilter)
                    );
                });
            } else {
                filteredData = filteredData.filter((item: any) => filters[key].includes(item[key]));
            }
        });

        dispatch({
            type: 'SET_FILTERED_DATA',
            payload: filteredData,
        });
    };

    useEffect(() => {
        changeFilteredData(state.data.filters);
    }, [state.data.filters]);

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let actionType: string;

        if (event.target.id === 'minExperience' || event.target.id === 'maxExperience') actionType = 'CONTROL_VALUE_CHANGE';
        else actionType = event.target.checked ? 'ADD_NEW_RECORD_IN_ARRAY' : 'DELETE_RECORD_IN_SIMPLE_ARRAY';

        dispatch({
            type: actionType,
            payload: { dataKey: `filters.${event.target.id}`, name: event.target.id, value: event.target.value, errorMessages: [] },
        });

        // changeFilteredData({ ...state.data.filters, [event.target.id]: event.target.value });
    };

    const [isOpen, setOpen] = useState(false);

    const handleFilterToggle = () => {
        setOpen(!isOpen);
    };

    const toggleCollapse = (filterCode:any) => {
        console.log("collapsedFilters",collapsedFilters)
        setCollapsedFilters({
            ...collapsedFilters,
            [filterCode]: !collapsedFilters[filterCode]
        });
    };

    return (
        <div className="col-md-4 ji_filtersec">
            <div className="mobileFilter d-flex justify-content-between align-items-center d-md-none mobileonly text-end mt-3 px-2">
                <h3 className="font-18 font-500 mb-0" id="moblistcount">
                 {state?.data?.filteredData.length} Active Listings
                </h3>
                <button
                    className="btn-filter btn-unscroll font-18 font-500"
                    type="button"
                    onClick={handleFilterToggle}
                    aria-expanded={isOpen ? 'true' : 'false'}
                    aria-controls="filter_collapse">
                    <span className="icon-filter"></span>Filters
                </button>
            </div>
            <div className={`filters collapse ${isOpen ? '' : 'show'}`} id="filter_collapse">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                    <button
                        className="btn-filter btn-unscroll mobileonly"
                        type="button"
                        onClick={handleFilterToggle}
                        aria-expanded={isOpen ? 'true' : 'false'}
                        aria-controls="filter_collapse">
                        Filters
                    </button>
                    <h3 className="font-18 font-500 mb-2 mt-2">
                        <span className="icon-filter"></span>Filters
                    </h3>
                    </div>
                    <a className="font-14 font-500 text-burgandi text-decoration-underline" role="button" onClick={handleClearFilters}>
                    Clear
                    </a>
                </div>

                <div className="filterGroup" id="accordionFilters">
                    <div className="fiterItem mb-3">
                        <div className="filterHeader">
                            <div className="d-flex justify-content-between align-items-center position-relative">
                            <button
                                    className={`btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center ${collapsedFilters["collapseJobType"] ? 'collapsed' : ''}`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    // data-bs-target="#collapseJobType"
                                    // aria-expanded="false"
                                    // aria-controls="collapseJobType">
                                    // <span className="font-18 font-600">Job Type</span>
                                    // <span className="toggle"></span>
                                    onClick={() => toggleCollapse('collapseJobType')} 
                                    aria-expanded={collapsedFilters["collapseJobType"]} 
                                    aria-controls="collapsecollapseJobType">
                                    <span className="font-18 font-600">Job Type</span>
                                    <span className="toggle"></span>
                                </button>
                            </div>
                        </div>
                        <div className={`collapse mt-2 ${collapsedFilters["collapseJobType"] ? '' : 'show'}`} id="collapseJobType">
                            <div className="card card-body">
                                <div className="overflow-hidden">
                                    <div className="scrollMe">
                                        <div className="form-check d-flex flex-wrap SelectAllTypeCheckCount">
                                            <input
                                                className="form-check-input me-2"
                                                type="checkbox"
                                                value=""
                                                id="selectall_type"
                                                checked={selectAllJobType}
                                                onChange={handleSelectAllJobType}
                                            />
                                            <label className="form-check-label font-16 font-400 flex-1 ps-1 pt-1" htmlFor="selectall_type">
                                                Select All<span id="SelectAllTypeCheckCount"></span>
                                            </label>
                                        </div>
                                        <div id="CheckTypeofJob">
                                            {state.data?.pageDomain?.jobTypeCode.map((item: any) => (
                                                <div key={item.code} className="form-check d-flex flex-wrap">
                                                    <input
                                                        className="Typecheck form-check-input me-2"
                                                        type="checkbox"
                                                        value={item.code}
                                                        id="jobTypeCode"
                                                        checked={state.data.filters.jobTypeCode.includes(item.code)}
                                                        onChange={handleFilterChange}
                                                    />
                                                    <label
                                                        className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                        htmlFor="Full-time">
                                                        {
                                                            (
                                                                state.domain
                                                                    ?.get('JOB_TYPE_CODE')
                                                                    ?.find(
                                                                        (childItem: DomainElement) => childItem.code === item.code
                                                                    ) as any
                                                            )?.value
                                                        }{' '}
                                                        <span>({item.recordCount})</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="fiterItem mb-3 aos-init aos-animate" data-aos="fade-right">
                        <div className="filterHeader">
                            <div className="d-flex justify-content-between align-items-center position-relative">
                               <button
                                    className={`btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center ${collapsedFilters["collapseExperience"] ? 'collapsed' : ''}`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    // data-bs-target="#collapseExperience"
                                    // aria-expanded="false"
                                    // aria-controls="collapseExperience">
                                    // <span className="font-18 font-600">Experience</span>
                                    // <span className="toggle"></span>
                                    onClick={() => toggleCollapse('collapseExperience')} 
                                    aria-expanded={collapsedFilters["collapseExperience"]} 
                                    aria-controls="collapsecollapseExperience">
                                    <span className="font-18 font-600">Experience</span>
                                    <span className="toggle"></span>
                                </button>
                            </div>
                        </div>

                        <div className={`collapse ${collapsedFilters["collapseExperience"] ? '' : 'show'}`} id="collapseExperience">
                            <div className="card card-body">
                                <div className="overflow-hidden">
                                    <div className="">
                                        <form name="minmaxlb" id="minmaxlb" className="minmaxlb">
                                            <div className="row mx-0 mb-3 align-items-center">
                                                <div className="col-md-6 ps-0 pe-0 pe-md-2">
                                                    <label htmlFor="maxyears" className="form-label w-100">
                                                        Minumum (years)
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control w-100 digit-input"
                                                        placeholder="Ex: 0"
                                                        id="minExperience"
                                                        min="0"
                                                        max="15"
                                                        onChange={handleFilterChange}
                                                    />
                                                </div>
                                                <div className="col-md-6 pe-0 ps-0 ps-md-2 maxyears-cnt">
                                                    <label htmlFor="minyears" className="form-label w-100">
                                                        Maximum (years)
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control w-100 digit-input"
                                                        placeholder="Ex: 0"
                                                        id="maxExperience"
                                                        min="0"
                                                        max="30"
                                                        onChange={handleFilterChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mx-0">
                                                <button type="button" className="btn btn-submit">
                                                    Search
                                                </button>
                                            </div>
                                        </form>
                                        <div id="experr"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="fiterItem mb-3 aos-init aos-animate" data-aos="fade-right">
                        <div className="filterHeader">
                            <div className="d-flex justify-content-between align-items-center position-relative">
                                <button
                                    className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseLocation"
                                    aria-expanded="false"
                                    aria-controls="collapseLocation">
                                    <span className="font-18 font-600">Minimum Experience</span>
                                    <span className="toggle"></span>
                                </button>
                            </div>
                        </div>
                        <div className="collapse show" id="collapseLocation">
                            <div className="filterSearchblk">
                                <form className="d-flex align-items-center filterSearch position-relative">
                                    <input
                                        className="form-control flex-1 font-14 font-500 w-100"
                                        type="search"
                                        placeholder="Ex: Hyderabad"
                                        aria-label="Search"
                                        id="loc_search"
                                    />
                                    <button className="btn icon-search" type="button"></button>
                                </form>
                            </div>
                            <div className="card card-body mt-2">
                                <div className="overflow-hidden">
                                    <div className="scrollMe">
                                        <div className="form-check d-flex flex-wrap SelectAllLocationCheckCount">
                                            <input className="form-check-input me-2" type="checkbox" value="" id="selectall_location" />
                                            <label
                                                className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                htmlFor="selectall_location">
                                                Select All<span id="SelectAllLocationCheckCount"></span>
                                            </label>
                                        </div>
                                        <div id="CheckTypeofLocation">
                                            {state.data?.pageDomain?.experienceLevelCode.map((item: any) => (
                                                <div key={item.code} className="form-check d-flex flex-wrap Locationcheck">
                                                    <input
                                                        className="jobcheckboxes form-check-input me-2"
                                                        type="checkbox"
                                                        value={item.code}
                                                        id="experienceLevelCode"
                                                        onChange={handleFilterItemSelect}
                                                    />
                                                    <label
                                                        className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                        htmlFor="Hyderabad">
                                                        {
                                                            (
                                                                state.domain
                                                                    ?.get('JOB_EXPERIENCE_LEVEL_CODE')
                                                                    ?.find(
                                                                        (childItem: DomainElement) => childItem.code === item.code
                                                                    ) as any
                                                            )?.value
                                                        }{' '}
                                                        <span>({item.recordCount})</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="fiterItem mb-3 aos-init aos-animate" data-aos="fade-right">
                        <div className="filterHeader">
                            <div className="d-flex justify-content-between align-items-center position-relative">
                            <button
                                    className={`btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center ${collapsedFilters["collapseLocation"] ? 'collapsed' : ''}`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    // data-bs-target="#collapseLocation"
                                    // aria-expanded="false"
                                    // aria-controls="collapseLocation">
                                    // <span className="font-18 font-600">Location</span>
                                    // <span className="toggle"></span>
                                    onClick={() => toggleCollapse('collapseLocation')} 
                                    aria-expanded={collapsedFilters["collapseLocation"]} 
                                    aria-controls="collapsecollapseLocation">
                                    <span className="font-18 font-600">Location</span>
                                    <span className="toggle"></span>
                                </button>
                            </div>
                        </div>
                        <div className={`collapse ${collapsedFilters["collapseLocation"] ? '' : 'show'}`} id="collapseLocation">
                            <div className="filterSearchblk">
                                {/* <form className="d-flex align-items-center filterSearch position-relative">
                                    <input
                                        className="form-control flex-1 font-14 font-500 w-100"
                                        type="search"
                                        placeholder="Ex: Hyderabad"
                                        aria-label="Search"
                                        id="loc_search"
                                    />
                                    <button className="btn icon-search" type="button"></button>
                                </form> */}
                            </div>
                            <div className="card card-body mt-2">
                                <div className="overflow-hidden">
                                    <div className="scrollMe">
                                        <div className="form-check d-flex flex-wrap SelectAllLocationCheckCount">
                                        <input 
                                            className="form-check-input me-2" 
                                            type="checkbox" 
                                            value="" 
                                            id="selectall_location" 
                                            checked={selectAllLocationType}
                                            onChange={handleSelectAllLocationType}
                                            />
                                            <label
                                                className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                htmlFor="selectall_location">
                                                Select All<span id="SelectAllLocationCheckCount"></span>
                                            </label>
                                        </div>
                                        <div id="CheckTypeofLocation">
                                            {state.data?.pageDomain?.cityCode.map((item: any) => (
                                                <div key={item.code} className="form-check d-flex flex-wrap Locationcheck">
                                                    <input
                                                        className="jobcheckboxes form-check-input me-2"
                                                        type="checkbox"
                                                        value={item.code}
                                                        id="cityCode"
                                                        checked={state.data.filters.cityCode.includes(item.code)}
                                                        onChange={handleFilterChange}
                                                    />
                                                    <label
                                                        className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                        htmlFor="Hyderabad">
                                                        {
                                                            (
                                                                state.domain
                                                                    ?.get('CITY_CODE')
                                                                    ?.find(
                                                                        (childItem: DomainElement) => childItem.code === item.code
                                                                    ) as any
                                                            )?.value
                                                        }{' '}
                                                        <span>({item.recordCount})</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobFiltersControl;
