import { useEffect } from 'react';
import { getDomainValueForCode, isEmpty } from '../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
import pageConfig from './PageConfig.json';
import { useLoaderData } from 'react-router-dom';

class AlumniSchoolSupportService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const { schoolId, schoolGroupId,programLevel } = params;
        const searchCriteria = AlumniSchoolSupportService.getDefaultSearchCriteria(schoolId, schoolGroupId,programLevel, params.pageName);

        return Promise.all([
            AlumniSchoolSupportService.getPageConfig(),
            AlumniSchoolSupportService.search(searchCriteria, params.pageName),
            AlumniSchoolSupportService.getSchoolGroupDomain(),
        ]).then((values) => {
            state.formConfig = values[0];
            state.data = {
                studentList: values[1].data ?? [],
                searchCriteria,
            };
            state.routeInfo = params;
            state.internal = { gridState: {} };
            state.internal.schoolGroupDomain = values[2].data;
            return Promise.resolve(state);
        });
    }

    static getDefaultSearchCriteria(schoolId: number, schoolGroupId: number, programLevel:number, pageName: string = '') {
        const searchCriteria: any = {};
        if (!isEmpty(schoolId)) searchCriteria.schoolId = schoolId;
        if (!isEmpty(schoolGroupId)) searchCriteria.schoolGroupId = schoolGroupId;
        if (!isEmpty(programLevel)) searchCriteria.programLevel = programLevel;
        return searchCriteria;
    }

    static search(searchCriteria: any, pageName: string = '') {
        return axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-matching/search-matching-report`, searchCriteria);
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    static onSchoolChange(props: ControlOnChangeArguments) {
        let schoolName = getDomainValueForCode(props.value, 'INSTITUTE_DOMAIN', props.state);

        props.dispatch({
            type: 'SET_INTERNAL_STATE',
            payload: { key:"schoolName", value:schoolName },
        });
    }

    static onSprogramLevelChange(props: ControlOnChangeArguments) {
        let programLevelName = getDomainValueForCode(props.value, 'PROGRAM_GROUP_LEVEL', props.state);

        props.dispatch({
            type: 'SET_INTERNAL_STATE',
            payload: { key:"programLevel", value:programLevelName },
        });
    }
    
    static onSchoolGroupChange(props: ControlOnChangeArguments) {
        let schoolGroupName = getDomainValueForCode(props.value, 'INSTITUTE_DOMAIN', props.state);

        props.dispatch({
            type: 'SET_INTERNAL_STATE',
            payload: { key:"schoolGroupName", value:schoolGroupName },
        });
        
        props.dispatch({
            type: 'SET_INTERNAL_STATE',
            payload: { key:"schoolName", value:"" },
        });
    }

    static getSchoolGroupDomain() {
        return axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-matching/school-group-domain`);
    }

    // static getBatchYearDropdown(filter: any) {
    //     return axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-matching/filter-data`, filter);
    // }

    static searchStudentCount(searchCriteria: any, pageName: string) {
       return axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-matching/all-studentmatch-count`, searchCriteria);
    }
}

export default AlumniSchoolSupportService;
