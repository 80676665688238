import { useContext, useState } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import TextAsLabelControl from './TextAsLabelControl';
import TextControl from './TextControl';

import editIconVisual from '../../styles/images/edit.svg';
import emailVisual from '../../styles/images/email.svg';
import smartPhoneVisual from '../../styles/images/smartphone.svg';

const handlePhoneChange = (hideclass: string, showClass: string) => {
    console.log(hideclass);
};

const NotifyMeCardsControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    
    const data = getControlValueFromState(dataKey, state as State);
    const [isPhoneOpened, setPhoneIsOpened] = useState(false);
    const [isEmailOpened, setEmailIsOpened] = useState(false);
    const [isPhoneValid, setPhoneValid] = useState(true);
    const [isEmailValid, setEmailValid] = useState(true);
    const isValidNumber = () => {

        const mobileCountryCode = state?.data?.profile.contactMobileCountryCode;
        const mobileNumber = state?.data?.profile.contactMobileNumber;

        if (mobileNumber?.length == 0) {
            setPhoneValid(false);
            setPhoneIsOpened(true);
            return;
        }

        if (mobileCountryCode != '91') {
            setPhoneValid(false);
            setPhoneIsOpened(true);
            return;
        }

        if (
            (mobileNumber?.length > 10) || // mobile number length should not be greater than 10 characters
            (mobileNumber?.length < 10) || // mobile number length should not be less than 10 characters
            (parseInt(mobileNumber.charAt(0)) < 6) // mobile number first number should be greater than or equal to 6
        ){
            setPhoneValid(false);
            setPhoneIsOpened(true);
            return;
        }

        setPhoneValid(true);
        setPhoneIsOpened(false);
    };

    const isValidEmail = () => {
        const validRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        const email = state?.data?.profile.contactEmailId;

        if (validRegex.test(email) == false) {
            setEmailValid(false);
            setEmailIsOpened(true);    
            return;
        }

        setEmailValid(true);
        setEmailIsOpened(false);
    };
    return (
        <>
            <h5 className="font-18 mt-4 pt-3">Notify Me on this Email ID and Number</h5>

            <div className="mentoring_contact p-4 mb-4">
                <div className="mentoring_contact_phone">
                    {!isPhoneOpened && (
                        <p className="">
                            <span className="flex-grow-1 d-flex align-items-center">
                                {' '}
                                <i>
                                    <img src={smartPhoneVisual} className="" alt="" />
                                </i>
                                {/* <span className="form-label"></span> */}
                                <TextAsLabelControl
                                    control={control.controlGroup[1]}
                                    dataKey={control.controlGroup[1].dataPath}
                                    parentDataKey={parentDataKey}
                                />
                                <TextAsLabelControl
                                    control={control.controlGroup[2]}
                                    dataKey={control.controlGroup[2].dataPath}
                                    parentDataKey={parentDataKey}
                                />
                            </span>{' '}
                            <a
                                href=""
                                className="btn-edit"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setPhoneIsOpened(!isPhoneOpened);
                                }}>
                                <img src={editIconVisual} className="" alt="" />
                            </a>
                        </p>
                    )}

                    {isPhoneOpened && (
                        <div className="edit_mentoring_contact">
                            <div className="row g-3 mb-3  align-items-center position-relative">
                                <div className="col-auto">
                                    <i>
                                        <img src={smartPhoneVisual} className="" alt="" />
                                    </i>
                                </div>
                                <div className="col">
                                    <TextControl
                                        control={control.controlGroup[2]}
                                        dataKey={control.controlGroup[2].dataPath}
                                        parentDataKey={parentDataKey}
                                    />
                                    {isPhoneValid == false && <label>Not a valid phone number</label>}
                                </div>
                                <div className="col-sm-6">
                                    <div className="d-flex align-items-center justify-content-end justify-content-sm-start w-100">
                                        <button
                                            className="btn-update"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                isValidNumber();
                                            }}>
                                            Update
                                        </button>
                                        {/* <button className="btn-cancel">Cancel</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="mentoring_contact_email">
                    {!isEmailOpened && (
                        <p className="mb-0">
                            <span className="flex-grow-1 d-flex align-items-center">
                                <i>
                                    <img src={emailVisual} className="" alt="" />
                                </i>
                                <TextAsLabelControl
                                    control={control.controlGroup[0]}
                                    dataKey={control.controlGroup[0].dataPath}
                                    parentDataKey={parentDataKey}
                                />
                            </span>
                            <a
                                href=""
                                className="btn-edit"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setEmailIsOpened(!isEmailOpened);
                                }}>
                                <img src={editIconVisual} className="" alt="" />
                            </a>
                        </p>
                    )}

                    {isEmailOpened && (
                        <div className="edit_mentoring_contact ">
                            <div className="row g-3  align-items-center position-relative">
                                <div className="col-auto">
                                    <i>
                                        <img src={emailVisual} className="" alt="" />
                                    </i>
                                </div>
                                <div className="col">
                                    <TextControl
                                        control={control.controlGroup[0]}
                                        dataKey={control.controlGroup[0].dataPath}
                                        parentDataKey={parentDataKey}
                                    />
                                    {isEmailValid == false && <label>Not a valid Email</label>}
                                </div>
                                <div className="col-sm-6">
                                    <div className="d-flex align-items-center justify-content-end justify-content-sm-start w-100">
                                        <button
                                            className="btn-update"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                // setEmailIsOpened(!isEmailOpened);
                                                isValidEmail();
                                            }}>
                                            Update
                                        </button>
                                        {/* <button className="btn-cancel">Cancel</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default NotifyMeCardsControl;
