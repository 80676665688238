import { useContext, useEffect, useState } from 'react';
import useSmartState from '../../../common/hooks/useSmartState';
import FormBuilder from '../../../library/Builders/FormBuilder';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getFuzzySearchCriteria, isEmpty } from '../../../library/Core/SmartFunctions';
import { FormSection } from '../../../library/Core/SmartTypes';
import FiltersApplied from '../../../library/SimpleControls/FiltersApplied';
import ProfileMarkerNotesService from './ProfileMarkerNotesService';

export const SearchCriteria = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const searchFormConfig = state.formConfig?.sectionRepository[0] as FormSection;
    const [isExpanded, setIsExpanded] = useState(true);
    const [fuzzySearchText, setFuzzySearchText] = useState('');

    useEffect(() => {
        ProfileMarkerNotesService.search(state.data.searchCriteria, state.routeInfo.pageName,state?.data?.id).then((response: any) => {
            setStateValue('notesList', response.data);
            // fetchNotesCount(state);
        });
    }, [state.data.searchCriteria]);

    const handleFuzzySearch = (event: any) => {
        event.preventDefault();
        if (isEmpty(fuzzySearchText)) return;
        
        const searchCriteria = {...state.data.searchCriteria,...getFuzzySearchCriteria(fuzzySearchText)}
        setStateValue('searchCriteria',searchCriteria);
    };

    const handleClearAll = () => {
        const defaultCriteria = ProfileMarkerNotesService.getDefaultSearchCriteria();
        defaultCriteria.addedBy = "";
        defaultCriteria.createdFrom = "";
        defaultCriteria.createdTo = "";
        defaultCriteria.searchById = state?.routeInfo?.id;
        setStateValue('searchCriteria', defaultCriteria);
    };

    // const fetchNotesCount = async (state:any) => {
    //     try {
    //         const notedCountData = await ProfileMarkerNotesService.searchCount(state.data.searchCriteria, state.routeInfo.pageName);
    //         await new Promise(resolve => setTimeout(resolve, 200));
    //         dispatch({
    //             type: 'SET_INTERNAL_STATE',
    //             payload: { key:"notesCount", value:notedCountData?.data },
    //         });
    //     } catch (error) {
    //         console.error("Error fetching alumni count:", error);
    //     }
    // }

    return (
        <>
            <div className="d-flex flex-wrap justify-content-between align-items-start align-items-md-center mb-2 filterSearch">
                <div className="d-flex flex-wrap flex-1 mb-0 justify-content-start">
                    <button className="btn-grpfiter me-3" onClick={() => setIsExpanded(!isExpanded)}>
                        <i className="icon-filter font-13 me-2"></i>
                        Filters
                    </button>
                    <div className="search alumniSearch m-0 open position-static flex-1 mb-2">
                        <div className="form-inline d-flex flex-wrap justify-content-between d-block">
                            <input
                                value={fuzzySearchText}
                                placeholder="Search by description"
                                aria-label="Search"
                                className="form-control flex-1 font-13 font-500 text-truncate"
                                onChange={(event) => setFuzzySearchText(event?.target.value)}
                            />
                            <button className="btn btn-search icon icon-search" onClick={handleFuzzySearch}></button>
                        </div>
                        <a href="" className="btn-search-toggle icon-search"></a>
                    </div>
                    {isExpanded && (
                        <div className="col-12 fitergroup p-3 bg-body-secondary">
                            <FormBuilder key="searchCriteria" section={searchFormConfig} dataKey="searchCriteria" />
                        </div>
                    )}
                </div>
            </div>

            <div className="filterresult">
                <FiltersApplied dataKey="searchCriteria" onClearAll={handleClearAll} />
            </div>
        </>
    );
};
