import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains, getProgramLevelDomainForSchooling } from '../../../../library/Service/domainService';
import { handleNext } from './UserSchoolActions';
import UserSchoolLayout from './UserSchoolLayout';
import SessionContext from '../../../../library/Core/SessionContext';

const UserSchool = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');
    const { sessionState }: any = useContext(SessionContext);

    const levels = Array.from({ length: 13 }, (_, index) => index + 1).map(number => ({
        label: number.toString(),
        value: number
    }));
    const [programLevel, setprogramLevel] = useState(getProgramLevelDomainForSchooling('PROGRAM_LEVEL', levels));

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { SAVE: handleNext, ROUTER_NAVIGATE: navigate, REDIRECT: setRedirectUrl },
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        console.log('school redirect -=--=-=-=-=-=-=-=-=-=--=-=-')
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    useEffect(() => {
        data['schoolOutComesDetail'] = data['schoolOutComesDetail'] || {};
        const domainMap = new Map([...globalState.domain]);
        domainMap.set('INSTITUTE_PROGRAM_CODE', internal);
        domainMap.set('PROGRAM_LEVEL', programLevel);
        const domain = addUserSpecificDomains(new Map([...domainMap]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <UserSchoolLayout>{!state.flags.isDataLoading && <PageBuilder />}</UserSchoolLayout>
        </SmartContext.Provider>
    );
};

export default UserSchool;
