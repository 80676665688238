import { useContext, useEffect, useState } from "react";
import { SmartContext } from "../../../../../../library/Core/SmartContext";
import EditAffinityPageConfig from './FormConfiguration/EditAffinityPageConfig.json'
import EditAffinityModal from "./EditAffinityModal";
import ProfileMarkerService from "./ProfileMakerService";
import { Tooltip } from "bootstrap";
import { isEmpty } from "../../../../../../library/Core/SmartFunctions";
import EventDetailsModal from "./EventDetailsModal";

type AffinityProps = {
    networkingEvents: { label: string,isComment :string, toolTip:string};
    lAndDEvents: { label: string,isComment :string, toolTip:string};
    instituteSocialMedia: { label: string,isComment :string, toolTip:string};
    donationPledges:{label: string,isComment :string, toolTip:string};
    recognitionbyInstitute:{label: string,isComment :string, toolTip:string};
    managementInteractions:{label: string,isComment :string, toolTip:string};
    viewAboutInstitute:{label: string,isComment :string, toolTip:string};
    onlineReviews: { label: string,isComment :string, toolTip:string};
    admissionReferrals: { label: string,isComment :string, toolTip:string};
    isChildSameInstitute:{ label: string,isComment :string, toolTip:string};
    isParentsSameInstitute:{ label: string,isComment :string, toolTip:string};
    receivedScholarship:{ label: string,isComment :string, toolTip:string};
    guidanceVideos:{ label: string,isComment :string, toolTip:string};
    // webinars:{ label: string,isComment :string, toolTip:string};
    jobPosts:{ label: string,isComment :string, toolTip:string};
    albums:{ label: string,isComment :string, toolTip:string};
    negativeExperience:{ label: string,isComment :string, toolTip:string};
};

const AffinityProps: AffinityProps = {
    networkingEvents: { label: 'Networking Events',isComment:'networkingEventsComment', toolTip:'Alumnus is participating in “Networking” themed events like Batch reunion, Foundation Day etc, organized by institute either as attendee or presenter'},
    lAndDEvents: { label: 'L&D Events',isComment:'lAndDEventsComment', toolTip:'Alumnus is participating in “Learning , Development and Career Growth” themed events like Webinar, Guest Lectures etc organized by institute either as attendee or presenter'},
    instituteSocialMedia:{ label: 'Institute’s Social Media',isComment:'instituteSocialMediaComment', toolTip:'Alumnus level of interaction with institute’s social media pages on LinkedIn, Facebook, Instagram etc'},
    donationPledges:{ label: 'Donation Pledges',isComment:'donationPledgesComment', toolTip:'Alumnus has pledged to donate for the institute'},
    recognitionbyInstitute:{ label: 'Recognition by Institute',isComment:'recognitionbyInstituteComment', toolTip:'Alumnus has been recognized by institute on official website, social media posts or through any awards like “Distinguished Alumni Award” etc'},
    managementInteractions:{ label: 'Management Interactions',isComment:'managementInteractionsComment', toolTip:'The institute’s top management like Trustees, Chairman, Director or Deans have engaged in a 1-to-1 interaction with the alumnus officially or unofficially'},
    viewAboutInstitute:{ label: 'Views about the institute',isComment:'viewAboutInstituteComment', toolTip:'Alumnus has expressed their general views about the institute in a survey or a telephonic or face-to-face conversation'},
    onlineReviews:{ label: 'Online Reviews',isComment:'onlineReviewsComment', toolTip:'Alumnus has published a review on platforms like Google, Shiksha.com, CollegeDunia, Careers360 etc about the institute'},
    admissionReferrals:{ label: 'Admission Referrals',isComment:'admissionReferralsComment', toolTip:'Alumnus has referred their friends or family members to the institute for admissions'},
    isChildSameInstitute:{ label: 'Child studying in same institute',isComment:'isChildSameInstituteComment', toolTip:'The child of the alumnus is also studying currently in the same institute, OR the child is also an alumnus, making both of them alumni of your institute'},
    isParentsSameInstitute:{ label: 'Parents in same institute',isComment:'isParentsSameInstituteComment', toolTip:'The parents of the alumnus is also studying currently in the same institute, OR the parents is also an alumnus, making both of them alumni of your institute'},
    receivedScholarship: { label: 'Received Scholarship from Institute',isComment:'receivedScholarshipComment', toolTip:'Alumnus has received any scholarship from the institute while they were studying'},
    guidanceVideos:{ label: 'Guidance Videos',isComment:'', toolTip:'Alumnus has contributed guidance videos on Univariety platform for current students'},
    // webinars:{ label: 'Webinars',isComment:'', toolTip:'Alumnus has conducted webinars on Univariety platform for current students and alumni'},
    jobPosts:{ label: 'Job Posts',isComment:'', toolTip:'Alumnus has contributed jobs and internships on Univariety platform for current students and alumni'},
    albums:{ label: 'Albums',isComment:'', toolTip:'Alumnus has contributed memory albums on Univariety platform'},
    negativeExperience:{ label: 'Negative Experience',isComment:'negativeExperienceComment', toolTip:'Alumnus has had a negative experience incident with the institute while they were studying or after they graduated'},
};

const keys = Object.keys(AffinityProps) as (keyof AffinityProps)[];

const Affinity1 = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { fetchUserFromSameInstitute,fetchAffinityRelatedCountsByUserId,handleOpenLogsModal } = ProfileMarkerService();
    const [counts, setCounts] = useState([0, 0, 0]); // [jobPostCount, albumCount, guidanceCount]
    const [eventData, setEventData] = useState<any[]>([]);
    const [eventType, setEventType] = useState<string>('');

    const handleOpenModal = async (key: string) => {
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showAffinityModal' } });
        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: { 
                    ...state.data, 
                    visibleControl: key,
                    lAndDEvents: JSON.parse(state.data.marker?.lAndDEvents?.lAndDEvents || '[{}]'),
                    networkingEvents: JSON.parse(state.data.marker?.networkingEvents?.networkingEvents || '[{}]'),
                    recognitionbyInstitute: JSON.parse(state.data.marker?.recognitionbyInstitute?.recognitionbyInstitute || '[]'),
                    managementInteractions: JSON.parse(state.data.marker?.managementInteractions?.managementInteractions || '[{}]'),
                    onlineReviews: JSON.parse(state.data.marker?.onlineReviews?.onlineReviews || '[{}]')
                },
            },
        });
    };
    const model = state.internal.model;
    const [isConfigAvailable, setisConfigAvailable] = useState(false);
    const markers = state.data.marker || [];
    console.log("markers",markers)

    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, [state.data]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const userListFromSameInstitute = await fetchUserFromSameInstitute(state.internal.model.id,'Affinity');
                const domainMap = new Map([...state.domain]);
                console.log(userListFromSameInstitute);
                domainMap.set('CHILD_FROM_SAME_INSTITUTE', userListFromSameInstitute);

                const countsData = await fetchAffinityRelatedCountsByUserId(state.internal.model.id); 
                console.log("counts",countsData)
                setCounts([ countsData.jobPostCount || 0,countsData.albumCount || 0,countsData.guidanceCount || 0]);

                const config = await Promise.resolve(EditAffinityPageConfig);

                const networkingEvents = JSON.parse(state.data.marker?.networkingEvents?.networkingEvents || '[]');
                const onlineReviews = JSON.parse(state.data.marker?.onlineReviews?.onlineReviews || '[]');
                const lAndDEvents = JSON.parse(state.data.marker?.lAndDEvents?.lAndDEvents || '[]');
                const recognitionbyInstitute=JSON.parse(state.data.marker?.recognitionbyInstitute?.recognitionbyInstitute || '[]');
                const managementInteractions=JSON.parse(state.data.marker?.managementInteractions?.managementInteractions || '[]');

                dispatch({
                    type: 'DATA_INIT',
                    payload: {
                       formConfig: config,
                        data: {
                            ...state.data,
                            affinity: state.data.marker?.affinity || {},
                            networkingEvents: networkingEvents || {},
                            onlineReviews:onlineReviews || {},
                            lAndDEvents:lAndDEvents || {},
                            recognitionbyInstitute:recognitionbyInstitute || {},
                            managementInteractions:managementInteractions || {}
                        },
                        domain: domainMap,
                        internal: state.internal,
                        routeInfo: state.routeInfo,
                    },
                });
                setisConfigAvailable(true);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleOpenEventModal = (eventType: string) => {
      const data = eventType === 'networkingEvents'
        ? JSON.parse(markers.networkingEvents.networkingEvents || '[]')
        : JSON.parse(markers.lAndDEvents.lAndDEvents || '[]');
      setEventData(data); 
      setEventType(eventType); 
      dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showAffinityEventsModal' } });
    };

  
    useEffect(() => {
        const affinityData = { ...state.data.affinity };

        Object.keys(AffinityProps).forEach((key) => {
            const AffinityPropsKey = key as keyof AffinityProps;
            const prop = AffinityProps[AffinityPropsKey];
            if (prop && typeof prop === 'object' && 'isComment' in prop) {
                const isCommentField = prop.isComment;
                if (affinityData[AffinityPropsKey] === false) {
                    if (isCommentField) {
                        affinityData[isCommentField as keyof typeof affinityData] = '';
                    }
    
                    if (AffinityPropsKey === 'isChildSameInstitute') {
                        affinityData['childId'] = '';
                    }
                }
            }
        });

        if (affinityData.receivedScholarship === false) {
            affinityData.scholarshipCurrencyCode = '';
            affinityData.scholarshipAmount = '';
        }
    
        // Define an array of keys to check for dynamic updates
        const keysToCheck = [
            'isrecognitionbyInstitute', 
            'managementInteractionsComment', 
            'ismanagementInteractions',
        ];
    
        // Function to dynamically dispatch changes
        const dispatchChange = (dataKey: string, value: any) => {
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey, value },
            });
        };
    
        keysToCheck.forEach((key) => {
            if (affinityData[key] === false) {
                // Reset the associated comment field if it exists
                const commentKey = `${key.replace(/^is/, '')}Comment`;
                if (commentKey in affinityData) {
                    affinityData[commentKey] = '';
                }
    
                if (key === 'isrecognitionbyInstitute') {
                    const updatedState = { ...state.data, recognitionbyInstitute: [] };
                    if (JSON.stringify(state.data.recognitionbyInstitute) !== JSON.stringify(updatedState.recognitionbyInstitute)) {
                        dispatchChange('recognitionbyInstitute', updatedState.recognitionbyInstitute);
                    }
                }
    
                if (key === 'ismanagementInteractions') {
                    const updatedState = { ...state.data, managementInteractions: [] };
                    if (JSON.stringify(state.data.managementInteractions) !== JSON.stringify(updatedState.managementInteractions)) {
                        dispatchChange('managementInteractions', updatedState.managementInteractions);
                    }
                }
    
                if (key === 'managementInteractionsComment') {
                    const updatedState = { ...state.data, managementInteractionsComment: '' };
                    if (JSON.stringify(state.data.managementInteractionsComment) !== JSON.stringify(updatedState.managementInteractionsComment)) {
                        dispatchChange('managementInteractionsComment', updatedState.managementInteractionsComment);
                    }
                }

            }
        });
    
        // Dispatch changes to affinity if it has changed
        if (JSON.stringify(state.data.affinity) !== JSON.stringify(affinityData)) {
            dispatchChange('affinity', affinityData);
        }
    }, [state.data.affinity, state.data.recognitionbyInstitute, state.data.managementInteractions, dispatch]);
    
    
    const specialKeys = ['networkingEvents', 'lAndDEvents', 'recognitionbyInstitute','managementInteractions','onlineReviews'];
    
    const markerExists = (key: string): boolean => {    
        if (specialKeys.includes(key)) {
            const nestedValue = markers?.[key]?.[key];
    
            if (typeof nestedValue === 'string') {
                return nestedValue.length > 0;
            } else if (Array.isArray(nestedValue)) {
                return nestedValue.length > 0;
            } else if (typeof nestedValue === 'object' && nestedValue !== null) {
                return Object.keys(nestedValue).length > 0;
            }
        }
        return markers?.affinity && key in markers.affinity;
    };

    const markerEnabled = (key: string): boolean => {
        const value = markers?.affinity?.[key];
        if (value === true) return true;
        if (typeof value === 'string') {
            if (!isEmpty(value)) {
                return true;
            }
        }
            
        if (specialKeys.includes(key)) {
            const nestedKeyString = markers?.[key]?.[key];
            
            if (typeof nestedKeyString === 'string') {
                try {
                    const nestedKeyArray = JSON.parse(nestedKeyString);
                    if (Array.isArray(nestedKeyArray) && nestedKeyArray.length > 0) {
                        return nestedKeyArray.some(item => Object.keys(item).length > 0);
                    }
                } catch (error) {
                    console.error("Failed to parse nestedKeyString:", error);
                }
            } else if (Array.isArray(nestedKeyString)) {
                return nestedKeyString.some(item => Object.keys(item).length > 0);
            } else if (typeof nestedKeyString === 'object' && nestedKeyString !== null) {
                return Object.keys(nestedKeyString).length > 0;
            }
        }
        
        return false;
    };

    const comment = (key: string) => {
        const prop = AffinityProps[key as keyof AffinityProps];
        return prop.isComment ? state.data.affinity?.[prop.isComment] : '';
    };

    const value = (key: string) => {
      if (markers?.affinity && key in markers.affinity) {
          return markers.affinity[key];
      }
      return null;
    };

    const renderOnlineReviews = () => {
        const hasOnlineReviewsInMarkers = markers?.onlineReviews?.onlineReviews &&
        Array.isArray(markers.onlineReviews.onlineReviews) &&
        markers.onlineReviews.onlineReviews.filter((item: any) => 
          item && typeof item === 'object' && Object.keys(item).length > 0
        ).length > 0;
      
      const hasOnlineReviewsInState = state.data.marker?.onlineReviews?.onlineReviews &&
        Array.isArray(JSON.parse(state.data.marker.onlineReviews.onlineReviews)) &&
        JSON.parse(state.data.marker.onlineReviews.onlineReviews).filter((item: any) => 
          item && typeof item === 'object' && Object.keys(item).length > 0
        ).length > 0;
    
        if (!(hasOnlineReviewsInMarkers || hasOnlineReviewsInState)) {
          return (
            <div className="col-12">
            <div className="row g-3 align-items-center">
                 <div className="col-auto">
                    <div className={`d-flex flex-column justify-content-center align-items-center fade`}>
                    <div className={`meter-wrap positive`}>
                        <span className="meter-icon">
                        <i className="pointer-pin"></i>
                        </span>
                    </div>
                    <div className="meter-title">Positive</div>
                    </div>
                </div>
                <div className="col">
                  <div className="listed-border disabled" onClick={() => handleOpenModal('onlineReviews')}>
                    <i className="tick"></i>
                    <p>Online Review</p>
                  </div>
                </div>
              </div>
            </div>
          );
        }
    
        const onlineReviews = JSON.parse(state.data.marker.onlineReviews.onlineReviews);
    
        return (
          <div className="row g-3 align-items-center">
            {onlineReviews.map((review: any, index: number) => (
              <div className="col-12" key={index}>
                <div className="row g-3 align-items-center">
                  <div className="col-auto">
                    <div className={`d-flex flex-column justify-content-center align-items-center`}>
                      <div className={`meter-wrap ${getMeterClass(review.onlineReviewsaboutInstitute)}`}>
                        <span className="meter-icon">
                          <i className="pointer-pin"></i>
                        </span>
                      </div>
                      <div className="meter-title">{review.onlineReviewsaboutInstitute}</div>
                    </div>
                  </div>
                  <div className="col">
                    <div className={`listed-border`}>
                      <i className="tick"></i>
                      <p onClick={() => handleOpenModal('onlineReviews')}>
                        <strong>{review.onlineReviewsName}:</strong> {review.onlineReviewsaboutInstitute}
                      </p>
                      {review.onlineReviewsaboutInstituteFilePath && (
                        <a href={review.onlineReviewsaboutInstituteFilePath} target="_blank" rel="noopener noreferrer" className="eventslink_willingness">
                          Download
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
    };
    
    const getCountForKey = (key:string) => {
      switch (key) {
          case 'guidanceVideos':
              return counts[2]; 
          case 'webinars':
              return 0; 
          case 'jobPosts':
              return counts[0]; 
          case 'albums':
              return counts[1]; 
          default:
              return 0;
      }
  };

  function getMeterClass(val:any) {
    if (typeof val === 'string') {
      switch (val.toLowerCase()) {
        case 'high':
          return 'positive';
        case 'medium':
          return 'neutral';
        case 'low':
          return 'negative';
        default:
          return val.toLowerCase(); 
      }
    }
    return val && typeof val === 'string' ? val.toLowerCase() : ''; 
  }

  const filteredKeys = keys.filter((key) => {
    if (key === 'isParentsSameInstitute') {
      return !isEmpty(state?.data?.affinity?.parentId); 
    }
    return true; 
  });

  return (
    <div
      className="tab-pane fade active show"
      id="v-pills-affinity"
      role="tabpanel"
      aria-labelledby="v-pills-affinity-tab"
    >
      <div className="max-470 mx-auto">
        <div className="row g-3 align-items-center">
          {filteredKeys.map((key) => {
            const exists = markerExists(key);
            const enabled = markerEnabled(key);
            const comm = comment(key);
            const val = value(key);
            const isOnlineReviews = key === 'onlineReviews';
            const childId = state.data.affinity?.childId;
            const parentId = state.data.affinity?.parentId;

            const hasOnlineReviewsInMarkers = markers?.onlineReviews?.onlineReviews.length > 0;

            const count = getCountForKey(key); 

            const borderClass = (key === 'guidanceVideos'  || key === 'jobPosts' || key === 'albums') 
              ? (count > 0 ? '' : 'disabled') 
              : (enabled ? '' : 'disabled');

            return (
              <div className="col-12" key={key}>
                <div className="row g-3 align-items-center">
                  {!isOnlineReviews && (
                    <div className="col-auto">
                      <div className={`d-flex flex-column justify-content-center align-items-center ${(!val || val === true || val==='Not Engaged') ? 'fade' : ''}`}>
                      <div className={`meter-wrap ${getMeterClass(val)}`}>
                      <span className="meter-icon">
                            <i className="pointer-pin"></i>
                          </span>
                        </div>
                        <div className="meter-title">{val}</div>
                      </div>
                    </div>
                  )}

                  <div className="col">
                    {isOnlineReviews ? (
                      renderOnlineReviews()
                    ) : (
                      <div className={`listed-border ${borderClass}`}>
                        <i className="tick"></i>
                        <p
                          onClick={
                            key === 'guidanceVideos' ||
                            key === 'jobPosts' ||
                            key === 'albums' ||
                            key === 'isParentsSameInstitute' 
                              ? undefined
                              : () => handleOpenModal(key)
                          }
                        >
                       {AffinityProps[key].label}{' '}
                          {key === 'networkingEvents' || key === 'lAndDEvents' ? (
                            markers?.[key]?.[key]?.length > 0  && enabled ? (
                              <>
                                {' - '}
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault(); 
                                    e.stopPropagation(); 
                                    handleOpenEventModal(key);
                                  }}
                                  className="eventslink_willingness"
                                >
                                  {JSON.parse(markers[key]?.[key] || '[]')?.length || 0} Events
                                </a>
                              </>
                            ) : ''
                          ) : key === 'isChildSameInstitute' && childId ? (
                            <>
                              <a
                                className="text-white"
                                href={`/alumni/${childId}/profile`}
                                target="_blank"
                                onClick={(event) => event.stopPropagation()}
                              >
                                View Child Profile
                              </a>
                            </>
                          ):  key === 'isParentsSameInstitute' && parentId ? (
                            <>
                              <a
                                className="text-white"
                                href={`/alumni/${parentId}/profile`}
                                target="_blank"
                                onClick={(event) => event.stopPropagation()}
                              >
                                View Parent Profile
                              </a>
                            </>
                          ) : (
                            key === 'recognitionbyInstitute' || key === 'managementInteractions' ? (
                              markers[key]?.[key] ? (
                                (() => {
                                  try {
                                    const parsedData = JSON.parse(markers[key][key]);
                                    const filteredData = Array.isArray(parsedData) 
                                      ? parsedData.filter(item => Object.keys(item).some(k => item[k] !== undefined && item[k] !== null && item[k] !== ''))
                                      : [];
                                    return filteredData.length > 0 ? ` - ${filteredData.length}` : '';
                                  } catch (e) {
                                    return ''; 
                                  }
                                })()
                              ) : ''
                            ) : key === 'guidanceVideos' ||  key === 'jobPosts' || key === 'albums' ? (
                              ` - ${count}`
                            ) : val ? (
                                ` - ${val === 1 || val === true ? 'Yes' : val}`
                            ) : (
                                ''
                            )
                          )}
                        </p>

                        {comm && (
                          <span className="note-wrap" data-bs-toggle="tooltip" data-bs-html="true" title={comm}>
                            <i className="icon-note"></i>
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="col-auto">
                    <i
                      className="icon-info"
                      data-bs-toggle="tooltip"
                      title={AffinityProps[key]?.toolTip || ''}
                      aria-label={AffinityProps[key]?.toolTip || ''}
                    ></i>
                  </div>
                </div>
              </div>
            );
          })}
             <div className="col-12 pt-3">
                <button className="btn btn-dark-blue w-auto height-40 px-4 d-flex align-items-center mx-auto" onClick={() => handleOpenLogsModal()}>
                <span className="text-white">View Update Logs</span>
                </button>
            </div>
        </div>
      </div>
      {isConfigAvailable && <EditAffinityModal userdata={{ model }} />}
      {/* Conditionally render the new modal */}
      <EventDetailsModal eventData={eventData} eventType={eventType}/>
    </div>
  );
};

export default Affinity1;
