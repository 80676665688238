import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
//import { onMobileCountryCodeChange, onMobileNumberChange, twelthPassoutYear } from './CreateAlumniActions';
import Layout from './Layout';


const ThemeOptions = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const eventId = queryParams.get('id');
    
    console.log(eventId)
    if(!eventId){
        navigate(`/event/addnew`);
    }
    const { routeInfo, formConfig, data } = useLoaderData() as State;
    
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            SAVE: handleCreate,            
        },
    } as any);

    function handleCreate(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();
        
        const isnotValideventtheme = iseventthemenotValid(state, dispatch);
        if (!isFormValid(state, dispatch) || isnotValideventtheme ) {
            //dispatch({ type: 'SHOW_ERRORS' });
            return;
        }

        dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [] });

        let updateaudience = `${process.env.REACT_APP_LEAD_SERVER}/marketevent/updatethemeinfo`;
        

        axiosClient()
            .post(`${updateaudience}`, {
                eventId : eventId,
                eventtheme: JSON.stringify(state['data']['updatetheme']),
                
                 
             })
            .then((res: any) => {
                console.log(res.data.eventId)
                alert(res.data.message)

                if (!res.data.eventId) return alert('There is some issue in event management. Please try after sometime!');
                navigate(`/event/manageevent`);
            });
    }

    //const { formConfig, data, routeInfo } = useLoaderData() as State;
    

    // const iseventthemenotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    //     const { updatetheme } = state?.data || {};
    
    //     const isEmpty = (value:any) => !value || value.length === 0;
    
    //     const hasValidEntry = (items:any) => Array.isArray(items) && items.some((item) => item?.label && item?.value);
    
    //     const checks = {
    //         departmentid: hasValidEntry(updatetheme?.departmentid),
    //         eventcountries: hasValidEntry(updatetheme?.eventcountries),
    //         eventcareers: hasValidEntry(updatetheme?.eventcareers),
    //         eventskills: hasValidEntry(updatetheme?.eventskills),
    //         eventtests: hasValidEntry(updatetheme?.eventtests),
    //         eventindustry: hasValidEntry(updatetheme?.eventindustry),
    //     };
    
    //     const invalidFields = Object.entries(checks).filter(([key, valid]) => !valid);
    
    //     if (invalidFields.length > 0) {
    //         alert("Please select at least one from all required tags");
    //         return true; // Invalid if there's at least one invalid field
    //     }
    
    //     return false; // No issues, validation passed
    // };


    const iseventthemenotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        // At least one skill
        var returnVal = false;
        const atLeastOnedepartmentidEntered = state?.data?.updatetheme?.departmentid?.some((departmentid: any) => !isEmpty(departmentid.departmentid));
        const atLeastOneeventcountriesEntered = state?.data?.updatetheme?.eventcountries?.some((eventcountries: any) => !isEmpty(eventcountries.eventcountries));
        const atLeastOneeventcareersEntered = state?.data?.updatetheme?.eventcareers?.some((eventcareers: any) => !isEmpty(eventcareers.eventcareers));
        const atLeastOneeventskillsEntered = state?.data?.updatetheme?.eventskills?.some((eventskills: any) => !isEmpty(eventskills.eventskills));
        const atLeastOneeventtestsEntered = state?.data?.updatetheme?.eventtests?.some((eventtests: any) => !isEmpty(eventtests.eventtests));
        const atLeastOneeventindustryEntered = state?.data?.updatetheme?.eventindustry?.some((eventindustry: any) => !isEmpty(eventindustry.eventindustry));
       
        //if (!atLeastOnedepartmentidEntered || !atLeastOneeventcountriesEntered || !atLeastOneeventcareersEntered || !atLeastOneeventskillsEntered || !atLeastOneeventtestsEntered || !atLeastOneeventindustryEntered) {
        if (!atLeastOnedepartmentidEntered){

            if (!atLeastOneeventcountriesEntered){
                
                if (!atLeastOneeventcareersEntered){

                    if (!atLeastOneeventskillsEntered){
                        
                        if (!atLeastOneeventtestsEntered){
                            
                            if (!atLeastOneeventindustryEntered){
                                alert('Please select at least one from Tags');
                                returnVal = true;
                            }

                        }

                    }
                }
            }

        }

        return returnVal;
    };



    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: { updatetheme: data },
                domain,
                routeInfo,
            },
        });
    }, []);



    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            
            <Layout>{!state.flags.isDataLoading && <PageBuilder />}</Layout>
        </SmartContext.Provider>
    );
};

export default ThemeOptions;
