import moment from 'moment';
import { useContext, useEffect } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState } from '../Core/SmartFunctions';
import { FormControl, SimpleFormControlArguments, State } from '../Core/SmartTypes';
import { getGridInitialState } from '../Service/GridService';
import ActionIconGroup from '../SimpleControls/ActionIconGroup';
import PaginationControl from './PaginationControl';

const TableControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey } = args;
    const originalData = getControlValueFromState(args.dataKey, state as State) as any[];
    const GRID_PAGE_SIZE = 10; // This need to be made properties of the table control

    useEffect(() => {
        if (!state?.internal?.gridState[control.id])
            dispatch({ type: 'GRID_INTERNAL_STATE_INIT', payload: { id: control.id, data: originalData } });
    }, []);

    let gridState = state?.internal?.gridState[control.id] ?? getGridInitialState(control.id, originalData, GRID_PAGE_SIZE);

    const handleSearchCriteriaChange = (id: string, value: string) => {
        dispatch({ type: 'TABLE_SEARCH_CRITERIA_VALUE_CHANGE', payload: { tableName: control.id, id, value, originalData, control } });
    };

    const handlePageChange = (pageNumber: number) => {
        dispatch({ type: 'GRID_PAGE_CHANGE', payload: { pageNumber, control } });
    };

    const handleBulkAction = () => {
        state.actions['BULK_ACTION'](state, dispatch);
    };

    const getHeader = () => {
        return (
            <thead className="table-light">
                <tr>
                    <th scope="col">
                        <input type="checkbox" />
                    </th>
                    {control?.controlGroup.map((column, index) => (
                        <th key={dataKey + column.id} scope="col" className={`col-${column.width} fw-semibold`}>
                            <div className="d-flex justify-content-between">{column.props.label}</div>
                        </th>
                    ))}
                </tr>
                {/* <tr>
                    <th scope="col"></th>
                    {control?.controlGroup.map((column, index) => (
                        <th key={dataKey + column.id} scope="col">
                            <input
                                id={column.id}
                                className="border border-1 col-12"
                                onChange={(event) => handleSearchCriteriaChange(column.id, event.target.value)}
                            />
                        </th>
                    ))}
                </tr> */}
            </thead>
        );
    };

    const getBody = () => {
        return (
            <tbody>
                {gridState.pageData.map((row: any, rowIndex: number) => (
                    <tr key={dataKey + rowIndex}>
                        <td>
                            <input type="checkbox" />
                        </td>
                        {control.controlGroup.map((column, colIndex) => (
                            <td key={`${colIndex}-${column.id}`}>{generateColumn(column, row, dataKey + rowIndex)}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        );
    };

    function generateColumn(column: FormControl, row: any, dataKey: string) {
        // return column.type === 'TEXT' ? row[column.id] : format(row[column.id], column.type);
        switch (column.type) {
            case 'TEXT':
                return row[column.id];
            case 'TEXT_WITH_STUDENT_LINK':
                return (
                    <a href={`/student/${row.uuid}/profile`} target="_blank" rel="noopener noreferrer">
                        {row[column.id]}
                    </a>
                );
            case 'LABEL_DATE':
            case 'DATE':
                return format(row[column.id], 'LABEL_DATE');
            case 'DATETIME':
                return format(row[column.id], 'LABEL_DATETIME');
            case 'ACTION_ICON_GROUP':
                return <ActionIconGroup control={column} dataKey={dataKey} row={row} />;
            default:
                return row[column.id];
        }
    }

    const format = (value: any, format: string) => {
        let response = '';

        switch (format) {
            case 'LABEL_DATE':
                response = moment(value).format('DD MMM YYYY');
                break;
            case 'LABEL_DATETIME':
                response = new Date(value).toLocaleString();
                break;
            default:
                response = value;
        }

        return response;
    };

    const getTableToolbar = () => {
        return (
            <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks">
                <div className="d-flex justify-content-start align-items-center customselect_parnt">
                    <select className="form-select w-120 me-2" id="BulkAction">
                        <option value="">Bulk Action</option>
                        <option value="1">Activate</option>
                        <option value="0">Deactivate</option>
                        <option value="2">Send Message</option>
                    </select>
                    <button className="btn btn-darkblue" type="button" onClick={handleBulkAction}>
                        <span className="line-height-16">Apply</span>
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="row mb-3">
                <div className="col-md-8">{getTableToolbar()}</div>
                <div className="col-md-4"></div>
            </div>
            <div className="table-responsive attendee_dtl">
                <table className="table w-100">
                    {getHeader()}
                    {getBody()}
                </table>
            </div>
            <PaginationControl data={gridState.filteredData} pageSize={10} onPageChange={handlePageChange} />
        </div>
    );
};

export default TableControl;
