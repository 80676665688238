import InfoVisual from '../../../../styles/images/mask-group-152.svg';
import UnlockImage from '../../../../styles/images/unlock.svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import PopUpImg from '../../../../styles/images/skip_later_visual.png';
import { useEffect, useState, useContext } from 'react';
import SessionContext from '../../../../library/Core/SessionContext';

let SchoolLogo = sessionStorage.getItem('school-image') as any;
const PostGraduateLayout = (props: any) => {
    const [profilePercentage, setprofilePercentage] = useState<any>('');
    //const [userStatusCode, setUserStatusCode] = useState(sessionStorage.getItem('user-status-code'));
    // useEffect(() => {
    //     setUserStatusCode(sessionStorage.getItem('user-status-code'));
    // }, []);

    const { sessionState } = useContext(SessionContext);
    const userStatusCode = sessionState?.userStatusCode;
    const userTypeCode = sessionState?.userTypeCode;

    useEffect(() => {
        const profilePercentage = sessionStorage.getItem('profile-percentage') ? sessionStorage.getItem('profile-percentage') : 0;
        setprofilePercentage(profilePercentage);
    }, [sessionStorage.getItem('profile-percentage')]);

    const navigate = useNavigate();
    return (
        <>
            <div className="main-wrap">
                <div className="leftPane">
                    <div className="leftPaneWrap">
                        {userTypeCode != 'SUPER_ADMIN' && userStatusCode != 'ACTIVE' && (
                            <div className="leftPaneHeaderWrap">
                                <div className="leftPaneHeader">
                                    <img src={SchoolLogo} alt="" />
                                    <label className="user_name">
                                        Hey <span>{`${sessionState?.firstName} ${sessionState?.lastName}`}</span>
                                    </label>
                                </div>
                                {/* <div className="global show" data-pai="75">
                                    <div className="unlock">
                                        <div className="img">
                                            <img src={UnlockImage} alt="" />
                                        </div>
                                        <div className="text">In the next 2 steps! Unlock Alumni benefits</div>
                                    </div>
                                    <div className="progress_svg">
                                        <CircularProgressbar
                                            value={profilePercentage}
                                            text={`${profilePercentage}%`}
                                            styles={buildStyles({
                                                // This is in units relative to the 100x100px
                                                // SVG viewbox.
                                                textSize: '25px',
                                                //   pathColor: `rgba(62, 152, 199, ${profilePercentage / 100})`,
                                                textColor: '#000',
                                                //   trailColor: '#d6d6d6',
                                            })}
                                        />
                                    </div>
                                </div> */}
                            </div>
                        )}
                        <div className="leftPaneBodyWrap">
                            <div className="leftPaneBody">
                                <div className="qs_body" data-aos="fade-up" data-nextpage="test.html">
                                    <div className="steps showTab" date-bgclr="basic-information" id="step1">
                                        <div className="steps_questions">{props.children}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rightPane basic-information bg-purple-gradient">
                    <div className="rightPaneWrap">
                        {userTypeCode != 'SUPER_ADMIN' && userStatusCode != 'ACTIVE' && (
                            <div className="rightPaneHeaderWrap">
                                <div className="rightPaneHeader">
                                    <label className="user_name">
                                        Hey <span>{`${sessionState?.firstName} ${sessionState?.lastName}`}</span>
                                    </label>
                                </div>
                            </div>
                        )}
                        <div className="rightPaneBodyWrap">
                            <div className="rightPaneBody">
                                <div className="step step_1">
                                    <a
                                        data-bs-toggle="collapse"
                                        data-bs-target="#listed_points"
                                        href=""
                                        className="mobile-step-block collapsed"
                                        aria-expanded="false"></a>
                                    <div className="textDetail">
                                        <h2 className="stepHeader">WHY ARE YOUR PG DETAILS IMPORTANT?</h2>
                                        <div className="listed_points" id="listed_points">
                                            <ul className="purple-line">
                                                <li>In Sharing College Experiences</li>
                                                <li>Your Profile To Become More Insightful</li>
                                                <li>Your Profile To Become More Insightful</li>
                                            </ul>
                                        </div>
                                        <a href="" className="step_header_wrap_link">
                                            Read <span className="more">More</span>
                                            <span className="less">Less</span>
                                        </a>
                                    </div>
                                    <div className="step_img mt-2">
                                        <img src={InfoVisual} className="img-fluid float_horizontal_animate" width="100%" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a
                    data-bs-toggle="collapse"
                    data-bs-target="#listed_points"
                    href=""
                    className="bg-drop collapsed"
                    aria-expanded="false"></a>
            </div>

            <div className="modal fade show" id="skip_pop" aria-labelledby="skip_popLabel" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="brife">
                                <h4>DON'T LEAVE JUST YET!</h4>
                                <p>Your journey can guide others, and inspire them!</p>
                            </div>
                            <div className="img_pop">
                                <img src={PopUpImg} alt="" />
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                className="btn btn-goback"
                                data-bs-target="#exampleModalToggle"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:none');
                                }}>
                                GO BACK
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    sessionStorage.setItem('user-status-code', 'ACTIVE');
                                    document.getElementById('updateUserStatusCode')?.click();
                                    navigate('/alumni/onboarding');
                                }}
                                className="btn btn-later">
                                I'LL DO THIS LATER
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PostGraduateLayout;
