import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Draftmail = ({ pageTitle, ...args }: { pageTitle: any; }) => {
    document.title = pageTitle;

    const limitMessage = (message: string, maxLength: number) => {
        if (message.length > maxLength) {
            return message.substring(0, maxLength) + '...'; // Display an ellipsis if the message is longer
        }
        return message;
    };

    const currentDate = new Date();
    const date = currentDate.toDateString();
    const urlParams = new URLSearchParams(window.location.search);
    const [templateData, setTemplateData] = useState<any[]>([]);
    const [message, setMessage] = useState<string | null>(null); // State for showing messages

    let sesinstituteId = 0;
    const userDataString = sessionStorage.getItem('user');
    if (userDataString) {
        const userData = JSON.parse(userDataString);
        sesinstituteId = userData.primaryInstitute;
    }
    
    const fetchData = async () => {
        try {
            const jwtToken = localStorage.getItem('user-jwt-token');
            const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/get-all-template`, {
                school_ref_id: sesinstituteId,
            }, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`  // Using Bearer token authentication
                }
            });
            setTemplateData(response.data.templates);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData(); // Call the async function when the component mounts
    }, []); // Empty dependency array to run once on mount

    const deleteTemplate = async (templateId: number) => {
        const isConfirmed = window.confirm('Do you really want to discard this template?');
        if (!isConfirmed) {
            return; // Do nothing if user cancels the delete action
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email-action/delete-draft`, {
                id: templateId
            });
            if (response.data.success) {
                // Remove the deleted template from the state
                setTemplateData(prevData => prevData.filter(template => template.id !== templateId));
                
                // Show success message
                setMessage('Template deleted successfully');
                
                // Clear the message after 3 seconds
                setTimeout(() => {
                    setMessage(null);
                }, 3000);
            }
        } catch (error) {
            console.error('Error deleting template:', error);
        }
    };

    return (
        <div className="main flex-1">
            <div className="max-1140 mx-auto d-flex h-100">
                <div className="flex-1 d-flex flex-column mb-5 w-100">
                    <div className="pageContent flex-1">
                        <div className="mb-5 pb-4">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div className="white-block white-block-notopborderradius h-100 p-0">
                                        <div className="d-flex justify-content-between align-items-center py-3 px-4">
                                            <h3 className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                                                Broadcast
                                            </h3>
                                            <div className="dashboard innerpage smart-scroll">
                                                <Link
                                                    to={`/broadcast/createnew`}
                                                    className="btn btn-green w-auto height-40 d-inline-flex text-white font-14 text-decoration-none job_internship_btn aos-init aos-animate">
                                                    <span> Create New</span>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="job_internship_tab polartabsec px-4 mx-0">
                                            <div className="xscroll p-0">
                                                <ul className="nav nav-tabs" id="JobInternshipTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <a href="/broadcast" className="nav-link active">
                                                            Drafts
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a href="/broadcast/schedule-mail" className="nav-link">
                                                            Scheduled
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a href="/broadcast/sent-mail" className="nav-link">
                                                            Completed
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="row m-0 pt-4 pb-5 mt-2 JobInternshipListing px-3">
                                            <div className="flex-1 polarcontsec tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="draftsbroadcast" role="tabpanel">
                                                    <div className="row EventsBlk">
                                                        <div className="col-lg-12 col-md-12 broadcast_table">
                                                            <div className="table-responsive attendee_dtl">
                                                                <table className="table" style={{ minWidth: '767px' }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Broadcast Title</th>
                                                                            <th>Created Date</th>
                                                                            <th>Action</th> {/* New Action Column */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {templateData.map((template, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    {template.template_ref_id ? (
                                                                                        <Link to={`/broadcast/import-html?temp_id=${template.template_ref_id}&id=${template.id}`}>
                                                                                            {limitMessage(template.subject, 100)}
                                                                                        </Link>
                                                                                    ) : (
                                                                                        <Link to={`/broadcast/createnew?id=${template.id}`}>
                                                                                            {limitMessage(template.subject, 100)}
                                                                                        </Link>
                                                                                    )}
                                                                                </td>
                                                                                <td>{template.created_date}</td>
                                                                                <td>
                                                                                    {/* Delete Button */}
                                                                                    <button
                                                                                        onClick={() => deleteTemplate(template.id)}
                                                                                        className="btn btn-danger d-flex radius-25">
                                                                                            
                                                                                        <span className='py-2'>Discard</span>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane fade" id="scheduledbroadcast" role="tabpanel">
                                                    <div className="row EventsBlk">
                                                        <div className="col-lg-12 col-md-12 broadcast_search_module">
                                                            <div className="search_module">
                                                                <div className="broadcastSearch_wrap">
                                                                    <form className="broadcastSearch position-relative">
                                                                        <button type="submit" className="btn icon-search"></button>
                                                                        <input
                                                                            className="form-control flex-1 font-14 font-500 w-100"
                                                                            type="search"
                                                                            aria-label="Search"
                                                                            placeholder="Search by broadcast title"
                                                                        />
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <div className="broadcast_count">
                                                                Showing <span className="current_broadcast">1-3</span>
                                                                of <span className="total_count">3</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Show success message */}
                                        {message && (
                                            <div className="alert alert-success mt-3">
                                                {message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Draftmail;
