import { useContext, useEffect, useRef, useState } from 'react';
import { SmartContext } from '../../../../../library/Core/SmartContext';
// import { Modal } from 'bootstrap';
// import EditProfileInformationModal from './EditProfileInformationModal';
import { getDomainValueForCode } from '../../../../../library/Core/SmartFunctions';
// import profileInformationFormPageConfig from './profileInformationFormPageConfig.json';
import { useNavigate } from 'react-router-dom';

const ProfileInformation = () => {
    const { state, dispatch } = useContext(SmartContext);
    const model = state.data.user;
    const profile = state.data.profile;
    // const [isConfigAvailable, setisConfigAvailable] = useState(false);
    const navigate = useNavigate();
    
    // useEffect(() => {
    //     Promise.resolve(profileInformationFormPageConfig)
    //         .then((config) => {
    //             dispatch({
    //                 type: 'DATA_INIT',
    //                 payload: {
    //                     formConfig: config,
    //                     data: state.data,
    //                     domain: new Map([...state.domain]),
    //                     internal: state.internal,
    //                     routeInfo: state.routeInfo,
    //                 },
    //             });
    //             setisConfigAvailable(true);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching page config:', error);
    //         });
    // }, []);

    const isPeerReviewed = sessionStorage.getItem('is-peer-reviewed') == 'true' ? true : false;
    const userType = sessionStorage.getItem('user-type-code');
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    // const handleOpenModal = async () => {
    //     const modalElement = document.getElementById('edit-profile-modal');
    //     if (modalElement) {
    //         const modal = new Modal(modalElement);
    //         modal.show();
    //     }
    // };

    let maskedLocalPart = '';

    if (profile?.contactEmailId != null && profile?.contactEmailId != '') {
        const [localPart, domain] = profile?.contactEmailId.split('@');
        maskedLocalPart = localPart.replace(/./g, 'x');

        maskedLocalPart = localPart[0] + maskedLocalPart.slice(1) + '@' + domain;
    }

    return (
        <>
            <div
                className="tab-pane fade active show"
                id="pills-profileInformation"
                role="tabpanel"
                aria-labelledby="pills-profileInformation-tab">
                <div className="underGraduate-detail">
                    <div className="blockHeader">
                        <div className="row g-2  align-items-center">
                            <div className="col">
                                <div className="title">
                                    <i className="icon icon-user"></i> <span> Profile Information L1 </span>
                                </div>
                            </div>
                            <div className="col-auto">
                                <a
                                    href="javascript:;"
                                    className="btn-add-section"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#profile-information-edit"
                                    // onClick={handleOpenModal}
                                    onClick={() => navigate(`/alumni/${state?.data?.id}/info`)}
                                    >
                                    <b className="icon icon-edit me-2"></b> <span>EDIT</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="graduateRow edit">
                        <div className="row g-3">
                            <div className="col-md-4 col-sm-6">
                                <div className="d-flex flex-column me-4 line-height-24">
                                    <span className="font-16 font-400">Name</span>
                                    <span className="font-18 font-500">
                                        {model?.firstName} {model?.lastName}
                                    </span>
                                </div>
                            </div>

                            {/* <div className="col-md-4 col-sm-6">
                                <div className="d-flex flex-column me-4 line-height-24">
                                    <span className="font-16 font-400">Batch</span>
                                    <span className="font-18 font-500">{profile?.passOutYear}</span>
                                </div>
                            </div> */}

                            <div className="col-md-4 col-sm-6">
                                <div className="d-flex flex-column me-4 line-height-24">
                                    <span className="font-16 font-400">Gender</span>
                                    <span className="font-18 font-500">{profile?.genderCode}</span>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="d-flex flex-column me-4 line-height-24">
                                    <span className="font-16 font-400">Mobile</span>
                                    <span className="font-18 font-500">
                                        {'+' + profile?.contactMobileCountryCode}
                                        {profile?.contactMobileNumber != null && profile?.contactMobileNumber != ''
                                            ? '-xxxxxx' + profile?.contactMobileNumber.slice(6)
                                            : ''}
                                    </span>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="d-flex flex-column me-4 line-height-24">
                                    <span className="font-16 font-400">Email</span>
                                    <span className="font-18 font-500">{maskedLocalPart}</span>
                                </div>
                            </div>

                            {/* <div className="col-md-4 col-sm-6">
                                <div className="d-flex flex-column me-4 line-height-24">
                                    <span className="font-16 font-400">Did you take a gap after Class 12?</span>
                                    <span className="font-18 font-500">{profile?.didYouTakeGapAfter12 === true ? 'Yes' : 'No'}</span>
                                </div>
                            </div> */}

                            <div className="col-md-4 col-sm-6">
                                <div className="d-flex flex-column me-4 line-height-24">
                                    <span className="font-16 font-400">Present Working Status</span>
                                    <span className="font-18 font-500">
                                        {getDomainValueForCode(
                                            profile?.workingStatusCode,
                                            'PRESENT_EDUCATION_STATUS_CODE',
                                            state
                                        ).toLowerCase()
                                        .split('_')
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' ')
                                    }
                                    </span>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="d-flex flex-column me-4 line-height-24">
                                    <span className="font-16 font-400">Present Education Status</span>
                                    <span className="font-18 font-500">
                                        {getDomainValueForCode(
                                            profile?.presentEducationStatusCode,
                                            'WORK_STATUS_CODE',
                                            state
                                        ).toLowerCase()
                                        .split('_')
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' ')}
                                    </span>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="d-flex flex-column me-4 line-height-24">
                                    <span className="font-16 font-400">Country</span>
                                    <span className="font-18 font-500">
                                        {getDomainValueForCode(profile?.residingCountryCode, 'COUNTRY_CODE', state)}
                                    </span>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="d-flex flex-column me-4 line-height-24">
                                    <span className="font-16 font-400">City</span>
                                    <span className="font-18 font-500">
                                        {getDomainValueForCode(profile?.residingCityCode, 'CITY_CODE', state)}
                                    </span>
                                </div>
                            </div>

                            {/* <div className="col-md-4 col-sm-6">
                                <div className="d-flex flex-column me-4 line-height-24">
                                    <span className="font-16 font-400">No of years spent in school</span>
                                    <span className="font-18 font-500">{profile?.yearSpendInSchool}</span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* {isConfigAvailable && <EditProfileInformationModal userdata={{ model, profile }} />} */}
        </>
    );
};

export default ProfileInformation;
